import React, { useCallback, useEffect, useState } from "react";
import "./ToastCustom.css";

// Props
// toasts: Array of objects 
// (e.g. [
//          {
//              type: <String | "success" | "information" | "error" | "warning">,
//              message: <String>,
//              stamp: <TimeStamp>,
//          }
//      ]
// )

const MAXIMUM_EXPIRATION_TIME = 5000; //in milisecs

export default function ToastCustom (props)
{
    const [allToasts, setAllToasts] = useState([]);

    const toastPutOrPush = useCallback(() => {
        setInterval(() => {
            let allToasts = props.toasts;
            let d = new Date();
            let stamp = d.getTime();

            allToasts = allToasts.filter(t => {
                if ((t.stamp + MAXIMUM_EXPIRATION_TIME) > stamp) { return true; }
                else { return false; }
            });
            
            setAllToasts(allToasts);

        }, 1000);
    }, [props, setAllToasts]);

    useEffect(() => {
        toastPutOrPush();
    }, [toastPutOrPush]);

    return (
        <div className="containerToastCustom">
            {
                allToasts.map((t, i) => {
                    return (
                        <div key={i} className={`toastCustom ${t.type}`}>
                            <span>{t.message}</span>
                        </div>
                    )
                })
            }
        </div>
    )
}