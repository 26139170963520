import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditCommissionRulesIntervals.css";
import { 
    formatedPercentToFloat,
    formatedMoneyBRAFromFloat,
    getWindowDimensions,
    formatedOnlyNumber
} from "../../auxiliary/generalFunctions";
import Select from "./Select";
import Button from "./Button";

const initialState = {
    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
}

class TableEditCommissionRulesIntervals extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT COMMISSION RULES INTERVALS prevProps ", prevProps);
        // console.log("TABLE EDIT COMMISSION RULES INTERVALS this.props ", this.props);        
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    setPositionsDetails = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX, clientY} = e;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        clientX = clientX - 180;

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    getTypeName = type =>
    {
        switch(type)
        {
            case "BY_GAIN":
                return "por Ganhos";

            default:
                return "";
        }
    }

    onlyNumber = (value) =>
    {
        value = formatedOnlyNumber(value);
        value = parseInt(value);
        return (isNaN(value)) ? 0 : value;
    }

    render ()
    {
        return (
            <div className="tableEditCommissionRulesIntervals">
                <label className="helpTableEditCommissionRulesIntervals" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditCommissionRulesIntervals" ref={headerTable => {this.headerTable = headerTable}}>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Unidade")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Unidade
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Tipo")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Tipo
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Início do Intervalo")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Início do Intervalo
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Fim do Intervalo")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Fim do Intervalo
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Comissão (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Comissão (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Meses de Cateira")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                M. Cateira
                            </td>
                            <td className="minimus"></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props?.data.map((e, i) => {

                                return (
                                    <tr key={`${i}`} className="tbodyTableEditCommissionRulesIntervals">
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditCommissionRulesIntervals noEdit`}
                                                name="Unidade" 
                                                placeholder={e.optionsUnit.filter(u => u.value === e.unit)[0].name}
                                                onPositiveClick={() => {}}
                                                onNegativeClick={() => {}}
                                                permissions={this.props.permissions} 
                                                optionsSelectMulti
                                            >
                                                <Select
                                                    default={{
                                                        name: e.optionsUnit.filter(u => u.value === e.unit)[0].name,
                                                        value: e.unit,
                                                        checked: true
                                                    }}
                                                    options={e.optionsUnit} 
                                                    onChange={async (choosenUnit) => {}}
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditCommissionRulesIntervals noEdit`}
                                                name="Tipo"
                                                placeholder={this.getTypeName(e.type)}
                                                onPositiveClick={() => {}}
                                                onNegativeClick={() => {}}
                                                permissions={this.props.permissions} 
                                                optionsSelectMulti
                                            >
                                                <Select
                                                    default={{
                                                        name: this.getTypeName(e.type),
                                                        value: e.type,
                                                        checked: true
                                                    }}
                                                    options={[
                                                        {
                                                            name: "por Ganhos",
                                                            value: "BY_GAIN",
                                                            checked: false
                                                        }
                                                    ]} 
                                                    onChange={async (choosenType) => {}}
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRulesIntervals 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.startInterval}
                                                placeholder={e.startInterval}
                                                name="Início do Intervalo"
                                                onChange={value => {
                                                    value = this.onlyNumber(value);
                                                    this.props.onChangeValue(value, e.id, "startInterval");
                                                }}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CRD_AMOUNT_START_INTERVAL", e.startInterval)}
                                                onNegativeClick={this.props.onIgnoreSave}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRulesIntervals 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.endsInterval}
                                                placeholder={e.endsInterval}
                                                name="Fim do Intervalo"
                                                onChange={value => {
                                                    value = this.onlyNumber(value);
                                                    this.props.onChangeValue(value, e.id, "endsInterval");
                                                }}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CRD_AMOUNT_ENDS_INTERVAL", e.endsInterval)}
                                                onNegativeClick={this.props.onIgnoreSave}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRulesIntervals 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedMoneyBRAFromFloat(e.percentCommission.toString())}
                                                placeholder={formatedMoneyBRAFromFloat(e.percentCommission.toString())}
                                                name="Comissão"
                                                onChange={value => {
                                                    value = value.replace(/[A-Za-z]/g, "");
                                                    value = value.toString().replace(",", ".");
                                                    this.props.onChangeValue(formatedMoneyBRAFromFloat(value), e.id, "percentCommission");
                                                }}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CRD_PERCENT_COMMISSION", formatedPercentToFloat(e.percentCommission))}
                                                onNegativeClick={this.props.onIgnoreSave}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRulesIntervals 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.monthsPortfolio}
                                                placeholder={e.monthsPortfolio}
                                                name="Meses de Cateira"
                                                onChange={value => {
                                                    value = this.onlyNumber(value);
                                                    this.props.onChangeValue(value, e.id, "monthsPortfolio");
                                                }}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CRD_TOTAL_MONTHS_OF_PORTFOLIO", e.monthsPortfolio)}
                                                onNegativeClick={this.props.onIgnoreSave}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>                                        
                                        <td>
                                        {
                                                this.props.statusReport !== 1 && this.props.permissions.toDelete &&
                                                <div className="detailsTableEditCommissionRulesIntervals">
                                                    <Button
                                                        classaditional="buttonShowDetailsTableEditCommissionRulesIntervals error"
                                                        icon="fas fa-trash"
                                                        onClick={() => this.props.onRemoveDetailsIntervals(e.id)}
                                                    />
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default connect(null, null)(TableEditCommissionRulesIntervals);