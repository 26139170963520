import React from "react";
import "./Loading.css";
import gif from  "../../assets/images/loading.gif";
import gifTransparent from  "../../assets/images/loading-transparent.png";

export default function Loading (props) 
{
	var asset = (props?.transparent) ? gifTransparent : gif;

	const element = (
		<div className={`loading ${props.classaditional}`}>
			<img src={asset} alt="carregando..."/>
		</div>
	)   
	const loading = props.loading ? element : null

	return loading;
}