import { 
    GET_ALL_ACTIVITIES,
    CHANGE_TASK_ACTIVITIES,
    TOGGLE_CHECKED_ALL_ACTIVITIES,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_ACTIVITIES
} from "../../core/constants";

const initialState = {
    allActivities: [],
    getAllActivitiesFromDatabaseCompleted: true,
}

export default function activitiesReducer (state = initialState, action)
{
    switch (action.type) {

        case GET_ALL_ACTIVITIES:
            
            return {
                ...state,
                allActivities: action.payload,
                getAllActivitiesFromDatabaseCompleted: true,
            }

        case CHANGE_TASK_ACTIVITIES:
            
            let {
                finished, 
                task, 
                checked, 
                businessTitle, 
                businessName, 
                businessPhone, 
                businessMail, 
                businessMoney, 
                propertyName,
                dateStart,
                timeStart,
                id,
                owner
            } = action.payload;
            let allActivitiesFinal = state.allActivities.map(d => {
                
                if (d.id === parseInt(id))
                {
                    switch (propertyName) {
                        case "finished":
                            d.finished = finished;
                            d.dateFinished = new Date();
                            break;
                        case "task":
                            d.task = task;
                            break;
                        case "businessTitle":
                            d.business.title = businessTitle;
                            break;
                        case "businessName":
                            d.business.nameUser = businessName;
                            break;
                        case "businessMail":
                            d.business.mailUser = businessMail;
                            break;
                        case "businessPhone":
                            d.business.phoneUser = businessPhone;
                            break;
                        case "businessMoney":
                            d.business.money = businessMoney;
                            break;
                        case "owner":
                            d.owner = owner;
                            break;
                        case "checked":
                            d.checked = checked;
                            break;
                        case "dateStart":
                            d.dateStart = dateStart;
                            break;
                        case "timeStart":
                            d.timeStart = timeStart;
                            break;
                        default: 
                    }                        
                }
                return d;
            });

            return {
                ...state,
                allActivities: allActivitiesFinal
            }

        case TOGGLE_CHECKED_ALL_ACTIVITIES:
            let status = action.payload;
            let allActivitiesFinalChecked = state.allActivities.map(d => {
                d.checked = status;
                return d;
            });

            return {
                ...state,
                allActivities: allActivitiesFinalChecked
            }

        case CHANGE_STATUS_GET_FROM_DATABASE_ALL_ACTIVITIES:
                
            return {
                ...state,
                getAllActivitiesFromDatabaseCompleted: action.payload
            }
        default:
            return state
    }
}