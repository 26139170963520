import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class CampaignController
{
    async create(data) 
    {
        const response = await api.campaign().post(data);
        return apiCallbackTreatment(response);
    }
    
    async get(userId, status = null) 
    {
        const response = await api.campaign().get(userId, status);
        return apiCallbackTreatment(response);
    }

    async getByFilters(filters = null) 
    {
        const response = await api.campaign().getByFilters(filters);
        return apiCallbackTreatment(response);
    }

    async getFromClients(userId) 
    {
        const response = await api.campaign().getFromClients(userId);
        return apiCallbackTreatment(response);
    }

    async update(id, data) 
    {
        const response = await api.campaign().update(id, data);
        return apiCallbackTreatment(response);
    }
}