import { 
    GET_ALL_SERVICE_QUEUE,
    SET_LAST_POSITION_SERVICE_QUEUE,
    SET_FROZEN_SERVICE_QUEUE,
    CHANGE_SERVICE_QUEUE,
    CHANGE_STATUS_ALL_SPECIALISTS_UPDATED_SERVICE_QUEUE,
    CHANGE_STATUS_LAST_POSITION_SPECIALIST_UPDATED_SERVICE_QUEUE,
    CHANGE_STATUS_FROZEN_SPECIALIST_UPDATED_SERVICE_QUEUE
}  from "../../core/constants";

//action creator
export function getServiceQueue ()
{
    return {
        type: GET_ALL_SERVICE_QUEUE
    }
}

//action creator
export function changeServiceQueue (data)
{
    return {
        type: CHANGE_SERVICE_QUEUE,
        payload: data,
    }
}

//action creator
export function changeStatusAllSpecialistsUpdatedServiceQueue (status)
{
    return {
        type: CHANGE_STATUS_ALL_SPECIALISTS_UPDATED_SERVICE_QUEUE,
        payload: status,
    }
}

//action creator
export function setLastPositionSpecialistServiceQueue (data)
{
    return {
        type: SET_LAST_POSITION_SERVICE_QUEUE,
        payload: data
    }
}

//action creator
export function changeStatusLastPositionSpecialistUpdatedServiceQueue (status)
{
    return {
        type: CHANGE_STATUS_LAST_POSITION_SPECIALIST_UPDATED_SERVICE_QUEUE,
        payload: status
    }
}

//action creator
export function setFrozenSpecialistServiceQueue (data)
{
    return {
        type: SET_FROZEN_SERVICE_QUEUE,
        payload: data
    }
}

// action creator
export function changeStatusFrozenSpecialistUpdatedServiceQueue (status)
{
    return {
        type: CHANGE_STATUS_FROZEN_SPECIALIST_UPDATED_SERVICE_QUEUE,
        payload: status
    }
}