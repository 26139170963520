import UserController from "../../controllers/UserController";
import { 
    GET_ALL_USERS_OWNERS
}  from "../../core/constants";

const userController = new UserController();

export const getAllUsersOwnersMiddleware = (store) => (next) => async (action) =>
{
    if (action.type === GET_ALL_USERS_OWNERS) 
    {
        let payload = [];
        let { user } = store.getState().auth;
        const result = await userController.get(user.PER_ID);
        const allUnitCompanies = await userController.getById(user.PER_ID);

        if (result.status)
        {
            for (const owner of result.data.data) 
            {
                let allowed = false;
                let ownerUnitCompanies = [];
                for (const unit of allUnitCompanies.data.unit_companies)
                {
                    const checked = owner.unit_companies.some((u) => u.UNT_ID === unit.UNT_ID);

                    ownerUnitCompanies.push({
                        id: unit.UNT_ID,
                        name: unit.UNT_NAME,
                        value: unit.UNT_ID,
                        checked
                    });

                    if (checked) { allowed = true; }
                }

                if (allowed)
                {
                    payload.push({
                        id: owner.PER_ID,
                        name: owner.PER_NAME,
                        phone: owner.PER_PHONE_ONE,
                        unitCompanies: ownerUnitCompanies,
                        permissionGroup: {
                            id: owner.PER_ID_PERMISSIONS_GROUP,
                            name: owner.PEG_NAME,
                            value: owner.PER_ID_PERMISSIONS_GROUP
                        },
                        date: new Date(owner.PER_CREATE_DATE),
                        archived: owner.PER_STATUS === 1 ? false : true,
                        checked: false,
                        mail: owner.PER_MAIL,
                        password: "",
                        idUserERP: owner.PER_ID_USER_ERP,
                        inQueue: owner.SEP_ID !== null ? true : false,
                        inQueuePreSale: owner.amountInPreSaleQueue > 0,
                        typeWorkplace: owner.PER_TYPE_WORKPLACE,
                        supervisor: owner.PER_ID_PERSON_SUPERIOR_TEAM,
                        shift: owner.WKP_ID_WORK_SHIFTS
                    });
                }
            };
        }
        action.payload = payload;
    }
    next(action);
}