import React from "react";
import "./CardChartPie.css";

export default function CardChartPie (props)
{
    return (
        <div className="cardChartPie">
            {
                props?.title && 
                <div className="titleCardChartPie">
                    {props.title}
                </div>
            }
            <div className="chartChartPie">
                {props.children}
                {
                    props.labelOver &&
                    <span className={`labelOverCardChartPie ${props.colorLabelOver ? props.colorLabelOver : "success"}`}>
                        {props.labelOver}
                    </span>
                }
            </div>
            {
                props?.subtitle && 
                <div className="subtitleCardChartPie">
                    {props.subtitle}
                </div>
            }
        </div>
    )
}