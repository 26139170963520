import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class DashboardProspectionController
{
    async get(userId, dateStart, dateEnds, timeStart, timeEnds, dateRunning, target, filters, version) 
    {
        const response = await api.dashboardProspection().get(userId, dateStart, dateEnds, timeStart, timeEnds, dateRunning, target, filters, version);
        return apiCallbackTreatment(response);
    }
}