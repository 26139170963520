import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditAbsenceAttendants.css";
import {
    formatedDateTimeToUSA,
    formatedDateTimeToBRA,
    formatedTime, 
    getWindowDimensions
} from "../../auxiliary/generalFunctions";
import Select from "./Select";
import Button from "./Button";

const initialState = {

    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
    
}

class TableEditAbsenceAttendants extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT ABSENCE ATTENDANTS prevProps ", prevProps);
        // console.log("TABLE EDIT ABSENCE ATTENDANTS this.props ", this.props);        
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    render ()
    {
        return (
            <div className="tableEditAbsenceAttendants">
                <label className="helpTableEditAbsenceAttendants" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditAbsenceAttendants" ref={headerTable => {this.headerTable = headerTable}}>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Atendente")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Atendente
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Data")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Data
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Horário Inicial")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Horário Inicial
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Horário Final")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Horário Final
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Motivo")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Motivo
                            </td>
                            <td
                                className="minimus100"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >    
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props?.data.map((e, i) => {
                            
                                return (
                                    <tr key={`${i}`} className="tbodyTableEditAbsenceAttendants">
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditAbsenceAttendants ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                placeholder={this.props.optionsAttendants.filter(a => a.value === e.idAttendant)[0].name}
                                                name="Atendente"
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "ABT_ID_ABSENCE_PERSON", e.idAttendant)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                optionsSelectMulti
                                                permissions={this.props.permissions}
                                            >
                                                <Select
                                                    default={{
                                                        name: this.props.optionsAttendants.filter(a => a.value === e.idAttendant)[0].name,
                                                        value: e.idAttendant,
                                                        checked: true
                                                    }}
                                                    options={this.props.optionsAttendants} 
                                                    onChange={async (chosenAttendant) => this.props.onChangeValue(chosenAttendant.value, e.id, "idAttendant")}
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditAbsenceAttendants ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                value={e.date !== null && formatedDateTimeToUSA(e.date)}
                                                placeholder={e.date !== null ? formatedDateTimeToBRA(e.date).toString(): ""}
                                                name="Data"
                                                onChange={value => this.props.onChangeValue((value), e.id, "date")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "ABT_ABSENCE_DATE", e.date)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                type="date"
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditAbsenceAttendants ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                value={e.timeStart !== null ? formatedTime(e.timeStart) : ""}
                                                placeholder={e.timeStart !== null ? formatedTime(e.timeStart).toString() : ""}
                                                name="Horário Inicial"
                                                onChange={value => this.props.onChangeValue(value, e.id, "timeStart")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "ABT_TIME_START", e.timeStart)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditAbsenceAttendants ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                value={e.timeEnds !== null ? formatedTime(e.timeEnds) : ""}
                                                placeholder={e.timeEnds !== null ? formatedTime(e.timeEnds).toString() : ""}
                                                name="Horário Final"
                                                onChange={value => this.props.onChangeValue(value, e.id, "timeEnds")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "ABT_TIME_ENDS", e.timeEnds)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditAbsenceAttendants ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                value={e.reason}
                                                placeholder={e.reason}
                                                name="Motivo"
                                                onChange={value => this.props.onChangeValue(value, e.id, "reason")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "ABT_ABSENCE_REASON", e.reason)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={50}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            {
                                                this.props.permissions.toDelete &&
                                                <div className="detailsTableEditAbsenceAttendants">
                                                        <Button
                                                            classaditional="buttonShowDetailsTableEditAbsenceAttendants error"
                                                            icon="fas fa-trash"
                                                            onClick={() => this.props.onDeleteValue(e.id, e.reason)}
                                                        />
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default connect(null, null)(TableEditAbsenceAttendants);