import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class DashboardMarketingController
{
    async get(userId, dateStart, dateEnds, timeStart, timeEnds, dateRunning, filters)
    {
        const response = await api.dashboardMarketing().get(userId, dateStart, dateEnds, timeStart, timeEnds, dateRunning, filters);
        return apiCallbackTreatment(response);
    }
}