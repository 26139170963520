import React from "react";
import "./DropDown.css";

export default function DropDown (props)
{
    return (
        <div className={`dropDown ${props.classaditional}`} {...props}>
            {props?.children}
        </div>
    )
}