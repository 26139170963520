import API from "../api";
import { apiCallbackTreatment} from "../auxiliary/generalFunctions";

var api = new API();

export default class TrainingController
{
    async get (userId)
    {
        const response = await api.training().get(userId);

        return apiCallbackTreatment(response);
    }
}