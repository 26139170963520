import CompanyUnitController from "../../controllers/CompanyUnitController";
import {
    GET_ALL_UNIT_COMPANY
} from "../../core/constants";

const companyUnitController = new CompanyUnitController();

export const getAllCompanyUnitsMiddleware = (store) => (next) => async (action) =>
{
    if (action.type === GET_ALL_UNIT_COMPANY)
    {
        let payload = [];
        const result = await companyUnitController.get();
        const filter = action.payload;

        if (result.status) 
        {
            for (const company of result.data.data)
            {
                if (company.UNT_STATUS === filter) 
                {
                    payload.push({
                        id: company.UNT_ID,
                        name: company.UNT_NAME,
                        phone: company.UNT_PHONE,
                        status: company.UNT_STATUS,
                        checked: false
                    });
                }
            }

        }

        action.payload = payload;
    }

    next(action);
}