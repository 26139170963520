import React, { Component } from "react";
import "./PersonalPreferences.css";

import Header from "../../components/CRM/Header";
import Box from "../../components/general/Box";
import InputGeneral from "../../components/general/InputGeneral";
import Button from "../../components/general/Button";
import AuthController from "../../controllers/AuthController";
import UserController from "../../controllers/UserController";
import LineCheckRequirement from "../../components/general/LineCheckRequirement";

import { setUserLogout } from "../../store/actions/auth";
import { connect } from "react-redux";
import { APPLICATION_NAME } from "../../core/constants";
import { withRouter } from "react-router";
import { setModalMessage } from "../../store/actions/general";
import { formatedOnlyNumber, hasSpecialChar, onlyLetters } from "../../auxiliary/generalFunctions";

const initialState = {
    oldPasswordUser: "",
    newPasswordUser: "",
    confirmNewPasswordUser: "",
    avatarUser: null,
    user: {},

    minimun8Chars: false,
    minimun1Special: false,
    minimun3Letters: false,
    minimun4Numbers: false,
    allValidatedMinimuns: false,
}

const authController = new AuthController();
const userController = new UserController();
class PersonalPreferences extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Configurações Pessoais`;
        this.setState({ user: this.props.user });
        this.isMandatory();
    }

    isMandatory = () =>
    {
        let currentLocation = window.location.href;
        let mandatory = "mandatory=true";
        
        if (currentLocation.indexOf(mandatory) >= 0)
        {
            this.message("information", "Opsssss, vamos precisar que você atualize sua senha, ok?!");
        }
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("PERSONAL PREFERENCES prevProps ", prevProps);
        // console.log("PERSONAL PREFERENCES this.props ", this.props); 

        if (this.state.user !== this.props.user)
        {
            this.setState({ user: this.props.user });
        }
    }

    onActionPassword = async () => 
    {
        let allValidatedMinimuns = await this.state.allValidatedMinimuns;

        if (!allValidatedMinimuns)
        {
            this.message("error", "Por favor, confira os requisitos para alterar a senha!");
            return;
        }

        const result = await authController.updatePassword({
            perID: this.state.user.PER_ID,
            oldPassword: this.state.oldPasswordUser,
            newPassword: this.state.newPasswordUser,
            newPasswordConfirm: this.state.confirmNewPasswordUser
        });

        if (!result.status) { return this.message("error", result.message); }

        this.message("success", `Senha alterada com sucesso! Você precisa logar novamente. Aguarde...`);

        setTimeout(() => {
            this.props.setUserLogout();
        },5000)
    }

    onActionAvatar = async () =>
    {
        let avatarUser = await this.state.avatarUser;
        let user = await this.state.user;

        if (!avatarUser) 
        {
            this.message("error", "Opsssss, escolha um avatar válido!");
            return;
        }

        let fileName = `avatar-${user.PER_ID}.jpeg`;
        let file = new File([avatarUser], fileName, {type: "iamge/jpeg", lastModified: new Date()})
        let form = new FormData();
        form.append("file", file);

        let result = await userController.changeAvatar(form);

        if (result.status)
        {
            this.message("success", "Obaaaaa, avatar alterado com sucesso!");
        }
        else
        {
            this.message("error", "Opsssss, tivemos algum problema técnico. Se o erro persistir contacte o desenvolvimento.");
        }
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    readFile = async (event) =>
    {
        let avatarUser = event.target.files[0];
        await this.setState({avatarUser});
    }

    onChange = async (property, newValue) =>
    {
        await this.setState({ [property]: newValue });
        await this.checkingValidation();
    }

    checkingValidation = async () =>
    {
        let newPasswordUser = await this.state.newPasswordUser;
        let minimun8Chars = newPasswordUser.toString().length > 7;
        let minimun1Special = hasSpecialChar(newPasswordUser);
        let minimun3Letters = onlyLetters(newPasswordUser.toString()).length > 2;
        let minimun4Numbers = formatedOnlyNumber(newPasswordUser.toString()).length > 3;
        let allValidatedMinimuns = (minimun8Chars && minimun1Special && minimun3Letters && minimun4Numbers);

        await this.setState({
            minimun8Chars, 
            minimun1Special, 
            minimun3Letters,
            minimun4Numbers,
            allValidatedMinimuns
        });
    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }

    render ()
    {
        return (
            <div className="personalPreferences">
                <Header title="Preferências Pessoais"/>
                <div className="scrollPersonalPreferences mb-4">
                    <div className="container headerPersonalPreferences">
                        <Box>
                            <h4>Alterando para { this.props.user.PER_NAME }</h4>
                        </Box>
                    </div>
                    <div className="container bodyPersonalPreferences">
                        <Box>
                            <div className="row no-gutters mt-2 mb-2 lineTitleHeaderConfigPersonalPreferences">
                                <div className="col-12 col-lg-4 columnTitleHeaderConfigPersonalPreferences">
                                    <span>Avatar</span>                                
                                </div>
                                <div className="col-12 col-lg-4">
                                </div>
                                <div className="col-12 col-lg-4 columnTitleHeaderConfigPersonalPreferences">
                                    <span>Senha</span>
                                </div>
                            </div>
                            <div className="row no-gutters mt-4 mb-2 lineHeaderConfigPersonalPreferences">
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences">
                                    <InputGeneral
                                        classaditional="chosenAvatarPersonPreferences"
                                        label="ESCOLHER AVATAR"
                                        type="file" 
                                        accept="image/*"
                                        onChange={(event) => this.readFile(event)}
                                        // onClick={(event) => event.target.value = null}
                                    />                                
                                </div>
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences">
                                </div>
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences">
                                    <InputGeneral
                                        label="SENHA ANTIGA"
                                        onChange={e => this.onChange("oldPasswordUser", e.target.value)}
                                        type="password"
                                        maxLength={15}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters mt-2 mb-2 lineFooterConfigPersonalPreferences">
                                <div className="col-12 col-lg-4 columnFooterConfigPersonalPreferences">
                                    <Button
                                        classaditional="buttonFooterConfigPersonalPreferences"
                                        name="Cancelar"
                                        onClick={() => this.props.history.push(`/activities`)}
                                    />
                                    {
                                        this.props.permissions.toUpdate && (
                                            <Button
                                                classaditional="buttonFooterConfigPersonalPreferences positive"
                                                name="Salvar"
                                                onClick={() => this.onActionAvatar()}
                                            />
                                        )
                                    }                                    
                                </div>
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences">
                                </div>
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences">
                                    <InputGeneral
                                        label="SENHA NOVA"
                                        onChange={e => this.onChange("newPasswordUser", e.target.value)}
                                        type="password"
                                        maxLength={15}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters mt-2 mb-4 lineHeaderConfigPersonalPreferences">
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences">
                                </div>
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences">
                                </div>
                                <div className="col-12 col-lg-4 columnHeaderConfigPersonalPreferences">
                                    <InputGeneral
                                        label="SENHA NOVA CONFIRMA"
                                        onChange={e => this.onChange("confirmNewPasswordUser", e.target.value)}
                                        type="password"
                                        maxLength={15}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters mt-2 mb-2 lineFooterConfigPersonalPreferences">
                                <div className="col-12 col-lg-4">    
                                </div>
                                <div className="col-12 col-lg-4">
                                </div>
                                <div className="col-12 col-lg-4 columnFooterConfigPersonalPreferences">
                                    <Button
                                        classaditional="buttonFooterConfigPersonalPreferences"
                                        name="Cancelar"
                                        onClick={() => this.props.history.push(`/activities`)}
                                    />
                                    {
                                        this.props.permissions.toUpdate && (
                                            <Button
                                                classaditional="buttonFooterConfigPersonalPreferences positive"
                                                name="Salvar"
                                                onClick={() => this.onActionPassword()}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row no-gutters mt-2 mb-2 lineHeaderConfigPersonalPreferences">
                                <div className="col-12 col-lg-4">
                                </div>
                                <div className="col-12 col-lg-4">
                                </div>
                                <div className="col-12 col-lg-4">
                                    <LineCheckRequirement
                                        classaditional="justify-content-center"
                                        label="Ter no mínimo 8 caracteres."
                                        status={this.state.minimun8Chars}
                                    />
                                    <LineCheckRequirement
                                        classaditional="justify-content-center"
                                        label="Ter no mínimo 1 caracter especial."
                                        status={this.state.minimun1Special}
                                    />
                                    <LineCheckRequirement
                                        classaditional="justify-content-center"
                                        label="Ter no mínimo 3 letras."
                                        status={this.state.minimun3Letters}
                                    />
                                    <LineCheckRequirement
                                        classaditional="justify-content-center"
                                        label="Ter no mínimo 4 números."
                                        status={this.state.minimun4Numbers}
                                    />                                    
                                </div>
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const permissions = state.permissions.personalPreferences;
    const { user } = state.auth;

    return {
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
        setUserLogout ()
        {
            //action creator -> action
            const action = setUserLogout();
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalPreferences));