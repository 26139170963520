import React, { useEffect, useState } from "react"; 
import "./MenusCRM.css";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { changeModeViewBusiness } from "../../store/actions/business";
import { setUserLogout } from "../../store/actions/auth";

import favicon from "../../assets/images/favicon.svg";
import { modeBusinessDraggable } from "../../core/constants";

function MenusCRM (props)
{
    const [routes, setRouteActive] = useState("users");
    const [multipleDash, setMultipleDash] = useState(false);
    const location = useLocation();
    const [notificationsAmountWhatsAppInQueue, setNotificationsAmountWhatsAppInQueue] = useState(0);
    const [notificationsAmountWhatsApp, setNotificationsAmountWhatsApp] = useState(0);

    useEffect(() => {
        let route = location.pathname;
        route = route.replace("/", "");
        setRouteActive(route);

        if (props.permissions.dashboard.toView && props.permissions.dashboardProspection.toView) { setMultipleDash(true) }

        setNotificationsAmountWhatsAppInQueue(props.notificationsWhatsAppInQueue);
        setNotificationsAmountWhatsApp(props.notificationsWhatsApp);
        
    },[routes, setRouteActive, setMultipleDash, location, props])

    return (
        <div className="menusCRM">
            <nav style={{height: "100%"}}>
                <ul>
                    { props.permissions.dashboard.toView && 
                        <li>
                            <Link 
                                to="/" 
                                className="anchorMenu" 
                                onClick={() => { if (!multipleDash) { window.location.href ="/"} }}
                            >
                                <div className={`flagMenu ${(routes === "" ? "active" : null)}`}></div>
                                <div className={`squareMenu ${(routes === "" ? "active" : null)}`}>
                                    <img src={favicon} className="squareMenu brandMenu" alt="marca sempnet logo"/>
                                </div>
                                <div className="moreInformationMenu">
                                    Vendas Comercial
                                </div>
                            </Link>
                        </li>
                    }
                    { props.permissions.dashboardProspection.toView && 
                        <li>
                            <Link
                                to="/dashboard-prospection" 
                                className="anchorMenu"
                                onClick={() => {}}
                            >
                                <div className={`flagMenu ${(routes === "dashboard-prospection" ? "active" : null)}`}></div>
                                <i className={`far fa-tachometer-alt-fast squareMenu ${(routes === "dashboard-prospection" ? "active" : null)} iconMenu`}></i>
                                <div className="moreInformationMenu">Vendas Operação</div>
                            </Link>
                        </li>
                    }
                    { props.permissions.dashboardMarketing.toView && 
                        <li>
                            <Link
                                to="/dashboard-marketing" 
                                className="anchorMenu"
                                onClick={() => {}}
                            >
                                <div className={`flagMenu ${(routes === "dashboard-marketing" ? "active" : null)}`}></div>
                                <i className={`far fa-megaphone squareMenu ${(routes === "dashboard-marketing" ? "active" : null)} iconMenu`}></i>
                                <div className="moreInformationMenu">
                                    Marketing
                                </div>
                            </Link>
                        </li>
                    }
                    <div className="dividerMenu"></div>
                    { props.permissions.leads.toView && 
                        <li>
                            <Link
                                to="/leads" 
                                className="anchorMenu"
                                onClick={() => {}}
                            >
                                <div className={`flagMenu ${(routes === "leads" ? "active" : null)}`}></div>
                                <i className={`fas fa-users squareMenu ${(routes === "leads" ? "active" : null)} iconMenu`}></i>
                                <div className="moreInformationMenu">
                                    Clientes
                                    <span className="numberInformationMenu">{ props.allUsers?.length || 0 }</span>
                                </div>
                            </Link>
                        </li>
                    }
                    { props.permissions.business.toView &&
                        <li>
                            <Link 
                                to="/business" 
                                className="anchorMenu"
                                onClick={() => { 
                                    props.changeModeViewBusiness(modeBusinessDraggable);
                                }}
                            >
                                <div className={`flagMenu ${((routes === "business") ? "active" : null)}`}></div>
                                <i className={`fal fa-bullseye-arrow squareMenu ${((routes === "business") ? "active" : null)} iconMenu`} ></i>
                                <div className="moreInformationMenu">
                                    Negócios
                                    <span className="numberInformationMenu">{ props.allDraggables?.length || 0 }</span>
                                </div>
                            </Link>
                        </li>
                    }
                    { props.permissions.activities.toView &&
                        <li>
                            <Link  
                                to="/activities" 
                                className="anchorMenu"
                                onClick={() => {}}
                            >
                                <div className={`flagMenu ${(routes === "activities" ? "active" : null)}`}></div>
                                <i className={`fas fa-calendar-day squareMenu ${(routes === "activities" ? "active" : null)} iconMenu`}></i>
                                <div className="moreInformationMenu">
                                    Atividades
                                    <span className="numberInformationMenu">{ props.allActivities?.length || 0 }</span>
                                </div>
                            </Link>
                        </li>
                    }
                    {props.permissions.training.toView && 
                        <li>
                            <Link  
                                to="/training" 
                                className="anchorMenu"
                                onClick={() => {}}
                            >
                                <div className={`flagMenu ${(routes === "training" ? "active" : null)}`}></div>
                                <i className={`far fa-presentation squareMenu ${(routes === "training" ? "active" : null)} iconMenu`}></i>
                                <div className="moreInformationMenu">
                                    Treinamento
                                    <span className="numberInformationMenu">0</span>
                                </div>
                            </Link>
                        </li>                    
                    }
                    { props.permissions.chat.toView &&
                        <li>
                            <Link  
                                to="/chat" 
                                className="anchorMenu"
                                onClick={() => {}}
                            >
                                <div className={`flagMenu ${(routes === "chat" ? "active" : null)}`}></div>
                                <i className={`fas fa-comments-alt squareMenu ${(routes === "chat" ? "active" : null)} iconMenu`}></i>
                                <div className="moreInformationMenu">
                                    Chat
                                    <span className="numberInformationMenu">0</span>
                                </div>
                            </Link>
                        </li>
                    }
                    { props.permissions.whatsapp.toView &&
                        <li>
                            <Link  
                                to="/whatsapp-queue" 
                                className="anchorMenu"
                                onClick={() => {}}
                            >
                                {notificationsAmountWhatsAppInQueue > 0 && <span className="amountNotificationsMenusCRM">{(notificationsAmountWhatsAppInQueue > 9 ? `9+` : `${notificationsAmountWhatsAppInQueue}`)}</span>}
                                <div className={`flagMenu ${(routes === "whatsapp-queue" ? "active" : null)}`}></div>
                                <i className={`fas fa-comment-times squareMenu ${(routes === "whatsapp-queue" ? "active" : null)} iconMenu`}></i>
                                <div className="moreInformationMenu">
                                    Fila de Atendimento do WhatsApp
                                    <span className="numberInformationMenu">{notificationsAmountWhatsAppInQueue}</span>
                                </div>
                            </Link>
                        </li>
                    }
                    { props.permissions.whatsapp.toView &&
                        <li>
                            <Link
                                to="/whatsapp" 
                                className="anchorMenu"
                                onClick={() => {}}
                            >
                                {notificationsAmountWhatsApp > 0 && <span className="amountNotificationsMenusCRM">{(notificationsAmountWhatsApp > 9 ? `9+` : `${notificationsAmountWhatsApp}`)}</span>}
                                <div className={`flagMenu ${(routes === "whatsapp" ? "active" : null)}`}></div>
                                <i className={`fab fa-whatsapp squareMenu ${(routes === "whatsapp" ? "active" : null)} iconMenu`}></i>
                                <div className="moreInformationMenu">
                                    WhatsApp
                                    <span className="numberInformationMenu">{notificationsAmountWhatsApp}</span>
                                </div>
                            </Link>
                        </li>
                    }
                    {/* { true &&
                        <li>
                            <Link  
                                to="/whatsapp" 
                                className="anchorMenu"
                                onClick={() => {}}
                            >
                                <div className={`flagMenu ${(routes === "whatsapp" ? "active" : null)}`}></div>
                                <i className={`fab fa-whatsapp squareMenu ${(routes === "whatsapp" ? "active" : null)} iconMenu`}></i>
                                <div className="moreInformationMenu">
                                    WhatsApp
                                    <span className="numberInformationMenu">0</span>
                                </div>
                            </Link>
                        </li>
                    } */}
                    <li>
                        <Link 
                            to="/" 
                            className="anchorMenu btnLogout"
                            onClick={() => props.setUserLogout()}
                        >
                            <i className={`fal fa-sign-out squareMenu iconMenu`} ></i>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

function mapStateToProps (state)
{
    const { allActivities } = state.activities;
    const { allDraggables } = state.business;
    const { allUsers } = state.leads;
    const permissions = state.permissions;

    return {
        allActivities,
        allDraggables,
        allUsers,
        permissions
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        changeModeViewBusiness (mode)
        {
            //action creator -> action
            const action = changeModeViewBusiness(mode);
            dispatch(action);
        },
        setUserLogout ()
        {
            //action creator -> action
            const action = setUserLogout();
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenusCRM);