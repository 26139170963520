import React, { Component } from "react";
import "./CommissionIntervalsDetailsForm.css";

import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import { formatedOnlyNumber } from "../../auxiliary/generalFunctions";
import { setModalMessage } from "../../store/actions/general";

import InputGeneral from "../general/InputGeneral";
import Button from "../general/Button";
import Select from "../general/Select";

import CommissionRulesIntervalsController from "../../controllers/CommissionRulesIntervalsController";
import CompanyUnitController from "../../controllers/CompanyUnitController";
import CommissionRulesDetailsProspectionController from "../../controllers/CommissionRulesDetailsIntervalsController";

const initialState = {

    disabledButton: false,

    // start content month
    month: "",
    validMonth: true,
    // ends content month

    // start content companyUnit
    optionsCompanyUnit: [],
    choosenCompanyUnit: {
        name: "",
        value: 0,
    },
    validCompanyUnit: true,
    // ends content companyUnit

    // start content startInterval
    startInterval: "",
    validStartInterval: true,
    // start content startInterval

    // start content endsInterval
    endsInterval: "",
    validEndsInterval: true,
    // start content endsInterval

    // start type
    validType: true,
    choosenType: {
        name: "por Ganhos",
        value: "BY_GAIN",
    },
    optionsType: [
        {
            name: "por Ganhos",
            value: "BY_GAIN",
            checked: false
        }
    ],
    // ends type

    // start content percentCommission
    percentCommission: "",
    validPercentCommission: true,
    // ends content percentCommission

    // start content monthsPortfolio
    monthsPortfolio: "",
    validMonthsPortfolio: true,
    // start content monthsPortfolio
}

const companyUnitController = new CompanyUnitController();
const commissionRulesIntervalsController = new CommissionRulesIntervalsController();
const commissionRulesDetailsProspectionController = new CommissionRulesDetailsProspectionController();
class CommissionIntervalsDetailsForm extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        await this.getCompanyUnit();
    }

    getCompanyUnit = async () => 
    {
        let optionsCompanyUnit = [];
        const result = await companyUnitController.get();

        if (result.status) 
        {
            for (const company of result.data.data)
            {
                if (company.UNT_STATUS === 1)
                {
                    optionsCompanyUnit.push(
                        {
                            name: company.UNT_NAME,
                            value: company.UNT_ID,
                            checked: false
                        }
                    );
                }
            }
        }
                
        await this.setState({optionsCompanyUnit});
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        };

        this.props.showModalMoreActions(data);
    }

    monthChangeAndChecking = async (month) =>
    {
        await this.setState({
            month,
            validMonth: month.toString().trim() !== ""
        });
    }

    companyUnitChangeAndChecking = async (choosenCompanyUnit) =>
    {
        await this.setState({
            choosenCompanyUnit,
            validCompanyUnit: choosenCompanyUnit.value !== 0
        });
    }

    startIntervalChangeAndChecking = async (startInterval) =>
    {
        let endsInterval = await this.state.endsInterval;

        startInterval = formatedOnlyNumber(startInterval);
        startInterval = parseInt(startInterval);
        startInterval = (isNaN(startInterval)) ? 0 : startInterval;

        await this.setState({
            startInterval: (startInterval),
            validStartInterval: (startInterval.toString().trim() !== "" && startInterval < endsInterval),
            validEndsInterval: (endsInterval.toString().trim() !== "" && startInterval < endsInterval)
        });
    }

    endsIntervalChangeAndChecking = async (endsInterval) =>
    {
        let startInterval = await this.state.startInterval;

        endsInterval = formatedOnlyNumber(endsInterval);
        endsInterval = parseInt(endsInterval);
        endsInterval = (isNaN(endsInterval)) ? 0 : endsInterval;

        await this.setState({
            endsInterval: (endsInterval),
            validStartInterval: (startInterval.toString().trim() !== "" && startInterval < endsInterval),
            validEndsInterval: (endsInterval.toString().trim() !== "" && startInterval < endsInterval)
        });
    }

    monthsPortfolioChangeAndChecking = async (monthsPortfolio) =>
    {
        monthsPortfolio = formatedOnlyNumber(monthsPortfolio);
        monthsPortfolio = parseInt(monthsPortfolio);
        monthsPortfolio = (isNaN(monthsPortfolio)) ? 0 : monthsPortfolio;

        await this.setState({
            monthsPortfolio: (monthsPortfolio),
            validMonthsPortfolio: (monthsPortfolio.toString().trim() !== "" && monthsPortfolio !== 0)
        });
    }

    pecentCommissionChangeAndChecking = async (percentCommission) =>
    {
        percentCommission = percentCommission.replace(/[A-Za-z]/g, "");
        percentCommission = percentCommission.toString().replace(",", ".");

        await this.setState({
            percentCommission,
            validPercentCommission: percentCommission.toString().trim() !== ""
        });
    }

    onActionForm = async () =>
    {
        await this.setState({disabledButton: true});

        let month = await this.state.month;
        let choosenCompanyUnit = await this.state.choosenCompanyUnit;
        let startInterval = await this.state.startInterval;
        let endsInterval = await this.state.endsInterval;
        let choosenType = await this.state.choosenType;
        let monthsPortfolio = await this.state.monthsPortfolio;
        let percentCommission = await this.state.percentCommission;
        let idCommissionRuleIntervals = 0;

        if (month.toString().trim() === "") { return this.setState({validMonth: false}); }
        if (choosenCompanyUnit.value === 0) { return this.setState({validCompanyUnit: false}); }
        if (startInterval.toString().trim() === "") { return this.setState({validStartInterval: false}); }
        if (endsInterval.toString().trim() === "") { return this.setState({validEndsInterval: false}); }
        if (percentCommission.toString().trim() === "") { return this.setState({validPercentCommission: false}); }
        if (monthsPortfolio.toString().trim() === "" || monthsPortfolio === 0) { return this.setState({validMonthsPortfolio: false}); }
        
        if (endsInterval < startInterval) 
        { 
            this.message("error", "Opsssss, o valor do 'Fim do Intervalo', deve ser maior que o valor do 'Início do Intervalo'.");
            return this.setState({validStartInterval: false, validEndsInterval: false});
        }

        percentCommission = parseFloat(percentCommission);

        let resultRule = await commissionRulesIntervalsController.get(month, choosenCompanyUnit.value);

        if (!resultRule.status || resultRule.data?.number_of_lines === 0)
        {
            const resultCreateRule = await commissionRulesIntervalsController.create({CRI_MONTH: month, CRI_ID_COMPANY_UNIT: choosenCompanyUnit.value});
            idCommissionRuleIntervals = resultCreateRule.data.CRI_ID;
        }
        else
        {
            idCommissionRuleIntervals = resultRule.data.data[0].CRI_ID;
        }

        const result = await commissionRulesDetailsProspectionController.create(
            {
                CRD_ID_COMMISSION_RULES_INTERVALS: idCommissionRuleIntervals,
                CRD_TYPE: choosenType.value,
                CRD_AMOUNT_START_INTERVAL: startInterval,
                CRD_AMOUNT_ENDS_INTERVAL: endsInterval,
                CRD_PERCENT_COMMISSION: percentCommission,
                CRD_TOTAL_MONTHS_OF_PORTFOLIO: monthsPortfolio
            }
        );

        if (!result.status)
        {
            await this.setState({disabledButton: false});
            return this.message("error", result.message);
        }

        await this.setState({disabledButton: false});
        this.props.data.onActionForm();
        this.closeModalMoreActions();
    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }

    render () 
    {
        return (
            <div className="commissionIntervalsDetailsForm"> 
                <div className="row no-gutters lineCommissionIntervalsDetailsForm">
                    <div className="col-6 columnCommissionIntervalsDetailsForm pr-1">
                        <InputGeneral
                            label="Mês de Referência"
                            value={this.state.month}
                            onChange={e => this.monthChangeAndChecking(e.target.value)}
                            mandatory="true"
                            type="month"
                            valid={this.state.validMonth.toString()} 
                        />
                    </div>
                    <div className="col-6 columnCommissionIntervalsDetailsForm pl-1">
                        <Select
                            label="Unidade"
                            options={this.state.optionsCompanyUnit}
                            default={this.state.choosenCompanyUnit}
                            onChange={(choosenCompanyUnit) => this.companyUnitChangeAndChecking(choosenCompanyUnit)} 
                            mandatory="true"
                            valid={this.state.validCompanyUnit.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionIntervalsDetailsForm">
                    <div className="col-6 columnCommissionIntervalsDetailsForm pr-1">
                        <InputGeneral
                            label="Início do Intervalo"
                            placeholder="1"
                            value={this.state.startInterval}
                            onChange={e => this.startIntervalChangeAndChecking(e.target.value)}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validStartInterval.toString()}
                        />
                    </div>
                    <div className="col-6 columnCommissionIntervalsDetailsForm pl-1">
                        <InputGeneral
                            label="Fim do Intervalo"
                            placeholder="10"
                            value={this.state.endsInterval}
                            onChange={e => this.endsIntervalChangeAndChecking(e.target.value)}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validEndsInterval.toString()}
                        />
                    </div>
                </div>                  
                <div className="row no-gutters lineCommissionIntervalsDetailsForm">
                    <div className="col-6 columnCommissionIntervalsDetailsForm pr-1">
                        <Select
                            label="Tipo"
                            options={this.state.optionsType}
                            default={this.state.choosenType}
                            onChange={(choosenType) => this.setState({choosenType})} 
                            mandatory="true"
                        />
                    </div>
                    <div className="col-6 columnCommissionIntervalsDetailsForm pl-1">
                        <InputGeneral
                            label="Comissão (%)"
                            placeholder="14.98"
                            value={this.state.percentCommission}
                            onChange={e => this.pecentCommissionChangeAndChecking(e.target.value)}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validPercentCommission.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionIntervalsDetailsForm">
                    <div className="col-6 columnCommissionIntervalsDetailsForm pr-1">
                        <InputGeneral
                            label="Meses de Cateira"
                            placeholder="8"
                            value={this.state.monthsPortfolio}
                            onChange={e => this.monthsPortfolioChangeAndChecking(e.target.value)}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validMonthsPortfolio.toString()}
                        />
                    </div>
                </div>                
                <div className="row no-gutters lineCommissionIntervalsDetailsForm">
                    <div className="col-12 columnCommissionIntervalsDetailsForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterCommissionIntervalsDetailsForm">
                    <Button
                        classaditional="buttonCommissionIntervalsDetailsForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions()}
                    />
                    <Button
                        classaditional="buttonCommissionIntervalsDetailsForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()} 
                        disabled={this.state.disabledButton}
                    />                  
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;

    return {
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        },
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionIntervalsDetailsForm);