import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class PreSaleServiceQueuePersonsController
{
    async get(idUser) 
    {
        const response = await api.preSaleServiceQueuePersons().get(idUser);
        return apiCallbackTreatment(response);
    }

    async post(idUser) 
    {
        const response = await api.preSaleServiceQueuePersons().post(idUser);
        return apiCallbackTreatment(response);
    }
    
    async changeFrozen(data)
    {
        const response = await api.preSaleServiceQueuePersons().changeFrozen(data);
        return apiCallbackTreatment(response);

    }
    
    async deletePosition(data)
    {
        const response = await api.preSaleServiceQueuePersons().deletePosition(data);
        return apiCallbackTreatment(response);
    }
    
    async deletePositionAll(data)
    {
        const response = await api.preSaleServiceQueuePersons().deletePositionAll(data);
        return apiCallbackTreatment(response);
    }
}