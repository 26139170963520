import React from "react";

const TruncatedText = ({ description, onClick }) => {
  // Limite de caracteres para truncar
  const charLimit = 30;

  // Conteúdo truncado
  const truncatedText =
    description.length > charLimit
      ? description.slice(0, charLimit)
      : description;

  return (
    <div style={{ verticalAlign: "middle" }}>
      {truncatedText}
      {description.length > charLimit && (
        <span
          style={{ color: "blue", cursor: "pointer", marginLeft: "5px" }}
          onClick={() => onClick(description)} // Apenas chama a função
        >
        ...
        </span>
      )}
    </div>
  );
};

export default TruncatedText;
