import React from "react";
import "./CardDetailsBusiness.css";
import Anchor from "../../components/general/Anchor";
import ProgressLine from "./ProgressLine";

export default function CardDetailsBusiness (props)
{
    const {id, item, value, name, progress} = props.data;

    return (
        <div className={`cardDetailsBusiness ${props.classaditional}`} {...props}>
            <div className="row no-gutters lineCardDetailsBusiness">
                <div className="col-2 columnCardDetailsBusiness columnIconCardDetailsBusiness">
                    <i className="far fa-dollar-sign iconCardDetailsBusiness big"/>
                </div>
                <div className="col-10 columnCardDetailsBusiness">
                    <div className="moreCardDetailsBusiness">
                        <Anchor 
                            label={item}
                            classaditional="anchorCardDetailsBusiness"
                            onClick={() =>{
                                window.open(`/business/details/${id}`)
                            }}
                        />
                        <span className="detailItem">R$&nbsp;{value}</span>
                    </div>
                </div>
            </div>
            <div className="row no-gutters lineCardDetailsBusiness">
                <div className="col-2 columnCardDetailsBusiness columnIconCardDetailsBusiness">
                    <i className="fas fa-user-alt iconCardDetailsBusiness"></i>
                </div>
                <div className="col-10 columnCardDetailsBusiness">
                    <div className="moreCardDetailsBusiness">
                        <Anchor
                            label={name}
                            classaditional="anchorNameCardDetailsBusiness"
                            onClick={() => {
                                window.open(`/business/details/${id}`)
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="row no-gutters lineCardDetailsBusiness"> 
                <div className="col-2 columnCardDetailsBusiness columnIconCardDetailsBusiness">
                    <i className="far fa-chart-bar iconCardDetailsBusiness"></i>
                </div>
                <div className="col-10 columnCardDetailsBusiness"> 
                    <ProgressLine 
                        step={progress - 1} 
                        onChangeStep={async (value) => {
                            alert("Somente visualização!");
                        }} 
                        classaditional="progressLineDetailsBusiness"/>
                </div>
            </div>
        </div>
    )
}