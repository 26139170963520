import React, { Component } from "react";
import "./Activities.css";
import { connect } from "react-redux";
import { 
    formatedDateTimeToUSA, 
    getDatesOfTheWeek, 
    getDateTimeCurrentBrowserAtMidnight, 
    getFullNameFisrtCharsUppercase, 
    getOptionsSelectCheckedFormated,
    sortInAlphabeticalOrder,
    validDate
} from "../../auxiliary/generalFunctions";
import { showModalMoreActions } from "../../store/actions/general";
import {
    PHONE_TYPE_ACTIVITY,
    VISIT_TYPE_ACTIVITY,
    DEADLINE_TYPE_ACTIVITY,
    MAIL_TYPE_ACTIVITY,
    LUNCH_TYPE_ACTIVITY,
    TASK_TYPE_ACTIVITY,
    HISTORIC_TYPE_ACTIVITY,
    APPLICATION_NAME,
    STATUS_BUSINESS_GAIN,
    STATUS_BUSINESS_LOSS,
    STATUS_BUSINESS_PAUSED,
    TRANSFER_TYPE_ACTIVITY,
    ID_BRAND_NOT_VERIFY_DATE_START_VISIT
} from "../../core/constants";
import { 
    getAllActivities, 
    changeTaskActivities, 
    changeStatusGetFromDatabaseCompletedActivities, 
    toggleCheckedAllActivities 
} from "../../store/actions/activities";
import { 
    changeStatusAllSpecialistsUpdatedServiceQueue, 
    setLastPositionSpecialistServiceQueue,
    setFrozenSpecialistServiceQueue
} from "../../store/actions/queue";

import Header from "../../components/CRM/Header";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import TableEditActivities from "../../components/general/TableEditActivities";
import SelectMulti from "../../components/general/SelectMulti";
import Menus from "../../components/general/Menus";
import Message from "../../components/general/Message";
import InputGeneral from "../../components/general/InputGeneral";
import Select from "../../components/general/Select";

import UserController from "../../controllers/UserController";
import ActivityController from "../../controllers/ActivityController";
import BusinessController from "../../controllers/BusinessController";
import ClientController from "../../controllers/ClientController";
import ProspectionTeamHierarchyController from "../../controllers/ProspectionTeamHierarchyController";
import MessageConfirmation from "../../components/general/MessageConfirmation";
import { Fragment } from "react";

const initialState = {
    allActivities: [],
    allActivitiesNoFiltered: [],
    allActivitiesChecked: false, //false as default
    allActivitiesUpdated: false,
    allActivitiesInitialLoaded: false,
    getAllActivitiesOnDatabase: false,
    hasOneActivitiesChecked: false,

    optionsSelectFrom : [
        {
            id: 0,
            name: "Telefone",
            value: PHONE_TYPE_ACTIVITY,
            checked: false
        },
        {
            id: 1,
            name: "Visita",
            value: VISIT_TYPE_ACTIVITY,
            checked: false
        },
        {
            id: 2,
            name: "Prazo",
            value: DEADLINE_TYPE_ACTIVITY,
            checked: false
        },
        {
            id: 3,
            name: "E-mail",
            value: MAIL_TYPE_ACTIVITY,
            checked: false
        },
        {
            id: 4,
            name: "Almoço",
            value: LUNCH_TYPE_ACTIVITY,
            checked: false
        },
        {
            id: 5,
            name: "Tarefa",
            value: TASK_TYPE_ACTIVITY,
            checked: false
        },
        {
            id: 6,
            name: "Informação",
            value: HISTORIC_TYPE_ACTIVITY,
            checked: false
        },
        {
            id: 7,
            name: "Transferência",
            value: TRANSFER_TYPE_ACTIVITY,
            checked: false
        },
    ],

    //start filter by types
    menusTypes: [
        {
            option: 0,
            checked: false,
            name: "Informação",
            value: HISTORIC_TYPE_ACTIVITY,
            icon: "far fa-clock"
        },
        {
            option: 1,
            checked: true,
            name: "Visita",
            value: VISIT_TYPE_ACTIVITY,
            icon: "fas fa-user-friends"
        },
        {
            option: 2,
            checked: false,
            name: "Tarefa",
            value: TASK_TYPE_ACTIVITY,
            icon: "fas fa-thumbtack",
        },
        {
            option: 3,
            checked: false,
            name: "Ligar",
            value: PHONE_TYPE_ACTIVITY,
            icon: "fas fa-phone-alt"
        },
        {
            option: 4,
            checked: false,
            name: "Transferência",
            value: TRANSFER_TYPE_ACTIVITY,
            icon: "fas fa-exchange-alt"
        },
    ],
    menusTypesActive: 0,
    // ends filter by types

    // start filter by intervals
    menusIntervals: [
        {
            option: 0,
            name: "Todos",
            value: "Todos",
            icon: ""
        },
        {
            option: 1,
            name: "Para Fazer",
            value: "Para Fazer",
            icon: ""
        },
        {
            option: 2,
            name: "Vencido",
            value: "Vencido",
            icon: ""
        },
        {
            option: 3,
            name: "Hoje",
            value: "Hoje",
            icon: ""
        },
        {
            option: 4,
            name: "Amanhã",
            value: "Amanhã",
            icon: ""
        },
        {
            option: 5,
            name: "Esta Semana",
            value: "Esta Semana",
            icon: ""
        },
        {
            option: 6,
            name: "Próxima Semana",
            value: "Próxima Semana",
            icon: ""
        },
        {
            option: 7,
            name: "Selecione o Período",
            value: "Selecione o Período",
            icon: ""
        },
    ],
    menusIntervalsActive: 1,
    // ends filter by intervals

    optionsSelectStatus: [
        {
            name: "Em Andamento",
            value: "OPEN",
            checked: false
        },
        {
            name: "Ganhos",
            value: STATUS_BUSINESS_GAIN,
            checked: false
        },
        {
            name: "Perdidos",
            value: STATUS_BUSINESS_LOSS,
            checked: false
        },
        {
            name: "Pausados",
            value: STATUS_BUSINESS_PAUSED,
            checked: false
        },
    ],
    optionsChosenStatusBusiness: {
        name: "Em Andamento",
        value: "OPEN"
    },

    // start filter by specific 
    showDropDownIntervals: false,
    dateStartIntervals: null,
    dateEndsIntervals: null,
    filterDisabledIntervals: false,
    // ends filter by specific

    optionsOwnersLead: [],
    optionsUnitCompany: [],

    // start modal message
    message_type: "information",
    message_show: false,
    message: "",

    message_show_confirmation_showedup: false,
    data_to_update_showedup: null,

    message_show_confirmation_undo_showedup: false,
    data_to_undo_showedup: null,
    type_undo_showedup: "REOPEN", // REOPEN or CHANGE
    // ends modal message

    // start about service queue
    idCurrentCompanyUnit: 2,
    allSpecialistsNotFiltered: [],
    optionOwnerSpecialistOrManagers: [], //WARNING: just one specialist in the array
    objManagerByCompanyUnit: {},
    // ends about service queue


    // start prospection
    optionsAttendants: [],
    // ends prospection
}

const userController = new UserController();
const activityController = new ActivityController();
const businessController = new BusinessController();
const clientController = new ClientController();
const prospectionTeamHierarchyController = new ProspectionTeamHierarchyController();

class Activities extends Component
{
    state = { ...initialState }

    componentDidMount = async () =>
    {
        document.title = `${APPLICATION_NAME} - Atividades`;
        await this.setState({
            dateStartIntervals: formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight()),
            dateEndsIntervals: formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight())
        });
        
        await this.setCurrentCompanyUnit();
        await this.getCompanyUnit();
        await this.getOwners();
        await this.getFirstOwnerAtQueueByCompanyUnit();
        await this.getManagerByCompanyUnit();
        await this.applyFilters();
        await this.getAttendantsForFilters();
    }

    componentDidUpdate = async (prevProps, prevState) =>
    {
        // console.log("ACTIVITIES prevProps ", prevProps);
        // console.log("ACTIVITIES this.props ", this.props);

        // WARNING: for getting initials activities and from database.
        // if 
        // (
        //     this.state.allActivities.length === 0 && 
        //     this.props.allActivities.length > 0 && 
        //     !this.state.allActivitiesInitialLoaded
        // )
        // {
        //     let allActivitiesNotFiltered = this.props.allActivities;
        //     let allActivities = await this.applyFilters(allActivitiesNotFiltered);
        //     this.hasAllActivitiesChecked(allActivities);
        //     await this.setState({allActivities, allActivitiesNotFiltered, allActivitiesInitialLoaded: true});

        // }
        
        // WARNING: for getting update activities and global state (redux).
        if (this.state.allActivitiesUpdated)
        {
            await this.setState({allActivitiesUpdated: false});
            await this.applyFilters();

            // let allActivitiesNotFiltered = this.props.allActivities;
            // let allActivities = await this.applyFilters(allActivitiesNotFiltered);
            // this.hasAllActivitiesChecked(allActivities);
            // await this.setState({allActivities, allActivitiesNotFiltered, allActivitiesUpdated: false});
        }

        // DANGER: waiting when it receives the ACTIVITIES from the database and is ready in props (redux).
        if (prevProps.getAllActivitiesFromDatabaseCompleted !== undefined)
        {
            if (!prevProps.getAllActivitiesFromDatabaseCompleted && this.props.getAllActivitiesFromDatabaseCompleted)
            {
                await this.setState({allActivitiesUpdated: true});
            }
        }

        // getting allSpecialists of the service queue, for each change in the queue.
        if (this.props.queue.allSpecialistsNotFilteredHasUpdated && this.props.queue.allSpecialistsNotFiltered.length > 0)
        {
            await this.props.changeStatusAllSpecialistsUpdatedServiceQueue(false);
            await this.getFirstOwnerAtQueueByCompanyUnit();
            await this.getManagerByCompanyUnit();
        }

    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            await this.props.changeTaskActivities({[propertyName]: newValue, id, propertyName});
        }
    }

    verifyDateMinAndDateMax = (type, clientCompanyUnit) =>
    {
        let dateMin = null;
        let dateMax = null;
        let verify = true;

        if (type === VISIT_TYPE_ACTIVITY)
        {
            const companyUnit = this.props.user.unit_companies.find(u => u.UNT_ID === clientCompanyUnit);

            if (companyUnit?.BNT_ID === ID_BRAND_NOT_VERIFY_DATE_START_VISIT) {
                verify = false;
            }

            if (verify)
            {
                dateMin = getDateTimeCurrentBrowserAtMidnight();
                dateMin = formatedDateTimeToUSA(dateMin);

                dateMax = getDateTimeCurrentBrowserAtMidnight();

                const indexFromDay = dateMax.getDay();

                if (indexFromDay === 6)
                {
                    dateMax.setDate(dateMax.getDate() + 2);
                }
                else
                {
                    dateMax.setDate(dateMax.getDate() + 1);
                }

                dateMax = formatedDateTimeToUSA(dateMax);
            }
        }

        return {
            dateMin,
            dateMax,
            verify
        }

    }

    saveValue = async (id, field, value, idActivity = null) =>
    {

        if (value === "")
        {
            await this.props.changeStatusGetFromDatabaseCompletedActivities(false);
            await this.applyFilters();
            return this.message("error", "Este campo não pode ficar em branco!");
        }

        // WARNING: for maintenance.
        // console.log(`id: ${id}, field: ${field}, value: ${value}`);
        let result = {
            status: true,
        }

        //WARNING:  other fields that do not belong to TB_ACTIVITIES
        switch (field) 
        {
            case "PER_NAME":
            case "PER_PHONE_ONE":
                result = await clientController.update(id, {
                    [field]: value
                });
                break;
            case "BUS_TITLE":
            case "BUS_ID_PERSON_OWNER":
            case "BUS_MONEY_VALUE":

                if (field === "BUS_MONEY_VALUE") { value = value.replace(".", "").replace(",", "."); }

                if (field === "BUS_ID_PERSON_OWNER")
                {
                    const resultBusiness = await businessController.getPendingBusinessFromUnits(this.props.user.PER_ID);

                    if(resultBusiness.data.number_of_lines > 0)
                    {
                        const idsBusiness = resultBusiness.data.data.map(b => b.BUS_ID).join(",");
                        return this.message("error", `Existem Negócios pendentes para dar ganho ou perdido em sua unidade! (${idsBusiness})`);
                    }

                    let idPersonClient = 0;
                    let idBusiness = id;
                    let idsBusinessSameClient = [];
                    let results = [];
                    let idOwnerChoosen = value;
                    let allActivities = await this.state.allActivities;
                    let objManagerByCompanyUnit = await this.state.objManagerByCompanyUnit;
                    
                    // getting the id person client...
                    allActivities.forEach(a => {
                        if (a.business.id === idBusiness) { idPersonClient = a.business.idUser; }
                    });

                    // getting all business by same client
                    allActivities.forEach(a => {
                        if (a.business.idUser === idPersonClient)
                        { idsBusinessSameClient.push(a.business.id); }
                    });

                    // saving the choosen owner for all business of the client
                    for (let idBusiness of idsBusinessSameClient)
                    {
                        result = await businessController.update(idBusiness, { "BUS_ID_PERSON_OWNER": idOwnerChoosen });
                        if (result.status) { results.push(idBusiness); }
                    }
                    
                    // DANGER: communicating to all sockets, except when the chosen owner is the unit manager, as he is not part of the service queue.
                    if (results.length > 0 && objManagerByCompanyUnit.id !== idOwnerChoosen) { this.props.setLastPositionSpecialistServiceQueue(idOwnerChoosen); }
                    
                    const annotations = `● Adicionou ${this.state.optionOwnerSpecialistOrManagers.find(s => s.id === value).name} como proprietário.`;

                    await this.applyFilters();
                    const activity = this.state.allActivities.find(a => a.id === idActivity);
            
                    await activityController.create({
                        ACT_TITLE: "INFORMAÇÃO",
                        ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
                        ACT_ANNOTATIONS: annotations.toUpperCase(),
                        ACT_ID_BUSINESS: activity.business.id,
                        ACT_ID_PERSON_CLIENT: activity.business.idUser,
                        ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
                        ACT_FINISHED: 1,
                        hasFile: false,
                        file: null
                    });

                    await activityController.update(idActivity, {
                        ACT_IS_SHOWEDUP: 1
                    });

                    if (activity)
                    {
                        if (activity.isShowedup !== 1)
                        {
                            await activityController.create({
                                ACT_TITLE: "INFORMAÇÃO",
                                ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
                                ACT_ANNOTATIONS: "● CLIENTE COMPARECEU.",
                                ACT_ID_BUSINESS: activity.business.id,
                                ACT_ID_PERSON_CLIENT: activity.business.idUser,
                                ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
                                ACT_FINISHED: 1,
                                hasFile: false,
                                file: null
                            });
                        }
                    }

                }
                else
                {                    
                    result = await businessController.update(id, {
                        [field]: value
                    });
                }

                break;
            default:
                if (field === "ACT_DATE_START" && !validDate(value.toString()))
                {
                    await this.props.changeStatusGetFromDatabaseCompletedActivities(false);
                    await this.applyFilters();
                    this.message("error", `Data inválida!`);
                    return;
                }
                if (field === "ACT_DATE_START")
                {
                    const activity = this.state.allActivities.find(a => a.id === id);
                    
                    if (activity.type === VISIT_TYPE_ACTIVITY) 
                    {
                        const { dateMin, dateMax, verify } = this.verifyDateMinAndDateMax(activity.type, activity.company.id);
                        if (verify && (activity.dateStart !== dateMin && activity.dateStart !== dateMax))
                        {
                            console.log(dateMin, dateMax, verify);
                            await this.props.changeStatusGetFromDatabaseCompletedActivities(false);
                            await this.applyFilters();
                            return this.message("error", "Data inválida!");
                        }

                        const daysToInvalidate = 4;
                        const createdAt = new Date(`${activity.createdAt.split(" ")[0]} 12:00:00`);
                        createdAt.setDate(createdAt.getDate() + daysToInvalidate);

                        const newDateStart = new Date(`${value} 12:00:00`);

                        if (newDateStart > createdAt) 
                        {
                            await this.props.changeStatusGetFromDatabaseCompletedActivities(false);
                            await this.applyFilters();
                            this.message("error", `Opsssss, esta VISITA não pode mais ser prorrogada por ter sido criada há mais de ${daysToInvalidate} dias, por favor, agende uma nova visita.!`);
                            return;
                        }

                    }

                }
                result = await activityController.update(id, {
                    [field]: value
                });

                if (field === "ACT_IS_SHOWEDUP")
                {
                    const activityData = this.state.allActivities.find(a => a.id === id);

                    await activityController.create({
                        ACT_TITLE: "INFORMAÇÃO",
                        ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
                        ACT_ANNOTATIONS: "● CLIENTE NÃO COMPARECEU.",
                        ACT_ID_BUSINESS: activityData.business.id,
                        ACT_ID_PERSON_CLIENT: activityData.business.idUser,
                        ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
                        ACT_FINISHED: 1,
                        hasFile: false,
                        file: null
                    });

                    await activityController.update(id, {
                        ACT_FINISHED: 1
                    });

                    this.message("success", `Atividade marcada como "Cliente não compareceu"!`);

                }   

                break;
        }

        if (!result.status) 
        {
            this.message("error", result.message);
        }

        await this.props.changeStatusGetFromDatabaseCompletedActivities(false);
        await this.applyFilters();
    }

    setCurrentCompanyUnit = async () =>
    {
        let companies = this.props.user.unit_companies;
        await this.setState({idCurrentCompanyUnit: companies[0].UNT_ID});
    }

    setOwnersByUnitCompanyActive = async (optionsUnitCompany) =>
    {
        let optionsOwnersLead = await this.state.optionsOwnersLeadNotFiltered;
        let optionsUnitCompanyActive = optionsUnitCompany.filter(u => u.checked);
        let fromAllUnitCompany = (optionsUnitCompanyActive.length === 0) ? true : false;
        
        if (!fromAllUnitCompany)
        {
            optionsOwnersLead = optionsOwnersLead.filter(o => {
                let hasUitCompany = false;

                optionsUnitCompanyActive.forEach(u => {
                    if (o.unitCompanies.some(uo => uo.UNT_ID === u.value)) { hasUitCompany = true; }
                });

                if (hasUitCompany) { return o; }

                return false;
            });
        }

        await this.setState({optionsOwnersLead});
    }

    getCompanyUnit = async () => 
    {
        let allCompanyUnitsAllowedUser = await this.props.user.unit_companies;
        let allCompanyUnits = [];

        for (const company of allCompanyUnitsAllowedUser)
        {
            allCompanyUnits.push({
                name: company.UNT_NAME,
                value: company.UNT_ID,
                checked: false
            });
        }
                
        await this.setState({optionsUnitCompany: allCompanyUnits});
    }

    getOwners = async () => 
    {
        let optionsOwnersLead = [];
        let responseOwners = await userController.getOwnersForUserOnAccess(this.props.user.PER_ID);

        if (responseOwners.status)
        {
            let unitCompanies = responseOwners.data.data;

            for (let unit of unitCompanies)
            {
                let idUnitCompany = unit.UNT_ID;
                let nameUnitCompany = unit.UNT_NAME;
                let owners = unit.owners;

                for (let owner of owners)
                {
                    optionsOwnersLead.push({
                        id: owner.PER_ID,
                        name: getFullNameFisrtCharsUppercase(owner.PER_NAME),
                        value: owner.PER_ID,
                        checked: false,
                        unitCompanies: [{
                            UNT_ID: idUnitCompany,
                            UNT_NAME: nameUnitCompany
                        }]
                    });
                }                
            }
        }

        optionsOwnersLead = sortInAlphabeticalOrder(optionsOwnersLead);
        await this.setState({ optionsOwnersLead, optionsOwnersLeadNotFiltered: optionsOwnersLead });
    }

    getAttendantsForFilters = async() =>
    {
        const 
            [
                result, 
                user
            ] 
            = await Promise.all(
            [
                await prospectionTeamHierarchyController.get(),
                userController.getById(this.props.user.PER_ID)
            ]
        );

        if (!result.status) 
        {
            return;
        }

        const {coordinators} = result.data.data;
        const userId = this.props.user.PER_ID;
        const userUnits = user.data.unit_companies.map(u => u.UNT_ID);
        
        const optionsAttendants = prospectionTeamHierarchyController.getProspectionOwners(coordinators, userId, this.props.permissions.activities, userUnits);

        await this.setState({
            optionsAttendants
        });
    }

    getFirstOwnerAtQueueByCompanyUnit = async () =>
    {
        let idCompanyUnit = await this.state.idCurrentCompanyUnit;
        let optionOwnerSpecialistOrManagers = [];
        let allSpecialistsNotFiltered = await this.props.queue.allSpecialistsNotFiltered;
        
        // getting just a service queue (of a company unit)
        if (this.props.user.unit_companies.length === 1 && allSpecialistsNotFiltered.length > 0)
        {
            let allSpecialists = [];
    
            allSpecialistsNotFiltered.forEach(u => {
                if (u.idCompanyUnit === idCompanyUnit)
                {
                    u.specialists.forEach(s => {
    
                        if (s.frozen === 2) //just the not-frozen specialists.
                        {
                            allSpecialists.push({
                                id: s.id,
                                name: getFullNameFisrtCharsUppercase(s.name),
                                value: s.id,
                                checked: false
                            });
                        }
                    });
                }
            });

            optionOwnerSpecialistOrManagers.push(allSpecialists[0]);

            await this.setState({
                allSpecialistsNotFiltered,
                optionOwnerSpecialistOrManagers
            });          
        }
    }

    getManagerByCompanyUnit = async () =>
    {
        let optionOwnerSpecialistOrManagers = await this.state.optionOwnerSpecialistOrManagers || [];
        let idCurrentCompanyUnit = await this.state.idCurrentCompanyUnit;
        let objManagerByCompanyUnit = {};

        let responseManager = await userController.getManagerOfCompanyUnit(idCurrentCompanyUnit);

        if (responseManager.status && responseManager.data !== null && responseManager.data !== undefined)
        {
            let manager = responseManager.data;
            manager = {
                id: manager.PER_ID,
                name: manager.PER_NAME,
                value: manager.PER_ID,
                checked: false
            };
            optionOwnerSpecialistOrManagers.push(manager);
            objManagerByCompanyUnit = manager;
    
            await this.setState({optionOwnerSpecialistOrManagers, objManagerByCompanyUnit});
        }

    }

    applyFilters = async () => 
    {
        let filters = {
            USER_ID: this.props.user.PER_ID,
            BUS_STATUS: this.state.optionsChosenStatusBusiness.value,
        };

        switch (this.state.menusIntervalsActive) 
        {
            case 0:
                // all
                break;

            case 1:
                // to make
                filters.ACT_FINISHED = 2;
                break;

            case 2:
                // all overdue activities
                let yesterday = getDateTimeCurrentBrowserAtMidnight();
                yesterday.setDate(yesterday.getDate() - 1);
                yesterday = formatedDateTimeToUSA(yesterday);
                filters.FINAL_DATE = yesterday;
                filters.ACT_FINISHED = 2;
                break;

            case 3:
                // today
                const today = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight());
                filters.INITIAL_DATE = today;
                filters.FINAL_DATE = today;
                filters.ACT_FINISHED = 2;
                break;

            case 4:
                //tomorrow
                let tomorrow = getDateTimeCurrentBrowserAtMidnight();
                tomorrow.setDate(tomorrow.getDate() + 1);
                tomorrow = formatedDateTimeToUSA(tomorrow);
                filters.INITIAL_DATE = tomorrow;
                filters.FINAL_DATE = tomorrow;
                filters.ACT_FINISHED = 2;
                break;

            case 5:
                // this week
                let datesWeek = getDatesOfTheWeek(getDateTimeCurrentBrowserAtMidnight());
                filters.INITIAL_DATE = formatedDateTimeToUSA(datesWeek[0]);
                filters.FINAL_DATE = formatedDateTimeToUSA(datesWeek[6]);
                filters.ACT_FINISHED = 2;
                break;

            case 6:
                // next week
                let thisWeek = getDatesOfTheWeek(getDateTimeCurrentBrowserAtMidnight());
                let nextWeekStart = thisWeek[0];
                nextWeekStart.setDate(nextWeekStart.getDate() + 7);
                nextWeekStart = formatedDateTimeToUSA(nextWeekStart);
                let nextWeekEnds = thisWeek[6];
                nextWeekEnds.setDate(nextWeekEnds.getDate() + 7);
                nextWeekEnds = formatedDateTimeToUSA(nextWeekEnds);
                filters.INITIAL_DATE = nextWeekStart;
                filters.FINAL_DATE = nextWeekEnds;
                filters.ACT_FINISHED = 2;
                break;

            case 7:
                // custom interval
                filters.INITIAL_DATE = this.state.dateStartIntervals;
                filters.FINAL_DATE = this.state.dateEndsIntervals;
                break;

            default:
                break;
        } 

        const optionsAttendants = this.state.optionsAttendants.filter(o => o.checked).map(o => o.value);
        const optionsOwners = this.state.optionsOwnersLead.filter(o => o.checked).map(o => o.value);
        const optionsCompanyUnits = this.state.optionsUnitCompany.filter(o => o.checked).map(o => o.value);
        const optionsTypes = this.state.menusTypes.filter(o => o.checked).map(o => o.value);

        optionsAttendants.length > 0 && (filters.PROSPECTION_OWNERS = optionsAttendants);
        optionsOwners.length > 0 && (filters.OWNERS = optionsOwners);
        optionsCompanyUnits.length > 0 && (filters.PER_ID_COMPANY_UNIT = optionsCompanyUnits);
        optionsTypes.length > 0 && (filters.ACT_TYPE = optionsTypes);

        await this.getActivites(filters);
        await this.setState({ allActivities: this.props.allActivities, allActivitiesNotFiltered: this.props.allActivities });
        await this.hasAllActivitiesChecked();
    }

    hasAllActivitiesChecked = async () =>
    {
        let allActivities = this.state.allActivities;
        let allActivitiesChecked = true;
        let hasOneActivitiesChecked = false;

        if (allActivities.length > 0)
        {
            allActivities.forEach(d => {
                if (!d.checked) allActivitiesChecked = false;
                if (d.checked) hasOneActivitiesChecked = true;
            });
        }
        else
        {
            allActivitiesChecked = false;
        }
        
        await this.setState({allActivitiesChecked, hasOneActivitiesChecked});
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    getActivites = async (filters) =>
    {
        let payload = [];
        const result = await activityController.getByFilters(filters);

        if (result.status)
        {
            for (const activity of result.data.data) 
            {
                let owner = null;
                
                if (activity.BUS_ID_PERSON_OWNER != null)
                {
                    owner = {
                        id: activity.BUS_ID_PERSON_OWNER,
                        name: getFullNameFisrtCharsUppercase(activity.OWNER_NAME),
                        value: activity.BUS_ID_PERSON_OWNER
                    }
                }

                let ownerPermissions = {...this.props.permissions.leadOwners};
                if ((owner === null && ownerPermissions.toInsert === true) || (owner !== null && ownerPermissions.toUpdate === true))
                {
                    ownerPermissions.toUpdate = true;
                } 
                else
                {
                    ownerPermissions.toUpdate = false;
                }

                payload.push({
                    id: activity.ACT_ID,
                    task: activity.ACT_TITLE,
                    type: activity.ACT_TYPE, //phone, meeting , deadline (prazo), mail, lunch (almoço) OR task.
                    dateStart: activity.ACT_DATE_START, //more on: https://pt.stackoverflow.com/questions/408160/bug-no-javascript-que-subtrai-dia-em-1-e-adiciona-hor%C3%A1rio-em-mesesdias-com-2-d
                    timeStart: activity.ACT_TIME_START,
                    dateEnds: activity.ACT_DATE_ENDS,
                    timeEnds: activity.ACT_TIME_ENDS,
                    dateFinished: null,
                    annotations: activity.ACT_ANNOTATIONS,
                    finished: parseInt(activity.ACT_FINISHED) === 1 ? true : false,
                    checked: false,
                    isShowedup: activity.ACT_IS_SHOWEDUP,
                    createdAt: activity.ACT_CREATE_DATE,
                    business: {
                        id: activity.BUS_ID,
                        status: activity.BUS_STATUS,
                        title: activity.BUS_TITLE,
                        nameUser: getFullNameFisrtCharsUppercase(activity.PER_NAME),
                        idUser: activity.PER_ID,
                        phoneUser: activity.PER_PHONE_ONE,
                        mailUser: activity.PER_MAIL,
                        money: activity.BUS_MONEY_VALUE
                    },
                    company: {
                        id: activity.PER_ID_COMPANY_UNIT,
                        name: activity.UNT_NAME
                    },
                    owner,
                    ownerPermissions
                });
            };
        }

        await this.props.getAllActivities(payload);
    }

    onCheckActivity = async (id, type, finished, idBusiness, idClient) =>
    {
        if (!this.props.permissions.activities.toUpdate)
        {
            return this.message("error", "Você não tem permissão para editar a atividade!");
        }

        if ([HISTORIC_TYPE_ACTIVITY, TRANSFER_TYPE_ACTIVITY].includes(type))
        {
            return this.message("error", "As atividades do tipo HISTÓRICO e TRANSFERÊNCIA não podem ser reabertas!");
        }

        if (type === VISIT_TYPE_ACTIVITY && finished === 1)
        {
            await this.setState({
                message_show_confirmation_showedup: true,
                data_to_update_showedup: {
                    id,
                    finished,
                    idBusiness,
                    idClient
                }
            });
        }
        else
        {
            const resultActivities = await activityController.getByBusiness(this.props.user.PER_ID, idBusiness);
            const activities = resultActivities.data.data;
            if (activities.some(a => a.ACT_TYPE === VISIT_TYPE_ACTIVITY && a.ACT_ID > id)) {
                return this.message("error", "Esta visita não pode ser reaberta, existe uma visita mais recente!");
            }

            if (type === VISIT_TYPE_ACTIVITY && finished === 2)
            {
                const activity = this.state.allActivities.find(a => a.id === id);
                let typeModal = "REOPEN";
                const today = getDateTimeCurrentBrowserAtMidnight();
                const activityDate = new Date(`${formatedDateTimeToUSA(activity.dateStart)} 00:00`);

                if (activityDate < today)
                {
                    if (activity.isShowedup === 2)
                    {
                        return this.message("error", "Esta visita está no passado e não pode ser reaberta!");
                    }
                    typeModal = "CHANGE";
                }

                return await this.setState({
                    message_show_confirmation_undo_showedup: true,
                    type_undo_showedup: typeModal,
                    data_to_undo_showedup: {
                        id,
                        finished,
                        idBusiness,
                        idClient
                    }
                });
            }

            await activityController.update(id, {
                ACT_FINISHED: finished,
                ACT_IS_SHOWEDUP: null
            });

            this.applyFilters();
        }
    }

    updateActivityShowedup = async (showedup) =>
    {
        await this.applyFilters();
        const activityToUpdate = this.state.allActivities.find(a => a.id === this.state.data_to_update_showedup.id);

        await activityController.update(this.state.data_to_update_showedup.id, {
            ACT_FINISHED: this.state.data_to_update_showedup.finished,
            ACT_IS_SHOWEDUP: showedup
        });

        if (activityToUpdate)
        {
            if (activityToUpdate.isShowedup !== showedup)
            {
                await activityController.create({
                    ACT_TITLE: "INFORMAÇÃO",
                    ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
                    ACT_ANNOTATIONS: showedup === 2 ? "● CLIENTE NÃO COMPARECEU." : "● CLIENTE COMPARECEU.",
                    ACT_ID_BUSINESS: this.state.data_to_update_showedup.idBusiness,
                    ACT_ID_PERSON_CLIENT: this.state.data_to_update_showedup.idClient,
                    ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
                    ACT_FINISHED: 1,
                    hasFile: false,
                    file: null
                });
            }
        }

        await this.setState({
            message_show_confirmation_showedup: false,
            data_to_update_showedup: null
        });

        this.applyFilters();
    }

    undoShowedup = async () =>
    {
        await activityController.update(this.state.data_to_undo_showedup.id, {
            ACT_IS_SHOWEDUP: this.state.type_undo_showedup === "REOPEN" ? null : 2,
            ACT_FINISHED: this.state.type_undo_showedup === "REOPEN" ? 2 : 1
        });

        await activityController.create({
            ACT_TITLE: "INFORMAÇÃO",
            ACT_TYPE: HISTORIC_TYPE_ACTIVITY,
            ACT_ANNOTATIONS: this.state.type_undo_showedup === "REOPEN" ? `● VISITA REABERTA.` : `● CLIENTE NÃO COMPARECEU.`,
            ACT_ID_BUSINESS: this.state.data_to_undo_showedup.idBusiness,
            ACT_ID_PERSON_CLIENT: this.state.data_to_undo_showedup.idClient,
            ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
            ACT_FINISHED: 1,
            hasFile: false,
            file: null
        });

        await this.setState({
            message_show_confirmation_undo_showedup: false,
            data_to_undo_showedup: null,
            type_undo_showedup: "REOPEN"
        });

        this.applyFilters();
    }

    render ()
    {
        return (
            <div className="activities">
                <Header title="Atividades" classaditional="headerActivities" />
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <MessageConfirmation
                    message_type="information"
                    message="Este cliente compareceu?"
                    onClose={() => this.setState({message_show_confirmation_showedup: false, data_to_update_showedup: null})}
                    onClickYes={() => this.updateActivityShowedup(1)}
                    onClickNo={() => this.updateActivityShowedup(2)}
                    show={this.state.message_show_confirmation_showedup}
                >
                </MessageConfirmation>
                <MessageConfirmation
                    message_type="information"
                    message=""
                    onClose={() => this.setState({message_show_confirmation_undo_showedup: false, data_to_undo_showedup: null, type_undo_showedup: "CHANGE"})}
                    onClickYes={() => this.undoShowedup()}
                    onClickNo={() => this.setState({message_show_confirmation_undo_showedup: false, data_to_undo_showedup: null, type_undo_showedup: "CHANGE"})}
                    show={this.state.message_show_confirmation_undo_showedup}
                >
                    {
                        this.state.type_undo_showedup === "REOPEN" ?
                            <p>Deseja reabrir visita?</p>
                        :
                            <Fragment>
                                <p>Esta VISITA está no passado e não pode ser reaberta!</p>
                                <p>Esta VISITA está marcada como "COMPARECEU". Deseja alterar para "NÃO COMPARECEU"?</p>
                            </Fragment>
                    }
                </MessageConfirmation>
                {/* <div className="warningActivities d-flex d-lg-none">
                    <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                </div> */}
                <div className="containerNavigationHeaderActivities">
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderActivities ${!this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: false});
                        }}
                    />
                    {/* RESERVED */}
                    {/* <Button
                        icon="fal fa-plus"
                        name="&nbsp;&nbsp;Button"
                        classaditional="buttonPlusNavigationHeaderActivities"
                        onClick={async () => {}}
                    /> */}

                    {/* RESERVED */}
                    {/* {
                        this.state.hasOneActivitiesChecked &&
                        <ButtonIcon
                            icon="fas fa-trash"
                            classaditional="buttonTrashNavigationHeaderActivities"
                            onClick={() => this.message("success", "nothing...")}
                        />
                    } */}
                    <div className={`filterHeaderActivities one`}>
                        <i className="fas fa-building iconfilterHeaderActivities"></i>
                        <SelectMulti
                            classaditional="selectFilterHeaderActivities"
                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsUnitCompany, "[Todas Unidades]", "unidades")}}
                            options={this.state.optionsUnitCompany} 
                            onChange={async (optionsUnitCompany) => {
                                await this.setState({optionsUnitCompany});
                                await this.setOwnersByUnitCompanyActive(optionsUnitCompany);
                                this.setState({allActivitiesUpdated: true});
                            }}
                            withFieldSearch
                        />
                    </div>
                    <div className="filterHeaderActivities">
                        <i className="fas fa-user-friends iconfilterHeaderActivities"></i>
                        <SelectMulti
                            classaditional="selectFilterHeaderActivities"
                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsAttendants, "[Todos Atendentes]", "atendentes")}}
                            options={this.state.optionsAttendants} 
                            onChange={(optionsAttendants) => {
                                this.setState({optionsAttendants});
                                this.setState({allActivitiesUpdated: true});
                            }}
                            withFieldSearch
                        />
                    </div>
                    {
                        this.props.permissions.leadOwners.toView &&
                        <div className="filterHeaderActivities">
                            <i className="fas fa-user-friends iconfilterHeaderActivities"></i>
                            <SelectMulti
                                classaditional="selectFilterHeaderActivities"
                                default={{name: getOptionsSelectCheckedFormated(this.state.optionsOwnersLead, "[Todos Proprietários]", "proprietários")}}
                                options={this.state.optionsOwnersLead} 
                                onChange={(optionsOwnersLead) => {
                                    this.setState({optionsOwnersLead});
                                    this.setState({allActivitiesUpdated: true});
                                }}
                                withFieldSearch
                            />
                        </div>
                    }
                    <div className={`filterHeaderActivities filterStatusHeaderActivities`}>
                        <i className="fas fa-info iconfilterStatusHeaderActivities"></i>
                        <Select
                            classaditional="selectStatusHeaderActivities"
                            default={this.state.optionsChosenStatusBusiness}
                            options={this.state.optionsSelectStatus}
                            onChange={async (optionsChosenStatusBusiness) => {
                                this.setState({optionsChosenStatusBusiness})
                                this.setState({allActivitiesUpdated: true})                                
                            }}
                        />
                    </div>
                    {/* RESERVED */}
                    {/* <div className="filterHeaderActivities">
                        <i className="fas fa-tag iconfilterHeaderActivities"></i>
                        <SelectMulti
                            classaditional="selectFontHeaderActivities "
                            default={{name: this.getCompanyUnitsCheckedFormated(this.state.optionsSelectFrom)}}
                            options={this.state.optionsSelectFrom} 
                            onChange={(optionsSelectFrom) => {
                                this.setState({optionsSelectFrom, optionsChosenOwnersLead: optionsSelectFrom});
                            }}
                        />
                    </div> */}
                </div>
                <div className="containerTypesAndIntervalsNavigationHeaderActivities" >
                    <div className="row no-gutters w-100">
                        <div className="col-md-12 col-md-12 col-lg-12 col-xl-5">
                            <Menus
                                classaditional="p-md-2 p-lg-0 menusTypesAndIntervalsActivities types"
                                menus={this.state.menusTypes}
                                active={this.state.menusTypesActive}
                                areMultipleActive
                                onChange={async (option) => {
                                    let menusTypes = this.state.menusTypes.map(m => {
                                            if (m.option === option) { m.checked = !m.checked; }
                                            return m;
                                        });
                                    await this.setState({menusTypes, allActivitiesUpdated: true});
                                }}
                            />
                        </div>
                        <div className="col-md-12 col-lg-7">
                            <Menus
                                classaditional="p-md-2 p-lg-0 menusTypesAndIntervalsActivities intervals"
                                menus={this.state.menusIntervals}
                                active={this.state.menusIntervalsActive}
                                onChange={(option) => {
                                    this.setState({menusIntervalsActive: option, showDropDownIntervals: false, allActivitiesUpdated: true});
                                    if (option === 7) this.setState({showDropDownIntervals:  !this.state.showDropDownIntervals})
                                }}
                            />
                            {
                                this.state.showDropDownIntervals &&
                                <div className="dropIntervalsHeaderActivities">
                                    <div className="row no-gutters lineBodyDropIntervalsHeaderActivities">
                                        <div className="col-12 col-md-6 colOne">
                                            <InputGeneral
                                                label="Data Inicial"
                                                type="date" 
                                                onChange={(e) => this.setState({dateStartIntervals: e.target.value})}
                                                value={this.state.dateStartIntervals}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 colTwo">
                                            <InputGeneral
                                                label="Data Final"
                                                type="date" 
                                                onChange={(e) => this.setState({dateEndsIntervals: e.target.value})}
                                                value={this.state.dateEndsIntervals}
                                            />
                                        </div>
                                    </div>
                                    <div className="row no-gutters lineFooterDropIntervalsHeaderActivities">
                                        <Button
                                            classaditional="buttonIntervalsHeaderActivities"
                                            name="Limpar"
                                            onClick={async () => {
                                                await this.setState({
                                                    showDropDownIntervals: false, 
                                                    filterDisabledIntervals: true,
                                                    dateStartIntervals: formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight()),
                                                    dateEndsIntervals: formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight()),
                                                    allActivitiesUpdated: true
                                                });
                                            }}
                                        />
                                        <Button
                                            classaditional="buttonIntervalsHeaderActivities positive"
                                            name="Aplicar"
                                            onClick={async () => {
                                                await this.setState({filterDisabledIntervals: false, allActivitiesUpdated: true});
                                            }}
                                        />                  
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="containerListHeaderActivities">
                    <TableEditActivities
                        data={this.state.allActivities}
                        allActivitiesChecked={this.state.allActivitiesChecked}
                        onToggleCheckedAllActivities={async (status) => {
                            await this.props.toggleCheckedAllActivities(status);
                            await this.hasAllActivitiesChecked();
                        }}
                        onChangeValue={this.changeValue}
                        onSaveValue={this.saveValue}
                        onIgnoreSave={async () => {
                            // WARNING: setting the allActivities state to an empty array, in order to retrieve data from the database, thus ignoring eventual changes in the global state (redux).
                            await this.props.changeStatusGetFromDatabaseCompletedActivities(false);
                            await this.applyFilters();
                        }}
                        permissions={this.props.permissions}
                        optionsOwners={this.state.optionOwnerSpecialistOrManagers}
                        hasAllActivitiesChecked={this.hasAllActivitiesChecked} 
                        onCheckActivity={this.onCheckActivity} 
                        onClickShowedup={async (id, finished, idBusiness, idClient) => {
                            await this.setState({
                                message_show_confirmation_showedup: true,
                                data_to_update_showedup: {
                                    id,
                                    finished,
                                    idBusiness,
                                    idClient
                                }
                            });
                        }}
                        verifyDateMinAndDateMax={this.verifyDateMinAndDateMax}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { allActivities, getAllActivitiesFromDatabaseCompleted } = state.activities;
    const permissions = state.permissions;
    const { user } = state.auth;
    const queue = state.queue;

    return {
        allActivities,
        getAllActivitiesFromDatabaseCompleted,
        permissions,
        user,
        queue
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        changeTaskActivities (mode) 
        {
            //action creator -> action
            const action = changeTaskActivities(mode);
            dispatch(action);
        },
        getAllActivities (data)
        {
            //action creator -> action
            const action = getAllActivities(data);
            dispatch(action);
        },
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        changeStatusGetFromDatabaseCompletedActivities (data)
        {
            //action creator -> action
            const action = changeStatusGetFromDatabaseCompletedActivities(data);
            dispatch(action);
        },
        toggleCheckedAllActivities (status) 
        {
            //action creator -> action
            const action = toggleCheckedAllActivities(status);
            dispatch(action);
        },
        changeStatusAllSpecialistsUpdatedServiceQueue (status) 
        {
            //action creator -> action
            const action = changeStatusAllSpecialistsUpdatedServiceQueue(status);
            dispatch(action);
        },
        setLastPositionSpecialistServiceQueue (data) 
        {
            //action creator -> action
            const action = setLastPositionSpecialistServiceQueue(data);
            dispatch(action);
        },
        setFrozenSpecialistServiceQueue (data) 
        {
            //action creator -> action
            const action = setFrozenSpecialistServiceQueue(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities);