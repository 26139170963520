import React, { Component } from "react";
import "./UnitCompanyForm.css";
import InputGeneral from "../general/InputGeneral";
import { formatedOnlyNumber, formatedPhoneAndCellphone } from "../../auxiliary/generalFunctions";
import { newUnitCompany, getAllUnitCompany } from "../../store/actions/company";
import Button from "../general/Button";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import CompanyUnitController from "../../controllers/CompanyUnitController";

const initialState = {
    // start content name
    name: "",
    searchingName: false,
    warningName: false,
    noticesName: [],
    validName: true,
    // ends content name     

    // start content phone
    phone: "",
    searchingPhone: false,
    warningPhone: false,
    noticesPhone: [
        {
            label: "Unidade de São Paulo/SP",
            input: "(11)1111-2222",
        },
        {
            label: "Unidade de Londrina/PR",
            input: "(43)1111-2222",
        },        
        {
            label: "Unidade de São Paulo/SP",
            input: "(11)1111-2222",
        },
        {
            label: "Unidade de Londrina/PR",
            input: "(43)1111-2222",
        },        
        {
            label: "Unidade de São Paulo/SP",
            input: "(11)1111-2222",
        },
        {
            label: "Unidade de Londrina/PR",
            input: "(43)1111-2222",
        },        
        {
            label: "Unidade de São Paulo/SP",
            input: "(11)1111-2222",
        },
        {
            label: "Unidade de Londrina/PR",
            input: "(43)1111-2222",
        },        
    ],
    noticesClickedPhone: "",
    validPhone: true,
    // ends content phone 
}

const companyUnitController = new CompanyUnitController();

class UnitCompanyForm extends Component
{
    state = {...initialState}

    constructor(props) {
        super(props);
        window.handleWarningClickedPhoneUnitCompanyForm = this.handleWarningClickedPhoneUnitCompanyForm;
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        }
        this.props.showModalMoreActions(data);
    }

    nameChangeAndChecking = (name) =>
    {
        this.setState({
            searchingName: true, 
            warningName: false, 
            validName: true
        });
        this.setState({name});

        setTimeout(() => {
            this.setState({searchingName: false});
        }, 1000);
    }

    phoneChangeAndChecking = (phone) =>
    {
        this.setState({
            searchingPhone: true, 
            warningPhone: false, 
            validPhone: phone.toString().length < 13 ? false : true,
            phone: formatedPhoneAndCellphone(phone)
        });

        setTimeout(() => {
            this.setState({
                searchingPhone: false, 
                warningPhone: phone.length < 13 ? true : false,
                validPhone: phone.toString().length < 13 ? false : true,
            });
        }, 1000);
    }

    onActionForm = async () => 
    {
        let {name, phone} = this.state;
        if (name.toString().trim() === "")
        {
            return alert("Informe um nome!");
        }
        if (phone.toString().trim() === "" || phone.toString().length < 13)
        {
            return alert("Informe um telefone!");
        }

        const data = {
            UNT_NAME: name,
            UNT_PHONE: formatedOnlyNumber(phone)
        };

        const result = await companyUnitController.create(data);

        if (!result.status)
        {
            return alert(result.message);
        }

        this.props.getAllUnitCompany();

        this.closeModalMoreActions();

    }

    handleWarningClickedPhoneUnitCompanyForm = (phone) => 
    {
        this.setState({phone});
    }

    render () 
    {
        return (
            <div className="unitCompanyForm"> 
                <div className="row no-gutters lineUnitCompanyForm">
                    <div className="col-12 columnUnitCompanyForm">
                        <InputGeneral
                            label="Nome"
                            value={this.state.name}
                            onChange={e => this.nameChangeAndChecking(e.target.value)}
                            searching={this.state.searchingName.toString()}
                            warning={this.state.warningName.toString()}
                            warningcontent={this.state.noticesName}
                            mandatory="true"
                            valid={this.state.validName.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineUnitCompanyForm">
                    <div className="col-12 columnUnitCompanyForm">
                        <InputGeneral
                            label="Telefone"
                            value={this.state.phone}
                            onChange={e => this.phoneChangeAndChecking(e.target.value)}
                            searching={this.state.searchingPhone.toString()}
                            warning={this.state.warningPhone.toString()}
                            // warningcontent={this.state.noticesPhone}
                            // warningclicked="handleWarningClickedPhoneUnitCompanyForm"
                            mandatory="true"
                            valid={this.state.validPhone.toString()}
                            maxLength="14"
                        />
                    </div>
                </div>
                <div className="row no-gutters lineUnitCompanyForm">
                    <div className="col-12 columnUnitCompanyForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterUnitCompanyForm">
                    <Button
                        classaditional="buttonUnitCompanyForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions()}
                        />
                    <Button
                        classaditional="buttonUnitCompanyForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                    />                  
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { allUnitCompany } = state.company;

    return {
        allUnitCompany 
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        },
        newUnitCompany (data)
        {
            //action creator -> action
            const action = newUnitCompany(data);
            dispatch(action);
        },
        getAllUnitCompany ()
        {
            //action creator -> action
            const action = getAllUnitCompany();
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitCompanyForm);