import React from "react";
import "./Menus.css";
import Button from "../general/Button";

// DEFAULT/RESERVED
// WARNING: menus configuration when areMultipleActive is defined.
// const menus = [
//     {
//         option: 0,
//         name: "Menu 1",
//         icon: "far fa-sticky-note"
//     },
//     {
//         option: 1,
//         name: "Menu 2",
//         icon: "far fa-sticky-note"
//     },
//     {
//         option: 2,
//         name: "Menu 3",
//         icon: "far fa-sticky-note"
//     },
// ]

// WARNING: menus configuration when areMultipleActive is not defined.
// const menus = [
    // {
    //     option: 0,
    //     checked: false,
    //     name: "Ligar",
    //     icon: "fas fa-phone-alt"
    // },
    // {
    //     option: 1,
    //     checked: true,
    //     name: "Visita",
    //     icon: "fas fa-user-friends"
    // },
// ]

export default function Menus (props)
{

    return (
        <div className={`menus ${props?.classaditional}`}>
            {
                !props.areMultipleActive && props.menus.map((m, i) => {
                    return (
                        <Button
                            key={`${m.name}${i}`}
                            classaditional={`buttonMenus ${m.option === props.active ? "active" : ""}`}
                            icon={(props.withoutIcon) ? null : m.icon}
                            name={(props.withoutLabel) ? null : m.name}
                            onClick={() => props.onChange(m.option)}
                        />
                    )
                })
            }

            {
                props.areMultipleActive && props.menus.map((m, i) => {
                    return (
                        <Button
                            key={`${m.name}${i}`}
                            classaditional={`buttonMenus ${m.checked ? "active" : ""}`}
                            icon={(props.withoutIcon) ? null : m.icon}
                            name={(props.withoutLabel) ? null : m.name}
                            onClick={() => props.onChange(m.option)}
                        />
                    )
                })
            }
        </div>
    )
}
