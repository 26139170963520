import React, { Component } from "react";
import "./ServiceQueue.css";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { ReactSortable } from "react-sortablejs"; // more information in: https://github.com/SortableJS/react-sortablejs
import socketIOClient from "socket.io-client";
import Header from "../../components/CRM/Header";
import Box from "../../components/general/Box";
import ButtonIcon from "../../components/general/ButtonIcon";
import MessageConfirmation from "../../components/general/MessageConfirmation";
import Select from "../../components/general/Select";
import InputGeneral from "../../components/general/InputGeneral";

import { APPLICATION_NAME, EVENT_SERVICE_QUEUE_ALL, EVENT_SERVICE_QUEUE_UPDATED } from "../../core/constants";
import { baseURLSocket } from "../../configuration";
import { changeServiceQueue } from "../../store/actions/queue";
import { setModalMessage } from "../../store/actions/general";
import { formatedDateTime, getFullNameFisrtCharsUppercase } from "../../auxiliary/generalFunctions";

import CompanyUnitController from "../../controllers/CompanyUnitController";
import ServiceQueuePersonsController from "../../controllers/ServiceQueuePersonsController";
import ServiceQueueReasonsForChangeFrozenController from "../../controllers/ServiceQueueReasonsForChangeFrozenController";

const CHOSEN_COMPANY_UNIT = {
    name: "Campo Grande/MS",
    value: 4,
};
const initialState = {

    allSpecialists: [
        // {
        //  id: 1,
        //  name: "Paulo Fernandes",
        //  frozen: 2,
        //  position: 1,
        //  idCompanyUnit: 1
        // }
    ],
    allSpecialistsNotFiltered: [
    // {
    //     idCompanyUnit: 1,
    //     specialists: [
    //         {
    //             id: 1,
    //             name: "Paulo Fernandes",
    //             frozen: 2,
    //             position: 1,
    //             idCompanyUnit: 1
    //         }
    //     ]
    // }
    ],
    optionsCompanyUnit: [],
    choosenCompanyUnit: {...CHOSEN_COMPANY_UNIT},
    idCurrentCompanyUnit: CHOSEN_COMPANY_UNIT.value,
    idsAllowedCompanyUnit: [],
    idCurrentPersonUser: 0,
    savingAllSpecialists: false,
    historicFrozen: [],

    // start field for reason for change status frozen
    showFieldReasonForChangeFrozen: false,
    textFieldReasonForChangeFrozen: "",
    // ends field for reason for change status frozen

    // start message confirmation modal
    message_type_confirmation: "information",
    message_show_confirmation: false,
    message_confirmation: "",
    statusConfirmation: false,
    nameFunctionYesModalConfirmation: "",
    objectConfirmation: {},
    // ends message confirmation modal

}

const companyUnitController = new CompanyUnitController();
const serviceQueuePersonsController = new ServiceQueuePersonsController();
const serviceQueueReasonsForChangeFrozenController = new ServiceQueueReasonsForChangeFrozenController();

var socket;
class ServiceQueue extends Component
{
    state = {...initialState}
    
    componentDidMount = async () => 
    {   
        socket = socketIOClient(baseURLSocket);
        document.title = `${APPLICATION_NAME} - Fila de Atendimento (especialistas)`;

        await this.setState({idCurrentPersonUser: this.props.user.PER_ID});
        await this.setCurrentCompanyUnit();
        await this.getCompanyUnit();
        await this.getHistoricFrozen();

        socket.emit(EVENT_SERVICE_QUEUE_UPDATED);
        socket.on(EVENT_SERVICE_QUEUE_ALL, async (allSpecialists) => {
            let allSpecialistsNotFiltered = allSpecialists;
            let idCompanyChosen = await this.state.choosenCompanyUnit.value
            allSpecialists = allSpecialistsNotFiltered.filter(u => u.idCompanyUnit === idCompanyChosen)[0].specialists;
            this.setState({ allSpecialists, allSpecialistsNotFiltered });
            this.props.changeServiceQueue(allSpecialistsNotFiltered);
        });
    }
    
    componentDidUpdate = async (prevProps) =>
    {
        // console.log("SERVICE QUEUE prevState ", prevState);
        // console.log("SERVICE QUEUE this.state ", this.state.allSpecialists);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}
    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }

    messageConfirmation = (type, message) =>
    {
        this.setState({
            message_type_confirmation: type,
            message_confirmation: message,
            message_show_confirmation: true
        });
    }

    onClickYesConfirmation =  () =>
    {
        let fn = this.state.nameFunctionYesModalConfirmation;

        this.setState({
            statusConfirmation: true,
            message_show_confirmation: false,
            message_confirmation: "",
            message_type_confirmation: "information"
        });

        switch (fn) 
        {
            case "onDeleteSpecialist":
                this.onDeleteSpecialist();
                break;

            case "onChangeFrozenSpecialist":
                this.onChangeFrozenSpecialist();
                break;
        
            default:
                break;
        }
    }

    onClickNoConfirmation = () =>
    {
        this.setState({
            statusConfirmation: false,
            objectConfirmation: {},
            message_show_confirmation: false,
            message_confirmation: "",
            message_type_confirmation: "information"
        });
    }

    setCurrentCompanyUnit = async () =>
    {
        let companies = this.props.user.unit_companies;
        let idsAllowedCompanyUnit = [];
        
        for (let unit of companies)
        {
            idsAllowedCompanyUnit.push(unit.UNT_ID);
        }
        
        await this.setState({idsAllowedCompanyUnit, idCurrentCompanyUnit: idsAllowedCompanyUnit[0]});
    }

    getCompanyUnit = async () => 
    {
        let optionsCompanyUnit = [];
        let idCurrentCompanyUnit = await this.state.idCurrentCompanyUnit;
        let idsAllowedCompanyUnit = await this.state.idsAllowedCompanyUnit;
        let choosenCompanyUnit = {...CHOSEN_COMPANY_UNIT};

        const result = await companyUnitController.get();

        if (result.status) 
        {
            for (const company of result.data.data)
            {
                if (company.UNT_STATUS === 1 && idsAllowedCompanyUnit.includes(company.UNT_ID))
                {
                    optionsCompanyUnit.push({
                        name: company.UNT_NAME,
                        value: company.UNT_ID,
                        checked: false
                    });

                    if (company.UNT_ID === idCurrentCompanyUnit)
                    {
                        choosenCompanyUnit.name = company.UNT_NAME;
                        choosenCompanyUnit.value = company.UNT_ID;
                    }
                }
            }
        }

        await this.setState({optionsCompanyUnit, choosenCompanyUnit});
    }

    getHistoricFrozen = async () =>
    {
        let idCurrentCompanyUnit = this.state.idCurrentCompanyUnit;
        let responseHistoric = await serviceQueueReasonsForChangeFrozenController.getByCompanyUnit(idCurrentCompanyUnit);
        let historicFrozen = [];

        if (responseHistoric.status)
        {
            let histories = responseHistoric.data.data || []; 
            for (let historic of histories)
            {
                let obj = {
                    date: formatedDateTime(historic.SEC_DATE_CREATED.toString()),
                    time: "",
                    status: historic.SEC_FINAL_FROZEN,
                    specialist: historic.SEC_PERSON_NAME,
                    whoChanged: historic.SEC_PERSON_WHO_CHANGED_NAME,
                    title: historic.SEC_TITLE
                };
                historicFrozen.push(obj);
            }

        }
        await this.setState({historicFrozen});
    }

    onChangePositionSpecialist = async (oldPosition, newPosition) =>
    {
        oldPosition = oldPosition + 1;
        newPosition = newPosition + 1;
        let currentFronzen = null;
        let amountNotFrozenSpecialists = 0;
        let allSpecialistsNotFiltered = await this.state.allSpecialistsNotFiltered;
        let idCurrentCompanyUnit = await this.state.idCurrentCompanyUnit;
        let idSpecialistUpdatePosition = 0;

        // searching for the ID of the specialist who had a position change.
        allSpecialistsNotFiltered.forEach(u => {
            if (u.idCompanyUnit === idCurrentCompanyUnit)
            {
                u.specialists.forEach(s => {
                    if (s.position === oldPosition)
                    {
                        idSpecialistUpdatePosition = s.id;
                        currentFronzen = s.frozen;
                    }

                    if (s.frozen === 2)
                    {
                        amountNotFrozenSpecialists++;
                    }
                });
            }
        });

        // do not allow positioning of frozen or non-frozen specialists higher than allowed.
        if (currentFronzen === 2 && (newPosition <= amountNotFrozenSpecialists))
        {
            let responseUpdatePositions = await serviceQueuePersonsController.updatePosition({
                "SEP_ID_PERSON": idSpecialistUpdatePosition,
                "SEP_POSITION": newPosition
            });
    
            if (responseUpdatePositions.status) { socket.emit(EVENT_SERVICE_QUEUE_UPDATED); }
        }

        await this.getHistoricFrozen();
    }

    onChangeFrozenSpecialist = async () =>
    {
        let obj = await this.state.objectConfirmation;
        let status = (parseInt(obj.currentFronzen) === 2) ? "congelar" : "descongelar"; //WARNING: here is inverse.

        if (!this.state.statusConfirmation)
        {
            await this.setState({showFieldReasonForChangeFrozen: true});
            this.messageConfirmation("warning", `Você deseja realmente ${status}? Se sim, por favor, indique o motivo no campo abaixo.`);
        }
        else
        {
            let reason = await this.state.textFieldReasonForChangeFrozen;
            let idCurrentPersonUser = await this.state.idCurrentPersonUser;
            let idCurrentCompanyUnit = await this.state.idCurrentCompanyUnit;
            let idSpecialist = obj.idSpecialist;
            let currentFronzen = obj.currentFronzen;
            let finalFronzen = parseInt((currentFronzen === 1) ? 2 : 1);

            if (reason.toString().trim() === "" || reason.toString().length < 4)
            {
                this.messageConfirmation("error", `Informe o motivo pelo qual você deseja ${status}.`);
                return;
            }
            
            // recording the reason for the freeze status change.
            let responseReason = await serviceQueueReasonsForChangeFrozenController.create({
                "SEC_TITLE"                 : reason,
                "SEC_FINAL_FROZEN"          : finalFronzen,
                "SEC_ID_PERSON"             : idSpecialist,
                "SEC_ID_COMPANY_UNIT"       : idCurrentCompanyUnit,
                "SEC_ID_PERSON_WHO_CHANGED" : idCurrentPersonUser
            });

            if (!responseReason.status)
            {
                this.messageConfirmation("error", `Opsssss, tivemos algum problema ao registrar a mudança para ${status}. Se o problema persistir, contacte o time de desenvolvimento.`);
                return;
            }

            let allSpecialists = await this.state.allSpecialists;
            let amountThawingSpecialists = 0;
            let finalPosition = (allSpecialists.length); 

            // if thawing...
            if (finalFronzen === 2)
            {
                allSpecialists.forEach(s => {
                    if (s.frozen === 2) { amountThawingSpecialists++; }
                });
                finalPosition = (amountThawingSpecialists + 1);
            }
            
            // changing the position first.
            let responseUpdatePositions = await serviceQueuePersonsController.updatePosition({
                "SEP_ID_PERSON": idSpecialist,
                "SEP_POSITION": finalPosition
            });

            // informing all sockets of the position update.
            if (responseUpdatePositions.status) { socket.emit(EVENT_SERVICE_QUEUE_UPDATED); }

            // changing the frozen status.
            let responseUpdateFrozen = await serviceQueuePersonsController.update(idSpecialist, {
                "SEP_FROZEN": finalFronzen
            });

            // informing all sockets of the frozen status update.
            if (responseUpdateFrozen.status) { socket.emit(EVENT_SERVICE_QUEUE_UPDATED); }

            await this.setState({
                statusConfirmation: false,
                objectConfirmation: {},
                showFieldReasonForChangeFrozen: false,
                textFieldReasonForChangeFrozen: ""
            });
            
        }

        await this.getHistoricFrozen();
    }

    onDeleteSpecialist = async () =>
    {
        let obj = await this.state.objectConfirmation;

        if (!this.state.statusConfirmation)
        {
            this.messageConfirmation("warning", `Deseja realmente excluir ${obj.name} da fila de atendimento?`);
        }
        else
        {
            this.message("information", `OK, vamos excluir ${obj.name}`);

            let responseDeletePosition = await serviceQueuePersonsController.deletePosition(obj.id);

            if (responseDeletePosition.status)
            {
                socket.emit(EVENT_SERVICE_QUEUE_UPDATED);

                await this.setState({
                    statusConfirmation: false,
                    objectConfirmation: {}
                });
            }
            else
            {
                this.message("error", "Opsssss, não conseguimos excluir o especialista! Se o erro persistir contacte o time de desenvolvimento!");
            }
        }
    }

    render ()
    {
        return (
            <div className="serviceQueue">
                <Header title="Fila de Atendimento (especialistas)" classaditional="headerNavigationServiceQueue"/>
                <MessageConfirmation
                    message_type={this.state.message_type_confirmation}
                    message={this.state.message_confirmation}
                    onClose={() => this.setState({message_show_confirmation: false})}
                    show={this.state.message_show_confirmation}
                    onClickYes={() => this.onClickYesConfirmation()}
                    onClickNo={() => this.onClickNoConfirmation()}
                >
                    {
                        this.state.showFieldReasonForChangeFrozen &&
                        <div>
                            <InputGeneral 
                                value={this.state.textFieldReasonForChangeFrozen}
                                onChange={(e) => this.setState({textFieldReasonForChangeFrozen: e.target.value})}
                            />
                            <br />
                        </div>
                    }
                </MessageConfirmation>
                <div className="scrollServiceQueue mb-4">
                    <div className="warningServiceQueue d-flex d-md-none">
                        <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                    </div>
                    <div className="container headerServiceQueue">
                        <Box>
                            <h4>Fila de Atendimento (especialistas)</h4>
                        </Box>
                    </div>
                    <div className="container bodyServiceQueue d-none d-md-block">
                        <Box>
                            <div className="row no-gutters mb-4 lineCompanyUnitServiceQueue">
                                <div className="col-12 col-lg-4"></div>
                                <div className="col-12 col-lg-4 pl-2 pr-2">
                                    <div className="filterBodyServiceQueue d-none d-md-flex">
                                        <i className="fas fa-building iconfilterBodyServiceQueue"></i>
                                        <Select
                                            classaditional="selectFilterBodyServiceQueue"
                                            default={this.state.choosenCompanyUnit}
                                            options={this.state.optionsCompanyUnit} 
                                            onChange={async (choosenCompanyUnit) => {
                                                let idChoosenCompanyUnit = choosenCompanyUnit.value;
                                                let allSpecialists = await this.state.allSpecialistsNotFiltered.filter(u => u.idCompanyUnit === idChoosenCompanyUnit)[0].specialists;
                                                await this.setState({choosenCompanyUnit, allSpecialists, idCurrentCompanyUnit: idChoosenCompanyUnit});
                                                this.getHistoricFrozen();
                                            }}
                                            withFieldSearch
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4"></div>
                            </div>
                            <div className="row no-gutters lineTitleHeaderServiceQueue">
                                <div className="col-1 columnTitleHeaderServiceQueue">
                                    <span></span>
                                </div>
                                <div className="col-1 columnTitleHeaderServiceQueue">
                                    <span>Posição</span>
                                </div>
                                <div className="col-3 columnTitleHeaderServiceQueue">
                                    <span>Especialista</span>
                                </div>
                                <div className="col-2 columnTitleHeaderServiceQueue">
                                    <span>Status</span>
                                </div>
                                <div className="col-3 columnTitleHeaderServiceQueue">
                                    <span>Unidade</span>
                                </div>
                                <div className="col-2 columnTitleHeaderServiceQueue">
                                    <span></span>
                                </div>
                            </div>
                            {
                                this.props.permissions.toView &&
                                <div>
                                    <ReactSortable
                                        list={this.state.allSpecialists}
                                        setList={(listChanged) => {}}
                                        onUpdate={(e) => {
                                            if (this.props.permissions.toUpdate)
                                            {
                                                this.onChangePositionSpecialist(e.oldIndex, e.newIndex);
                                            }
                                        }}
                                    >
                                        {
                                            this.state.allSpecialists.map((o, i) => {
                                                let nameCompanyUnit = this.state.optionsCompanyUnit.filter(u => u.value === o.idCompanyUnit)[0].name;

                                                return (
                                                    <div key={`${o.id}`}>
                                                        <div 
                                                            className={
                                                                `row no-gutters lineBodyServiceQueue 
                                                                    ${i} 
                                                                    ${(i === (this.state.allSpecialists.length - 1 ))? "final" : ""}
                                                                    ${o.frozen === 1 ? "frozen" : ""}
                                                                `}
                                                        >
                                                            <div className="col-1 columnBodyServiceQueue">
                                                                <i className="fas fa-bars"></i>
                                                            </div>
                                                            <div className="col-1 columnBodyServiceQueue">
                                                                <span>{o.position}º</span>
                                                            </div>
                                                            <div className="col-3 columnBodyServiceQueue justify-content-start">
                                                                <span>{getFullNameFisrtCharsUppercase(o.name)}</span>
                                                            </div>
                                                            <div className="col-2 columnBodyServiceQueue">
                                                                <span>{o.frozen === 1 ? "Congelado(a)" : "Ativo(a)"}</span>
                                                            </div>
                                                            <div className="col-3 columnBodyServiceQueue companyUnit">
                                                                <span className="partialCompanyUnitServiceQueue">
                                                                    {nameCompanyUnit}
                                                                </span>
                                                                <span className="fullCompanyUnitServiceQueue">
                                                                    {nameCompanyUnit}
                                                                </span>
                                                            </div>
                                                            <div className="col-2 columnBodyServiceQueue d-flex justify-content-end">
                                                                {
                                                                    this.props.permissions.toUpdate &&
                                                                    <ButtonIcon
                                                                        icon={`${(o.frozen === 1) ? "fas fa-icicles" : "fas fa-smile-wink "}`}
                                                                        classaditional="buttonBodyConfiServiceQueue"
                                                                        onClick={async () => {
                                                                            await this.setState({
                                                                                objectConfirmation: {
                                                                                    idSpecialist: o.id,
                                                                                    currentFronzen: o.frozen
                                                                                },
                                                                                nameFunctionYesModalConfirmation: "onChangeFrozenSpecialist"
                                                                            });
                                                                            this.onChangeFrozenSpecialist();
                                                                        }}
                                                                    />
                                                                }
                                                                {
                                                                    this.props.permissions.toDelete &&
                                                                    <ButtonIcon
                                                                        icon="fas fa-trash"
                                                                        classaditional="buttonBodyConfiServiceQueue"
                                                                        onClick={async () => {
                                                                            await this.setState({
                                                                                objectConfirmation: {
                                                                                    id: o.id,
                                                                                    name: o.name
                                                                                },
                                                                                nameFunctionYesModalConfirmation: "onDeleteSpecialist"
                                                                            });
                                                                            this.onDeleteSpecialist();
                                                                        }}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </ReactSortable>
                                </div>
                            }
                        </Box>
                    </div>
                    <div className="container footerServiceQueue d-none d-md-block">
                        <Box>
                            <div className="row no-gutters lineTitleFooterServiceQueue">
                                <div className="col-3 columnTitleFooterServiceQueue">
                                    <span>Data e Hora</span>
                                </div>
                                <div className="col-3 columnTitleFooterServiceQueue">
                                    <span>Especialista</span>
                                </div>
                                <div className="col-3 columnTitleFooterServiceQueue">
                                    <span>Alterado por</span>
                                </div>
                                <div className="col-3 columnTitleFooterServiceQueue">
                                    <span>Motivo</span>
                                </div>
                            </div>
                            {
                                this.state.historicFrozen.map((r, j) => {
                                    return (
                                        <div 
                                            key={`${j}`}
                                            className={`
                                                row no-gutters lineFooterServiceQueue 
                                                ${(r.status === 2) ? "success" : "error"}
                                            `}
                                        >
                                            <div className="col-3 columnFooterServiceQueue">
                                                <span>{r.date}</span>
                                            </div>
                                            <div className="col-3 columnFooterServiceQueue">
                                                <span>{r.specialist}</span>
                                            </div>
                                            <div className="col-3 columnFooterServiceQueue">
                                                <span>{r.whoChanged}</span>
                                            </div>
                                            <div className="col-3 columnFooterServiceQueue reason">
                                                <span className="partialReasonServiceQueue">
                                                    <span>"{r.title}"</span>
                                                </span>
                                                <span className="fullReasonServiceQueue">
                                                    <span>"{r.title}"</span>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const permissions = state.permissions.serviceQueue;
    const user = state.auth.user;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        permissions,
        user,
        hasPermissionsLoaded
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
        changeServiceQueue (data) 
        {
            //action creator -> action
            const action = changeServiceQueue(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceQueue));