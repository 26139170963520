import {
    NEW_ATTENDANT_ABSENCE
} from "../../core/constants";

const initialState = {

    newAttendantAbsence: false,
}

export default function campaignReducer (state = initialState, action)
{
    switch (action.type) 
    {
        case NEW_ATTENDANT_ABSENCE:
            
            return {
                ...state,
                newAttendantAbsence: action.payload
            }

        default:
            return state
    }
}