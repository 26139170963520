import React, { useEffect, useState } from "react";

import IntegrationsController from "../../controllers/IntegrationsController";
import Button from "../general/Button";

const integrationsController = new IntegrationsController();
const axios = require('axios');

export default function FacebookLogin() 
{
	const [loggedIn, setLoggedIn] = useState(false);

	useEffect(() => 
	{
		window.fbAsyncInit = () => {
			window.FB.init({appId: "2576839769373443", xfbml: true, version: "v19.0"});

			window.FB.AppEvents.logPageView();
			window.FB.getLoginStatus((response) => {
				if (response.status === "connected") 
				{ setLoggedIn(true); }
			});
		};
		
		loadFacebookSDK(document, "script", "facebook-jssdk");
	}, []);

	function loadFacebookSDK(d, s, id)
	{
		let js,
		fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) { return; }
		js = d.createElement(s);
		js.id = id;
		js.src = "https://connect.facebook.net/en_US/sdk.js";
		fjs.parentNode.insertBefore(js, fjs);
	}

	function getPageAccessToken(userAcessToken, userID) 
	{
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `https://graph.facebook.com/v19.0/${userID}/accounts?access_token=${userAcessToken}`
		};
		
		axios.request(config)
		.then((response) => {
			const data = response.data.data;
			data.forEach(page => {
				integrationsController.post(
					{
						"ING_ID_INTEGRATION": "1",
						"ING_ID_TARGET": page.id,
						"ING_NAME_TARGET": page.name,
						"ING_TOKEN_TARGET": page.access_token
					}
				);
				
				verifyPage(page.access_token, page.id);
				getFormsFromPage(page.access_token, page.id);
			});
		})
		.catch((error) => { console.log(error); });
	}

	function getUserLongAccessToken(userAccessToken) 
	{
		const appID = 2576839769373443;
		const appSecret = '6aeecd59231b8df22a28b6f81e2ab5c9';
	
		return new Promise((resolve, reject) => {
			axios.get(`https://graph.facebook.com/v19.0/oauth/access_token`, {
				params: {
					grant_type: 'fb_exchange_token',
					client_id: appID,
					client_secret: appSecret,
					fb_exchange_token: userAccessToken
				}
			})
			.then(response => {
				if (response.data && response.data.access_token)
				{ resolve(response.data.access_token); } 
				else
				{ reject("Erro ao obter o token de acesso longo."); }
			})
			.catch(error => { reject(error); });
		});
	}

	function getFormsFromPage(userAcessToken, pageID) 
	{
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `https://graph.facebook.com/v19.0/${pageID}/leadgen_forms?access_token=${userAcessToken}`
		};
		
		axios.request(config)
		.then((response) => {
			const data = response.data.data;
			data.forEach(form => {
				integrationsController.saveFormFromPage(
					{
						"INF_ID_INTEGRATION_TARGET": pageID,
						"INF_ID_FORM": form.id,
						"INF_NAME": form.name
					}
				);
			});
		})
		.catch((error) => { console.log(error); });
	}

	function verifyPage(pageAcessToken, pageID) 
	{
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `https://graph.facebook.com/${pageID}/subscribed_apps?subscribed_fields=leadgen&access_token=${pageAcessToken}`
		};
		
		axios.request(config)
		.then((response) => { console.log(response.data); })
		.catch((error) => { console.log(error); });
	}

	async function handleLogin() 
	{
		const PERMISSIONS_SCOPE = 'pages_show_list,ads_management,leads_retrieval,pages_read_engagement,pages_manage_metadata,pages_manage_ads,business_management,public_profile';

		return new Promise((resolve, reject) => {
			window.FB.login(async (response) => {
				if (response.authResponse) 
				{
					setLoggedIn(true);
					const userID = response.authResponse.userID;

					try 
					{
						getUserLongAccessToken(response.authResponse.accessToken)
						.then(longAccessToken => { getPageAccessToken(longAccessToken, userID); })

						console.log("Login com o Facebook foi feito com sucesso.");

						resolve();
					} 
					catch (error) 
					{
						console.error("Erro ao obter token de acesso:", error);
						reject(error);
					}
				} 
				else
				{
					console.log("Login do usuário foi cancelado ou não foi autorizado.");
					reject(new Error("Login do usuário foi cancelado ou não foi autorizado."));
				}
			}, { scope: PERMISSIONS_SCOPE });
		});
	}

	function handleLogout()
	{
		window.FB.logout((response) => {
			setLoggedIn(false);
			console.log("Logged out " + JSON.stringify(response));
		});
	}

	return (
		<div>
			{
				loggedIn 
				?
				(
					<div>
						<p>Você está logado no Facebook!</p>
						<Button name="Logout" onClick={() => handleLogout()} />
					</div>
				) 
				: 
				(
					<Button name="Login com Facebook" onClick={() => handleLogin()} />
				)
			}
		</div>
	);
}
