import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class BusinessServices
{
    async getByIdCompanyUnit(idCompanyUnit) 
    {
        const response = await api.businessServices().getByIdCompanyUnit(idCompanyUnit);
        return apiCallbackTreatment(response);
    }
}