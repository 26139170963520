import React, { Component } from "react";
import "./CommissionReportIntervals.css";
import { connect } from "react-redux";
import {
    formatedDateTimeToUSA,
    getDateTimeCurrentBrowserAtMidnight,
    getFullNameFisrtCharsUppercase,
    getOptionsSelectCheckedFormated
} from "../../auxiliary/generalFunctions";
import {
    APPLICATION_NAME
} from "../../core/constants";
import { 
    getAllCommissionReportIntervals,
    changeStatusGetFromDatabaseCompletedCommissionReportIntervals
} from "../../store/actions/commission";
import { withRouter } from "react-router-dom";

import Header from "../../components/CRM/Header";
import ButtonIcon from "../../components/general/ButtonIcon";
import SelectMulti from "../../components/general/SelectMulti";
import InputGeneral from "../../components/general/InputGeneral";
import TableEditCommissionReportIntervals from "../../components/general/TableEditCommissionReportIntervals";

const initialState = {

    allCommissionReportIntervals: [],
    allCommissionReportIntervalsNotFiltered: [],
    allCommissionReportIntervalsUpdated: false,
    filtered: false,

    optionsUnitCompany: [],

    currentMonthYear: "", //looks like: "2022-04"
}

class CommissionReportIntervals extends Component
{
    state = { ...initialState }

    componentDidMount = async () =>
    {
        document.title = `${APPLICATION_NAME} - Relatório de Comissão`;

        let { params } = this.props.match;
        let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight()); //looks like "2022-04-01"

        if (params?.interval && params.interval !== undefined)  //looks like "2022-04"
        { 
            currentDate = params.interval;
        }

        await this.setCurrentMonthYear(currentDate);
        await this.getCompanyUnit();
        await this.props.getAllCommissionReportIntervals(); //INFORMATION: just Month and Year, as params.
    }

    componentDidUpdate = async (prevProps, prevState) =>
    {
        // console.log("COMMISSION REPORT INTERVALS prevProps ", prevProps);
        // console.log("COMMISSION REPORT INTERVALS this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}

        if (this.props.getAllCommissionReportIntervalsFromDatabaseCompleted)
        {
            this.props.changeStatusGetFromDatabaseCompletedCommissionReportIntervals(false);
            let allCommissionReportIntervalsNotFiltered = this.props.allCommissionReportIntervals || [];
            let allCommissionReportIntervals = allCommissionReportIntervalsNotFiltered;
            await this.setState({allCommissionReportIntervals, allCommissionReportIntervalsNotFiltered});
            await this.applyFilters();
        }
    }

    setCurrentMonthYear = async (currentDate) =>
	{
		let parts = currentDate.toString().split("-");
		let y = parseInt(parts[0]);
		let m = parseInt(parts[1]).toString().padStart(2, '0');
		let currentMonthYear = `${y}-${m}`;
		await this.setState({currentMonthYear});
	}

    getCompanyUnit = async () => 
    {
        let allCompanyUnitsAllowedUser = await this.props.user.unit_companies;
        let optionsUnitCompany = [];

        for (const company of allCompanyUnitsAllowedUser)
        {
            optionsUnitCompany.push(
                {
                    name: company.UNT_NAME,
                    value: company.UNT_ID,
                    checked: false
                }
            );
        }

        await this.setState({optionsUnitCompany});
    }

    applyFilters = async () => 
    {
        let allCommissionReportIntervals = [];
        let allCompanyUnitsAllowedUser = await this.props.user.unit_companies;
        let allCommissionReportIntervalsNotFiltered = await this.state.allCommissionReportIntervalsNotFiltered;
        let currentMonthYearActive = await this.state.currentMonthYear;
        let optionsUnitCompanyActive = await this.state.optionsUnitCompany.filter(u => u.checked);
        let fromAllUnitCompany = (optionsUnitCompanyActive.length === 0) ? true : false;

        allCommissionReportIntervalsNotFiltered.forEach(r => {
            
            if (r.month === currentMonthYearActive && allCompanyUnitsAllowedUser.some(u => u.UNT_ID === r.company.id))
            {
                let report = r;
                r.name = getFullNameFisrtCharsUppercase(r.name);

                if (!fromAllUnitCompany && !optionsUnitCompanyActive.some(c => c.value === r.company.id)) { report = null; }

                if (report){ allCommissionReportIntervals.push(report); }
            }

        });

        await this.setState({allCommissionReportIntervals});
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        // if (propertyName !== null)
        // {
        //     // asking the socket to make the change, as it will be possible to follow the status change even if the page is refreshed.
        //     if (propertyName === "status")
        //     {
        //         let status = newValue;
        //         let allCommissionRules = await this.state.allCommissionRules;
        //         let rule = allCommissionRules.filter(r => r.id === id)[0];
        //         let monthRule = parseInt(rule.month.toString().split("-")[1]);
        //         let monthCurrent = parseInt(formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight()).toString().split("-")[1]);

        //         if (monthRule >= monthCurrent)
        //         {
        //             this.message("error", "Não é possível gerar relatório de comissão dessa regra, apenas para as regras dos meses anteriores ao atual!");
        //             return;
        //         }

        //         // asking to generate...
        //         if (status === 1) //1 = "GENERATED", on view
        //         {
        //             this.props.newReportCommissionRule(id);
        //         }

        //         // asking to generate...
        //         if (status === 3) //3 = "NOT_GENERATED"
        //         {
        //             this.props.deleteReportCommissionRule(id);
        //         }

        //         newValue = 2; //WARNING: to force the LOADING...
        //     }

        //     // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
        //     await this.props.changeCommissionRule({[propertyName]: newValue, id, propertyName});
        //     await this.setState({allCommissionRulesUpdated: true});
        // }
    }

    saveValue = async (id, field, value) =>
    {
        // WARNING: for maintenance.
        // console.log(`id: ${id}, field: ${field}, value: ${value}`);
        // let result = {
        //     status: true,
        // }

        // result = await commissionRulesController.update(id, {
        //     [field]: value
        // });
      
        // if (!result.status) 
        // {
        //     this.message("error", result.message);
        // }

        // await this.props.changeStatusGetFromDatabaseCompletedCommissionRules(false);
        // await this.props.getAllCommissionRules();
    }

    saveCommission = async () =>
    {
        await this.props.getAllCommissionReportIntervals();
    }

    message = (type, message) =>
    {

    }

    render ()
    {
        return (
            <div className="commissionReportIntervals">
                <Header title="Relatório de Comissão - Limpa Nome" classaditional="headerCommissionReportIntervals" />
                <div className="warningCommissionReportIntervals d-flex d-lg-none">
                    <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                </div>
                <div className="containerNavigationHeaderCommissionReportIntervals">
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderCommissionReportIntervals ${!this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: false});
                        }}
                    />
                    <div className="filterHeaderCommissionReportIntervals d-none d-md-flex ml-auto">
                        <i className="fas fa-building iconfilterHeaderCommissionReportIntervals"></i>
                        <SelectMulti
                            classaditional="selectFilterHeaderCommissionReportIntervals"
                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsUnitCompany, "[Todas Unidades]", "unidades")}}
                            options={this.state.optionsUnitCompany} 
                            onChange={async (optionsUnitCompany) => {
                                await this.setState({optionsUnitCompany});
                                await this.applyFilters();
                            }}
                            withFieldSearch
                        />
                    </div>
                    <div className={`d-none d-lg-flex ml-2`}>
                        <InputGeneral
                            type="month"
                            value={this.state.currentMonthYear}
                            onChange={async (e) => {
                                await this.setCurrentMonthYear(e.target.value);
                                await this.applyFilters();
                            }}
                        />
                    </div>
                </div>
                <div className="containerListHeaderCommissionReportIntervals">
                    <TableEditCommissionReportIntervals
                        data={this.state.allCommissionReportIntervals}
                        onChangeValue={this.changeValue}
                        onSaveValue={this.saveValue}
                        onIgnoreSave={this.applyFilters}
                        permissions={this.props.permissions}
                        onSaveCommission={this.saveCommission}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;
    const { allCommissionReportIntervals, getAllCommissionReportIntervalsFromDatabaseCompleted } = state.commission;
    const permissions = state.permissions.commissionReportIntervals;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;
    
    return {
        user,
        permissions,
        hasPermissionsLoaded,
        allCommissionReportIntervals,
        getAllCommissionReportIntervalsFromDatabaseCompleted
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        getAllCommissionReportIntervals ()
        {
            //action creator -> action
            const action = getAllCommissionReportIntervals();
            dispatch(action);
        },
        changeStatusGetFromDatabaseCompletedCommissionReportIntervals (status)
        {
            //action creator -> action
            const action = changeStatusGetFromDatabaseCompletedCommissionReportIntervals(status);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommissionReportIntervals));