import React, { useState } from 'react';
import './InputGroup.css';
import ButtonIcon from './ButtonIcon';
import loading from '../../assets/images/loading.png';

export default function InputGroup(props) {
    const [showWarning, setShowWarning] = useState(false);
    const [showClear] = useState(props?.onClear ? true : false);
    var ownProperties = { ...props };
    ownProperties = { ...ownProperties };
    delete ownProperties.onClear;

    return (
        <div className={`inputGroupComponent ${props?.classaditional}`}>
            {props.label && (
                <label className="labelInputGroup">
                    {props.label}
                    {props.mandatory && props.mandatory === 'true' ? ' *' : ''}
                </label>
            )}
            <div className="inputInputGroup">
                <input
                    type="text"
                    className={`form-control ${
                        props?.valid === 'false' ? 'error' : ''
                    }`}
                    placeholder=""
                    aria-label=""
                    aria-describedby="button-addon2"
                    {...ownProperties}
                />
                <button
                    className={`btn buttonInputGroup`}
                    type="button"
                    id="button-addon2"
                    onClick={props.onButtonClick}
                >
                    <i style={{color: props.colorIcon}} className={`${props.icon} iconButtonIcon`}></i>
                </button>
                {showClear && (
                    <ButtonIcon
                        icon="far fa-times"
                        classaditional="buttonClearInputGroup"
                        onClick={props.onClear}
                    />
                )}
                {props?.searching && (
                    <div className="moreInformationSearchInputGroup">
                        {props?.searching === 'true' ? (
                            <img
                                className="img-fluid"
                                src={loading}
                                alt="imagem de loading"
                            />
                        ) : null}

                        {props?.warning === 'true' ? (
                            <ButtonIcon
                                icon="far fa-exclamation-triangle"
                                onClick={(e) => setShowWarning(!showWarning)}
                                classaditional="buttonInformationInputGroup"
                            />
                        ) : null}

                        {props?.warningcontent && showWarning ? (
                            <div className="contentWarningSearchInputGroup">
                                <div className="headerContentWarningInputGroup">
                                    <h3 className="title">Aviso</h3>
                                    <ButtonIcon
                                        icon="fal fa-times"
                                        onClick={() => setShowWarning(false)}
                                        classaditional="buttonCloseContentWarningInputGroup"
                                    />
                                </div>
                                <div className="bodyContentWarningInputGroup">
                                    {props.warningcontent.map((e, i) => (
                                        <div
                                            key={`notice${i}`}
                                            className="notice"
                                        >
                                            <span className="label">
                                                {e.label}
                                            </span>
                                            <span
                                                className="input"
                                                onClick={() => {
                                                    if (
                                                        props.warningclicked !==
                                                            null &&
                                                        props.warningclicked !==
                                                            undefined
                                                    ) {
                                                        window[
                                                            props.warningclicked
                                                        ](e.input);
                                                    }

                                                    setShowWarning(false);
                                                }}
                                            >
                                                {e.input}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        </div>
    );
}
