import ReactDOM from "react-dom";
import React from "react";
import "./index.css";
import App from "./views/general/App";
import { Provider } from "react-redux";
import storeConfig from "./store/storeConfig";

const store = storeConfig();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
)