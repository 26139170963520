import React, { Component } from "react";
import "./AbsenceAttendants.css";
import { connect } from "react-redux";
import { APPLICATION_NAME, absenceAttendants } from "../../core/constants";
import { showModalMoreActions, setModalMessage } from "../../store/actions/general";
import { newAttendantAbsence } from "../../store/actions/absence";
import { withRouter } from "react-router-dom";
import { validDate } from "../../auxiliary/generalFunctions";
import { getDateTimeCurrentBrowserAtMidnight } from "../../auxiliary/generalFunctions";
import { formatedDateTimeToUSA } from "../../auxiliary/generalFunctions";

import Header from "../../components/CRM/Header";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import TableEditAbsenceAttendants from "../../components/general/TableEditAbsenceAttendants";
import InputGeneral from "../../components/general/InputGeneral";

import AbsenceAttendantsController from "../../controllers/AbsenceAttendantsController";
import WorkShiftsPersonController from"../../controllers/WorkShiftsPersonController";
import MessageConfirmation from "../../components/general/MessageConfirmation";

const initialState = {

    allAbsenceAttendants: [],
    allAbsenceAttendantsUpdated: true,
    filtered: false,

    optionsAttendants: [],

    // start filter by specific 
    showDropDownIntervals: false,
    dateStartIntervals: null,
    dateEndsIntervals: null,
    filterDisabledIntervals: false,
    // ends filter by specific

    // start message confirmation modal
    message_type_confirmation: "information",
    message_show_confirmation: false,
    message_confirmation: "",
    statusConfirmation: false,
    nameFunctionYesModalConfirmation: "",
    objectConfirmation: {},
    // ends message confirmation modal

}

const absenceAttendantsController = new AbsenceAttendantsController();
const workShiftsPersonController = new WorkShiftsPersonController();
class AbsenceAttendants extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Faltas (vendedores)`;

        await this.setCurrentDatesForIntervals();
        await this.getAllAttendants();
        await this.getAllAbsenceAttendants();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("ABSENCE ATTENDENTS prevProps ", prevProps);
        // console.log("ABSENCE ATTENDENTS this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}

        if (this.props.absence.newAttendantAbsence)
        {
            await this.getAllAbsenceAttendants();
            this.props.newAttendantAbsence(false);
        }
        
    }

    getAllAbsenceAttendants = async () =>
    {
        let allAbsenceAttendants = [];
        let dateStartIntervals = await this.state.dateStartIntervals;
        let dateEndsIntervals = await this.state.dateEndsIntervals;

        console.log("dateStartIntervals: ", dateStartIntervals);
        console.log("dateEndsIntervals: ", dateEndsIntervals);

        if (!validDate(dateStartIntervals) || !validDate(dateEndsIntervals))
        {
            this.message("warning", "Opsssss, as datas estão erradas. Por favor, corriga-as e tente novamente!");
            return;
        }

        const result = await absenceAttendantsController.get(dateStartIntervals, dateEndsIntervals);

        if (result.status)
        {
            let {data} = result.data;

            for (const absence of data)
            {
                allAbsenceAttendants.push(
                    {
                        id: absence.ABT_ID,
                        idAttendant: absence.ABT_ID_ABSENCE_PERSON,
                        date: absence.ABT_ABSENCE_DATE,
                        timeStart: absence.ABT_TIME_START,
                        timeEnds: absence.ABT_TIME_ENDS,
                        reason: absence.ABT_ABSENCE_REASON,
                        checked: false,
                    }
                );
            }
        }
        
        await this.setState({allAbsenceAttendants, showDropDownIntervals: false});
    }

    getAllAttendants = async () =>
    {
        let optionsAttendants = [];
        const result = await workShiftsPersonController.get();

        if (result.status) 
        {
            let {data} = result.data;

            for (const attendant of data)
            {
                optionsAttendants.push(
                    {
                        name: attendant.PER_NAME,
                        value: attendant.PER_ID,
                        checked: false
                    }
                );
            }
        }
                
        await this.setState({optionsAttendants});
    }

    checkIsTimeEndsAfterTimeStart = (timeStart, timeEnds) =>
    {
        function zeroPad(num, places) { return String(num).padStart(places, '0'); }
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = zeroPad((currentDate.getMonth() + 1), 2);
        let day = zeroPad(currentDate.getDate(), 2);

        let intervalStartTime = `${year}-${month}-${day} ${timeStart}:00`;
        let intervalEndsTime = `${year}-${month}-${day} ${timeEnds}:00`;
        let stampStart = new Date(intervalStartTime).getTime();
        let stampEnds = new Date(intervalEndsTime).getTime();
        return (stampEnds > stampStart);
    }

    timeIsCompleted = (time) =>
    {
        return time.toString().trim() !== "" && time.toString().trim().length === 5;
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            let allAbsenceAttendants = await this.state.allAbsenceAttendants;
            allAbsenceAttendants = allAbsenceAttendants.map(a => {
                if (a.id === id) { a[propertyName] = newValue; }
                return a;
            });
            await this.setState({allAbsenceAttendants});

            // validating if the end time is bigger then the start time.
            if (propertyName === "timeStart" || propertyName === "timeEnds")
            {
                allAbsenceAttendants = allAbsenceAttendants.forEach(a => {
                    if (a.id === id && this.timeIsCompleted(a.timeStart) && this.timeIsCompleted(a.timeEnds))
                    {
                        if (!this.checkIsTimeEndsAfterTimeStart(a.timeStart, a.timeEnds))
                        {
                            this.message("warning", "Opsssss, os horários estão errados. A hora final deve ser maior que a hora inicial!");
                        }
                    }
                });
            }
        }
    }

    saveValue = async (id, column, value) =>
    {
        let error = "";

        if (value === "")
        {
            error += " Este campo não pode ficar em branco. ";
        }

        if (column === "ABT_ABSENCE_DATE" && !validDate(value))
        {
            error += " Esta não é uma data válida. ";
        }

        if (column === "ABT_TIME_START" || column === "ABT_TIME_ENDS")
        {
            let allAbsenceAttendants = await this.state.allAbsenceAttendants;
            allAbsenceAttendants.forEach(a => {
                if (a.id === id)
                {
                    if (!this.timeIsCompleted(a.timeStart) || !this.timeIsCompleted(a.timeEnds))
                    {
                        error = " Os campos horários devem estar no formato HH:MM. "
                    }

                    if (!this.checkIsTimeEndsAfterTimeStart(a.timeStart, a.timeEnds))
                    {
                        error += " A hora final deve ser maior que a hora inicial. ";
                    }
                }
            });
        }

        if (error.toString().trim() !== "")
        {
            this.message("error", "Opsssss, não é possível alterar os dados pois encontramos os seguintes problemas: " + error);
            return;
        }

        let resultUpdate = await absenceAttendantsController.update(id, {column, value});

        if (!resultUpdate.status)
        {
            this.message("error", "Opsssss, houve algum erro ao atualizar os dados!");
            return;
        }        
    }

    deleteValue = async (id, reason) =>
    {
        await this.setState(
            {
                statusConfirmation: false,
                nameFunctionYesModalConfirmation: "onDeleteValue",
                objectConfirmation: {id, reason},
            }
        );

        this.onDeleteValue();
    }

    onDeleteValue = async () =>
    {
        let obj = await this.state.objectConfirmation;

        if (!this.state.statusConfirmation)
        {
            this.messageConfirmation("warning", `Deseja realmente excluir a falta "${obj.reason}"?`);
        }
        else
        {
            let resultDelete = await absenceAttendantsController.delete(obj.id);

            if (resultDelete.status)
            {
                await this.setState({
                    statusConfirmation: false,
                    objectConfirmation: {}
                });

                await this.getAllAbsenceAttendants();
            }
            else
            {
                this.message("error", "Opsssss, não conseguimos excluir a falta! Se o erro persistir contacte o time de desenvolvimento!");
            }
        }
    }

    onClickYesConfirmation =  () =>
    {
        let fn = this.state.nameFunctionYesModalConfirmation;

        this.setState({
            statusConfirmation: true,
            message_show_confirmation: false,
            message_confirmation: "",
            message_type_confirmation: "information"
        });

        switch (fn) 
        {
            case "onDeleteValue":
                this.onDeleteValue();
                break;
        
            default:
                break;
        }
    }

    onClickNoConfirmation = () =>
    {
        this.setState({
            statusConfirmation: false,
            objectConfirmation: {},
            message_show_confirmation: false,
            message_confirmation: "",
            message_type_confirmation: "information"
        })
    }

    setCurrentDatesForIntervals = async () =>
    {
        let dateStartIntervals = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight());
        let dateEndsIntervals = dateStartIntervals;
        await this.setState({dateStartIntervals, dateEndsIntervals});
    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }

    messageConfirmation = (type, message) =>
    {
        this.setState({
            message_type_confirmation: type,
            message_confirmation: message,
            message_show_confirmation: true
        });
    }

    render ()
    {
        return (
            <div className="absenceAttendants">
                <Header title="Faltas (vendedores)" classaditional="headerAbsenceAttendants"/>
                <MessageConfirmation
                    message_type={this.state.message_type_confirmation}
                    message={this.state.message_confirmation}
                    onClose={() => this.setState({message_show_confirmation: false})}
                    show={this.state.message_show_confirmation}
                    onClickYes={() => this.onClickYesConfirmation()}
                    onClickNo={() => this.onClickNoConfirmation()}
                />
                <div className="containerNavigationHeaderAbsenceAttendants">
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderAbsenceAttendants ${!this.state.filtered ? "active" : ""}`}
                        onClick={() => {}}
                    />
                    {
                        this.props.permissions.toInsert  &&
                        <Button
                            icon="fal fa-plus"
                            name="&nbsp;&nbsp;Falta"
                            classaditional="buttonPlusNavigationHeaderAbsenceAttendants"
                            onClick={() => {
                                let data = {
                                    show: true,
                                    type: absenceAttendants
                                };
                                this.props.showModalMoreActions(data);
                            }}
                        />
                    }
                    <div className={`filterStatusHeaderAbsenceAttendants filterPeriodHeaderAbsenceAttendants d-none d-lg-flex ml-auto ml-2`}>
                        <Button
                            classaditional="buttonStatusHeaderAbsenceAttendants"
                            icon="fas fa-calendar-alt"
                            onClick={() => this.setState({showDropDownIntervals: !this.state.showDropDownIntervals})}
                            name="&nbsp;&nbsp;&nbsp;Selecione o Período"
                        />
                        {
                            this.state.showDropDownIntervals &&
                            <div className="dropIntervalsHeaderAbsenceAttendants">
                                <div className="row no-gutters lineBodyDropIntervalsHeaderAbsenceAttendants">
                                    <div className="col-6 pr-1">
                                        <InputGeneral
                                            label="Data Inicial"
                                            type="date" 
                                            onChange={(e) => this.setState({dateStartIntervals: e.target.value})}
                                            value={this.state.dateStartIntervals}
                                        />
                                    </div>
                                    <div className="col-6 pl-1">
                                        <InputGeneral
                                            label="Data Final"
                                            type="date" 
                                            onChange={(e) => this.setState({dateEndsIntervals: e.target.value})}
                                            value={this.state.dateEndsIntervals}
                                        />
                                    </div>
                                </div>
                                <div className="row no-gutters lineFooterDropIntervalsHeaderAbsenceAttendants">
                                    <Button
                                        classaditional="buttonIntervalsHeaderAbsenceAttendants"
                                        name="Limpar"
                                        onClick={async () => this.setCurrentDatesForIntervals()}
                                    />
                                    <Button
                                        classaditional="buttonIntervalsHeaderAbsenceAttendants positive"
                                        name="Aplicar"
                                        onClick={async () => this.getAllAbsenceAttendants()}
                                    />                  
                                </div>
                            </div>
                        }                    
                    </div>
                </div>
                <div className="containerListHeaderAbsenceAttendants">
                    <TableEditAbsenceAttendants
                        data={this.state.allAbsenceAttendants}
                        onChangeValue={this.changeValue}
                        onSaveValue={this.saveValue}
                        onDeleteValue={this.deleteValue}
                        onIgnoreSave={() => this.getAllAbsenceAttendants()}
                        permissions={this.props.permissions}
                        optionsAttendants={this.state.optionsAttendants}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const absence = state.absence;
    const { user } = state.auth;
    const permissions = state.permissions.absenceAttendants;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;
    
    return {
        user,
        absence,
        permissions,
        hasPermissionsLoaded
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
        newAttendantAbsence (status) 
        {
            //action creator -> action
            const action = newAttendantAbsence(status);
            dispatch(action);
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AbsenceAttendants));