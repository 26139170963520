import React from "react";
import "./ButtonDetailsAndSelectPanelWhatsApp.css";

export default function ButtonDetailsAndSelectPanelWhatsApp (props)
{
    return (
        <div className={`buttonDetailsAndSelectPanelWhatsApp ${props.classaditional}`} onClick={props.onClick}>
            <div className="partOneButtonDetailsAndSelectPanelWhatsApp">
                {
                    props.icon &&
                    <div className="iconButtonDetailsAndSelectPanelWhatsApp">{props.icon}</div>
                }
                <span className="titleButtonDetailsAndSelectPanelWhatsApp">{props.title}</span>
            </div>
            <div className="partTwoButtonDetailsAndSelectPanelWhatsApp">
                {
                    props.amount &&
                    <span className="amountButtonDetailsAndSelectPanelWhatsApp">{props.amount}</span>
                }
                <div className="iconButtonDetailsAndSelectPanelWhatsApp">
                    <svg viewBox="0 0 10 21" height="21" width="10" preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px" enableBackground="new 0 0 10 21"><path d="M1,15.75l5.2-5.2L1,5.35l1.5-1.5l6.5,6.7l-6.6,6.6L1,15.75z"></path></svg>
                </div>
            </div>
        </div>
    )
}