import React, { Component } from "react";
import "./CampaignForm.css";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import { newCampaign } from "../../store/actions/campaign";
import { IDS_MIDIA_ORIGIN_POSSIBLE_RUN_FOR_CAMPAIGN } from "../../core/constants";

import Button from "../general/Button";
import Select from "../general/Select";
import Message from "../general/Message";
import InputGeneral from "../general/InputGeneral";

import CampaignController from "../../controllers/CampaignController";
import MidiaOriginController from "../../controllers/MidiaOriginController";
import UserController from "../../controllers/UserController";
import { 
    dateDiff, 
    formatedDateTimeToBrowserAtMidnight, 
    formatedDateTimeToUSA, 
    formatedMoneyBRA, 
    formatedMoneyBRAToFloat, 
    formatedTime, 
    getDateTimeCurrentBrowserAtMidnight, 
    validTime 
} from "../../auxiliary/generalFunctions";

const initialState = {

    // start content name
    name: "",
    searchingName: false,
    warningName: false,
    noticesName: [],
    validName: true,
    // ends content name 

    // start content mida origin
    validMidiaOrigin: true,
    choosenMidiaOrigin: {
        name: "",
        value: 0,
    },
    // ends content mida origin

    // start content unit company
    validUnitCompany: true,
    choosenUnitCompany: {
        name: "",
        value: 0,
    },
    // ends content unit company

    // start content dateStart
    dateStart: "",
    validDateStart: true,
    // ends content dateStart 

    // start content dateEnds
    dateEnds: "",
    validDateEnds: true,
    // ends content dateEnds 

    // start content timeStart
    timeStart: "",
    validTimeStart: true,
    // ends content timeStart 

    // start content timeEnds
    timeEnds: "",
    validTimeEnds: true,
    // ends content timeEnds 

    // start content executations
    executions: "",
    validExecutions: true,
    // ends content executations 

    // start content amountInvested
    amountInvested: "",
    validAmountInvested: true,
    // ends content amountInvested 

    optionsUnitCompany: [],
    optionsMidiaOrigin: [],
    specificFieldsWithMandatoryFilling: false,

    // start modal message
    message_type: "information",
    message_show: false,
    message: "",
    // ends modal message
}

const campaignController = new CampaignController();
const midiaOriginController = new MidiaOriginController();
const userController = new UserController();

class CampaignForm extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        await this.getCompanyUnit();
        await this.getMidiaOrigin();
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        }
        this.props.showModalMoreActions(data);
    }

    getCompanyUnit = async () => 
    {
        let optionsUnitCompany = [];
        const result = await userController.getById(this.props.user.PER_ID);

        if (result.status) 
        {
            for (const company of result.data.unit_companies)
            {
                optionsUnitCompany.push({
                    name: company.UNT_NAME,
                    value: company.UNT_ID,
                    checked: false
                });
            }
        }
                
        await this.setState({optionsUnitCompany});
    }

    getMidiaOrigin = async () =>
    {
        let allMidiaOrigin = [];
        let responseMidia = await midiaOriginController.get();

        if (responseMidia.status)
        {
            let {data} = responseMidia.data;
            for (const midia of data)
            {
                allMidiaOrigin.push({
                    name: midia.MID_NAME,
                    value: midia.MID_ID,
                    checked: false
                });
            }
        }
        
        await this.setState({optionsMidiaOrigin: allMidiaOrigin});
    }

    nameChangeAndChecking = async (name) =>
    {
        await this.setState({
            name,
            validName: name.toString().trim() !== ""
        });
    }

    midiaChangeAndChecking = async (choosenMidiaOrigin) =>
    {
        let specificFieldsWithMandatoryFilling = false;

        if (IDS_MIDIA_ORIGIN_POSSIBLE_RUN_FOR_CAMPAIGN.includes(choosenMidiaOrigin.value)) { specificFieldsWithMandatoryFilling = true; }

        await this.setState({
            choosenMidiaOrigin,
            validMidiaOrigin: choosenMidiaOrigin.value !== 0,
            specificFieldsWithMandatoryFilling
        });

        if (specificFieldsWithMandatoryFilling)
        {
            await this.setState({
                dateStart: formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight()),
                dateEnds: formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight())
            });
        }
        else
        {
            await this.setState({
                dateStart: "",
                dateEnds: ""
            });
        }

    }

    unitChangeAndChecking = async (choosenUnitCompany) =>
    {
        await this.setState({
            choosenUnitCompany,
            validUnitCompany: choosenUnitCompany.value !== 0
        });
    }

    dateStartChangeAndChecking = async (dateStart) =>
    {
        let diffDays = dateDiff.inDays(formatedDateTimeToBrowserAtMidnight(dateStart), formatedDateTimeToBrowserAtMidnight(this.state.dateEnds));

        await this.setState({
            dateStart,
            validDateEnds: diffDays >= 0,
            validDateStart: dateStart.toString().trim() !== "" && diffDays >= 0
        });
    }

    dateEndsChangeAndChecking = async (dateEnds) =>
    {
        let diffDays = dateDiff.inDays(formatedDateTimeToBrowserAtMidnight(this.state.dateStart), formatedDateTimeToBrowserAtMidnight(dateEnds));

        await this.setState({
            dateEnds,
            validDateStart: diffDays >= 0,
            validDateEnds: dateEnds.toString().trim() !== "" && diffDays >= 0
        });
    }

    timeStartChangeAndChecking = async (timeStart) =>
    {
        await this.setState({
            timeStart,
            validTimeStart: timeStart.toString().trim() !== "" && timeStart.toString().trim().length === 5
        });
    }

    timeEndsChangeAndChecking = async (timeEnds) =>
    {
        await this.setState({
            timeEnds,
            validTimeEnds: timeEnds.toString().trim() !== "" && timeEnds.toString().trim().length === 5
        });
    }

    executionsChangeAndChecking = async (executions) =>
    {
        await this.setState({
            executions: !isNaN(executions) ? executions : 1,
            validExecutions: executions.toString().trim() !== "" && executions.toString().trim().length <= 3 && !isNaN(executions)
        });
    }

    amountInvestedChangeAndChecking = async (amountInvested) =>
    {
        await this.setState({
            amountInvested: formatedMoneyBRA(amountInvested),
            validAmountInvested: amountInvested.toString().trim() !== "" && formatedMoneyBRAToFloat(amountInvested) > 0
        });
    }

    onActionForm = async () =>
    {
        let allCorrect = true;
        let specificFieldsWithMandatoryFilling = await this.state.specificFieldsWithMandatoryFilling;
        
        // strings
        let name = await this.state.name;
        let timeStart = await this.state.timeStart;
        let timeEnds = await this.state.timeEnds;
        let executions = this.state.executions;
        let amountInvested = await this.state.amountInvested;
        
        // numbers
        let midia = await this.state.choosenMidiaOrigin.value;
        let company = await this.state.choosenUnitCompany.value;
        
        // objects
        let dateStart = await this.state.dateStart;
        let dateEnds = await this.state.dateEnds;

        if (name.toString().trim() === "")
        {
            allCorrect = false;
            await this.setState({validName: false});
        }

        if ((timeStart.toString().trim().length < 5 || validTime(timeStart) === "00:00") && specificFieldsWithMandatoryFilling)
        {
            allCorrect = false;
            await this.setState({validTimeStart: false});
        }

        if ((timeEnds.toString().trim().length < 5 || validTime(timeEnds) === "00:00") && specificFieldsWithMandatoryFilling)
        {
            allCorrect = false;
            await this.setState({validTimeEnds: false});
        }

        if (isNaN(midia) || midia === 0)
        {
            allCorrect = false;
            await this.setState({validMidiaOrigin: false});
        }

        if (isNaN(company) || company === 0)
        {
            allCorrect = false;
            await this.setState({validUnitCompany: false});
        }

        if ((isNaN(executions) || executions.toString().trim() === "" || executions <= "0") &&  specificFieldsWithMandatoryFilling)
        {
            allCorrect = false;
            await this.setState({validExecutions: false});
        }

        if (amountInvested.toString().trim() === "" || formatedMoneyBRAToFloat(amountInvested) <= 0)
        {
            allCorrect = false;
            await this.setState({validAmountInvested: false});
        }
        
        let diffDays = dateDiff.inDays(formatedDateTimeToBrowserAtMidnight(dateStart), formatedDateTimeToBrowserAtMidnight(dateEnds));
        
        if ((diffDays < 0 || isNaN(diffDays)) && specificFieldsWithMandatoryFilling)
        {
            allCorrect = false;
            await this.setState({validDateStart: false, validDateEnds: false});
        }

        let diffTimes = parseFloat(timeEnds.replace(":", ".")) - parseFloat(timeStart.replace(":", "."));
        
        if ((diffTimes < 0 && diffDays === 0) && specificFieldsWithMandatoryFilling)
        {
            allCorrect = false;
            await this.setState({validTimeStart: false, validTimeEnds: false});
        }

        if (!allCorrect)
        {
            await this.message("error", "Confira o(s) campo(s) destacado(s) em vermelho!");
            return;
        }

        const result = await campaignController.create({
            CAM_NAME: name,
            CAM_DATE_START: dateStart || null,
            CAM_DATE_ENDS: dateEnds || null,
            CAM_TIME_START: timeStart || null,
            CAM_TIME_ENDS: timeEnds || null,
            CAM_EXECUTIONS: parseInt(executions) || null,
            CAM_AMOUNT_INVESTED: formatedMoneyBRAToFloat(amountInvested),
            CAM_IS_IT_POSSIBLE_TO_RUN: IDS_MIDIA_ORIGIN_POSSIBLE_RUN_FOR_CAMPAIGN.includes(midia) ? 1 : 2,
            CAM_ID_COMPANY_UNIT: company,
            CAM_ID_MIDIA_ORIGIN: midia
        });

        if (!result.status)
        {
            return this.message("error", result.message);
        }
        
        this.closeModalMoreActions();
        this.props.data.onActionForm();
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render () 
    {
        return (
            <div className="campaignForm"> 
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="row no-gutters lineCampaignForm">
                    <div className="col-12 columnCampaignForm">
                        <Select
                            label="Mídia *"
                            options={this.state.optionsMidiaOrigin}
                            default={this.state.choosenMidiaOrigin}
                            onChange={(choosenMidiaOrigin) => this.midiaChangeAndChecking(choosenMidiaOrigin)}
                            valid={this.state.validMidiaOrigin.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCampaignForm">
                    <div className="col-12 columnCampaignForm">
                        <InputGeneral
                            label="Nome da Campanha"
                            value={this.state.name}
                            onChange={e => this.nameChangeAndChecking(e.target.value)}
                            searching={this.state.searchingName.toString()}
                            warning={this.state.warningName.toString()}
                            warningcontent={this.state.noticesName}
                            mandatory="true"
                            maxLength={50}
                            valid={this.state.validName.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCampaignForm">
                    <div className="col-12 columnCampaignForm">
                        <Select
                            label="Unidade *"
                            options={this.state.optionsUnitCompany}
                            default={this.state.choosenUnitCompany}
                            onChange={(choosenUnitCompany) => this.unitChangeAndChecking(choosenUnitCompany)}
                            valid={this.state.validUnitCompany.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCampaignForm">
                    <div className="col-6 columnCampaignForm pr-1">
                        <InputGeneral
                            label="Data Inicial"
                            value={this.state.dateStart}
                            onChange={e => this.dateStartChangeAndChecking(e.target.value)}
                            mandatory={`${this.state.specificFieldsWithMandatoryFilling.toString()}`}
                            type="date"
                            valid={this.state.validDateStart.toString()}
                        />
                    </div>
                    <div className="col-6 columnCampaignForm pl-1">
                        <InputGeneral
                            label="Data Final"
                            value={this.state.dateEnds}
                            onChange={e => this.dateEndsChangeAndChecking(e.target.value)}
                            mandatory={`${this.state.specificFieldsWithMandatoryFilling.toString()}`}
                            type="date"
                            valid={this.state.validDateEnds.toString()}
                        />                    
                    </div>
                </div>
                <div className="row no-gutters lineCampaignForm">
                    <div className="col-6 columnCampaignForm pr-1">
                        <InputGeneral
                            label="Horário Inicial"
                            value={formatedTime(this.state.timeStart)}
                            onChange={e => this.timeStartChangeAndChecking(e.target.value)}
                            mandatory={`${this.state.specificFieldsWithMandatoryFilling.toString()}`}
                            valid={this.state.validTimeStart.toString()}
                            maxLength={5}
                        />
                    </div>
                    <div className="col-6 columnCampaignForm pl-1">
                        <InputGeneral
                            label="Horário Final"
                            value={formatedTime(this.state.timeEnds)}
                            onChange={e => this.timeEndsChangeAndChecking(e.target.value)}
                            mandatory={`${this.state.specificFieldsWithMandatoryFilling.toString()}`}
                            valid={this.state.validTimeEnds.toString()}
                            maxLength={5}
                        />                        
                    </div>
                </div>
                <div className="row no-gutters lineCampaignForm">
                    <div className="col-6 columnCampaignForm pr-1">
                        <InputGeneral
                            label="Inserções"
                            value={this.state.executions}
                            onChange={e => this.executionsChangeAndChecking(e.target.value)}
                            mandatory={`${this.state.specificFieldsWithMandatoryFilling.toString()}`}
                            valid={this.state.validExecutions.toString()}
                            maxLength={3}
                        />
                    </div>
                    <div className="col-6 columnCampaignForm">
                        <InputGeneral
                            label="Valor Investido (R$)"
                            value={formatedMoneyBRA(this.state.amountInvested.toString())}
                            onChange={e => this.amountInvestedChangeAndChecking(e.target.value)}
                            mandatory="true"
                            valid={this.state.validAmountInvested.toString()}
                            maxLength={14}
                        />                                     
                    </div>
                </div>
                <div className="row no-gutters lineCampaignForm">
                    <div className="col-12 columnCampaignForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterCampaignForm">
                    <Button
                        classaditional="buttonCampaignForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions()}
                    />
                    <Button
                        classaditional="buttonCampaignForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;

    return {
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        },
        newCampaign (data)
        {
            //action creator -> action
            const action = newCampaign(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignForm);