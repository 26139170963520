import axios from "axios";
import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";
import { baseURLTemporaryWebhook, integrationKeyTemporaryWebhook }  from "../configuration";

const api = new API();

export default class UserController 
{
    async create(data) 
    {
        const response = await api.user().post(data);
        return apiCallbackTreatment(response);
    }

    async get(userId = null) 
    {
        const response = await api.user().get(userId);
        return apiCallbackTreatment(response);
    }

    async getAllSpecialistByUnitCompany(companyUnitId) 
    {
        const response = await api.user().getAllSpecialistByUnitCompany(companyUnitId);
        return apiCallbackTreatment(response);
    }

    async getAllUsersByCompanyUnit(idUserLogged, status = null) 
    {
        const response = await api.user().getAllUsersByCompanyUnit(idUserLogged, status);
        return apiCallbackTreatment(response);
    }

    async getManagerOfCompanyUnit(companyUnitId) 
    {
        const response = await api.user().getManagerOfCompanyUnit(companyUnitId);
        return apiCallbackTreatment(response);
    }

    async getById(id) 
    {
        const response = await api.user().getById(id);
        return apiCallbackTreatment(response);
    }

    async getOwnersForUserOnAccess(id) 
    {
        const response = await api.user().getOwnersForUserOnAccess(id);
        return apiCallbackTreatment(response);
    }
    
    async update(id, data) 
    {
        const response = await api.user().update(id, data);
        return apiCallbackTreatment(response);
    }
    
    async changeAvatar(data, id) 
    {
        const response = await api.user().changeAvatar(data, id);
        return apiCallbackTreatment(response);
    }

    async getAvatar(id) 
    {
        const response = await api.user().getAvatar(id);
        return apiCallbackTreatment(response);
    }

    async hasAvatar(id) 
    {
        const response = await api.user().hasAvatar(id);
        return apiCallbackTreatment(response);
    }

    async getUsersERPTemporaryWebhook()
    {
        let response = await axios.get(`${baseURLTemporaryWebhook}/user/all/key=${integrationKeyTemporaryWebhook}`);
        response = response.data;
        return apiCallbackTreatment(response);
    }
}