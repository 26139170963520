import React, { Component } from "react";  
import "./Login.css";

import { setAuthenticated } from "../../store/actions/auth";
import { connect } from "react-redux";
import { setTokenUser }  from "../../configuration";
import { APPLICATION_NAME } from "../../core/constants";
import { getFullNameFisrtCharsUppercase } from "../../auxiliary/generalFunctions";

import Anchor from "../../components/general/Anchor";
import Input from "../../components/general/Input";
import Button from "../../components/general/Button";
import Message from "../../components/general/Message";

import AuthController from "../../controllers/AuthController";

const initialState = {
    mail: "",
    password: "",
    remember: false,
    token: "",
    currentYear: "2021",
    modeRecovery: false,

    actionButtonIsDisabled: false,

    // start content about message component
    message_type: "information",
    message_show: false,
    message: "",
    // ends content about message component
}

const authController = new AuthController();

class Login extends Component
{
    state = {...initialState};

    componentDidMount = async (props) =>  
    {
        document.title = `${APPLICATION_NAME} - Entrar`;
        let date = new Date();
        let currentYear = date.getFullYear();
        await this.setState({currentYear})
    }

    login = async () =>
    {
        const result = await authController.login({
            email: this.state.mail,
            password: this.state.password
        });

        if (!result.status) {
            return this.message("error", result.message); 
        }

        const user = {
            ...result.data.USER,
            token: result.data.AUTH.TOKEN
        };

        if (user && user?.token !== "")
        {
            setTokenUser(user.token)
            this.props.setAuthenticated(user);
            this.message("success", `Seja bem-vindo(a), ${getFullNameFisrtCharsUppercase(result.data.USER.PER_NAME)}!`);

            setTimeout(() => {
                this.props.onLogin();
            }, 2000);
        }
        else
        {
            this.message("error", "Opsssss, tivemos algum problema ao registrar seu usuário no sistema!");
        }
    }

    recovery = async () =>
    {
        let mail = await this.state.mail;
        const result = await authController.forgotPassword({"PER_MAIL": mail});
        this.message(result.status ? "success" : "error", result.message);
    }

    onActionForm = async () =>
    {
        let modeRecovery = await this.state.modeRecovery;
        !modeRecovery ? await this.login() : await this.recovery();
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        });
    }

    render ()
    {
        return (
            <div className="container login">
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="navigation">
                    <Anchor className="brand" target="_blank" href="/" label="sempnet"/>
                </div>
                <div className="container form">
                    <h1 className="title">{this.state.modeRecovery ? "Recuperar" : "Entrar"}</h1>
                    <div className="group"> 
                        <Input 
                            icon="far fa-envelope"  
                            value={this.state.mail} 
                            onChange={e => this.setState({mail: e.target.value})} 
                            type="text" 
                            label="E-mail"
                            autoFocus
                        />
                        <span className="spanError">erro aqui</span>
                    </div>
                    {
                        !this.state.modeRecovery &&
                        <div className="group">
                            <Input 
                                icon="far fa-unlock-alt" 
                                value={this.state.password}  
                                onChange={e => this.setState({password: e.target.value})}
                                onKeyDown={e => e.key === 'Enter' ? this.login() : null}
                                type="password" 
                                label="Senha"
                                help={
                                    <Anchor
                                        className="anchorForgotPassword" 
                                        label="Esqueceu?" 
                                        onClick={() => this.setState({modeRecovery: true})}
                                    />}
                            />
                            <span className="spanError">erro aqui</span>
                        </div>
                    }
                    <Button
                        style={{marginBottom: "25px"}} 
                        name={`${this.state.modeRecovery ? "Recuperar" : "Entrar"}`} 
                        onClick={async () => {
                            await this.setState({
                                actionButtonIsDisabled: true
                            });
                            await this.onActionForm();
                            await this.setState({
                                actionButtonIsDisabled: false
                            });
                        }} 
                        disabled={this.state.actionButtonIsDisabled}
                    />
                    {/* {
                        !this.state.modeRecovery &&
                        <Check 
                            name="Lembrar" 
                            onClick={() => this.setState({remember: !this.state.remember})} 
                            checked={this.state.remember}
                        />
                    } */}
                    {
                        this.state.modeRecovery &&
                        <Anchor
                            className="anchorLogin" 
                            label="entrar"
                            onClick={() => this.setState({modeRecovery: false})}
                        />
                    }
                </div>
                <div className="footer">
                    <Anchor className={`linkCRM`} target="_blank" href="/" label={`© 2021 - ${this.state.currentYear} SEMPNET`}/>&nbsp;
                    <Anchor className={`linkSupport`} target="_blank" href="/" label=" | Dúvidas e Suporte"/>
                </div>
            </div>
        )
        
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth

    return {
        user
    };
}

function mapDispatchToProps (dispatch)
{
    return {
        setAuthenticated (data)
        {
            //action creator -> action
            const action = setAuthenticated(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);