import React, { Component } from "react";  
import "./BusinessByClientForm.css";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { formatedMoneyBRA, getFullNameFisrtCharsUppercase } from "../../auxiliary/generalFunctions";
import { newDealBusiness } from "../../store/actions/business";
import { showModalMoreActions }  from "../../store/actions/general";

import Button from "../general/Button";
import ProgressLine from "./ProgressLine";
import InputGeneral from "../general/InputGeneral";
import BusinessController from "../../controllers/BusinessController";
import Select from "../general/Select";
import BusinessServices from "../../controllers/BusinessServicesController";

const initialState = { 
    // start content name
    name: "",
    searchingName: false,
    warningName: false,
    noticesName: [],
    validName: true,
    // ends content name 
    
    // start content midiaOrigin
    optionsMidiaOrigin: [],
    optionChosenMidiaOrigin: null,
    validMidiaOrigin: true,
    // ends content stampsLead 

    // start content title
    title: "",
    validTitle: true,
    // ends content title

    // start content money
    money: "",
    validMoney: true,
    // ends content money

    // start content step
    step: 0,
    // ends content step

    // start content companyUnit
    optionsCompanyUnit: [],
    optionChosenCompanyUnit: null,
    validCompanyUnit: true,
    // ends content companyUnit

    // start content button
    disabledButton: false,
    // ends content button

    // start content business recovered
    isBusinessRecovered: false,
    idBusiness: null,
    // ends content business recovered

    // start content businessServices
    optionsBusinessServices: [],
    optionChosenBusinessServices: null,
    validBusinessServices: true,
    // ends content businessServices
}

const businessController = new BusinessController();
const businessServices = new BusinessServices();

class BusinessByClientForm extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        let {id, name, owner, idCompanyUnitByClient} = this.props.data;
        let isBusinessRecovered = this.props.data?.isBusinessRecovered || false;
        let idBusiness = this.props.data?.idBusiness || null;

        await this.setState({id, name, owner, isBusinessRecovered, idCompanyUnitByClient, idBusiness});
        await this.getBusinessServices(idCompanyUnitByClient);
    }

    componentDidUpdate = async (prevProps) =>
    {
        // console.log("BUSINESS BY CLIENT prevProps ", prevProps);
        // console.log("BUSINESS BY CLIENT this.props ", this.props);
    }

    getBusinessServices = async (id) =>
    {
        const result = await businessServices.getByIdCompanyUnit(id);
        let optionsBusinessServices = [];

        if (result.status)
        {
            for (let service of result.data)
            {
                optionsBusinessServices.push(
                    {
                        id: service.BUN_ID_BUSINESS_SERVICE,
                        name: service.BUP_NAME,
                        value: service.BUN_ID_BUSINESS_SERVICE,
                    }
                );
            }

            await this.setState({optionsBusinessServices});
        }
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        }
        this.props.showModalMoreActions(data);
    }

    titleChangeAndChecking = (title) =>
    {
        this.setState({title});
    }

    moneyChangeAndChecking = (money) =>
    {
        this.setState({money: formatedMoneyBRA(money), validMoney: true});
    }

    zeroPad = (num, places) => String(num).padStart(places, '0');

    onActionForm = async () =>
    {
        if (this.state.title.trim() === "")
        {
            return this.setState({ validTitle: false });
        }

        if (this.state.money.trim() === "")
        {
            return this.setState({ validMoney: false });
        }

        if (this.state.optionChosenBusinessServices === null)
        {
            return this.setState({validBusinessServices: false});
        }

        let isBusinessRecovered = this.state.isBusinessRecovered;
        let idBusinessRecovered = null;
        let owner = null;

        if ((this.props.user.PER_ID === this.state.owner?.id) && !isBusinessRecovered)
        {
            owner = this.props.user.PER_ID;
        }
        if (isBusinessRecovered)
        {
            idBusinessRecovered = await this.state.idBusiness;
        }

        const stamp = 1;
        const date = new Date();
        const day = `${date.getFullYear()}-${this.zeroPad((date.getMonth() + 1), 2)}-${this.zeroPad(date.getDate(), 2)}`;

        const business = await businessController.create({
            BUS_TITLE: getFullNameFisrtCharsUppercase(this.state.title),
            BUS_STEP: this.state.step + 1,
            BUS_ID_PERSON_CLIENT: this.state.id,
            BUS_ID_STAMP: stamp,
            BUS_MONEY_VALUE: this.state.money.replaceAll(".", "").replace(",", "."),
            BUS_DATE_CREATED: day,
            BUS_ID_PERSON_OWNER: owner,
            BUS_ID_CREATE_PERSON: this.props.user.PER_ID,
            BUS_ID_BUSINESS_RECOVERED: idBusinessRecovered,
            BUS_ID_BUSINESS_SERVICE: this.state.optionChosenBusinessServices.value
        });

        if (!business.status)
        {
            await this.setState({ disabledButton: false });
            return alert(`ERRO: ${business.message}`);
        }

        await this.setState({ disabledButton: false });
        await this.closeModalMoreActions()
        await this.props.history.push(`/business/details/${business.data.BUS_ID}`);
        window.location.reload();
    }

    render () 
    {
        return (
            <div className="businessByClientForm"> 
                <div className="row no-gutters">
                    <div className="col-12 pr-2">
                        <div className="row no-gutters lineBusinessByClientForm">
                            <div className="col-12 columnBusinessByClientForm">
                                <InputGeneral
                                    classaditional="fieldBusinessByClientForm disabled"
                                    label="Nome"
                                    value={this.state.name}
                                    onChange={e => {}}
                                    mandatory="true"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lineBusinessByClientForm">
                            <div className="col-12 columnBusinessByClientForm">
                                <InputGeneral
                                    label="Título"
                                    value={this.state.title}
                                    onChange={e => this.titleChangeAndChecking(e.target.value)}
                                    mandatory="true"
                                    valid={this.state.validTitle.toString()}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lineBusinessByClientForm">
                            <div className="col-12 columnBusinessByClientForm">
                                <InputGeneral
                                    label="Valor"
                                    value={this.state.money}
                                    onChange={e => this.moneyChangeAndChecking(e.target.value)}
                                    valid={this.state.validMoney.toString()}
                                    mandatory="true"
                                    maxLength={14}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lineBusinessByClientForm">
                            <div className="col-12 columnBusinessByClientForm">
                                <Select
                                    label="Produto"
                                    default={this.state.optionChosenBusinessServices}
                                    options={this.state.optionsBusinessServices} 
                                    onChange={(optionChosenBusinessServices) => {
                                        this.setState({optionChosenBusinessServices});
                                        if (this.state.validBusinessServices === false)
                                        {
                                            this.setState({ validBusinessServices: true });
                                        }
                                    }}
                                    valid={this.state.validBusinessServices.toString()}
                                    mandatory
                                />
                            </div>
                        </div>
                        <div className="row no-gutters lineBusinessByClientForm">
                            <div className="col-12 columnBusinessByClientForm">
                                <label className="stepBusinessByClientForm">Etapa do Funil</label>
                                <ProgressLine 
                                    classaditional="progressLineBusinessByClientForm" 
                                    step={this.state.step} 
                                    onChangeStep={(value) => this.setState({ step: value })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row no-gutters lineBusinessByClientForm">
                    <div className="col-12 columnBusinessByClientForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterBusinessByClientForm">
                    <Button
                        classaditional="buttonBusinessByClientForm"
                        name="Cancelar"
                        onClick={() => this.props.showModalMoreActions({show: false, type: null})}
                        />
                    <Button
                        classaditional="buttonBusinessByClientForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                        disabled={this.state.disabledButton}
                    />                  
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;
    const {allDraggables} = state.business;

    return {
        allDraggables,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        newDealBusiness (data)
        {
            //action creator -> action
            const action = newDealBusiness(data);
            dispatch(action);
        },
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessByClientForm));