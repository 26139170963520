import React, { Component } from "react";
import "./Owners.css";
import Header from "../../components/CRM/Header";
import { connect } from "react-redux";
import { setModalMessage, showModalMoreActions } from "../../store/actions/general";
import { APPLICATION_NAME, owner } from "../../core/constants";
import { 
    changeStatusGetFromDatabaseCompletedOwners, 
    changeUserOwners, 
    syncUsersOwners, 
    toggleCheckedAllUsersOwners 
} from "../../store/actions/owners";
import { getOptionsSelectCheckedFormated, isEmail } from "../../auxiliary/generalFunctions";
import { setAuthenticated } from "../../store/actions/auth";
import { withRouter } from "react-router-dom";

import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import SelectMulti from "../../components/general/SelectMulti";
import TableEditOwners from "../../components/general/TableEditOwners";

import PermissionsGroupCollectionsController from "../../controllers/PermissionsGroupCollectionsController";
import UserController from "../../controllers/UserController";
import PersonCompanyUnitController from "../../controllers/PersonCompanyUnitController";
import AuthController from "../../controllers/AuthController";
import WorkShiftsPersonController from "../../controllers/WorkShiftsPersonController";
import WorkShiftsController from "../../controllers/WorkShiftsController";
import PreSaleServiceQueuePersonsController from "../../controllers/PreSaleServiceQueuePersonsController";
import WhatsAppController from "../../controllers/WhatsAppController";

const initialState = {
    allOwners: [],
    allOwnersChecked: false, //false as default
    allOwnersUpdated: false,
    allOwnersInitialLoaded: false,
    filtered: false,

    hasOneUsersChecked: false,

    optionsUnitCompany: [],
    optionsPermissionsGroup: [],
    optionsWorkShifts: [
        {
            name: "Turno 1",
            value: 1,
            checked: false
        },
        {
            name: "Turno 2",
            value: 2,
            checked: false
        },
    ],

    checkSlugsDepartamentsByUsers: [] //putting here all ids by users for check slugs
}

const authController = new AuthController();
const permissionsGroupCollectionsController = new PermissionsGroupCollectionsController();
const personCompanyUnitController = new PersonCompanyUnitController();
const userController = new UserController();
const workShiftsPersonController = new WorkShiftsPersonController();
const workShiftsController = new WorkShiftsController();
const preSaleQueue = new PreSaleServiceQueuePersonsController();
const whatsAppController = new WhatsAppController();
class Owners extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Proprietários`;
        await this.getWorkShifts();
        this.getCompanyUnit(); 
        this.getPermissionsGroup();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("OWNERS prevProps ", prevProps);
        // console.log("OWNERS this.props ", this.props);
        
        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}

        if (!this.state.allOwnersInitialLoaded)
        {
                await this.setState({ allOwnersInitialLoaded: true });
                await this.getUsers();
                let allOwners = await this.applyFilters(this.props.allOwners);
                await this.hasAllOwnersChecked();
                await this.setState({ allOwners, allOwnersInitialLoaded: true });
        }     

        if (this.state.allOwnersUpdated)
        {            
            let allOwners = await this.applyFilters(this.props.allOwners);
            await this.hasAllOwnersChecked();
            await this.setState({ allOwners, allOwnersUpdated: false });
        }

        if (prevProps.getAllOwnersFromDatabaseCompleted !== undefined)
        {
            if (!prevProps.getAllOwnersFromDatabaseCompleted && this.props.getAllOwnersFromDatabaseCompleted)
            {
                await this.setState({allOwnersUpdated: true});
            }
        }
    }

    getUsers = async() =>
    {
        let data = [];
        const user = this.props.user;
        const result = await userController.get(user.PER_ID);
        const allUnitCompanies = await userController.getById(user.PER_ID);
        let checkSlugsDepartamentsByUsers = await this.state.checkSlugsDepartamentsByUsers;

        if (result.status)
        {
            for (const owner of result.data.data) 
            {
                let allowed = false;
                let ownerUnitCompanies = [];
                let departaments = [];

                for (const unit of allUnitCompanies.data.unit_companies)
                {
                    const checked = owner.unit_companies.some((u) => u.UNT_ID === unit.UNT_ID);

                    ownerUnitCompanies.push({
                        id: unit.UNT_ID,
                        name: unit.UNT_NAME,
                        value: unit.UNT_ID,
                        checked
                    });

                    if (checked) { allowed = true; }
                }
                
                // checking wich departaments slugs by target user...
                if (checkSlugsDepartamentsByUsers.includes(owner.PER_ID))
                {
                    let idUserOnAccess = this.props.user.PER_ID;
                    let responseUserOnAccess = await whatsAppController.getWichDepartamentsSlugsByUser(idUserOnAccess);
                    let responseUserTable = await whatsAppController.getWichDepartamentsSlugsByUser(checkSlugsDepartamentsByUsers[0]);

                    if (responseUserTable.status && responseUserOnAccess.status)
                    { 
                        let allPossibleDepartaments = responseUserOnAccess.data
                        .filter(s => {
                            if (s.checked) { return true; }
                            else { return false; }
                        })
                        .map(s => s.value);
                        
                        departaments = responseUserTable.data;
                        departaments = departaments.filter(d => {
                            if (allPossibleDepartaments.includes(d.value)) { return true; }
                            else { return false; }
                        });
                    }
                }

                if (allowed)
                {
                    data.push({
                        id: owner.PER_ID,
                        name: owner.PER_NAME,
                        phone: owner.PER_PHONE_ONE,
                        unitCompanies: ownerUnitCompanies,
                        departaments,
                        permissionGroup: {
                            id: owner.PER_ID_PERMISSIONS_GROUP,
                            name: owner.PEG_NAME,
                            value: owner.PER_ID_PERMISSIONS_GROUP
                        },
                        date: new Date(owner.PER_CREATE_DATE),
                        archived: owner.PER_STATUS === 1 ? false : true,
                        checked: false,
                        mail: owner.PER_MAIL,
                        password: "",
                        idUserERP: owner.PER_ID_USER_ERP,
                        inQueue: owner.SEP_ID !== null ? true : false,
                        inQueuePreSale: owner.amountInPreSaleQueue > 0,
                        typeWorkplace: owner.PER_TYPE_WORKPLACE,
                        supervisor: owner.PER_ID_PERSON_SUPERIOR_TEAM,
                        shift: owner.WKP_ID_WORK_SHIFTS
                    });
                }
            };
        }
        
        this.props.syncUsersOwners(data);
        this.setState({checkSlugsDepartamentsByUsers: []});
    }

    checkSlugsDepartamentsByUser = async (props) =>
    {
        let checkSlugsDepartamentsByUsers = [props.id];

        await this.setState({checkSlugsDepartamentsByUsers});
        await this.props.changeStatusGetFromDatabaseCompletedOwners(false);
        await this.getUsers();
    }

    hasAllOwnersChecked = async () =>
    {
        let allOwners = await this.state.allOwners;
        let allOwnersChecked = true;
        let hasOneUsersChecked = false;

        if (allOwners.length > 0)
        {
            allOwners.forEach(d => {
                if (!d.checked) allOwnersChecked = false;
                if (d.checked) hasOneUsersChecked = true;
            });
        }
        else
        {
            allOwnersChecked = false;
        }

        await this.setState({allOwnersChecked, hasOneUsersChecked});
    }

    getFontsCheckedFormated = (options) =>
    {
        let checkeds = "Todas as Fontes";
        let plus = 0;
        let display = "";

        options.forEach((obj, i) => {
            if (obj.checked)
            {
                plus++;
                if (plus === 1)
                {
                    checkeds = obj.name;
                }
                
            }
        });

        if (plus <= 0)
        {
            display = checkeds;
        }
        else
        {
            if (plus === 0)
            {
                display = checkeds;
            }
            else
            {
                if (plus === 1)
                {
                    display = checkeds;
                }
                else
                {
                    checkeds = "fontes"
                    display = " "+plus+" "+checkeds;
                }
            }

        }
        return display.toString();
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    getWorkShifts = async () =>
    {
        const result = await workShiftsController.get();
        let optionsWorkShifts = [];

        if (result.status)
        {
            let {data} = result.data;

            for (let shift of data) 
            {
                optionsWorkShifts.push(
                    {
                        name: shift.WKS_NAME,
                        value: shift.WKS_ID,
                        checked: false
                    }
                );
            }
        }

        await this.setState({optionsWorkShifts});
    }

    getCompanyUnit = async () => 
    {
        const result = await userController.getById(this.props.user.PER_ID);
        let units = [];
        for (const unit of result.data.unit_companies) 
        {
            units.push({
                id: unit.UNT_ID,
                name: unit.UNT_NAME,
                value: unit.UNT_ID,
                checked: false
            });
        }
        this.setState({ optionsUnitCompany: units });
    }

    getPermissionsGroup = async () => {
        let allCollectionsPermissionsGroup = [];

        const result = await permissionsGroupCollectionsController.getByPermissionGroup(this.props.user.PER_ID_PERMISSIONS_GROUP);

        if (result.status) 
        {
            for (const permission of result.data.data)
            {
                allCollectionsPermissionsGroup.push({
                    name: permission.PEG_NAME_CONTROLLED,
                    value: permission.PEB_ID_PERMISSIONS_GROUP_CONTROLLED,
                    checked: false
                });
            }
        }
                
        this.setState({optionsPermissionsGroup: allCollectionsPermissionsGroup});
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            await this.props.changeUserOwners({[propertyName]: newValue, id, propertyName});
        }
        
        if (propertyName === "checked") 
        {
            let ownersUpdated = await this.applyFilters(this.props.allOwners);
            await this.setState({
                allOwners: ownersUpdated
            });
        }
    }

    saveValue = async (id, field, value) =>
    {
        if (field === "company_unit")
        {
            let unitsInDatabase = [];
            let userUnitCompanies = [];
            const result = await personCompanyUnitController.getByUser(id);

            if (result.status) { unitsInDatabase = result.data.data; }

            for (const unit of value)
            {
                let existsInDatabase = unitsInDatabase.some(u => u.PCU_ID_COMPANY_UNIT === unit.value);

                if (unit.checked)
                {
                    if (!existsInDatabase)
                    {
                        await personCompanyUnitController.create({
                            PCU_ID_PERSON: id,
                            PCU_ID_COMPANY_UNIT: unit.value
                        });
                    }

                    userUnitCompanies.push({UNT_ID: unit.value, UNT_NAME: unit.name});
                }
                else
                {
                    if (existsInDatabase)
                    {
                        await personCompanyUnitController.deleteById(unitsInDatabase.find(u => u.PCU_ID_COMPANY_UNIT === unit.value).PCU_ID);
                    }
                }
            }

            if (this.props.user.PER_ID === id)
            {
                await this.props.setAuthenticated({...this.props.user, unit_companies: userUnitCompanies});
            }

            // updating too the whatsapp departaments...
            let idsCompanyUnits = value.filter(u => u.checked).map(u => u.value);
            let slugsDepartaments = [];

            // getting all departaments slugs...
            let response = await whatsAppController.getWichDepartamentsSlugsByUser(id);
            if (response.status)
            { 
                slugsDepartaments = response.data;
                slugsDepartaments = slugsDepartaments.filter(d => d.checked).map(d => d.value);

                await whatsAppController.setDepartamentsByUser(id, slugsDepartaments, idsCompanyUnits);
            }
        }
        else if (field === "departament")
        {
            let props = value;
            let slugsDepartaments = props.departaments.filter(d => d.checked).map(d => d.value);
            let idsCompanyUnits = props.unitCompanies.filter(u => u.checked).map(u => u.value);

            let response = await whatsAppController.setDepartamentsByUser(id, slugsDepartaments, idsCompanyUnits);

            if (!response.status) { this.message("error", response.message); }
        }
        else if (field === "PASSWORD") 
        {
            const result = await authController.forceUpdatePassword(id, value);

            if (!result.status) 
            {
                return this.message("error", result.message);
            }
            else
            {
                return this.message("success", "A senha do usuário foi alterada!");
            }
        } 
        else if (field === "WKP_ID_PERSON")
        {
            let result = await workShiftsPersonController.post(
                {
                    idAttendant: id,
                    idWorkShift: value
                }
            );
            let type = (result.status) ? "success" : "error";
            let message = (result.status) ? "Turno alterado com sucesso!" : result.message;
            this.message(type, message);
        }
        else
        {
            if (field === "PER_MAIL")
            {
                if (!isEmail(value))
                {
                    return this.message("error", "Informe um e-mail válido!");
                }
            }

            const result = await userController.update(id, {
                [field]: value
            });

            if (!result.status) 
            {
                return this.message("error", result.message);
            }
        }

        await this.getUsers();
        await this.setState({allOwnersUpdated: true});
    }
    
    insertPersonToPreSaleQueue = async (idAttendant) =>
    {
        let resultInsert = await preSaleQueue.post(idAttendant);
        let message = (resultInsert.status) ? "Obaaaaa, colocamos o atendente na fila!" : "Opsssss, tivemos algum problema ao colocar o atendente na fila!";
        let type = (resultInsert.status) ? "success" : "error";
        this.message(type, message);
        await this.getUsers();
        await this.setState({allOwnersUpdated: true});
    }
    
    removePersonToPreSaleQueue = async (idAttendant) =>
    {
        let responseDeletePosition = await preSaleQueue.deletePositionAll({ idAttendant });
        
        if (responseDeletePosition.status)
        {
            this.message("success", "Obaaaaa, conseguimos excluir o atendente de todas as filas!");
            await this.getUsers();
            await this.setState({allOwnersUpdated: true});
        }
        else
        {
            this.message("error", "Opsssss, não conseguimos excluir o atendente! Se o erro persistir contacte o time de desenvolvimento!");
        }
    }

    updateOwners = async () =>
    {
        const owners = this.state.allOwners;
        const checkeds = owners.filter(owner => owner.checked);
        const filter = this.state.filtered;

        const newStatus = filter ? 1 : 2;
        const action = filter ? "desarquivado(s)" : "arquivado(s)";

        for (const owner of checkeds)
        {
            await userController.update(owner.id, {
                PER_STATUS: newStatus
            });
        }

        await this.setState({ hasOneUsersChecked: false, allOwnersChecked: false });

        await this.getUsers();
        await this.setState({allOwnersUpdated: true});

        this.message("success", `Usuário(s) ${action} com sucesso!`);
    }

    applyFilters = async (allOwners) =>
    {
        const optionsCompanyUnits = this.state.optionsUnitCompany.filter(o => o.checked).map(o => o.value);
        const optionsPermissionsGroup = this.state.optionsPermissionsGroup.filter(o => o.checked).map(o => o.value);
        const archived = this.state.filtered;

        allOwners = allOwners.filter(o => {

            let allowed = true;

            const companyUnits = o.unitCompanies.filter(o => o.checked).map(o => o.value);
            const permissionGroup = o.permissionGroup.value;

            if (optionsCompanyUnits.length > 0)
            {
                let countNotIncluded = 0;
                for (const unit of companyUnits)
                {
                    if (optionsCompanyUnits.includes(unit) === false)
                    {
                        countNotIncluded++;
                    }
                }

                if (companyUnits.length === countNotIncluded)
                {
                    allowed = false;
                }

            }

            if (optionsPermissionsGroup.length > 0 && optionsPermissionsGroup.includes(permissionGroup) === false)
            {
                allowed = false;
            }

            if (o.archived !== archived)
            {
                allowed = false;
            }

            if (allowed)
            {
                return o;
            }

            return false;


        });

        return allOwners;
    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }

    render ()
    {
        return (
            <div className="owners">
                <Header title="Usuários" classaditional="headerOwners"/>
                <div className="containerNavigationHeaderOwners">
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderOwners ${!this.state.filtered ? "active" : ""}`}
                        onClick={async () => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: false});
                            await this.getUsers();
                            await this.setState({allOwnersUpdated: true});
                        }}
                    />
                    <ButtonIcon
                        icon="fas fa-archive"
                        classaditional={`buttonNavigationHeaderOwners ${this.state.filtered ? "active" : ""}`}
                        onClick={async () => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: true});
                            await this.getUsers();
                            await this.setState({allOwnersUpdated: true});
                        }}
                    />
                    {
                        (!this.state.hasOneUsersChecked && this.props.permissions.toInsert) &&
                            <Button
                                icon="fal fa-plus"
                                name="&nbsp;&nbsp;Usuário"
                                classaditional="buttonPlusNavigationHeaderOwners"
                                onClick={() => {
                                    let data = {
                                        show: true,
                                        type: owner
                                    }
                                    this.props.showModalMoreActions(data);
                                }}
                            />
                    }
                    {
                        (this.state.hasOneUsersChecked && this.props.permissions.toDelete) &&
                        <ButtonIcon
                            icon="fas fa-trash"
                            classaditional="buttonTrashNavigationHeaderOwners"
                            onClick={() => this.message("information", "nothing...")}
                        />
                    }
                    {
                        (this.state.hasOneUsersChecked && this.props.permissions.toFile) &&
                        <Button
                            icon="fas fa-archive"
                            name={ `\u00A0\u00A0${this.state.filtered ? "Desarquivar" : "Arquivar"}` }
                            classaditional="buttonPlusNavigationHeaderOwners"
                            onClick={() => this.updateOwners()}
                        />
                    }

                    <div className="filterFontsHeaderOwners ml-auto">
                        <i className="fas fa-building iconFilterFontsHeaderOwners"></i>
                        <SelectMulti
                            classaditional="selectFontHeaderOwners "
                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsUnitCompany, "[Todas Unidades]", "unidades")}}
                            options={this.state.optionsUnitCompany} 
                            onChange={(optionsUnitCompany) => {
                                this.setState({optionsUnitCompany});
                                this.setState({allOwnersUpdated: true});
                            }}
                            withFieldSearch
                        />
                    </div>
                    <div className="filterFontsHeaderOwners ml-2">
                        <i className="fas fa-users iconFilterFontsHeaderOwners"></i>
                        <SelectMulti
                            classaditional="selectFontHeaderOwners "
                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsPermissionsGroup, "[Todos Grupos]", "grupos")}}
                            options={this.state.optionsPermissionsGroup} 
                            onChange={(optionsPermissionsGroup) => {
                                this.setState({optionsPermissionsGroup});
                                this.setState({allOwnersUpdated: true});
                            }}
                            withFieldSearch
                        />
                    </div>
                </div>
                <div className="containerListHeaderOwners">
                    <TableEditOwners
                        data={this.state.allOwners}
                        allOwnersChecked={this.state.allOwnersChecked}
                        onChangeValue={this.changeValue}
                        onSaveValue={this.saveValue}
                        onInsertPersonToPreSaleQueue={this.insertPersonToPreSaleQueue}
                        onRemovePersonToPreSaleQueue={this.removePersonToPreSaleQueue}
                        hasAllOwnersChecked={this.hasAllOwnersChecked}
                        onEdit={(props) => this.checkSlugsDepartamentsByUser(props)}
                        onIgnoreSave={async () => {
                            await this.props.changeStatusGetFromDatabaseCompletedOwners(false);
                            await this.getUsers();
                        }}
                        onToggleCheckedAllUsersOwners={async (status) => {
                            await this.props.toggleCheckedAllUsersOwners(status);
                            await this.hasAllOwnersChecked();
                        }}
                        optionsWorkShifts={this.state.optionsWorkShifts}
                        permissions={this.props.permissions}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { allOwners, getAllOwnersFromDatabaseCompleted } = state.owners;
    const permissions = state.permissions.owners;
    const { user } = state.auth;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        hasPermissionsLoaded,
        allOwners,
        getAllOwnersFromDatabaseCompleted,
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        changeStatusGetFromDatabaseCompletedOwners (data)
        {
            //action creator -> action
            const action = changeStatusGetFromDatabaseCompletedOwners(data);
            dispatch(action);
        },
        changeUserOwners (data)
        {
            //action creator -> action
            const action = changeUserOwners(data);
            dispatch(action);
        },
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        syncUsersOwners (data)
        {
            //action creator -> action
            const action = syncUsersOwners(data);
            dispatch(action);
        },
        setAuthenticated (data)
        {
            const action = setAuthenticated(data);
            dispatch(action);
        },
        toggleCheckedAllUsersOwners (status)
        {
            //action creator -> action
            const action = toggleCheckedAllUsersOwners(status);
            dispatch(action);
        },
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Owners));