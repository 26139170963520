import React, { Component } from "react";
import "./ServiceQueuePreSale.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { 
    APPLICATION_NAME,
} from "../../core/constants";
import { changeServiceQueue } from "../../store/actions/queue";
import { setModalMessage } from "../../store/actions/general";
import { formatedDateTime, getFullNameFisrtCharsUppercase } from "../../auxiliary/generalFunctions";
import { ReactSortable } from "react-sortablejs"; // more information in: https://github.com/SortableJS/react-sortablejs

import Header from "../../components/CRM/Header";
import Box from "../../components/general/Box";
import Select from "../../components/general/Select";

import CompanyUnitController from "../../controllers/CompanyUnitController";
import PreSaleServiceQueuePersonsController from "../../controllers/PreSaleServiceQueuePersonsController";
import ButtonIcon from "../../components/general/ButtonIcon";

const initialState = {

    allAttendants: [
        // {
        //     id: 1,
        //     name: "Paulo Fernandes",
        //     frozen: 2,
        //     position: 1,
        //     idCompanyUnit: 1
        // }
    ],
    allAttendantsNotFiltered: [
    // {
    //     idCompanyUnit: 1,
    //     Attendants: [
    //         {
    //             id: 1,
    //             name: "Paulo Fernandes",
    //             frozen: 2,
    //             position: 1,
    //             idCompanyUnit: 1
    //         }
    //     ]
    // }
    ],
    optionsCompanyUnit: [],
    choosenCompanyUnit: {
        name: "",
        value: 0,
    },
    idsAllowedCompanyUnit: [],
    idCurrentPersonUser: 0,
    savingAllAttendants: false,
}

const companyUnitController = new CompanyUnitController();
const preSaleServiceQueuePersonsController = new PreSaleServiceQueuePersonsController();
class ServiceQueuePreSale extends Component
{
    state = {...initialState}
    
    componentDidMount = async () =>
    {
        document.title = `${APPLICATION_NAME} - Fila de Atendimento (vendedores)`;

        await this.setState({idCurrentPersonUser: this.props.user.PER_ID});
        await this.setCurrentCompanyUnit();
        await this.getCompanyUnit();
        await this.getAllServiceQueuePreSale();
    }
    
    componentDidUpdate = async (prevProps) =>
    {
        // console.log("PRE SALE SERVICE QUEUE prevState ", prevState);
        // console.log("PRE SALE SERVICE QUEUE this.state ", this.state.allAttendants);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}
    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }

    setCurrentCompanyUnit = async () =>
    {
        let companies = this.props.user.unit_companies;
        let idsAllowedCompanyUnit = [];
        
        for (let unit of companies) { idsAllowedCompanyUnit.push(unit.UNT_ID); }

        idsAllowedCompanyUnit = idsAllowedCompanyUnit.sort((a, b) => {
            if (a > b) { return 1; }
            if (a < b) { return -1; }
            return 0;
        });
        
        await this.setState({idsAllowedCompanyUnit});
    }

    getCompanyUnit = async () => 
    {
        let optionsCompanyUnit = [];
        let idsAllowedCompanyUnit = await this.state.idsAllowedCompanyUnit;
        let choosenCompanyUnit = {
            name: "",
            value: 0,
        };

        const result = await companyUnitController.get();

        if (result.status) 
        {
            for (const company of result.data.data)
            {
                if (company.UNT_STATUS === 1 && idsAllowedCompanyUnit.includes(company.UNT_ID))
                {
                    optionsCompanyUnit.push({
                        name: company.UNT_NAME,
                        value: company.UNT_ID,
                        checked: false
                    });

                    if (company.UNT_ID === idsAllowedCompanyUnit[0])
                    {
                        choosenCompanyUnit.name = company.UNT_NAME;
                        choosenCompanyUnit.value = company.UNT_ID;
                    }
                }
            }
        }
        
        await this.setState({optionsCompanyUnit, choosenCompanyUnit});
    }

    getAllServiceQueuePreSale = async () =>
    {
        let idUser = this.props.user.PER_ID;
        let resultGet = await preSaleServiceQueuePersonsController.get(idUser);
        let idChoosenCompanyUnit = await this.state.choosenCompanyUnit.value;

        let allAttendants = [];
        let allAttendantsNotFiltered = [];

        if (resultGet)
        {
            let {data} = resultGet.data;

            for (const attendant of data) 
            {
                let objAttendant = {
                    id: attendant.PER_ID,
                    name: attendant.PER_NAME,
                    frozen: attendant.SES_FRONZEN,
                    position: attendant.SES_POSITION,
                    idCompanyUnit: attendant.SES_ID_COMPANY_UNIT,
                    lastLogin: formatedDateTime(attendant.LCC_DATETIME_CONNECT),
                    workShifts: attendant.WKS_NAME
                };

                if (attendant.SES_ID_COMPANY_UNIT === idChoosenCompanyUnit)
                {
                    allAttendants.push(objAttendant);
                }

                allAttendantsNotFiltered.push(objAttendant);
            }

            await this.setState({allAttendants, allAttendantsNotFiltered});
        }
    }

    render ()
    {
        return (
            <div className="serviceQueuePreSale">
                <Header title="Fila de Atendimento (vendedores)" classaditional="headerNavigationServiceQueuePreSale"/>
                <div className="scrollServiceQueuePreSale mb-4">
                    <div className="warningServiceQueuePreSale d-flex d-md-none">
                        <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                    </div>
                    <div className="container headerServiceQueuePreSale">
                        <Box>
                            <h4>Fila de Atendimento (pré-venda)</h4>
                        </Box>
                    </div>
                    <div className="container bodyServiceQueuePreSale d-none d-md-block">
                        <Box>
                            <div className="row no-gutters mb-4 lineCompanyUnitServiceQueuePreSale">
                                <div className="col-12 col-lg-4"></div>
                                <div className="col-12 col-lg-4 pl-2 pr-2">
                                    <div className="filterBodyServiceQueuePreSale d-none d-md-flex">
                                        <i className="fas fa-building iconfilterBodyServiceQueuePreSale"></i>
                                        <Select
                                            classaditional="selectFilterBodyServiceQueuePreSale"
                                            default={this.state.choosenCompanyUnit}
                                            options={this.state.optionsCompanyUnit} 
                                            onChange={async (choosenCompanyUnit) => {
                                                await this.setState({choosenCompanyUnit});
                                                await this.getAllServiceQueuePreSale();
                                            }}
                                            withFieldSearch
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 d-flex justify-content-end align-items-center">
                                    <ButtonIcon
                                        classaditional="buttonRefreshServiceQueuePreSale"
                                        icon="fas fa-sync"
                                        onClick={async () => {
                                            this.setState({allAttendants: []});
                                            await this.getAllServiceQueuePreSale();
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineTitleHeaderServiceQueuePreSale">
                                <div className="col-2 columnTitleHeaderServiceQueuePreSale">
                                    <span>Posição</span>
                                </div>
                                <div className="col-2 columnTitleHeaderServiceQueuePreSale justify-content-start">
                                    <span>Atendentes</span>
                                </div>
                                <div className="col-2 columnTitleHeaderServiceQueuePreSale justify-content-start">
                                    <span>Unidade</span>
                                </div>
                                <div className="col-3 columnTitleHeaderServiceQueuePreSale justify-content-start">
                                    <span>Último Login</span>
                                </div>
                                <div className="col-3 columnTitleHeaderServiceQueuePreSale justify-content-start">
                                    <span>Turno</span>
                                </div>
                            </div>
                            {
                                this.props.permissions.toView &&
                                <div>
                                    <ReactSortable
                                        list={this.state.allAttendants}
                                        setList={(listChanged) => {}}
                                        onUpdate={(e) => this.message("warning", "Não é possível alterar a posição dos atendentes desta fila manualmente, pois essa fila é manipulada por um bot!")}
                                    >
                                        {
                                            this.state.allAttendants.map((o, i) => {
                                                let nameCompanyUnit = this.state.optionsCompanyUnit.filter(u => u.value === o.idCompanyUnit)[0].name;
                                                return (
                                                    <div key={`${o.id}`}>
                                                        <div 
                                                            className={
                                                                `row no-gutters lineBodyServiceQueuePreSale 
                                                                    ${i} 
                                                                    ${(i === (this.state.allAttendants.length - 1 ))? "final" : ""}
                                                                    ${o.frozen === 1 ? "frozen" : ""}
                                                                `}
                                                        >
                                                            <div className="col-2 columnBodyServiceQueuePreSale">
                                                                <span>{o.position}º</span>
                                                            </div>
                                                            <div className="col-2 columnBodyServiceQueuePreSale justify-content-start">
                                                                <span>{getFullNameFisrtCharsUppercase(o.name)}</span>
                                                            </div>
                                                            <div className="col-2 columnBodyServiceQueuePreSale companyUnit">
                                                                <span className="partialCompanyUnitServiceQueuePreSale">
                                                                    {nameCompanyUnit}
                                                                </span>
                                                                <span className="fullCompanyUnitServiceQueuePreSale">
                                                                    {nameCompanyUnit}
                                                                </span>
                                                            </div>
                                                            <div className="col-3 columnBodyServiceQueuePreSale d-flex justify-content-start">
                                                                <span>{o.lastLogin}</span>
                                                            </div>
                                                            <div className="col-3 columnBodyServiceQueuePreSale d-flex justify-content-start">
                                                                <span>{o.workShifts}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </ReactSortable>
                                </div>
                            }
                        </Box>
                        <br /><br />
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const permissions = state.permissions.preSaleServiceQueue;
    const user = state.auth.user;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        permissions,
        user,
        hasPermissionsLoaded
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
        changeServiceQueue (data) 
        {
            //action creator -> action
            const action = changeServiceQueue(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceQueuePreSale));