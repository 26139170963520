import React, { Component } from "react";
import { connect } from "react-redux";
import "./TableEditCommissionReportManager.css";
import { withRouter } from "react-router-dom";
import { formatedMoneyBRAFromFloat, getWindowDimensions } from "../../auxiliary/generalFunctions";
import Message from "./Message";
import { commissionReport } from "../../core/constants";
import { showModalMoreActions } from "../../store/actions/general";

const initialState = {

    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
  
    // start message modal
    message_type: "information",
    message_show: false,
    message: "",
    // ends message modal

}

class TableEditCommissionReportManager extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });
    }
    
    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT COMMISSION REPORT prevProps ", prevProps);
        // console.log("TABLE EDIT COMMISSION REPORT this.props ", this.props);
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        });
    }

    render ()
    {
        return (
            <div className="tableEditCommissionReportManager">
                <label className="helpTableEditCommissionReport" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <table className="table">
                    <thead>
                        <tr className="theadTableEditCommissionReport" ref={headerTable => {this.headerTable = headerTable}}>
                            <td
                                className="minimus140"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Especialista")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Gerente
                            </td>
                            <td
                                className="minimus140"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Unidade")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Unidade
                            </td>
                            <td
                                className="minimus75 center"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Atendeu")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Atendeu
                            </td>
                            <td
                                className="minimus75 center"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Ganhou")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Ganhou
                            </td>
                            <td
                                className="minimus100 center"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "(%) Aproveitamento")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                (%) Aproveitamento
                            </td>
                            <td
                                className="minimus100 right"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "(R$) Vendeu")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                (R$) Vendeu
                            </td>
                            <td
                                className="minimus100 right"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "(R$) Recebeu")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                (R$) Recebeu
                            </td>
                            <td
                                className="minimus100 center"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "(%) Recebimento")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                (%) Recebimento
                            </td>
                            <td
                                className="minimus75 center"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Meses Portfólio")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Meses Portfólio
                            </td>
                            <td
                                className="minimus100 right"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "(R$) Recebeu Porftólio")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                (R$) Recebeu Porftólio
                            </td>
                            <td
                                className="minimus100 right"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "(R$) Total Recebido")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                (R$) Total Recebido
                            </td>
                            <td
                                className="minimus100 center"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "(%) Comissão")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                (%) Comissão
                            </td>
                            <td
                                className="minimus100 right"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "(R$) Comissão")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                (R$) Comissão
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.data.map((e, i) => {
                                let rowColor = "";
                                if (e.percentUtilization >= e.percentUtilizationMinimumGoal && e.percentReceiving >= e.percentReceivingMinimumGoal)
                                {
                                    rowColor = "success";
                                }
                                else if (e.percentUtilization >= e.percentUtilizationMinimumGoal || e.percentReceiving >= e.percentReceivingMinimumGoal)
                                {
                                    rowColor = "warning";
                                }

                                if (e.log)
                                {
                                    rowColor = "information";
                                }

                                return (
                                    <tr 
                                        key={`${i}`} 
                                        className={`tbodyTableEditCommissionReport`}
                                        style={{
                                            color: `var(--color-${rowColor})`
                                        }}
                                    >
                                        <td 
                                            className={`left ${ this.props.permissions.toUpdate && "hoverEditEffect" }`} 
                                            onClick={() => {
                                                if (!this.props.permissions.toUpdate) 
                                                {
                                                    return;
                                            }

                                                let data = {
                                                    show: true,
                                                    type: commissionReport,
                                                    modeUpdateModal: true,
                                                    data: {
                                                        id: e.idCommissionReport,
                                                        name: e.name,
                                                        amountTotal: e.amountTotalReceived,
                                                        percentCommission: e.percentCommission,
                                                        amountCommission: e.amountCommissionToSeller,
                                                        onSaveCommission: this.props.onSaveCommission
                                                    }
                                                }
                                                this.props.showModalMoreActions(data);
                                            }}
                                        >
                                            {e.name}
                                        </td>
                                        <td className="left">{e.company.name}</td>
                                        <td className="center">{e.amountUtilizationTotal}</td>
                                        <td className="center">{e.amountUtilizationGain}</td>
                                        <td className="center">{e.percentUtilization}%</td>
                                        <td className="right">{formatedMoneyBRAFromFloat(e.amountReceivingSold)}</td>
                                        <td className="right">{formatedMoneyBRAFromFloat(e.amountRecevingReceived)}</td>
                                        <td className="center">{e.percentReceiving}%</td>
                                        <td className="center">{e.monthsPortfolio}</td>
                                        <td className="right">{formatedMoneyBRAFromFloat(e.amountRecevingReceivedOnMonthsPortfolio)}</td>
                                        <td className="right">{formatedMoneyBRAFromFloat(e.amountTotalReceived)}</td>
                                        <td className="center" title={e.log && `Anterior: ${parseFloat(e.log.oldCommissionPercent).toFixed(2)} %`}>{formatedMoneyBRAFromFloat(parseFloat(e.percentCommission))} %</td>
                                        <td className="right" title={e.log && `Anterior: R$ ${formatedMoneyBRAFromFloat(parseFloat(e.log.oldCommissionAmount))}`}>{formatedMoneyBRAFromFloat(e.amountCommissionToSeller)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr 
                            className={`tfootTableEditCommissionReport`}
                        >
                            <td className="left">TOTAIS</td>
                            <td className="left"></td>
                            <td className="center">{this.props.data.reduce((total, e) => total += e.amountUtilizationTotal, 0)}</td>
                            <td className="center">{this.props.data.reduce((total, e) => total += e.amountUtilizationGain, 0)}</td>
                            <td className="center"></td>
                            <td className="right">{formatedMoneyBRAFromFloat(this.props.data.reduce((total, e) => total += e.amountReceivingSold, 0))}</td>
                            <td className="right">{formatedMoneyBRAFromFloat(this.props.data.reduce((total, e) => total += e.amountRecevingReceived, 0))}</td>
                            <td className="center"></td>
                            <td className="center"></td>
                            <td className="right">{formatedMoneyBRAFromFloat(this.props.data.reduce((total, e) => total += e.amountRecevingReceivedOnMonthsPortfolio, 0))}</td>
                            <td className="right">{formatedMoneyBRAFromFloat(this.props.data.reduce((total, e) => total += e.amountTotalReceived, 0))}</td>
                            <td className="center"></td>
                            <td className="right">{formatedMoneyBRAFromFloat(this.props.data.reduce((total, e) => total += e.amountCommissionToSeller, 0))}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        )
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(null, mapDispatchToProps)(TableEditCommissionReportManager));