import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class LogsConnectController
{
    async getByFilters(filters = null)
    {
        const response = await api.logsConnect().getByFilters(filters);
        return apiCallbackTreatment(response);
    }

    async unlock(logId, userLoggedId) 
    {
        const response = await api.logsConnect().unlock(logId, {
            LCC_ID_PERSON_WHO_UPDATED: userLoggedId
        });
        return apiCallbackTreatment(response);
    }
}