import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./AdvancedBusiness.css";

import { ADVANCED_BUSINESS_OPTION_SELECTED_COLUMNS, ADVANCED_BUSINESS_OPTION_SELECTED_FILTERS, APPLICATION_NAME, FILES_TYPE_ACTIVITY, HISTORIC_TYPE_ACTIVITY, ID_BRAND_USE_GROUP_2_ON_REASONS_FOR_LOSS, PHONE_TYPE_ACTIVITY, STATUS_BUSINESS_GAIN, STATUS_BUSINESS_LOSS, STATUS_BUSINESS_PAUSED, TASK_TYPE_ACTIVITY, TRANSFER_TYPE_ACTIVITY, VISIT_TYPE_ACTIVITY } from "../../core/constants";
import { formatedDateTime, formatedMoneyBRAFromFloat, formatedTime, getOptionsSelectCheckedFormated, getWindowDimensions, validTime } from "../../auxiliary/generalFunctions";

import Header from "../../components/CRM/Header";
import ButtonIcon from "../../components/general/ButtonIcon";
import MenuRight from "../../components/general/MenuRight";
import TableEditAdvancedBusiness from "../../components/general/TableEditAdvancedBusiness";
import Check from "../../components/general/Check";
import Button from "../../components/general/Button";
import SelectMulti from "../../components/general/SelectMulti";
import InputGeneral from "../../components/general/InputGeneral";
import Loading from "../../components/general/Loading";

import BusinessController from "../../controllers/BusinessController";
import BusinessReasonsForLossController from "../../controllers/BusinessReasonsForLossController";
import CampaignController from "../../controllers/CampaignController";
import MidiaOriginController from "../../controllers/MidiaOriginController";
import ProspectionTeamHierarchyController from "../../controllers/ProspectionTeamHierarchyController";
import UserController from "../../controllers/UserController";

const businessController = new BusinessController();
const businessReasonsForLossController = new BusinessReasonsForLossController();
const campaignController = new CampaignController();
const midiaOriginController = new MidiaOriginController();
const prospectionTeamHierarchyController = new ProspectionTeamHierarchyController();
const userController = new UserController();

const initialState = {
    business: [],
    columns: [],

    loading: false,

    isMenuOpen: false,
    menuViewOptionSelected: ADVANCED_BUSINESS_OPTION_SELECTED_FILTERS,
    currentPage: 1,
    totalPages: 0,
    countTotalBusiness: 0,

    // starts filters
    filterCreateDateStart: "",
    filterCreateDateEnds: "",
    filterCreateTimeStart: "00:00",
    filterCreateTimeEnds: "23:59",
    filterLeadCreateDateStart: "",
    filterLeadCreateTimeStart: "00:00",
    filterLeadCreateDateEnds: "",
    filterLeadCreateTimeEnds: "23:59",
    filterSearchClient: "",
    filterStatusDateStart: "",
    filterStatusDateEnds: "",
    filterStatusTimeStart: "00:00",
    filterStatusTimeEnds: "23:59",
    filterActivityDateCreatedStart: "",
    filterActivityDateCreatedEnds: "",
    filterActivityTimeCreatedStart: "00:00",
    filterActivityTimeCreatedEnds: "23:59",
    filterActivityDateStart: "",
    filterActivityDateEnds: "",
    filterActivityTimeStart: "00:00",
    filterActivityTimeEnds: "23:59",
    onlyBusinessWithAttendant: false,
    onlyBusinessWithOwner: false,
    onlyBusinessWithoutVisit: false,
    onlyBusinessWithActivities: false,
    optionsCampaigns: [],
    optionsCompanyUnits: [],
    optionsCoordinators: [],
    optionsMidiaOrigin: [],
    optionsOwners: [],
    optionsOwnersFiltered: [],
    optionsProspectionOwners: [],
    optionsReasonsForLoss: [],
    optionsActivityCreatedBy: [],
    optionsShowedup: [],
    optionsSteps: [],
    optionsStatus: [],
    optionsSupervisors: [],
    optionsTypeActivity: [],
    optionsTypeVisitBusiness: [],
    withGroupBy: true
    // ends filters
}

class AdvancedBusiness extends Component 
{
    state = { ...initialState }

    componentDidMount = async () =>
    {
        document.title = `${APPLICATION_NAME} - Negócios`;
        await this.getColumns();
        if (this.props.permissions.hasPermissionsLoaded) 
        {
            await this.getFilters();
            await this.verifyParamsURL();
        }
    }

    componentDidUpdate = async (prevProps, prevState) =>
    {
        if (prevProps.permissions.hasPermissionsLoaded !== this.props.permissions.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.advancedBusiness.toView) { this.props.history.push(`/activities`); }
            await this.getFilters();
            await this.verifyParamsURL();
		}
    }

    applyFilters = async () =>
    {
        const rowHeight = 32;
        const { height } = getWindowDimensions();
        const NUMBER_ROWS_PER_PAGE = Math.ceil(((height - 56 - 65 - rowHeight) / rowHeight) * 1.5);

        let filters = {
            USER_ID: this.props.user.PER_ID,
            ONLY_BUSINESS_WITH_OWNER: this.state.onlyBusinessWithOwner,
            ONLY_BUSINESS_WITH_ATTENDANT: this.state.onlyBusinessWithAttendant,
            ONLY_BUSINESS_WITH_ACTIVITIES: this.state.onlyBusinessWithActivities,
            ONLY_BUSINESS_WITHOUT_VISIT: this.state.onlyBusinessWithoutVisit,
            PAGINATE: true,
            NUMBER_ROWS_PER_PAGE,
            PAGE: this.state.currentPage,
            WITH_GROUP_BY: this.state.withGroupBy === true ? 1 : 2
        };

        const dateCreatedStart = this.state.filterCreateDateStart;
        const dateCreatedEnds = this.state.filterCreateDateEnds;
        const dateLeadCreatedStart = this.state.filterLeadCreateDateStart;
        const dateLeadCreatedEnds = this.state.filterLeadCreateDateEnds;
        const dateClosedStart = this.state.filterStatusDateStart;
        const dateClosedEnds = this.state.filterStatusDateEnds;
        const optionsCampaigns = this.state.optionsCampaigns.filter(c => c.checked).map(c => c.value);
        const optionsCompanyUnits = this.state.optionsCompanyUnits.filter(c => c.checked).map(c => c.value);
        const optionsMidiaOrigin = this.state.optionsMidiaOrigin.filter(m => m.checked).map(m => m.value);
        const optionsOwners = this.state.optionsOwnersFiltered.filter(o => o.checked).map(o => o.value);
        const optionsProspectionOwners = this.state.optionsProspectionOwners.filter(p => p.checked).map(p => p.value);
        const optionsReasonsForLoss = this.state.optionsReasonsForLoss.filter(r => r.checked).map(r => r.value);
        const optionsStatus = this.state.optionsStatus.filter(s => s.checked).map(s => s.value);
        const optionsSteps = this.state.optionsSteps.filter(s => s.checked).map(s => s.value);
        const searchClientName = this.state.filterSearchClient;
        const timeCreatedStart = this.state.filterCreateTimeStart;
        const timeCreatedEnds = this.state.filterCreateTimeEnds;
        const timeLeadCreatedStart = this.state.filterLeadCreateTimeStart;
        const timeLeadCreatedEnds = this.state.filterLeadCreateTimeEnds;
        const timeStatusStart = this.state.filterStatusTimeStart;
        const timeStatusEnds = this.state.filterStatusTimeEnds;

        dateCreatedStart !== "" && (filters.BUS_DATE_CREATED_START = dateCreatedStart);
        dateCreatedEnds !== "" && (filters.BUS_DATE_CREATED_ENDS = dateCreatedEnds);
        dateLeadCreatedStart !== "" && (filters.LEAD_DATE_CREATED_START = dateLeadCreatedStart);
        dateLeadCreatedEnds !== "" && (filters.LEAD_DATE_CREATED_ENDS = dateLeadCreatedEnds);
        dateClosedStart !== "" && (filters.BUS_STATUS_DATE_START = dateClosedStart);
        dateClosedEnds !== "" && (filters.BUS_STATUS_DATE_ENDS = dateClosedEnds);
        optionsCampaigns.length > 0 && (filters.PER_CAMPAIGN_NAME = optionsCampaigns);
        optionsCompanyUnits.length > 0 && (filters.PER_ID_COMPANY_UNIT = optionsCompanyUnits);
        optionsMidiaOrigin.length > 0 && (filters.PER_ID_MIDIA_ORIGIN = optionsMidiaOrigin);
        optionsOwners.length > 0 && (filters.OWNERS = optionsOwners);
        optionsProspectionOwners.length > 0 && (filters.PROSPECTION_OWNERS = optionsProspectionOwners);
        optionsReasonsForLoss.length > 0 && (filters.BUS_ID_REASON_FOR_LOSS = optionsReasonsForLoss);
        optionsStatus.length > 0 && (filters.BUS_STATUS = optionsStatus);
        optionsSteps.length > 0 && (filters.BUS_STEP = optionsSteps);
        searchClientName.length > 0 && (filters.SEARCH = searchClientName);
        timeCreatedStart !== "" && (filters.CREATED_TIME_START = timeCreatedStart);
        timeCreatedEnds !== "" && (filters.CREATED_TIME_ENDS = timeCreatedEnds);
        timeLeadCreatedStart !== "" && (filters.LEAD_CREATED_TIME_START = timeLeadCreatedStart);
        timeLeadCreatedEnds !== "" && (filters.LEAD_CREATED_TIME_ENDS = timeLeadCreatedEnds);
        timeStatusStart !== "" && (filters.STATUS_TIME_START = timeStatusStart);
        timeStatusEnds !== "" && (filters.STATUS_TIME_ENDS = timeStatusEnds);

        if (this.state.onlyBusinessWithActivities)
        {
            const activityDateCreatedStart = this.state.filterActivityDateCreatedStart;
            const activityDateCreatedEnds = this.state.filterActivityDateCreatedEnds;
            const activityDateStart = this.state.filterActivityDateStart;
            const activityDateEnds = this.state.filterActivityDateEnds;
            const activityTimeCreatedStart = this.state.filterActivityTimeCreatedStart;
            const activityTimeCreatedEmds = this.state.filterActivityTimeCreatedEnds;
            const activityTimeStart = this.state.filterActivityTimeStart;
            const activityTimeEnds = this.state.filterActivityTimeEnds;
            const optionsActivityCreatedBy = this.state.optionsActivityCreatedBy.filter(c => c.checked).map(c => c.value);
            const optionsCoordinators = this.state.optionsCoordinators.filter(s => s.checked).map(s => s.value);
            const optionsShowedup = this.state.optionsShowedup.filter(s => s.checked).map(s => s.value);
            const optionsSupervisors = this.state.optionsSupervisors.filter(s => s.checked).map(s => s.value);
            const optionsTypeActivity = this.state.optionsTypeActivity.filter(t => t.checked).map(t => t.value);
            const optionsTypeVisitBusiness = this.state.optionsTypeVisitBusiness.find(t => t.checked);

            activityDateCreatedStart !== "" && (filters.ACTIVITY_DATE_CREATED_START = activityDateCreatedStart);
            activityDateCreatedEnds !== "" && (filters.ACTIVITY_DATE_CREATED_ENDS = activityDateCreatedEnds);
            activityDateStart !== "" && (filters.ACTIVITY_DATE_START = activityDateStart);
            activityDateEnds !== "" && (filters.ACTIVITY_DATE_ENDS = activityDateEnds);
            activityTimeCreatedStart !== "" && (filters.ACTIVITY_TIME_CREATED_START = activityTimeCreatedStart);
            activityTimeCreatedEmds !== "" && (filters.ACTIVITY_TIME_CREATED_ENDS = activityTimeCreatedEmds);
            activityTimeStart !== "" && (filters.ACTIVITY_TIME_START = activityTimeStart);
            activityTimeEnds !== "" && (filters.ACTIVITY_TIME_ENDS = activityTimeEnds);
            optionsActivityCreatedBy.length > 0 && (filters.ACTIVITY_CREATED_BY = optionsActivityCreatedBy);
            optionsCoordinators.length > 0 && (filters.COORDINATORS = optionsCoordinators);
            optionsShowedup.length > 0 && (filters.ACT_IS_SHOWEDUP = optionsShowedup);
            optionsSupervisors.length > 0 && (filters.SUPERVISORS = optionsSupervisors);
            optionsTypeActivity.length > 0 && (filters.ACTIVITY_TYPE = optionsTypeActivity);
            optionsTypeVisitBusiness && (filters.TYPE_PERIOD_VISIT_BUSINESS = optionsTypeVisitBusiness.value);
        }

        await this.getBusiness(filters);

    }

    getBusiness = async (filters) =>
    {
        await this.setState({ loading: true });

        const result = await businessController.getByFilters(filters);

        if (!result.status) 
        {
            return;
        }

        const data = result.data.data;
        let rows = [];
        for (const business of data) 
        {
            const prospectionOwner = business.BUS_ID_PERSON_PROSPECTION_OWNER === null ? null : {
                id: business.BUS_ID_PERSON_PROSPECTION_OWNER,
                name: business.PROSPECTION_OWNER_NAME
            }

            const owner = business.BUS_ID_PERSON_OWNER === null ? null : {
                id: business.BUS_ID_PERSON_OWNER,
                name: business.OWNER_NAME
            }

            const reasonForLoss = business.BUS_ID_REASON_FOR_LOSS === null ? null : {
                id: business.BUS_ID_REASON_FOR_LOSS,
                name: business.REASON_FOR_LOSS_TITLE
            }

            const activityCreatedBy = business?.ACT_ID_CREATE_PERSON ? {
                    id: business.ACT_ID_CREATE_PERSON,
                    name: business.ACT_CREATE_NAME
                } 
                : null;

            const showedup = business?.ACT_IS_SHOWEDUP ? {
                    value: business.ACT_IS_SHOWEDUP,
                    name: this.getShowedupName(business.ACT_IS_SHOWEDUP),
                    icon: this.getShowedupIcon(business.ACT_IS_SHOWEDUP)
                } 
                : null;

            rows.push({
                id: business.BUS_ID,
                title: business.BUS_TITLE,
                money: formatedMoneyBRAFromFloat(parseFloat(business.BUS_MONEY_VALUE)),
                createdAt: formatedDateTime(business.BUS_DATE_CREATED),
                showedup,
                step: {
                    id: business.BUS_STEP,
                    name: this.getStepName(business.BUS_STEP)
                },
                status: {
                    name: this.getStatusName(business.BUS_STATUS),
                    icon: this.getStatusIcon(business.BUS_STATUS)
                },
                statusDate: business.BUS_STATUS_DATE ? formatedDateTime(business.BUS_STATUS_DATE) : null,
                client: {
                    id: business.PER_ID,
                    name: business.PER_NAME,
                    phoneOne: business.PER_PHONE_ONE,
                    email: business.PER_MAIL
                },
                companyUnit: {
                    id: business.PER_ID_COMPANY_UNIT,
                    name: business.UNT_NAME
                },
                prospectionOwner,
                owner,
                reasonForLoss,
                activityCreatedBy,
                idServiceERP: business.BUS_ID_SERVICE_ERP,
                stamp: {
                    id: business.BUS_ID_STAMP,
                    name: business.STA_NAME,
                    style: business.STA_STYLE
                },
                campaign: business.PER_CAMPAIGN_NAME,
                midiaOrigin: {
                    id: business.PER_ID_MIDIA_ORIGIN,
                    name: business.MIDIA_ORIGIN_NAME,
                    icon: business.MIDIA_ORIGIN_ICON ?? "fas fa-minus"
                }
            });
        }

        const countTotalBusiness = result.data._paginate.total_rows;
        const totalPages = result.data._paginate.total_pages;

        if (this.state.currentPage > 1)
        {
            rows = [
                ...this.state.business,
                ...rows
            ]
        }

        await this.setState({business: rows, countTotalBusiness, totalPages, loading: false});
    }

    getColumns = async () =>
    {
        const columnsInLocalStorage = JSON.parse(localStorage.getItem("CRM_SEMPNET_BUSINESS_ADVANCED_COLUMNS"));
        let columns = [
            {
                name: "ID",
                slug: "ID",
                visible: true
            },
            {
                name: "Título",
                slug: "TITLE",
                visible: true
            },
            {
                name: "Status",
                slug: "STATUS",
                visible: true
            },
            {
                name: "Data Status",
                slug: "STATUS_DATE",
                visible: false
            },
            {
                name: "Compareceu",
                slug: "SHOWEDUP",
                visible: false
            },
            {
                name: "Motivo da perda",
                slug: "REASON_FOR_LOSS",
                visible: false
            },
            {
                name: "ID Serviço ERP",
                slug: "ID_SERVICE_ERP",
                visible: false
            },
            {
                name: "Etapa",
                slug: "STEP",
                visible: false
            },
            {
                name: "Valor",
                slug: "MONEY",
                visible: true
            },
            {
                name: "Pessoa de Contato",
                slug: "CLIENT",
                visible: true
            },
            {
                name: "E-mail",
                slug: "EMAIL",
                visible: false
            },
            {
                name: "Telefone",
                slug: "PHONE_ONE",
                visible: false
            },
            {
                name: "Atendente",
                slug: "PROSPECTION_OWNER",
                visible: true
            },
            {
                name: "Atividade Criada Por",
                slug: "ACTIVITY_CREATED_BY",
                visible: false
            },
            {
                name: "Proprietário",
                slug: "OWNER",
                visible: true
            },
            {
                name: "Campanha",
                slug: "CAMPAIGN",
                visible: false
            },
            {
                name: "Fonte",
                slug: "MIDIA_ORIGIN",
                visible: false
            },
            {
                name: "Etiqueta",
                slug: "STAMP",
                visible: false
            },
            {
                name: "Unidade",
                slug: "COMPANY_UNIT",
                visible: false
            },
            {
                name: "Criado em",
                slug: "CREATED_AT",
                visible: true
            },
        ];
        if (columnsInLocalStorage) 
        {
            const allVisibleColumns = columnsInLocalStorage.filter(c => c.visible).map(c => c.slug);
            columns = columns.map(c => allVisibleColumns.includes(c.slug) ? {...c, visible: true} : {...c, visible: false});
        }
        await this.setState({columns});
    }

    getFilters = async () =>
    {
        const user = await userController.getById(this.props.user.PER_ID);
        let group = 1;

        const hasGroupInUnits = user.data.unit_companies.some(u => u.BNT_ID === ID_BRAND_USE_GROUP_2_ON_REASONS_FOR_LOSS);

        if (hasGroupInUnits)
        {
            group = null;

            if (user.data.unit_companies.length === 1)
            {
                group = 2;
            }
        }

        const 
            [
                resultCampaigns, 
                resultCompanyUnit, 
                resultMidiaOrigin, 
                resultProspectionOwner, 
                resultReasonsForLoss,
            ] 
                = await Promise.all(
            [
                campaignController.getFromClients(this.props.user.PER_ID),
                userController.getOwnersForUserOnAccess(this.props.user.PER_ID),
                midiaOriginController.get(),
                prospectionTeamHierarchyController.get(),
                businessReasonsForLossController.get(group)
            ]
        );

        const userUnits = user.data.unit_companies.map(u => u.UNT_ID);

        const optionsCampaigns = [];
        for (const campaign of resultCampaigns.data.data) 
        {
            optionsCampaigns.push({
                name: campaign.PER_CAMPAIGN_NAME,
                value: campaign.PER_CAMPAIGN_NAME,
                checked: false
            });
        }

        const optionsMidiaOrigin = [];
        for (const midiaOrigin of resultMidiaOrigin.data.data) 
        {
            optionsMidiaOrigin.push({
                name: midiaOrigin.MID_NAME,
                value: midiaOrigin.MID_ID,
                checked: false
            });
        }

        const optionsCompanyUnits = [];
        const optionsOwners = [];
        const allUnits = resultCompanyUnit.data.data.filter(u => u.UNT_STATUS === 1);
            
        for (const unit of allUnits) 
        {
            optionsCompanyUnits.push({
                name: unit.UNT_NAME,
                value: unit.UNT_ID,
                checked: allUnits.length === 1 ? true : false
            });

            for (const owner of unit.owners)
            {
                if (owner.PER_STATUS === 1)
                {
                    optionsOwners.push({
                        id: owner.PER_ID,
                        name: owner.PER_NAME,
                        value: owner.PER_ID,
                        idCompanyUnit: unit.UNT_ID,
                        checked: false
                    });
                }
            }
        }

        optionsOwners.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return 0;
            }
        });

        optionsOwners.unshift({
            id: 0,
            name: "[SEM PROPRIETÁRIO]",
            value: 0,
            idCompanyUnit: null,
            checked: false
        });

        const optionsProspectionOwners = prospectionTeamHierarchyController.getProspectionOwners(resultProspectionOwner.data.data.coordinators, this.props.user.PER_ID, this.props.permissions.advancedBusiness, userUnits);

        const optionsReasonsForLoss = [];
        for (const reason of resultReasonsForLoss.data.data) 
        {
            if (reason.BUL_STATUS === 1)
            {
                optionsReasonsForLoss.push({
                    id: reason.BUL_ID,
                    name: reason.BUL_TITLE,
                    value: reason.BUL_ID,
                    checked: false
                });
            }
        }

        optionsReasonsForLoss.unshift({
            id: 0,
            name: "[SEM MOTIVO]",
            value: 0,
            checked: false
        });

        let optionsActivityCreatedBy = prospectionTeamHierarchyController.getProspectionOwners(resultProspectionOwner.data.data.coordinators, this.props.user.PER_ID, this.props.permissions.advancedBusiness, userUnits);

        optionsActivityCreatedBy = optionsActivityCreatedBy.filter(o => o.value !== 0);

        const optionsShowedup = [
            {
                name: "Compareceu",
                value: 1,
                checked: false
            },
            {
                name: "Compareceu ganhou",
                value: 3,
                checked: false
            },
            {
                name: "Não compareceu",
                value: 2,
                checked: false
            }
        ];

        const optionsStatus = [
            {
                name: "Em Andamento",
                value: "OPEN",
                checked: false
            },
            {
                name: "Ganhos",
                value: STATUS_BUSINESS_GAIN,
                checked: false
            },
            {
                name: "Perdidos",
                value: STATUS_BUSINESS_LOSS,
                checked: false
            },
            {
                name: "Pausados",
                value: STATUS_BUSINESS_PAUSED,
                checked: false
            }
        ];

        const optionsSteps = [
            {
                name: "Lead",
                value: 1,
                checked: false
            },
            {
                name: "Primeiro Contato",
                value: 2,
                checked: false
            },
            {
                name: "Atendimento Presencial",
                value: 3,
                checked: false
            },
            {
                name: "Fechamento",
                value: 4,
                checked: false
            },
            {
                name: "Reagendar",
                value: 5,
                checked: false
            }
        ];

        let optionsCoordinators = [];
        let optionsSupervisors = [];
        for (const coordinator of resultProspectionOwner.data.data.coordinators) 
        {
            let allowedCoordinator = false;
            for (const unit of coordinator.units)
            {
                if (!allowedCoordinator)
                {
                    allowedCoordinator = userUnits.some(u => u === unit.UNT_ID);
                }
            }

            if (allowedCoordinator)
            {
                optionsCoordinators.push({
                    name: coordinator.name,
                    value: coordinator.id,
                    checked: false
                });
            }
            
            for (const supervisor of coordinator.supervisors) 
            {
                let allowedSupervisor = false;
                for (const unit of supervisor.units)
                {
                    if (!allowedSupervisor)
                    {
                        allowedSupervisor = userUnits.some(u => u === unit.UNT_ID);
                    }
                }
                                
                if (allowedSupervisor)
                {
                    optionsSupervisors.push({
                        name: supervisor.name,
                        value: supervisor.id,
                        checked: false
                    });
                }
            }
        }

        optionsCoordinators = optionsCoordinators.sort((a, b) => {
            if (a.name < b.name)
            {
                return -1;
            }
            else if (a.name > b.name)
            {
                return 1;
            }
            else
            {
                return 0;
            }
        });

        optionsSupervisors = optionsSupervisors.sort((a, b) => {
            if (a.name < b.name)
            {
                return -1;
            }
            else if (a.name > b.name)
            {
                return 1;
            }
            else
            {
                return 0;
            }
        });

        const optionsTypeActivity = [
            {
                checked: false,
                name: "Arquivos",
                value: FILES_TYPE_ACTIVITY
            },
            {
                checked: false,
                name: "Informação",
                value: HISTORIC_TYPE_ACTIVITY
            },
            {
                checked: false,
                name: "Ligar",
                value: PHONE_TYPE_ACTIVITY
            },
            {
                checked: false,
                name: "Tarefa",
                value: TASK_TYPE_ACTIVITY
            },
            {
                checked: false,
                name: "Transferência",
                value: TRANSFER_TYPE_ACTIVITY
            },
            {
                checked: false,
                name: "Visita",
                value: VISIT_TYPE_ACTIVITY
            }
        ];

        const optionsTypeVisitBusiness = [
            {
                name: "No mesmo dia do negócio",
                value: "SAME_PERIOD",
                checked: false
            },
            {
                name: "Em um dia diferente do negócio",
                value: "ANOTHER_PERIOD",
                checked: false
            },
        ];

        await this.setState({
            optionsCampaigns,
            optionsCompanyUnits,
            optionsCoordinators,
            optionsMidiaOrigin,
            optionsOwners,
            optionsProspectionOwners,
            optionsReasonsForLoss,
            optionsActivityCreatedBy,
            optionsShowedup,
            optionsStatus,
            optionsSteps,
            optionsSupervisors,
            optionsTypeActivity,
            optionsTypeVisitBusiness
        });

        await this.getOptionsOwnersFiltered();
    }

    getMenuTitle = () => 
    {
        switch(this.state.menuViewOptionSelected)
        {
            case ADVANCED_BUSINESS_OPTION_SELECTED_COLUMNS:
                return "Configurar colunas";
            case ADVANCED_BUSINESS_OPTION_SELECTED_FILTERS:
                return "Filtros";
            default:
                return "";
        }
    }

    getOptionsOwnersFiltered = async () => 
    {
        const checkedCompanyUnits = this.state.optionsCompanyUnits.filter(u => u.checked).map(u => u.value);
        if (checkedCompanyUnits.length === 0) 
        {
            await this.setState({
                optionsOwnersFiltered: this.state.optionsOwners
            });
            return;
        }

        const owners = this.state.optionsOwners.filter(o => checkedCompanyUnits.includes(o.idCompanyUnit));

        await this.setState({
            optionsOwnersFiltered: owners
        });

    }

    getShowedupName = showedup =>
    {
        switch(showedup)
        {
            case 1:
                return "Compareceu";
            case 2:
                return "Não compareceu";
            default: 
                return "";
        }
    }

    getShowedupIcon = showedup =>
    {
        switch(showedup)
        {
            case 1:
            case 3:
                return "far fa-user-check";
            case 2:
                return "far fa-user-slash";
            default: 
                return "";
        }
    }

    getStatusName = status => 
    {
        switch(status)
        {
            case STATUS_BUSINESS_GAIN:
                return "Ganho";
            case STATUS_BUSINESS_LOSS:
                return "Perdido";
            case STATUS_BUSINESS_PAUSED:
                return "Pausado";
            default:
                return "Em andamento";
        }
    }

    getStatusIcon = status =>
    {
        switch (status) 
        {
            case STATUS_BUSINESS_GAIN:
                return "fas fa-trophy-alt";
            case STATUS_BUSINESS_LOSS:
                return "fas fa-do-not-enter";
            case STATUS_BUSINESS_PAUSED:
                return "far fa-pause-circle";
            default:
                return "far fa-running";
        }
    }

    getStepName = step =>
    {
        switch(step) 
        {
            case 1:
                return "Lead";
            case 2:
                return "Primeiro Contato";
            case 3:
                return "Atendimento Presencial";
            case 4:
                return "Fechamento";
            case 5:
                return "Reagendar";
            default:
                return "";
        }
    }

    handleChangeColumns = async (slug) =>
    {
        const updatedColumns = this.state.columns.map(c => c.slug === slug ? {...c, visible: !c.visible} : c);
        await this.setState({ columns: updatedColumns });
        localStorage.setItem("CRM_SEMPNET_BUSINESS_ADVANCED_COLUMNS", JSON.stringify(updatedColumns));
    }

    verifyParamsURL = async () =>
    {
        const query = new URLSearchParams(this.props.location.search);
        const type = query.get('type');

        let activityCreatedBy = query.get('activity_created_by');
        let companyUnit = query.get('company_unit');
        const groupBy = query.get('group_by');
        const onlyBusinessWithAttendant = query.get('only_business_with_attendant');
        const onlyBusinessWithOwner = query.get('only_business_with_owner');
        const createDateStart = query.get('create_date_start');
        const createDateEnds = query.get('create_date_ends');
        const midiaOrigin = query.get('midia_origin');
        const activityDateCreatedStart = query.get('activity_create_date_start');
        const activityDateCreatedEnds = query.get('activity_create_date_ends');
        const activityDateStart = query.get('activity_date_start');
        const activityDateEnds = query.get('activity_date_ends');
        const statusDateStart = query.get('status_date_start');
        const statusDateEnds = query.get('status_date_ends');
        let prospectionOwners = query.get('prospection_owner');
        const reasonForLoss = query.get('reason_for_loss');
        let coordinator = query.get('coordinator');
        let showedup = query.get('showedup');
        let status = query.get('status');
        let supervisor = query.get('supervisor');
        const timeStart = query.get('time_start');
        const timeEnds = query.get('time_ends');
        let typeActivity = query.get('type_activity');
        const typePeriodVisit = query.get('type_visit_period');
        const withActivities = query.get('with_activities');

        let filtered = false;

        if (type)
        {
            filtered = true;
            switch(type)
            {
                case "all":
                    const optionsReasonsForLossAll = this.state.optionsReasonsForLoss.map(r => ({ ...r, checked: ![3,21,22].includes(r.value) }));
                    await this.setState({ 
                        optionsReasonsForLoss: optionsReasonsForLossAll 
                    });
                    break;
                case "in_progress_attendance":
                    const optionsStatusInProgressAttendance = this.state.optionsStatus.map(s => ({ ...s, checked: s.value === "OPEN" }));
                    const optionsOwnersInProgressAttendance = this.state.optionsOwnersFiltered.map(o => ({ ...o, checked: o.value === 0 }));
                    const optionsProspectionOwnersInProgressAttendance = this.state.optionsProspectionOwners.map(p => ({ ...p, checked: p.value !== 0 }));
                    await this.setState({ 
                        optionsStatus: optionsStatusInProgressAttendance, 
                        optionsOwnersFiltered: optionsOwnersInProgressAttendance, 
                        optionsProspectionOwners: optionsProspectionOwnersInProgressAttendance 
                    });
                    break;
                case "loss":
                    const optionsStatusLoss = this.state.optionsStatus.map(s => ({ ...s, checked: s.value === STATUS_BUSINESS_LOSS }));
                    const optionsReasonsForLossLoss = this.state.optionsReasonsForLoss.map(r => ({ ...r, checked: ![3,7,8,12,13,18,19,20,21,22,23].includes(r.value) }));
                    await this.setState({ 
                        optionsStatus: optionsStatusLoss, 
                        optionsReasonsForLoss: optionsReasonsForLossLoss 
                    });
                    break;
                case "not_applicable":
                    const optionsReasonsForLossNotApplicable = this.state.optionsReasonsForLoss.map(r => ({ ...r, checked: [7,8,12,13,18,19,20,23].includes(r.value) }));
                    const optionsStatusNotApplicable = this.state.optionsStatus.map(s => ({ ...s, checked: s.value === STATUS_BUSINESS_LOSS }));
                    await this.setState({ 
                        optionsStatus: optionsStatusNotApplicable, 
                        optionsReasonsForLoss: optionsReasonsForLossNotApplicable 
                    });
                    break;
                case "discarded":
                    const optionsReasonsForLossDiscarded = this.state.optionsReasonsForLoss.map(r => ({ ...r, checked: [3,21,22].includes(r.value) }));
                    await this.setState({ 
                        optionsReasonsForLoss: optionsReasonsForLossDiscarded 
                    });
                    break;
                case "paused":
                    const optionsStatusPaused = this.state.optionsStatus.map(s => ({ ...s, checked: s.value === STATUS_BUSINESS_PAUSED }));
                    await this.setState({ optionsStatus: optionsStatusPaused });
                    break;
                case "scheduled":
                    const optionsTypeActivityScheduled = this.state.optionsTypeActivity.map(t => ({ ...t, checked: t.value === VISIT_TYPE_ACTIVITY }));
                    await this.setState({ onlyBusinessWithActivities: true, optionsTypeActivity: optionsTypeActivityScheduled });
                    break;
                case "gain_units":
                    const optionsStatusGainUnits = this.state.optionsStatus.map(s => ({ ...s, checked: s.value === STATUS_BUSINESS_GAIN}));
                    await this.setState({ onlyBusinessWithoutVisit: true, optionsStatus: optionsStatusGainUnits });
                    break;
                case "without_attendance":
                    const optionsStatusWithoutAttendance = this.state.optionsStatus.map(s => ({ ...s, checked: s.value === "OPEN" }));
                    const optionsProspectionOwnersWithoutAttendance = this.state.optionsProspectionOwners.map(p => ({ ...p, checked: p.value === 0 }));
                    await this.setState({ 
                        optionsStatus: optionsStatusWithoutAttendance, 
                        optionsProspectionOwners: optionsProspectionOwnersWithoutAttendance 
                    });
                    break;
                case "showed_up":
                    const optionsShowedup = this.state.optionsShowedup.map(s => ({ ...s, checked: s.value === 1 }));
                    const optionsTypeActivityShowedup = this.state.optionsTypeActivity.map(t => ({ ...t, checked: t.value === VISIT_TYPE_ACTIVITY }));
                    await this.setState({ 
                        optionsShowedup,
                        onlyBusinessWithActivities: true,
                        optionsTypeActivity: optionsTypeActivityShowedup
                    });
                    break;
                case "no_showed_up":
                    const optionsNoShowedup = this.state.optionsShowedup.map(s => ({ ...s, checked: s.value === 2 }));
                    const optionsTypeActivityNoShowedup = this.state.optionsTypeActivity.map(t => ({ ...t, checked: t.value === VISIT_TYPE_ACTIVITY }));
                    await this.setState({ 
                        optionsShowedup: optionsNoShowedup,
                        onlyBusinessWithActivities: true,
                        optionsTypeActivity: optionsTypeActivityNoShowedup
                    });
                    break;
                case "showed_up_or_no_showed_up":
                    const optionsShowedupOrNoShowedup = this.state.optionsShowedup.map(s => ({ ...s, checked: true }));
                    const optionsTypeActivityShowedupOrNoShowedup = this.state.optionsTypeActivity.map(t => ({ ...t, checked: t.value === VISIT_TYPE_ACTIVITY }));
                    await this.setState({ 
                        optionsShowedup: optionsShowedupOrNoShowedup,
                        onlyBusinessWithActivities: true,
                        optionsTypeActivity: optionsTypeActivityShowedupOrNoShowedup
                    });
                    break;
                default:
                    break;
            }
        }

        if (activityCreatedBy)
        {
            filtered = true;
            activityCreatedBy = activityCreatedBy.split(",").map(u => parseInt(u));

            let optionsActivityCreatedBy = [];
            for (const po of this.state.optionsActivityCreatedBy)
            {
                optionsActivityCreatedBy.push({
                    ...po,
                    checked: activityCreatedBy.includes(po.value)
                });
            }

            await this.setState({
                optionsActivityCreatedBy
            });
        }

        if (activityDateCreatedStart)
        {
            filtered = true;
            await this.setState({filterActivityDateCreatedStart: activityDateCreatedStart});
        }

        if (activityDateCreatedEnds)
        {
            filtered = true;
            await this.setState({filterActivityDateCreatedEnds: activityDateCreatedEnds});
        }

        if (activityDateStart)
        {
            filtered = true;
            await this.setState({filterActivityDateStart: activityDateStart});
        }

        if (activityDateEnds)
        {
            filtered = true;
            await this.setState({filterActivityDateEnds: activityDateEnds});
        }

        if (companyUnit)
        {
            filtered = true;
            companyUnit = companyUnit.split(",").map(u => parseInt(u));
            let optionsCompanyUnits = [];

            for (const unit of this.state.optionsCompanyUnits)
            {
                optionsCompanyUnits.push(
                    {
                        ...unit,
                        checked: companyUnit.includes(unit.value)
                    }
                );
            }

            await this.setState({optionsCompanyUnits});
        }

        if (createDateStart)
        {
            filtered = true;
            await this.setState({filterCreateDateStart: createDateStart});
        }

        if (createDateEnds)
        {
            filtered = true;
            await this.setState({filterCreateDateEnds: createDateEnds});
        }

        if (groupBy)
        {
            filtered = true;
            await this.setState({withGroupBy: parseInt(groupBy) === 1 ? true : false});
        }

        if (midiaOrigin)
        {
            filtered = true;
            const optionsMidiaOriginGeneral = this.state.optionsMidiaOrigin.map(m => ({...m, checked: m.value === parseInt(midiaOrigin)}));
            await this.setState({optionsMidiaOrigin: optionsMidiaOriginGeneral});
        }

        if (onlyBusinessWithAttendant)
        {
            filtered = true;
            await this.setState({onlyBusinessWithAttendant: parseInt(onlyBusinessWithAttendant) === 1 ? true : false});
        }

        if (onlyBusinessWithOwner)
        {
            filtered = true;
            await this.setState({onlyBusinessWithOwner: parseInt(onlyBusinessWithOwner) === 1 ? true : false});
        }

        if (prospectionOwners)
        {
            filtered = true;
            prospectionOwners = prospectionOwners.split(",").map(u => parseInt(u));

            let optionsProspectionOwners = [];
            for (const po of this.state.optionsProspectionOwners)
            {
                optionsProspectionOwners.push({
                    ...po,
                    checked: prospectionOwners.includes(po.value)
                });
            }

            await this.setState({
                optionsProspectionOwners
            });
        }

        if (reasonForLoss)
        {
            filtered = true;
            const optionsReasonsForLoss = this.state.optionsReasonsForLoss.map(r => ({ ...r, checked: r.value === parseInt(reasonForLoss) }));
            await this.setState({optionsReasonsForLoss});
        }

        if (showedup)
        {
            filtered = true;
            showedup = showedup.split(",").map(u => parseInt(u));

            let optionsShowedup = [];
            for (const s of this.state.optionsShowedup)
            {
                optionsShowedup.push({
                    ...s,
                    checked: showedup.includes(s.value)
                });
            }

            await this.setState({
                optionsShowedup
            });
        }

        if (status)
        {
            filtered = true;
            status = status.split(",").map(s => s.toUpperCase());

            let optionsStatus = [];
            for (const s of this.state.optionsStatus)
            {
                optionsStatus.push({
                    ...s,
                    checked: status.includes(s.value)
                });
            }

            await this.setState({
                optionsStatus
            });
        }

        if (statusDateStart)
        {
            filtered = true;
            await this.setState({filterStatusDateStart: statusDateStart});
        }

        if (statusDateEnds)
        {
            filtered = true;
            await this.setState({filterStatusDateEnds: statusDateEnds});
        }

        if (coordinator)
        {
            filtered = true;
            coordinator = coordinator.split(",").map(u => parseInt(u));

            let optionsCoordinators = [];
            for (const c of this.state.optionsCoordinators)
            {
                optionsCoordinators.push({
                    ...c,
                    checked: coordinator.includes(c.value)
                });
            }

            await this.setState({
                optionsCoordinators
            });
        }

        if (supervisor)
        {
            filtered = true;
            supervisor = supervisor.split(",").map(u => parseInt(u));

            let optionsSupervisors = [];
            for (const s of this.state.optionsSupervisors)
            {
                optionsSupervisors.push({
                    ...s,
                    checked: supervisor.includes(s.value)
                });
            }

            await this.setState({
                optionsSupervisors
            });
        }

        if (timeStart)
        {
            filtered = true;
            await this.setState({
                filterCreateTimeStart: timeStart,
                filterStatusTimeStart: timeStart,
                filterActivityTimeCreatedStart: timeStart,
                filterActivityTimeStart: timeStart
            });
        }

        if (timeEnds)
        {
            filtered = true;
            await this.setState({
                filterCreateTimeEnds: timeEnds,
                filterStatusTimeEnds: timeEnds,
                filterActivityTimeCreatedEnds: timeEnds,
                filterActivityTimeEnds: timeEnds
            });
        }

        if (typeActivity)
        {
            filtered = true;
            typeActivity = typeActivity.split(",").map(t => t.toUpperCase());

            let optionsTypeActivity = [];
            for (const t of this.state.optionsTypeActivity)
            {
                optionsTypeActivity.push({
                    ...t,
                    checked: typeActivity.includes(t.value)
                });
            }

            await this.setState({
                optionsTypeActivity
            });
        }

        if (typePeriodVisit)
        {
            filtered = true;
            const optionsTypeVisitBusiness = this.state.optionsTypeVisitBusiness.map(t => ({...t, checked: t.value === typePeriodVisit.toUpperCase()}));
            await this.setState({optionsTypeVisitBusiness});
        }

        if (withActivities)
        {
            filtered = true;
            await this.setState({onlyBusinessWithActivities: parseInt(withActivities) === 1 ? true : false});
        }

        if (filtered) {
            await this.applyFilters();
        }
    }

    render()
    {
        return (
            <div className="advancedBusiness">
                {
                    this.state.loading &&
                    <div className="advancedBusinessLoading">
                        <Loading loading={this.state.loading} />
                    </div>
                }

                <MenuRight 
                    title={this.getMenuTitle()} 
                    isOpen={this.state.isMenuOpen} 
                    onCloseMenu={() => this.setState({
                        isMenuOpen: !this.state.isMenuOpen
                    })}
                >
                    { this.state.menuViewOptionSelected === ADVANCED_BUSINESS_OPTION_SELECTED_FILTERS && 
                    <Fragment>
                        <div className="row no-gutters">
                            <div className="col-12 mb-2 pr-1 pl-1">
                                <SelectMulti
                                    label="Unidade(s)"
                                    default={{name: getOptionsSelectCheckedFormated(this.state.optionsCompanyUnits, "[Todas Unidades]", "unidades")}}
                                    options={this.state.optionsCompanyUnits} 
                                    onChange={async (optionsCompanyUnits) => {
                                        await this.setState({optionsCompanyUnits});
                                        await this.getOptionsOwnersFiltered();
                                    }}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 mt-2 mb-2 pr-1 pl-1">
                                <SelectMulti
                                    label="Atendente(s)"
                                    default={{name: getOptionsSelectCheckedFormated(this.state.optionsProspectionOwners, "[Todos Atendentes]", "atendentes")}}
                                    options={this.state.optionsProspectionOwners} 
                                    onChange={(optionsProspectionOwners) => this.setState({optionsProspectionOwners})}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 mt-2 mb-2 pr-1 pl-1">
                                <SelectMulti
                                    label="Proprietário(s)"
                                    default={{name: getOptionsSelectCheckedFormated(this.state.optionsOwnersFiltered, "[Todos Proprietários]", "proprietários")}}
                                    options={this.state.optionsOwnersFiltered} 
                                    onChange={(optionsOwnersFiltered) => this.setState({optionsOwnersFiltered})}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 mt-2 mb-2 pr-1 pl-1">
                                <SelectMulti
                                    label="Status"
                                    default={{name: getOptionsSelectCheckedFormated(this.state.optionsStatus, "[Todos Status]", "status")}}
                                    options={this.state.optionsStatus} 
                                    onChange={(optionsStatus) => this.setState({optionsStatus})}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 mt-2 mb-2 pr-1 pl-1">
                                <SelectMulti
                                    label="Campanha(s)"
                                    default={{name: getOptionsSelectCheckedFormated(this.state.optionsCampaigns, "[Todas Campanhas]", "campanhas")}}
                                    options={this.state.optionsCampaigns} 
                                    onChange={async (optionsCampaigns) => {
                                        await this.setState({optionsCampaigns});
                                    }}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 mt-2 mb-2 pr-1 pl-1">
                                <SelectMulti
                                    label="Fonte(s)"
                                    default={{name: getOptionsSelectCheckedFormated(this.state.optionsMidiaOrigin, "[Todas Fontes]", "fontes")}}
                                    options={this.state.optionsMidiaOrigin} 
                                    onChange={async (optionsMidiaOrigin) => {
                                        await this.setState({optionsMidiaOrigin});
                                    }}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 mt-2 mb-2 pr-1 pl-1">
                                <SelectMulti
                                    label="Motivo(s) da perda"
                                    default={{name: getOptionsSelectCheckedFormated(this.state.optionsReasonsForLoss, "[Todos motivos]", "motivos")}}
                                    options={this.state.optionsReasonsForLoss} 
                                    onChange={(optionsReasonsForLoss) => this.setState({optionsReasonsForLoss})}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 mt-2 mb-2 pr-1 pl-1">
                                <SelectMulti
                                    label="Etapa(s) do Funil"
                                    default={{name: getOptionsSelectCheckedFormated(this.state.optionsSteps, "[Todas Etapas]", "etapas")}}
                                    options={this.state.optionsSteps} 
                                    onChange={(optionsSteps) => this.setState({optionsSteps})}
                                    withFieldSearch
                                />
                            </div>
                        </div>
                        <div className="row no-gutters mt-2 mb-2 pr-1 pl-1">
                            <div className="col-12 columnOwnerForm">
                                <InputGeneral
                                    label="Nome/Telefone do cliente"
                                    value={this.state.filterSearchClient}
                                    onChange={e => this.setState({filterSearchClient: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters mt-2 mb-3 pr-1 pl-1">
                            <div className="col-6 pr-1">
                                <InputGeneral
                                    label="Lead Criado De:"
                                    type="date" 
                                    value={this.state.filterLeadCreateDateStart}
                                    onChange={e => {
                                        this.setState({filterLeadCreateDateStart: e.target.value});
                                        if (e.target.value === "")
                                        {
                                            this.setState({filterLeadCreateTimeStart: "00:00"});
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-6 pl-1">
                                <InputGeneral
                                    label="&nbsp;"
									value={`${this.state.filterLeadCreateTimeStart}`}
									onChange={async (e) => {
										let time = e.target.value;
										time = (time.length > 4) ? validTime(time) : time;
										await this.setState({filterLeadCreateTimeStart: formatedTime(time)});
									}}
									maxLength={5}
								/>
                            </div>
                        </div>
                        <div className="row no-gutters mt-2 mb-3 pr-1 pl-1">
                            <div className="col-6 pr-1">
                                <InputGeneral
                                    label="Lead Criado Até:"
                                    type="date" 
                                    value={this.state.filterLeadCreateDateEnds}
                                    onChange={e => {
                                        this.setState({filterLeadCreateDateEnds: e.target.value});
                                        if (e.target.value === "")
                                        {
                                            this.setState({filterLeadCreateTimeEnds: "23:59"});
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-6 pl-1">
                                <InputGeneral
                                    label="&nbsp;"
									value={`${this.state.filterLeadCreateTimeEnds}`}
									onChange={async (e) => {
										let time = e.target.value;
										time = (time.length > 4) ? validTime(time) : time;
										await this.setState({filterLeadCreateTimeEnds: formatedTime(time)});
									}}
									maxLength={5}
								/>
                            </div>
                        </div>
                        <div className="row no-gutters mt-2 mb-3 pr-1 pl-1">
                            <div className="col-6 pr-1">
                                <InputGeneral
                                    label="Negócio Criado De:"
                                    type="date" 
                                    value={this.state.filterCreateDateStart}
                                    onChange={e => {
                                        this.setState({filterCreateDateStart: e.target.value});
                                        if (e.target.value === "")
                                        {
                                            this.setState({filterCreateTimeStart: "00:00"});
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-6 pl-1">
                                <InputGeneral
                                    label="&nbsp;"
									value={`${this.state.filterCreateTimeStart}`}
									onChange={async (e) => {
										let time = e.target.value;
										time = (time.length > 4) ? validTime(time) : time;
										await this.setState({filterCreateTimeStart: formatedTime(time)});
									}}
									maxLength={5}
								/>
                            </div>
                        </div>
                        <div className="row no-gutters mt-2 mb-3 pr-1 pl-1">
                            <div className="col-6 pr-1">
                                <InputGeneral
                                    label="Negócio Criado Até:"
                                    type="date" 
                                    value={this.state.filterCreateDateEnds}
                                    onChange={e => {
                                        this.setState({filterCreateDateEnds: e.target.value});
                                        if (e.target.value === "")
                                        {
                                            this.setState({filterCreateTimeEnds: "23:59"});
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-6 pl-1">
                                <InputGeneral
                                    label="&nbsp;"
									value={`${this.state.filterCreateTimeEnds}`}
									onChange={async (e) => {
										let time = e.target.value;
										time = (time.length > 4) ? validTime(time) : time;
										await this.setState({filterCreateTimeEnds: formatedTime(time)});
									}}
									maxLength={5}
								/>
                            </div>
                        </div>
                        <div className="row no-gutters mt-3 mb-3 pr-1 pl-1">
                            <div className="col-6 pr-1">
                                <InputGeneral
                                    label="Negócio Fechado De:"
                                    type="date" 
                                    value={this.state.filterStatusDateStart}
                                    onChange={e => {
                                        this.setState({filterStatusDateStart: e.target.value});
                                        if (e.target.value === "")
                                        {
                                            this.setState({filterStatusTimeStart: "00:00"});
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-6 pl-1">
                                <InputGeneral
                                    label="&nbsp;"
									value={`${this.state.filterStatusTimeStart}`}
									onChange={async (e) => {
										let time = e.target.value;
										time = (time.length > 4) ? validTime(time) : time;
										await this.setState({filterStatusTimeStart: formatedTime(time)});
									}}
									maxLength={5}
								/>
                            </div>
                        </div>
                        <div className="row no-gutters mt-3 mb-3 pr-1 pl-1">
                            <div className="col-6 pr-1">
                                <InputGeneral
                                    label="Negócio Fechado Até:"
                                    type="date" 
                                    value={this.state.filterStatusDateEnds}
                                    onChange={e => {
                                        this.setState({filterStatusDateEnds: e.target.value});
                                        if (e.target.value === "")
                                        {
                                            this.setState({filterStatusTimeEnds: "23:59"});
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-6 pl-1">
                                <InputGeneral
                                    label="&nbsp;"
									value={`${this.state.filterStatusTimeEnds}`}
									onChange={async (e) => {
										let time = e.target.value;
										time = (time.length > 4) ? validTime(time) : time;
										await this.setState({filterStatusTimeEnds: formatedTime(time)});
									}}
									maxLength={5}
								/>
                            </div>
                        </div>
                        <div className="row no-gutters mt-4 mb-3 pr-1 pl-1">
                            <div className="col-12">
                                <Check 
                                    name="Apenas negócios com atividades" 
                                    checked={this.state.onlyBusinessWithActivities}
                                    onClick={(e) => {
                                        const newCheckedState = !this.state.onlyBusinessWithActivities;
                                        this.setState({ onlyBusinessWithActivities: newCheckedState })
                                    }} 
                                />
                            </div>
                        </div>
                        {
                            this.state.onlyBusinessWithActivities &&
                            <Fragment>
                                <div className="row no-gutters mt-3 mb-3 pr-1 pl-1">
                                    <div className="col-6 pr-1">
                                        <InputGeneral
                                            label="Atividade Criada De:"
                                            type="date" 
                                            value={this.state.filterActivityDateCreatedStart}
                                            onChange={e => {
                                                this.setState({filterActivityDateCreatedStart: e.target.value});
                                                if (e.target.value === "")
                                                {
                                                    this.setState({filterActivityTimeCreatedStart: "00:00"});
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-6 pl-1">
                                        <InputGeneral
                                            label="&nbsp;"
						        			value={`${this.state.filterActivityTimeCreatedStart}`}
						        			onChange={async (e) => {
						        				let time = e.target.value;
						        				time = (time.length > 4) ? validTime(time) : time;
						        				await this.setState({filterActivityTimeCreatedStart: formatedTime(time)});
						        			}}
						        			maxLength={5}
						        		/>
                                    </div>
                                </div>
                                <div className="row no-gutters mt-3 mb-3 pr-1 pl-1">
                                    <div className="col-6 pr-1">
                                        <InputGeneral
                                            label="Atividade Criada Até:"
                                            type="date" 
                                            value={this.state.filterActivityDateCreatedEnds}
                                            onChange={e => {
                                                this.setState({filterActivityDateCreatedEnds: e.target.value});
                                                if (e.target.value === "")
                                                {
                                                    this.setState({filterActivityTimeCreatedEnds: "23:59"});
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-6 pl-1">
                                        <InputGeneral
                                            label="&nbsp;"
						        			value={`${this.state.filterActivityTimeCreatedEnds}`}
						        			onChange={async (e) => {
						        				let time = e.target.value;
						        				time = (time.length > 4) ? validTime(time) : time;
						        				await this.setState({filterActivityTimeCreatedEnds: formatedTime(time)});
						        			}}
						        			maxLength={5}
						        		/>
                                    </div>
                                </div>
                                <div className="row no-gutters mt-2 mb-3 pr-1 pl-1">
                                    <div className="col-6 pr-1">
                                        <InputGeneral
                                            label="Início da Atividade Para:"
                                            type="date" 
                                            value={this.state.filterActivityDateStart}
                                            onChange={e => {
                                                this.setState({filterActivityDateStart: e.target.value});
                                                if (e.target.value === "")
                                                {
                                                    this.setState({filterActivityTimeStart: "00:00"});
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-6 pl-1">
                                        <InputGeneral
                                            label="&nbsp;"
						        			value={`${this.state.filterActivityTimeStart}`}
						        			onChange={async (e) => {
						        				let time = e.target.value;
						        				time = (time.length > 4) ? validTime(time) : time;
						        				await this.setState({filterActivityTimeStart: formatedTime(time)});
						        			}}
						        			maxLength={5}
						        		/>
                                    </div>
                                </div>
                                <div className="row no-gutters mt-2 mb-2 pr-1 pl-1">
                                    <div className="col-6 pr-1">
                                        <InputGeneral
                                            label="Até:"
                                            type="date" 
                                            value={this.state.filterActivityDateEnds}
                                            onChange={e => {
                                                this.setState({filterActivityDateEnds: e.target.value});
                                                if (e.target.value === "")
                                                {
                                                    this.setState({filterActivityTimeEnds: "23:59"});
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-6 pl-1">
                                        <InputGeneral
                                            label="&nbsp;"
						        			value={`${this.state.filterActivityTimeEnds}`}
						        			onChange={async (e) => {
						        				let time = e.target.value;
						        				time = (time.length > 4) ? validTime(time) : time;
						        				await this.setState({filterActivityTimeEnds: formatedTime(time)});
						        			}}
						        			maxLength={5}
						        		/>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-12 mt-2 mb-2 pr-1 pl-1">
                                        <SelectMulti
                                            label="Tipo De Atividade:"
                                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsTypeActivity, "[Todos tipos]", "tipos")}}
                                            options={this.state.optionsTypeActivity} 
                                            onChange={(optionsTypeActivity) => this.setState({optionsTypeActivity})}
                                            withFieldSearch
                                        />
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-12 mt-2 mb-2 pr-1 pl-1">
                                        <SelectMulti
                                            label="Atividade Criada Por:"
                                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsActivityCreatedBy, "[Todos usuários]", "usuários")}}
                                            options={this.state.optionsActivityCreatedBy} 
                                            onChange={(optionsActivityCreatedBy) => this.setState({optionsActivityCreatedBy})}
                                            withFieldSearch
                                        />
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-12 mt-2 mb-2 pr-1 pl-1">
                                        <SelectMulti
                                            label="Coordenador:"
                                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsCoordinators, "[Todos coordenadores]", "coordenadores")}}
                                            options={this.state.optionsCoordinators} 
                                            onChange={(optionsCoordinators) => this.setState({optionsCoordinators})}
                                            withFieldSearch
                                        />
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-12 mt-2 mb-2 pr-1 pl-1">
                                        <SelectMulti
                                            label="Supervisor:"
                                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsSupervisors, "[Todos supervisores]", "supervisores")}}
                                            options={this.state.optionsSupervisors} 
                                            onChange={(optionsSupervisors) => this.setState({optionsSupervisors})}
                                            withFieldSearch
                                        />
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-12 mt-2 mb-2 pr-1 pl-1">
                                        <SelectMulti
                                            label="Compareceu:"
                                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsShowedup, "[Todos]", "compareceu")}}
                                            options={this.state.optionsShowedup} 
                                            onChange={(optionsShowedup) => this.setState({optionsShowedup})}
                                            withFieldSearch
                                        />
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-12 mt-2 mb-2 pr-1 pl-1">
                                        <SelectMulti
                                            label="Data da Atividade:"
                                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsTypeVisitBusiness, "[Todos]", "tipo")}}
                                            options={this.state.optionsTypeVisitBusiness} 
                                            onChange={(optionsTypeVisitBusiness) => this.setState({optionsTypeVisitBusiness})}
                                            withFieldSearch
                                        />
                                    </div>
                                </div>
                                <div className="row no-gutters mt-3 mb-3 pr-1 pl-1">
                                    <div className="col-12">
                                        <Check 
                                            name="Agrupar negócios" 
                                            checked={this.state.withGroupBy}
                                            onClick={(e) => {
                                                const newCheckedState = !this.state.withGroupBy;
                                                this.setState({ withGroupBy: newCheckedState })
                                            }} 
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        }
                        <div className="row no-gutters mt-4 mb-3 pr-1 pl-1">
                            <div className="col-12">
                                <Check 
                                    name="Apenas negócios sem visita" 
                                    checked={this.state.onlyBusinessWithoutVisit}
                                    onClick={(e) => {
                                        const newCheckedState = !this.state.onlyBusinessWithoutVisit;
                                        this.setState({ onlyBusinessWithoutVisit: newCheckedState })
                                    }} 
                                />
                            </div>
                        </div>
                        <div className="row no-gutters mt-4 mb-3 pr-1 pl-1">
                            <div className="col-12">
                                <Check 
                                    name="Apenas negócios com atendente" 
                                    checked={this.state.onlyBusinessWithAttendant}
                                    onClick={(e) => {
                                        const newCheckedState = !this.state.onlyBusinessWithAttendant;
                                        this.setState({ onlyBusinessWithAttendant: newCheckedState })
                                    }} 
                                />
                            </div>
                        </div>
                        {
                            this.props.permissions.advancedBusiness.toViewAll &&
                                <div className="row no-gutters mt-4 mb-3 pr-1 pl-1">
                                    <div className="col-12">
                                        <Check 
                                            name="Apenas negócios com proprietário" 
                                            checked={this.state.onlyBusinessWithOwner}
                                            onClick={(e) => {
                                                const newCheckedState = !this.state.onlyBusinessWithOwner;
                                                this.setState({ onlyBusinessWithOwner: newCheckedState })
                                            }} 
                                        />
                                    </div>
                                </div>
                        }
                        <div className="row no-gutters">
                            <div className="col-12 mt-3 mb-2 pr-1 pl-1">
                                <Button
                                    name="Aplicar filtros"
                                    classaditional="buttonApplyFiltersAdvancedBusiness"
                                    onClick={async () => {
                                        await this.setState({
                                            isMenuOpen: !this.state.isMenuOpen,
                                            currentPage: 1
                                        });
                                        this.applyFilters()
                                    }}
                                />
                            </div>
                        </div>
                    </Fragment>
                    }
                    { this.state.menuViewOptionSelected === ADVANCED_BUSINESS_OPTION_SELECTED_COLUMNS &&
                        <div className="row no-gutters">
                        {
                            this.state.columns.map((column, i) => (
                                <div key={i} className="col-12 mt-2 mb-2">
                                    <Check 
                                        name={column.name} 
                                        checked={column.visible} 
                                        onClick={() => this.handleChangeColumns(column.slug)} 
                                    />
                                </div>
                            ))
                        }
                        </div>
                    }
                </MenuRight>

                <Header title="Negócios - Avançado" classaditional="headerAdvancedBusiness"/>

                <div className="warningAdvancedBusiness d-flex d-md-none">
                    <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                </div>

                <div className="row no-gutters align-items-center containerHeaderAdvancedBusiness">
                    <div className="col">
                        <ButtonIcon
                            icon="far fa-bars"
                            classaditional="buttonNavigationHeaderAdvancedBusiness active" 
                        />
                    </div>
                    <div className="col">
                        {this.state.countTotalBusiness} negócio(s) encontrado(s)
                    </div>
                    <div className="ml-auto">
                        <Button
                            icon="fas fa-columns"
                            name="&nbsp;&nbsp;Colunas"
                            classaditional="buttonHeaderAdvancedBusiness"
                            onClick={() => {
                                this.setState({
                                    isMenuOpen: !this.state.isMenuOpen,
                                    menuViewOptionSelected: ADVANCED_BUSINESS_OPTION_SELECTED_COLUMNS
                                });
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            icon="fas fa-filter"
                            name="&nbsp;&nbsp;Filtros"
                            classaditional="buttonHeaderAdvancedBusiness"
                            onClick={() => {
                                this.setState({
                                    isMenuOpen: !this.state.isMenuOpen,
                                    menuViewOptionSelected: ADVANCED_BUSINESS_OPTION_SELECTED_FILTERS
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="containerListAdvancedBusiness">
                    <TableEditAdvancedBusiness
                        data={this.state.business} 
                        columns={this.state.columns} 
                        onScroll={async () => {
                            if (this.state.currentPage === this.state.totalPages)
                            {
                                return;
                            }
                            const nextPage = this.state.currentPage + 1;
                            await this.setState({ currentPage: nextPage });
                            await this.applyFilters();
                        }}
                    />
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) 
{
    const permissions = state.permissions;
    const { user } = state.auth;

    return {
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvancedBusiness));