import React from "react";
import "./StylizedMoneyOrNumber.css";

export default function StylizedMoneyOrNumber (props)
{
    return (
        <div 
            className="stylizedMoneyOrNumber" 
            style={
                {
                    color: `${props.color ? `var(--color-${props.color})` : `var(--dark-dark)`}`,
                    ...props.style
                }
            }
        >
            <span className="symbolStylizedMoneyOrNumber">{props.symbol ? props.symbol : "R$"}</span>
            <span className="valueStylizesMoneyOrNumber">{props.value}</span>
        </div>
    )
}