import {
    NEW_ATTENDANT_ABSENCE
}  from "../../core/constants";

//action creator
export function newAttendantAbsence (status)
{
    return {
        type: NEW_ATTENDANT_ABSENCE,
        payload: status,
    }
}