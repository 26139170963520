import React from "react";
import "./LineCheckRequirement.css";

export default function LineCheckRequirement (props)
{

    return (
        <div className={`lineCheckRequirement ${props.classaditional} success`}>
            <div className="columnIconCheckRequirement">
                {
                    props.status &&
                    <i className="fas fa-check iconCheckRequirement success"></i>
                }
                {
                    !props.status &&
                    <i className="fas fa-times iconCheckRequirement error"></i>
                }

            </div>
            <div className="columnLabelCheckRequirement">
                <span className={`${props.status ? "success" : "error"}`}>{props.label}</span>
            </div>
        </div>
    )
}