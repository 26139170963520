import React, { useState } from "react"; 
import "./Input.css";

export default function Input (props)
{
    const [focused, setFocused] = useState(false);

    function getStyleFocus ()
    {
        if (focused)
        {
            return "yesFocus";
        }
        else
        {
            return "noFocus";
        }
    }

    function handleFocus (focused)
    {
        if (focused)
        {
            if (props.value === "")
            {
                setFocused(false)
            }
        }
        else
        {
            setFocused(true)

        }
    }

    return (
        <div className="groupInput">

            {
                props.label &&
                <label 
                    className={`
                        label
                        ${getStyleFocus()}
                    `}
                >{props.label}</label>                
            }
            <div className="input"> 
                {
                    props.icon &&
                    <i 
                        className={`
                            ${props.icon} icon 
                            ${getStyleFocus()}
                        `}
                    ></i>
                }
                <input 
                    className={`
                        field
                        ${getStyleFocus()}
                    `}

                    onFocus={e => handleFocus(false)}
                    onBlur={() => handleFocus(true)}
                    onMouseUp={() => handleFocus(false)}
                    {...props}
                />
                {
                    props.help &&
                    props.help
                }
            </div>
        </div>
    )

}