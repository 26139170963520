import React, { Component } from "react";
import "./CommissionRules.css";
import { connect } from "react-redux";
import { APPLICATION_NAME, commissionRule } from "../../core/constants";
import { showModalMoreActions } from "../../store/actions/general";
import {
    newReportCommissionRule,
    deleteReportCommissionRule,
    changeCommissionRule, 
    getAllCommissionRules, 
    changeStatusGetFromDatabaseCompletedCommissionRules,
    toggleCheckedAllCommissionRules
} from "../../store/actions/commission";

import Header from "../../components/CRM/Header";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import Message from "../../components/general/Message";
import TableEditCommissionRules from "../../components/general/TableEditCommissionRules";

import CommissionRulesController from "../../controllers/CommissionRulesController";
import CompanyUnitController from "../../controllers/CompanyUnitController";
import PermissionGroupController from "../../controllers/PermissionsGroupController";
import { withRouter } from "react-router-dom";
import { formatedDateTimeToUSA, getDateTimeCurrentBrowserAtMidnight, getOptionsSelectCheckedFormated } from "../../auxiliary/generalFunctions";
import SelectMulti from "../../components/general/SelectMulti";
import InputGeneral from "../../components/general/InputGeneral";

const initialState = {
    allCommissionRules: [],
    allCommissionRulesNotFiltered: [],
    allCommissionRulesChecked: false, //false as default
    allCommissionRulesUpdated: false,
    allCommissionRulesInitialLoaded: false,
    hasOneCommissionRulesChecked: false,
    filtered: false,

    optionsSelectFrom : [],
    optionsUnitCompany: [],
    optionsPermissionsGroup: [],

    // start Message Modal
    message_type: "information",
    message_show: false,
    message: "",
    // ends Message Modal

    currentMonthYear: "", //looks like: "2022-04"
}

const commissionRulesController = new CommissionRulesController();
const companyUnitController = new CompanyUnitController();
const permissionGroupController = new PermissionGroupController();

class CommissionRules extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Regras Comissão - Vendas Comercial`;
        let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight()); //looks like "2022-04-01"

        await this.setCurrentMonthYear(currentDate);
        await this.getPermissionsGroup();
        await this.getCompanyUnit();
        await this.props.getAllCommissionRules();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("COMMISSION RULES prevProps ", prevProps);
        // console.log("COMMISSION RULES this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}

        // WARNING: for getting initials commission rules and from database.
        if 
        (
            this.state.allCommissionRules.length === 0 && 
            this.props.allCommissionRules.length > 0 && 
            !this.state.allCommissionRulesInitialLoaded &&
            this.state.optionsUnitCompany.length > 0 &&
            this.state.optionsPermissionsGroup.length > 0
        )
        {
            let allCommissionRulesNotFiltered = this.props.allCommissionRules;
            let allCommissionRules = await this.someTreatments(allCommissionRulesNotFiltered);
            this.hasAllCommissionRulesChecked(allCommissionRules);
            await this.setState({allCommissionRules, allCommissionRulesNotFiltered, allCommissionRulesInitialLoaded: true});
            await this.applyFilters();
        }

        // WARNING: for getting update activities and global state (redux).
        if (this.state.allCommissionRulesUpdated)
        {
            let allCommissionRulesNotFiltered = this.props.allCommissionRules;
            let allCommissionRules = await this.someTreatments(allCommissionRulesNotFiltered);
            this.hasAllCommissionRulesChecked(allCommissionRules);
            await this.setState({allCommissionRules, allCommissionRulesNotFiltered, allCommissionRulesUpdated: false});
            await this.applyFilters();
        }

        // DANGER: waiting when it receives the CommissionRules from the database and is ready in props (redux).
        if (prevProps.getAllCommissionRulesFromDatabaseCompleted !== undefined)
        {
            if (!prevProps.getAllCommissionRulesFromDatabaseCompleted && this.props.getAllCommissionRulesFromDatabaseCompleted)
            {
                await this.setState({allCommissionRulesUpdated: true});
            }
        }
    }

    setCurrentMonthYear = async (currentDate) =>
	{
		let parts = currentDate.toString().split("-");

		let y = parseInt(parts[0]);
		let m = parseInt(parts[1]).toString().padStart(2, '0');

		let currentMonthYear = `${y}-${m}`;

		await this.setState({currentMonthYear});
	}

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // asking the socket to make the change, as it will be possible to follow the status change even if the page is refreshed.
            if (propertyName === "status")
            {
                let status = newValue;
                // let allCommissionRules = await this.state.allCommissionRules;
                // let rule = allCommissionRules.filter(r => r.id === id)[0];
                // let monthDate = new Date(`${rule.month}-01 00:00`);
                // let currentDate = getDateTimeCurrentBrowserAtMidnight();

                // if (monthDate >= currentDate)
                // {
                //     this.message("error", "Não é possível gerar relatório de comissão dessa regra, apenas para as regras dos meses anteriores ao atual!");
                //     return;
                // }

                // asking to generate...
                if (status === 1) //1 = "GENERATED", on view
                {
                    this.props.newReportCommissionRule(id);
                }

                // asking to generate...
                if (status === 3) //3 = "NOT_GENERATED"
                {
                    this.props.deleteReportCommissionRule(id);
                }

                newValue = 2; //WARNING: to force the LOADING...
            }

            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            await this.props.changeCommissionRule({[propertyName]: newValue, id, propertyName});
            await this.setState({allCommissionRulesUpdated: true});
        }
    }

    saveValue = async (id, field, value) =>
    {
        // WARNING: for maintenance.
        // console.log(`id: ${id}, field: ${field}, value: ${value}`);
        let result = {
            status: true,
        }

        result = await commissionRulesController.update(id, {
            [field]: value
        });
      
        if (!result.status) 
        {
            this.message("error", result.message);
        }

        await this.props.changeStatusGetFromDatabaseCompletedCommissionRules(false);
        await this.props.getAllCommissionRules();
    }

    hasAllCommissionRulesChecked = async (allCommissionRules) =>
    {
        let allCommissionRulesChecked = true;
        let hasOneCommissionRulesChecked = false;

        allCommissionRules.forEach(d => {
            if (!d.checked) allCommissionRulesChecked = false;
            if (d.checked) hasOneCommissionRulesChecked = true;
        });
        
        await this.setState({allCommissionRulesChecked, hasOneCommissionRulesChecked});
    }

    getPermissionsGroup = async () =>
    {
        let optionsPermissionsGroup = [];
        const result = await permissionGroupController.get();

        if (result.status) 
        {
            for (const group of result.data.data)
            {
                if (group.PEG_IS_COMMISSIONED === 1)
                {
                    optionsPermissionsGroup.push({
                        name: group.PEG_NAME,
                        value: group.PEG_ID,
                        checked: false
                    });
                }
                
            }
        }
                
        await this.setState({optionsPermissionsGroup});
    }

    getCompanyUnit = async () => 
    {
        let allCompanyUnits = [];
        const result = await companyUnitController.get();

        if (result.status) 
        {
            for (const company of result.data.data)
            {
                if (company.UNT_STATUS === 1)
                {
                    allCompanyUnits.push({
                        name: company.UNT_NAME,
                        value: company.UNT_ID,
                        checked: false
                    });
                }
            }
        }
                
        await this.setState({optionsUnitCompany: allCompanyUnits});
    }

    someTreatments = async (allComissionRules) =>
    {
        let optionsCompanyUnit = await this.state.optionsUnitCompany;
        let optionsPermissionsGroup = await this.state.optionsPermissionsGroup;

        allComissionRules = allComissionRules.map(c => {
            c["optionsCompanyUnit"] = optionsCompanyUnit;
            c["optionsPermissionsGroup"] = optionsPermissionsGroup;
            return c;
        });
        return allComissionRules
    }

    applyFilters = async () => 
    {
        let allCommissionRules = [];
        let allCommissionRulesNotFiltered = await this.state.allCommissionRulesNotFiltered;
        let currentMonthYearActive = await this.state.currentMonthYear;
        let optionsUnitCompanyActive = await this.state.optionsUnitCompany;
        let fromAllUnitCompany = false;

        optionsUnitCompanyActive = optionsUnitCompanyActive.filter(u => u.checked);
        fromAllUnitCompany = (optionsUnitCompanyActive.length === 0) ? true : false;        

        allCommissionRules = allCommissionRulesNotFiltered.filter(r => {

            if (fromAllUnitCompany)
            {
                if (r.month === currentMonthYearActive) { return r; }
            }
            else
            {
                if (r.month === currentMonthYearActive && optionsUnitCompanyActive.some(u => u.value === r.unit)) { return r; }
            }

            return false;
        });

        await this.setState({allCommissionRules});
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render ()
    {
        return (
            <div className="commissionRules">
                <Header title="Regras Comissão - Vendas Comercial" classaditional="headerCommissionRules"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="containerNavigationHeaderCommissionRules">                
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderCommissionRules ${!this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: false});
                        }}
                    />
                    {/* RESERVED */}
                    {/* <ButtonIcon
                        icon="fas fa-archive"
                        classaditional={`buttonNavigationHeaderCommissionRules ${this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: true});
                        }}
                    /> */}
                    {
                        (!this.state.hasOneCommissionRuleChecked) && (this.props.permissions.toInsert) &&
                        <Button
                            icon="fal fa-plus"
                            name="&nbsp;&nbsp;Regra"
                            classaditional="buttonPlusNavigationHeaderCommissionRules"
                            onClick={() => {
                                let data = {
                                    show: true,
                                    type: commissionRule
                                }
                                this.props.showModalMoreActions(data);
                            }}
                        />
                    }
                    {
                        (this.state.hasOneCommissionRuleChecked) &&
                            <ButtonIcon
                                icon="fas fa-trash"
                                classaditional="buttonTrashNavigationHeaderCommissionRules"
                                onClick={() => this.message("information", "nothing...")}
                            />
                    }
                    {
                        (this.state.hasOneCommissionRuleChecked) &&
                            <Button
                                icon="fas fa-archive"
                                name={ `\u00A0\u00A0${this.state.filtered ? "Desarquivar" : "Arquivar"}` }
                                classaditional="buttonPlusNavigationHeaderCommissionRules"
                                onClick={() => {}}
                            />
                    }
                    <div className={`filterStatusHeaderCommissionRules d-none d-lg-flex ml-2 ml-auto`}>
                        <i className="fas fa-building iconfilterStatusHeaderCommissionRules"></i>
                        <SelectMulti
                            classaditional="selectStatusHeaderCommissionRules"
                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsUnitCompany, "[Todas Unidades]", "unidades")}}
                            options={this.state.optionsUnitCompany} 
                            onChange={async (optionsUnitCompany) => {
                                await this.setState({optionsUnitCompany});
                                await this.applyFilters();
                            }}
                            withFieldSearch
                        />
                    </div>
                    <div className={`filterStatusHeaderCommissionRules d-none d-lg-flex ml-2`}>
                        <InputGeneral
                            classaditional="inputFilterCommissionRules"
                            type="month"
                            value={this.state.currentMonthYear}
                            onChange={async (e) => {
                                await this.setCurrentMonthYear(e.target.value);
                                await this.applyFilters();
                            }}
                        />
                    </div>

                    {/* RESERVED */}
                    {/* <div className="filterFontsHeaderCommissionRules ml-auto">
                        <i className="fas fa-tag iconFilterFontsHeaderCommissionRules"></i>
                        <SelectMulti
                            classaditional="selectFontHeaderCommissionRules "
                            default={null}
                            options={this.state.optionsSelectFrom} 
                            onChange={(optionsSelectFrom) => {
                                this.setState({optionsSelectFrom, optionsChosenOwnersLead: optionsSelectFrom});
                            }}
                        />
                    </div> */}
                </div>
                <div className="containerListHeaderCommissionRules">
                    <TableEditCommissionRules
                        data={this.state.allCommissionRules}
                        allCommissionRulesChecked={this.state.allCommissionRulesChecked}
                        onToggleCheckedAllCommissionRules={async (status) => {
                            await this.props.toggleCheckedAllCommissionRules(status);
                            await this.setState({allCommissionRulesUpdated: true});
                        }}
                        onChangeValue={this.changeValue}
                        onSaveValue={this.saveValue}
                        onIgnoreSave={async () => {
                            // WARNING: setting the allActivities state to an empty array, in order to retrieve data from the database, thus ignoring eventual changes in the global state (redux).
                            await this.props.changeStatusGetFromDatabaseCompletedCommissionRules(false);
                            await this.props.getAllCommissionRules();
                        }}
                        permissions={this.props.permissions}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { allCommissionRules, getAllCommissionRulesFromDatabaseCompleted } = state.commission;
    const permissions = state.permissions.commissionRules;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        hasPermissionsLoaded,
        allCommissionRules,
        getAllCommissionRulesFromDatabaseCompleted,
        permissions
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        newReportCommissionRule (data) 
        {
            //action creator -> action
            const action = newReportCommissionRule(data);
            dispatch(action);
        },
        deleteReportCommissionRule (data) 
        {
            //action creator -> action
            const action = deleteReportCommissionRule(data);
            dispatch(action);
        },
        changeCommissionRule (mode) 
        {
            //action creator -> action
            const action = changeCommissionRule(mode);
            dispatch(action);
        },
        getAllCommissionRules ()
        {
            //action creator -> action
            const action = getAllCommissionRules();
            dispatch(action);
        },
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        changeStatusGetFromDatabaseCompletedCommissionRules (data)
        {
            //action creator -> action
            const action = changeStatusGetFromDatabaseCompletedCommissionRules(data);
            dispatch(action);
        },
        toggleCheckedAllCommissionRules (status) 
        {
            //action creator -> action
            const action = toggleCheckedAllCommissionRules(status);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommissionRules));