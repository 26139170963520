import axios from "axios";
import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";
import { baseURLTemporaryWebhook, integrationKeyTemporaryWebhook }  from "../configuration";

const api = new API();

export default class CommissionRulesController
{
    async create(data) 
    {
        const response = await api.commissionRules().post(data);
        return apiCallbackTreatment(response);
    }

    async deleteById(id)
    {
        const response = await api.commissionRules().remove(id);
        return apiCallbackTreatment(response);
    }

    async get() 
    {
        const response = await api.commissionRules().get();
        return apiCallbackTreatment(response);
    }

    async getByUserAndUnitCompanyAndPeriod(idUser, idUnitCompany, period) 
    {
        const response = await api.commissionRules().getByUserAndUnitCompanyAndPeriod(idUser, idUnitCompany, period);
        return apiCallbackTreatment(response);
    }

    async getById(id) 
    {
        const response = await api.commissionRules().getById(id);
        return apiCallbackTreatment(response);
    }

    async update(id, data) 
    {
        const response = await api.commissionRules().update(id, data);
        return apiCallbackTreatment(response);
    }

    async getAmountSalesByPeriodERPTemporaryWebhook(dateStart, dateEnds, idUserERP)
    {
        let response = await axios.get(`${baseURLTemporaryWebhook}/service/getAmountSalesByPeriod/user=${idUserERP}/dateStart=${dateStart}/dateEnds=${dateEnds}/key=${integrationKeyTemporaryWebhook}`);
        response = response.data;
        return apiCallbackTreatment(response);
    }

    async getAmountSalesGlobalByPeriodERPTemporaryWebhook(dateStart, dateEnds)
    {
        let response = await axios.get(`${baseURLTemporaryWebhook}/service/getAmountSalesGlobalByPeriod/dateStart=${dateStart}/dateEnds=${dateEnds}/key=${integrationKeyTemporaryWebhook}`);
        response = response.data;
        return apiCallbackTreatment(response);
    }

    async getAmountReceivedByPeriodERPTemporaryWebhook(dateStart, dateEnds, idUserERP)
    {
        let response = await axios.get(`${baseURLTemporaryWebhook}/service/getAmountReceivedByPeriod/user=${idUserERP}/dateStart=${dateStart}/dateEnds=${dateEnds}/key=${integrationKeyTemporaryWebhook}`);
        response = response.data;
        return apiCallbackTreatment(response);
    }

    async getAmountReceivedGlobalByPeriodERPTemporaryWebhook(dateStart, dateEnds)
    {
        let response = await axios.get(`${baseURLTemporaryWebhook}/service/getAmountReceivedGlobalByPeriod/dateStart=${dateStart}/dateEnds=${dateEnds}/key=${integrationKeyTemporaryWebhook}`);
        response = response.data;
        return apiCallbackTreatment(response);
    }
}