import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class BusinessReasonsForLossController 
{
    async create(data) 
    {
        const response = await api.businessReasonsForLoss().post(data);
        return apiCallbackTreatment(response);
    }

    async deleteById(id)
    {
        const response = await api.businessReasonsForLoss().remove(id);
        return apiCallbackTreatment(response);
    }

    async get(group = null) 
    {
        const response = await api.businessReasonsForLoss().get(group);
        return apiCallbackTreatment(response);
    }

    async update(id, data) 
    {
        const response = await api.businessReasonsForLoss().update(id, data);
        return apiCallbackTreatment(response);
    }
}