import React from "react";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { getRandomNumber } from "../../auxiliary/generalFunctions";
import "./LimitedDynamicTable.css";

// Props
// classaditional?: <String>
// header: Array of objects 
// (e.g. [
//          {
//              name: <String>|float|integer|JSX element,
//              nameHelp?: <String>,
//              justify?: <String | "left" | "center" | "right">,
//          }
//      ]
// )
// body: Array of Array of objects
// (e.g. [
//            [
//                  {
//                       name: <String>|float|integer|JSX element,
//                       justify?: <String | "left" | "center" | "right">,
//                       onClick?: (indexLine:<integer>, indexColumn:<integer>) => someFunction(indexLine, indexColumn) | () => someFunction(); callback function,
//                  }
//            ]
// 
//      ]
// )
// footer: Array of objects 
// (e.g. [
//          {
//              name: <String>|float|integer|JSX element,
//              justify?: <String | "left" | "center" | "right">,
//          }
//      ]
// )
// config?: Object of objects
// (e.g. {
//          columns?: Array of objects,
//          (e.g.   [
//                      {
//                          index: <integer>; column index you want to put custom width,
//                          width: <float | percentage>;
//                      }
//                  ]
//          )
//          positionHeaderTitleRuler?: <String | "fixed" | "none">; WARNING: mandatory when prop withHeaderTitleRuler is provided
//      }
// )
// withHeaderTitleRuler?: [whithout parameter]

export default function LimitedDynamicTable (props)
{
    const [widthColumn, setWidthColumn] = useState(0);
    const limitedDynamicTable = useRef(null);

    useEffect (() => {
        settingWidthColumn();
    });

    function settingWidthColumn ()
    {
        let customColumns = props?.config?.columns || [];
        let customWidth = customColumns.map(i => i.width);
        let amountColumns = (props.header.length - customColumns.length);
        customWidth = customWidth.length !== 0 && customWidth.reduce((previousValue, currentValue) => previousValue = (previousValue + currentValue));
        setWidthColumn(parseFloat( (100 - customWidth)/amountColumns));
    }
    
    function getCustomWidth (obj, index)
    {
        let customWidth = props?.config?.columns.filter(obj => obj.index === index) || [];
        return (customWidth.length !== 0) ? customWidth[0].width : widthColumn;
    }

    function getHeaderLimitedDynamicTable (isHeaderTitleRuler = null)
    {
        let limitedDynamicTablewidth = limitedDynamicTable?.current?.clientWidth
        let widthTableTitleRuler = (limitedDynamicTablewidth !== undefined) ? limitedDynamicTablewidth : 0;

        return (
            <div 
                className={`row no-gutters 
                    lineHeaderLimitedDynamicTable 
                    ${isHeaderTitleRuler && props?.config?.positionHeaderTitleRuler ? props.config.positionHeaderTitleRuler : ""}
                `}
                style={{"width": widthTableTitleRuler}}
            >
                {
                    props.header.map((h, i) => {
                        let customWidth = getCustomWidth(h, i);
                        let rdn = getRandomNumber();

                        return (
                            <div 
                                key={`${rdn}`}
                                className={`columnHeaderLimitedDynamicTable ${h?.justify ? h.justify : ""}`}
                                style={{
                                    width: `${customWidth}%`,
                                }}
                            >
                                <span>{h.name}</span>
                                {
                                    h.nameHelp && <span className="help">{h.nameHelp}</span>
                                }
                            </div>
                        )                        
                    })
                }
            </div>
        )
    }

    return (
        <div className={`limitedDynamicTable ${props?.classaditional ? props.classaditional : ""}`} ref={limitedDynamicTable} >
            {props.withHeaderTitleRuler && getHeaderLimitedDynamicTable(true)}
            {getHeaderLimitedDynamicTable()}
            {
                props.body.map((bl, j) => {
                    let rdnOne = getRandomNumber();

                    return (
                        <div key={`${rdnOne}`} className="row no-gutters lineBodyLimitedDynamicTable">
                            {
                                bl.map((bc, k) => {
                                    let customWidth = getCustomWidth(bc, k);
                                    let rdnTwo = getRandomNumber();

                                    return (
                                        <div 
                                            key={`${rdnTwo}`}
                                            className={`columnBodyLimitedDynamicTable ${bc?.justify ? bc.justify : ""}`}
                                            style={{
                                                width: `${customWidth}%`,
                                            }}
                                        >
                                            <span
                                                className={`${bc.onClick && "click"}`}
                                                onClick={() => bc.onClick && bc.onClick(j, k)}
                                            >
                                                {bc.name}
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
            <div className="row no-gutters lineFooterLimitedDynamicTable">
                {
                    props.footer.map((f, l) => {
                        let customWidth = getCustomWidth(f, l);
                        let rdn = getRandomNumber();

                        return (
                            <div 
                                key={`${rdn}`}
                                className={`columnFooterLimitedDynamicTable ${f?.justify ? f.justify : ""}`}
                                style={{
                                    width: `${customWidth}%`,
                                }}
                            >
                                <span>{f.name}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}