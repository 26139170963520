import React from "react";
import "./CardDetailsContact.css";
import {getInitialsName} from "../../auxiliary/generalFunctions";
import Anchor from "../general/Anchor";

export default function CardDetailsContact (props)
{
    return (
        <div className={`cardDetailsContact ${props.classaditional}`} {...props}>
            <div className="row no-gutters lineCardDetailsContact">
                <div className="col-2 columnIconDetailsContact">
                    <span className="initialsNameDetailsContact">{getInitialsName(props.data.name)}</span>
                </div>
                <div className="col-10 columnDescriptionDetailsContact">
                    <Anchor 
                        label={props.data.name}
                        classaditional="anchorCardDetailsContact"
                        onClick={() => alert("nothing...")}
                    />
                </div>
            </div>
            {
                (props.data?.phone || props.data?.cellphone) &&
                <div className="row no-gutters lineCardDetailsContact">
                    <div className="col-2 columnIconDetailsContact">
                        <i className="fas fa-phone-alt iconDetailsContact"></i>
                    </div>
                    <div className="col-10 columnDescriptionDetailsContact">
                        <span>{props.data?.phone}</span>
                        <span>{props.data?.cellphone}</span>
                    </div>
                </div>
            }
            {
                props.data?.mail &&
                <div className="row no-gutters lineCardDetailsContact">
                    <div className="col-2 columnIconDetailsContact">
                        <i className="fal fa-envelope iconDetailsContact"></i>
                    </div>
                    <div className="col-10 columnDescriptionDetailsContact">
                        <span>{props.data?.mail}</span>
                    </div>
                </div>
            }
            {
                props.data?.product &&
                <div className="row no-gutters lineCardDetailsContact">
                    <div className="col-2 columnIconDetailsContact">
                        <i className="fal fa-building iconDetailsContact"></i>
                    </div>
                    <div className="col-10 columnDescriptionDetailsContact">
                        <span>{props.data?.product}</span>
                    </div>
                </div>
            }
        </div>
    )
}