import React, { Component } from "react";
import "./UnitCompany.css";
import Header from "../../components/CRM/Header";
import { connect } from "react-redux";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import { showModalMoreActions } from "../../store/actions/general";
import { getAllUnitCompany } from "../../store/actions/company";
import Message from "../../components/general/Message";
import MessageConfirmation from "../../components/general/MessageConfirmation";
import { APPLICATION_NAME, unitCompany } from "../../core/constants";
import TableEditUnitCompany from "../../components/general/TableEditUnitCompany";
import CompanyUnitController from "../../controllers/CompanyUnitController";
import { withRouter } from "react-router-dom";

const initialState = {
    allUnitCompany: [],
    allUnitCompanyChecked: false, //false as default
    filtered: false,

    hasOneUnitCompanyChecked: false,

    // start infos modal message
    message_type: "information",
    message_show: false,
    message: "",
    // ends infos modal message

    // start infos modal confirmation
    // start infos modal confirmation
    // start infos modal confirmation
    // modal properly
    message_type_confirmation: "warning", // "warning" as DEFAULT
    message_show_confirmation: false,
    message_confirmation: "", 
    // modal properly
    statusConfirmation: false, //false as DEFAULT
    nameFunctionYesModalConfirmation: "", // "" [empty] as DEFAULT
    objectConfirmation: {}, // {} [empty] as DEFAULT
    // ends infos modal confirmation
    // ends infos modal confirmation
    // ends infos modal confirmation
}

const companyUnitController = new CompanyUnitController();

class UnitCompany extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Unidades`;
        await this.getAllUnitCompany();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("UNIT COMPANY prevProps ", prevProps);
        // console.log("UNIT COMPANY this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}

        if ((this.state.allUnitCompany.length !== this.props.allUnitCompany.length) || (prevState.filtered !== this.state.filtered))
        {
            await this.getAllUnitCompany();
        }

        // for new deal business
        if (this.props.newUnitCompany !== undefined)
        {
            if (prevProps!== undefined && (prevProps.newUnitCompany?.id !== this.props.newUnitCompany.id))
            {
                let {id,
                    name,
                    phone,
                    status,
                    checked
                } = this.props.newUnitCompany;
                
                let newUnitCompany = {
                    id,
                    name,
                    phone,
                    status,
                    checked
                };

                let allUnitCompany = this.state.allUnitCompany;
                allUnitCompany.push(newUnitCompany);
                this.setState({allUnitCompany});
            }
        }

    }

    getAllUnitCompany = async () =>
    {
        const filter = this.state.filtered === true ? 2 : 1;
        await this.props.getAllUnitCompany(filter);
        this.setState({ allUnitCompany: this.props.allUnitCompany });
    }

    updateUnits = async () =>
    {
        const units = this.props.allUnitCompany;
        const checkeds = units.filter(unit => unit.checked);
        const filter = this.state.filtered;

        const newStatus = filter ? 1 : 2;
        const action = filter ? "desarquivada(s)" : "arquivada(s)";

        for (const unit of checkeds)
        {
            await companyUnitController.update(unit.id, {
                UNT_STATUS: newStatus
            });
        }
        this.getAllUnitCompany();
        this.setState({ hasOneUnitCompanyChecked: false, allUnitCompanyChecked: false });
        this.message("success", `Unidades(s) ${action} com sucesso!`);
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    saveStatus = async () =>
    {
        let object = await this.state.objectConfirmation;

        if (this.state.statusConfirmation)
        {
            let {status, id, final} = object;
            await this.message("success", `OK, iremos salvar como ${object.status}!`);
            console.log(`status:  ${status}, id: ${id} and final: ${final}`);

            await this.resetModalConfirmation();
            await this.setState({
                statusConfirmation: false,
                nameFunctionYesModalConfirmation: "",
                objectConfirmation: {},
            });
        }
        else
        {
            await this.setState({
                message_confirmation: `Você realmente deseja salvar como ${object.status}?`,
                message_show_confirmation: true,
                message_type_confirmation: "warning",
                nameFunctionYesModalConfirmation: "saveStatus",
                statusConfirmation: false
            });
        }

    }

    onClickYesModalConfirmation = async () =>
    {
        let fn = await this.state.nameFunctionYesModalConfirmation;
        await this.setState({statusConfirmation: true});
        await this.resetModalConfirmation();

        switch (fn) {
            case "saveStatus":
            this.saveStatus();
            break;

            default:
                break;
        }
    }

    resetModalConfirmation = async () =>
    {
        await this.setState({
            message_type_confirmation: "warning",
            message_show_confirmation: false,
            message_confirmation: "",
        });
    }

    onClickNoModalConfirmation = async () =>
    {
        await this.resetModalConfirmation();
    }

    render ()
    {
        return (
            <div className="unitCompany">
                <Header title="Unidades" classaditional="headerUnitCompany"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <MessageConfirmation
                    message_type={this.state.message_type_confirmation}
                    message={this.state.message_confirmation}
                    onClose={() => this.setState({message_show_confirmation: false})}
                    onClickNo={() => this.onClickNoModalConfirmation()}
                    onClickYes={() => this.onClickYesModalConfirmation()}
                    show={this.state.message_show_confirmation}
                />
                <div className="containerNavigationHeaderUnitCompany">
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderUnitCompany ${!this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: false});
                        }}
                    />
                    <ButtonIcon
                        icon="fas fa-archive"
                        classaditional={`buttonNavigationHeaderUnitCompany ${this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: true});
                        }}
                    />

                    {/* RESERVED */}
                    {/* <ButtonIcon
                        icon="fas fa-archive"
                        classaditional={`buttonNavigationHeaderUnitCompany ${this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: true});
                        }}
                    /> */}
                    {
                        (!this.state.hasOneUnitCompanyChecked && this.props.permissions.toInsert) &&
                        <Button
                            icon="fal fa-plus"
                            name="&nbsp;&nbsp;Unidade"
                            classaditional="buttonPlusNavigationHeaderUnitCompany"
                            onClick={() => {
                                let data = {
                                    show: true,
                                    type: unitCompany
                                }
                                this.props.showModalMoreActions(data);
                            }}
                        />
                    }

                    {/* RESERVED */}
                    {/* {
                        this.state.hasOneUnitCompanyChecked &&
                        <ButtonIcon
                            icon="fas fa-trash"
                            classaditional="buttonTrashNavigationHeaderUnitCompany"
                            onClick={() => this.message("information", "nothing...")}
                        />
                    } */}
                    {/* {
                        (this.state.hasOneUnitCompanyChecked && this.props.permissions.toUpdate) &&
                        <Button
                            icon="fal fa-check"
                            name="&nbsp;&nbsp;Ativo"
                            classaditional="buttonPlusNavigationHeaderUnitCompany"
                            onClick={async () => {
                                let object = {
                                    id: parseInt(Math.random() * 123),
                                    status: "ATIVO",
                                    final: "ACTIVE"
                                };

                                await this.setState({objectConfirmation: object});
                                await this.saveStatus();
                            }}
                        />
                    } */}
                    {/* {
                        (this.state.hasOneUnitCompanyChecked && this.props.permissions.toUpdate) &&
                        <Button
                            icon="fal fa-times"
                            name="&nbsp;&nbsp;Inativo"
                            classaditional="buttonPlusNavigationHeaderUnitCompany"
                            onClick={async () => {
                                let object = {
                                    id: parseInt(Math.random() * 123),
                                    status: "INATIVO",
                                    final: "INACTIVE"
                                };

                                await this.setState({objectConfirmation: object});
                                await this.saveStatus();
                            }}
                        />
                    } */}
                    {
                        (this.state.hasOneUnitCompanyChecked && this.props.permissions.toFile) &&
                        <Button
                            icon="fas fa-archive"
                            name={ `\u00A0\u00A0${this.state.filtered ? "Desarquivar" : "Arquivar"}` }
                            classaditional="buttonPlusNavigationHeaderUnitCompany"
                            onClick={() => this.updateUnits()}
                        />
                    }
                </div>
                <div className="containerListHeaderUnitCompany">
                    <TableEditUnitCompany
                        data={this.props.allUnitCompany}
                        allUnitCompanyChecked={this.state.allUnitCompanyChecked}
                        onChangeHasOneUnitChecked={(hasOneUnitCompanyChecked) => this.setState({hasOneUnitCompanyChecked})}
                        permissions={this.props.permissions}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { allUnitCompany, newUnitCompany  } = state.company;
    const permissions = state.permissions.companyUnit;
    const { user } = state.auth;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        hasPermissionsLoaded,
        allUnitCompany,
        newUnitCompany,
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        getAllUnitCompany (data)
        {
            //action creator -> action
            const action = getAllUnitCompany(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnitCompany));