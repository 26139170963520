import React, { useState } from "react"; 
import "./LargeModal.css";
import Modal from 'react-bootstrap/Modal'
import { FaCheck } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5';
import { AiOutlineClose } from 'react-icons/ai';
import { FiAlertTriangle, FiAlertCircle } from 'react-icons/fi';
import Button from "./Button";

export default function LargeModal (props)
{
    const [isDisabled, setIsDisabled] = useState(false);

    return (
        <Modal
            {...props}
            fullscreen={true}
            aria-labelledby="contained-modal-title-vcenter"
            className="large-modal"
            // centered
            // dialogClassName="modal-fullscreen-dialog"
        >
            <div className="header">
                {
                    props?.message_type === "success" &&
                    <span className="status success"><FaCheck className="iconStatus" /> Sucesso!</span>
                }
                {
                    props?.message_type === "error" &&
                    <span className="status error"><IoCloseSharp className="iconStatus"/> Erro!</span>
                }
                {
                    props?.message_type === "warning" &&
                    <span className="status warning"><FiAlertTriangle className="iconStatus"/> Aviso!</span>
                }
                {
                    props?.message_type === "information" &&
                    <span className="status information"><FiAlertCircle className="iconStatus"/> Informação!</span>
                }
                
                <button className="close" onClick={() => props.onClose()}><AiOutlineClose className="iconButton" /></button>
            </div> 
            <div className="body">
                <div className="row no-gutters w-100 lineBodyLargeModal">
                    <div className="col-12">
                        <p>{props.message}</p>
                        {props.children}
                    </div>
                </div>
                {
                    (props.onClickNo || props.onClickYes) &&
                    <div className="row no-gutters lineBodyLargeModal">
                        <div className="col-4 columnBodyMessageConfirmation">
                            {
                                props.onClickNo &&
                                <Button 
                                    classaditional="buttonMessageConfirmation"
                                    name="Não" 
                                    onClick={(e) => props.onClickNo(e)} 
                                />
                            }
                        </div>
                        <div className="col-2"></div>
                        <div className="col-2"></div>
                        <div className="col-4 columnBodyMessageConfirmation d-flex justify-content-end">
                            {
                                (props.onClickYes && props?.showButtonYes !== false) &&
                                <Button 
                                    classaditional="buttonMessageConfirmation positive"
                                    name={isDisabled ? "Aguarde..." : "Sim"} 
                                    onClick={async (e) => {
                                        setIsDisabled(true);
                                        await props.onClickYes(e);
                                        setIsDisabled(false);
                                    }}
                                    disabled={isDisabled}
                                />
                            }
                        </div>
                    </div>
                }
            </div>
        </Modal>
    );
}
  