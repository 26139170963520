import React, { Component } from "react";
import "./CommissionReportAttendant.css";
import { connect } from "react-redux";
import {
    formatedDateTimeToUSA,
    getDateTimeCurrentBrowserAtMidnight,
} from "../../auxiliary/generalFunctions";
import {
    APPLICATION_NAME
} from "../../core/constants";

import Header from "../../components/CRM/Header";
import ButtonIcon from "../../components/general/ButtonIcon";

import TableEditCommissionReportAttendant from "../../components/general/TableEditCommissionReportAttendant";
import InputGeneral from "../../components/general/InputGeneral";
import { withRouter } from "react-router-dom";
import CommissionReportProspectionController from "../../controllers/CommissionReportProspectionController";

const commissionReportProspectionController = new CommissionReportProspectionController();

const initialState = {
    allCommissionReportAttendant: [],
    currentMonthYear: "", //looks like: "2022-04"
}

class CommissionReportAttendant extends Component
{
    state = { ...initialState }

    componentDidMount = async () =>
    {
        document.title = `${APPLICATION_NAME} - Relatório de Comissão - Vendedores`;

        let { params } = this.props.match;
        let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight()); //looks like "2022-04-01"

        if (params?.interval && params.interval !== undefined)
        {
            currentDate = params.interval; //looks like "2022-04"
        }

        await this.setCurrentMonthYear(currentDate);
        await this.getCommissionReportAttendant();
    }

    componentDidUpdate = async (prevProps, prevState) =>
    {
        // console.log("COMMISSION REPORT prevProps ", prevProps);
        // console.log("COMMISSION REPORT this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}
    }

    setCurrentMonthYear = async (currentDate) =>
	{
		let parts = currentDate.toString().split("-");

		let y = parseInt(parts[0]);
		let m = parseInt(parts[1]).toString().padStart(2, '0');

		let currentMonthYear = `${y}-${m}`;

		await this.setState({currentMonthYear});
	}

    getCommissionReportAttendant = async () =>
    {
        const result = await commissionReportProspectionController.get(
            this.state.currentMonthYear,
            "ATTENDANT"
        );

        if (!result.status) 
        {
            return;
        }

        let allCommissionReportAttendant = [];

        for (const report of result.data.data)
        {
            allCommissionReportAttendant.push({
                id: report.CMP_ID,
                name: report.PER_NAME,
                amountBusiness: report.CMP_AMOUNT_BUSINESS,
                amountScheduledSamePeriod: report.CMP_AMOUNT_BUSINESS_SCHEDULED_SAME_PERIOD,
                amountScheduledAnotherPeriod: report.CMP_AMOUNT_BUSINESS_SCHEDULED_ANOTHER_PERIOD,
                amountScheduledTo: report.CMP_AMOUNT_BUSINESS_SCHEDULED_TO,
                amountShowedup: report.CMP_AMOUNT_BUSINESS_SHOWEDUP,
                percentScheduled: report.CMP_PERCENT_BUSINESS_SCHEDULED,
                percentShowedup: report.CMP_PERCENT_BUSINESS_SHOWEDUP,
                amountCommissionUnitary: report.CMP_AMOUNT_COMMISSION_UNITARY,
                amountCommissionTotal: report.CMP_AMOUNT_COMMISSION_TOTAL,
            });
        }

        allCommissionReportAttendant.sort((a, b) => 
        {
            if (a.percentScheduled < b.percentScheduled) 
            {
                return 1;
            } 
            else if (a.percentScheduled > b.percentScheduled)
            {
                return -1;
            }
            else {
                return 0;
            }
        })

        await this.setState({ allCommissionReportAttendant });
    }

    render ()
    {
        return (
            <div className="commissionReportAttendant">
                <Header title="Relatório de Comissão - Vendedores" classaditional="headerCommissionReportAttendant" />
                <div className="warningCommissionReportAttendant d-flex d-lg-none">
                    <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                </div>
                <div className="containerNavigationHeaderCommissionReportAttendant">
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional="buttonNavigationHeaderCommissionReportAttendant active"
                        onClick={() => {}}
                    />
                    <div className={`filterStatusHeaderCommissionRules d-none d-lg-flex ml-auto`}>
                        <InputGeneral
                            classaditional="inputFilterCommissionRules"
                            type="month"
                            value={this.state.currentMonthYear}
                            onChange={async (e) => {
                                await this.setCurrentMonthYear(e.target.value);
                                await this.getCommissionReportAttendant();
                            }}
                        />
                    </div>
                </div>
                <div className="containerListHeaderCommissionReportAttendant">
                    <TableEditCommissionReportAttendant
                        data={this.state.allCommissionReportAttendant}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;
    const permissions = state.permissions.commissionReportAttendant;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;
    
    return {
        hasPermissionsLoaded,
        user,
        permissions
    }
}

export default withRouter(connect(mapStateToProps, null)(CommissionReportAttendant));