import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class PermissionsDetailsController 
{
    async get()
    {
        const response = await api.permissionsDetails().get();
        return apiCallbackTreatment(response);
    }
}