import React, { Component } from "react";
import "./DetailsPermissions.css";
import { withRouter } from "react-router-dom";
import Header from "../../components/CRM/Header";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import Message from "../../components/general/Message";
import Box from "../../components/general/Box";
import Check from "../../components/general/Check";
import Button from "../../components/general/Button";
import SelectMulti from "../../components/general/SelectMulti";
import PermissionsDetailsController from "../../controllers/PermissionsDetailsController";
import PermissionsGroupController from "../../controllers/PermissionsGroupController";
import { getAllPermissions } from "../../store/actions/permissions";
import { APPLICATION_NAME } from "../../core/constants";
const camelCase = require('camelcase');

const initialState = {

    permissions: [],
    allPermissionsGroup: [],
    allCollectionsPermissionsGroup: [],
    name: "",
    id: 0,
    message_type: "information",
    message_show: false,
    message: "",
    collections: [],
    labels: []
}

const permissionsDetailsController = new PermissionsDetailsController();
const permissionsGroupController = new PermissionsGroupController();

class DetailsPermissions extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {   
        document.title = `${APPLICATION_NAME} - Detalhes Permissões`;
        await this.getDetailsPermissions();
        // this.getAllPermissionsGroup();
    }

    getDetailsPermissions = async () => 
    {
        const { path, params } = this.props.match;
        
        if (path.includes("permissions") && path.includes("details")) 
        {
            if (params !== null) 
            {
                let id = params.id;

                const result = await permissionsGroupController.getById(id);
                let name = result.status ? result.data.PEG_NAME : "";

                let allCollectionsPermissionsGroup = [];
                const getAll = await permissionsGroupController.get();
                this.setState({ permissions: result.data.permissions });

                let collections = [];
                collections = result.data.collections;
                this.setState({ collections: result.data.collections });

                if (getAll.status) {

                    for (const permission of getAll.data.data)
                    {
                        let checked = collections
                            .some(c => c.PEB_ID_PERMISSIONS_GROUP === parseInt(id) && c.PEB_ID_PERMISSIONS_GROUP_CONTROLLED === permission.PEG_ID);
                        allCollectionsPermissionsGroup.push({
                            id: permission.PEG_ID,
                            name: permission.PEG_NAME,
                            value: permission.PEG_ID,
                            checked
                        });
                    }

                }

                let labels = [];
                const getDetails = await permissionsDetailsController.get();
                if (getDetails.status)
                {
                    for (const label of getDetails.data.data)
                    {
                        labels.push({
                            name: label.PED_PERMISSIONS_NAME,
                            action: camelCase(label.PED_SLUG),
                            value: label.PER_NUMBER_TO_SUM
                        });
                    }
                }
                
                this.setState({name, id, allCollectionsPermissionsGroup, labels});
            }
        }   
    }

    toggleCheckedPermissionsComponent = async (id) =>
    {
        let permissions = await this.state.permissions;

        permissions.map(p => {
            if (p.id === id)
            {
                let checkedFinal = !p.checked;
                p.checked = checkedFinal;

                p.actions.map(a => {
                    a.toView = checkedFinal;

                    return a;
                });
            }

            return p;
        });

        await this.setState({permissions});
    }

    toggleCheckedPermissionsActions = async (idComponent, idAction, type) =>
    {
        let permissions = await this.state.permissions;
        permissions.map(p => {
            if (p.id === idComponent)
            {
                let actionsAllChecked = true;

                p.actions.map(a => {
                    
                    if (a.id === idAction)
                    {
                        a[type] = !a[type];
                    }
                    
                    if (!a.toView)
                    {
                        actionsAllChecked = false;
                    }
                    return a;
                });

                p.checked = actionsAllChecked;
            }

            return p;
        });

        await this.setState({permissions});

    }

    calculateSum = (action) => 
    {
        let sum = 0;
        const labels = this.state.labels;
        for (const l of labels)
        {
            if (action[l.action])
            {
                sum += parseInt(l.value);
            }
        }
        return sum;
    }

    savePermissionsGroup = async () =>
    {
        let permissions = this.state.permissions;
        const { params } = this.props.match;
        const id = params.id;

        const allPermissionsGroup = this.state.allCollectionsPermissionsGroup;
        const collections = this.state.collections;

        await permissionsGroupController.savePermissions(id, {
            permissions,
            allPermissionsGroup,
            collections
        });

        this.getDetailsPermissions();

        if (parseInt(id) === this.props.user.PER_ID_PERMISSIONS_GROUP) 
        {
            this.props.getAllPermissions();
        }

        this.message("success", `Alterações efetuadas com sucesso!`);
    }
    
    componentDidUpdate = async (prevProps) =>
    {
        // console.log("DETAILS PERMISSIONS prevProps ", prevProps);
        // console.log("DETAILS PERMISSIONS this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.userPermissions.toView) { this.props.history.push(`/activities`); }
		}

        if (this.state.draggableUpdated)
        {
            let allDraggables = await this.props.allDraggables;
            await this.setState({allDraggables, draggableUpdated: false});
            await this.getDetailsPermissions();
        }
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render ()
    {
        return (
            <div className="detailsPermissions">
                <Header title="Grupos de Permissão" classaditional="headerNavigationDetailsPermissions"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="scrollDetailsPermissions mb-4">
                    <div className="container headerDetailsPermissions">
                        <Box>
                            <h4>Alterando o Grupo de Permissão {this.state.name}</h4>
                        </Box>
                    </div>
                    <div className="container bodyDetailsPermissions">
                        <Box classaditional="boxBodyDetailsPermissions" >
                            <div className="row no-gutters mb-4 lineCollectionsDetailsPermissions">
                                <div className="col-12 col-lg-4"></div>
                                <div className="col-12 col-lg-4 pl-2 pr-2">
                                    <div className="filterBodyDetailsPermissions">
                                        <i className="fas fa-user-friends iconfilterBodyDetailsPermissions"></i>
                                        <SelectMulti
                                            classaditional="selectFilterBodyDetailsPermissions"
                                            default={this.state.allPermissionsGroup}
                                            options={this.state.allCollectionsPermissionsGroup}
                                            onChange={(allPermissionsGroup) => this.setState({allPermissionsGroup})}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4"></div>
                            </div>
                            <div className="row no-gutters lineTitleHeaderChecksConfigDetailsPermissions">
                                <div className="col-4 columnTitleHeaderChecksConfigDetailsPermissions">
                                    <span>Componente</span>
                                </div>
                                {
                                    this.state.labels.map((l, i) => (
                                        <div key={i} className="col columnTitleHeaderChecksConfigDetailsPermissions">
                                            <span>{l.name}</span>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                this.state.permissions.map((p, i) => {
                                    return (
                                        <div key={`${i}`}>
                                            <div className="row no-gutters lineHeaderChecksConfigDetailsPermissions">
                                                <div className="col-4 columnHeaderChecksConfigDetailsPermissions">
                                                    <span>{p.name}</span>
                                                </div>
                                                {
                                                    this.state.labels.map((l, i) => (
                                                        <div key={i} className="col columnHeaderChecksConfigDetailsPermissions">
                                                            {
                                                                i === 0 && (
                                                                    <Check
                                                                        classaditional={`checkHeaderConfigDetailsPermissions ${p.checked ? "active" : ""}`}
                                                                        onClick={() => this.toggleCheckedPermissionsComponent(p.id)}
                                                                        checked={p.checked}
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            {
                                                p.actions.map((a, i) => {
                                                    return (
                                                        <div key={`${i}`} className="row no-gutters lineBodyChecksConfigDetailsPermissions">
                                                            <div className="col-4 columnBodyChecksConfigDetailsPermissions">
                                                                <span>{a.name}</span>
                                                            </div>
                                                            {
                                                                this.state.labels.map((l, i) => 
                                                                {
                                                                    return (
                                                                        <div key={i} className="col columnBodyChecksConfigDetailsPermissions">
                                                                            {
                                                                                a.showCheckbox[l.action] && (
                                                                                    <Check
                                                                                        classaditional={`checkBodyConfigDetailsPermissions ${a[l.action] ? "active" : ""}`}
                                                                                        checked={a[l.action]}
                                                                                        onClick={() => this.toggleCheckedPermissionsActions(p.id, a.id, l.action)}
                                                                                    /> 
                                                                                )
                                                                            }
                                                                        </div>
                                                                )})
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                            <div className="row no-gutters lineFooterChecksConfigDetailsPermissions">
                                <Button
                                    classaditional="buttonCheckConfigDetailsPermissions"
                                    name="Cancelar"
                                    onClick={() => this.props.history.push(`/permissions`)}
                                    />
                                {
                                    this.props.userPermissions.toUpdate &&
                                        <Button
                                            classaditional="buttonCheckConfigDetailsPermissions positive"
                                            name="Salvar"
                                            onClick={() => this.savePermissionsGroup()}
                                        />
                                }      
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;
    const userPermissions = state.permissions.permissionGroups;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        hasPermissionsLoaded,
        user,
        userPermissions
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        getAllPermissions()
        {
            const action = getAllPermissions();
            dispatch(action);
        },
        showModalMoreActions(data)
        {
            const action = showModalMoreActions(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsPermissions));