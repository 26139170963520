import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditCommissionRulesProspection.css";
import { 
    formatedMoneyBRAFromFloat, formatedPercentToFloat,
} from "../../auxiliary/generalFunctions";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";
import Select from "./Select";
import Button from "./Button";

const initialState = {
    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
}

class TableEditCommissionRulesProspection extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT COMMISSION RULES prevProps ", prevProps);
        // console.log("TABLE EDIT COMMISSION RULES this.props ", this.props);        
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    setPositionsDetails = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX, clientY} = e;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        clientX = clientX - 180;

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    getGroupName = group =>
    {
        switch(group) {
            case "ATTENDANT":
                return "Atendente";
            case "SUPERVISOR":
                return "Supervisor";
            default:
                return "";
        }
    }

    getTypeName = type =>
    {
        switch(type) {
            case "SCHEDULED":
                return "Agendamento";
            case "SHOWEDUP":
                return "Comparecimento";
            default:
                return "";
        }
    }

    getTypeCurrentRuleName = type =>
    {
        switch(type) {
            case 1:
                return "Percentual";
            case 2:
                return "Quantidade";
            default:
                return "";
        }
    }

    render ()
    {
        return (
            <div className="tableEditCommissionRulesProspection">
                <label className="helpTableEditCommissionRulesProspection" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditCommissionRulesProspection" ref={headerTable => {this.headerTable = headerTable}}>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Grupo")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Grupo
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Tipo")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Tipo
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Tipo Meta")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Regra
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Meta Diária")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Quantidade Diária
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Meta Mensal")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Quantidade Mensal
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Meta Mínima (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Meta Mínima (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Meta Máxima (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Meta Máxima (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Comissão (R$)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Comissão (R$)
                            </td>
                            <td className="minimus"></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props?.data.map((e, i) => {

                                return (
                                    <tr key={`${i}`} className="tbodyTableEditCommissionRulesProspection">
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRulesProspection 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Grupo" 
                                                placeholder={this.getGroupName(e.group)}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CPD_GROUP", e.group)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions} 
                                                optionsSelectMulti
                                            >
                                                <Select
                                                    default={{
                                                        name: this.getGroupName(e.group),
                                                        value: e.group,
                                                        checked: true
                                                    }}
                                                    options={[
                                                        {
                                                            name: "Atendente",
                                                            value: "ATTENDANT",
                                                            checked: false
                                                        },
                                                        {
                                                            name: "Supervisor",
                                                            value: "SUPERVISOR",
                                                            checked: false
                                                        }
                                                    ]} 
                                                    onChange={async (choosenGroup) => this.props.onChangeValue(choosenGroup.value, e.id, "group")}
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRulesProspection 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Tipo" 
                                                placeholder={this.getTypeName(e.type)}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CPD_TYPE", e.type)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions} 
                                                optionsSelectMulti
                                            >
                                                <Select
                                                    default={{
                                                        name: this.getTypeName(e.type),
                                                        value: e.type,
                                                        checked: true
                                                    }}
                                                    options={[
                                                        {
                                                            name: "Agendamento",
                                                            value: "SCHEDULED",
                                                            checked: false
                                                        },
                                                        {
                                                            name: "Comparecimento",
                                                            value: "SHOWEDUP",
                                                            checked: false
                                                        }
                                                    ]} 
                                                    onChange={async (choosenType) => this.props.onChangeValue(choosenType.value, e.id, "type")}
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRulesProspection 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Regra" 
                                                placeholder={this.getTypeCurrentRuleName(e.typeCurrentRule)}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CPD_TYPE_CURRENT_RULE", e.typeCurrentRule)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions} 
                                                optionsSelectMulti
                                            >
                                                <Select
                                                    default={{
                                                        name: this.getTypeCurrentRuleName(e.typeCurrentRule),
                                                        value: e.typeCurrentRule,
                                                        checked: true
                                                    }}
                                                    options={[
                                                        {
                                                            name: "Percentual",
                                                            value: 1,
                                                            checked: false
                                                        },
                                                        {
                                                            name: "Quantidade",
                                                            value: 2,
                                                            checked: false
                                                        }
                                                    ]} 
                                                    onChange={async (choosenType) => this.props.onChangeValue(choosenType.value, e.id, "typeCurrentRule")}
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRulesProspection 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.minimumGoalDay}
                                                placeholder={e.minimumGoalDay}
                                                name="Quantidade Diária"
                                                onChange={value => this.props.onChangeValue(value, e.id, "minimumGoalDay")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CPD_MINIMUM_GOAL_DAY", formatedPercentToFloat(e.minimumGoalDay))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRulesProspection 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.minimumGoalMonth}
                                                placeholder={e.minimumGoalMonth}
                                                name="Quantidade Mensal"
                                                onChange={value => this.props.onChangeValue(value, e.id, "minimumGoalMonth")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CPD_MINIMUM_GOAL_MONTH", formatedPercentToFloat(e.minimumGoalMonth))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRulesProspection 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.minimumPercent}
                                                placeholder={e.minimumPercent}
                                                name="Meta Mínima"
                                                onChange={value => this.props.onChangeValue(value, e.id, "minimumPercent")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CPD_MINIMUM_PERCENT", formatedPercentToFloat(e.minimumPercent))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRulesProspection 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.maximumPercent}
                                                placeholder={e.maximumPercent}
                                                name="Meta Máxima"
                                                onChange={value => this.props.onChangeValue(value, e.id, "maximumPercent")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CPD_MAXIMUM_PERCENT", formatedPercentToFloat(e.maximumPercent))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRulesProspection 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedMoneyBRAFromFloat(e.amountCommission)}
                                                placeholder={formatedMoneyBRAFromFloat(e.amountCommission)}
                                                name="Comissão (R$)"
                                                onChange={value => this.props.onChangeValue(formatedMoneyBRAFromFloat(value), e.id, "amountCommission")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CPD_AMOUNT_COMMISSION", formatedPercentToFloat(e.amountCommission))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                        {
                                                this.props.statusReport !== 1 && this.props.permissions.toDelete &&
                                                <div className="detailsTableEditCommissionRulesProspection">
                                                    <Button
                                                        classaditional="buttonShowDetailsTableEditCommissionRulesProspection error"
                                                        icon="fas fa-trash"
                                                        onClick={() => this.props.onRemoveDetailsProspection(e.id)}
                                                    />
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default connect(null, null)(TableEditCommissionRulesProspection);