import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditBusiness.css";
import { formatedMoneyBRA, formatedMoneyBRAFromFloat } from "../../auxiliary/generalFunctions";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";
import Check from "./Check";
import Select from "./Select";

const initialState = {
    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default

    optionsOwners: [],
    optionsProspectionOwnersTransfer: [],
}

class TableEditBusiness extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });

        await this.setState({optionsOwners: this.props.optionsOwners, optionsProspectionOwnersTransfer: this.props.optionsProspectionOwnersTransfer});
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    render ()
    {
        return (
            <div className="tableEditBusiness">
                <label className="helpTableEditBusiness" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditBusiness" ref={headerTable => {this.headerTable = headerTable}}>
                            <td className="checkTableEditBusiness">
                                <Check
                                    classaditional={`buttonCheckTableEditBusiness ${(this.props.allDraggablesChecked) ? "active" : ""}`}
                                    checked={this.props.allDraggablesChecked}
                                    onClick={() => this.props.onToggleCheckDraggable(!this.props.allDraggablesChecked)}
                                />
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Título")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Título</td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Valor")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Valor</td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Pessoa de Contato")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Pessoa de Contato</td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Data de Fechamento")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Data de Fechamento</td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Atendente")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Atendente</td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Proprietário")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Proprietário</td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "ID Serviço")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                ID Serviço</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props?.data.map((e, i) => {

                                return (
                                    <tr key={`${i}`} className="tbodyTableEditBusiness">
                                        <td className="checkTableEditBusiness">
                                            <Check
                                                classaditional={`buttonCheckTableEditBusiness ${(e.checked) ? "active" : ""}`}
                                                checked={e.checked}
                                                onClick={() => {
                                                    this.props.onChangeValue(!e.checked, e.id, "checked");
                                                    this.props.hasAllDraggablesChecked();
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditBusiness 
                                                    ${!this.props.permissions.business.toUpdate && "noEdit"}` 
                                                }
                                                value={e.title}
                                                placeholder={e.title}
                                                name="Título"
                                                onChange={value => this.props.onChangeValue(value, e.id, "title")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "BUS_TITLE", e.title)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions.business}
                                            />
                                        </td>                                    
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditBusiness 
                                                    ${!this.props.permissions.business.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedMoneyBRAFromFloat(e.money)}
                                                placeholder={formatedMoneyBRAFromFloat(e.money)}
                                                name="Valor"
                                                onChange={value => this.props.onChangeValue(formatedMoneyBRA(value), e.id, "money")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "BUS_MONEY_VALUE", formatedMoneyBRA(e.money))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions.business}
                                            />                                     
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditBusiness 
                                                    ${!this.props.permissions.leads.toUpdate && "noEdit"}` 
                                                }
                                                value={e.name}
                                                placeholder={e.name}
                                                name="Pessoa de Contato"
                                                onChange={value => this.props.onChangeValue(value, e.id, "name")}
                                                onPositiveClick={() => this.props.onSaveValue(e.clientId, "PER_NAME", e.name)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions.leads}
                                            />                                    
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditBusiness noEdit"
                                                value={e.statusDate}
                                                placeholder={e.statusDate}
                                                name="Data de Fechamento"
                                                onChange={value => {}}
                                                onPositiveClick={() => {}}
                                                onNegativeClick={() => {}}
                                                permissions={this.props.permissions.business}
                                            />    
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditBusiness 
                                                    ${!this.props.permissions.business.toUpdate && "noEdit"}` 
                                                }
                                                name="Atendente"
                                                optionsSelectMulti
                                                placeholder={e.prospectionOwner?.name}
                                                onChange={() => {}}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "BUS_ID_PERSON_PROSPECTION_OWNER", e.prospectionOwner?.value)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions.business}
                                                >
                                                <Select
                                                    default={e.prospectionOwner}
                                                    options={this.state.optionsProspectionOwnersTransfer}
                                                    onChange={async (optionChosenProspectionOwner) => {
                                                        await this.props.onChangeValue(optionChosenProspectionOwner, e.id, "prospectionOwner");
                                                    }}
                                                    withFieldSearch
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditBusiness 
                                                    ${!this.props.permissions.leadOwners.toView && "noEdit"}` 
                                                }
                                                name="Proprietário"
                                                optionsSelectMulti
                                                placeholder={e.owner?.name}
                                                onChange={() => {}}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "BUS_ID_PERSON_OWNER", e.owner?.value)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={e.ownerPermissions}
                                                >
                                                <Select
                                                    default={e.owner}
                                                    options={this.state.optionsOwners}
                                                    onChange={async (optionChosenOwner) => {
                                                        await this.props.onChangeValue(optionChosenOwner, e.id, "owner");
                                                    }}
                                                    withFieldSearch
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditBusiness noEdit"
                                                value={e.idServiceERP}
                                                placeholder={e.idServiceERP}
                                                name="ID Serviço"
                                                onChange={value => {}}
                                                onPositiveClick={() => {}}
                                                onNegativeClick={() => {}}
                                                permissions={this.props.permissions.business}
                                            />   
                                        </td>
                                    </tr>
                                )
                            })
                        }
    
                        {/* RESERVED */}
                        {/* <tr className="tbodyTableEditBusiness">
                            <td className="checkTableEditBusiness">C</td>
                            <td>Dívida do...</td>
                            <td>R$ 123,99</td>
                            <td>Maria</td>
                            <td>10/10/2010</td>
                            <td>10/10/2011</td>
                            <td>Gustavo</td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default connect(null)(TableEditBusiness);