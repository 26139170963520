import React, { Component } from "react"; 
import "./Header.css";

import { 
    getWindowDimensions, 
    getPortugueseTypeComponent, 
    formatedMoneyBRAFromFloat
} from "../../auxiliary/generalFunctions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showModalMoreActions } from "../../store/actions/general";
import { setUserLogout } from "../../store/actions/auth";
import { setModalMessage } from "../../store/actions/general";
import { 
    person, 
    owner, 
    business, 
    activity, 
    campaign,
    unitCompany,
    commissionRule,
    permissionGroup, 
    businessByClient,
    commissionReport,
    absenceAttendants,
    commissionRuleIntervals,
    commissionRuleProspection,
    commissionReportIntervals,
} from "../../core/constants";

import OwnerForm from "./OwnerForm";
import PersonForm from "./PersonForm";
import Anchor from "../general/Anchor";
import Button from "../general/Button";
import BusinessForm from "./BusinessForm";
import ActivityForm from "./ActivityForm";
import CampaignForm from "./CampaignForm";
import DropDown from "../general/DropDown";
import ButtonIcon from "../general/ButtonIcon";
import UnitCompanyForm from "./UnitCompanyForm";
import InputSearch from "../general/InputSearch";
import ModalGeneral from "../general/ModalGeneral";
import CommissionRuleForm from "./CommissionRuleForm";
import PermissionGroupForm from "./PermissionGroupForm";
import BusinessByClientForm from "./BusinessByClientForm";
import CommissionReportForm from "./CommissionReportForm";
import AbsenceAttendantsForm from "./AbsenceAttendantsForm";
import MessageConfirmation from "../general/MessageConfirmation";
import CommissionProspectionDetailsForm from "./CommissionProspectionDetailsForm";

import UserController from "../../controllers/UserController";
import BusinessController from "../../controllers/BusinessController";
import CommissionIntervalsDetailsForm from "./CommissionIntervalsDetailsForm";
import CommissionReportIntervalsForm from "./CommissionReportIntervalsForm";

const initialState = { 
    valueSearch: "",

    //start dropdown more actions (button plus)
    showDropDownMoreActions: false,
    widthDropDownMoreActions: 286,
    buttonMore: null, //ref for element DOM
    //ends dropdown more actions (button plus) 
    
    //start dropdown profile
    showDropDownProfile: false,
    widthDropDownProfile: 380,
    //ends dropdown profile

    showDropDownReports: false,
    showDropDownConfiguration: false,
    menusActivitiesActive: 1,
    
    showModalMoreActions: false,
    actionModalMoreActions: business,
    modeUpdateModalMoreActions: false,
    dataModalMoreActions: null,
    posY: 300,
    posX: 300,
    isMobile: false,
    header: null, //ref for element DOM

    dataSearch: [],
    amountSharedLeads: 50,
    loading: false,
    hasAvatar: false, //default is false

    // start message confirmation modal
    message_type_confirmation: "information",
    message_show_confirmation: false,
    message_confirmation: "",
    statusConfirmation: false,
    nameFunctionYesModalConfirmation: "",
    objectConfirmation: {},
    // ends message confirmation modal
}

const businessController = new BusinessController();
const userController = new UserController();
class Header extends Component
{
    state = {...initialState}

    componentDidMount = async () => {
        this.getAvatar();
        
        let header = this.header;
        let buttonMore = this.buttonMore;

        this.setState({ 
            header, 
            buttonMore,
            isMobile: getWindowDimensions().width < 768 ? true : false,
        });
    }

    componentDidUpdate = async (prevProps) => 
    {       
        // console.log("HEADER prevProps ", prevProps);
        // console.log("HEADER this.props ", this.props);

        if (prevProps?.showModal !== this.props.showModal)
        {
            this.setState({
                showModalMoreActions: this.props.showModal,
                actionModalMoreActions: this.props.typeModal,
                modeUpdateModalMoreActions: this.props.modeUpdateModal,
                dataModalMoreActions: this.props.dataModal
            });
        }
    }

    getAvatar = async () => 
	{
		let result = await userController.hasAvatar(this.props.user.PER_ID);
		if (result.status)
		{
			let {data} = result.data;
			this.setState({hasAvatar: data});
		}
	}

    setPostionsDropDownProfile = () =>
    {
        let width = this.state.widthDropDownProfile;
        
        if (this.state.isMobile) width = 300;

        let posX = getWindowDimensions().width - width;
        let posY = this.state.header.clientHeight - 5;        

        this.setState({
            showDropDownMoreActions: false,
            showDropDownProfile: !this.state.showDropDownProfile,
            widthDropDownProfile: width,
            posX,
            posY
        });
    }

    setPostionsDropDownMoreActions = () =>
    {
        let posX = this.state.buttonMore.getBoundingClientRect().x;
        let posY = this.state.header.clientHeight - 5;

        if (this.state.isMobile)
        {
            posX = getWindowDimensions().width - this.state.widthDropDownMoreActions;
        }

        this.setState({
            showDropDownProfile: false,
            showDropDownMoreActions: !this.state.showDropDownMoreActions,
            posX,
            posY
        });
    }

    getContentModalMoreActions = () =>
    {
        switch (this.state.actionModalMoreActions)
        {
            case person:
                return <PersonForm />;

            case business:
                return <BusinessForm />;

            case businessByClient:
                return <BusinessByClientForm data={this.state.dataModalMoreActions} />;

            case commissionReport:
                return <CommissionReportForm data={this.state.dataModalMoreActions} />;

            case commissionReportIntervals:
                return <CommissionReportIntervalsForm data={this.state.dataModalMoreActions} />;

            case owner:
                return <OwnerForm />;

            case unitCompany:
                return <UnitCompanyForm />;

            case permissionGroup:
                return <PermissionGroupForm />;

            case campaign:
                return <CampaignForm data={this.state.dataModalMoreActions} />;

            case absenceAttendants:
                return <AbsenceAttendantsForm />;

            case activity:
                return (
                    <ActivityForm 
                        inModal
                        business={{
                            name: "",
                            title: "",
                        }}
                        contentOption={this.state.menusActivitiesActive}
                    />
                );

            case commissionRule:
                return <CommissionRuleForm />;

            case commissionRuleProspection:
                return <CommissionProspectionDetailsForm data={this.state.dataModalMoreActions} />;

            case commissionRuleIntervals:
                return <CommissionIntervalsDetailsForm data={this.state.dataModalMoreActions} />;

            default:
                return <span>nothing...</span>;
        }
    }

    messageConfirmation = (type, message) =>
    {
        this.setState({
            message_type_confirmation: type,
            message_confirmation: message,
            message_show_confirmation: true
        });
    }

    getSharedLeads = async () =>
    {
        let obj = await this.state.objectConfirmation;

        if (!this.state.statusConfirmation)
        {
            let amountSharedLeads = obj.amountSharedLeads;
            this.messageConfirmation("warning", `Você deseja realmente importar ${amountSharedLeads} leads?`);
        }
        else
        {
            console.log("importing...");

            await this.setState({loading: true});

            const result = await businessController.duplicateFromAtoB(
                {
                    AMOUNT_SHARED_LEADS: obj.amountSharedLeads,
                    ID_COMPANY_UNIT_A: 7,
                    ID_COMPANY_UNIT_B: 17
                }
            );
    
            await this.setState({loading: false});
            
            if  (result.status)
            {
                this.message("success", "Obaaaaa, deu tudo certo! Aguarde, vamos redirecionar-te");
    
                setTimeout(() => {
                    window.location.href = "/business";
                }, 3000);
            }
            else
            {
                this.message("error", "Opsssss, alguma coisa não deu certo! Se o erro persistir, contacte o desenvolvedor.");
            }

            await this.setState({
                statusConfirmation: false,
                objectConfirmation: {},
            });
        }

    }

    search = async (value) =>
    {
        await this.setState({valueSearch: value});
        let rows = [];

        let limit = 50;

        if (value !== "")
        {

            if (value.length >= 4) 
            {

                if (value.length >= 3)
                {
                    limit = 500;
                }

                const result = await businessController.getByFilters({
                    USER_ID: this.props.user.PER_ID,
                    SEARCH: value,
                    LIMIT: limit
                });

                if (result.status)
                {
                    let business = result.data.data;

                    business = business.sort(function (a, b) 
                    {
                        if (a.PER_NAME > b.PER_NAME) 
                        {
                          return 1;
                        }
                        if (a.PER_NAME < b.PER_NAME) 
                        {
                          return -1;
                        }
                        return 0;
                    });

                    business = business.sort(function (a, b) 
                    {
                        if (a.UNT_NAME > b.UNT_NAME) 
                        {
                          return 1;
                        }
                        if (a.UNT_NAME < b.UNT_NAME) 
                        {
                          return -1;
                        }
                        return 0;
                    });

                    for (const row of business)
                    {
                        rows.push({
                            type: "business",
                            id: row.BUS_ID,
                            item: row.BUS_TITLE,
                            name: row.PER_NAME,
                            value: formatedMoneyBRAFromFloat(row.BUS_MONEY_VALUE),
                            progress: row.BUS_STEP,
                            status: row.BUS_STATUS,
                            companyUnit: row.UNT_NAME
                        });
                    }
                }
            
            }
            
        }
        
        await this.setState({ dataSearch: rows });

    }

    openModalMoreActions = (type) => {
        let data = {
            show: true,
            type: type
        }
        this.props.showModalMoreActions(data);
    }

    onClickYesConfirmation =  () =>
    {
        let fn = this.state.nameFunctionYesModalConfirmation;

        this.setState({
            statusConfirmation: true,
            message_show_confirmation: false,
            message_confirmation: "",
            message_type_confirmation: "information"
        });

        switch (fn) {
            case "onGetSharedLeads":
                this.getSharedLeads();
                break;
        
            default:
                break;
        }
    }

    onClickNoConfirmation = () =>
    {
        this.setState({
            statusConfirmation: false,
            objectConfirmation: {},
            message_show_confirmation: false,
            message_confirmation: "",
            message_type_confirmation: "information"
        })
    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }

    render ()
    {
        return (
            <div className={`row no-gutters header ${this.props?.classaditional}`} ref={header => {this.header = header}}>
                {/* start modals form more actions */}
                {
                    this.state.showModalMoreActions &&
                    <ModalGeneral
                        title={`${this.state.modeUpdateModalMoreActions ? "Editar" : "Adicionar" } `+ getPortugueseTypeComponent(this.state.actionModalMoreActions)}
                        onClose={() => {
                            let data = {
                                show: false,
                                type: null
                            };
                            this.props.showModalMoreActions(data);
                        }}                        
                    >
                        {this.getContentModalMoreActions()}
                    </ModalGeneral>
                }
                <MessageConfirmation
                    message_type={this.state.message_type_confirmation}
                    message={this.state.message_confirmation}
                    onClose={() => this.setState({message_show_confirmation: false})}
                    show={this.state.message_show_confirmation}
                    onClickYes={() => this.onClickYesConfirmation()}
                    onClickNo={() => this.onClickNoConfirmation()}
                />
                {/* ends modals form more actions */}
                <div className="col-12 col-md-4 colHeader">
                    <h1 className="title">{this.props?.title}</h1>
                    <div className="d-flex d-md-none ml-auto">
                        {
                            this.state.hasAvatar
                            ?
                            <div className="areaAvatarHeader" onClick={() => this.setPostionsDropDownProfile()}>
                                <img src={`${process.env.REACT_APP_BACKEND_URL}/user/avatar/${this.props.user.PER_ID}`} alt="Foto do usuário logado" className="avatarHeader" />
                            </div>
                            :
                            <ButtonIcon 
                                icon="fas fa-user"
                                classaditional="buttonProfileHeader"
                                onClick={() => this.setPostionsDropDownProfile()}
                            />
                        }
                    </div>
                </div>
                <div className="col-12 col-md-4 colHeader"> 
                    <div className="row no-gutters w-100">
                        <div className="col-10">
                            <InputSearch 
                                onChange={(e) => this.search(e.target.value)}
                                onClearInputSearch={() => this.setState({dataSearch: [], valueSearch: ""})}
                                placeholder="Pesquisar no Sempnet"
                                value={this.state.valueSearch}
                                data={this.state.dataSearch}
                                permissions={this.props.permissions}
                            />
                        </div>
                        <div className="col-2 d-flex justify-content-center"
                            ref={buttonMore => {this.buttonMore = buttonMore}}
                        >
                            {
                                (this.props.permissions.business.toInsert ||this.props.permissions.leads.toInsert) &&
                                    <ButtonIcon
                                        icon="fal fa-plus"
                                        classaditional="buttonMoreHeader"
                                        onClick={() => this.setPostionsDropDownMoreActions()}

                                    />
                            }
                            {
                                this.state.showDropDownMoreActions &&
                                <DropDown
                                    style={{
                                        top: `${this.state.posY}px`,
                                        left: `${this.state.posX}px`,
                                        width: `${this.state.widthDropDownMoreActions}px`,
                                    }}
                                    onMouseLeave={() => this.setState({showDropDownMoreActions: false})}
                                    classaditional="dropMoreActionsHeader"
                                >
                                    {/* <Button
                                        classaditional="buttonMoreActionsHeader"
                                        icon="fal fa-bullseye-arrow"
                                        name="Lead"
                                        onClick={() => this.setState({showModalMoreActions: true, actionModalMoreActions: lead})}
                                    /> */}
                                    {
                                        this.props.permissions.business.toInsert &&
                                            <Button
                                                classaditional="buttonMoreActionsHeader"
                                                icon="far fa-dollar-sign"
                                                name="Cadastrar novo Cliente/Negócio"
                                                onClick={() => this.openModalMoreActions(business)}
                                            />
                                    }
                                    {/* <Button
                                        classaditional="buttonMoreActionsHeader"
                                        icon="far fa-calendar-check"
                                        name="Atividade"
                                        onClick={() => this.setState({showModalMoreActions: true, actionModalMoreActions: activity})}
                                    /> */}
                                    {/* {
                                        this.props.permissions.leads.toInsert &&
                                            <Button
                                                classaditional="buttonMoreActionsHeader"
                                                icon="fas fa-user"
                                                name="Pessoa"
                                                onClick={() => this.openModalMoreActions(person)}
                                            />
                                    } */}
                                    {/* <Button
                                        classaditional="buttonMoreActionsHeader"
                                        icon="fas fa-sticky-note"
                                        name="Nota"
                                        onClick={() => this.setState({showModalMoreActions: true, actionModalMoreActions: note})}
                                    /> */}
                                </DropDown>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 colHeader justify-content-end">
                    <span className="nameProfileHeader d-none d-md-flex">{this.props.user.PER_NAME}</span>
                    {
                        this.state.hasAvatar
                        ?
                        <div className="areaAvatarHeader d-none d-md-flex" onClick={() => this.setPostionsDropDownProfile()}>
                            <img src={`${process.env.REACT_APP_BACKEND_URL}/user/avatar/${this.props.user.PER_ID}`} alt="Foto do usuário logado" className="avatarHeader" />
                        </div>
                        :
                        <ButtonIcon 
                            icon="fas fa-user"
                            classaditional="buttonProfileHeader d-none d-md-flex"
                            onClick={() => this.setPostionsDropDownProfile()}
                        /> 
                    }
                    {
                        this.state.showDropDownProfile &&
                        <DropDown
                            style={{
                                top: `${this.state.posY}px`,
                                left: `${this.state.posX}px`,
                                width: `${this.state.widthDropDownProfile}px`,
                            }}
                            onMouseLeave={() => this.setState({showDropDownProfile: false})}
                            classaditional="dropProfileHeader"
                        >
                            <span className="titleProfileHeader">{this.props.user.PER_NAME}</span>
                            {
                                this.props.permissions.personalPreferences.toView &&
                                    <Anchor 
                                        icon="fas fa-user-cog"
                                        classaditional="anchorProfileHeader"
                                        label="Preferências Pessoais"
                                        onClick={() => this.props.history.push("/preferences-user")}
                                    />
                            }
                            <div className="divider"></div>
                            <span className="titleProfileHeader">Empresa</span>
                            <Anchor
                                icon="fas fa-file-chart-line"
                                classaditional="anchorProfileHeader anchorProfileSubmenuHeader"
                                label="Relatórios"
                                onClick={() => this.setState({showDropDownReports: !this.state.showDropDownReports})}
                            >
                                {
                                    this.state.showDropDownReports &&
                                    <DropDown
                                        classaditional="dropProfileSubmenuHeader"
                                        style={{
                                            width: `calc(${this.state.widthDropDownProfile}px - 40px)`,
                                        }}
                                    >
                                        {
                                            this.props.permissions.commissionReport.toView &&
                                            <Button
                                                icon="far fa-angle-right"
                                                classaditional="buttonProfileSubmenuHeader"
                                                name="Comissão - Especialistas"
                                                onClick={() => this.props.history.push("/commission-report")}
                                            />
                                        }
                                        {
                                            this.props.permissions.commissionReportManager.toView &&
                                            <Button
                                                icon="far fa-angle-right"
                                                classaditional="buttonProfileSubmenuHeader"
                                                name="Comissão - Gerentes"
                                                onClick={() => this.props.history.push("/commission-report-manager")}
                                            />
                                        }
                                        {
                                            this.props.permissions.commissionReportAttendant.toView &&
                                            <Button
                                                icon="far fa-angle-right"
                                                classaditional="buttonProfileSubmenuHeader"
                                                name="Comissão - Vendedores"
                                                onClick={() => this.props.history.push("/commission-report-attendant")}
                                            />
                                        }
                                        {
                                            this.props.permissions.commissionReportSupervisor.toView &&
                                            <Button
                                                icon="far fa-angle-right"
                                                classaditional="buttonProfileSubmenuHeader"
                                                name="Comissão - Supervisores"
                                                onClick={() => this.props.history.push("/commission-report-supervisor")}
                                            />
                                        }
                                        {
                                            this.props.permissions.commissionReportIntervals.toView &&
                                            <Button
                                                icon="far fa-angle-right"
                                                classaditional="buttonProfileSubmenuHeader"
                                                name="Comissão - Limpa Nome"
                                                onClick={() => this.props.history.push("/commission-report-intervals")}
                                            />
                                        }
                                        {
                                            this.props.permissions.advancedBusiness.toView &&
                                            <Button
                                                icon="far fa-angle-right"
                                                classaditional="buttonProfileSubmenuHeader"
                                                name="Negócios - Avançado"
                                                onClick={() => this.props.history.push("/business/advanced")}
                                            />
                                        }
                                    </DropDown>
                                }
                            </Anchor>
                            <Anchor
                                icon="fas fa-cog"
                                classaditional="anchorProfileHeader anchorProfileSubmenuHeader"
                                label="Configurações"
                                onClick={() => this.setState({showDropDownConfiguration: !this.state.showDropDownConfiguration})}
                            >
                                {
                                    this.state.showDropDownConfiguration &&
                                    <DropDown
                                        classaditional="dropProfileSubmenuHeader"
                                        style={{
                                            width: `calc(${this.state.widthDropDownProfile}px - 40px)`,
                                        }}
                                    >
                                        {
                                            this.props.permissions.companyPreferences.toView &&
                                            <Button
                                                icon="far fa-angle-right"
                                                classaditional="buttonProfileSubmenuHeader"
                                                name="Empresa"
                                                onClick={() => this.props.history.push("/preferences-company")}
                                            />
                                        }
                                        {
                                            this.props.permissions.commissionRules.toView &&
                                            <Button
                                                icon="far fa-angle-right"
                                                classaditional="buttonProfileSubmenuHeader"
                                                name="Regras Comissão - Vendas Comercial"
                                                onClick={() => this.props.history.push("/commission-rules")}
                                            />
                                        }
                                        {
                                            this.props.permissions.commissionRulesProspection.toView &&
                                            <Button
                                                icon="far fa-angle-right"
                                                classaditional="buttonProfileSubmenuHeader"
                                                name="Regras Comissão - Vendas Operação"
                                                onClick={() => this.props.history.push("/commission-rules-prospection")}
                                            />
                                        }
                                        {
                                            this.props.permissions.commissionRulesIntervals.toView &&
                                            <Button
                                                icon="far fa-angle-right"
                                                classaditional="buttonProfileSubmenuHeader"
                                                name="Regras Comissão - Limpa Nome"
                                                onClick={() => this.props.history.push("/commission-rules-intervals")}
                                            />
                                        }
                                        {
                                            this.props.permissions.connectionLog.toView &&
                                            <Button
                                                icon="far fa-angle-right"
                                                classaditional="buttonProfileSubmenuHeader"
                                                name="Log de Conexões"
                                                onClick={() => this.props.history.push("/connection-logs")}
                                            />
                                        }
                                        {
                                            this.props.permissions.permissionGroups.toView &&
                                                <Button
                                                    icon="far fa-angle-right"
                                                    classaditional="buttonProfileSubmenuHeader"
                                                    name="Grupos de Permissão"
                                                    onClick={() => this.props.history.push(`/permissions`)}
                                                />
                                        }
                                        {
                                            this.props.permissions.ownersOnline.toView &&
                                            <Button
                                                icon="far fa-angle-right"
                                                classaditional="buttonProfileSubmenuHeader"
                                                name="Usuários Online"
                                                onClick={() => this.props.history.push(`/online-user`)}
                                            />
                                        }
                                    </DropDown>
                                }
                            </Anchor>
                            {
                                this.props.permissions.owners.toView &&
                                    <Anchor 
                                        icon="fas fa-users"
                                        classaditional="anchorProfileHeader"
                                        label="Usuários"
                                        onClick={() => this.props.history.push(`/owners`)}
                                    />
                            }
                            {
                                this.props.permissions.companyUnit.toView &&
                                    <Anchor 
                                        icon="fas fa-building"
                                        classaditional="anchorProfileHeader"
                                        label="Unidades"
                                        onClick={() => this.props.history.push(`/unit-company`)}
                                    />
                            }
                            {
                                this.props.permissions.serviceQueue.toView &&
                                <Anchor
                                    icon="fas fa-concierge-bell"
                                    classaditional="anchorProfileHeader"
                                    label="Fila de Atendimento (especialistas)"
                                    onClick={() => this.props.history.push("/service-queue")}
                                />
                            }
                            {
                                this.props.permissions.preSaleServiceQueue.toView &&
                                <Anchor
                                    icon="fas fa-concierge-bell"
                                    classaditional="anchorProfileHeader"
                                    label="Fila de Atendimento (vendedores)"
                                    onClick={() => this.props.history.push("/service-queue-pre-sale")}
                                />
                            }
                            {
                                this.props.permissions.campaign.toView &&
                                <Anchor
                                    icon="fas fa-ad"
                                    classaditional="anchorProfileHeader"
                                    label="Campanhas"
                                    onClick={() => this.props.history.push("/campaign")}
                                />
                            }
                            {
                                this.props.user.unit_companies.map(u => u.UNT_ID).includes(17) &&
                                <Anchor
                                    icon="far fa-arrow-to-bottom"
                                    classaditional="anchorProfileHeader"
                                    label="Importar Leads"
                                    style={{"pointerEvents": `${this.state.loading ? "none" : ""}`}}
                                    onClick={async () => {
                                        await this.setState({
                                            objectConfirmation: {
                                                amountSharedLeads: this.state.amountSharedLeads
                                            },
                                            nameFunctionYesModalConfirmation: "onGetSharedLeads"
                                        });
                                        this.getSharedLeads();
                                    }}
                                />
                            }
                            {
                                this.props.permissions.absenceAttendants.toView &&
                                <Anchor
                                    icon="fas fa-clipboard"
                                    classaditional="anchorProfileHeader"
                                    label="Faltas (vendedores)"
                                    onClick={() => this.props.history.push("/attendants-absence")}
                                />
                            }
                            <div className="divider"></div>
                            <Anchor 
                                icon="fal fa-sign-out"
                                classaditional="anchorProfileHeader"
                                label="Sair"
                                onClick={() => this.props.setUserLogout()}
                            />
                        </DropDown>
                    }          
                </div>
            </div>
        )
    } 
}

function mapStateToProps (state)
{
    const permissions = state.permissions;
    const {showModal, typeModal, modeUpdateModal, dataModal} = state.general;
    const { user } = state.auth;
    
    return {
        modeUpdateModal,
        permissions,
        showModal,
        typeModal,
        dataModal,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        setUserLogout ()
        {
            //action creator -> action
            const action = setUserLogout();
            dispatch(action);
        },
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));