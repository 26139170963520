import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class CommissionReportProspectionController
{
    async generate(idCommissionRuleProspection) 
    {
        const response = await api.commissionReportProspection().post(idCommissionRuleProspection);
        return apiCallbackTreatment(response);
    }

    async get(month, group)
    {
        const response = await api.commissionReportProspection().get(month, group);
        return apiCallbackTreatment(response);
    }

    async deleteById(id)
    {
        const response = await api.commissionReportProspection().remove(id);
        return apiCallbackTreatment(response);
    }

    async monthClosure(month) 
    {
        const response = await api.monthClosureProspection().post({
            MONTH: month
        });
        return apiCallbackTreatment(response);
    }
}