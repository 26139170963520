import { createStore, combineReducers, applyMiddleware } from "redux";

import activitiesReducer from "./reducers/activities";
import authReducer from "./reducers/auth";
import businessReducer from "./reducers/business";
import companyReducer from "./reducers/company";
import generalReducer from "./reducers/general";
import leadsReducer from "./reducers/leads";
import ownersReducer from "./reducers/owners";
import permissionsReducer from "./reducers/permissions";
import commissionReducer from "./reducers/commission";
import queueReducer from "./reducers/queue";
import campaignReducer from "./reducers/campaign";
import absenceReducer from "./reducers/absence";

import {
    getAllCompanyUnitsMiddleware,
} from "./middlewares/company";
import {
    getAllPermissionsMiddleware,
} from "./middlewares/permissions";
import {
    getAllUsersLeadsMiddleware,
} from "./middlewares/leads";
import {
    getAllUsersOwnersMiddleware,
} from "./middlewares/owners";
import {
    getAllCommissionRulesMiddleware,
    getAllCommissionReportMiddleware
} from "./middlewares/commission";

const reducers = combineReducers({
    activities: activitiesReducer,
    auth: authReducer,
    business: businessReducer,
    company: companyReducer,
    general: generalReducer,
    leads: leadsReducer,
    owners: ownersReducer,
    permissions: permissionsReducer,
    commission: commissionReducer,
    queue: queueReducer,
    campaign: campaignReducer,
    absence: absenceReducer,
});

function storeConfig() {
    return createStore(
        reducers,
        {},
        applyMiddleware(
            getAllCompanyUnitsMiddleware,
            getAllPermissionsMiddleware,
            getAllUsersLeadsMiddleware,
            getAllUsersOwnersMiddleware,
            getAllCommissionRulesMiddleware,
            getAllCommissionReportMiddleware
        )
    );
}

export default storeConfig;