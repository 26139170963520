import React, { Component } from "react";
import { connect } from "react-redux";
import "./TableEditCommissionReportAttendant.css";
import { withRouter } from "react-router-dom";
import { formatedMoneyBRAFromFloat, getWindowDimensions } from "../../auxiliary/generalFunctions";

const initialState = {
    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
}

class TableEditCommissionReportAttendant extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });
    }
    
    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT COMMISSION REPORT prevProps ", prevProps);
        // console.log("TABLE EDIT COMMISSION REPORT this.props ", this.props);
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    render ()
    {
        return (
            <div className="tableEditCommissionReportAttendant">
                <label className="helpTableEditCommissionReportAttendant" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditCommissionReportAttendant" ref={headerTable => {this.headerTable = headerTable}}>
                            <td
                                className="minimus140"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Atendente")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Atendente
                            </td>
                            <td
                                className="minimus75 center"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Negócios")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Negócios
                            </td>
                            <td
                                className="minimus75 center"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Agendou (Período)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Agendou (Período)
                            </td>
                            <td
                                className="minimus75 center"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Agendou (Outras Datas)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Agendou (Outras Datas)
                            </td>
                            <td
                                className="minimus100 right"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "(%) Agendamento")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                (%) Agendamento
                            </td>
                            <td
                                className="minimus75 center"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Agendou Para (Período)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Agendou Para (Período)
                            </td>
                            <td
                                className="minimus75 center"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Compareceu")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Compareceu
                            </td>
                            <td
                                className="minimus100 right"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "(%) Comparecimento")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                (%) Comparecimento
                            </td>
                            <td
                                className="minimus100 right"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "(R$) Unitário")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                (R$) Unitário
                            </td>
                            <td
                                className="minimus100 right"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "(R$) Comissão")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                (R$) Comissão
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.data.map((e, i) => {

                                return (
                                    <tr 
                                        key={`${i}`} 
                                        className={`tbodyTableEditCommissionReportAttendant`}
                                    >
                                        <td className="left">{e.name}</td>
                                        <td className="center">{e.amountBusiness}</td>
                                        <td className="center">{e.amountScheduledSamePeriod}</td>
                                        <td className="center">{e.amountScheduledAnotherPeriod}</td>
                                        <td className="right">{e.percentScheduled}%</td>
                                        <td className="center">{e.amountScheduledTo}</td>
                                        <td className="center">{e.amountShowedup}</td>
                                        <td className="right">{e.percentShowedup}%</td>
                                        <td className="right">{formatedMoneyBRAFromFloat(e.amountCommissionUnitary)}</td>
                                        <td className="right">{formatedMoneyBRAFromFloat(e.amountCommissionTotal)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default withRouter(connect(null, null)(TableEditCommissionReportAttendant));