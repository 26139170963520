import { 
    GET_ALL_USERS_LEADS,
    CHANGE_USER_LEADS,
    TOGGLE_CHECKED_ALL_USERS_LEADS,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_LEADS
} from "../../core/constants";

const initialState = {
    allUsers: [],
    getAllLeadsFromDatabaseCompleted: true,
}

export default function leadsReducer (state = initialState, action)
{
    switch (action.type) {

        case GET_ALL_USERS_LEADS:
            let allUsers = action.payload;
            return {
                ...state,
                allUsers,
                getAllLeadsFromDatabaseCompleted: true
            }

        case CHANGE_USER_LEADS:
            let {name, phone, from, date, owners, checked, stamp, propertyName, id, companyUnit} = action.payload;
            let allUsersFinal = state.allUsers.map(d => {
               
                if (d.id === parseInt(id))
                {
                    switch (propertyName) {
                        case "name":
                            d.name = name;
                            break;
                        case "phone":
                            d.phone = phone;
                            break;
                        case "from":
                            d.from = from;
                            break;
                        case "date":
                            d.date = date;
                            break;
                        case "owners":
                            d.owners = [];
                            d.owners = owners;
                            break;
                        case "checked":
                            d.checked = checked;
                            break;
                        case "stamp":
                            d.stamp = {}
                            d.stamp = stamp;
                            break;
                        case "companyUnit":
                            d.companyUnit = {}
                            d.companyUnit = companyUnit;
                            break;
                        default: 
                    }                        
                }
                return d;
            });

            return {
                ...state,
                allUsers: allUsersFinal
            }

        case TOGGLE_CHECKED_ALL_USERS_LEADS:
            let status = action.payload;
            let allUsersFinalChecked = state.allUsers.map(d => {
                d.checked = status;
                return d;
            });

            return {
                ...state,
                allUsers: allUsersFinalChecked
            }

        case CHANGE_STATUS_GET_FROM_DATABASE_ALL_LEADS:
                
            return {
                ...state,
                getAllLeadsFromDatabaseCompleted: action.payload
            }
    
        default:
            return state
    }
}