import {
    GET_ALL_COMMISSION_RULE,
    NEW_COMMISSION_RULE,
    NEW_REPORT_COMMISSION_RULE,
    CHANGE_COMMISSION_RULE,
    CHANGE_STATUS_INSERTED_REPORT_COMMISSION_RULE,
    TOGGLE_CHECKED_ALL_COMMISSION_RULE,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_RULE,
    DELETE_REPORT_COMMISSION_RULE,
    CHANGE_STATUS_DELETED_REPORT_COMMISSION_RULE,
    GET_ALL_COMMISSION_REPORT,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_REPORT,
    GET_ALL_COMMISSION_REPORT_INTERVALS,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_REPORT_INTERVALS,
} from "../../core/constants";

const initialState = {

    allCommissionRules: [
        // RESERVED
        // {
        //     id: 1,
        //     checked: false,
        //     unit: "Unidade de Campo Grande (MS)",
        //     month: "2022-01",
        //     utilizationCommission: 2.0,
        //     receiptCommission: 2.0,
        //     utilizationGoal: 70,
        //     receivingGoal: 20,
        //     lossGoal: 20,
        //     performanceBonus:  2.00,
        //     receivingBonus: 2.00,
        //     usageCeiling: 80,
        //     receiptCeiling: 30,
        //     monthsPortfolio: 3,
        //     target: 1
        // },
        // {
        //     id: 2,
        //     checked: false,
        //     unit: "Unidade de Campo Grande (MS)",
        //     month: "2022-01",
        //     utilizationCommission: 2.0,
        //     receiptCommission: 2.0,
        //     utilizationGoal: 70,
        //     receivingGoal: 20,
        //     lossGoal: 20,
        //     performanceBonus:  2.00,
        //     receivingBonus: 2.00,
        //     usageCeiling: 80,
        //     receiptCeiling: 30,
        //     monthsPortfolio: 3,
        //     target: 2
        // },
    ],
    allCommissionReport: [
        // RESERVED
        // {
        //     id: 1,
        //     title: "título 1",
        //     company: {
        //         id: 1,
        //         name: "Empresa 1"
        //     }
        // },
        // {
        //     id: 2,
        //     title: "título 2",
        //     company: {
        //         id: 2,
        //         name: "Empresa 2"
        //     }
        // },
    ],
    getAllCommissionRulesFromDatabaseCompleted: true,
    idCommissionRuleForReport: null,
    idCommissionRuleForReportUpdated: true,
    idCommissionRuleForDeleteReport: null,
    idCommissionRuleForDeleteReportUpdated: true,
    getAllCommissionReportFromDatabaseCompleted: true,
    getAllCommissionReportIntervalsFromDatabaseCompleted: true,
}

export default function commissionReducer (state = initialState, action)
{
    switch (action.type) {
        case GET_ALL_COMMISSION_RULE:
            
            return {
                ...state,
                allCommissionRules: action.payload,
                getAllCommissionRulesFromDatabaseCompleted: true,
            }

        case NEW_COMMISSION_RULE:
            
            let commissionRules = state.allCommissionRules;
            commissionRules.push(action.payload);
            return {
                ...state,
                allCommissionRules: commissionRules
            }

        case NEW_REPORT_COMMISSION_RULE:

            return {
                ...state,
                idCommissionRuleForReport: action.payload,
                idCommissionRuleForReportUpdated: false
            }

        case DELETE_REPORT_COMMISSION_RULE:

            return {
                ...state,
                idCommissionRuleForDeleteReport: action.payload,
                idCommissionRuleForDeleteReportUpdated: false
            }

        case CHANGE_COMMISSION_RULE:
            
            let {
                id, 
                checked, 
                unit, 
                    month, 
                    utilizationCommission, 
                    receiptCommission, 
                    utilizationGoal, 
                    receivingGoal,
                    lossGoal,
                    performanceBonus,
                    receivingBonus,
                    usageCeiling,
                    receiptCeiling,
                    lossCeiling,
                    monthsPortfolio,
                    target,
                    propertyName,
                    status,
                } = action.payload;

            let allCommissionRulesFinal = state.allCommissionRules.map(d => {
                
                if (d.id === parseInt(id))
                {
                    switch (propertyName) {
                        case "checked":
                            d.checked = checked;
                            break;

                        case "status":
                            d.status = status;
                            break;

                        case "unit":
                            d.unit = unit;
                            break;

                        case "month":
                            d.month = month;
                            break;

                        case "utilizationCommission":
                            d.utilizationCommission = utilizationCommission;
                            break;

                        case "receiptCommission":
                            d.receiptCommission = receiptCommission;
                            break;

                        case "utilizationGoal":
                            d.utilizationGoal = utilizationGoal;
                            break;

                        case "receivingGoal":
                            d.receivingGoal = receivingGoal;
                            break;

                        case "lossGoal":
                            d.lossGoal = lossGoal;
                            break;

                        case "performanceBonus":
                            d.performanceBonus = performanceBonus;
                            break;

                        case "receivingBonus":
                            d.receivingBonus = receivingBonus;
                            break;

                        case "usageCeiling":
                            d.usageCeiling = usageCeiling;
                            break;

                        case "receiptCeiling":
                            d.receiptCeiling = receiptCeiling;
                            break;

                        case "lossCeiling":
                            d.lossCeiling = lossCeiling;
                            break;

                        case "monthsPortfolio":
                            d.monthsPortfolio = monthsPortfolio;
                            break;

                        case "target":
                            d.target = target;
                            break;

                        default: 
                            break;
                    }                        
                }
                return d;
            });

            return {
                ...state,
                allCommissionRules: allCommissionRulesFinal
            }
    
        case TOGGLE_CHECKED_ALL_COMMISSION_RULE:

            let checkedToggle = action.payload;
            let allCommissionRulesFinalChecked = state.allCommissionRules.map(d => {
                d.checked = checkedToggle;
                return d;
            });

            return {
                ...state,
                allCommissionRules: allCommissionRulesFinalChecked
            }

        case CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_RULE:
            
            return {
                ...state,
                getAllCommissionRulesFromDatabaseCompleted: action.payload
            }
            
        case CHANGE_STATUS_INSERTED_REPORT_COMMISSION_RULE:
            
            return {
                ...state,
                idCommissionRuleForReport: null,
                idCommissionRuleForReportUpdated: action.payload
            }

        case CHANGE_STATUS_DELETED_REPORT_COMMISSION_RULE:
            
            return {
                ...state,
                idCommissionRuleForDeleteReport: null,
                idCommissionRuleForDeleteReportUpdated: action.payload
            }

        case CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_REPORT:
        
            return {
                ...state,
                getAllCommissionReportFromDatabaseCompleted: action.payload
            }

        case GET_ALL_COMMISSION_REPORT:
            
            return {
                ...state,
                allCommissionReport: action.payload,
                getAllCommissionReportFromDatabaseCompleted: true,
            }

        case GET_ALL_COMMISSION_REPORT_INTERVALS:

            return {
                ...state,
                allCommissionReportIntervals: action.payload,
                getAllCommissionReportIntervalsFromDatabaseCompleted: true,
            }

        case CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_REPORT_INTERVALS:

            return {
                ...state,
                getAllCommissionReportIntervalsFromDatabaseCompleted: action.payload
            }

        default:
            return state
    }
}