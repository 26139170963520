import React, { Component } from "react";  
import "./CommissionReportForm.css";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showModalMoreActions }  from "../../store/actions/general";

import Button from "../general/Button";
import InputGeneral from "../general/InputGeneral";
import { formatedMoneyBRA, formatedMoneyBRAFromFloat } from "../../auxiliary/generalFunctions";
import { getAllCommissionReport } from "../../store/actions/commission";
import CommissionReportController from "../../controllers/CommissionReportController";

const initialState = {
    id: 0, 
    name: "",
    amountTotal: 0,
    amountCommission: 0,
    validAmountCommission: true,
    percentCommission: 0,
    validPercentCommission: true,
    disabledButton: false
}

const commissionReportController = new CommissionReportController();

class CommissionReportForm extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        let {id, name, percentCommission, amountCommission, amountTotal} = this.props.data;
        await this.setState({id, name, percentCommission, amountCommission, amountTotal});
    }

    componentDidUpdate = async (prevProps) =>
    {
        // console.log("BUSINESS BY CLIENT prevProps ", prevProps);
        // console.log("BUSINESS BY CLIENT this.props ", this.props);

    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        }
        this.props.showModalMoreActions(data);
    }

    onActionForm = async () =>
    {
        let percent = this.state.percentCommission;
        let amount = this.state.amountCommission;
        
        if (percent.toString().trim() === "") {
            return this.setState({validPercentCommission: false});
        }

        if (amount.toString().trim() === "") {
            return this.setState({validAmountCommission: false});
        }

        await this.setState({ disabledButton: true });

        await commissionReportController.update(this.state.id, {
            COP_COMMISSION_PERCENT_TOTAL_COMMISSION_TO_SELLER: percent.toString().replaceAll(".", "").replace(",", "."),
            COP_COMMISSION_AMOUNT_TOTAL_GLOBAL_TO_SELLER: amount.toString().replaceAll(".", "").replace(",", "."),
            USER_ID: this.props.user.PER_ID
        });

        
        this.props.data.onSaveCommission();

        this.closeModalMoreActions();
        await this.setState({ disabledButton: false });
    }

    render () 
    {
        return (
            <div className="commissionReportForm"> 
                <div className="row no-gutters lineCommissionReportForm">
                    <div className="col-12 columnCommissionReportForm">
                        <InputGeneral
                            classaditional="fieldCommissionReportForm disabled"
                            label="Nome"
                            value={this.state.name}
                            onChange={e => {}}
                            mandatory="true"
                            disabled
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionReportForm">
                    <div className="col-12 columnCommissionReportForm">
                        <InputGeneral
                            classaditional="fieldCommissionReportForm disabled"
                            label="Total Recebido"
                            value={formatedMoneyBRAFromFloat(this.state.amountTotal)}
                            onChange={e => {}}
                            mandatory="true"
                            disabled
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionReportForm">
                    <div className="col-12 columnCommissionReportForm">
                        <InputGeneral
                            label="Comissão (%)"
                            value={formatedMoneyBRAFromFloat(this.state.percentCommission)}
                            onChange={async e => {
                                !this.state.validPercentCommission && this.setState({ validAmountCommission: true });

                                let percent = parseFloat(e.target.value.toString().replaceAll(".", "").replace(",", "")) / 100;
                                let amountCommission = formatedMoneyBRA((this.state.amountTotal * (parseFloat(percent)/100)).toFixed(2));
                                await this.setState({amountCommission, percentCommission: formatedMoneyBRA(percent.toFixed(2).toString())})
                            }}
                            maxLength={5}
                            mandatory="true"
                            valid={this.state.validPercentCommission.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionReportForm">
                    <div className="col-12 columnCommissionReportForm">
                        <InputGeneral
                            label="Comissão (R$)"
                            value={formatedMoneyBRAFromFloat(this.state.amountCommission)}
                            onChange={async e => {
                                !this.state.validAmountCommission && this.setState({ validAmountCommission: true });

                                let amount = parseFloat(e.target.value.toString().replaceAll(".", "").replace(",", "")) / 100;
                                let percentCommission = formatedMoneyBRA(((amount / this.state.amountTotal) * 100).toFixed(2));
                                await this.setState({amountCommission: formatedMoneyBRA(amount.toFixed(2).toString()), percentCommission})
                            }}
                            mandatory="true"
                            valid={this.state.validAmountCommission.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionReportForm">
                    <div className="col-12 columnCommissionReportForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterCommissionReportForm">
                    <Button
                        classaditional="buttonCommissionReportForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions()}
                    />
                    <Button
                        classaditional="buttonCommissionReportForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                        disabled={this.state.disabledButton}
                    />                  
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;
    return {
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        },
        getAllCommissionReport ()
        {
            //action creator -> action
            const action = getAllCommissionReport();
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommissionReportForm));