import React, { useState } from "react";
import "./CardResumeWithHover.css";

export default function CardResumeWithHover (props)
{
    const [over, setOver] = useState(false);

    return (
        <div 
            className="cardResumeWithHover"
            onClick={props.onClick}
            onMouseOver={() => props.hoverContent && setOver(true)}
            onMouseLeave={() => props.hoverContent && setOver(false)}
            style={{
                "background": `${props.colorBackground ? props.colorBackground : ""}`,
                "width": `${props.width ? props.width+"px" : ""}`
            }}
        >
            {
                !over &&
                (
                    <div>
                        <div className="headCardResumeWithHover">
                            {
                                props.icon &&
                                <i className={`iconCardResumeWithHover ${props.icon}`}></i>
                            }
                            <h6 className="titleCardResumeWithRouter" style={{"color": `${props.colorTitle ? props.colorTitle : ""}`}}>{props.title}</h6>
                        </div>
                        <div className="bodyCardResumeWithHover">
                            <div className={`buttonCardResumeWithRouter ${props?.backgroundClick}`}>
                                <span className="labelButtonCardResumeWithRouter">{props.labelClick}</span>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                props.hoverContent && over &&
                (
                    <div className="hoverContentBodyCardResumeWithRouter">
                        {props.hoverContent}
                    </div>
                )
            }
        </div>
    )
}