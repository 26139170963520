import React from "react"; 
import "./MessageContent.css";
import Modal from 'react-bootstrap/Modal'
import { FaCheck } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5';
import { AiOutlineClose } from 'react-icons/ai';
import { FiAlertTriangle, FiAlertCircle } from 'react-icons/fi';

export default function MessageContent (props)
{
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="messageContent"
      >
        <div className="content">
            <div className="header">
                {
                    props?.message_type === "success" &&
                    <span className="status success"><FaCheck className="iconStatus" /> {props?.message_title ?  props.message_title : ""}</span>
                }
                {
                    props?.message_type === "error" &&
                    <span className="status error"><IoCloseSharp className="iconStatus"/> {props?.message_title ?  props.message_title : ""}</span>
                }
                {
                    props?.message_type === "warning" &&
                    <span className="status warning"><FiAlertTriangle className="iconStatus"/> {props?.message_title ?  props.message_title : ""}</span>
                }
                {
                    props?.message_type === "information" &&
                    <span className="status information"><FiAlertCircle className="iconStatus"/> {props?.message_title ?  props.message_title : ""}</span>
                }
                
                <button className="close" onClick={() => props.onClose()}><AiOutlineClose className="iconButton" /></button>
            </div> 
            <div className="body">
                <div className="row no-gutters w-100 lineBodyMessageContent">
                    <div className="col-12">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
      </Modal>
    );
}
  