import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class ActivityController
{
    async create(data) 
    {
        const response = await api.activity().post(data);
        return apiCallbackTreatment(response);
    }

    async deleteById(id)
    {
        const response = await api.activity().remove(id);
        return apiCallbackTreatment(response);
    }

    async get(userId) 
    {
        const response = await api.activity().get(userId);
        return apiCallbackTreatment(response);
    }

    async getByFilters(filters = null) 
    {
        const response = await api.activity().getByFilters(filters);
        return apiCallbackTreatment(response);
    }

    async getByBusiness(userId, businessId) 
    {
        const response = await api.activity().getByBusiness(userId, businessId);
        return apiCallbackTreatment(response);
    }

    async update(id, data) 
    {
        const response = await api.activity().update(id, data);
        return apiCallbackTreatment(response);
    }

    async setArchivedFile(idFileActivity, finalStatus) 
    {
        const response = await api.activity().setArchivedFile(idFileActivity, finalStatus);
        return apiCallbackTreatment(response);
    }
}