import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditLeads.css";
import { dateDiff, formatedOnlyNumber, formatedPhoneAndCellphone } from "../../auxiliary/generalFunctions";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";
import Check from "./Check";
import Select from "./Select";
import StampController from "../../controllers/StampController";
import UserController from "../../controllers/UserController";

const initialState = {
    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
    optionsStampsLead: [],
    optionChosenStampsLead: {},
    optionsCompanyUnit: [],
    optionChosenCompanyUnit: {},
}

const stampController = new StampController();
const userController = new UserController();

class TableEditLeads extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.getCompanyUnit();
        this.getStamps();
        this.setState({ headerTable });
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT LEADS prevProps ", prevProps);
        // console.log("TABLE EDIT LEADS this.props ", this.props);
        
        // if (this.state.updateTableEditLeads)
        // {
        //     let allUsers = await this.props.data;
        //     await this.setState({updateTableEditLeads: false, allUsers});
        //     await this.hasAllUsersChecked();
        // }

        // if (this.state.allUsers.length !== this.props.data.length)
        // {
        //     await this.setState({ allUsers: this.props.data });
        // }
        
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    createAt = (date) =>
    {
        let diff = dateDiff.inDays(new Date(date), new Date());

        if (diff <= 0)
        {
            diff = " hoje ";
        }
        else
        {
            diff = (diff === 1) ? diff+" dia " : diff+" dias ";
        }
        return diff;
    }

    getStamps = async() => 
    {
        const result = await stampController.get();
        if (result.status) 
        {
            let stamps = [];
            for (const stamp of result.data.data) 
            {
                stamps.push({
                    name: stamp.STA_NAME,
                    value: stamp.STA_ID,
                    style: stamp.STA_STYLE
                });

            }
            this.setState({ optionsStampsLead: stamps });
        }  
    }

    getCompanyUnit = async () => 
    {
        const result = await userController.getById(this.props.user.PER_ID);
        let units = [];
        for (const unit of result.data.unit_companies) 
        {
            units.push({
                id: unit.UNT_ID,
                name: unit.UNT_NAME,
                value: unit.UNT_ID
            });
        }
        this.setState({ optionsCompanyUnit: units });
    }

    render ()
    {
        return (
            <div className="tableEditLeads">
                <label className="helpTableEditLeads" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditLeads" ref={headerTable => {this.headerTable = headerTable}}>
                            <td className="checkTableEditLeads">
                                <Check
                                    classaditional={`buttonCheckTableEditLeads ${(this.props.allUsersChecked) ? "active" : ""}`}
                                    checked={this.props.allUsersChecked}
                                    onClick={() => this.props.onToggleCheckedAllUsersLeads(!this.props.allUsersChecked)}
                                />
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Cliente")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Cliente
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Telefone")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Telefone
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Etiqueta")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Etiqueta
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Unidade")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Unidade
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Fonte")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Fonte
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Criado há")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Criado há
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.data.map((e, i) => {

                                return (
                                    <tr key={`${i}`} className="tbodyTableEditLeads">
                                        <td className="checkTableEditLeads">
                                            <Check
                                                classaditional={`buttonCheckTableEditLeads ${(e.checked) ? "active" : ""}`}
                                                checked={e.checked}
                                                onClick={() => {
                                                    this.props.onChangeValue(!e.checked, e.id, "checked");
                                                    this.props.hasAllUsersChecked();
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditLeads 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.name}
                                                placeholder={e.name}
                                                name="Cliente"
                                                onChange={value => this.props.onChangeValue(value, e.id, "name")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "PER_NAME", e.name)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions}
                                            />
                                        </td>                                    
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditLeads 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPhoneAndCellphone(e.phone)}
                                                placeholder={formatedPhoneAndCellphone(e.phone)}
                                                name="Cliente"
                                                onChange={value => this.props.onChangeValue(formatedPhoneAndCellphone(value), e.id, "phone")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "PER_PHONE_ONE", formatedOnlyNumber(e.phone))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={14}
                                                permissions={this.props.permissions}
                                            />
                                        </td>                                    
                                        <td>
                                            <span 
                                                className="stampEditLeads" 
                                                style={{backgroundColor: `var(--color-${e.stamp.style})`}}
                                            >
                                                {e.stamp.name}
                                            </span>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditLeads 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Etiqueta"
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "PER_STAMP", e.stamp.value)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                optionsSelectMulti
                                                permissions={this.props.permissions}
                                            >
                                                    <Select
                                                        classaditional="selectStampsBusinessForm w-100"
                                                        default={e.stamp}
                                                        options={this.state.optionsStampsLead} 
                                                        onChange={(optionChosenStampsLead) => {
                                                            this.props.onChangeValue(optionChosenStampsLead, e.id, "stamp")
                                                        }}
                                                    />
                                            </FieldEdit>                                   
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditLeads 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Unidade"
                                                placeholder={e.companyUnit.name}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "PER_ID_COMPANY_UNIT", e.companyUnit.value)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                optionsSelectMulti
                                                permissions={this.props.permissions}
                                            >
                                                    <Select
                                                        classaditional="selectStampsBusinessForm w-100"
                                                        default={e.companyUnit}
                                                        options={this.state.optionsCompanyUnit} 
                                                        onChange={(optionChosenCompanyUnit) => {
                                                            this.props.onChangeValue(optionChosenCompanyUnit, e.id, "companyUnit")
                                                        }}
                                                    />
                                            </FieldEdit>                                   
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditLeads noEdit"
                                                value={e.from}
                                                placeholder={e.from}
                                                name="Fonte"
                                                onChange={value => this.props.onChangeValue(value, e.id, "from")}
                                                onPositiveClick={() => this.props.onSaveValue()}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions}
                                            />                                    
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditLeads noEdit"
                                                value={e.date}
                                                placeholder={`${this.createAt(e.date)}`}
                                                type="date"
                                                name="Criado há"
                                                onChange={value => {}}
                                                onPositiveClick={() => {}}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions}
                                            />    
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;

    return {
        user
    }
}

export default connect(mapStateToProps, null)(TableEditLeads);