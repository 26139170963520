import { 
    GET_ALL_SERVICE_QUEUE,
    SET_LAST_POSITION_SERVICE_QUEUE,
    CHANGE_SERVICE_QUEUE,
    CHANGE_STATUS_ALL_SPECIALISTS_UPDATED_SERVICE_QUEUE,
    CHANGE_STATUS_LAST_POSITION_SPECIALIST_UPDATED_SERVICE_QUEUE,
    SET_FROZEN_SERVICE_QUEUE,
    CHANGE_STATUS_FROZEN_SPECIALIST_UPDATED_SERVICE_QUEUE
} from "../../core/constants";

const initialState = {
    allSpecialistsNotFiltered: [], //WARNING: more in the component ServiceQueue.jsx
    allSpecialistsNotFilteredHasUpdated: false,
    idSpecialistForLastPosition: null,
    idSpecialistForLastPositionUpdated: true,
    idSpecialistForFrozen: null,
    idSpecialistForFrozenUpdated: true,
}

export default function queueReducer (state = initialState, action)
{
    switch (action.type) {

        case GET_ALL_SERVICE_QUEUE:
            return {
                ...state
            }

        case CHANGE_SERVICE_QUEUE:
            return {
                ...state,
                allSpecialistsNotFilteredHasUpdated: true,
                allSpecialistsNotFiltered: action.payload
            }

        case CHANGE_STATUS_ALL_SPECIALISTS_UPDATED_SERVICE_QUEUE:
            return {
                ...state,
                allSpecialistsNotFilteredHasUpdated: action.payload,
            }
            
        case SET_LAST_POSITION_SERVICE_QUEUE:
            return {
                ...state,
                idSpecialistForLastPositionUpdated: false,
                idSpecialistForLastPosition: action.payload
            }

        case CHANGE_STATUS_LAST_POSITION_SPECIALIST_UPDATED_SERVICE_QUEUE:
            return {
                ...state,
                idSpecialistForLastPositionUpdated: action.payload
            }
        
        case SET_FROZEN_SERVICE_QUEUE:
            return {
                ...state,
                idSpecialistForFrozenUpdated: false,
                idSpecialistForFrozen: action.payload
            }

        case CHANGE_STATUS_FROZEN_SPECIALIST_UPDATED_SERVICE_QUEUE:
            return {
                ...state,
                idSpecialistForFrozenUpdated: action.payload
            }

        default:
            return state
    }
}