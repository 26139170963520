import React, { Component } from "react";
import "./Leads.css";
import Header from "../../components/CRM/Header";
import { connect } from "react-redux";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import TableEditLeads from "../../components/general/TableEditLeads";
import SelectMulti from "../../components/general/SelectMulti";
import { APPLICATION_NAME, person } from "../../core/constants";
import { showModalMoreActions } from "../../store/actions/general";
import Message from "../../components/general/Message";
import { changeStatusGetFromDatabaseCompletedLeads, changeUserLeads, getAllUsersLeads, toggleCheckedAllUsersLeads } from "../../store/actions/leads";
import ClientController from "../../controllers/ClientController";
import MidiaOriginController from "../../controllers/MidiaOriginController";
import UserController from "../../controllers/UserController";
import { getOptionsSelectCheckedFormated } from "../../auxiliary/generalFunctions";
import { withRouter } from "react-router-dom";

const initialState = {
    allUsers: [],
    allUsersChecked: false, //false as default
    hasOneUsersChecked: false,
    allLeadsUpdated: false,
    allUsersInitialLoaded: false,
    user: {},
    
    filtered: false,
    optionsSelectFrom : [],

    // start Message Modal
    message_type: "information",
    message_show: false,
    message: "",
    // ends Message Modal

    optionsUnitCompany: [],
}

const clientController = new ClientController();
const midiaOriginController = new MidiaOriginController();
const userController = new UserController();

class Leads extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Clientes`;
        await this.props.getAllUsersLeads();
        await this.getCompanyUnit();
        await this.getOptionsSelectFrom();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("LEADS prevProps ", prevProps);
        // console.log("LEADS this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}

        if (this.state.user !== this.props.user)
        {
            this.setState({user: this.props.user});
        }

        if ((this.state.allUsers.length === 0 && this.props.allUsers.length > 0) && !this.state.allUsersInitialLoaded)
        {
            let allUsers = await this.applyFilters(this.props.allUsers);
            await this.hasAllUsersChecked();
            await this.setState({ allUsers, allUsersInitialLoaded: true });
        }     

        if (this.state.allLeadsUpdated)
        {            
            let allUsers = await this.applyFilters(this.props.allUsers);
            await this.hasAllUsersChecked();
            await this.setState({ allUsers, allLeadsUpdated: false });
        }  

        if (prevProps.getAllLeadsFromDatabaseCompleted !== undefined)
        {
            if (!prevProps.getAllLeadsFromDatabaseCompleted && this.props.getAllLeadsFromDatabaseCompleted)
            {
                await this.setState({allLeadsUpdated: true});
            }
        }

    }

    getCompanyUnit = async () => 
    {
        const result = await userController.getById(this.props.user.PER_ID);
        let units = [];
        for (const unit of result.data.unit_companies) 
        {
            units.push({
                name: unit.UNT_NAME,
                value: unit.UNT_ID,
                checked: false
            });
        }
        this.setState({ optionsUnitCompany: units });
    }

    getOptionsSelectFrom = async () => 
    {
        const result = await midiaOriginController.get();
        let options = [];
        if (result.status) {
            for (const midiaOrigin of result.data.data) {
                options.push({
                    id: midiaOrigin.MID_ID,
                    name: midiaOrigin.MID_NAME,
                    value: midiaOrigin.MID_NAME,
                    checked: false
                });
            }
        }

        this.setState({ optionsSelectFrom: options });
    }

    hasAllUsersChecked = async () =>
    {
        let allUsers = await this.state.allUsers;
        let allUsersChecked = true;
        let hasOneUsersChecked = false;

        if (allUsers.length > 0)
        {
            allUsers.forEach(d => {
                if (!d.checked) allUsersChecked = false;
                if (d.checked) hasOneUsersChecked = true;
            });
        }
        else
        {
            allUsersChecked = false;
        }
        await this.setState({allUsersChecked, hasOneUsersChecked});
    }

    updateLeads = async () =>
    {
        const leads = this.props.allUsers;
        const checkeds = leads.filter(lead => lead.checked);
        const filter = this.state.filtered;

        const newStatus = filter ? 1 : 2;
        const action = filter ? "desarquivado(s)" : "arquivado(s)";

        for (const lead of checkeds)
        {
            await clientController.update(lead.id, {
                PER_STATUS: newStatus
            });
        }

        await this.setState({ hasOneUsersChecked: false, allUsersChecked: false });

        await this.props.getAllUsersLeads();
        setTimeout(async () => {
            await this.setState({allLeadsUpdated: true});
        }, 1000);

        this.message("success", `Cliente(s) ${action} com sucesso!`);
    }

    applyFilters = async (allUsers) =>
    {
        const optionsCompanyUnits = this.state.optionsUnitCompany.filter(o => o.checked).map(o => o.value);
        const optionsMidiaOrigin = this.state.optionsSelectFrom.filter(o => o.checked).map(o => o.value);
        const archived = this.state.filtered;

        allUsers = allUsers.filter(l => {

            let allowed = true;

            const companyUnitId = l.companyUnit.id;
            const midiaOrigin = l.from;

            if (optionsCompanyUnits.length > 0 && optionsCompanyUnits.includes(companyUnitId) === false)
            {
                allowed = false;
            }

            if (optionsMidiaOrigin.length > 0 && optionsMidiaOrigin.includes(midiaOrigin) === false)
            {
                allowed = false;
            }

            if (l.archived !== archived)
            {
                allowed = false;
            }

            if (allowed)
            {
                return l;
            }

            return false;


        });

        return allUsers;
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
            await this.props.changeUserLeads({[propertyName]: newValue, id, propertyName});
        }
    }

    saveValue = async (id, field, value) => 
    {
        const result = await clientController.update(id, {
            [field]: value
        });
    
        if (!result.status)
        {
            await this.props.changeStatusGetFromDatabaseCompletedLeads(false);
            await this.props.getAllUsersLeads();
            return this.message("error", result.message);
        }

        await this.props.getAllUsersLeads();
        await this.setState({allLeadsUpdated: true});
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render ()
    {
        return (
            <div className="leads">
                <Header title="Clientes" classaditional="headerLeads"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="warningLeads d-flex d-md-none">
                    <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                </div>
                <div className="containerNavigationHeaderLeads">
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderLeads ${!this.state.filtered ? "active" : ""}`}
                        onClick={async () => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: false});
                            await this.props.getAllUsersLeads();
                            await this.setState({allLeadsUpdated: true});
                        }}
                    />
                    <ButtonIcon
                        icon="fas fa-archive"
                        classaditional={`buttonNavigationHeaderLeads ${this.state.filtered ? "active" : ""}`}
                        onClick={async () => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: true});
                            await this.props.getAllUsersLeads();
                            await this.setState({allLeadsUpdated: true});
                        }}
                    />
                    {
                        (!this.state.hasOneUsersChecked && this.props.permissions.toInsert) &&
                        <Button
                            icon="fal fa-plus"
                            name="&nbsp;&nbsp;Cliente"
                            classaditional="buttonPlusNavigationHeaderLeads"
                            onClick={() => {
                                let data = {
                                    show: true,
                                    type: person
                                }
                                this.props.showModalMoreActions(data);
                            }}
                        />
                    }
                    {
                        (this.state.hasOneUsersChecked && this.props.permissions.toDelete) &&
                            <ButtonIcon
                                icon="fas fa-trash"
                                classaditional="buttonTrashNavigationHeaderLeads"
                                onClick={() => this.message("information", "nothing...")}
                            />
                    }
                    {
                        (this.state.hasOneUsersChecked && this.props.permissions.toFile) &&
                            <Button
                                icon="fas fa-archive"
                                name={ `\u00A0\u00A0${this.state.filtered ? "Desarquivar" : "Arquivar"}` }
                                classaditional="buttonPlusNavigationHeaderLeads"
                                onClick={() => this.updateLeads()}
                            />
                    }
                    <div className="filterFontsHeaderLeads d-none d-lg-flex ml-2 ml-auto">
                        <i className="fas fa-building iconFilterFontsHeaderLeads"></i>
                        <SelectMulti
                            classaditional="selectFontHeaderLeads"
                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsUnitCompany, "[Todas Unidades]", "unidades")}}
                            options={this.state.optionsUnitCompany} 
                            onChange={(optionsUnitCompany) => {
                                this.setState({optionsUnitCompany});
                                this.setState({allLeadsUpdated: true});
                            }}
                            withFieldSearch
                        />
                    </div>
                    <div className="filterFontsHeaderLeads d-none d-lg-flex ml-2">
                        <i className="fas fa-tag iconFilterFontsHeaderLeads"></i>
                        <SelectMulti
                            classaditional="selectFontHeaderLeads "
                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsSelectFrom, "[Todas Fontes]", "fontes")}}
                            options={this.state.optionsSelectFrom} 
                            onChange={(optionsSelectFrom) => {
                                this.setState({optionsSelectFrom});
                                this.setState({allLeadsUpdated: true});
                            }}
                        />

                    </div>
                </div>
                <div className="containerListHeaderLeads">
                    <TableEditLeads
                        data={this.state.allUsers}
                        allUsersChecked={this.state.allUsersChecked}
                        onToggleCheckedAllUsersLeads={async (status) => {
                            await this.props.toggleCheckedAllUsersLeads(status);
                            await this.hasAllUsersChecked();
                        }}
                        onChangeValue={this.changeValue}
                        onSaveValue={this.saveValue}
                        onIgnoreSave={async () => {
                            await this.props.changeStatusGetFromDatabaseCompletedLeads(false);
                            await this.props.getAllUsersLeads();
                        }}
                        permissions={this.props.permissions}
                        hasAllUsersChecked={this.hasAllUsersChecked}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { allUsers, getAllLeadsFromDatabaseCompleted } = state.leads;
    const permissions = state.permissions.leads;
    const { user } = state.auth;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        hasPermissionsLoaded,
        allUsers,
        getAllLeadsFromDatabaseCompleted,
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        getAllUsersLeads ()
        {
            //action creator -> action
            const action = getAllUsersLeads();
            dispatch(action);
        },
        changeUserLeads (data)
        {
            //action creator -> action
            const action = changeUserLeads(data);
            dispatch(action);
        },
        changeStatusGetFromDatabaseCompletedLeads (data)
        {
            //action creator -> action
            const action = changeStatusGetFromDatabaseCompletedLeads(data);
            dispatch(action);
        },
        toggleCheckedAllUsersLeads (status) 
        {
            //action creator -> action
            const action = toggleCheckedAllUsersLeads(status);
            dispatch(action);
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Leads));