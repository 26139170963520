import React, { Component } from "react";
import "./Development.css";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { APPLICATION_NAME } from "../../core/constants";
import { setModalMessage } from "../../store/actions/general";

import Header from "../../components/CRM/Header";
import Box from "../../components/general/Box";
import Button from "../../components/general/Button";
import axios from "axios";
// import WhatsAppController from "../../controllers/WhatsAppController";

const initialState = {
    variable: "",
}

// const whatsAppController = new WhatsAppController();
class Development extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Área de Desenvolvimento`;
    }

    componentWillUnmount = async () =>  { }
    
    componentDidUpdate = async (prevProps) =>
    {
        // console.log("SERVICE QUEUE prevState ", prevState);
        // console.log("SERVICE QUEUE this.state ", this.state.allSpecialists);
    }

    request = async () => 
    {
        // let result = await whatsAppController.getAllChats();
        // console.log(result);

        await axios.get("https://api-whatsapp.spnet.cc/whatsapp/status")
        .then(d => {
            console.log("data: ", d);
        })
        .catch(e => {
            console.log("error: ", e);
        });
    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }
    
    render ()
    {
        return (
            <div className="development">
                <Header title="Área de Desenvolvimento" classaditional="headerNavigationDevelopment"/>
                <div className="scrollDevelopment mb-4">
                    <div className="warningDevelopment d-flex d-md-none">
                        <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                    </div>
                    <div className="container headerDevelopment">
                        <Box>
                            <h4>Área de Desenvolvimento</h4>
                        </Box>
                        <Box>
                            <Button
                                name="request"
                                onClick={this.request}
                            />
                            {this.state.variable}
                        </Box>
                        <div className="warningDevelopment b-warning">
                            <span>Faça os testes em outros navegadores também!</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    return {}
}

function mapDispatchToProps (dispatch)
{
    return { 
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Development));