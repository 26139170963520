import React, { Component } from "react";
import "./Training.css";
import Header from "../../components/CRM/Header";
import { setModalMessage } from "../../store/actions/general";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { APPLICATION_NAME } from "../../core/constants";
import Box from "../../components/general/Box";
import TrainingController from "../../controllers/Training";
import { formatedDateTime } from "../../auxiliary/generalFunctions";

const initialState = {
    materials: [],
    materialTargetIframe: ""
}

const trainingController = new TrainingController();

class Training extends Component
{
    state = {...initialState}
    
    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Treinamento`;
        this.getAllMaterials();
    }

    getAllMaterials = async () =>
    {
        let resultConsult = await trainingController.get(this.props.user.PER_ID);
        let materials = [];

        if (resultConsult.status)
        {
            let {data} = resultConsult.data;

            for (const material of data) 
            {
                materials.push(material);
            }

            this.setState({materials});
        }
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TRAINING prevProps ", prevProps);
        // console.log("TRAINING this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}
    }

    getFileTypeIcon = (path) =>
    {
        if (path.includes(".html"))
        {
            return <i className="fas fa-globe-americas iconTraining"></i>;
        }
        else if (path.includes(".pdf"))
        {
            return <i class="fas fa-file-pdf iconTraining"></i>;
        }
        else if (path.includes(".doc") || path.includes(".docx"))
        {
            return <i class="fas fa-file-word iconTraining"></i>;
        }
        else if (path.includes(".xlx") || path.includes(".xlxs"))
        {
            return <i class="fas fa-file-excel iconTraining"></i>
        }
        else if (path.includes(".mp4") || path.includes(".flv"))
        {
            return <i class="fas fa-photo-video iconTraining"></i>
        }
        else if (path.includes(".mp3"))
        {
            return <i class="fas fa-headphones iconTraining"></i>;
        }
        else
        {
            return <i className="fas fa-file iconTraining"></i>;
        }
    }

    redirectToURL = (url) => window.open(url, "Treinamento", "width=1200,height=760");

    render ()
    {
        return (
            <div className="training">
                <Header className="headerTraining" title="Treinamento"/>
                <div className="scrollTraining mb-4">
                    <div className="container headerTraining">
                        <Box>
                            <h4>Todos os Materiais</h4>
                        </Box>
                    </div>
                    <div className="container bodyTraining d-none d-md-block">
                        <Box>
                            {
                                this.state.materials.map((m) => {
                                    return (
                                        <div key={m.TRF_ID} className="materialTraining">
                                            {this.getFileTypeIcon(m.TRF_FILE_PATH)}
                                            <span 
                                                className="nameTraining"
                                                onClick={() => this.redirectToURL(m.TRF_FILE_PATH)}
                                            >
                                                {m.TRF_FILE_NAME}
                                            </span>
                                            <span className="dateTraining">inserido em: {formatedDateTime(m.TRF_UPLOAD_DATE)}</span>
                                        </div>
                                    )
                                })
                            }
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const {user} = state.auth;
    const permissions = state.permissions.training;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        user,
        permissions,
        hasPermissionsLoaded,
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Training));