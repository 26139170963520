import React, { Component } from "react";
import InputGeneral from "../general/InputGeneral";
import { 
    optionsFrequencyTypePhone, 
    optionsFrequencyTypeMail, 
    ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR, 
    ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT,
    ID_PERMISSIONS_GROUP_PROSPECTION_COORDINATORS
} from "../../core/constants";
import { isEmail, formatedPhoneAndCellphone, formatedOnlyNumber, getFullNameFisrtCharsUppercase } from "../../auxiliary/generalFunctions";
import "./OwnerForm.css";
import Select from "../general/Select";
import Button from "../general/Button";
import { connect } from "react-redux";
import { setModalMessage, showModalMoreActions } from "../../store/actions/general";
import { getAllUsersOwners } from "../../store/actions/owners";
import PermissionsGroupCollectionsController from "../../controllers/PermissionsGroupCollectionsController";
import PersonCompanyUnitController from "../../controllers/PersonCompanyUnitController";
import UserController from "../../controllers/UserController";
import SelectMulti from "../general/SelectMulti";

const initialState = {
    // start content name
    name: "",
    searchingName: false,
    warningName: false,
    noticesName: [],
    validName: true,
    // ends content name     

    // start content phoneOne
    phoneOne: "",
    searchingPhoneOne: false,
    warningPhoneOne: false,
    noticesPhoneOne: [
        <span>aviso 1</span>,
        <span>aviso 2</span>,
    ],
    validPhoneOne: true,
    optionsPhoneOne: optionsFrequencyTypePhone,
    optionChosenFrequencyTypePhoneOne: null,
    // ends content phoneOne 

    // start content password
    password: "",
    validPassword: true,
    // ends content password
    
    // start content confirm password
    confirmPassword: "",
    validConfirmPassword: true,
    // ends content confirm password

    // start content mail
    mail: "",
    searchingMail: false,
    warningMail: false,
    noticesMail: [],
    validMail: true,
    optionsMail: optionsFrequencyTypeMail,
    optionChosenFrequencyTypeMail: null,
    // ends content mail

    // start content units
    optionsUnitCompany: [],
    validCompanyUnits: true,
    // ends content units

    // start content supervisor
    mandatoryFillingSuperior: false,
    optionsSupervisor: [],
    optionChosenSuperior: null,
    validSupervisor: true,
    // ends content supervisor

    // start content permissions group
    optionsPermissionsGroup: [],
    optionChosenPermissionsGroup: null,
    validPermissionGroup: true,
    // ends content permissions group

    // start content button
    disabledButton: false
    // ends content button
}

const permissionsGroupCollectionsController = new PermissionsGroupCollectionsController();
const personCompanyUnitController = new PersonCompanyUnitController();
const userController = new UserController();

class OwnerForm extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        await this.getCompanyUnit();
        await this.getPermissionsGroup();
        await this.getAllSupervisors();
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        }
        this.props.showModalMoreActions(data);
    }

    nameChangeAndChecking = async (name) =>
    {
        await this.setState({searchingName: true, warningName: false, validName: true});
        await this.setState({name});

        setTimeout(() => {
            this.setState({searchingName: false});
        }, 1000);
    }

    phoneOneChangeAndChecking = async (phoneOne) =>
    {
        let valid = formatedOnlyNumber(this.state.phoneOne).length >= 10;
        await this.setState({
            searchingPhoneOne: true, 
            warningPhoneOne: false, 
            validPhoneOne: valid,
            phoneOne: formatedPhoneAndCellphone(phoneOne)
        });

        setTimeout(() => {
            this.setState({searchingPhoneOne: false, warningPhoneOne: false});
        }, 1000);
    }

    passwordChangeAndChecking = async (password) =>
    {
        let equals = (password.toString().trim() !== "" && this.state.confirmPassword === password) ? true : false;

        await this.setState({
            validConfirmPassword: equals,
            validPassword: equals,
            password
        });
    }

    confirmPasswordChangeAndChecking = async (confirmPassword) =>
    {
        let equals = (confirmPassword.toString().trim() !== "" && this.state.password === confirmPassword) ? true : false;

        await this.setState({
            validConfirmPassword: equals,
            validPassword: equals,
            confirmPassword
        });
    }

    unitCompanyChangeAndChecking = async (optionsUnitCompany) =>
    {
        await this.setState({
            validCompanyUnits: (this.state.optionsUnitCompany === false) ? true : false,
            optionsUnitCompany
        });
    }

    permissionGroupChangeAndChecking = async (optionChosenPermissionsGroup) =>
    {
        let mandatoryFillingSuperior = (ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(optionChosenPermissionsGroup.value)) || ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(optionChosenPermissionsGroup.value) ? true : false;
        let optionsSupervisor = await this.state.optionsSupervisorNoFiltered;

        const userUnits = this.state.optionsUnitCompany;

        if (ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(optionChosenPermissionsGroup.value))
        {
            optionsSupervisor = optionsSupervisor.filter(u => {
                let allowed = false;
                for (const unit of u.units)
                {
                    if (!allowed)
                    {
                        allowed = userUnits.some(u => u.id === unit.UNT_ID);
                    }
                }

                return u.coordinator && allowed;
            });
        }

        if (ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT.includes(optionChosenPermissionsGroup.value))
        {
            optionsSupervisor = optionsSupervisor.filter(u => {
                let allowed = false;
                for (const unit of u.units)
                {
                    if (!allowed)
                    {
                        allowed = userUnits.some(u => u.id === unit.UNT_ID);
                    }
                }

                return u.supervisor && allowed;
            });
        }
        
        await this.setState({
            validPermissionGroup: (this.state.optionChosenPermissionsGroup === false) ? false : true,
            optionChosenPermissionsGroup,
            mandatoryFillingSuperior,
            optionsSupervisor
        });
    }

    superiorChangeAndChecking = async (optionChosenSuperior) =>
    {
        await this.setState({
            validSupervisor: (this.state.optionChosenSuperior === false) ? false : true,
            optionChosenSuperior
        });
    }

    phoneTwoChangeAndChecking = async (phoneTwo) =>
    {
        await this.setState({
            searchingPhoneTwo: true, 
            warningPhoneTwo: false,
            phoneTwo: formatedPhoneAndCellphone(phoneTwo)
        });

        setTimeout(() => {
            this.setState({searchingPhoneTwo: false, warningPhoneTwo: false});
        }, 1000);
    }

    mailChangeAndChecking = async (mail) =>
    {
        await this.setState({searchingMail: true, warningMail: false});
        await this.setState({mail, validMail: isEmail(mail)});

        setTimeout(() => {
            this.setState({searchingMail: false, warningMail: false});
        }, 1000);
    }

    getCompanyUnit = async () => 
    {
        const result = await userController.getById(this.props.user.PER_ID);
        let units = [];
        for (const unit of result.data.unit_companies) 
        {
            units.push({
                id: unit.UNT_ID,
                name: unit.UNT_NAME,
                value: unit.UNT_ID,
                checked: false
            });
        }
        
        await this.setState({ optionsUnitCompany: units });
    }

    getPermissionsGroup = async () => 
    {
        let allCollectionsPermissionsGroup = [];
        const result = await permissionsGroupCollectionsController.getByPermissionGroup(this.props.user.PER_ID_PERMISSIONS_GROUP);

        if (result.status) 
        {
            for (const permission of result.data.data)
            {
                allCollectionsPermissionsGroup.push({
                    name: permission.PEG_NAME_CONTROLLED,
                    value: permission.PEB_ID_PERMISSIONS_GROUP_CONTROLLED,
                    checked: false
                });
            }

        }
    
       await this.setState({optionsPermissionsGroup: allCollectionsPermissionsGroup});
    }

    getAllSupervisors = async () =>
    {
        let optionsSupervisor = [];
        let responseUsers = await userController.get();

        if (responseUsers.status)
        {
            let {data} = responseUsers.data;
            
            for (let user of data)
            {
                if 
                (
                    (ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(user.PER_ID_PERMISSIONS_GROUP) ||
                    ID_PERMISSIONS_GROUP_PROSPECTION_COORDINATORS.includes(user.PER_ID_PERMISSIONS_GROUP)) &&
                    user.PER_STATUS === 1
                )
                {
                    optionsSupervisor.push({
                        name: user.PER_NAME,
                        value: user.PER_ID,
                        checked: false,
                        supervisor: ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR.includes(user.PER_ID_PERMISSIONS_GROUP),
                        coordinator: ID_PERMISSIONS_GROUP_PROSPECTION_COORDINATORS.includes(user.PER_ID_PERMISSIONS_GROUP),
                        units: user.unit_companies
                    });
                }
            }
        }
        
        await this.setState({optionsSupervisor, optionsSupervisorNoFiltered: optionsSupervisor});
    }

    onActionForm = async () => 
    {
        const units = this.state.optionsUnitCompany.filter(unit => unit.checked);

        if (this.state.name.trim() === "") 
        {
            return this.setState({ validName: false });
        }

        if (this.state.mail.trim() === "") 
        {
            return this.setState({ validMail: false });
        }

        if (!isEmail(this.state.mail))
        {
            return this.setState({ validMail: false });
        }

        if (this.state.password.trim() === "") 
        {
            return this.setState({ validPassword: false });
        }

        if (this.state.confirmPassword.trim() === "") 
        {
            return this.setState({ validConfirmPassword: false });
        }

        if (this.state.password !== this.state.confirmPassword) 
        {
            return this.setState({ validPassword: false, validConfirmPassword: false });
        }

        if (units.length === 0)
        {
            this.message("information", "Informe pelo menos uma Unidade!");
            return;
        }

        if (this.state.optionChosenPermissionsGroup === null) 
        {
            return await this.setState({ validPermissionGroup: false });
        }

        if (this.state.mandatoryFillingSuperior && this.state.optionChosenSuperior === null)
        {
            this.message("information", "Escolha um(a) supervisor(a) para o(a) atendente!");
            return;
        }

        await this.setState({ disabledButton: true });

        const data = {
            PER_NAME: getFullNameFisrtCharsUppercase(this.state.name),
            PER_MAIL: this.state.mail,
            PER_ID_PERSON_TYPE: 1,
            PER_ID_PERMISSIONS_GROUP: this.state.optionChosenPermissionsGroup.value,
            PER_PASSWORD: this.state.password,
            PER_ID_PERSON_SUPERIOR_TEAM: (this.state.mandatoryFillingSuperior) ? this.state.optionChosenSuperior.value : null,
        }
        
        const result = await userController.create(data);

        if (!result.status) 
        {
            await this.setState({ disabledButton: false });
            this.message("information", `ERRO: ${result.message}`);
            return;
        }

        for (const unit of units)
        {
            await personCompanyUnitController.create({
                PCU_ID_PERSON: result.data.PER_ID,
                PCU_ID_COMPANY_UNIT: unit.value
            });
        }

        await this.setState({ disabledButton: false });
        await this.props.getAllUsersOwners();

        this.closeModalMoreActions();
    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }

    render () 
    {
        return (
            <div className="ownerForm"> 
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <InputGeneral
                            label="Nome"
                            value={this.state.name}
                            onChange={e => this.nameChangeAndChecking(e.target.value)}
                            searching={this.state.searchingName.toString()}
                            warning={this.state.warningName.toString()}
                            warningcontent={this.state.noticesName}
                            mandatory="true"
                            valid={this.state.validName.toString()}
                        />
                    </div>
                </div>
                {/* <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <InputGeneral
                            label="Telefone"
                            value={this.state.phoneOne}
                            onChange={e => this.phoneOneChangeAndChecking(e.target.value)}
                            searching={this.state.searchingPhoneOne.toString()}
                            warning={this.state.warningPhoneOne.toString()}
                            warningcontent={this.state.noticesPhoneOne}
                            mandatory="true"
                            valid={this.state.validPhoneOne.toString()}
                            maxLength="14"
                        />
                    </div>
                    <div className="col-6 pl-1 columnOwnerForm">
                        <Select 
                            default={this.state.optionChosenFrequencyTypePhoneOne}
                            options={this.state.optionsPhoneOne} 
                            onChange={(optionChosenFrequencyTypePhoneOne) => this.setState({optionChosenFrequencyTypePhoneOne})}
                        />
                    </div>
                </div> */}
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <InputGeneral
                            label="E-mail"
                            value={this.state.mail}
                            onChange={e => this.mailChangeAndChecking(e.target.value)}
                            searching={this.state.searchingMail.toString()}
                            warning={this.state.warningMail.toString()}
                            warningcontent={this.state.noticesMail} 
                            valid={this.state.validMail.toString()}
                            mandatory="true"
                        />
                    </div>
                    {/* <div className="col-6 pl-1 columnOwnerForm">
                        <Select 
                            default={this.state.optionChosenFrequencyTypeMail}
                            options={this.state.optionsMail} 
                            onChange={(optionChosenFrequencyTypeMail) => this.setState({optionChosenFrequencyTypeMail})}
                        />
                    </div> */}
                </div>
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <InputGeneral
                            label="Senha"
                            value={this.state.password}
                            onChange={e => this.passwordChangeAndChecking(e.target.value)}
                            mandatory="true"
                            valid={this.state.validPassword.toString()}
                            maxLength="14"
                            type="password"
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <InputGeneral
                            label="Confirme a senha"
                            value={this.state.confirmPassword}
                            onChange={(e) => this.confirmPasswordChangeAndChecking(e.target.value)}
                            mandatory="true"
                            maxLength="14"
                            type="password"
                            autoComplete="off"
                            valid={this.state.validConfirmPassword.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <SelectMulti 
                            label="Unidade(s)"
                            default={this.state.optionsUnitCompany}
                            options={this.state.optionsUnitCompany} 
                            onChange={(optionsUnitCompany) => this.unitCompanyChangeAndChecking(optionsUnitCompany)}
                            valid={this.state.validCompanyUnits.toString()}
                            mandatory
                        />
                    </div>
                </div>
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <Select
                            label="Grupo de Permissões"
                            default={this.state.optionChosenPermissionsGroup}
                            options={this.state.optionsPermissionsGroup} 
                            onChange={(optionChosenPermissionsGroup) => this.permissionGroupChangeAndChecking(optionChosenPermissionsGroup)}
                            valid={this.state.validPermissionGroup.toString()}
                            mandatory="true"
                        />
                    </div>
                </div>
                {
                    this.state.mandatoryFillingSuperior &&
                    <div className="row no-gutters lineOwnerForm">
                        <div className="col-12 columnOwnerForm">
                            <Select
                                label="Superior"
                                default={this.state.optionChosenSuperior}
                                options={this.state.optionsSupervisor} 
                                onChange={async (optionChosenSuperior) => this.superiorChangeAndChecking(optionChosenSuperior)}
                                valid={this.state.validSupervisor.toString()}
                                mandatory="true"
                            />
                        </div>
                    </div>
                }
                <div className="row no-gutters lineOwnerForm">
                    <div className="col-12 columnOwnerForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterOwnerForm">
                    <Button
                        classaditional="buttonOwnerForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions()}
                    />
                    <Button
                        classaditional="buttonOwnerForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                        disabled={this.state.disabledButton}
                    />                 
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;

    return {
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        },
        getAllUsersOwners ()
        {
            //action creator -> action
            const action = getAllUsersOwners();
            dispatch(action);
        },
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerForm);