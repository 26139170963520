import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";
import { baseURLTemporaryWebhook, integrationKeyTemporaryWebhook }  from "../configuration";
import axios from "axios";

const api = new API();

export default class BusinessController
{
    async create(data)
    {
        const response = await api.business().post(data);
        return apiCallbackTreatment(response);
    }

    async deleteById(id)
    {
        const response = await api.business().remove(id);
        return apiCallbackTreatment(response);
    }

    async get(idUser, status = null)
    {
        const response = await api.business().get(idUser, status);
        return apiCallbackTreatment(response);
    }

    async getByClient(idClient)
    {
        const response = await api.business().getByClient(idClient);
        return apiCallbackTreatment(response);
    }

    async getByFilters(filters = null)
    {
        const response = await api.business().getByFilters(filters);
        return apiCallbackTreatment(response);
    }

    async getPendingBusinessFromUnits(idUser)
    {
        const response = await api.business().getPendingBusinessFromUnits(idUser);
        return apiCallbackTreatment(response);
    }

    async getBusinessServicesERP(idUser)
    {
        const response = await api.business().getBusinessServicesERP(idUser);
        return apiCallbackTreatment(response);
    }

    async getBusinessServices()
    {
        const response = await api.business().getBusinessServices();
        return apiCallbackTreatment(response);
    }

    async getRelationshipTargetUnitAndService(idUser)
    {
        const response = await api.business().getRelationshipTargetUnitAndService(idUser);
        return apiCallbackTreatment(response);
    }

    async getById(id)
    {
        const response = await api.business().getById(id);
        return apiCallbackTreatment(response);
    }

    async getAmountTotalBusinessLoss(idUser, period)
    {
        const response = await api.business().getTotalBusinessLoss(idUser, period);
        return apiCallbackTreatment(response);
    }

    async getAmountTotalBusinessRecovered(idUser, period)
    {
        const response = await api.business().getTotalBusinessRecovered(idUser, period);
        return apiCallbackTreatment(response);
    }

    async getAmountTotalBusinessLossByPeriod(idUser, dateStart, dateEnds, whatsSlugServices = null)
    {
        const response = await api.business().getTotalBusinessLossByPeriod(idUser, dateStart, dateEnds, whatsSlugServices);
        return apiCallbackTreatment(response);
    }

    async getAmountTotalBusinessRecoveredByPeriod(idUser, dateStart, dateEnds, whatsSlugServices = null)
    {
        const response = await api.business().getTotalBusinessRecoveredByPeriod(idUser, dateStart, dateEnds, whatsSlugServices);
        return apiCallbackTreatment(response);
    }

    async update(id, data)
    {
        const response = await api.business().update(id, data);
        return apiCallbackTreatment(response);
    }

    async getServicesERPTemporaryWebhook(idUserERP)
    {
        let response = await axios.get(`${baseURLTemporaryWebhook}/service/all/user=${idUserERP}/key=${integrationKeyTemporaryWebhook}`);
        response = response.data;
        return apiCallbackTreatment(response);
    }

    async duplicateFromAtoB(data)
    {
        const response = await api.business().duplicateFromAtoB(data);
        return apiCallbackTreatment(response);
    }

    async getInstallmentsAvailable(idPermissionGroup) 
    {
        const response = await api.business().getInstallmentsAvailable(idPermissionGroup);
        return apiCallbackTreatment(response);
    }
}