import React, { Component } from "react";
import "./DropdownWhatsApp.css";
export default class DropdownWhatsApp extends Component
{
    constructor (props)
    {
        super(props);
        this.dropdownRef = React.createRef();
        this.handleClickOutSide = this.handleClickOutSide.bind(this);
    }

    componentDidMount = async () =>
    {
        document.addEventListener("mousedown", this.handleClickOutSide);
    }

    componentWillUnmount = () =>
    {
        document.removeEventListener("mousedown", this.handleClickOutSide);
    }

    handleClickOutSide = (event) =>
    {
        let targetClass = event.target.className.baseVal;
        
        if 
        (
            this.dropdownRef && 
            !this.dropdownRef.current.contains(event.target) && 
            this.props.clickedOutSide &&
            targetClass !== this.props.classExtendsToDropdown
        )
        { this.props.clickedOutSide(); }
    }

    render ()
    {
        return (
            <div 
                className={`dropdownWhatsApp ${this.props?.classaditional} ${this.props.status}`} 
                style={{...this.props?.style}}
                ref={this.dropdownRef}
            >
                {this.props.children}
            </div>
        )
    }

}