import React from "react";
import "./SubtitleProgressLine.css";

export default function SubtitleProgressLine (props)
{
    return (
        <div className={`subtitleProgressLine ${props.classaditional}`}>
            <span className="labelSubtitleProgressLine">{props.label}</span>
            <div className="areaProgressSubtitleProgressLine">
                <span className="barSubtitleProgressLine" style={{backgroundColor: `var(--color-${props.color})`}}></span>
                <span 
                    className={`progressSubtitleProgressLine one ${props.onOverOne ? "withAction" : ""}`}
                    onClick={props?.onClickOne}
                    onMouseOver={props?.onOverOne}
                    onMouseLeave={props?.onLeaveOne}                    
                >
                    {props.progressOne}
                    {props.unitSymbolOne ? props.unitSymbolOne : "" }
                </span>
                { props.progressTwo && <span className="progressSubtitleProgressLine divider">/</span> }
                {
                    props.progressTwo &&
                    <span
                        className={`progressSubtitleProgressLine two ${props.onOverTwo ? "withAction" : ""}`}
                        onClick={props?.onClickTwo}
                        onMouseOver={props?.onOverTwo}
                        onMouseLeave={props?.onLeaveTwo}                    
                    >
                        {props.progressTwo}
                        {props.unitSymbolTwo ? props.unitSymbolTwo : ""  }
                    </span>
                }
            </div>
        </div>
    )
}