import React, { Component } from "react"; 
import "./ConnectionLogs.css";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { APPLICATION_NAME } from "../../core/constants";

import Header from "../../components/CRM/Header";
import Box from "../../components/general/Box";
import Anchor from "../../components/general/Anchor";
import CompanyUnitController from "../../controllers/CompanyUnitController";
import LogsConnectController from "../../controllers/LogsConnectController"
import { 
    formatedDateTime, 
    formatedDateTimeToUSA, 
    getDateTimeCurrentBrowserAtMidnight, 
    getFullNameFisrtCharsUppercase, 
    getOptionsSelectCheckedFormated
} from "../../auxiliary/generalFunctions";
import UserController from "../../controllers/UserController";
import SelectMulti from "../../components/general/SelectMulti";
import InputGeneral from "../../components/general/InputGeneral";
import Button from "../../components/general/Button";
import PermissionsGroupCollectionsController from "../../controllers/PermissionsGroupCollectionsController";
import NetworkAddressWhiteListController from "../../controllers/NetworkAddressWhiteListController";
import ButtonIcon from "../../components/general/ButtonIcon";

const initialState = {

    typeCurrentMessageToUser: 4, //DEFAULT [4] (suspicious login)!

    // start filter by specific 
    showDropDownIntervals: false,
    dateStartIntervals: "", //looks like: "2022-04-01"
    dateEndsIntervals: "", //looks like: "2022-04-30"
    // ends filter by specific

    allConnectionLogs: [
        // RESERVED
        // {
        //     id: 1,
        //     userName: "Thais",
        //     userId: 1,
        //     address: "200.170.183.74",
        //     date: "18/07/2022 14:59:59",
        //     type: 0,
        //     message: "Login suspeito!!!",
        //     status: 2,
        //     browser: "Chrome SO Mac"
        // },
        // {
        //     id: 2,
        //     userName: "Lucas",
        //     userId: 4,
        //     address: "200.170.183.74",
        //     date: "18/07/2022 14:59:59",
        //     type: 0,
        //     message: "",
        //     status: 1,
        //     browser: "Chrome SO Mac"
        // },
    ],

    choosenStatusLogs: {
        name: "Login Suspeito",
        value: 4,
        checked: true
    },
    optionsStatusLogs: [
        // DANGER!!! Don't remove!
        {
            name: "Login com Sucesso",
            value: 1,
            checked: true
        },
        {
            name: "Senha Inválida",
            value: 2,
            checked: true
        },
        {
            name: "Usuário Inativo",
            value: 3,
            checked: true
        },
        {
            name: "Login Suspeito (IP fora da WhiteList)",
            value: 4,
            checked: true
        },
        {
            name: "Login Suspeito (Dispositivo Móvel)",
            value: 5,
            checked: true
        },
        {
            name: "Login Suspeito (Fora WhiteList e Expediente)",
            value: 6,
            checked: true
        },
        {
            name: "Login Suspeito (Usuário bloqueado no ERP)",
            value: 7,
            checked: true
        },
        {
            name: "Login Suspeito (Desbloqueado)",
            value: 8,
            checked: true
        },
        {
            name: "Logout",
            value: 9,
            checked: true
        },
    ],

    optionsCompanyUnit: [],
    optionsUserByUnit: [],
    optionsUserByUnitByPermissionsGroup: [],
    optionsPermissionsGroup: [],
}

const companyUnitController = new CompanyUnitController();
const logsConnectController = new LogsConnectController();
const userController = new UserController();
const permissionsGroupCollectionsController = new PermissionsGroupCollectionsController();
const networkAddressWhiteList = new NetworkAddressWhiteListController();
class ConnectionLogs extends Component
{
    _mounted = false;
    state = {...initialState}

    componentDidMount = async () => 
    {   
        this._mounted = true;
        document.title = `${APPLICATION_NAME} - Logs de Conexões`;
        await this.getPermissionsGroup();
        await this.setTodayAnd5DaysBefore();
        await this.getCompanyUnit();
        await this.getUsersByUnitCompany();
        await this.getAllConnectionLogs();

        if (!this.props.connectionLog.toView) { this.props.history.push(`/activities`); }
    }

    componentWillUnmount = async () => 
	{ 
		this._mounted = false;
		console.info("CONNECTION LOGS demoting.");
	}

    componentDidUpdate = async (prevProps) =>
    {
        // console.log("CONNECTION LOGS prevProps ", prevProps);
        // console.log("CONNECTION LOGS this.props ", this.props);
    }

    getAllConnectionLogs =  async () =>
    {
        let allConnectionLogs = [];
        let dateStartIntervals = await this.state.dateStartIntervals;
        let dateEndsIntervals = await this.state.dateEndsIntervals;
        let optionsStatusLogs = await this.state.optionsStatusLogs;
        let optionsCompanyUnit = await this.state.optionsCompanyUnit;
        let optionsUserByUnit = await this.state.optionsUserByUnit;
        let optionsPermissionsGroup = await this.state.optionsPermissionsGroup;
        let status = [];
        let statusForNotChecked = [];
        let units = [];
        let unitsForNotChecked = [];
        let users = [];
        let usersForNotChecked = [];
        let groups = [];
        let groupsForNotChecked = [];

        optionsCompanyUnit.forEach(u => {
            if (u.checked) { units.push(u.value); }
            unitsForNotChecked.push(u.value);
        });
        
        // @OVERWRITE
        units = (units.length > 0) ? units : unitsForNotChecked;

        optionsUserByUnit.forEach(u => {
            if (u.checked) { users.push(u.value); }
            usersForNotChecked.push(u.value);
        });

        // @OVERWRITE
        users = (users.length > 0) ? users : usersForNotChecked;

        optionsStatusLogs.forEach(s => {
            if (s.checked) { status.push(s.value); }
            statusForNotChecked.push(s.value);
        });

        // @OVERWRITE
        status = (status.length > 0) ? status : statusForNotChecked;
        
        optionsPermissionsGroup.forEach(p => {
            if (p.checked) { groups.push(p.value); }
            groupsForNotChecked.push(p.value);
        });

        // @OVERWRITE
        groups = (groups.length > 0) ? groups : groupsForNotChecked;

        let responseLogs = await logsConnectController.getByFilters({
            LCC_DATETIME_CONNECT_START: dateStartIntervals,
            LCC_DATETIME_CONNECT_ENDS: dateEndsIntervals,
            LCC_ID_PERSON: users,
            PER_ID_PERMISSIONS_GROUP: groups,
            LCC_MESSAGE_TO_USER: status,
            UNITS: units,
        });

        if (responseLogs.status)
        {
            let {data} = responseLogs.data;

            for (let log of data)
            {
                allConnectionLogs.push({
                    id: log.LCC_ID,
                    userName: log.PER_NAME,
                    userId: log.LCC_ID_PERSON,
                    address: log.LCC_NETWORK_ADDRESS,
                    date: formatedDateTime(log.LCC_DATETIME_CONNECT),
                    type: log.LCC_MESSAGE_TO_USER,
                    message: log.LCC_MESSAGE_TO_USER_DESCRIPTION,
                    status: log.LCC_STATUS,
                    browser: log.LCC_BROWSER_INFO,
                    units: log.unit_companies,
                    isWhiteList: log.isWhiteList
                });
            }
        }

        allConnectionLogs = allConnectionLogs.sort((a, b) => {
            if (a.id > b.id) { return -1; }
            if (a.id < b.id) { return 1; }
            return 0;
        });

        if (this._mounted)
        { await this.setState({allConnectionLogs}); }
    }

    getUsersByUnitCompany = async () =>
    {
        let optionsCompanyUnit = await this.state.optionsCompanyUnit;
        let optionsUserByUnit = [];
        let optionsUserByUnitByPermissionsGroup = [];
        let units = [];
        let unitsForNotChecked = [];
        
        optionsCompanyUnit.forEach(u => {
            if (u.checked) { units.push(u.value); }
            unitsForNotChecked.push(u.value);
        });

        // @OVERWRITE
        units = (units.length > 0) ? units : unitsForNotChecked;
        let responseUser = await userController.getAllUsersByCompanyUnit(this.props.user.PER_ID);

        if (responseUser.status)
        {
            let data = responseUser.data;
            
            for (let unit of data)
            {
                if (units.some(u => u === unit.UNT_ID))
                {
                    let users = unit.users;

                    for (let user of users)
                    {
                        optionsUserByUnit.push({
                            name: getFullNameFisrtCharsUppercase(user.PER_NAME),
                            value: user.PER_ID,
                            checked: false
                        });

                        optionsUserByUnitByPermissionsGroup.push({
                            name: getFullNameFisrtCharsUppercase(user.PER_NAME),
                            idPermissionsGroup: user.PER_ID_PERMISSIONS_GROUP,
                            value: user.PER_ID,
                            checked: false
                        });
                    }
                }
            }
        }

        if (this._mounted)
        {
            await this.setState({optionsUserByUnit, optionsUserByUnitByPermissionsGroup});
        }
    }

    getCompanyUnit = async () => 
    {
        let optionsCompanyUnit = [];
        const result = await companyUnitController.get();

        if (result.status) 
        {
            for (const company of result.data.data)
            {
                if (company.UNT_STATUS === 1)
                {
                    optionsCompanyUnit.push({
                        name: company.UNT_NAME,
                        value: company.UNT_ID,
                        checked: false
                    });
                }
            }
        }

        if (this._mounted)
        {
            await this.setState({optionsCompanyUnit});
        }
    }

    getPermissionsGroup = async () => {
        let optionsPermissionsGroup = [];

        const result = await permissionsGroupCollectionsController.getByPermissionGroup(this.props.user.PER_ID_PERMISSIONS_GROUP);

        if (result.status) 
        {
            for (const permission of result.data.data)
            {
                optionsPermissionsGroup.push({
                    name: permission.PEG_NAME_CONTROLLED,
                    value: permission.PEB_ID_PERMISSIONS_GROUP_CONTROLLED,
                    checked: false
                });
            }
        }
    
        if (this._mounted)
        {
            await this.setState({optionsPermissionsGroup});
        }
    }

    getUsersByPermissionsGroup = async () =>
    {
        let optionsUserByUnit = [];
        let optionsPermissionsGroup = await this.state.optionsPermissionsGroup;
        let optionsUserByUnitByPermissionsGroup = await this.state.optionsUserByUnitByPermissionsGroup;
        let permissionsGroupForNotChecked = [];
        let permissions = [];

        optionsPermissionsGroup.forEach(u => {
            if (u.checked) { permissions.push(u.value); }
            permissionsGroupForNotChecked.push(u.value);
        });

        // @OVERWRITE
        permissions = (permissions.length > 0) ? permissions : permissionsGroupForNotChecked;

        for (let user of optionsUserByUnitByPermissionsGroup)
        {
            if (permissions.includes(user.idPermissionsGroup))
            {
                optionsUserByUnit.push({
                    name: user.name,
                    value: user.value,
                    checked: false
                });
            }
        }

        if (this._mounted)
        {
            await this.setState({optionsUserByUnit});
        }
    }

    setTodayAnd5DaysBefore = async ()  =>
    {
        let dateToday = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
        // let dateBefore = formatedDateTimeToUSA(getPreviousDateTimeCurrentBrowserAtMidnight(5).toDateString());

        if (this._mounted)
        {
            await this.setState({
                dateStartIntervals: dateToday,
                dateEndsIntervals: dateToday
            });
        }
    }

    onInsertIPonWhiteLIst = async (address, units) =>
    {
        let result = await networkAddressWhiteList.post({address, units});
        if (result.status) { this.getAllConnectionLogs(); }
    }

    render ()
    {
        return (
            <div className="connectionLogs">
                <Header title="Logs de Conexões" classaditional="headerNavigationConnectionLogs"/>
                <div className="scrollConnectionLogs mb-4">
                    <div className="warningConnectionLogs d-flex d-md-none">
                        <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                    </div>
                    <div className="container-fluid bodyConnectionLogs d-none d-md-block">
                        <Box>
                            <div className="row no-gutters mt-2 mb-4 lineFiltersConnectionLogs justify-content-center">
                                <div className="filterBodyConnectionLogs d-none d-md-flex extra">
                                    <i className="fas fa-key iconfilterBodyConnectionLogs"></i>
                                    <SelectMulti
                                        classaditional="selectMultiFilterBodyConnectionLogs"
                                        default={{name: getOptionsSelectCheckedFormated(this.state.optionsStatusLogs, "[Todos Status]", "status")}}
                                        options={this.state.optionsStatusLogs} 
                                        onChange={async (optionsStatusLogs) => {
                                            await this.setState({optionsStatusLogs});
                                            await this.getAllConnectionLogs();
                                        }}
                                    />
                                </div>
                                <div className="filterBodyConnectionLogs d-none d-md-flex">
                                    <i className="fas fa-building iconfilterBodyConnectionLogs"></i>
                                    <SelectMulti
                                        classaditional="selectMultiFilterBodyConnectionLogs"
                                        default={{name: getOptionsSelectCheckedFormated(this.state.optionsCompanyUnit, "[Todas Unidades]", "unidades")}}
                                        options={this.state.optionsCompanyUnit} 
                                        onChange={async (optionsCompanyUnit) => {
                                            await this.setState({optionsCompanyUnit});
                                            await this.getUsersByUnitCompany();
                                            await this.getAllConnectionLogs();
                                        }}
                                        withFieldSearch
                                    />
                                </div>
                                <div className="filterBodyConnectionLogs d-none d-md-flex">
                                    <i className="fas fa-users iconfilterBodyConnectionLogs"></i>
                                    <SelectMulti
                                        classaditional="selectMultiFilterBodyConnectionLogs"
                                        default={{name: getOptionsSelectCheckedFormated(this.state.optionsPermissionsGroup, "[Todos Grupos]", "grupos")}}
                                        options={this.state.optionsPermissionsGroup} 
                                        onChange={async (optionsPermissionsGroup) => {
                                            await this.setState({optionsPermissionsGroup});
                                            await this.getUsersByPermissionsGroup();
                                            await this.getAllConnectionLogs();
                                        }}
                                        withFieldSearch
                                    />
                                </div>
                                <div className="filterBodyConnectionLogs d-none d-md-flex">
                                    <i className="fas fa-user iconfilterBodyConnectionLogs"></i>
                                    <SelectMulti
                                        classaditional="selectMultiFilterBodyConnectionLogs"
                                        default={{name: getOptionsSelectCheckedFormated(this.state.optionsUserByUnit, "[Todos Usuários]", "usuários")}}
                                        options={this.state.optionsUserByUnit} 
                                        onChange={async (optionsUserByUnit) => {
                                            await this.setState({optionsUserByUnit});
                                            await this.getAllConnectionLogs();
                                        }}
                                        withFieldSearch
                                    />
                                </div>
                                <div className="filterBodyConnectionLogs d-none d-md-flex">
                                    <i className="far fa-calendar iconfilterBodyConnectionLogs"></i>
                                    <Button
                                        classaditional="buttonToggleIntervalsConnectionLogs"
                                        name="Selecione o Período"
                                        onClick={() => this.setState({showDropDownIntervals: !this.state.showDropDownIntervals})}
                                    />
                                    {
                                        this.state.showDropDownIntervals &&
                                        <div className="dropIntervalsBodyConnectionLogs">
                                            <div className="row no-gutters lineBodyDropIntervalsBodyConnectionLogs">
                                                <div className="col-6 pr-1">
                                                    <InputGeneral
                                                        label="Data Inicial"
                                                        type="date" 
                                                        onChange={(e) => this.setState({dateStartIntervals: e.target.value})}
                                                        value={this.state.dateStartIntervals}
                                                    />
                                                </div>
                                                <div className="col-6 pl-1">
                                                    <InputGeneral
                                                        label="Data Final"
                                                        type="date" 
                                                        onChange={(e) => this.setState({dateEndsIntervals: e.target.value})}
                                                        value={this.state.dateEndsIntervals}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row no-gutters lineFooterDropIntervalsBodyConnectionLogs">
                                                <Button
                                                    classaditional="buttonIntervalsBodyConnectionLogs"
                                                    name="Limpar"
                                                    onClick={async () => {
                                                        await this.setState({
                                                            showDropDownIntervals: false,
                                                            dateStartIntervals: formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight()),
                                                            dateEndsIntervals: formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight()),
                                                        });
                                                    }}
                                                />
                                                <Button
                                                    classaditional="buttonIntervalsBodyConnectionLogs positive"
                                                    name="Aplicar"
                                                    onClick={async () => {
                                                        await this.setState({showDropDownIntervals: false});
                                                        await this.getAllConnectionLogs();
                                                    }}
                                                />                  
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row no-gutters lineTitleHeaderConfigConnectionLogs">
                                <div className="col-2 columnTitleHeaderConfigConnectionLogs">
                                    <span>ID | Usuário</span>
                                </div>
                                <div className="col-2 columnTitleHeaderConfigConnectionLogs">
                                    <span>Data</span>
                                </div>
                                <div className="col-1 columnTitleHeaderConfigConnectionLogs">
                                    <span>Status</span>
                                </div>
                                <div className="col-2 columnTitleHeaderConfigConnectionLogs">
                                    <span>Mensagem</span>
                                </div>
                                <div className="col-2 columnTitleHeaderConfigConnectionLogs">
                                    <span>Browser</span>
                                </div>
                                <div className="col-1 columnTitleHeaderConfigConnectionLogs">
                                    <span>Endereço</span>
                                </div>
                                <div className="col-2 columnTitleHeaderConfigConnectionLogs">
                                    <span>Unidade</span>
                                </div>
                            </div>
                            {
                                this.state.allConnectionLogs.map((l, i) => {
                                    return (
                                        <div key={`${i}`}>
                                            <div className={`row no-gutters lineBodyConfigConnectionLogs ${i} ${i % 2 === 0 ? "pair" : ""} ${(i === (this.state.allConnectionLogs.length - 1 ))? "final" : ""} `}>
                                                <div className="col-2 columnBodyConfigConnectionLogs justify-content-start">
                                                    <span>{l.userId} | &nbsp;{l.userName}</span>
                                                </div>
                                                <div className="col-2 columnBodyConfigConnectionLogs">
                                                    <span>{l.date}</span>
                                                </div>
                                                <div className="col-1 columnBodyConfigConnectionLogs">
                                                    {
                                                        l.status === 1 &&
                                                        <i className="far fa-check-circle pr-2 iconConnectionLog success"></i>
                                                    }
                                                    {
                                                        l.status === 2 &&
                                                        <i className="fas fa-ban pr-2 iconConnectionLog error"></i>
                                                    }
                                                </div>
                                                <div className="col-2 columnBodyConfigConnectionLogs left">
                                                    <span>{l.message}</span>
                                                </div>
                                                <div className="col-2 columnBodyConfigConnectionLogs left">
                                                    <span>{l.browser} </span>
                                                </div>
                                                <div className="col-1 columnBodyConfigConnectionLogs">
                                                    <Anchor
                                                        label={`${l.address}`}
                                                        href={`https://www.ipaddress.com/ipv4/${l.address}`}
                                                        target="_blank"
                                                    />
                                                    {
                                                        l.isWhiteList &&
                                                        <i className="far fa-check-circle pr-2 iconConnectionLog success"></i>
                                                    }
                                                    {
                                                        !l.isWhiteList &&
                                                        <ButtonIcon
                                                            title="Adicionar IP à lista branca"
                                                            classaditional="buttonInsertWhiteListConnectionLogs"
                                                            icon="far fa-plus-circle iconConnectionLog information"
                                                            onClick={() => this.onInsertIPonWhiteLIst(l.address, l.units)}
                                                        />
                                                    }
                                                </div>
                                                <div className="col-2 columnBodyConfigConnectionLogs left">
                                                    <span>{l.units[0].UNT_NAME} </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;
    const { connectionLog } = state.permissions;

    return {
        user,
        connectionLog
    }
}

function mapDispatchToProps (dispatch)
{
    return { }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectionLogs));