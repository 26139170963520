import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class ServiceQueuePersonsController
{
    async create(data) 
    {
        const response = await api.serviceQueuePersons().post(data);
        return apiCallbackTreatment(response);
    }

    async deletePosition(personId)
    {
        const response = await api.serviceQueuePersons().remove(personId);
        return apiCallbackTreatment(response);
    }

    async get() 
    {
        const response = await api.serviceQueuePersons().get();
        return apiCallbackTreatment(response);
    }

    async getById(id) 
    {
        const response = await api.serviceQueuePersons().getById(id);
        return apiCallbackTreatment(response);
    }

    async update(personId, data) 
    {
        const response = await api.serviceQueuePersons().update(personId, data);
        return apiCallbackTreatment(response);
    }

    async updatePosition(data) 
    {
        const response = await api.serviceQueuePersons().updatePosition(data);
        return apiCallbackTreatment(response);
    }
}