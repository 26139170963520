import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class CommissionRulesProspectionController
{
    async create(data) 
    {
        const response = await api.commissionRulesProspection().post(data);
        return apiCallbackTreatment(response);
    }

    async deleteById(id)
    {
        const response = await api.commissionRulesProspection().remove(id);
        return apiCallbackTreatment(response);
    }

    async get(month) 
    {
        const response = await api.commissionRulesProspection().get(month);
        return apiCallbackTreatment(response);
    }

    async getById(id) 
    {
        const response = await api.commissionRulesProspection().getById(id);
        return apiCallbackTreatment(response);
    }

    async update(id, data) {
        const response = await api.commissionRulesProspection().update(id, data);
        return apiCallbackTreatment(response);
    }
}