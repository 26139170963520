// adapted by ewertondias.com
import React, { Component } from "react";
import "./DatePickerCustom.css";
import DatePicker from "react-date-picker"; //more in: https://www.npmjs.com/package/react-date-picker
import { 
    formatedDateTimeToBrowserAtAnyTime,
    // formatedDateTimeToUSA,
    // identifiesBrowser
} from "../../auxiliary/generalFunctions";

export default class DatePickerCustom extends Component 
{
    handleOnChange = (value) =>
    {
        // let isMonth = (this.props.type && this.props.type === "month") ? true : false;
        // console.log("--------------------");
        // console.log("before handleOnChange value: ", value);
        // console.log("isMonth: ", isMonth);
        // console.log("--------------------");
        
        if (value !== null)
        {
            // let browser = identifiesBrowser();
            // value = (browser.isSafari) ? formatedDateTimeToUSA(value.toDateString()) : formatedDateTimeToUSA(value);
            // let parts = value.toString().split("-");
            // value = (isMonth) ? [parts[0], parts[1]].join("-") : value;
            
            let d = new Date(value);
            let month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
            day = ("0" + day).slice(-2);
            month = ("0" + month).slice(-2);
            
            value = `${year}-${month}-${day}`;
            
            this.props.onChange(value);

        }
        else
        {
            value = "";
            this.props.onChange(value);
        }
        
        // console.log("--------------------");
        // console.log("after handleOnChange value: ", value);
        // console.log("--------------------");
    }

    handleValue = (value) =>
    {
        // let isMonth = (this.props.type && this.props.type === "month") ? true : false;
        // console.log("--------------------");
        // console.log("before handleValue value: ", value);
        // console.log("isMonth: ", isMonth);
        // console.log("--------------------");

        if (value.toString().trim() !== "" && value !== null)
        {
            value = formatedDateTimeToBrowserAtAnyTime(`${value} 00:00:00`);
        }
        else
        {
            value = "";
        }
        
        // console.log("--------------------");
        // console.log("after handleValue value: ", value);
        // console.log("--------------------");

        return value;
    }

    render ()
    {
        return (
            <DatePicker
                className={`inputDatePickerCustom ${this.props?.classaditional}`}
                calendarClassName="calendarDatePickerCustom"
                value={this.handleValue(this.props.value)} 
                onChange={(value) => this.handleOnChange(value)}
                locale="pt-BR"
                format={`${this.props.type && this.props.type === "month" ? "MM/yyyy" : "dd/MM/yyyy"}`}
                dayPlaceholder="Dia"
                monthPlaceholder="Mês"
                yearPlaceholder="Ano"
                maxDetail={`${this.props.type && this.props.type === "month" ? "year" : "month"}`}
                clearIcon={<i className="far fa-times"></i>}
            />
        )
    }
}