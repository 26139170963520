import React, { Component } from "react";
import { connect } from "react-redux";
import "./TableEditAdvancedBusiness.css";
import { withRouter } from "react-router-dom";
import Anchor from "./Anchor";

const initialState = {
    divScroll: null
}
class TableEditAdvancedBusiness extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let divScroll = this.divScroll;

        await this.setState({ divScroll })
    }
    
    componentDidUpdate = async (prevProps, prevState) => 
    {
    }

    onScroll = async () =>
    {
        if (this.state.divScroll.scrollHeight - Math.ceil(this.state.divScroll.scrollTop) === this.state.divScroll.clientHeight)
        {
            this.props.onScroll();
        }
    }

    render ()
    {
        return (
            <div 
                className="tableEditAdvancedBusiness" 
                onScroll={this.onScroll} 
                ref={divScroll => this.divScroll = divScroll}
            >
                <table className="table">
                    <thead>
                        <tr className="theadTableEditAdvancedBusiness">
                            <td
                                className={`minimus60 ${this.props.columns.find(c => c.slug === "ID" && !c.visible) ? "d-none" : ""}`}
                                title="ID"
                            >
                                ID
                            </td>
                            <td
                                className={`minimus160 center ${this.props.columns.find(c => c.slug === "CREATED_AT" && !c.visible) ? "d-none" : ""}`}
                                title="Criado em"
                            >
                                Criado em
                            </td>
                            <td
                                className={`minimus140 ${this.props.columns.find(c => c.slug === "TITLE" && !c.visible) ? "d-none" : ""}`}
                                title="Título"
                            >
                                Título
                            </td>
                            <td
                                className={`minimus100 ${this.props.columns.find(c => c.slug === "STEP" && !c.visible) ? "d-none" : ""}`}
                                title="Etapa"
                            >
                                Etapa
                            </td>
                            <td
                                className={`minimus140 ${this.props.columns.find(c => c.slug === "CLIENT" && !c.visible) ? "d-none" : ""}`}
                                title="Pessoa de Contato"
                            >
                                Pessoa de Contato
                            </td>
                            <td
                                className={`minimus140 ${this.props.columns.find(c => c.slug === "EMAIL" && !c.visible) ? "d-none" : ""}`}
                                title="Pessoa de Contato"
                            >
                                E-mail
                            </td>
                            <td
                                className={`minimus100 ${this.props.columns.find(c => c.slug === "PHONE_ONE" && !c.visible) ? "d-none" : ""}`}
                                title="Pessoa de Contato"
                            >
                                Telefone
                            </td>
                            <td
                                className={`minimus140 ${this.props.columns.find(c => c.slug === "CAMPAIGN" && !c.visible) ? "d-none" : ""}`}
                                title="Campanha"
                            >
                                Campanha
                            </td>
                            <td
                                className={`minimus45 center ${this.props.columns.find(c => c.slug === "MIDIA_ORIGIN" && !c.visible) ? "d-none" : ""}`}
                                title="Fonte"
                            >
                                Fonte
                            </td>
                            <td
                                className={`minimus45 center ${this.props.columns.find(c => c.slug === "STATUS" && !c.visible) ? "d-none" : ""}`}
                                title="Status"
                            >
                                Status
                            </td>
                            <td
                                className={`minimus160 center ${this.props.columns.find(c => c.slug === "STATUS_DATE" && !c.visible) ? "d-none" : ""}`}
                                title="Data do Status"
                            >
                                Data do Status
                            </td>
                            <td
                                className={`minimus45 center ${this.props.columns.find(c => c.slug === "SHOWEDUP" && !c.visible) ? "d-none" : ""}`}
                                title="Compareceu"
                            >
                                Compareceu
                            </td>
                            <td
                                className={`minimus60 ${this.props.columns.find(c => c.slug === "ID_SERVICE_ERP" && !c.visible) ? "d-none" : ""}`}
                                title="ID Serviço ERP"
                            >
                                ID Serviço ERP
                            </td>
                            <td
                                className={`minimus140 ${this.props.columns.find(c => c.slug === "REASON_FOR_LOSS" && !c.visible) ? "d-none" : ""}`}
                                title="Motivo da perda"
                            >
                                Motivo da perda
                            </td>
                            <td
                                className={`minimus75 right ${this.props.columns.find(c => c.slug === "MONEY" && !c.visible) ? "d-none" : ""}`}
                                title="Valor"
                            >
                                Valor
                            </td>
                            <td
                                className={`minimus100 ${this.props.columns.find(c => c.slug === "PROSPECTION_OWNER" && !c.visible) ? "d-none" : ""}`}
                                title="Atendente"
                            >
                                Atendente
                            </td>
                            <td
                                className={`minimus100 ${this.props.columns.find(c => c.slug === "ACTIVITY_CREATED_BY" && !c.visible) ? "d-none" : ""}`}
                                title="Atividade Criada Por"
                            >
                                Atividade Criada Por
                            </td>
                            <td
                                className={`minimus100 ${this.props.columns.find(c => c.slug === "OWNER" && !c.visible) ? "d-none" : ""}`}
                                title="Proprietário"
                            >
                                Proprietário
                            </td>
                            <td
                                className={`minimus140 ${this.props.columns.find(c => c.slug === "COMPANY_UNIT" && !c.visible) ? "d-none" : ""}`}
                                title="Unidade"
                            >
                                Unidade
                            </td>
                            <td
                                className={`${this.props.columns.find(c => c.slug === "STAMP" && !c.visible) ? "d-none" : ""}`}
                                title="Etiqueta"
                            >
                                Etiqueta
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.data.map((e, i) => {
                                return (
                                    <tr key={`${i}`} className="tbodyTableEditAdvancedBusiness">
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "ID" && !c.visible) ? "d-none" : ""}`}
                                            title={e.id}
                                        >
                                            <Anchor
                                                classaditional="anchorOpenBusinessInNewWindow" 
                                                onClick={() => {
                                                    window.open(`/business/details/${e.id}`);
                                                }}
                                            >
                                                {e.id}
                                            </Anchor>
                                        </td>
                                        <td
                                            className={`center ${this.props.columns.find(c => c.slug === "CREATED_AT" && !c.visible) ? "d-none" : ""}`}
                                            title={e.createdAt}
                                        >
                                            {e.createdAt}
                                        </td>
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "TITLE" && !c.visible) ? "d-none" : ""}`}
                                            title={e.title}
                                        >
                                            {e.title}
                                        </td>
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "STEP" && !c.visible) ? "d-none" : ""}`}
                                            title={e.step}
                                        >
                                            {e.step.name}
                                        </td>
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "CLIENT" && !c.visible) ? "d-none" : ""}`}
                                            title={e.client.name}
                                        >
                                            {e.client.name}
                                        </td>
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "EMAIL" && !c.visible) ? "d-none" : ""}`}
                                            title={e.client.email}
                                        >
                                            {e.client.email}
                                        </td>
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "PHONE_ONE" && !c.visible) ? "d-none" : ""}`}
                                            title={e.client.phoneOne}
                                        >
                                            {e.client.phoneOne}
                                        </td>
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "CAMPAIGN" && !c.visible) ? "d-none" : ""}`}
                                            title={e.campaign}
                                        >
                                            {e.campaign}
                                        </td>
                                        <td
                                            className={`center ${this.props.columns.find(c => c.slug === "MIDIA_ORIGIN" && !c.visible) ? "d-none" : ""}`}
                                            title={e.midiaOrigin.name}
                                        >
                                            <i className={`${e.midiaOrigin.icon} iconStatusAdvancedBusiness`}></i>
                                        </td>
                                        <td
                                            className={`center ${this.props.columns.find(c => c.slug === "STATUS" && !c.visible) ? "d-none" : ""}`}
                                            title={e.status.name}
                                        >
                                            <i className={`${e.status.icon} iconStatusAdvancedBusiness`}></i>
                                        </td>
                                        <td
                                            className={`center ${this.props.columns.find(c => c.slug === "STATUS_DATE" && !c.visible) ? "d-none" : ""}`}
                                            title={e.statusDate}
                                        >
                                            {e.statusDate}
                                        </td>
                                        <td
                                            className={`center ${this.props.columns.find(c => c.slug === "SHOWEDUP" && !c.visible) ? "d-none" : ""}`}
                                            title={e.showedup?.name}
                                        >
                                            <i className={`${e.showedup?.icon} iconStatusAdvancedBusiness`}></i>
                                        </td>
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "ID_SERVICE_ERP" && !c.visible) ? "d-none" : ""}`}
                                            title={e.idServiceERP}
                                        >
                                            {e.idServiceERP}
                                        </td>
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "REASON_FOR_LOSS" && !c.visible) ? "d-none" : ""}`}
                                            title={e.reasonForLoss?.name}
                                        >
                                            {e.reasonForLoss?.name}
                                        </td>
                                        <td
                                            className={`right ${this.props.columns.find(c => c.slug === "MONEY" && !c.visible) ? "d-none" : ""}`}
                                            title={e.money}
                                        >
                                            {e.money}
                                        </td>
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "PROSPECTION_OWNER" && !c.visible) ? "d-none" : ""}`}
                                            title={e.prospectionOwner?.name}
                                        >
                                            {e.prospectionOwner?.name}
                                        </td>
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "ACTIVITY_CREATED_BY" && !c.visible) ? "d-none" : ""}`}
                                            title={e.activityCreatedBy?.name}
                                        >
                                            {e.activityCreatedBy?.name}
                                        </td>
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "OWNER" && !c.visible) ? "d-none" : ""}`}
                                            title={e.owner?.name}
                                        >
                                            {e.owner?.name}
                                        </td>
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "COMPANY_UNIT" && !c.visible) ? "d-none" : ""}`}
                                            title={e.companyUnit.name}
                                        >
                                            {e.companyUnit.name}
                                        </td>
                                        <td
                                            className={`${this.props.columns.find(c => c.slug === "STAMP" && !c.visible) ? "d-none" : ""}`}
                                            title={e.stamp.name}
                                        >
                                            {e.stamp.name}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default withRouter(connect(null, null)(TableEditAdvancedBusiness));