import {
    NEW_COMMISSION_RULE,
    CHANGE_COMMISSION_RULE,
    GET_ALL_COMMISSION_RULE,
    GET_ALL_COMMISSION_REPORT,
    NEW_REPORT_COMMISSION_RULE,
    DELETE_REPORT_COMMISSION_RULE,
    TOGGLE_CHECKED_ALL_COMMISSION_RULE,
    GET_ALL_COMMISSION_REPORT_INTERVALS,
    CHANGE_STATUS_DELETED_REPORT_COMMISSION_RULE,
    CHANGE_STATUS_INSERTED_REPORT_COMMISSION_RULE,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_RULE,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_REPORT,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_REPORT_INTERVALS
}  from "../../core/constants";

//action creator
export function getAllCommissionRules ()
{
    return {
        type: GET_ALL_COMMISSION_RULE
    }
}

//action creator
export function newCommissionRule (data)
{
    return {
        type: NEW_COMMISSION_RULE,
        payload: data,
    }
}

//action creator
export function newReportCommissionRule (data)
{
    return {
        type: NEW_REPORT_COMMISSION_RULE,
        payload: data,
    }
}

//action creator
export function deleteReportCommissionRule (data)
{
    return {
        type: DELETE_REPORT_COMMISSION_RULE,
        payload: data,
    }
}

//action creator
// export function newDealBusiness (data)
// {
//     return {
//         type: NEW_COMMISSION_RULE,
//         payload: data,
//     }
// }

//action creator
export function changeCommissionRule (data)
{
    return {
        type: CHANGE_COMMISSION_RULE,
        payload: data,
    }
}

//action creator
export function toggleCheckedAllCommissionRules (status)
{
    return {
        type: TOGGLE_CHECKED_ALL_COMMISSION_RULE,
        payload: status
    }
}

//action creator
export function changeStatusGetFromDatabaseCompletedCommissionRules (status)
{
    return {
        type: CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_RULE,
        payload: status
    }
}

//action creator
export function changeStatusInsertedReportCommissionRules (status)
{
    return {
        type: CHANGE_STATUS_INSERTED_REPORT_COMMISSION_RULE,
        payload: status
    }
}

//action creator
export function changeStatusDeletedReportCommissionRules (status)
{
    return {
        type: CHANGE_STATUS_DELETED_REPORT_COMMISSION_RULE,
        payload: status
    }
}

//action creator
export function getAllCommissionReport ()
{
    return {
        type: GET_ALL_COMMISSION_REPORT
    }
}

//action creator
export function changeStatusGetFromDatabaseCompletedCommissionReport (status)
{
    return {
        type: CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_REPORT,
        payload: status
    }
}

//action creator
export function getAllCommissionReportIntervals ()
{
    return {
        type: GET_ALL_COMMISSION_REPORT_INTERVALS
    }
}

//action creator
export function changeStatusGetFromDatabaseCompletedCommissionReportIntervals (status)
{
    return {
        type: CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_REPORT_INTERVALS,
        payload: status
    }
}