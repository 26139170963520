import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class ServiceQueueReasonsForChangeFrozenController
{
    async create(data) 
    {
        const response = await api.serviceQueueReasonsForChangeFrozen().post(data);
        return apiCallbackTreatment(response);
    }

    async delete(id)
    {
        const response = await api.serviceQueueReasonsForChangeFrozen().remove(id);
        return apiCallbackTreatment(response);
    }

    async get() 
    {
        const response = await api.serviceQueueReasonsForChangeFrozen().get();
        return apiCallbackTreatment(response);
    }

    async getByCompanyUnit(idCompanyUnit) 
    {
        const response = await api.serviceQueueReasonsForChangeFrozen().getByCompanyUnit(idCompanyUnit);
        return apiCallbackTreatment(response);
    }

    async getById(id) 
    {
        const response = await api.serviceQueueReasonsForChangeFrozen().getById(id);
        return apiCallbackTreatment(response);
    }

    async update(id, data) 
    {
        const response = await api.serviceQueueReasonsForChangeFrozen().update(id, data);
        return apiCallbackTreatment(response);
    }
}