import { Component } from "react";

import "./Service.css";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { APPLICATION_NAME } from "../../core/constants";

import Header from "../../components/CRM/Header";
import Menus from "../../components/general/Menus";
import Box from "../../components/general/Box";
import Message from "../../components/general/Message";
import ServiceForm from "../../components/CRM/ServiceForm";


const initialState = {
    tabs: [
        {
            option: 10,
            name: "Dados do Cliente"
        },
        {
            option: 11,
            name: "Dados do Resp. Financeiro"
        },
        {
            option: 12,
            name: "Dados do Serviço"
        },
        {
            option: 13,
            name: "Dados do Contrato"
        },
        {
            option: 14,
            name: "Valor do Serviço"
        },
        {
            option: 15,
            name: "Financeiro"
        },
    ],
    activeTab: 10,

    // START MODAL
    message_type: "information",
    message_show: false,
    message: "",
    // END MODAL

}

class Service extends Component 
{

    state = { ...initialState }

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Serviços`;
    }

    componentDidUpdate = async (prevProps, prevState) =>
    {
        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.services.toView) 
            {
                this.props.history.push(`/activities`);
            }
		}
    }
    
    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render()
    {
        return (
            <div className="service">
                <Header title="Serviços" />

                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />

                <div className="container scrollService mt-3">
                    <Box>

                        <Menus
                            menus={this.state.tabs}
                            active={this.state.activeTab}
                            onChange={(option) => this.setState({activeTab: option})}
                        />

                        <ServiceForm
                            activeTab={this.state.activeTab}
                        />
                    </Box>
                </div>

            </div>
        )
    }
}

function mapStateToProps (state) 
{
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;
    const permissions = state.permissions;
    const { user } = state.auth;
    
    return {
        hasPermissionsLoaded,
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Service));