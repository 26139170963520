// general
export const APPLICATION_NAME = "CRM";
export const commissionRule = "commissionRule";
export const commissionRuleProspection = "commissionRuleProspection";
export const commissionRuleIntervals = "commissionRuleIntervals";
export const permissionGroup = "permissionGroup";
export const unitCompany = "unitCompany";
export const campaign = "campaign";
export const absenceAttendants = "absenceAttendants";
export const optionsFrequencyTypePhone = [{value: "COMMERCIAL",name: "Comercial"},{value: "RESIDENTIAL",name: "Residencial"},{value: "CELLPHONE",name: "Celular"},{value: "OTHER",name: "Outro"}];
export const optionsFrequencyTypeMail = [{value: "COMMERCIAL",name: "Comercial"},{value: "RESIDENTIAL",name: "Residencial"},{value: "OTHER",name: "Outro"}];
export const optionsTypesStampsLead = [{name: "LEAD QUENTE",value: "HOT_LEAD",style: "error"},{name: "LEAD MORNO",value: "WARM_LEAD",style: "warning"},{name: "LEAD FRIO",value: "COLD_LEAD",style: "focus"}];
export const colors = [{style: "success"},{style: "error"},{style: "warning"},{style: "focus"},{style: "purple"}];
export const optionsStatusUnitCompany = [{value: 1,name: "ATIVA"},{value: 2,name: "INATIVA"}];
export const months = [{order: 0,initials: "Jan", month: "Janeiro"},{order: 1,initials: "Fev", month: "Fevereiro"},{order: 2,initials: "Mar", month: "Março"},{order: 3,initials: "Abr", month: "Abril"},{order: 4,initials: "Mai", month: "Maio"},{order: 5,initials: "Jun", month: "Junho"},{order: 6,initials: "Jul", month: "Julho"},{order: 7,initials: "Ago", month: "Agosto"},{order: 8,initials: "Set", month: "Setembro"},{order: 9,initials: "Out", month: "Outubro"},{order: 10,initials: "Nov", month: "Novembro"},{order: 11,initials: "Dez", month: "Dezembro"},];
export const days = [{order: 0,dayUSA: "Sunday",dayBRA: "Domingo"},{order: 1,dayUSA: "Monday",dayBRA: "Segunda-Feira"},{order: 2,dayUSA: "Tuesday",dayBRA: "Terça-Feira"},{order: 3,dayUSA: "Wednesday",dayBRA: "Quarta-Feira"},{order: 4,dayUSA: "Thursday",dayBRA: "Quinta-Feira"},{order: 5,dayUSA: "Friday",dayBRA: "Sexta-Feira"},{order: 6,dayUSA: "Saturday",dayBRA: "Sábado"},];
export const allComponents = [];
export const PHONE_TYPE_ACTIVITY = "PHONE";
export const MEETING_TYPE_ACTIVITY = "MEETING";
export const VISIT_TYPE_ACTIVITY = "VISIT";
export const DEADLINE_TYPE_ACTIVITY = "DEADLINE";
export const MAIL_TYPE_ACTIVITY = "MAIL";
export const LUNCH_TYPE_ACTIVITY = "LUNCH";
export const TASK_TYPE_ACTIVITY = "TASK";
export const HISTORIC_TYPE_ACTIVITY = "HISTORIC";
export const TRANSFER_TYPE_ACTIVITY = "TRANSFER";
export const FILES_TYPE_ACTIVITY = "FILES";
export const STATUS_BUSINESS_GAIN = "GAIN";
export const STATUS_BUSINESS_LOSS = "LOSS";
export const STATUS_BUSINESS_PAUSED = "PAUSED";
export const PDF = "PDF";
export const XLS = "XLS";
export const PNG = "PNG";
export const JPG = "JPG";
export const DOC = "DOC";
export const DOCX = "DOCX";
export const TEXT = "TEXT";
export const DOCUMENT = "DOCUMENT";
export const IMAGE = "IMAGE";
export const AUDIO = "AUDIO";
export const AUDIO_RECORDING = "AUDIO_RECORDING";
export const GENERAL = "GENERAL";
export const AUDIO_NO_LISTENED = "AUDIO_NO_LISTENED";

export const ID_BRAND_USE_GROUP_1_ON_REASONS_FOR_LOSS = [1,2,3,4,5,6];
export const ID_BRAND_USE_GROUP_2_ON_REASONS_FOR_LOSS = 8;
export const ID_BRAND_USE_GROUP_3_ON_REASONS_FOR_LOSS = 9;
export const ID_BRAND_NOT_VERIFY_DATE_START_VISIT = 8;

// details type
export const business = "business";
export const businessByClient = "businessByClient";
export const lead = "lead";
export const contact = "contact";
export const activity = "activity";
export const person = "person";
export const owner = "owner";
export const note = "note";
export const commissionReport = "commissionReport";
export const commissionReportIntervals = "commissionReportIntervals";

// start redux constants
// start redux constants
// start redux constants
// general
export const SHOW_MODAL_MORE_ACTIONS_GENERAL = "SHOW_MODAL_MORE_ACTIONS_GENERAL";
export const SET_MODAL_MESSAGE = "SET_MODAL_MESSAGE";
export const SET_CURRENT_SCREEN_CRM = "SET_CURRENT_SCREEN_CRM";

// auth
export const SET_AUTHENTICATE = "SET_AUTHENTICATE";
export const GET_AUTHENTICATE = "GET_AUTHENTICATE";
export const SET_USER_LOGOUT = "SET_USER_LOGOUT";
export const SET_SOCKETS_MAIN_ONLINE = "SET_SOCKETS_MAIN_ONLINE";
export const SET_SOCKETS_MAIN_ONLINE_UPDATED = "SET_SOCKETS_MAIN_ONLINE_UPDATED";

// business
export const NEW_DEAL_BUSINESS = "NEW_DEAL_BUSINESS";
export const CHANGE_DEAL_BUSINESS = "CHANGE_DEAL_BUSINESS";
export const CHANGE_MODE_VIEW_BUSINESS = "CHANGE_MODE_VIEW_BUSINESS";
export const GET_ALL_DRAGGABLES_BUSINESS = "GET_ALL_DRAGGABLES_BUSINESS";
export const TOGGLE_CHECKED_ALL_BUSINESS = "TOGGLE_CHECKED_ALL_BUSINESS";
export const CHANGE_STATUS_GET_FROM_DATABASE_ALL_BUSINESS = "CHANGE_STATUS_GET_FROM_DATABASE_ALL_BUSINESS";
export const GET_ALL_BUSINESS_FILTERS = "GET_ALL_BUSINESS_FILTERS";

// business services
export const ALL_EXCEPT_NEW_SERVICE = "ALL_EXCEPT_NEW_SERVICE";
export const FINANCING_SERVICE = "FINANCING_SERVICE";
export const CREDIT_CARD_SERVICE = "CREDIT_CARD_SERVICE";
export const BANK_LOAN_SERVICE = "BANK_LOAN_SERVICE";
export const OVERDRAFT_SERVICE = "OVERDRAFT_SERVICE";
export const REMAINING_BALANCE_SERVICE = "REMAINING_BALANCE_SERVICE";
export const NEW_SERVICE = "NEW_SERVICE";

// leads
export const GET_ALL_USERS_LEADS = "GET_ALL_USERS_LEADS";
export const CHANGE_USER_LEADS = "CHANGE_USER_LEADS";
export const TOGGLE_CHECKED_ALL_USERS_LEADS = "TOGGLE_CHECKED_ALL_USERS_LEADS";
export const CHANGE_STATUS_GET_FROM_DATABASE_ALL_LEADS = "CHANGE_STATUS_GET_FROM_DATABASE_ALL_LEADS";

// activities
export const GET_ALL_ACTIVITIES = "GET_ALL_ACTIVITIES";
export const CHANGE_TASK_ACTIVITIES = "CHANGE_TASK_ACTIVITIES";
export const TOGGLE_CHECKED_ALL_ACTIVITIES = "TOGGLE_CHECKED_ALL_ACTIVITIES";
export const CHANGE_STATUS_GET_FROM_DATABASE_ALL_ACTIVITIES = "CHANGE_STATUS_GET_FROM_DATABASE_ALL_ACTIVITIES";

// owners
export const GET_ALL_USERS_OWNERS = "GET_ALL_USERS_OWNERS";
export const SYNC_ALL_USERS_OWNERS = "SYNC_ALL_USERS_OWNERS";
export const CHANGE_USER_OWNERS = "CHANGE_USER_OWNERS";
export const TOGGLE_CHECKED_ALL_USERS_OWNERS = "TOGGLE_CHECKED_ALL_USERS_OWNERS";
export const CHANGE_STATUS_GET_FROM_DATABASE_ALL_OWNERS = "CHANGE_STATUS_GET_FROM_DATABASE_ALL_OWNERS";

// company
export const NEW_UNIT_COMPANY = "NEW_UNIT_COMPANY";
export const GET_ALL_UNIT_COMPANY = "GET_ALL_UNIT_COMPANY";
export const CHANGE_UNIT_COMPANY = "CHANGE_UNIT_COMPANY";
export const TOGGLE_CHECKED_ALL_UNIT_COMPANY = "TOGGLE_CHECKED_ALL_UNIT_COMPANY";

// permissions
export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS";
export const SET_EDIT_PERMISSION_GROUP = "SET_EDIT_PERMISSION_GROUP";

// commission rules
export const GET_ALL_COMMISSION_RULE = "GET_ALL_COMMISSION_RULE";
export const NEW_COMMISSION_RULE = "NEW_COMMISSION_RULE";
export const NEW_REPORT_COMMISSION_RULE = "NEW_REPORT_COMMISSION_RULE";
export const DELETE_REPORT_COMMISSION_RULE = "DELETE_REPORT_COMMISSION_RULE";
export const CHANGE_COMMISSION_RULE = "CHANGE_COMMISSION_RULE";
export const CHANGE_STATUS_INSERTED_REPORT_COMMISSION_RULE = "CHANGE_STATUS_INSERTED_REPORT_COMMISSION_RULE";
export const TOGGLE_CHECKED_ALL_COMMISSION_RULE = "TOGGLE_CHECKED_ALL_COMMISSION_RULE";
export const CHANGE_STATUS_DELETED_REPORT_COMMISSION_RULE = "CHANGE_STATUS_DELETED_REPORT_COMMISSION_RULE";
export const CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_RULE = "CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_RULE";

// commission report
export const GET_ALL_COMMISSION_REPORT = "GET_ALL_COMMISSION_REPORT";
export const GET_ALL_COMMISSION_REPORT_INTERVALS = "GET_ALL_COMMISSION_REPORT_INTERVALS";
export const CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_REPORT = "CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_REPORT";
export const CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_REPORT_INTERVALS = "CHANGE_STATUS_GET_FROM_DATABASE_ALL_COMMISSION_REPORT_INTERVALS";

//permissions
export const ACTIVITY = "ACTIVITY";
export const ADVANCED_BUSINESS = "ADVANCED_BUSINESS";
export const ANALYSIS = "ANALYSIS";
export const BUSINESS = "BUSINESS";
export const CAMPAIGN = "CAMPAIGN";
export const COMMISSION_RULES = "COMMISSION_RULES";
export const COMMISSION_RULES_PROSPECTION = "COMMISSION_RULES_PROSPECTION";
export const COMMISSION_RULES_INTERVALS = "COMMISSION_RULES_INTERVALS";
export const COMPANY = "COMPANY";
export const UNIT_COMPANY = "UNIT_COMPANY";
export const DASHBOARD = "DASHBOARD";
export const DASHBOARD_MONTHS = "DASHBOARD_MONTHS";
export const DASHBOARD_PROSPECTION = "DASHBOARD_PROSPECTION";
export const DASHBOARD_MARKETING = "DASHBOARD_MARKETING";
export const GAIN_STATUS = "GAIN_STATUS";
export const CLIENT = "CLIENT";
export const LEAD_OWNERS = "LEAD_OWNERS";
export const LOSS_STATUS = "LOSS_STATUS";
export const OWNERS = "OWNERS";
export const PAUSED_STATUS = "PAUSED_STATUS";
export const PERMISSIONS = "PERMISSIONS";
export const REPORT = "REPORT";
export const SERVICES = "SERVICES";
export const USER = "USER";
export const NULL_STATUS = "NULL_STATUS";
export const SERVICE_QUEUE = "SERVICE_QUEUE";
export const PRE_SALE_SERVICE_QUEUE = "PRE_SALE_SERVICE_QUEUE";
export const ABSENCE_ATTENDANTS = "ABSENCE_ATTENDANTS";
export const WHATSAPP = "WHATSAPP";
export const PHONE = "PHONE";
export const CONNECTION_LOG = "CONNECTION_LOG";
export const DEBUG = "DEBUG";
export const CHAT = "CHAT";
export const TRAINING = "TRAINING";
export const RUN_CHAT = "RUN_CHAT";
export const LIST_ALL = "LIST_ALL";
export const LIST_ALL_TO_CONNECT_UP = "LIST_ALL_TO_CONNECT_UP";
export const LIST_ALL_COMMISSION = "LIST_ALL_COMMISSION";
export const LIST_ALL_COMMISSION_SPECIALIST = "LIST_ALL_COMMISSION_SPECIALIST";
export const LIST_ALL_COMMISSION_MANAGER = "LIST_ALL_COMMISSION_MANAGER";
export const LIST_ALL_COMMISSION_ATTENDANT = "LIST_ALL_COMMISSION_ATTENDANT";
export const LIST_ALL_COMMISSION_SUPERVISOR = "LIST_ALL_COMMISSION_SUPERVISOR";
export const LIST_ALL_COMMISSION_INTERVALS = "LIST_ALL_COMMISSION_INTERVALS";
export const MODAL = "MODAL";
export const SCREEN = "SCREEN";

export const defaultPermissions =  {
    toView: false,
    toViewAll: false,
    toInsert: false,
    toUpdate: false,
    toDelete: false,
    toFile: false
}

// service queue
export const GET_ALL_SERVICE_QUEUE = "GET_ALL_SERVICE_QUEUE";
export const SET_LAST_POSITION_SERVICE_QUEUE = "SET_LAST_POSITION_SERVICE_QUEUE";
export const SET_FROZEN_SERVICE_QUEUE = "SET_FROZEN_SERVICE_QUEUE";
export const CHANGE_SERVICE_QUEUE = "CHANGE_SERVICE_QUEUE";
export const CHANGE_STATUS_ALL_SPECIALISTS_UPDATED_SERVICE_QUEUE = "CHANGE_STATUS_ALL_SPECIALISTS_UPDATED_SERVICE_QUEUE";
export const CHANGE_STATUS_FROZEN_SPECIALIST_UPDATED_SERVICE_QUEUE = "CHANGE_STATUS_FROZEN_SPECIALIST_UPDATED_SERVICE_QUEUE";
export const CHANGE_STATUS_LAST_POSITION_SPECIALIST_UPDATED_SERVICE_QUEUE = "CHANGE_STATUS_LAST_POSITION_SPECIALIST_UPDATED_SERVICE_QUEUE";

// campaign
export const GET_ALL_CAMPAIGN = "GET_ALL_CAMPAIGN";
export const CHANGE_CAMPAIGN = "CHANGE_CAMPAIGN";
export const NEW_CAMPAIGN = "NEW_CAMPAIGN";
export const CHANGE_STATUS_GET_FROM_DATABASE_ALL_CAMPAIGN = "CHANGE_STATUS_GET_FROM_DATABASE_ALL_CAMPAIGN";
export const TOGGLE_CHECKED_ALL_CAMPAIGN = "TOGGLE_CHECKED_ALL_CAMPAIGN";

// absence
export const NEW_ATTENDANT_ABSENCE = "NEW_ATTENDANT_ABSENCE";

// ends redux constants
// ends redux constants
// ends redux constants

// start views mode Business.jsx
// start views mode Business.jsx
// start views mode Business.jsx
export const modeBusinessDraggable = "draggable";
export const modeBusinessList = "list";
export const modeBusinessDetails = "details";
// ends views mode Business.jsx
// ends views mode Business.jsx
// ends views mode Business.jsx

// start general messages
export const unauthorizedDefaultMessage = "Sessão expirada.";
export const badRequestDefaultMessage = "Solicitação ruim.";
export const serverErrorDefaultMessage = "Erro interno do servidor.";
export const unknowApiErrorDefaultMessage = "Erro desconhecido.";
// ends general messages

// start permission group identifiers
export const IDS_PERMISSIONS_GROUP_MANAGERS = [3, 5, 6, 8, 9, 19, 20, 28, 31, 33, 34];
export const IDS_PERMISSIONS_GROUPS_NEED_CHANGE_PASSWORD_EVERY_MONDAY = [3, 7, 10];
export const ID_PERMISSIONS_GROUP_MANAGER = 3;
export const ID_PERMISSIONS_GROUP_SPECIALIST = 7;
export const ID_PERMISSIONS_GROUP_PROSPECTION_COORDINATORS = [1, 17, 25];
export const ID_PERMISSIONS_GROUP_PROSPECTION_SUPERVISOR = [11, 18, 24];
export const ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT = [2, 23, 27];
export const ID_PERMISSIONS_GROUP_PROSPECTION_ATTENDANT_AND_SPECIALIST = 16;
export const ID_PERMISSIONS_GROUP_PROSPECTION_MANAGERS = [14];
export const ID_PERMISSIONS_GROUP_TRAINING = [30];
// ends permission group identifiers

// start source media that can be started within campaigns (with START and STOP)
export const IDS_MIDIA_ORIGIN_POSSIBLE_RUN_FOR_CAMPAIGN = [32];
// ends source media that can be started within campaigns (with START and STOP)

// start default stamps
export const ID_STAMP_IS_ALREADY_CLIENT = 9;
// ends default stamps

// start crm-socket variables
export const EVENT_SERVICE_QUEUE_ALL = "EVENT_SERVICE_QUEUE_ALL";
export const EVENT_SERVICE_QUEUE_UPDATED = "EVENT_SERVICE_QUEUE_UPDATED";
export const EVENT_COMMISSION_RULES_NEW_REPORT = "EVENT_COMMISSION_RULES_NEW_REPORT";
export const EVENT_COMMISSION_RULES_DELETE_REPORT = "EVENT_COMMISSION_RULES_DELETE_REPORT";
export const EVENT_COMMISSION_RULES_UPDATED_REPORT = "EVENT_COMMISSION_RULES_UPDATED_REPORT";
export const EVENT_CONNECTION_OPEN = "EVENT_CONNECTION_OPEN";
export const EVENT_CONNECTION_ESTABLISHED = "EVENT_CONNECTION_ESTABLISHED";
export const EVENT_CONNECTION_UPDATED = "EVENT_CONNECTION_UPDATED";
export const EVENT_CONNECTION_SURVIVAL_PACKAGE = "EVENT_CONNECTION_SURVIVAL_PACKAGE";
export const TIME_INTERVAL_SURVIVAL_PACKAGES_SEND_MINIMUM = 1000 * 60 * 5; //DANGER: must be half the value of TIME_INTERVAL_SURVIVAL_PACKAGES_SEND_MAXIMUM (crm-socket)
// ends crm-socket variables

// start views mode AdvancedBusiness.jsx
export const ADVANCED_BUSINESS_OPTION_SELECTED_COLUMNS = "ADVANCED_BUSINESS_OPTION_SELECTED_COLUMNS";
export const ADVANCED_BUSINESS_OPTION_SELECTED_FILTERS = "ADVANCED_BUSINESS_OPTION_SELECTED_FILTERS";
// ends views mode AdvancedBusiness.jsx

// start constants chat
export const EVENT_JOIN_ROOM = "JOIN_ROOM";
export const EVENT_USER_CONNECTED = "USER_CONNECTED";
export const EVENT_USER_DISCONNECTED = "USER_DISCONNECTED";
export const EVENT_STOP_MY_VIDEO = "STOP_MY_VIDEO";
export const EVENT_START_MY_VIDEO = "START_MY_VIDEO";
export const EVENT_STOP_MY_AUDIO = "STOP_MY_AUDIO";
export const EVENT_START_MY_AUDIO = "START_MY_AUDIO";
export const EVENT_SEND_MESSAGE = "SEND_MESSAGE";
// ends constants chat