import React, { useReducer, useEffect, useCallback, forwardRef, useImperativeHandle, useState, useRef } from "react";
import "./Wizard.css";

const pointer = (state, action) => 
{
	const { steps, activePageIndex } = state;
	switch (action.type) {
		case 'NEXT_PAGE':
		const nextPageIndex = activePageIndex + 1;
		if (nextPageIndex < steps) {
			return { ...state, activePageIndex: nextPageIndex };
		}
		return state;

		case 'PREV_PAGE':
		if (activePageIndex > 0) {
			return { ...state, activePageIndex: activePageIndex - 1 };
		}
		return state;

		case 'GO_TO_PAGE':
		return { ...state, activePageIndex: action.payload };

		case 'UPDATE_STEPS':
		return { ...state, steps: action.payload };

		default:
		return state;
	}
};

const Wizard = forwardRef(({ children, allVisited = false }, ref) => 
{
	const countPages = React.Children.toArray(children);

	const initialVisitedPages = allVisited 
		? new Set(countPages.map((_, index) => index)) 
		: new Set([0]);

	const [state, dispatch] = useReducer(pointer, { activePageIndex: 0, steps: countPages.length });
	const [visitedPages, setVisitedPages] = useState(initialVisitedPages);
	const hasCalledOnCurrentRef = useRef(new Set());

	const currentStep = countPages[state.activePageIndex];

	useEffect(() => 
	{
		dispatch({ type: 'UPDATE_STEPS', payload: countPages.length });

		if (state.activePageIndex >= countPages.length) {
		dispatch({ type: 'GO_TO_PAGE', payload: countPages.length - 1 });
		}
	}, [countPages.length, state.activePageIndex]);

	useEffect(() => 
	{
		if (currentStep?.props?.onCurrent && !hasCalledOnCurrentRef.current.has(state.activePageIndex)) {
		currentStep.props.onCurrent();
		hasCalledOnCurrentRef.current.add(state.activePageIndex);
		}
	}, [currentStep, state.activePageIndex]);

	useEffect(() => 
	{
		setVisitedPages((prev) => 
		{
			if (!prev.has(state.activePageIndex)) {
				const newSet = new Set(prev);
				newSet.add(state.activePageIndex);
				return newSet;
			}
			return prev;
		});
	}, [state.activePageIndex]);

	const goNextPage = useCallback(() => 
	{
		const currentStep = countPages[state.activePageIndex];
		if (React.isValidElement(currentStep) && currentStep.props.onNext)
		{
			currentStep.props.onNext();
		}
		dispatch({ type: 'NEXT_PAGE' });
	}, [state.activePageIndex, countPages]);

	const goPrevPage = useCallback(() => 
	{
		dispatch({ type: 'PREV_PAGE' });
	}, []);

	const goSpecificPage = useCallback((pageIndex) => 
	{
		if (visitedPages.has(pageIndex))
		{
			dispatch({ type: 'GO_TO_PAGE', payload: pageIndex });
		}
	}, [visitedPages]);

	useImperativeHandle(ref, () => ({
		data: {
			totalPage: countPages.length,
			currentPage: state.activePageIndex + 1,
		},
		actions: {
			goNextPage,
			goPrevPage,
			goSpecificPage,
		},
	}));

	const CurrentPage = countPages[state.activePageIndex];
	return (
		<div className="containerWizard">
			<div className="progressBarWizard">
				{countPages.map((child, index) => (
					<React.Fragment key={index}>
						<div
							className={`boxStepWizard ${visitedPages.has(index) ? 'visited' : ''}`}
							onClick={() => goSpecificPage(index)}
						>
							<div
								className={`progressStepWizard ${index <= state.activePageIndex ? 'active' : ''}`}
							>
								{index + 1}
							</div>
							<span>
								{React.isValidElement(child) && child.props.title ? child.props.title : ''}
							</span>
						</div>
						{index < countPages.length - 1 && (
							<div
								className={`progressConnectorWizard ${index < state.activePageIndex ? 'active' : ''}`}
							/>
						)}
					</React.Fragment>
				))}
			</div>
			<div className="containerCurrentPageWizard">
				{CurrentPage}
			</div>
		</div>
	);
});

export default Wizard;
