import React, { Component } from "react";
import "./CommissionReport.css";
import { connect } from "react-redux";
import {
    formatedDateTimeToUSA,
    getDateTimeCurrentBrowserAtMidnight,
    getFullNameFisrtCharsUppercase,
    getOptionsSelectCheckedFormated,
    sortInAlphabeticalOrder,
} from "../../auxiliary/generalFunctions";
import {
    APPLICATION_NAME, ID_PERMISSIONS_GROUP_MANAGER
} from "../../core/constants";
import { getAllCommissionReport } from "../../store/actions/commission";

import Header from "../../components/CRM/Header";
import ButtonIcon from "../../components/general/ButtonIcon";
import SelectMulti from "../../components/general/SelectMulti";
import Message from "../../components/general/Message";

import TableEditCommissionReport from "../../components/general/TableEditCommissionReport";
import UserController from "../../controllers/UserController";
import InputGeneral from "../../components/general/InputGeneral";
import { withRouter } from "react-router-dom";

const initialState = {

    allCommissionReport: [],
    allCommissionReportNotFiltered: [],
    allCommissionReportUpdated: false,
    allCommissionReportInitialLoaded: false,
    filtered: false,

    optionsOwnersLead: [],
    optionsOwnersLeadNotFiltered: [],
    optionsUnitCompany: [],

    // start modal message
    message_type: "information",
    message_show: false,
    message: "",
    // ends modal message

    currentMonthYear: "", //looks like: "2022-04"

}

const userController = new UserController();

class CommissionReport extends Component
{
    state = { ...initialState }

    componentDidMount = async () =>
    {
        document.title = `${APPLICATION_NAME} - Relatório de Comissão`;

        let { params } = this.props.match;
        let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight()); //looks like "2022-04-01"

        if (params?.interval && params.interval !== undefined)
        {
            currentDate = params.interval; //looks like "2022-04"
        }

        await this.setCurrentMonthYear(currentDate);
        await this.getCompanyUnit();
        await this.getOwners();
        await this.props.getAllCommissionReport();
    }

    componentDidUpdate = async (prevProps, prevState) =>
    {
        // console.log("COMMISSION REPORT prevProps ", prevProps);
        // console.log("COMMISSION REPORT this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}

        if 
        (
            this.state.allCommissionReport.length === 0 && 
            this.props.allCommissionReport.length > 0 &&
            !this.state.allCommissionReportInitialLoaded
        )
        {
            let allCommissionReportNotFiltered = [];
            let allCommissionReportNotFilteredProps = this.props.allCommissionReport;
            for (const report of allCommissionReportNotFilteredProps) {
                if (report.idPermissionsGroup === ID_PERMISSIONS_GROUP_MANAGER || report.idPermissionsGroup === 31) { continue }
                allCommissionReportNotFiltered.push({ ...report });
            }
            let allCommissionReport = allCommissionReportNotFiltered;
            await this.setState({allCommissionReport, allCommissionReportNotFiltered, allCommissionReportInitialLoaded: true});
            await this.applyFilters();
        }
    }

    setCurrentMonthYear = async (currentDate) =>
	{
		let parts = currentDate.toString().split("-");

		let y = parseInt(parts[0]);
		let m = parseInt(parts[1]).toString().padStart(2, '0');

		let currentMonthYear = `${y}-${m}`;

		await this.setState({currentMonthYear});
	}

    setOwnersByUnitCompanyActive = async (optionsUnitCompany) =>
    {
        let optionsOwnersLead = await this.state.optionsOwnersLeadNotFiltered;
        let optionsUnitCompanyActive = optionsUnitCompany.filter(u => u.checked);
        let fromAllUnitCompany = (optionsUnitCompanyActive.length === 0) ? true : false;
        
        if (!fromAllUnitCompany)
        {
            optionsOwnersLead = optionsOwnersLead.filter(o => {
                let hasUitCompany = false;

                optionsUnitCompanyActive.forEach(u => {
                    if (o.unitCompanies.some(uo => uo.UNT_ID === u.value)) { hasUitCompany = true; }
                });

                if (hasUitCompany) { return o; }

                return false;
            });
        }

        await this.setState({optionsOwnersLead});
    }

    getOwners = async () => 
    {
        let optionsOwnersLead = [];
        let responseOwners = await userController.getOwnersForUserOnAccess(this.props.user.PER_ID);

        if (responseOwners.status)
        {
            let unitCompanies = responseOwners.data.data;

            for (let unit of unitCompanies)
            {
                let idUnitCompany = unit.UNT_ID;
                let nameUnitCompany = unit.UNT_NAME;
                let owners = unit.owners;

                for (let owner of owners)
                {
                    if (owner.PER_ID_PERMISSIONS_GROUP === ID_PERMISSIONS_GROUP_MANAGER) { continue }
                    
                    optionsOwnersLead.push({
                        id: owner.PER_ID,
                        name: getFullNameFisrtCharsUppercase(owner.PER_NAME),
                        value: owner.PER_ID,
                        checked: false,
                        unitCompanies: [{
                            UNT_ID: idUnitCompany,
                            UNT_NAME: nameUnitCompany
                        }]
                    });
                }                
            }
        }

        optionsOwnersLead = sortInAlphabeticalOrder(optionsOwnersLead);
        await this.setState({ optionsOwnersLead, optionsOwnersLeadNotFiltered: optionsOwnersLead });
    }

    getCompanyUnit = async () => 
    {
        let allCompanyUnitsAllowedUser = await this.props.user.unit_companies;
        let allCompanyUnits = [];

        for (const company of allCompanyUnitsAllowedUser)
        {
            allCompanyUnits.push({
                name: company.UNT_NAME,
                value: company.UNT_ID,
                checked: false
            });
        }
                
        await this.setState({optionsUnitCompany: allCompanyUnits});
    }

    getFirstOwnerAtQueueByCompanyUnit = async () =>
    {
        let idCompanyUnit = await this.state.idCurrentCompanyUnit;
        let optionOwnerSpecialistOrManagers = [];
        
        // getting just a service queue (of a company unit)
        if (this.props.user.unit_companies.length === 1)
        {
            let allSpecialistsNotFiltered = this.props.queue.allSpecialistsNotFiltered;
            let allSpecialists = [];
    
            allSpecialistsNotFiltered.forEach(u => {
                if (u.idCompanyUnit === idCompanyUnit)
                {
                    u.specialists.forEach(s => {
    
                        if (s.frozen === 2) //just the not-frozen specialists.
                        {
                            allSpecialists.push({
                                id: s.id,
                                name: s.name,
                                value: s.id,
                                checked: false
                            });
                        }
                    });
                }
            });

            optionOwnerSpecialistOrManagers.push(allSpecialists[0]);

            await this.setState({
                allSpecialistsNotFiltered,
                optionOwnerSpecialistOrManagers
            });          
        }
    }

    saveCommission = async () =>
    {
        await this.props.getAllCommissionReport();
        setTimeout(() => {
            this.setState({ allCommissionReport: [], allCommissionReportInitialLoaded: false });
        }, 1000);
    }

    applyFilters = async () => 
    {
        let allCommissionReport = [];
        let allCompanyUnitsAllowedUser = await this.props.user.unit_companies;
        let allCommissionReportNotFiltered = await this.state.allCommissionReportNotFiltered;
        let currentMonthYearActive = await this.state.currentMonthYear;
        let optionsUnitCompanyActive = await this.state.optionsUnitCompany.filter(u => u.checked);
        let optionsOwnersLeadActive = await this.state.optionsOwnersLead.filter(o => o.checked);
        let fromAllUnitCompany = (optionsUnitCompanyActive.length === 0) ? true : false;
        let fromAllOwners = (optionsOwnersLeadActive.length === 0) ? true : false;

        allCommissionReportNotFiltered.forEach(r => {
            
            if (r.month === currentMonthYearActive && allCompanyUnitsAllowedUser.some(u => u.UNT_ID === r.company.id))
            {
                let report = r;
                r.name = getFullNameFisrtCharsUppercase(r.name);

                if (!fromAllUnitCompany && !optionsUnitCompanyActive.some(c => c.value === r.company.id)) { report = null; }

                if (!fromAllOwners && !optionsOwnersLeadActive.some(o => o.id === r.id)) { report = null; }

                if (report){ allCommissionReport.push(report); }
            }

        });

        await this.setState({allCommissionReport});
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        // if (propertyName !== null)
        // {
        //     // asking the socket to make the change, as it will be possible to follow the status change even if the page is refreshed.
        //     if (propertyName === "status")
        //     {
        //         let status = newValue;
        //         let allCommissionRules = await this.state.allCommissionRules;
        //         let rule = allCommissionRules.filter(r => r.id === id)[0];
        //         let monthRule = parseInt(rule.month.toString().split("-")[1]);
        //         let monthCurrent = parseInt(formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight()).toString().split("-")[1]);

        //         if (monthRule >= monthCurrent)
        //         {
        //             this.message("error", "Não é possível gerar relatório de comissão dessa regra, apenas para as regras dos meses anteriores ao atual!");
        //             return;
        //         }

        //         // asking to generate...
        //         if (status === 1) //1 = "GENERATED", on view
        //         {
        //             this.props.newReportCommissionRule(id);
        //         }

        //         // asking to generate...
        //         if (status === 3) //3 = "NOT_GENERATED"
        //         {
        //             this.props.deleteReportCommissionRule(id);
        //         }

        //         newValue = 2; //WARNING: to force the LOADING...
        //     }

        //     // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
        //     await this.props.changeCommissionRule({[propertyName]: newValue, id, propertyName});
        //     await this.setState({allCommissionRulesUpdated: true});
        // }
    }

    saveValue = async (id, field, value) =>
    {
        // WARNING: for maintenance.
        // console.log(`id: ${id}, field: ${field}, value: ${value}`);
        // let result = {
        //     status: true,
        // }

        // result = await commissionRulesController.update(id, {
        //     [field]: value
        // });
      
        // if (!result.status) 
        // {
        //     this.message("error", result.message);
        // }

        // await this.props.changeStatusGetFromDatabaseCompletedCommissionRules(false);
        // await this.props.getAllCommissionRules();
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render ()
    {
        return (
            <div className="commissionReport">
                <Header title="Relatório de Comissão - Especialistas" classaditional="headerCommissionReport" />
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="warningCommissionReport d-flex d-lg-none">
                    <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                </div>
                <div className="containerNavigationHeaderCommissionReport">
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderCommissionReport ${!this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            this.setState({filtered: false});
                        }}
                    />
                    <div className="filterHeaderCommissionReport d-none d-md-flex ml-auto">
                        <i className="fas fa-building iconfilterHeaderCommissionReport"></i>
                        <SelectMulti
                            classaditional="selectFilterHeaderCommissionReport"
                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsUnitCompany, "[Todas Unidades]", "unidades")}}
                            options={this.state.optionsUnitCompany} 
                            onChange={async (optionsUnitCompany) => {
                                await this.setState({optionsUnitCompany});
                                await this.setOwnersByUnitCompanyActive(optionsUnitCompany);
                                await this.applyFilters();
                            }}
                            withFieldSearch
                        />
                    </div>
                   <div className="filterHeaderCommissionReport d-none d-md-flex ml-2">
                        <i className="fas fa-user-friends iconfilterHeaderActivities"></i>
                        <SelectMulti
                            classaditional="selectFilterHeaderCommissionReport"
                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsOwnersLead, "[Todos Proprietários]", "proprietários")}}
                            options={this.state.optionsOwnersLead} 
                            onChange={async (optionsOwnersLead) => {
                                await this.setState({optionsOwnersLead});
                                await this.applyFilters();
                            }}
                            withFieldSearch
                        />
                    </div>
                    <div className={`filterStatusHeaderCommissionRules d-none d-lg-flex ml-2`}>
                        <InputGeneral
                            classaditional="inputFilterCommissionRules"
                            type="month"
                            value={this.state.currentMonthYear}
                            onChange={async (e) => {
                                await this.setCurrentMonthYear(e.target.value);
                                await this.applyFilters();
                            }}
                        />
                    </div>
                </div>
                <div className="containerListHeaderCommissionReport">
                    <TableEditCommissionReport
                        data={this.state.allCommissionReport}
                        onChangeValue={this.changeValue}
                        onSaveValue={this.saveValue}
                        onIgnoreSave={() => {}}
                        permissions={this.props.permissions}
                        onSaveCommission={this.saveCommission}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;
    const { allCommissionReport } = state.commission;
    const permissions = state.permissions.commissionReport;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;
    
    return {
        hasPermissionsLoaded,
        user,
        permissions,
        allCommissionReport
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        getAllCommissionReport ()
        {
            //action creator -> action
            const action = getAllCommissionReport();
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommissionReport));