import React, { Fragment, useState } from "react"; 
import "./ItemHistoric.css";
import {business, lead, contact, STATUS_BUSINESS_GAIN, STATUS_BUSINESS_LOSS, STATUS_BUSINESS_PAUSED} from "../../core/constants";
import {getInitialsName, getFullNameFisrtCharsUppercase} from "../../auxiliary/generalFunctions";
import CardDetailsBusiness from "./CardDetailsBusiness";
import CardDetailsContact from "./CardDetailsContact";

export default function ItemHistoric (props)
{ 
    const {data} = props;
    const {type} = props.data
    const [posX, setPosX] = useState("120");
    const [posY, setPosY] = useState("120");

    function getNameStatusBusiness(slug)
    {
        switch (slug) {
            case null:
                return "Em andamento";
            case STATUS_BUSINESS_GAIN:
                return "Ganho";
            case STATUS_BUSINESS_LOSS:
                return "Perdido";
            case STATUS_BUSINESS_PAUSED:
                return "Pausado";
            default:
                return "";
        }
    }

    function getIconStatusBusiness(slug)
    {
        switch (slug) {
            case null:
                return "far fa-running";

            case STATUS_BUSINESS_GAIN:
                return "fas fa-trophy-alt";

            case STATUS_BUSINESS_LOSS:
                return "fas fa-do-not-enter";

            case STATUS_BUSINESS_PAUSED:
                return "far fa-pause-circle";

            default:
                return "";
        }
    }

    function getDescriptionItem ()
    {
        switch (type) {
            case business:
                return ( 
                    <Fragment>
                        <span>{data.name}</span>
                        <br />
                        <span>R$ {data.value} - {data.companyUnit}</span>
                    </Fragment>
                )
            case lead:
                return <span>{data.name}</span>;
            case contact:
                return <span>{data.product}</span>;
            default:
                break;
        }
    }

    function getNameItem ()
    {
        switch (type) {
            case business:
                return <span>{data.item}</span>;
            case lead:
                return <span>{data.item}</span>;
            case contact:
                return <span>{getFullNameFisrtCharsUppercase(data.name)}</span>;
            default:
                break;
        }
    }

    function getIconItem ()
    {
        switch (type) {
            case business:
                return <i title={getNameStatusBusiness(data.status)} className={`far ${getIconStatusBusiness(data.status)} iconItemHistoric`}/>;
            case lead:
                return <i className="fal fa-bullseye-arrow iconItemHistoric"/>;
            case contact:
                return <span className="iconItemHistoric initialsName">{getInitialsName(data.name)}</span>;
            default:
                break;
        }

    }

    function setPosition (e) 
    {
        let posX = e.clientX;
        let posY = e.clientY;
        setPosX(posX);
        setPosY(posY);
    }

    return (
        <div className="row no-gutters itemHistoric"> 
            <div  
                className="col-2 colItemHistoric"
                >
                {getIconItem()}
            </div>
            <div className="col-10 colItemHistoric detailsItemHistoric">  
                <div className="nameItemHistoric" onMouseEnter={(e) => setPosition(e)}>  
                    {getNameItem()}
                    {
                        type === business &&
                        <CardDetailsBusiness 
                            data={data}
                            permissions={props.permissions.business} 
                            classaditional="cardDetailsItemHistoric"
                            style={{
                                top: posY,
                                left: posX
                            }}
                        /> 
                    }
                    {
                        type === contact &&
                        <CardDetailsContact 
                            data={data} 
                            classaditional="cardDetailsItemHistoric"
                            style={{
                                top: posY,
                                left: posX
                            }}
                        /> 
                    }
                </div>
                <div className="descriptionItemHistoric"> 
                    {getDescriptionItem()}
                </div>
            </div>
        </div>
    )
}