import API from "../api";
import { apiCallbackTreatment} from "../auxiliary/generalFunctions";

var api = new API();

export default class WorkShiftsPersonController
{
    async post (data)
    {
        const response = await api.workShiftsPerson().post(data);

        return apiCallbackTreatment(response);
    }

    async get ()
    {
        const response = await api.workShiftsPerson().get();

        return apiCallbackTreatment(response);
    }
}