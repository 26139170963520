import React, { Component } from "react";
import InputGeneral from "../general/InputGeneral";
import "./PermissionGroupForm.css";
import Button from "../general/Button";
import Select from "../general/Select";
import { showModalMoreActions } from "../../store/actions/general";
import { connect } from "react-redux";
import PermissionsGroupController from '../../controllers/PermissionsGroupController';
import { getFullNameFisrtCharsUppercase } from "../../auxiliary/generalFunctions";

const initialState = { 
    // start content name
    name: "",
    searchingName: false,
    warningName: false,
    noticesName: [],
    validName: true,
    // ends content name

    // start content status
    optionsChosenStatus: { value: 1, name: "Ativo" },
    optionsStatus: [ {value: 1,name: "Ativo"}, {value: 2,name: "Inativo"} ],
    // ends content status

    // start content button
    disabledButton: false
    // ends content button
}

const permissionsGroupController = new PermissionsGroupController();

class PermissionGroupForm extends Component
{
    state = {...initialState}

    componentDidMount = () => 
    {
        if (this.props.idPermissionGroupEdit !== null) 
        {
            this.getPermissionGroup();
        }
    }

    getPermissionGroup = async () =>
    {
        const result = await permissionsGroupController.getById(this.props.idPermissionGroupEdit);
        if (result.status)
        {
            this.setState({
                name: result.data.PEG_NAME,
                optionsChosenStatus: {
                    value: result.data.PEG_STATUS,
                    name: result.data.PEG_STATUS === 1 ? "Ativo" : "Inativo"
                }
            });
        }
    }

    nameChangeAndChecking = (name) =>
    {
        this.setState({searchingName: true, warningName: false, validName: true});
        this.setState({name});

        setTimeout(() => {
            this.setState({searchingName: false});
        }, 1000);
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        }
        this.props.showModalMoreActions(data);
    }

    onActionForm = async () => {

        if (this.state.name.trim() === "") 
        {
            return this.setState({ validName: false });
        }

        await this.setState({ disabledButton: true });

        const data = {
            PEG_NAME: getFullNameFisrtCharsUppercase(this.state.name),
            PEG_STATUS: this.state.optionsChosenStatus.value
        }

        let result = null;

        if (this.props.idPermissionGroupEdit === null) 
        {
            result = await permissionsGroupController.create(data);
        }
        else
        {
            result = await permissionsGroupController.update(this.props.idPermissionGroupEdit, data);
        }
        

        if (!result.status) 
        {
            await this.setState({ disabledButton: false });
            return alert(`ERRO: ${result.message}`);
        }

        await this.setState({ disabledButton: false });
        this.closeModalMoreActions();
        window.location.reload();
    }

    render () 
    {
        return (
            <div className="permissionGroupForm"> 
                <div className="row no-gutters linePermissionGroupForm">
                    <div className="col-12 columnPermissionGroupForm">
                        <InputGeneral
                            label="Nome"
                            value={this.state.name}
                            onChange={e => this.nameChangeAndChecking(e.target.value)}
                            searching={this.state.searchingName.toString()}
                            warning={this.state.warningName.toString()}
                            warningcontent={this.state.noticesName}
                            mandatory="true"
                            valid={this.state.validName.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters linePermissionGroupForm">
                    <div className="col-12 columnPermissionGroupForm">
                        <Select 
                            label="Status"
                            default={this.state.optionsChosenStatus}
                            options={this.state.optionsStatus} 
                            onChange={(optionsChosenStatus) => this.setState({optionsChosenStatus})}
                            mandatory
                        />
                    </div>
                </div>
                <div className="row no-gutters linePermissionGroupForm">
                    <div className="col-12 columnPermissionGroupForm">
                        <span className="mandatoryInformation">(*) campo com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterPermissionGroupForm">
                    <Button
                        classaditional="buttonPermissionGroupForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions() }
                        />
                    <Button
                        classaditional="buttonPermissionGroupForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                        disabled={this.state.disabledButton}
                    />                  
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;
    const { idPermissionGroupEdit } = state.permissions;

    return {
        idPermissionGroupEdit,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionGroupForm);