import { 
    GET_ALL_USERS_OWNERS,
    CHANGE_USER_OWNERS,
    TOGGLE_CHECKED_ALL_USERS_OWNERS,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_OWNERS,
    SYNC_ALL_USERS_OWNERS,
} from "../../core/constants";

const initialState = {
    allOwners: [],
    getAllOwnersFromDatabaseCompleted: true,
}

export default function ownersReducer (state = initialState, action)
{
    switch (action.type) {


        case GET_ALL_USERS_OWNERS:
            
            return {
                ...state,
                allOwners: action.payload,
                getAllOwnersFromDatabaseCompleted: true
            }

        case SYNC_ALL_USERS_OWNERS:
            
            return {
                ...state,
                allOwners: action.payload,
                getAllOwnersFromDatabaseCompleted: true
            }

        case CHANGE_USER_OWNERS:
            let {
                    name, 
                    phone, 
                    companyUnit, 
                    permissionGroup, 
                    password, 
                    date, 
                    owners, 
                    checked, 
                    mail, 
                    propertyName, 
                    id,
                    idUserERP,
                    typeWorkplace,
                    supervisor,
                    shift
                } = action.payload;

            let allOwnersFinal = state.allOwners.map(d => {
               
                if (d.id === parseInt(id))
                {
                    switch (propertyName) {
                        case "name":
                            d.name = name;
                            break;
                        case "phone":
                            d.phone = phone;
                            break;
                        case "password":
                            d.password = password;
                            break;
                        case "companyUnit":
                            d.companyUnit = companyUnit;
                            break;
                        case "permissionGroup":
                            d.permissionGroup = permissionGroup;
                            break;
                        case "date":
                            d.date = date;
                            break;
                        case "owners":
                            d.owners = [];
                            d.owners = owners;
                            break;
                        case "checked":
                            d.checked = checked;
                            break;
                        case "mail":
                            d.mail = mail;
                            break;
                        case "idUserERP":
                            d.idUserERP = idUserERP;
                            break;
                        case "typeWorkplace":
                            d.typeWorkplace = typeWorkplace;
                            break;
                        case "supervisor":
                            d.supervisor = supervisor;
                            break;
                        case "shift":
                            d.shift = shift;
                            break;
                        default: 
                    }                        
                }
                return d;
            });

            return {
                ...state,
                allOwners: allOwnersFinal
            }

        case TOGGLE_CHECKED_ALL_USERS_OWNERS:
            let status = action.payload;
            let allOwnersFinalChecked = state.allOwners.map(d => {
                d.checked = status;
                return d;
            });

            return {
                ...state,
                allOwners: allOwnersFinalChecked
            }

        case CHANGE_STATUS_GET_FROM_DATABASE_ALL_OWNERS:
                
            return {
                ...state,
                getAllOwnersFromDatabaseCompleted: action.payload
            }
    
        default:
            return state
    }
}