import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditCampaign.css";
import { 
    formatedMoneyBRA, 
    formatedDateTimeToUSA,
    formatedDateTimeToBRA,
    formatedTime, 
    getWindowDimensions
} from "../../auxiliary/generalFunctions";
import Select from "./Select";
import Button from "./Button";
import Check from "./Check";
import { IDS_MIDIA_ORIGIN_POSSIBLE_RUN_FOR_CAMPAIGN } from "../../core/constants";

const initialState = {

    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
    
}

class TableEditCampaign extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT CAMPAIGN prevProps ", prevProps);
        // console.log("TABLE EDIT CAMPAIGN this.props ", this.props);        
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    setPositionsDetails = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX, clientY} = e;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        clientX = clientX - 180;

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    render ()
    {
        return (
            <div className="tableEditCampaign">
                <label className="helpTableEditCampaign" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditCampaign" ref={headerTable => {this.headerTable = headerTable}}>
                            <td className="checkTableEditCampaign">
                                <Check
                                    classaditional={`buttonCheckTableEditCampaign ${(this.props.allCampaignChecked) ? "active" : ""}`}
                                    checked={this.props.allCampaignChecked}
                                    onClick={() => this.props.onToggleCheckedAllCampaign(!this.props.allCampaignChecked)}
                                />
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Nome da Campanha")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Nome da Campanha
                            </td>
                            <td
                                className="minimus100"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Mídia")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Mídia
                            </td>
                            <td
                                className="minimus100"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Unidade")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Unidade
                            </td>
                            <td
                                className="minimus100"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Data Inicial")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Data Inicial
                            </td>
                            <td
                                className="minimus100"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Data Final")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Data Final
                            </td>
                            <td
                                className="minimus100"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Horário Inicial")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Horário Inicial
                            </td>
                            <td
                                className="minimus100"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Horário Final")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Horário Final
                            </td>
                            <td
                                className="minimus100"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Inserções")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Inserções
                            </td>
                            <td
                                className="minimus140"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Valor Investido (R$)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Valor Investido (R$)
                            </td>
                            <td
                                className="minimus100"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Ações")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Ações
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props?.data.map((e, i) => {
                            
                                return (
                                    <tr key={`${i}`} className="tbodyTableEditCampaign">
                                        <td className="checkTableEditCampaign">
                                            <Check
                                                classaditional={`buttonCheckTableEditCampaign ${(e.checked) ? "active" : ""}`}
                                                checked={e.checked}
                                                onClick={async () => {
                                                    await this.props.onChangeValue(!e.checked, e.id, "checked");
                                                    this.props.hasAllCampaignChecked();
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditCampaign ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                value={e.name}
                                                placeholder={e.name}
                                                name="Nome da Campanha"
                                                onChange={value => this.props.onChangeValue(value, e.id, "name")}
                                                onPositiveClick={() => {}}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={50}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditCampaign ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                placeholder={e.nameMidiaOrigin}
                                                name="Mídia"
                                                onPositiveClick={() => {
                                                    let isItPossibleToRun = IDS_MIDIA_ORIGIN_POSSIBLE_RUN_FOR_CAMPAIGN.includes(e.idMidiaOrigin) ? 1 : 2;
                                                    this.props.onSaveValue(e.id, "CAM_ID_MIDIA_ORIGIN", e.idMidiaOrigin);
                                                    this.props.onSaveValue(e.id, "CAM_IS_IT_POSSIBLE_TO_RUN", isItPossibleToRun);
                                                }}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                optionsSelectMulti
                                                permissions={this.props.permissions}
                                            >
                                                <Select
                                                    default={{
                                                        name: e.nameMidiaOrigin,
                                                        value: e.idMidiaOrigin,
                                                        checked: true
                                                    }}
                                                    options={this.props.optionsMidiaOrigin} 
                                                    onChange={async (choosenMidiaOrigin) => {
                                                        this.props.onChangeValue(choosenMidiaOrigin.value, e.id, "idMidiaOrigin");
                                                        this.props.onChangeValue(choosenMidiaOrigin.name, e.id, "nameMidiaOrigin");
                                                    }}
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditCampaign ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                placeholder={e.nameCompanyUnit}
                                                name="Unidade"
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CAM_ID_COMPANY_UNIT", e.idCompanyUnit)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                optionsSelectMulti
                                                permissions={this.props.permissions}
                                            >
                                                <Select
                                                    default={{
                                                        name: e.nameCompanyUnit,
                                                        value: e.idCompanyUnit,
                                                        checked: true
                                                    }}
                                                    options={this.props.optionsUnitCompany} 
                                                    onChange={async (choosenUnitCompany) => {
                                                        this.props.onChangeValue(choosenUnitCompany.value, e.id, "idCompanyUnit");
                                                        this.props.onChangeValue(choosenUnitCompany.name, e.id, "nameCompanyUnit");
                                                    }}
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditCampaign ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                value={e.dateStart !== null && formatedDateTimeToUSA(e.dateStart)}
                                                placeholder={e.dateStart !== null ? formatedDateTimeToBRA(e.dateStart).toString(): ""}
                                                name="Data Inicial"
                                                onChange={value => this.props.onChangeValue((value), e.id, "dateStart")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CAM_DATE_START", e.dateStart)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                type="date"
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditCampaign ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                value={e.dateEnds !== null && formatedDateTimeToUSA(e.dateEnds)}
                                                placeholder={e.dateEnds !== null ? formatedDateTimeToBRA(e.dateEnds).toString() : ""}
                                                name="Data Final"
                                                onChange={value => this.props.onChangeValue((value), e.id, "dateEnds")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CAM_DATE_ENDS", e.dateEnds)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                type="date"
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditCampaign ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                value={e.timeStart !== null ? formatedTime(e.timeStart) : ""}
                                                placeholder={e.timeStart !== null ? formatedTime(e.timeStart).toString() : ""}
                                                name="Horário Inicial"
                                                onChange={value => this.props.onChangeValue(value, e.id, "timeStart")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CAM_TIME_START", e.timeStart)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditCampaign ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                value={e.timeEnds !== null ? formatedTime(e.timeEnds) : ""}
                                                placeholder={e.timeEnds !== null ? formatedTime(e.timeEnds).toString() : ""}
                                                name="Horário Final"
                                                onChange={value => this.props.onChangeValue(value, e.id, "timeEnds")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CAM_TIME_ENDS", e.timeEnds)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditCampaign ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                value={e.executions}
                                                placeholder={e.executions}
                                                type="number"
                                                name="Inserções"
                                                onChange={value => (!isNaN(value) ? this.props.onChangeValue(value, e.id, "executions") : 0)}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CAM_EXECUTIONS", e.executions)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={3}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditCampaign ${!this.props.permissions.toUpdate && "noEdit"}`}
                                                value={formatedMoneyBRA(e.amountInvested.toString())}
                                                placeholder={formatedMoneyBRA(e.amountInvested.toString())}
                                                name="Valor Investido"
                                                onChange={value => this.props.onChangeValue(formatedMoneyBRA(value.toString()), e.id, "amountInvested")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "CAM_AMOUNT_INVESTED", e.amountInvested)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={14}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            {
                                                this.props.permissions.toUpdate &&
                                                <div className={`detailsTableEditCampaign`}>
                                                    {
                                                        e.running === 1 && e.isItPossibleToRun === 1 &&
                                                        <Button
                                                            classaditional="buttonShowDetailsTableEditCampaign error"
                                                            icon="fas fa-stop"
                                                            onClick={async () => {
                                                                await this.props.onSaveValue(e.id, "CAM_RUNNING", 2);
                                                                this.props.onIgnoreSave();
                                                            }}
                                                            onMouseOver={(event) => this.setPositionsDetails(event, "A campanha está rodando; deseja pará-la?")}
                                                            onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                                                        />
                                                    }
                                                    {
                                                        e.running === 2 && e.isItPossibleToRun === 1 &&
                                                        <Button
                                                            classaditional="buttonShowDetailsTableEditCampaign success"
                                                            icon="fas fa-play"
                                                            onClick={async () => {
                                                                await this.props.onSaveValue(e.id, "CAM_RUNNING", 1);
                                                                this.props.onIgnoreSave();
                                                            }}
                                                            onMouseOver={(event) => this.setPositionsDetails(event, "A campanha está parada; deseja iniciá-la?")}
                                                            onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                                                        />
                                                    }
                                                    {
                                                        e.isItPossibleToRun === 2 &&
                                                        <Button
                                                            classaditional="buttonShowDetailsTableEditCampaign noAction"
                                                            icon="&nbsp;"
                                                            onClick={() => {}}
                                                            onMouseOver={(event) => {}}
                                                            onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                                                        />
                                                    }
                                                </div> 
                                            }      
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default connect(null, null)(TableEditCampaign);