import React, { Component } from "react"; 
import ButtonIcon from "./ButtonIcon";
import "./ModalGeneral.css";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";

class ModalGeneral extends Component
{
    
    componentDidUpdate = async (prevProps, prevState) =>
    {
        // console.log("MODAL GENERAL prevProps ", prevProps);
        // console.log("MODAL GENERAL this.props ", this.props);
    }

    render ()
    {
        return (
            <div className={`modalGeneral ${this.props?.classaditional}`}>
                <div className="contentModalGeneral">
                    <div className="row no-gutters lineHeaderModalGeneral">
                        <h3 className="title">{this.props?.title}</h3>
                        <ButtonIcon 
                            icon="fal fa-times"
                            onClick={() => this.props.onClose()}
                            classaditional="buttonCloseModalGeneral"
                        />
                    </div>
                    <div className="row no-gutters lineBodyModalGeneral">
                        {this.props?.children}
                    </div>
                    <div className="row no-gutters lineFooterModalGeneral">
                    </div>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        }
    }
}

export default connect(null, mapDispatchToProps)(ModalGeneral);