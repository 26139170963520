import React, { Component } from "react";
import "./AudioRecordingWhatsApp.css";
import { connect } from "react-redux";
import { setModalMessage } from "../../store/actions/general";
import AudioPlayer from "../general/AudioPlayer";
// https://stackoverflow.com/questions/46265330/webaudio-how-to-deactivate-microphone/

const AUDIO_DEFAULT = {
    data: null,
    recording: false, //default is false
    audioSource: null,
    duration: 0,
    currentDuration: 0,
    maximumDuration: 0,
    played: false,
}
const initialState = {
    isPermissionDenied: false,
    ...AUDIO_DEFAULT
}

var MEDIA_RECORDER = null;
var MEDIA_STREAM = null;
var DATA_ARRAY = [];
var STOPWATCH = null;
var STOPWATCH_DURATION = 0;

class AudioRecordingWhatsApp extends Component
{    
    state = {...initialState}

    componentDidMount = async () =>
    {
        // nothing...
    }

    componentDidUpdate = async (prevProps) =>
    {
        let propsRecording = this.props.recording;
        let currentRecording = await this.state.recording;
        let propsClearRecording = this.props.clearRecording;
        let isPermissionDenied = await this.state.isPermissionDenied;

        // console.log(`currentRecording: ${currentRecording}`);

        if (currentRecording !== propsRecording && !isPermissionDenied)
        {
            if (!propsRecording)
            {
                // console.log("pausing...");
                await this.pause();
            }
        
            if (propsRecording)
            {
                // console.log("starting...");
                await this.start();
            }
        }

        if (propsClearRecording)
        {
            // console.log("to clear...");
            this.props.onChangeClearRecording(false);
            await this.clear();
        }

        if (isPermissionDenied) { this.getPermissions(); }
    }

    getPermissions = async () =>
    {
        // console.log("getPermissions...");
        navigator.mediaDevices.getUserMedia({audio: true})
        .then((stream) => {
            MEDIA_STREAM = stream;
            this.setState({isPermissionDenied: false});
            this.start(stream);
        })
        .catch((error) => {
            // console.log(error);
            this.message("error", "Opsssss, precisamos da sua autorização para podermos gravar o áudio!");
            this.setState({isPermissionDenied: true});
        });
    }

    clear = async () =>
    {
        // console.log("cleaning...");
        await this.createAudioData([]);
        this.clearMediaStream();
        await this.clearStopwatch();
        await this.setState({...AUDIO_DEFAULT});
        MEDIA_RECORDER = null;
        DATA_ARRAY = [];
    }

    start = async (stream = null) =>
    {
        if (stream)
        {
            // console.log("starting...");
            MEDIA_RECORDER = new MediaRecorder(stream);
            MEDIA_RECORDER.start();
            await this.setState({recording: true});
            this.startStopwatch();
            MEDIA_RECORDER.ondataavailable = function (ev) {
                DATA_ARRAY.push(ev.data);
            }
            MEDIA_RECORDER.onstop = async (ev) =>
            {
                await this.createAudioData(DATA_ARRAY);
                this.clearMediaStream();
            }
        }

        if (MEDIA_STREAM === null)
        {
            // console.log("starting again...");
            this.getPermissions();
        }
    }

    pause = async () =>
    {
        if (MEDIA_RECORDER !== null)
        {
            MEDIA_RECORDER.stop();
            await this.setState({recording: false});
            this.stopStopwatch();
        }
    }

    createAudioData = async (data) =>
    {
        // console.log("createAudioData...", data);
        let audioData = new Blob(data, {'type': 'audio/ogg;'});
        let audioSrc = window.URL.createObjectURL(audioData);
        await this.setState({recording: false, audioSource: audioSrc});
        this.props.onPauseRecording(audioSrc, audioData);
    }

    clearMediaStream = () =>
    {
        // console.log("clearMediaStream... MEDIA_STREAM: ", MEDIA_STREAM);
        if (MEDIA_STREAM !== null) { MEDIA_STREAM.getAudioTracks().forEach((track) => track.stop()); }
        MEDIA_STREAM = null;
    }

    startStopwatch = async () =>
    {
        // console.log("starting startStopwatch...");
        STOPWATCH = setInterval(async () => {
            STOPWATCH_DURATION++;
            await this.setState({duration: STOPWATCH_DURATION});
        }, 1000);
    }

    stopStopwatch = () =>
    {
        // console.log("stoping stopStopwatch...");
        clearInterval(STOPWATCH);
    }

    clearStopwatch = async () =>
    {
        this.stopStopwatch();
        STOPWATCH_DURATION = 0;
        await this.setState({duration: STOPWATCH_DURATION});
    }

    formatedDuration = (duration) =>
    {
        let secondsNumber = parseInt(duration, 10);
        let hours   = Math.floor(secondsNumber / 3600);
        let minutes = Math.floor((secondsNumber - (hours * 3600)) / 60);
        let seconds = secondsNumber - (hours * 3600) - (minutes * 60);
    
        seconds = seconds.toString().padStart(2, "0");
        
        if (hours > 0)
        {
            minutes = minutes.toString().padStart(2, "0");
            return `${hours}:${minutes}:${seconds}`;
        }

        if (hours === 0) { return `${minutes}:${seconds}`; }
    }

    getFormatedDuration = (duration) =>
    {
        let durationNumber = parseInt(duration);
        let hours   = Math.floor(durationNumber / 3600);
        let minutes = Math.floor((durationNumber - (hours * 3600)) / 60);
        let seconds = durationNumber - (hours * 3600) - (minutes * 60);

        if (duration < 60) //haven't a minute
        {
            seconds = seconds.toString().padStart(2, "0");
            return `0:${seconds}`;
        }
        
        if (duration > 60 && duration < 3600) //have a minute, but haven't a hour
        {
            seconds = seconds.toString().padStart(2, "0");
            return `${minutes}:${seconds}`;
        }
        
        if (duration > 3600) //have a hour
        {
            seconds = seconds.toString().padStart(2, "0");
            minutes = minutes.toString().padStart(2, "0");
            return `${hours}:${minutes}:${seconds}`;
        }
    }

	message = (type, message) =>
	{
        this.props.setModalMessage({show: true, type, message});
	}

    render ()
    {
        return (
            <div className={`audioRecordingWhatsApp ${this.props.classaditional && this.props.classaditional}`}>
                {this.state.recording && <span className="durationAudiotRecordingWhatsApp">{this.formatedDuration(this.state.duration)}</span>}
                <div className="recordingAudioRecordingWhatsApp">
                    {
                        this.state.recording &&
                        <div className="innerPointsAudioRecordingWhatsApp">
                            {new Array(100).fill(0).map((v, i) => <div key={i+1} className="pointRecordingAudioRecordingWhatsApp"/>)}
                        </div>                        
                    }
                    {
                        (!this.state.recording && this.state.audioSource === null && this.props.recording) &&
                        <div className="lineAudioRecordingWhatsApp"></div>
                    }
                </div>
                {
                    (!this.state.recording && this.state.audioSource !== null) &&
                    <div className="controlesAudioRecordingWhatsApp">
                        <button
                            className="buttonDefaultAudioRecordingWhatsApp"
                            onClick={async () => this.setState({played: !this.state.played})}
                            title="Play or Pause"
                        >
                            {
                                this.state.played 
                                ?
                                    (<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="18px" viewBox="0 0 16 18"><path d="M1.9,1c-0.6,0-1,0.4-1,1v14c0,0.6,0.4,1,1,1H5c0.6,0,1-0.4,1-1V2c0-0.6-0.4-1-1-1H1.9z" className="iconButtonDefaultAudioRecordingWhatsApp"></path><path className="iconButtonDefaultAudioRecordingWhatsApp" d="M10.9,1c-0.6,0-1,0.4-1,1v14c0,0.6,0.4,1,1,1h3.1c0.6,0,1-0.4,1-1V2c0-0.6-0.4-1-1-1H10.9z"></path></svg>)
                                :
                                    (<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="18px" viewBox="0 0 16 18" className="iconButtonDefaultAudioRecordingWhatsApp"><path d="M15.05,8.39,2,.32a1,1,0,0,0-1.53.85V16.83A1,1,0,0,0,2,17.7L15,10.1A1,1,0,0,0,15.05,8.39Z"></path></svg>)
                            }
                        </button>
                        <AudioPlayer
                            classaditional="playerAudioRecordingWhatsApp"
                            source={this.state.audioSource}
                            onChangeDuration={(value) => this.setState({currentDuration: value})}
                            maximumDuration={(value) => this.setState({maximumDuration: value})}
                            finishedDownload={() => {console.log("finished download.")}}
                            play={this.state.played}
                            speed={1}
                            listened={2}
                            emphasis={false}
                            sentForMe={false}
                        />
                        <span className="durationAudioRecordingWhatsApp">
                            {
                                this.state.played
                                ? this.getFormatedDuration(this.state.currentDuration)
                                : this.getFormatedDuration(this.state.maximumDuration)
                            }
                        </span>
                    </div>
                }
                {/* {
                    !this.state.recording &&
                    <audio src={this.state.audioSource} controls/>
                } */}
            </div>
        )
    }
}

function mapStateToProps (state)
{    
    return {
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioRecordingWhatsApp);