import "./MenuRight.css";

export default function MenuRight({ isOpen, title, children, onCloseMenu }) 
{
    return (
        <div className={`menuRight ${isOpen ? "open" : ""}`}>
            <button 
                className="buttonToggleMenuRight" 
                onClick={onCloseMenu}
            >
                { isOpen ?
                    <i className="fas fa-chevron-right"></i>
                    :
                    <i className="fas fa-chevron-left"></i>
                }
            </button>
            <header className="row no-gutters align-items-center menuRightHeader">
                <div className="col-9">
                    <h4>{title}</h4>
                </div>
                <div className="col-3 text-right">
                    <button
                        className="buttonCloseMenuRight" 
                        onClick={onCloseMenu}
                    >
                        <i className="fal fa-times-circle"></i>
                    </button>
                </div>
            </header>
            <div className="menuRightContent">
                {children}
            </div>
        </div>
    )
}