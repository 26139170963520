import API from "../api";
import { apiCallbackTreatment} from "../auxiliary/generalFunctions";

var api = new API();

export default class IntegrationsController
{
    async get()
    {
        const response = await api.integrations().get();
        return apiCallbackTreatment(response);
    }

    async post(data)
    {
        const response = await api.integrations().post(data);
        return apiCallbackTreatment(response);
    }

    async saveFormFromPage(data)
    {
        const response = await api.integrations().saveFormFromPage(data);
        return apiCallbackTreatment(response);
    }
}