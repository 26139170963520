import React, { useEffect, useState } from "react";
import "./ProgressLine.css";

const stepsName = [
    "Lead",
    "Primeiro Contato",
    "Atendimento Presencial",
    "Fechamento",
    "Reagendar"
]

export default function ProgressLine (props)
{
    const [step, setStep] = useState(props.step);

    useEffect (() => {
        let step = props.step;
        setStep(step);

    }, [props.step, step]);

    function setLineProgress (step) 
    {
        let steps = new Array(5).fill(0);
        
        return steps.map((s, i) => {
            let first = (i === 0) ? "first" : "";
            let stageContentActive = (i <= step) ? "active" : "";
            let stageArrowActive = (i <= step) ? "active" : "";
            let last = (i > step && (i <= (step + 1))) ? "last" : "";
            // let rnd = parseInt((Math.random()+10)*2);
            const rnd = props?.days;

            return (
                <div 
                    key={`${i}`}
                    className={`
                            stageContentProgressLine 
                            ${first}
                            ${stageContentActive} 
                            ${last}
                        `}
                    onClick={() => props.onChangeStep(i)}    
                >                            
                    <span 
                        className={`
                            stageArrowProgressLine 
                            ${stageArrowActive}
                        `}
                    />
                    {props.step === i && `${rnd} dia(s)`}
                    <span className="infoStageContentProgressLine">{stepsName[i]}</span>
                </div> 
            )
        })
    }

    return (
        <div className={`progressLine ${props?.classaditional}`}>
            <div className="row no-gutters lineProgressLine">
                {setLineProgress(step)}

                {/* RESERVED */}
                {/* <div className="stageContentProgressLine first active">                            
                    <span className="stageArrowProgressLine active"></span>
                    <span className="infoStageContentProgressLine">Qualificado</span>
                </div>                
                <div className="stageContentProgressLine last">                            
                    <span className="stageArrowProgressLine"></span>
                    <span className="infoStageContentProgressLine">Contatado</span>
                </div>                
                <div className="stageContentProgressLine">                            
                    <span className="stageArrowProgressLine"></span>
                    <span className="infoStageContentProgressLine">Demo Agendada</span>
                </div>                
                <div className="stageContentProgressLine">                            
                    <span className="stageArrowProgressLine"></span>
                    <span className="infoStageContentProgressLine">Proposta Feita</span>
                </div>                
                <div className="stageContentProgressLine">                            
                    <span className="stageArrowProgressLine"></span>
                    <span className="infoStageContentProgressLine">Negociações Iniciadas</span>
                </div> */}
            </div>
            <div className="row no-gutters lineDetailsProgressLine">
                <span>Linha de Progresso</span>
                <i className="fal fa-arrow-right iconProgressLine"></i>
                <span>{stepsName[step]}</span>
            </div>
        </div>
    )

}
