import API_ERP from "../api/erp";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const apiERP = new API_ERP();

export default class ClientFromERPController 
{
    async create(data) 
    {
        const response = await apiERP.client().post(data);
        return apiCallbackTreatment(response);
    }

    async update(id, data) 
    {
        const response = await apiERP.client().update(id, data);
        return apiCallbackTreatment(response);
    }
}