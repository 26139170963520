import React, { Component } from "react";
import "./CommissionProspectionDetailsForm.css";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import { formatedOnlyNumber, formatedPercentageNumber, formatedPercentToFloat } from "../../auxiliary/generalFunctions";
import InputGeneral from "../general/InputGeneral";
import Button from "../general/Button";
import Select from "../general/Select";
import Message from "../general/Message";
import CommissionRulesDetailsProspectionController from "../../controllers/CommissionRulesDetailsProspectionController";
import CommissionRulesProspectionController from "../../controllers/CommissionRulesProspectionController";

const initialState = {

    disabledButton: false,

    // start content group
    validGroup: true,
    choosenGroup: {
        name: "",
        value: 0,
    },
    // ends content group

    // start type
    validType: true,
    choosenType: {
        name: "",
        value: 0,
    },
    // ends type

    // start current rule
    validCurrentRule: true,
    choosenCurrentRule: {
        name: "",
        value: 0,
    },
    // ends current rule

    // start content month
    month: "",
    searchingMonth: false,
    warningMonth: false,
    noticesMonth: [],
    validMonth: true,
    // ends content month
    
    // start content minimumGoalDay
    minimumGoalDay: 10,
    // ends content minimumGoalDay 

    // start content minimumGoalDay
    minimumGoalMonth: 220,
    // ends content minimumGoalMonth 

    // start content minimumPercent
    minimumPercent: "0",
    searchingMinimumPercent: false,
    warningMinimumPercent: false,
    noticesMinimumPercent: [],
    validMinimumPercent: true,
    // ends content minimumPercent 

    // start content maximumPercent
    maximumPercent: "0",
    searchingMaximumPercent: false,
    warningMaximumPercent: false,
    noticesMaximumPercent: [],
    validMaximumPercent: true,
    // ends content maximumPercent 

    // start content amountCommission
    amountCommission: "",
    searchingAmountCommission: false,
    warningAmountCommission: false,
    noticesAmountCommission: [],
    validAmountCommission: true,
    // ends content amountCommission 

    optionsGroup: [
        {
            name: "Atendente",
            value: "ATTENDANT",
            checked: false
        },
        {
            name: "Supervisor",
            value: "SUPERVISOR",
            checked: false
        }
    ],

    optionsType: [
        {
            name: "Agendamento",
            value: "SCHEDULED",
            checked: false
        },
        {
            name: "Comparecimento",
            value: "SHOWEDUP",
            checked: false
        }
    ],

    optionsTypeCurrentRule: [
        {
            name: "Percentual",
            value: 1,
            checked: false
        },
        {
            name: "Quantidade",
            value: 2,
            checked: false
        }
    ],

    // start modal message
    message_type: "information",
    message_show: false,
    message: "",
    // ends modal message
}

const commissionRulesDetailsProspectionController = new CommissionRulesDetailsProspectionController();
const commissionRulesProspectionController = new CommissionRulesProspectionController();

class CommissionProspectionDetailsForm extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        await this.setState({
            month: this.props.data.month
        });
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        }
        this.props.showModalMoreActions(data);
    }

    groupChangeAndChecking = async (choosenGroup) =>
    {
        await this.setState({
            choosenGroup,
            validGroup: choosenGroup.value !== 0
        });
    }

    typeChangeAndChecking = async (choosenType) =>
    {
        await this.setState({
            choosenType,
            validType: choosenType.value !== 0
        });
    }

    monthChangeAndChecking = async (month) =>
    {
        await this.setState({
            month,
            validMonth: month.toString().trim() !== ""
        });
    }

    minimumPercentChangeAndChecking = async (minimumPercent) =>
    {
        await this.setState({
            minimumPercent,
            validMinimumPercent: minimumPercent.toString().trim() !== ""
        });
    }

    maximumPercentChangeAndChecking = async (maximumPercent) =>
    {
        await this.setState({
            maximumPercent,
            validMaximumPercent: maximumPercent.toString().trim() !== ""
        });
    }

    amountCommissionChangeAndChecking = async (amountCommission) =>
    {
        await this.setState({
            amountCommission,
            validAmountCommission: amountCommission.toString().trim() !== ""
        });
    }

    onActionForm = async () =>
    {
        await this.setState({disabledButton: true});

        let idCommissionRuleProspection = 0;

        const month = this.state.month;
        const group = this.state.choosenGroup?.value;
        const type = this.state.choosenType?.value;
        const currentRule = this.state.choosenCurrentRule?.value;
        const minimumGoalDay = formatedOnlyNumber(this.state.minimumGoalDay.toString() || "0");
        const minimumGoalMonth = formatedOnlyNumber(this.state.minimumGoalMonth.toString() || "0");
        const minimumPercent = formatedPercentToFloat(this.state.minimumPercent || "0");
        const maximumPercent = formatedPercentToFloat(this.state.maximumPercent || "0");
        const amountCommission = formatedPercentToFloat(this.state.amountCommission);

        if (!currentRule)
        {
            await this.setState({disabledButton: false});
            return this.message("error", "Informe a regra!");
        }

        if (!month)
        {
            await this.setState({disabledButton: false});
            return this.message("error", "Informe o mês!");
        }

        const resultRule = await commissionRulesProspectionController.get(month);
        if (!resultRule.status || resultRule.data?.number_of_lines === 0) 
        {
            const resultCreateRule = await commissionRulesProspectionController.create({
                CRP_MONTH: month
            });

            idCommissionRuleProspection = resultCreateRule.data.CRP_ID;
        }
        else
        {
            idCommissionRuleProspection = resultRule.data.data[0].CRP_ID;
        }

        const result = await commissionRulesDetailsProspectionController.create({
            CPD_ID_COMMISSION_RULE_PROSPECTION: idCommissionRuleProspection,
            CPD_TYPE: type,
            CPD_GROUP: group,
            CPD_MINIMUM_PERCENT: minimumPercent,
            CPD_MAXIMUM_PERCENT: maximumPercent,
            CPD_AMOUNT_COMMISSION: amountCommission,
            CPD_MINIMUM_GOAL_DAY: minimumGoalDay,
            CPD_MINIMUM_GOAL_MONTH: minimumGoalMonth,
            CPD_TYPE_CURRENT_RULE: currentRule
        });

        if (!result.status)
        {
            await this.setState({disabledButton: false});
            return this.message("error", result.message);
        }

        this.setState({
            choosenGroup: {
                name: "",
                value: 0,
            },
            choosenType: {
                name: "",
                value: 0,
            },
            month: "",
            minimumPercent: "",
            maximumPercent: "",
            amountCommission: ""
        });

        await this.setState({disabledButton: false});
        this.props.data.onActionForm();
        this.closeModalMoreActions();
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render () 
    {
        return (
            <div className="commissionProspectionDetailsForm"> 
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="row no-gutters lineCommissionProspectionDetailsForm">
                    <div className="col-6 columnCommissionProspectionDetailsForm pr-1">
                        <InputGeneral
                            label="Mês de Referência"
                            value={this.state.month}
                            onChange={e => this.monthChangeAndChecking(e.target.value)}
                            searching={this.state.searchingMonth.toString()}
                            warning={this.state.warningMonth.toString()}
                            warningcontent={this.state.noticesMonth}
                            mandatory="true"
                            type="month"
                            valid={this.state.validMonth.toString()} 
                        />
                    </div>
                    <div className="col-6 columnCommissionProspectionDetailsForm pl-1">
                        <Select
                            label="Regra"
                            options={this.state.optionsTypeCurrentRule}
                            default={this.state.choosenCurrentRule}
                            onChange={(choosenCurrentRule) => this.setState({ choosenCurrentRule})} 
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionProspectionDetailsForm">
                    <div className="col-6 columnCommissionProspectionDetailsForm pr-1">
                        <Select
                            label="Grupo"
                            options={this.state.optionsGroup}
                            default={this.state.choosenGroup}
                            onChange={(choosenGroup) => this.groupChangeAndChecking(choosenGroup)}
                            valid={this.state.validGroup.toString()}
                            mandatory="true"
                        />
                    </div>
                    <div className="col-6 columnCommissionProspectionDetailsForm pl-1">
                        <Select
                            label="Tipo"
                            options={this.state.optionsType}
                            default={this.state.choosenType}
                            onChange={(choosenType) => this.typeChangeAndChecking(choosenType)}
                            valid={this.state.validType.toString()}
                            mandatory="true"
                        />
                    </div>
                </div>
                {
                    this.state.choosenCurrentRule?.value === 2 &&
                    <div className="row no-gutters lineCommissionProspectionDetailsForm">
                        <div className="col-6 columnCommissionProspectionDetailsForm pr-1">
                            <InputGeneral
                                label="Quantidade Diária"
                                value={this.state.minimumGoalDay}
                                onChange={e => this.setState({ minimumGoalDay: e.target.value })}
                                mandatory="true"
                                maxLength={4}
                            />
                        </div>
                        <div className="col-6 columnCommissionProspectionDetailsForm pl-1">
                            <InputGeneral
                                label="Quantidade Mensal"
                                value={this.state.minimumGoalMonth}
                                onChange={e => this.setState({ minimumGoalMonth: e.target.value })}
                                mandatory="true"
                                maxLength={4}
                            />
                        </div>
                    </div>
                }
                {
                    this.state.choosenCurrentRule?.value === 1 &&
                    <div className="row no-gutters lineCommissionProspectionDetailsForm">
                        <div className="col-6 columnCommissionProspectionDetailsForm pr-1">
                            <InputGeneral
                                label="Meta Mínima (%)"
                                value={this.state.minimumPercent}
                                onChange={e => this.minimumPercentChangeAndChecking(formatedPercentageNumber(e.target.value))}
                                searching={this.state.searchingMinimumPercent.toString()}
                                warning={this.state.warningMinimumPercent.toString()}
                                warningcontent={this.state.noticesMinimumPercent}
                                mandatory="true"
                                maxLength={6}
                                valid={this.state.validMinimumPercent.toString()}
                            />
                        </div>
                        <div className="col-6 columnCommissionProspectionDetailsForm pl-1">
                            <InputGeneral
                                label="Meta Máxima (%)"
                                value={this.state.maximumPercent}
                                onChange={e => this.maximumPercentChangeAndChecking(formatedPercentageNumber(e.target.value))}
                                searching={this.state.searchingMaximumPercent.toString()}
                                warning={this.state.warningMaximumPercent.toString()}
                                warningcontent={this.state.noticesMaximumPercent}
                                mandatory="true"
                                maxLength={6}
                                valid={this.state.validMaximumPercent.toString()}
                            />
                        </div>
                    </div>
                }
                <div className="row no-gutters lineCommissionProspectionDetailsForm">
                    <div className="col-12 columnCommissionProspectionDetailsForm">
                        <InputGeneral
                            label="Comissão (R$)"
                            value={this.state.amountCommission}
                            onChange={e => this.amountCommissionChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingAmountCommission.toString()}
                            warning={this.state.warningAmountCommission.toString()}
                            warningcontent={this.state.noticesAmountCommission}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validAmountCommission.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionProspectionDetailsForm">
                    <div className="col-12 columnCommissionProspectionDetailsForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterCommissionProspectionDetailsForm">
                    <Button
                        classaditional="buttonCommissionProspectionDetailsForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions()}
                    />
                    <Button
                        classaditional="buttonCommissionProspectionDetailsForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()} 
                        disabled={this.state.disabledButton}
                    />                  
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;

    return {
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionProspectionDetailsForm);