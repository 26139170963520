import React, { Component } from "react"; 
import "./Debug.css";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { APPLICATION_NAME } from "../../core/constants";

import { setModalMessage } from "../../store/actions/general";

import Header from "../../components/CRM/Header";
import Check from "../../components/general/Check";
import Box from "../../components/general/Box";
import Button from "../../components/general/Button";
import InputGeneral from "../../components/general/InputGeneral";

import DebugController from "../../controllers/DebugController";

const DEFAULT_STATE = {
    inputConsult: `
    update
        SELECT COUNT(BUS_ID) AS AMOUNT, SUM(BUS_MONEY_VALUE) AS AMOUNT_VALUE FROM 
        (SELECT business.BUS_ID, business.BUS_MONEY_VALUE FROM tb_activities AS activity 
        LEFT JOIN tb_business AS business 
        ON business.BUS_ID = activity.ACT_ID_BUSINESS
        LEFT JOIN tb_person AS person
        ON person.PER_ID = business.BUS_ID_PERSON_CLIENT
        WHERE
        person.PER_ID_COMPANY_UNIT IN (1,2,3,4,5,6,7,8,9,10,11,12,13,14) AND
        DATE(activity.ACT_DATE_START) >= "{dateStart}" AND
        DATE(activity.ACT_DATE_START) <= "{dateEnds}" AND
        DATE(business.BUS_DATE_CREATED) >= "{dateStart}" AND
        DATE(business.BUS_DATE_CREATED) <= "{dateEnds}" AND
        activity.ACT_TYPE = "VISIT" GROUP BY business.BUS_ID ) AS RESULT
   
    `,
    columnName: "MID_ID",
    loading: false,
    processed: false,
    queryResults: "",
    queryProcessed: "",
    numberRowsQueryResult: 0,
    inputResults: "",
    typeProcessing: "unifyAndJoinComma",
    checkOne: true,
    variableOneBefore: "{dateStart}",
    variableOneAfter: "2022-11-01",
}

const initialState = {
    ...DEFAULT_STATE
}

const debugController = new DebugController();

class Debug extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {   
        document.title = `${APPLICATION_NAME} - Debug Screen`;
        // this.message("information", "Welcome to debug screen!");
    }
    
    componentDidUpdate = async (prevProps) =>
    {
        // console.log("Debug prevProps ", prevProps);
        // console.log("Debug this.props ", this.props);
    }

    clean = async () =>
    {
        await this.setState({...DEFAULT_STATE});
    }

	message = (type, message) =>
	{
        this.props.setModalMessage({show: true, type, message});
	}

    consult = async () =>
    {
        let loading = await this.state.loading;
        let inputConsult = await this.state.inputConsult;
        let numberRowsQueryResult = 0;

        if (loading) 
        { 
            this.message("information", "Wait for the current process to finish!");
            return;
        }

        await this.setState({loading: true, processed: false});

		let resultConsult = await debugController.debugging(inputConsult);

        if (resultConsult.status)
        {
            let response = resultConsult.data.data;
            let queryResults = JSON.stringify(response);
            await this.setState({queryResults});
            numberRowsQueryResult = response.length;
        }
        else
        {
            this.message("error", resultConsult.message);
        }

        await this.setState({loading: false, numberRowsQueryResult});
    }

    applyReplacement = async () =>
    {
        let variableOneBefore = await this.state.variableOneBefore;
        let variableOneAfter = await this.state.variableOneAfter;
        let inputConsult = await this.state.inputConsult;
        let regOne = new RegExp(variableOneBefore,"gi");
        inputConsult = inputConsult.replace(regOne, variableOneAfter);
        await this.setState({inputConsult});
    }

    applyTreatment = async () =>
    {
        let loading = await this.state.loading;
        let columnName = await this.state.columnName;
        let typeProcessing = await this.state.typeProcessing;
        let queryResults = await this.state.queryResults;
        let inputResults = "";

        if (loading) { this.message("information", "Wait for the current process to finish!") }

        if (columnName.toString().trim() === "") 
        { 
            this.message("error","Choose at lease a column!");
            return;
        }

        if (typeProcessing === "unifyAndJoinComma")
        {
            queryResults = JSON.parse(queryResults);
            queryResults.forEach(q => {
                inputResults += q[columnName] + ",";
            });
        }

        await this.setState({processed: true, inputResults});
    }

    render ()
    {
        return (
            <div className="debug">
                <Header title="Debug Screen" classaditional="headerNavigationDebug"/>
                {
                    !this.props.debug.toView &&
                    <div className="scrollDebug mb-4 mt-4">
                        <div className="container bodyDebug d-none d-md-block error">
                            <span>You don't have permissions over component!</span>  
                        </div>
                    </div>
                }
                {
                    this.props.debug.toView &&
                    <div className="scrollDebug mb-4 mt-4">
                        <div className="container bodyDebug d-none d-md-block">
                            <Box
                                classaditional="boxDebug input"
                            >
                                <span>SQL String:</span>
                                <textarea 
                                    className="inputStringSQLDebug"
                                    onChange={(e) => this.setState({inputConsult: e.target.value})}
                                    value={this.state.inputConsult}
                                    rows="20"
                                    cols={120}
                                />
                                <span>Variables Replacement:</span>
                                <div className="row no-gutters lineDebug">
                                    <div className="col-5 pr-2 columnDebug">
                                        <InputGeneral
                                            classaditional="inputAttributeDebug"
                                            value={this.state.variableOneBefore}
                                            onChange={(e) => this.setState({variableOneBefore: e.target.value})}
                                        />
                                    </div>
                                    <div className="col-1 pr-2 pl-2 columnDebug">
                                        <span>with</span>
                                    </div>
                                    <div className="col-5 pl-2 columnDebug">
                                        <InputGeneral
                                            classaditional="inputAttributeDebug"
                                            value={this.state.variableOneAfter}
                                            onChange={(e) => this.setState({variableOneAfter: e.target.value})}
                                        />
                                    </div>
                                    <div className="col-1 pr-2 pl-2 columnDebug">
                                        <Button
                                            classaditional="buttonDebug"
                                            name="apply"
                                            onClick={() => this.applyReplacement()}
                                        />
                                    </div>
                                </div>
                                <Button
                                    classaditional="buttonDebug"
                                    name="Consult"
                                    onClick={() => this.consult()}
                                />
                                <Button
                                    classaditional="buttonDebug error"
                                    name="Clean"
                                    onClick={() => this.clean()}
                                />
                            </Box>
                            {
                                !this.state.loading &&
                                <Box
                                    classaditional="boxDebug processing"
                                >
                                    <span>Query Result*:</span>
                                    <textarea 
                                        className="inputStringSQLDebug"
                                        onChange={(e) =>{}}
                                        value={this.state.queryResults}
                                        rows="10"
                                        cols={120}
                                    />
                                    <span>*{this.state.numberRowsQueryResult} line(s) found!</span>
                                    <br />
                                    <span>Column Name:</span>
                                    <InputGeneral
                                        classaditional="inputAttributeDebug"
                                        value={this.state.columnName}
                                        onChange={(e) => this.setState({columnName: e.target.value})}
                                    />
                                    <div className="treatmentOptionsDebug">
                                        <span>Treatment Options:</span>
                                        <Check
                                            classaditional="checkApplyTreatmentDebug"
                                            name="unify columns and separate by commas."
                                            checked={this.state.checkOne}
                                            onClick={async () => {
                                                let checkOneBefore = await this.state.checkOne;
                                                let checkOne = (checkOneBefore) ? false : true;
                                                let typeProcessing = (checkOneBefore) ? "" : "unifyAndJoinComma";
                                                this.setState({checkOne, typeProcessing});
                                            }}
                                        />
                                    </div>
                                    <Button
                                        classaditional="buttonDebug"
                                        name="Apply Treatment"
                                        onClick={() => this.applyTreatment()}
                                    />
                                </Box>
                            }
                            {
                                !this.state.loading && this.state.processed &&
                                <Box
                                    classaditional="boxDebug output"
                                >
                                    <span>Treatment Result:</span>
                                    <textarea 
                                        className="inputStringSQLDebug"
                                        onChange={(e) =>{}}
                                        value={this.state.inputResults}
                                        rows="4"
                                        cols={100}
                                    />
                                </Box>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const debug = state.permissions.debug;

    return {
        debug
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Debug));