import { 
    GET_ALL_USERS_LEADS,
    CHANGE_USER_LEADS,
    TOGGLE_CHECKED_ALL_USERS_LEADS,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_LEADS
}  from "../../core/constants";

//action creator
export function getAllUsersLeads ()
{
    return {
        type: GET_ALL_USERS_LEADS
    }
}

//action creator
export function changeUserLeads (data)
{
    return {
        type: CHANGE_USER_LEADS,
        payload: data,
    }
}

//action creator
export function toggleCheckedAllUsersLeads (status)
{
    return {
        type: TOGGLE_CHECKED_ALL_USERS_LEADS,
        payload: status
    }
}

//action creator
export function changeStatusGetFromDatabaseCompletedLeads (status)
{
    return {
        type: CHANGE_STATUS_GET_FROM_DATABASE_ALL_LEADS,
        payload: status
    }
}