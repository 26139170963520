import React from "react";
import "./NotFound.css";

export default function NotFound ()
{
    return (
        <div className="notFound">
            <h1>404</h1>
            <h6>Opsssss, página não encontrada!</h6>
        </div>
    )
}