import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class CommissionRulesIntervalsController
{
    async create(data) 
    {
        const response = await api.commissionRulesIntervals().post(data);
        return apiCallbackTreatment(response);
    }

    // async deleteById(id)
    // {
    //     const response = await api.commissionRulesIntervals().remove(id);
    //     return apiCallbackTreatment(response);
    // }

    async get(month, idCompanyUnit) 
    {
        const response = await api.commissionRulesIntervals().get(month, idCompanyUnit);
        return apiCallbackTreatment(response);
    }

    async getById(id) 
    {
        const response = await api.commissionRulesIntervals().getById(id);
        return apiCallbackTreatment(response);
    }

    // async update(id, data) {
    //     const response = await api.commissionRulesIntervals().update(id, data);
    //     return apiCallbackTreatment(response);
    // }
}