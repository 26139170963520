import React, { Component } from "react";
import "./FieldEdit.css";
import ButtonIcon from "./ButtonIcon";
import ModalEdit from "./ModalEdit";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";

const initialState = {
    showModalEdit: false,
    field: null, //element DOM for reference
    maxWidthModalFieldEdit: 300,
    maxHeightModalFieldEdit: 140,
    y: 0,
    x: 0,
}

export default class FieldEdit extends Component
{
    _mounted = false;
    state = {...initialState}

    componentDidMount = async () =>
    {
        this._mounted = true;
        let field = this.field;
        await this.setState({ field });
        await this.setPositionsModalEdit();
    }

    componentWillUnmount = async () =>
    {
        this._mounted = false;
        console.info("FIELDEDIT demoting.");
    }

    setPositionsModalEdit = async () => 
    {
        let posField = await this.state.field;
        let {height, width, x, y} = posField.getBoundingClientRect();
        let maxWidthModalFieldEdit = await this.state.maxWidthModalFieldEdit;
        let maxHeightModalFieldEdit = await this.state.maxHeightModalFieldEdit;
        let posX = (x + width - 50);
        let posY = (y + height - 5);

        let dimensions = getWindowDimensions();

        if ((posX + maxWidthModalFieldEdit) > dimensions.width)
        {
            posX = (posX - maxWidthModalFieldEdit);
        }        

        if ((posY + maxHeightModalFieldEdit) > dimensions.height)
        {
            posY = (dimensions.height - maxHeightModalFieldEdit - 50 - 250);
        }

        // @OVERWRITE positions for mobile view
        if (dimensions.width <= 768)
        {
            posY = 300;
            posX = 56 + 10;
        }

        if (this._mounted)
        {
            await this.setState({ y: posY, x: posX });
        }
    }

    render ()
    {
        return (
            <div className={`fieldEdit ${this.props?.classaditional}`} ref={field => {this.field = field}}>
                <span>
                    {  
                        this.props?.icon
                        ? <i className={`${this.props.icon} iconFieldEdit`}></i>
                        : null
                    }
                    {this.props?.placeholder}
                </span>
                <ButtonIcon
                    icon={`${this.state.showModalEdit ? "fal fa-times" : "fal fa-pencil-alt"}`}
                    onClick={() => {
                        this.setState({showModalEdit: !this.state.showModalEdit});
                        if (this.props.onEditClick && !this.state.showModalEdit) { this.props.onEditClick(); }
                    }}
                    classaditional="buttonFieldEdit"
                />
                {
                    this.state.showModalEdit &&
                    <div 
                        className="modalFieldEdit"
                        style={{
                            maxWidth: `${this.state.maxWidthModalFieldEdit}px`,
                            maxHeight: `${this.state.maxHeightModalFieldEdit}px`,
                            top: `${this.state.y}px`,
                            left: `${this.state.x}px`,
                        }}
                    >
                        <ModalEdit
                            maxLength={this.props?.maxLength}
                            type={this.props.type ? this.props.type : "text"}
                            title={`Editar ${this.props.name}`}
                            value={this.props.value}
                            onChange={value => this.props?.onChange(value)}
                            onPositiveClick={() => {
                                this.props?.onPositiveClick();
                                this.setState({showModalEdit: false});
                            }}
                            onNegativeClick={() => {
                                this.setState({showModalEdit: false})
                                if (this.props.onNegativeClick) { this.props.onNegativeClick(); };
                            }}
                            optionsSelectMulti={this.props.optionsSelectMulti ? true : false}
                            permissions={this.props.permissions}
                            min={this.props?.min}
                            max={this.props?.max}
                        >
                            {this.props.optionsSelectMulti && 
                                this.props.children
                            }
                        </ModalEdit>
                    </div>
                }
            </div>
        )
    }
}