import { 
    GET_ALL_UNIT_COMPANY,
    CHANGE_UNIT_COMPANY,
    TOGGLE_CHECKED_ALL_UNIT_COMPANY,
    NEW_UNIT_COMPANY
}  from "../../core/constants";


//action creator
export function newUnitCompany (data)
{
    return {
        type: NEW_UNIT_COMPANY,
        payload: data,
    }
}

//action creator
export function getAllUnitCompany (data)
{
    return {
        type: GET_ALL_UNIT_COMPANY,
        payload: data
    }
}

//action creator
export function changeUnitCompany (data)
{
    return {
        type: CHANGE_UNIT_COMPANY,
        payload: data,
    }
}

//action creator
export function toggleCheckedAllUnitCompany (status)
{
    return {
        type: TOGGLE_CHECKED_ALL_UNIT_COMPANY,
        payload: status
    }
}