import React, { Component } from "react";
import "./CommissionRulesProspection.css";
import { connect } from "react-redux";
import { APPLICATION_NAME, commissionRuleProspection } from "../../core/constants";
import { showModalMoreActions } from "../../store/actions/general";

import Header from "../../components/CRM/Header";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import Message from "../../components/general/Message";
import TableEditCommissionRulesProspection from "../../components/general/TableEditCommissionRulesProspection";

import CommissionReportProspectionController from "../../controllers/CommissionReportProspectionController";
import CommissionRulesDetailsProspectionController from "../../controllers/CommissionRulesDetailsProspectionController";
import CommissionRulesProspectionController from "../../controllers/CommissionRulesProspectionController";
import { withRouter } from "react-router-dom";
import { formatedDateTimeToUSA, getDateTimeCurrentBrowserAtMidnight } from "../../auxiliary/generalFunctions";
import InputGeneral from "../../components/general/InputGeneral";

const initialState = {
    ruleCommissionReportProspection: null,
    commissionRulesDetailsProspection: [],

    buttonGenerateReportDisabled: false,
    buttonRemoveReportDisabled: false,

    // start Message Modal
    message_type: "information",
    message_show: false,
    message: "",
    // ends Message Modal

    currentMonthYear: "", //looks like: "2022-04"
}

const commissionReportProspectionController = new CommissionReportProspectionController();
const commissionRulesDetailsProspectionController = new CommissionRulesDetailsProspectionController();
const commissionRulesProspectionController = new CommissionRulesProspectionController();

class CommissionRulesProspection extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Regras Comissão - Vendas Operação`;
        let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight());

        await this.setCurrentMonthYear(currentDate);
        await this.applyFilters();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("COMMISSION RULES prevProps ", prevProps);
        // console.log("COMMISSION RULES this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}
    }

    setCurrentMonthYear = async (currentDate) =>
	{
		let parts = currentDate.toString().split("-");

		let y = parseInt(parts[0]);
		let m = parseInt(parts[1]).toString().padStart(2, '0');

		let currentMonthYear = `${y}-${m}`;

		await this.setState({currentMonthYear});
	}

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            const details = this.state.commissionRulesDetailsProspection.map(d => d.id === id ? {
                ...d,
                [propertyName]: newValue
            } : {...d});

            await this.setState({commissionRulesDetailsProspection: details});
        }
    }

    saveValue = async (id, field, value) =>
    {
        // WARNING: for maintenance.
        // console.log(`id: ${id}, field: ${field}, value: ${value}`);
        let result = {
            status: true,
        }

        result = await commissionRulesDetailsProspectionController.update(id, {
            [field]: value
        });
      
        if (!result.status) 
        {
            return this.message("error", result.message);
        }

        this.applyFilters();
    }

    applyFilters = async () => 
    {
        const currentMonthYearActive = await this.state.currentMonthYear;
        const resultRule = await commissionRulesProspectionController.get(currentMonthYearActive);
        
        if (!resultRule.status || resultRule.data?.number_of_lines === 0)
        {
            await this.setState({
                ruleCommissionReportProspection: null,
                commissionRulesDetailsProspection: []
            });
            return;
        }

        const rule = {
            id: resultRule.data.data[0].CRP_ID,
            month: resultRule.data.data[0].CRP_MONTH,
            status: resultRule.data.data[0].CRP_STATUS
        };

        const result = await commissionRulesProspectionController.getById(rule.id);
        if (!result.status)
        {
            await this.setState({commissionRulesDetailsProspection: []});
            return;
        }

        let rows = [];
        for (const detail of result.data.details) 
        {
            rows.push({
                id: detail.CPD_ID,
                group: detail.CPD_GROUP,
                type: detail.CPD_TYPE,
                typeCurrentRule: detail.CPD_TYPE_CURRENT_RULE,
                minimumPercent: detail.CPD_MINIMUM_PERCENT,
                maximumPercent: detail.CPD_MAXIMUM_PERCENT,
                minimumGoalDay: detail.CPD_MINIMUM_GOAL_DAY,
                minimumGoalMonth: detail.CPD_MINIMUM_GOAL_MONTH,
                amountCommission: detail.CPD_AMOUNT_COMMISSION,
                checked: false
            });
        }

        await this.setState({
            ruleCommissionReportProspection: rule, 
            commissionRulesDetailsProspection: rows
        });
    }

    generateCommissionReportProspection = async () =>
    {
        await this.setState({
            buttonGenerateReportDisabled: true
        });

        const idCommissionRule = this.state.ruleCommissionReportProspection?.id;
        if (!idCommissionRule)
        {
            await this.setState({
                buttonGenerateReportDisabled: false
            });
            return this.message("error", "ID da regra não definido!");
        }

        await commissionReportProspectionController.generate(idCommissionRule);
        await commissionReportProspectionController.monthClosure(this.state.currentMonthYear);

        this.message("success", "Relatório gerado com sucesso!");

        await this.setState({
            buttonGenerateReportDisabled: false
        });

        this.applyFilters();
    }

    removeCommissionReportProspection = async () =>
    {
        await this.setState({
            buttonRemoveReportDisabled: true
        });

        const idCommissionRule = this.state.ruleCommissionReportProspection?.id;
        if (!idCommissionRule)
        {
            await this.setState({
                buttonRemoveReportDisabled: false
            });
            return this.message("error", "ID da regra não definido!");
        }

        await commissionReportProspectionController.deleteById(idCommissionRule);

        this.message("success", "Relatório removido com sucesso!");

        await this.setState({
            buttonRemoveReportDisabled: false
        });

        this.applyFilters();
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render ()
    {
        return (
            <div className="commissionRulesProspection">
                <Header title="Regras Comissão - Vendas Operação" classaditional="headerCommissionRulesProspection"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="containerNavigationHeaderCommissionRulesProspection">                
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderCommissionRulesProspection ${!this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            this.setState({filtered: false});
                        }}
                    />
                    {
                        this.props.permissions.toInsert &&
                        <Button
                            icon="fal fa-plus"
                            name="&nbsp;&nbsp;Regra"
                            classaditional="buttonPlusNavigationHeaderCommissionRulesProspection"
                            onClick={() => {
                                let data = {
                                    show: true,
                                    type: commissionRuleProspection,
                                    data: {
                                        month: this.state.currentMonthYear,
                                        onActionForm: () => this.applyFilters()
                                    }
                                }
                                this.props.showModalMoreActions(data);
                            }}
                        />
                    }
                    {
                        (this.props.permissions.toDelete && this.state.ruleCommissionReportProspection?.status === 1) &&
                        <ButtonIcon
                            icon="fas fa-trash" 
                            title="Excluir relatório"
                            classaditional="buttonTrashNavigationHeaderCommissionRulesProspection"
                            onClick={() => this.removeCommissionReportProspection()}
                            disabled={this.state.buttonRemoveReportDisabled}
                        />
                    }
                    {
                        (this.state.ruleCommissionReportProspection?.status === 3 && this.state.commissionRulesDetailsProspection.length > 0) &&
                        <ButtonIcon
                            icon="fas fa-play" 
                            title="Gerar relatório"
                            classaditional="buttonTrashNavigationHeaderCommissionRulesProspection"
                            onClick={() => this.generateCommissionReportProspection()}
                            disabled={this.state.buttonGenerateReportDisabled}
                        />
                    }
                    <div className={`filterStatusHeaderCommissionRulesProspection d-none d-lg-flex ml-2 ml-auto`}>
                        <InputGeneral
                            classaditional="inputFilterCommissionRulesProspection"
                            type="month"
                            value={this.state.currentMonthYear}
                            onChange={async (e) => {
                                await this.setCurrentMonthYear(e.target.value);
                                await this.applyFilters();
                            }}
                        />
                    </div>
                </div>
                <div className="containerListHeaderCommissionRulesProspection">
                    <TableEditCommissionRulesProspection
                        data={this.state.commissionRulesDetailsProspection}
                        onChangeValue={this.changeValue}
                        onSaveValue={this.saveValue}
                        onIgnoreSave={async () => { this.applyFilters() }}
                        permissions={this.props.permissions} 
                        statusReport={this.state.ruleCommissionReportProspection?.status}
                        onRemoveDetailsProspection={async (idCommissionDetailsProspection) => {
                            await commissionRulesDetailsProspectionController.deleteById(idCommissionDetailsProspection);
                            this.applyFilters();
                        }}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const permissions = state.permissions.commissionRulesProspection;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        hasPermissionsLoaded,
        permissions
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommissionRulesProspection));