import React, { Component } from "react";
import "./DashboardMarketing.css";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
	APPLICATION_NAME, 
	months
} from "../../core/constants";
import {
	formatedDateTime,
	formatedDateTimeToBRA,
	formatedDateTimeToUSA,
	formatedPercentageNumberFromFloat,
	formatedTime,
	getCurrentTimeBrowser,
	getDateTimeCurrentBrowserAtMidnight,
	getOptionsSelectCheckedFormated,
	getPreviousDateTimeCurrentBrowserAtMidnight,
	sortInAlphabeticalOrder,
	validTime
} from "../../auxiliary/generalFunctions";

import personDefault from "../../assets/images/person-default.png";

import { setModalMessage } from "../../store/actions/general";

import Header from "../../components/CRM/Header";
import DatePickerCustom from "../../components/general/DatePickerCustom";
import Box from "../../components/general/Box";
import CardResumeWithHover from "../../components/CRM/CardResumeWithHover";
import MessageContent from "../../components/general/MessageContent";
import CardButonsTopLineChart from "../../components/CRM/CardButonsTopLineChart";

import DashboardMarketingController from "../../controllers/DashboardMarketingController";
import UserController from "../../controllers/UserController";
import SelectMulti from "../../components/general/SelectMulti";
import InputGeneral from "../../components/general/InputGeneral";
import Button from "../../components/general/Button";
import ButtonIcon from "../../components/general/ButtonIcon";

var DEFAULT_VALUES_STATE = {
	//start general charts data
	amountBusiness: 0,
	amountScheduled: 0,
	amountShowedUp: 0,
	amountGain: 0,
	//ends general charts data

    // start general results (cards)
	amountBusinessAllGeneralResults: 0,
	amountBusinessInProgressAttendanceGeneralResults: 0,
	amountBusinessLossGeneralResults: 0,
	detailsBusinessLossGeneralResults: [],
	amountBusinessPausedGeneralResults: 0,
	amountBusinessNotApplicableGeneralResults: 0,
	detailsBusinessNotApplicableGeneralResults: [],
	amountBusinessWithoutAttendanceGeneralResults: 0,
	amountScheduledGeneralResults: 0,
	percentUtilizationGeneralResults: 0,
	midiaOriginGeneralResults: {
		amountFacebook: 0,
		amountGoogle: 0,
		amountInstagram: 0,
		amountNoMidia: 0,
		amountPortals: 0,
		amountTV: 0,
		amountWebsite: 0,
		amountWhatsapp: 0,
		details: [],
		percentFacebook: 0,
		percentGoogle: 0,
		percentInstagram: 0,
		percentNoMidia: 0,
		percentPortals: 0,
		percentTV: 0,
		percentWebsite: 0,
		percentWhatsapp: 0,
	},
	// ends general results (cards)

	// start month results (cards)
	amountBusinessAllMonthResults: 0,
	amountBusinessBeforeMonthMonthResults: 0,
	amountBusinessInProgressAttendanceMonthResults: 0,
	amountBusinessLossMonthResults: 0,
	detailsBusinessLossMonthResults: [],
	amountBusinessPausedMonthResults: 0, 
	amountBusinessMonthMonthResults: 0,
	amountBusinessNotApplicableMonthResults: 0,
	detailsBusinessNotApplicableMonthResults: [],
	amountBusinessTodayMonthResults: 0,
	amountBusinessWeekMonthResults: 0,
	amountBusinessWithoutAttendanceMonthResults: 0,
	amountScheduledMonthResults: 0,
	percentMonthOverBeforeMonthMonthResults: 0,
	percentUtilizationMonthResults: 0,
	midiaOriginMonthResults: {
		amountFacebook: 0,
		amountGoogle: 0,
		amountInstagram: 0,
		amountNoMidia: 0,
		amountPortals: 0,
		amountTV: 0,
		amountWebsite: 0,
		amountWhatsapp: 0,
		details: [],
		percentFacebook: 0,
		percentGoogle: 0,
		percentInstagram: 0,
		percentNoMidia: 0,
		percentPortals: 0,
		percentTV: 0,
		percentWebsite: 0,
		percentWhatsapp: 0,
	},
	// ends month results (cards)

    // start message content modal
    message_type_content: "information",
    message_show_content: false,
    message_content_content: null, //DOM element
    message_title_content: "",
    // ends message content modal

	updatedTopLineChart: false,
    charts: [],

    loading: true,
}

const initialState = {
	name: "", 
	mail: "", //looks like: "name@domain.com"
	photo: personDefault, //looks like: "https://i.pinimg.com/564x/d3/1a/e3/d31ae3d4130a644b4f53eaefdaa481f0.jpg"
	lastAccess: "", //looks like: "01/01/2022 às 14hs"
	currentMonthYear: "", //looks like: "2022-04"
	currentMonthName: "", //looks like: "Abril"
	dateRunning: "", //looks like: "2022/12/31"
	chosenStartDateReport: "", //looks like: "2022-09-01"
	chosenEndsDateReport: "", //looks like: "2022-09-31"
	isWithinTheSameMonth: true, //defatult: true
	timeStartDataReport: "00:00",
	timeEndsDataReport: "00:00",
	optionsUnitCompany: [],
	optionsUnitBrand: [],
	buttonsTopLineChart: [],

	...DEFAULT_VALUES_STATE
}

const MESSAGE_WAITING_REPORT_IN_PROGRESS = "Por favor, aguarde a geração do relatório em andamento, para poder escolher outro período!";

const dashboardMarketingController = new DashboardMarketingController();
const userController = new UserController();
class DashboardMarketing extends Component
{
	_mounted = false;
	state = {...initialState}

	componentDidMount = async () =>
	{
		this._mounted = true;
		document.title = `${APPLICATION_NAME} - Marketing`;

		await this.getInitialParameters();
		await this.getCompanyUnit();
		let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
		let paramsDate = await this.getCurrentFirstAndLastDayOnMonth(currentDate);
		
		if (this._mounted)
		{
			await this.setState({
				user: this.props.user,
				dateRunning: currentDate,
				...paramsDate
			});
	
			await this.getDataDashboardMarketing();
		}
	}

	componentDidUpdate = async (prevProps) =>
    {
        // console.log("DASHBOARD MARKETING prevState ", prevState);
        // console.log("DASHBOARD MARKETING this.state ", this.state.allSpecialists);

		if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			// if (!this.props.dashboardMarketing.toView) { this.props.history.push(`/activities`); }
		}
    }

	getInitialParameters = async () =>
	{
		let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
		let paramsDate = await this.getCurrentFirstAndLastDayOnMonth(currentDate);
		
		if (this._mounted)
		{
			await this.setState({
				user: this.props.user,
				dateRunning: currentDate,
				timeEndsDataReport: "23:59",
				...paramsDate
			});
		}
	}

	getCurrentFirstAndLastDayOnMonth = async (chosenMonthYear) =>
	{
		let parts = chosenMonthYear.toString().split("-");
		let y = parseInt(parts[0]);
		let m = parseInt(parts[1]).toString().padStart(2, '0');
		let currentMonthYear = `${y}-${m}`;
		let chosenStartDateReport = formatedDateTimeToUSA((new Date(y, (m - 1), 1)).toDateString());
		let chosenEndsDateReport = formatedDateTimeToUSA((new Date(y, m, 0)).toDateString());
		let currentMonthName = months.find(o => o.order === parseInt(m - 1)).month.toString().toLocaleUpperCase();

		return {
			currentMonthYear,
			chosenStartDateReport,
			chosenEndsDateReport,
			currentMonthName,
		}
	}

	getCompanyUnit = async () => 
    {
        const result = await userController.getById(this.props.user.PER_ID);
		let optionsUnitBrandChecked = [];
        let optionsUnitCompany = [];
		let optionsUnitBrand = [];
		
		// getting all possible brands...
        for (const unit of result.data.unit_companies) 
        {
			let inserted = false;

			if (optionsUnitBrand.length > 0)
			{
				inserted = optionsUnitBrand.filter(b => b.value === unit.BNT_ID);
				inserted = inserted.length > 0 ? true : false;
			}

			if (!inserted) 
			{
				optionsUnitBrand.push(
					{
						name: `${unit.BNT_NAME}`,
						value: unit.BNT_ID,
						checked: (unit.BNT_ID !== 8 && unit.BNT_ID !== 9 && unit.BNT_ID !== 6) ? true : false
					}
				);
			}
        }

		optionsUnitBrandChecked = optionsUnitBrand.filter(b => b.checked).map(b => b.value);

		// getting all possible units...
        for (const unit of result.data.unit_companies) 
        {
            optionsUnitCompany.push(
				{
					name: `${unit.UNT_NAME}`,
					value: unit.UNT_ID,
					checked: (optionsUnitBrandChecked.includes(unit.BNT_ID) && unit.UNT_ID !== 14) ? true : false,
					brand: unit.BNT_ID
            	}
			);
        }

		if (this._mounted)
		{
			optionsUnitCompany = sortInAlphabeticalOrder(optionsUnitCompany);
			optionsUnitBrand = sortInAlphabeticalOrder(optionsUnitBrand);
			await this.setState({ optionsUnitCompany, optionsUnitBrand });
			this.onChangeUnitBrand(optionsUnitBrand);
		}
    }

	onChangeUnitBrand = async (optionsUnitBrand) =>
	{
		let optionsUnitCompany = await this.state.optionsUnitCompany;
		let optionsUnitBrandChecked = optionsUnitBrand.filter(b => b.checked).map(b => b.value);

		optionsUnitCompany = optionsUnitCompany.map(u => {
			u.checked = (optionsUnitBrandChecked.includes(u.brand) && u.value !== 14); //14 = company unit (Recife)
			return u;
		});

		if (this._mounted)
		{
			optionsUnitCompany = sortInAlphabeticalOrder(optionsUnitCompany);
			await this.setState({ optionsUnitCompany, optionsUnitBrand });
		}
	}

	setDefaultValuesState = async () =>
	{
		if (this._mounted)
		{
			await this.setState({...DEFAULT_VALUES_STATE});
		}
	}

	getDataDashboardMarketing = async () =>
	{
		let user = await this.state.user;
		let chosenStartDateReport = await this.state.chosenStartDateReport;
		let chosenEndsDateReport = await this.state.chosenEndsDateReport;
		let dateRunning = await this.state.dateRunning;
		let timeStartDataReport = await this.state.timeStartDataReport;
		let timeEndsDataReport = await this.state.timeEndsDataReport;
		let optionsUnitCompany = await this.state.optionsUnitCompany.filter(u => u.checked).map(u => u.value);
		let filters = {
			units: [...optionsUnitCompany]
		};

		if (timeStartDataReport.length <= 4 || timeEndsDataReport.length <= 4)
		{
			await this.message("information", "Confira os campos de horas de início e fim, e tente novamente!");
			return;
		}

		await this.setDefaultValuesState();

		let resultDashboardMarketing = await dashboardMarketingController.get(
				user.PER_ID,
				chosenStartDateReport,
				chosenEndsDateReport,
				timeStartDataReport,
				timeEndsDataReport,
				dateRunning,
				filters
			);
		
		if (resultDashboardMarketing.status)
		{
			let {
				name, 
				mail,
				photo, 
				lastAccess,
				charts,
				generalResults,
				monthResults,
				attendanceByUsers
			} = resultDashboardMarketing.data.data;

			let amountBusinessAllMonthResults = 0;
			let amountBusinessBeforeMonthMonthResults = 0;
			let amountBusinessInProgressAttendanceMonthResults = 0;
			let amountBusinessLossMonthResults = 0;
			let amountBusinessPausedMonthResults = 0;
			let amountBusinessMonthMonthResults = 0;
			let amountBusinessNotApplicableMonthResults = 0;
			let amountBusinessTodayMonthResults = 0;
			let amountBusinessWeekMonthResults = 0;
			let amountBusinessWithoutAttendanceMonthResults = 0;
			let amountScheduledMonthResults = 0;
			let percentMonthOverBeforeMonthMonthResults = 0;
			let percentUtilizationMonthResults = 0;
			let detailsBusinessNotApplicableMonthResults = [];
			let detailsBusinessLossMonthResults = [];
			let midiaOriginMonthResults = {};

			let amountBusinessAllGeneralResults = 0;
			let amountBusinessInProgressAttendanceGeneralResults = 0;
			let amountBusinessLossGeneralResults = 0;
			let detailsBusinessLossGeneralResults = [];
			let amountBusinessPausedGeneralResults = 0;
			let amountBusinessNotApplicableGeneralResults = 0;
			let detailsBusinessNotApplicableGeneralResults = [];
			let amountBusinessWithoutAttendanceGeneralResults = 0;
			let amountScheduledGeneralResults = 0;
			let percentUtilizationGeneralResults = 0;
			let midiaOriginGeneralResults = {};

			if (generalResults && monthResults)
			{
				amountBusinessAllMonthResults = monthResults.amountBusinessAll;
				amountBusinessBeforeMonthMonthResults = monthResults.amountBusinessBeforeMonth;
				amountBusinessInProgressAttendanceMonthResults = monthResults.amountBusinessInProgressAttendance;
				amountBusinessLossMonthResults = monthResults.amountBusinessLoss;
				amountBusinessPausedMonthResults = monthResults.amountBusinessPaused;
				amountBusinessMonthMonthResults = monthResults.amountBusinessMonth;
				amountBusinessNotApplicableMonthResults = monthResults.amountBusinessNotApplicable;
				amountBusinessTodayMonthResults = monthResults.amountBusinessToday;
				amountBusinessWeekMonthResults = monthResults.amountBusinessWeek;
				amountBusinessWithoutAttendanceMonthResults = monthResults.amountBusinessWithoutAttendance;
				amountScheduledMonthResults = monthResults.amountScheduled;
				percentMonthOverBeforeMonthMonthResults = monthResults.percentMonthOverBeforeMonth;
				percentUtilizationMonthResults = monthResults.percentUtilization;
				detailsBusinessNotApplicableMonthResults = monthResults.detailsBusinessNotApplicable;
				detailsBusinessLossMonthResults = monthResults.detailsBusinessLoss;
				midiaOriginMonthResults = monthResults.midiaOrigin;
				
				amountBusinessAllGeneralResults = generalResults.amountBusinessAll;
				amountBusinessInProgressAttendanceGeneralResults = generalResults.amountBusinessInProgressAttendance;
				amountBusinessLossGeneralResults = generalResults.amountBusinessLoss;
				amountBusinessPausedGeneralResults = generalResults.amountBusinessPaused;
				amountBusinessNotApplicableGeneralResults = generalResults.amountBusinessNotApplicable;
				amountBusinessWithoutAttendanceGeneralResults = generalResults.amountBusinessWithoutAttendance;
				amountScheduledGeneralResults = generalResults.amountScheduled;
				percentUtilizationGeneralResults = generalResults.percentUtilization;
				detailsBusinessNotApplicableGeneralResults = generalResults.detailsBusinessNotApplicable;
				detailsBusinessLossGeneralResults = generalResults.detailsBusinessLoss;
				midiaOriginGeneralResults = generalResults.midiaOrigin;

			}

			if (this._mounted)
			{
				await this.setState({
					name,
					mail,
					photo: photo === "" ? personDefault : photo,
					lastAccess: formatedDateTime(lastAccess),
					charts,
					attendanceByUsers,
					loading: false,
					// start general results
					amountBusinessAllGeneralResults,
					amountBusinessInProgressAttendanceGeneralResults,
					amountBusinessLossGeneralResults,
					detailsBusinessLossGeneralResults,
					midiaOriginGeneralResults,
					amountBusinessPausedGeneralResults,
					amountBusinessNotApplicableGeneralResults,
					detailsBusinessNotApplicableGeneralResults,
					amountBusinessWithoutAttendanceGeneralResults,
					amountScheduledGeneralResults,
					percentUtilizationGeneralResults,
					// ends general results
					// start month results
					amountBusinessAllMonthResults,
					amountBusinessBeforeMonthMonthResults,
					amountBusinessInProgressAttendanceMonthResults,
					amountBusinessLossMonthResults,
					detailsBusinessLossMonthResults,
					midiaOriginMonthResults,
					amountBusinessPausedMonthResults,
					amountBusinessMonthMonthResults,
					amountBusinessNotApplicableMonthResults,
					detailsBusinessNotApplicableMonthResults,
					amountBusinessTodayMonthResults,
					amountBusinessWeekMonthResults,
					amountBusinessWithoutAttendanceMonthResults,
					amountScheduledMonthResults,
					percentMonthOverBeforeMonthMonthResults,
					percentUtilizationMonthResults,
					// ends month results
				});

				await this.setDataButtonsTopLineChart();
			}
		}

	}

	getRadomDataForTopLineChartByDays = (amountDays, title) =>
	{
		let data = [];
		let week = ["dom.", "seg.", "ter.", "qua.", "qui.", "sex.", "sáb."];
		let month = ["jan.", "fev.", "mar.", "abr.", "mai.", "jun.", "jul.", "ago.", "set.", "out.", "nov.", "dez."];

		for (let i = amountDays; i > 0; i--) 
		{			
			let dateToday = getPreviousDateTimeCurrentBrowserAtMidnight(i);
			let dayToday = dateToday.getDate().toString().padStart("2", "0");
			let dateTodayWeek = week[dateToday.getDay()];
			let monthToday = month[dateToday.getMonth()];
			dateToday = formatedDateTimeToBRA(dateToday);

			let dateBeforeEquivalent = getPreviousDateTimeCurrentBrowserAtMidnight(28 + i);
			let dayBefore = dateBeforeEquivalent.getDate().toString().padStart("2", "0");
			let monthBefore = month[dateBeforeEquivalent.getMonth()];
			let dateBeforeEquivalentWeek = week[dateBeforeEquivalent.getDay()];
			dateBeforeEquivalent = formatedDateTimeToBRA(dateBeforeEquivalent);

			let obj = {
				name: `${dayToday} ${monthToday}`,
				nameBefore: `${dateBeforeEquivalentWeek} ${dayBefore} ${monthBefore}`,
				nameAfter: `${dateTodayWeek} ${dayToday} ${monthToday}`,
				after: parseInt(Math.random() * 400),
				before: parseInt(Math.random() * 400),
				title,
			}
			data.push(obj);
			// INFORMATION: don't remove!
			// console.log(`dateToday: ${dateToday} (${dateTodayWeek}) , dateBeforeEquivalent: ${dateBeforeEquivalent} (${dateBeforeEquivalentWeek}) `);
		}
		
		return data;
	}

	getInformationCompilation = (data) => 
	{
		let amountBeforePerformance = 0;
		let amountAfterPerformance = 0;
		
		data.forEach(d => {
			amountBeforePerformance += d.before;
			amountAfterPerformance += d.after;
		});

		// amountAfterPerformance = parseFloat(amountAfterPerformance).toLocaleString('pt-br', {minimumFractionDigits: 0});
		// amountBeforePerformance = parseFloat(amountBeforePerformance).toLocaleString('pt-br', {minimumFractionDigits: 0});

		return {
			amountBeforePerformance,
			amountAfterPerformance
		}
	}

	setDataButtonsTopLineChart = async () =>
	{
		let chartsState = await this.state.charts;
		let chartOne = chartsState[0];
		let chartTwo = chartsState[1];
		let compilationOne = await this.getInformationCompilation(chartOne);
		let compilationTwo = await this.getInformationCompilation(chartTwo);

		console.log("chartOne: ", chartOne);

		let charts = [
			{
				indexButtonTarget: 0,
				chart: chartOne,
			},
			{
				indexButtonTarget: 1,
				chart: chartTwo,
			},
		];

		let buttonsTopLineChart = [
			{
				index: 0,
				active: true,
				label: "Leads",
				amountBeforePerformance: compilationOne.amountBeforePerformance,
				amountAfterPerformance: compilationOne.amountAfterPerformance,
			},
			{
				index: 1,
				active: false,
				label: "Negócios",
				amountBeforePerformance: compilationTwo.amountBeforePerformance,
				amountAfterPerformance: compilationTwo.amountAfterPerformance,
			},
		];

		let chartTopLineChart = charts;

		if (this._mounted)
		{
			await this.setState({buttonsTopLineChart, chartTopLineChart, updatedTopLineChart: true});
		}
	}

	setDatesAndTimesToday = async () =>
	{
		let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
		let loading = await this.state.loading;
		let timeEndsDataReport = getCurrentTimeBrowser();

		if (loading)
		{
			this.message("information", MESSAGE_WAITING_REPORT_IN_PROGRESS)
			return;
		}

		if (this._mounted)
		{
			await this.setState({
				chosenStartDateReport: currentDate,
				chosenEndsDateReport: currentDate,
				timeStartDataReport: "00:00",
				timeEndsDataReport,
				dateRunning: currentDate,
				attendanceByUsersAttendants: []
			});

			await this.getDataDashboardMarketing();
		}

	}

	getColorAndSignalAndValue = (number) =>
	{
		let color = "";
		let value = parseFloat(number);

		if (number > 0)
		{
			color = "success";
		}
		else if (number < 0)
		{
			color = "error";
			value =  Math.abs(value);
		}
		else
		{
			color = "focus";
		}

		return {color, value}
	}

	getCurrentFirstAndLastDayOnMonth = async (chosenMonthYear) =>
	{
		let parts = chosenMonthYear.toString().split("-");
		let y = parseInt(parts[0]);
		let m = parseInt(parts[1]).toString().padStart(2, '0');
		let currentMonthYear = `${y}-${m}`;
		let chosenStartDateReport = formatedDateTimeToUSA((new Date(y, (m - 1), 1)).toDateString());
		let chosenEndsDateReport = formatedDateTimeToUSA((new Date(y, m, 0)).toDateString());
		let currentMonthName = months.find(o => o.order === parseInt(m - 1)).month.toString().toLocaleUpperCase();

		return {
			currentMonthYear,
			chosenStartDateReport,
			chosenEndsDateReport,
			currentMonthName,
		}
	}

	getAnchorForDetailsCard = (title, amount) =>
	{
		return (
			<div 
				key={parseInt(Math.random()*123456789)}
				className="row no-gutters w-100 lineDetailsCardDashboardMarketing"
				onClick={() => {}}
			>
				<div className="col-3">
					{amount}
				</div>
				<div className="col-7">
					{title}
				</div>
				<div className="col-2 d-flex justify-content-end">
					<i className="fas fa-eye iconDetailsCardDashboardMarketing"></i>
				</div>
			</div>
		);
	}

	getChosenDatesReport = () =>
	{
		return `${formatedDateTimeToBRA(this.state.chosenStartDateReport)} a ${formatedDateTimeToBRA(this.state.chosenEndsDateReport)}`;
	}

	showDetaisCard = async (type) =>
	{
		let loading = await this.state.loading;

		if (loading) { return;  }

		switch (type) {
			case "monthWebsite":
				let midiaOriginwebsiteMonthResults = await this.state.midiaOriginMonthResults.details;
				midiaOriginwebsiteMonthResults = midiaOriginwebsiteMonthResults.filter(m => m.type === 1);
				if (midiaOriginwebsiteMonthResults.length === 0) { return; }
				await this.messageContent(
					"information",
					'"SITE" (detalhamento)',
					midiaOriginwebsiteMonthResults.map(n => this.getAnchorForDetailsCard(n.name, n.amount))
				);
				break;
			case "monthPortals":
				let midiaOriginPortalsMonthResults = await this.state.midiaOriginMonthResults.details;
				midiaOriginPortalsMonthResults = midiaOriginPortalsMonthResults.filter(m => m.type === 2);
				if (midiaOriginPortalsMonthResults.length === 0) { return; }
				await this.messageContent(
					"information",
					'"PORTAIS" (detalhamento)',
					midiaOriginPortalsMonthResults.map(n => this.getAnchorForDetailsCard(n.name, n.amount))
				);
				break;
			case "generalWebsite":
				let midiaOriginWebsiteGeneralResults = await this.state.midiaOriginGeneralResults.details;
				midiaOriginWebsiteGeneralResults = midiaOriginWebsiteGeneralResults.filter(m => m.type === 1);
				if (midiaOriginWebsiteGeneralResults.length === 0) { return; }
				await this.messageContent(
					"information",
					'"SITE" (detalhamento)',
					midiaOriginWebsiteGeneralResults.map(n => this.getAnchorForDetailsCard(n.name, n.amount))
				);
				break;
			case "generalPortals":
				let midiaOriginPortalsGeneralResults = await this.state.midiaOriginGeneralResults.details;
				midiaOriginPortalsGeneralResults = midiaOriginPortalsGeneralResults.filter(m => m.type === 2);
				if (midiaOriginPortalsGeneralResults.length === 0) { return; }
				await this.messageContent(
					"information",
					'"PORTAIS" (detalhamento)',
					midiaOriginPortalsGeneralResults.map(n => this.getAnchorForDetailsCard(n.name, n.amount))
				);
				break;
		
			default:
				break;
		}
	}

	setChosenMonthReport = async (chosenMonth) =>
	{
		let paramsDate = null;

		if (typeof chosenMonth === "string" && chosenMonth.trim() !== "")
		{
			paramsDate = await this.getCurrentFirstAndLastDayOnMonth(chosenMonth);
		}
		else
		{
			// chosenMonth is not empty!
			let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
			paramsDate = await this.getCurrentFirstAndLastDayOnMonth(currentDate);
		}

		if (this._mounted)
		{
			await this.setState({...paramsDate});
		}
	}

	componentWillUnmount = async () =>
	{
		this._mounted = false;
		console.info("DASHBOARD MARKETING demoting.");
	}

	messageContent = async (type, title, content) =>
	{
		await this.setState({
			message_show_content: true,
			message_content_content: content,
			message_type_content: type,
			message_title_content: title
		});
	}

	message = (type, message) =>
	{
        this.props.setModalMessage({show: true, type, message});
	}

	render() 
	{
		return (
			<div className="dashboardMarketing">
				<Header title="Dashboard (Marketing)" classaditional="headerDashboardMarketing" />
                <MessageContent
                    show={this.state.message_show_content}
                    message_type={this.state.message_type_content}
					message_title={this.state.message_title_content}
                    onClose={() => this.setState({message_show_content: false, message_content_content: null})}
                >
                    {this.state.message_content_content}
                </MessageContent>
				<div className="containerBodyDashboardMarketing">
					{
						this.props.permissionMonths.toView &&
						<Box classaditional="boxHeaderFiltersDashboardMarketing filtersDashboardMarketing">
							<div className="filterCompanyUnitDashboardMarketing m-1 justify-content-between">
								<i className="fas fa-building iconFilterCompanyUnitDashboardMarketing"></i>
								<SelectMulti
									classaditional="selectCompanyUnitDashboardMarketing"
									default={{name: getOptionsSelectCheckedFormated(this.state.optionsUnitBrand, "[Todas Brands]", "brands")}}
									options={this.state.optionsUnitBrand} 
									onChange={async (optionsUnitBrand) => {												
										if (this.state.loading)
										{
											this.message("information", MESSAGE_WAITING_REPORT_IN_PROGRESS)
										}
										else
										{
											this.onChangeUnitBrand(optionsUnitBrand);
										}	
									}}
									withFieldSearch
								/>
							</div>							
							<div className="filterCompanyUnitDashboardMarketing m-1">
								<i className="fas fa-building iconFilterCompanyUnitDashboardMarketing"></i>
								<SelectMulti
									classaditional="selectCompanyUnitDashboardMarketing"
									default={{name: getOptionsSelectCheckedFormated(this.state.optionsUnitCompany, "[Todas Unidades]", "unidades")}}
									options={this.state.optionsUnitCompany}
									onChange={async (optionsUnitCompany) => {												
										if (this.state.loading)
										{
											this.message("information", MESSAGE_WAITING_REPORT_IN_PROGRESS)
										}
										else
										{
											await this.setState({optionsUnitCompany});
										}	
									}}
									withFieldSearch
								/>
							</div>
							<DatePickerCustom
								classaditional="inputFilterDashboardMarketing m-1"
								value={this.state.chosenStartDateReport}
								onChange={async (chosenStartDateReport) => {												
									if (this.state.loading)
									{
										this.message("information", MESSAGE_WAITING_REPORT_IN_PROGRESS)
									}
									else
									{
										if (chosenStartDateReport.toString().trim() === "")
										{
											let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
											let month = await this.getCurrentFirstAndLastDayOnMonth(currentDate);
											chosenStartDateReport = formatedDateTimeToUSA(month.chosenStartDateReport);
										}
										await this.setState({chosenStartDateReport});
									}	
								}}
							/>
							<DatePickerCustom
								classaditional="inputFilterDashboardMarketing m-1"
								value={this.state.chosenEndsDateReport}
								onChange={async (chosenEndsDateReport) => {												
									if (this.state.loading)
									{
										this.message("information", MESSAGE_WAITING_REPORT_IN_PROGRESS)
									}
									else
									{
										if (chosenEndsDateReport.toString().trim() === "")
										{
											let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight().toDateString()); //looks like "2022-04-01"
											let month = await this.getCurrentFirstAndLastDayOnMonth(currentDate);
											chosenEndsDateReport = formatedDateTimeToUSA(month.chosenEndsDateReport);
										}
										await this.setState({chosenEndsDateReport});
									}	
								}}
							/>
							<InputGeneral
								classaditional="inputTimeFilterDashboardMarketing m-1"
								value={`${this.state.timeStartDataReport}`}
								onChange={async (e) => {
									let time = e.target.value;
									time = (time.length > 4) ? validTime(time) : time;
									await this.setState({timeStartDataReport: formatedTime(time)});
								}}
								onClear={async () => await this.setState({timeStartDataReport: "00:00"})}
								maxLength={5}
							/>
							<InputGeneral
								classaditional="inputTimeFilterDashboardMarketing m-1"
								value={`${this.state.timeEndsDataReport}`}
								onChange={async (e) => {
									let time = e.target.value;
									time = (time.length > 4) ? validTime(time) : time;
									await this.setState({timeEndsDataReport: formatedTime(time)});

								}}
								onClear={async () => await this.setState({timeEndsDataReport: "23:59"})}
								maxLength={5}
							/>
							<Button
								classaditional="buttonTodayDashboardMarketing"
								name="HOJE"
								onClick={() => this.setDatesAndTimesToday()}
							/>
							<ButtonIcon
								classaditional="buttonSearchReportDashboardMarketing m-1"
								icon="far fa-search"
								onClick={async () => {
									let loading = await this.state.loading;
									if (loading)
									{
										this.message("information", MESSAGE_WAITING_REPORT_IN_PROGRESS);
									}
									else
									{
										await this.getDataDashboardMarketing();
									}
								}}
							/>
						</Box>						
					}
					{
						this.props.permissionMonths.toView &&
						<div className="row no-gutters lineHeaderDashboardMarketing d-none d-md-flex">
							<Box classaditional="boxHeaderDashboardMarketing">
								<h4>Resultados de: {this.getChosenDatesReport()}</h4>
							</Box>
						</div>
					}
					{/* RESERVED */}
					{/* <div className="row no-gutters lineBodyDashboardMarketing">
						<div className="col-12 columnBodyDashboardMarketing pl-md-2 pr-md-2">
							<CardInfoLeftChart
								classaditional="cardInfoLeftChartDashboardMarketing exclusive"
								title={`${getGreetingMessage()}, ${this.state.name}`}
								typeColorTitle="success"
								amountColumnLeft={4}
								amountColumnRight={8}
								information={(
									<div className="areaDashboardMarketing">
										<img src={`${this.state.photo}`} alt="Foto de Ana" className="img-fluid avatarDashboardMarketing" />
									</div>
								)}
							>
								<div className="itemInfoLeftChartDashboardMarketing"><span className="featured">E-mail:</span>&nbsp;{this.state.mail}</div>
								<div className="itemInfoLeftChartDashboardMarketing"><span className="featured">Último acesso:</span>&nbsp;{this.state.lastAccess}hs</div>
							</CardInfoLeftChart>
						</div>
					</div> */}
					<CardButonsTopLineChart
						classaditional=""
						buttons={this.state.buttonsTopLineChart}
						charts={this.state.chartTopLineChart}
						updated={this.state.updatedTopLineChart}
					/>
                    <div>
                        <div className="row no-gutters lineBodyDashboardMarketing">
                            <div className="col-12 columnBodyDashboardMarketing pl-md-2 pr-md-2">
                                <Box classaditional="boxDashboardMarketing">
                                    <h4 className="titleBoxDashboardPropsection information">Resultados do Mês</h4>
                                    <div className="row no-gutters lineBoxDashboardMarketing">
                                        <CardResumeWithHover
                                            icon="fas fa-calendar-day"
                                            title="Leads de Hoje"
                                            labelClick={`${this.state.amountBusinessTodayMonthResults}`}
                                        />
                                        <CardResumeWithHover
                                            icon="fas fa-calendar-week"
                                            title="Leads da Semana"
                                            labelClick={`${this.state.amountBusinessWeekMonthResults}`}
                                        />
                                        <CardResumeWithHover
                                            icon="fas fa-calendar"
                                            title="Leads do Mês"
                                            labelClick={`${this.state.amountBusinessMonthMonthResults}`}
                                        />
                                        <CardResumeWithHover
                                            icon="fas fa-calendar"
                                            title="Leads do Mês Anterior"
                                            labelClick={`${this.state.amountBusinessBeforeMonthMonthResults}`}
                                        />
                                        <CardResumeWithHover
                                            icon="fas fa-greater-than-equal"
                                            title="Relação ao Mês Anterior"
                                            backgroundClick={`${this.getColorAndSignalAndValue(this.state.percentMonthOverBeforeMonthMonthResults).color}`}
                                            labelClick={`
                                                ${formatedPercentageNumberFromFloat(this.getColorAndSignalAndValue(this.state.percentMonthOverBeforeMonthMonthResults).value)}%
                                            `}
                                        />
                                    </div>
                                    <div className="row no-gutters lineBoxDashboardMarketing">
                                        <CardResumeWithHover
                                            icon="fab fa-whatsapp"
                                            title="WhatsApp"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginMonthResults.amountWhatsapp} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginMonthResults.percentWhatsapp)}% `}
                                            colorBackground="#075e54"
                                            colorTitle="var(--white-light)"
                                        />
                                        <CardResumeWithHover
                                            icon="fab fa-facebook"
                                            title="Facebook"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginMonthResults.amountFacebook} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginMonthResults.percentFacebook)}% `}
                                            onClick={() => {}}
                                            colorBackground="#4267B2"
                                            colorTitle="var(--white-light)"
                                        />
                                        <CardResumeWithHover
                                            icon="fab fa-instagram"
                                            title="Instagram"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginMonthResults.amountInstagram} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginMonthResults.percentInstagram)}% `}
                                            onClick={() => {}}
                                            colorBackground="radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)"
                                            colorTitle="var(--white-light)"
                                        />
                                        <CardResumeWithHover
                                            icon="fab fa-google"
                                            title="Google"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginMonthResults.amountGoogle} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginMonthResults.percentGoogle)}% `}
                                            onClick={() => {}}
                                            colorBackground="#DB4437"
                                            colorTitle="var(--white-light)"
                                        />
                                        <CardResumeWithHover
                                            icon="fas fa-globe-americas"
                                            title="Site"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginMonthResults.amountWebsite} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginMonthResults.percentWebsite)}% `}
                                            onClick={() => this.showDetaisCard("monthWebsite")}
                                            colorBackground="var(--color-focus)"
                                            colorTitle="var(--white-light)"
                                        />
                                        <CardResumeWithHover
                                            icon="fas fa-network-wired"
                                            title="Portais"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginMonthResults.amountPortals} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginMonthResults.percentPortals)}% `}
                                            onClick={() => this.showDetaisCard("monthPortals")}
                                            colorBackground="var(--color-warning)"
                                            colorTitle="var(--white-light)"
                                        />
                                        <CardResumeWithHover
                                            icon="fas fa-tv"
                                            title="Televisão"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginMonthResults.amountTV} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginMonthResults.percentTV)}% `}
                                            onClick={() => {}}
                                            colorBackground="var(--color-information)"
                                            colorTitle="var(--white-light)"
                                        />
                                    </div>
                                </Box>						
                                <Box classaditional="boxDashboardMarketing">
                                    <h4 className="titleBoxDashboardPropsection information">Resultados Gerais</h4>
                                    <div className="row no-gutters lineBoxDashboardMarketing">
                                        <CardResumeWithHover
                                            icon="fab fa-whatsapp"
                                            title="WhatsApp"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginGeneralResults.amountWhatsapp} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginGeneralResults.percentWhatsapp)}% `}
                                            colorBackground="#075e54"
                                            colorTitle="var(--white-light)"
                                        />
                                        <CardResumeWithHover
                                            icon="fab fa-facebook"
                                            title="Facebook"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginGeneralResults.amountFacebook} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginGeneralResults.percentFacebook)}% `}
                                            onClick={() => {}}
                                            colorBackground="#4267B2"
                                            colorTitle="var(--white-light)"
                                        />
                                        <CardResumeWithHover
                                            icon="fab fa-instagram"
                                            title="Instagram"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginGeneralResults.amountInstagram} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginGeneralResults.percentInstagram)}% `}
                                            onClick={() => {}}
                                            colorBackground="radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)"
                                            colorTitle="var(--white-light)"
                                        />
                                        <CardResumeWithHover
                                            icon="fab fa-google"
                                            title="Google"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginGeneralResults.amountGoogle} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginGeneralResults.percentGoogle)}% `}
                                            onClick={() => {}}
                                            colorBackground="#DB4437"
                                            colorTitle="var(--white-light)"
                                        />
                                        <CardResumeWithHover
                                            icon="fas fa-globe-americas"
                                            title="Site"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginGeneralResults.amountWebsite} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginGeneralResults.percentWebsite)}% `}
                                            onClick={() => this.showDetaisCard("generalWebsite")}
                                            colorBackground="var(--color-focus)"
                                            colorTitle="var(--white-light)"
                                        />
                                        <CardResumeWithHover
                                            icon="fas fa-network-wired"
                                            title="Portais"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginGeneralResults.amountPortals} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginGeneralResults.percentPortals)}% `}
                                            onClick={() => this.showDetaisCard("generalPortals")}
                                            colorBackground="var(--color-warning)"
                                            colorTitle="var(--white-light)"
                                        />
                                        <CardResumeWithHover
                                            icon="fas fa-tv"
                                            title="Televisão"
                                            width={155}
                                            labelClick={`${this.state.midiaOriginGeneralResults.amountTV} | ${formatedPercentageNumberFromFloat(this.state.midiaOriginGeneralResults.percentTV)}% `}
                                            onClick={() => {}}
                                            colorBackground="var(--color-information)"
                                            colorTitle="var(--white-light)"
                                        />
                                    </div>
                                </Box>                                
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) 
{
	const permissionMonths = state.permissions.dashboardMonths;
	const {hasPermissionsLoaded, dashboardMarketing} = state.permissions;
	const {user} = state.auth;
	
	return {
		user,
		hasPermissionsLoaded,
		dashboardMarketing,
		permissionMonths,
	}
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardMarketing));