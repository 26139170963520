import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class ProspectionTeamHierarchyController 
{
    async get() 
    {
        const response = await api.prospectionTeamHierarchy().get();
        return apiCallbackTreatment(response);
    }

    getProspectionOwners(coordinators, userId, permissions, userUnits = [], includeWithoutAttendant = true)
    {
        // example userUnits = [1, 2, 3, 4 ,5 ,6]
        userId = parseInt(userId);
        const coordinator = coordinators.find(c => c.id === userId);
        let attendants = [];

        if (coordinator)
        {
            attendants.push(
                {
                    name: coordinator.name,
                    value: coordinator.id,
                    status: coordinator.status,
                    checked: false
                }
            );

            for (const supervisor of coordinator.supervisors)
            {
                attendants.push(
                    {
                        name: supervisor.name,
                        value: supervisor.id,
                        status: supervisor.status,
                        checked: false
                    }
                );

                for (const attendant of supervisor.attendants)
                {
                    attendants.push(
                        {
                            name: attendant.name,
                            value: attendant.id,
                            status: attendant.status,
                            checked: false
                        }
                    );
                }
            }
        } 
        else
        {
            let supervisor = null;

            for (const coordinator of coordinators) 
            {
                if (!supervisor)
                {
                    supervisor = coordinator.supervisors.find(s => s.id === userId);
                }
            }

            if (supervisor) 
            {
                attendants.push(
                    {
                        name: supervisor.name,
                        value: supervisor.id,
                        status: supervisor.status,
                        checked: false
                    }
                );

                for (const attendant of supervisor.attendants)
                {
                    attendants.push(
                        {
                            name: attendant.name,
                            value: attendant.id,
                            status: attendant.status,
                            checked: false
                        }
                    );
                }
            }
            else
            {
                let attendant = null;

                for (const coordinator of coordinators)
                {
                    for (const supervisor of coordinator.supervisors)
                    {
                        if (!attendant)
                        {
                            attendant = supervisor.attendants.find(a => a.id === userId);
                        }
                    }
                }

                if (attendant)
                {
                    attendants.push(
                        {
                            name: attendant.name,
                            value: attendant.id,
                            status: attendant.status,
                            checked: false
                        }
                    );
                }
                else
                {
                    if (permissions.toViewAll)
                    {
                        for (const coordinator of coordinators)
                        {
                            let allowedCoordinator = false;

                            for (const unit of coordinator.units)
                            {
                                if (!allowedCoordinator)
                                {
                                    allowedCoordinator = userUnits.some(u => u === unit.UNT_ID);
                                }
                            }

                            if (allowedCoordinator)
                            {
                                attendants.push(
                                    {
                                        name: coordinator.name,
                                        value: coordinator.id,
                                        status: coordinator.status,
                                        checked: false
                                    }
                                );
                            }

                            for (const supervisor of coordinator.supervisors)
                            {
                                let allowedSupervisor = false;

                                for (const unit of supervisor.units)
                                {
                                    if (!allowedSupervisor)
                                    {
                                        allowedSupervisor = userUnits.some(u => u === unit.UNT_ID);
                                    }
                                }
                                
                                if (allowedSupervisor)
                                {
                                    attendants.push(
                                        {
                                            name: supervisor.name,
                                            value: supervisor.id,
                                            status: supervisor.status,
                                            checked: false
                                        }
                                    );
                                }

                                for (const attendant of supervisor.attendants)
                                {
                                    let allowedAttendant = false;

                                    for (const unit of attendant.units)
                                    {
                                        if (!allowedAttendant)
                                        {
                                            allowedAttendant = userUnits.some(u => u === unit.UNT_ID);
                                        }
                                    }

                                    if (allowedAttendant)
                                    {
                                        attendants.push(
                                            {
                                                name: attendant.name,
                                                value: attendant.id,
                                                status: attendant.status,
                                                checked: false
                                            }
                                        );
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        attendants.sort((a, b) => {
            if (a.name < b.name) 
            { return -1; } 
            else if (a.name > b.name) 
            { return 1; } 
            else 
            { return 0; }
        });

        if (includeWithoutAttendant)
        {
            attendants.unshift(
                {
                    name: "[SEM ATENDENTE]",
                    value: 0,
                    checked: false,
                    status: 1,
                }
            );
        }

        return attendants;
    }
}