import React from 'react';
import PerformanceIndex from '../general/PerformanceIndex';
import "./CustomTooltipLineCharts.css"

export function CustomTooltipLineCharts ({ active, payload, label })
{
    if (active && payload && payload.length) 
    {
        let {nameAfter, nameBefore, title, after, before} = payload[0].payload;
        
        return (
            <div className="customTooltipLineCharts">
                <p className="label">{`${nameAfter} vs ${nameBefore}`}</p>
                <div className="lineCustomTooltipLineCharts">
                    <span className="title success">{`${title}`}</span>
                    <span className="amount"><strong>{`${after}`}</strong></span>
                    <PerformanceIndex classaditional="performanceCustomTooltipLineCharts" valueBefore={before} valueAfter={after} />
                </div>
                <div className="lineCustomTooltipLineCharts">
                    <span className="title information">{`${title}`}</span>
                    <span className="amount"><strong>{`${before}`}</strong></span>
                    <div className="emptyCustomToolTipLineCharts"></div>
                </div>
            </div>
        );
    }

    return null;
};