import React, { Component } from "react";
import "./CommissionRulesIntervals.css";
import { connect } from "react-redux";
import { APPLICATION_NAME, commissionRuleIntervals } from "../../core/constants";
import Header from "../../components/CRM/Header";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import InputGeneral from "../../components/general/InputGeneral";
import Select from "../../components/general/Select";
import TableEditCommissionRulesIntervals from "../../components/general/TableEditCommissionRulesIntervals";

import { withRouter } from "react-router-dom";
import { formatedDateTimeToUSA, getDateTimeCurrentBrowserAtMidnight } from "../../auxiliary/generalFunctions";
import { showModalMoreActions } from "../../store/actions/general";
import { setModalMessage } from "../../store/actions/general";

import CommissionReportIntervalsController from "../../controllers/CommissionReportIntervalsController";
import CommissionRulesDetailsIntervalsController from "../../controllers/CommissionRulesDetailsIntervalsController";
import CommissionRulesIntervalsController from "../../controllers/CommissionRulesIntervalsController";
import CompanyUnitController from "../../controllers/CompanyUnitController";
import Loading from "../../components/general/Loading";

const initialState = {
    ruleCommissionReportIntervals: null,
    commissionRulesDetailsIntervals: [],

    buttonGenerateReportDisabled: false,
    buttonRemoveReportDisabled: false,
    loading: false,

    // start content companyUnit
    optionsCompanyUnit: [],
    choosenCompanyUnit: {
        name: "",
        value: 0,
    },
    // ends content companyUnit

    // start Message Modal
    message_type: "information",
    message_show: false,
    message: "",
    // ends Message Modal

    currentMonthYear: "", //looks like: "2022-04"
}

const commissionReportIntervalsController = new CommissionReportIntervalsController();
const commissionRulesDetailsIntervalsController = new CommissionRulesDetailsIntervalsController();
const commissionRulesIntervalsController = new CommissionRulesIntervalsController();
const companyUnitController = new CompanyUnitController();

class CommissionRulesIntervals extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Regras Comissão - Limpa Nome`;
        let currentDate = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight());

        await this.getCompanyUnit();
        await this.setCurrentMonthYear(currentDate);
        await this.applyFilters();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("COMMISSION RULES INTERVALS prevProps ", prevProps);
        // console.log("COMMISSION RULES INTERVALS this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}
    }

    getCompanyUnit = async () => 
    {
        let optionsCompanyUnit = [];
        let choosenCompanyUnit = {};

        let result = await companyUnitController.get();

        if (result.status) 
        {
            for (const company of result.data.data)
            {
                if (company.UNT_STATUS === 1)
                {
                    optionsCompanyUnit.push(
                        {
                            name: company.UNT_NAME,
                            value: company.UNT_ID,
                            checked: false
                        }
                    );

                    if (company.UNT_ID === 21)
                    {
                        choosenCompanyUnit = {
                            name: company.UNT_NAME,
                            value: company.UNT_ID,
                        }
                    }
                }
            }
        }
                
        await this.setState({optionsCompanyUnit, choosenCompanyUnit});
    }

    setCurrentMonthYear = async (currentDate) =>
	{
		let parts = currentDate.toString().split("-");

		let y = parseInt(parts[0]);
		let m = parseInt(parts[1]).toString().padStart(2, '0');

		let currentMonthYear = `${y}-${m}`;

		await this.setState({currentMonthYear});
	}

    changeValue = async (newValue, id, propertyName = null) =>
    {
        // WARNING: for maintenance; don't remove!
        // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);

        if (propertyName !== null)
        {
            let commissionRulesDetailsIntervals = await this.state.commissionRulesDetailsIntervals;

            commissionRulesDetailsIntervals = commissionRulesDetailsIntervals.map(c => {
                if (c.id === id) { c[propertyName] = newValue; }
                return c;
            });

            await this.setState({commissionRulesDetailsIntervals});
        }
    }

    saveValue = async (id, field, value) =>
    {
        // WARNING: for maintenance; don't remove!
        // console.log(`id: ${id}, field: ${field}, value: ${value}`);

        let isError = false;
        let result = { status: true };

        if (field === "CRD_AMOUNT_START_INTERVAL" || field === "CRD_AMOUNT_ENDS_INTERVAL")
        {
            let commissionRulesDetailsIntervals = await this.state.commissionRulesDetailsIntervals;
            let interval = commissionRulesDetailsIntervals.filter(d => d.id === id)[0];

            if (field === "CRD_AMOUNT_START_INTERVAL" && (parseInt(value) > parseInt(interval.endsInterval)))
            { 
                this.message("error", "Opsssss, esse campo deve ter um valor menor que o do campo 'Fim do Intervalo'.");
                isError = true;
            }

            if (field === "CRD_AMOUNT_ENDS_INTERVAL" && (parseInt(value) < parseInt(interval.startInterval)))
            { 
                this.message("error", "Opsssss, esse campo deve ter um valor maior que o do campo 'Início do Intervalo'.");
                isError = true;
            }
        }

        if (field === "CRD_TOTAL_MONTHS_OF_PORTFOLIO" && value === 0)
        {
            this.message("error", "Opsssss, esse campo deve ter um número diferente de zero.");
            isError = true;
        }

        if (!isError)
        {
            result = await commissionRulesDetailsIntervalsController.update(id, { [field]: value });
            if (!result.status) { return this.message("error", result.message); }
        }

        await this.applyFilters();
    }

    applyFilters = async () => 
    {
        let optionsCompanyUnit = await this.state.optionsCompanyUnit;
        let choosenCompanyUnit = this.state.choosenCompanyUnit;
        let currentMonthYearActive = await this.state.currentMonthYear;
        let resultRule = await commissionRulesIntervalsController.get(currentMonthYearActive, choosenCompanyUnit.value);

        if (!resultRule.status || resultRule.data?.number_of_lines === 0)
        {
            await this.setState({ruleCommissionReportIntervals: null, commissionRulesDetailsIntervals: []});
            return;
        }

        let rule = {
            id: resultRule.data.data[0].CRI_ID,
            month: resultRule.data.data[0].CRI_MONTH,
            status: resultRule.data.data[0].CRI_STATUS,
            unit: resultRule.data.data[0].CRI_ID_COMPANY_UNIT
        };

        let result = await commissionRulesIntervalsController.getById(rule.id);

        if (!result.status)
        {
            await this.setState({commissionRulesDetailsIntervals: []});
            return;
        }

        let rows = [];

        for (let detail of result.data.details) 
        {
            rows.push(
                {
                    id: detail.CRD_ID,
                    unit: rule.unit,
                    type: detail.CRD_TYPE,
                    startInterval: detail.CRD_AMOUNT_START_INTERVAL,
                    endsInterval: detail.CRD_AMOUNT_ENDS_INTERVAL,
                    percentCommission: detail.CRD_PERCENT_COMMISSION,
                    monthsPortfolio: detail.CRD_TOTAL_MONTHS_OF_PORTFOLIO,
                    checked: false,
                    optionsUnit: [...optionsCompanyUnit]
                }
            );
        }

        await this.setState({ruleCommissionReportIntervals: rule, commissionRulesDetailsIntervals: rows});
    }

    generateCommissionReportIntervals = async () =>
    {
        await this.setState({buttonGenerateReportDisabled: true, loading: true});

        let idCommissionRule = this.state.ruleCommissionReportIntervals?.id;

        if (!idCommissionRule)
        {
            await this.setState({buttonGenerateReportDisabled: false, loading: false});
            return this.message("error", "ID da regra não definido!");
        }

        let result = await commissionReportIntervalsController.generate(idCommissionRule);
        // await commissionReportIntervalsController.monthClosure(this.state.currentMonthYear);

        if (result.status)
        { 
            await this.message("success", "Relatório gerado com sucesso!")
        }
        else
        {
            await this.message("error", "Opsssss, tivemos algum problema!");
        }

        await this.setState({buttonGenerateReportDisabled: false, loading: false});
        await this.applyFilters();
    }

    removeCommissionReportIntervals = async () =>
    {
        await this.setState({buttonRemoveReportDisabled: true, loading: true});

        let idRule = this.state.ruleCommissionReportIntervals?.id;

        if (!idRule)
        {
            await this.setState({buttonRemoveReportDisabled: false, loading: false});
            return this.message("error", "ID da regra não definido!");
        }
        
        let result = await commissionReportIntervalsController.deleteById(idRule);

        if (result.status)
        { 
            await this.message("success", "Relatório excluído com sucesso!")
        }
        else
        {
            await this.message("error", "Opsssss, tivemos algum problema!");
        }

        await this.setState({buttonRemoveReportDisabled: false, loading: false});
        await this.applyFilters();
    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }

    render ()
    {
        return (
            <div className="commissionRulesIntervals">
                <Header title="Regras Comissão - Limpa Nome" classaditional="headerCommissionRulesIntervals"/>
                {
                    this.state.loading &&
                    <div className="loadingCommissionRulesIntervals">
                        <Loading loading={this.state.loading} />
                    </div>
                }
                <div className="containerNavigationHeaderCommissionRulesIntervals">                
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderCommissionRulesIntervals ${!this.state.filtered ? "active" : ""}`}
                        onClick={() => {
                            this.setState({filtered: false});
                        }}
                    />
                    {
                        this.props.permissions.toInsert &&
                        <Button
                            icon="fal fa-plus"
                            name="&nbsp;&nbsp;Regra"
                            classaditional="buttonPlusNavigationHeaderCommissionRulesIntervals"
                            onClick={() => {
                                let data = {
                                    show: true,
                                    type: commissionRuleIntervals,
                                    data: {
                                        onActionForm: () => this.applyFilters()
                                    }
                                }
                                this.props.showModalMoreActions(data);
                            }}
                        />
                    }
                    {
                        (this.props.permissions.toDelete && this.state.ruleCommissionReportIntervals?.status === 1) &&
                        <ButtonIcon
                            icon="fas fa-trash" 
                            title="Excluir relatório"
                            classaditional="buttonTrashNavigationHeaderCommissionRulesIntervals"
                            onClick={() => this.removeCommissionReportIntervals()}
                            disabled={this.state.buttonRemoveReportDisabled}
                        />
                    }
                    {
                        (this.state.ruleCommissionReportIntervals?.status === 3 && this.state.commissionRulesDetailsIntervals.length > 0) &&
                        <ButtonIcon
                            icon="fas fa-play" 
                            title="Gerar relatório"
                            classaditional="buttonTrashNavigationHeaderCommissionRulesIntervals"
                            onClick={this.generateCommissionReportIntervals}
                            disabled={this.state.buttonGenerateReportDisabled}
                        />
                    }
                    <div className={`filterStatusHeaderCommissionRulesIntervals d-none d-lg-flex ml-2 ml-auto`}>
                        <i className="fas fa-building iconfilterStatusHeaderCommissionRules"></i>
                        <Select
                            classaditional="selectStatusHeaderCommissionRulesIntervals"
                            options={this.state.optionsCompanyUnit}
                            default={this.state.choosenCompanyUnit}
                            onChange={async (choosenCompanyUnit) => {
                                await this.setState({choosenCompanyUnit});
                                await this.applyFilters();
                            }} 
                            withFieldSearch
                        />
                    </div>                    
                    <div className={`filterStatusHeaderCommissionRulesIntervals d-none d-lg-flex ml-2`}>
                        <InputGeneral
                            classaditional="inputFilterCommissionRulesIntervals"
                            type="month"
                            value={this.state.currentMonthYear}
                            onChange={async (e) => {
                                await this.setCurrentMonthYear(e.target.value);
                                await this.applyFilters();
                            }}
                        />
                    </div>
                </div>
                <div className="containerListHeaderCommissionRulesIntervals">
                    <TableEditCommissionRulesIntervals
                        data={this.state.commissionRulesDetailsIntervals}
                        onChangeValue={this.changeValue}
                        onSaveValue={this.saveValue}
                        onIgnoreSave={this.applyFilters}
                        permissions={this.props.permissions} 
                        statusReport={this.state.ruleCommissionReportIntervals?.status}
                        onRemoveDetailsIntervals={async (idCommissionDetailsIntervals) => {
                            await commissionRulesDetailsIntervalsController.deleteById(idCommissionDetailsIntervals);
                            await this.applyFilters();
                        }}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const permissions = state.permissions.commissionRulesIntervals;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        hasPermissionsLoaded,
        permissions
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommissionRulesIntervals));