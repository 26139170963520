import React, { Component } from "react";
import "./Campaign.css";
import { connect } from "react-redux";
import { APPLICATION_NAME, campaign, IDS_MIDIA_ORIGIN_POSSIBLE_RUN_FOR_CAMPAIGN } from "../../core/constants";
import { showModalMoreActions } from "../../store/actions/general";

import { 
    getAllCampaign, 
    changeCampaign, 
    changeStatusGetFromDatabaseCompletedCampaign,
    toggleCheckedAllCampaign
} from "../../store/actions/campaign";
import { 
    getOptionsSelectCheckedFormated,
    formatedDateTimeToBrowserAtMidnight,
    dateDiff
} from "../../auxiliary/generalFunctions";

import Header from "../../components/CRM/Header";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import Message from "../../components/general/Message";
import TableEditCampaign from "../../components/general/TableEditCampaign";
import SelectMulti from "../../components/general/SelectMulti";

import CampaignController from "../../controllers/CampaignController";
import MidiaOriginController from "../../controllers/MidiaOriginController";
import InputGeneral from "../../components/general/InputGeneral";
import { withRouter } from "react-router-dom";

const campaignController = new CampaignController();
const midiaOriginController = new MidiaOriginController();

const initialState = {

    allCampaign: [],
    allCampaignUpdated: false,
    allCampaignChecked: false, //false as default
    hasOneCampaignChecked: false,
    filtered: false,

    optionsUnitCompany: [],
    optionsMidiaOrigin:  [],

    // start Message Modal
    message_type: "information",
    message_show: false,
    message: "",
    // ends Message Modal

    // start filter by specific 
    showDropDownIntervals: false,
    dateStartIntervals: null,
    dateEndsIntervals: null,
    filterDisabledIntervals: false,
    // ends filter by specific

}

class Campaign extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Campanhas`;
        
        await this.getMidiaOrigin();
        await this.getCompanyUnit();
        await this.applyFilters();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("CAMPAIGN prevProps ", prevProps);
        // console.log("CAMPAIGN this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}

        if (this.state.allCampaignUpdated)
        {          
            await this.setState({allCampaignUpdated: false});
            await this.applyFilters();
        }

    }

    applyFilters = async() => 
    {
        let filters = {
            USER_ID: this.props.user.PER_ID,
            CAM_STATUS: this.state.filtered ? 2 : 1
        };

        const optionsCompanyUnit = this.state.optionsUnitCompany.filter(u => u.checked).map(u => u.value);
        const optionsMidiaOrigin = this.state.optionsMidiaOrigin.filter(m => m.checked).map(m => m.value);
        const dateStartIntervals = this.state.dateStartIntervals;
        const dateEndsIntervals = this.state.dateEndsIntervals;

        optionsCompanyUnit.length > 0 && (filters.CAM_ID_COMPANY_UNIT = optionsCompanyUnit);
        optionsMidiaOrigin.length > 0 && (filters.CAM_ID_MIDIA_ORIGIN = optionsMidiaOrigin);
        dateStartIntervals !== null && (filters.CAM_DATE_START = dateStartIntervals);
        dateEndsIntervals !== null && (filters.CAM_DATE_ENDS = dateEndsIntervals);

        await this.getCampaigns(filters);
        await this.setState({ allCampaign: this.props.allCampaign });
        await this.hasAllCampaignChecked();
    }

    getCampaigns = async(filters) =>
    {
        let payload = [];
        const result = await campaignController.getByFilters(filters);
        if (result.status)
        {
            for (const campaign of result.data.data)
            {
                payload.push({
                    id: campaign.CAM_ID,
                    checked: false,
                    name: campaign.CAM_NAME,
                    dateStart: campaign.CAM_DATE_START,
                    dateEnds: campaign.CAM_DATE_ENDS,
                    timeStart: campaign.CAM_TIME_START,
                    timeEnds: campaign.CAM_TIME_ENDS,
                    executions: campaign.CAM_EXECUTIONS,
                    amountInvested: campaign.CAM_AMOUNT_INVESTED,
                    isItPossibleToRun: campaign.CAM_IS_IT_POSSIBLE_TO_RUN,
                    running: campaign.CAM_RUNNING,
                    idCompanyUnit: campaign.CAM_ID_COMPANY_UNIT,
                    nameCompanyUnit: campaign.UNT_NAME,
                    idMidiaOrigin: campaign.CAM_ID_MIDIA_ORIGIN,
                    nameMidiaOrigin: campaign.MID_NAME,
                    status: campaign.CAM_STATUS
                });
            }
        }

        await this.props.getAllCampaign(payload);
    }

    getCompanyUnit = async () => 
    {
        let allCompanyUnitsAllowedUser = await this.props.user.unit_companies;
        let allCompanyUnits = [];

        for (const company of allCompanyUnitsAllowedUser)
        {
            allCompanyUnits.push({
                name: company.UNT_NAME,
                value: company.UNT_ID,
                checked: false
            });
        }
         
        await this.setState({optionsUnitCompany: allCompanyUnits});
    }
    
    getMidiaOrigin = async () =>
    {
        let allMidiaOrigin = [];
        let responseMidia = await midiaOriginController.get();

        if (responseMidia.status)
        {
            let {data} = responseMidia.data;

            for (const midia of data)
            {
                allMidiaOrigin.push({
                    name: midia.MID_NAME,
                    value: midia.MID_ID,
                    slug: midia.MID_SLUG,
                    checked: false
                });
            }
        }
        
        await this.setState({optionsMidiaOrigin: allMidiaOrigin});
    }

    someTreatments = async (allCampaign) =>
    {
        let optionsCompanyUnit = await this.state.optionsUnitCompany;
        let optionsMidiaOrigin = await this.state.optionsMidiaOrigin;
        
        allCampaign = allCampaign.map(c => {
            c["optionsCompanyUnit"] = optionsCompanyUnit;
            c["optionsMidiaOrigin"] = optionsMidiaOrigin;
            return c;
        });
        
        return allCampaign;
    }
    
    validationOnChangeValue = async (newValue, id, propertyName) =>
    {
        let validated = true;

        let campaign = await this.state.allCampaign.find(c => c.id === id);
        let dateStart = null;
        let dateEnds = null;

        if (propertyName === "dateStart" || propertyName === "dateEnds")
        {
            if (IDS_MIDIA_ORIGIN_POSSIBLE_RUN_FOR_CAMPAIGN.includes(parseInt(campaign.idMidiaOrigin)) && (newValue === "" || newValue === null))
            {
                this.message("information", "Data não pode ficar em branco para este tipo de mídia!");
                validated = false;
                return;
            }

            if (propertyName === "dateStart")
            {
                dateStart = newValue || null;
                dateEnds = campaign.dateEnds;
            }

            if (propertyName === "dateEnds")
            {
                dateStart = campaign.dateStart;
                dateEnds = newValue || null;
            }

            if (dateStart !== null && dateEnds !== null)
            {
                let diffDays = dateDiff.inDays(formatedDateTimeToBrowserAtMidnight(dateStart), formatedDateTimeToBrowserAtMidnight(dateEnds));
    
                if (diffDays < 0 || isNaN(diffDays))
                {                    
                    this.message("information", "Opsssss, tem algum problema com as datas!");
                    validated = false;
                }
            }
        }

        if (propertyName === "idMidiaOrigin" && IDS_MIDIA_ORIGIN_POSSIBLE_RUN_FOR_CAMPAIGN.includes(parseInt(newValue)))
        {
            if 
            (
                campaign.dateStart === null || 
                campaign.dateEnds === null || 
                campaign.timeStart === null || 
                campaign.timeEnds === null || 
                campaign.executions === null ||
                isNaN(campaign.executions)
            )
            {
                this.message("information", "Opsssss, antes de alterar a mídia confira os campos de datas, horários e inserções!");
                validated = false;
            }
        }

        return validated;
    }

    changeValue = async (newValue, id, propertyName = null) =>
    {
        if (propertyName !== null)
        {
            // WARNING: @Lucio Add checking by time and time and date on validationOnChangeValue() method, just like CampaignForm.
            let validation = await this.validationOnChangeValue(newValue, id, propertyName);

            if (validation)
            {
                // console.log(`newValue: ${newValue}, id: ${id}, propertyName: ${propertyName}`);
                await this.props.changeCampaign({[propertyName]: newValue || null, id, propertyName});
            }
        }
    }

    saveValue = async (id, field, value) =>
    {
        if (value === "")
        {
            await this.props.changeStatusGetFromDatabaseCompletedBusiness(false);
            await this.applyFilters();
            return this.message("error", "Este campo não pode ficar em branco!");
        }

        if (field === "CAM_AMOUNT_INVESTED")
        {
            value = value.replace(".", "").replace(",", ".");
        }

        await campaignController.update(id, {
            [field]: value
        });

    }

    hasAllCampaignChecked = async () =>
    {
        let allCampaign = await this.state.allCampaign;
        let allCampaignChecked = true;
        let hasOneCampaignChecked = false;

        if (allCampaign.length > 0)
        {
            allCampaign.forEach(d => {
                if (!d.checked) allCampaignChecked = false;
                if (d.checked) hasOneCampaignChecked = true;
            });
        }
        else
        {
            allCampaignChecked = false;
        }

        await this.setState({allCampaignChecked, hasOneCampaignChecked});
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    updateStatusCampaign = async() =>
    {
        const filter = this.state.filtered;
        const campaignsChecked = this.state.allCampaign.filter(c => c.checked);

        const statusToUpdate = filter ? 1 : 2;
        const action = filter ? "desarquivada(s)" : "arquivada(s)";

        for (const campaign of campaignsChecked)
        {
            await campaignController.update(campaign.id, {
                CAM_STATUS: statusToUpdate
            });
        }

        await this.setState({ hasOneCampaignChecked: false, allCampaignChecked: false });
        this.applyFilters();

        this.message("success", `Campanha(s) ${action} com sucesso!`);
    }

    render ()
    {
        return (
            <div className="campaign">
                <Header title="Campanhas" classaditional="headerCampaign"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="containerNavigationHeaderCampaign">
                    <ButtonIcon
                        icon="far fa-bars"
                        classaditional={`buttonNavigationHeaderCampaign ${!this.state.filtered ? "active" : ""}`}
                        onClick={async () => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            await this.setState({filtered: false});
                            this.setState({allCampaignUpdated: true});
                        }}
                    />
                    <ButtonIcon
                        icon="fas fa-archive"
                        classaditional={`buttonNavigationHeaderCampaign ${this.state.filtered ? "active" : ""}`}
                        onClick={async () => {
                            // WARNING: just apply the filter, there is no change of view mode!
                            await this.setState({filtered: true});
                            this.setState({allCampaignUpdated: true});
                        }}
                    />
                    {
                        (this.props.permissions.toInsert && !this.state.hasOneCampaignChecked) &&
                        <Button
                            icon="fal fa-plus"
                            name="&nbsp;&nbsp;Campanha"
                            classaditional="buttonPlusNavigationHeaderCampaign"
                            onClick={() => {
                                let data = {
                                    show: true,
                                    type: campaign,
                                    data: {
                                        onActionForm: this.applyFilters
                                    }
                                }
                                this.props.showModalMoreActions(data);
                            }}
                        />
                    }
                    {
                        (this.props.permissions.toFile && this.state.hasOneCampaignChecked) &&
                        <Button
                            icon="fas fa-archive"
                            name={ `\u00A0\u00A0${this.state.filtered ? "Desarquivar" : "Arquivar"}` }
                            classaditional="buttonPlusNavigationHeaderCampaign"
                            onClick={() => this.updateStatusCampaign()}
                        />
                    }
                    <div className={`filterStatusHeaderCampaign d-none d-lg-flex ml-2 ml-auto`}>
                        <i className="fas fa-building iconfilterStatusHeaderCampaign"></i>
                        <SelectMulti
                            classaditional="selectStatusHeaderCampaign"
                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsUnitCompany, "[Todas Unidades]", "unidades")}}
                            options={this.state.optionsUnitCompany} 
                            onChange={async (optionsUnitCompany) => {
                                await this.setState({optionsUnitCompany});
                                this.setState({allCampaignUpdated: true});
                            }}
                            withFieldSearch
                        />
                    </div>
                    <div className={`filterStatusHeaderCampaign d-none d-lg-flex ml-2 ml-2`}>
                        <i className="far fa-tv-retro iconfilterStatusHeaderCampaign"></i>
                        <SelectMulti
                            classaditional="selectStatusHeaderCampaign"
                            default={{name: getOptionsSelectCheckedFormated(this.state.optionsMidiaOrigin, "[Todas Mídias]", "mídias")}}
                            options={this.state.optionsMidiaOrigin} 
                            onChange={async (optionsMidiaOrigin) => {
                                await this.setState({optionsMidiaOrigin});
                                this.setState({allCampaignUpdated: true});
                            }}
                            withFieldSearch
                        />
                    </div>
                    <div className={`filterStatusHeaderCampaign filterPeriodHeaderCampaign d-none d-lg-flex ml-2 ml-2`}>
                        <Button
                            classaditional="buttonStatusHeaderCampaign"
                            icon="fas fa-calendar-alt"
                            onClick={() => this.setState({showDropDownIntervals: !this.state.showDropDownIntervals})}
                            name="&nbsp;&nbsp;&nbsp;Selecione o Período"
                        />
                        {
                            this.state.showDropDownIntervals &&
                            <div className="dropIntervalsHeaderCampaign">
                                <div className="row no-gutters lineBodyDropIntervalsHeaderCampaign">
                                    <div className="col-6 pr-1">
                                        <InputGeneral
                                            label="Data Inicial"
                                            type="date" 
                                            onChange={(e) => this.setState({dateStartIntervals: e.target.value})}
                                            value={this.state.dateStartIntervals}
                                        />
                                    </div>
                                    <div className="col-6 pl-1">
                                        <InputGeneral
                                            label="Data Final"
                                            type="date" 
                                            onChange={(e) => this.setState({dateEndsIntervals: e.target.value})}
                                            value={this.state.dateEndsIntervals}
                                        />
                                    </div>
                                </div>
                                <div className="row no-gutters lineFooterDropIntervalsHeaderCampaign">
                                    <Button
                                        classaditional="buttonIntervalsHeaderCampaign"
                                        name="Limpar"
                                        onClick={async () => {
                                            await this.setState({dateStartIntervals: null, dateEndsIntervals: null}); 
                                            this.setState({allCampaignUpdated: true});
                                        }}
                                    />
                                    <Button
                                        classaditional="buttonIntervalsHeaderCampaign positive"
                                        name="Aplicar"
                                        onClick={async () => {
                                            this.setState({allCampaignUpdated: true});
                                        }}
                                    />                  
                                </div>
                            </div>
                        }                    
                    </div>
                </div>
                <div className="containerListHeaderCampaign">
                    <TableEditCampaign
                        data={this.state.allCampaign}
                        allCampaignChecked={this.state.allCampaignChecked}
                        onToggleCheckedAllCampaign={async (status) => {
                            await this.props.toggleCheckedAllCampaign(status);
                            await this.hasAllCampaignChecked();
                        }}
                        onChangeValue={this.changeValue}
                        onSaveValue={this.saveValue}
                        onIgnoreSave={async () => {
                            await this.props.changeStatusGetFromDatabaseCompletedCampaign(false);
                            await this.applyFilters();
                        }}
                        permissions={this.props.permissions}
                        hasAllCampaignChecked={this.hasAllCampaignChecked}
                        optionsUnitCompany={this.state.optionsUnitCompany}
                        optionsMidiaOrigin={this.state.optionsMidiaOrigin}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { allCampaign, getAllCampaignFromDatabaseCompleted } = state.campaign;
    const { user } = state.auth;
    const permissions = state.permissions.campaign;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        hasPermissionsLoaded,
        user,
        allCampaign,
        permissions,
        getAllCampaignFromDatabaseCompleted
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        },
        getAllCampaign (data) 
        {
            //action creator -> action
            const action = getAllCampaign(data);
            dispatch(action);
        },
        changeCampaign (data) 
        {
            //action creator -> action
            const action = changeCampaign(data);
            dispatch(action);
        },
        changeStatusGetFromDatabaseCompletedCampaign (data)
        {
            //action creator -> action
            const action = changeStatusGetFromDatabaseCompletedCampaign(data);
            dispatch(action);
        },
        toggleCheckedAllCampaign (status) 
        {
            //action creator -> action
            const action = toggleCheckedAllCampaign(status);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Campaign));