import React, { Component } from "react";  
import { connect } from "react-redux";
import InputGeneral from "../general/InputGeneral";
import { optionsFrequencyTypePhone, optionsFrequencyTypeMail } from "../../core/constants";
import { isEmail, formatedPhoneAndCellphone, formatedOnlyNumber, getFullNameFisrtCharsUppercase } from "../../auxiliary/generalFunctions";
import "./PersonForm.css";
import Select from "../general/Select";
import Button from "../general/Button";
import ClientController from "../../controllers/ClientController";
import { getAllUsersLeads } from "../../store/actions/leads";
import MidiaOriginController from "../../controllers/MidiaOriginController";
import { showModalMoreActions } from "../../store/actions/general";
import UserController from "../../controllers/UserController";

const initialState = { 
    // start content name
    name: "",
    searchingName: false,
    warningName: false,
    noticesName: [],
    validName: true,
    // ends content name 

    // start content companyUnit
    optionsCompanyUnit: [],
    optionChosenCompanyUnit: null,
    validCompanyUnit: true,
    // ends content companyUnit

    // start content midiaOrigin
    optionsMidiaOrigin: [],
    optionChosenMidiaOrigin: null,
    validMidiaOrigin: true,
    // ends content stampsLead 

    // start content phoneOne
    phoneOne: "",
    searchingPhoneOne: false,
    warningPhoneOne: false,
    noticesPhoneOne: [
        <span>aviso 1</span>,
        <span>aviso 2</span>,
    ],
    validPhoneOne: true,
    optionsPhoneOne: optionsFrequencyTypePhone,
    optionChosenFrequencyTypePhoneOne: null,
    // ends content phoneOne 

    // start content phoneTwo
    phoneTwo: "",
    searchingPhoneTwo: false,
    warningPhoneTwo: false,
    noticesPhoneTwo: [],
    validPhoneTwo: true,
    optionsPhoneTwo: optionsFrequencyTypePhone,
    optionChosenFrequencyTypePhoneTwo: null,
    // ends content phoneTwo 

    // start content mail
    mail: "",
    searchingMail: false,
    warningMail: false,
    noticesMail: [],
    validMail: true,
    optionsMail: optionsFrequencyTypeMail,
    optionChosenFrequencyTypeMail: null,
    // ends content mail

    // start content button
    disabledButton: false
    // ends content button
}

const clientController = new ClientController();
const midiaOriginController = new MidiaOriginController();
const userController = new UserController();

class PersonForm extends Component
{
    state = {...initialState}

    componentDidMount = () => {
        this.getCompanyUnit();
        this.getMidiaOrigin();
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        }
        this.props.showModalMoreActions(data);
    }

    nameChangeAndChecking = (name) =>
    {
        this.setState({searchingName: true, warningName: false, validName: true});
        this.setState({name});

        setTimeout(() => {
            this.setState({searchingName: false});
        }, 1000);
    }

    phoneOneChangeAndChecking = (phoneOne) =>
    {
        this.setState({
            searchingPhoneOne: true, 
            warningPhoneOne: false, 
            validPhoneOne: true,
            phoneOne: formatedPhoneAndCellphone(phoneOne)
        });

        setTimeout(() => {
            this.setState({searchingPhoneOne: false, warningPhoneOne: false});
        }, 1000);
    }

    phoneTwoChangeAndChecking = (phoneTwo) =>
    {
        this.setState({
            searchingPhoneTwo: true, 
            warningPhoneTwo: false,
            phoneTwo: formatedPhoneAndCellphone(phoneTwo)
        });

        setTimeout(() => {
            this.setState({searchingPhoneTwo: false, warningPhoneTwo: false});
        }, 2000);
    }

    mailChangeAndChecking = (mail) =>
    {
        this.setState({searchingMail: true, warningMail: false});
        this.setState({mail, validMail: isEmail(mail)});

        setTimeout(() => {
            this.setState({searchingMail: false, warningMail: false});
        }, 2000);
    }

    getCompanyUnit = async () => 
    {
        const unitCompanies = await userController.getById(this.props.user.PER_ID);
        let options = [];
        for (const company of unitCompanies.data.unit_companies) {
            options.push({
                id: company.UNT_ID,
                name: company.UNT_NAME,
                value: company.UNT_ID,
            });
        }

        this.setState({ optionsCompanyUnit: options });
    }

    getMidiaOrigin = async () => 
    {

        const result = await midiaOriginController.get();
        let options = [];
        if (result.status) {
            for (const midiaOrigin of result.data.data) 
            {
                if (midiaOrigin.MID_STATUS === 1)
                {
                    options.push({
                        id: midiaOrigin.MID_ID,
                        name: midiaOrigin.MID_NAME,
                        value: midiaOrigin.MID_ID,
                    });
                }
            }
        }

        this.setState({ optionsMidiaOrigin: options });
    }

    onActionForm = async () =>
    {
        if (this.state.name.trim() === "") 
        {
            return this.setState({ validName: false });
        }

        if (this.state.optionChosenMidiaOrigin === null) 
        {
            return this.setState({ validMidiaOrigin: false });
        }

        if (this.state.phoneOne.trim() === "") 
        {
            return this.setState({ validPhoneOne: false });
        }

        if (this.state.mail !== "" && !isEmail(this.state.mail))
        {
            return this.setState({ validMail: false });
        }

        if (this.props.user.unit_companies.length > 1 && this.state.optionChosenCompanyUnit === null) 
        {
            return this.setState({ validCompanyUnit: false });
        }

        await this.setState({ disabledButton: true });

        const stamp = 1;
        const typePhone = "CELULAR";
        const companyUnit = this.props.user.unit_companies.length > 1 ? this.state.optionChosenCompanyUnit.value : this.state.optionsCompanyUnit[0].value;

        const data = {
            PER_NAME: getFullNameFisrtCharsUppercase(this.state.name),
            PER_MAIL: this.state.mail,
            PER_PHONE_ONE: formatedOnlyNumber(this.state.phoneOne),
            PER_TYPE_PHONE_ONE: typePhone,
            PER_PHONE_TWO: formatedOnlyNumber(this.state?.phoneTwo),
            PER_TYPE_PHONE_TWO: typePhone,
            PER_ID_MIDIA_ORIGIN: this.state.optionChosenMidiaOrigin.value,
            PER_STAMP: stamp,
            PER_ID_PERSON_TYPE: 2,
            PER_ID_COMPANY_UNIT: companyUnit,
            PER_ID_CREATE_PERSON: this.props.user.PER_ID
        }

        const result = await clientController.create(data);

        if (!result.status) 
        {
            await this.setState({ disabledButton: false });
            return alert(`ERRO: ${result.message}`);
        }

        await this.props.getAllUsersLeads();
        await this.setState({ disabledButton: false });
        this.closeModalMoreActions();

    }

    render () 
    {
        return (
            <div className="personForm"> 
                <div className="row no-gutters linePersonForm">
                    <div className="col-12 columnPersonForm">
                        <InputGeneral
                            label="Nome"
                            value={this.state.name}
                            onChange={e => this.nameChangeAndChecking(e.target.value)}
                            searching={this.state.searchingName.toString()}
                            warning={this.state.warningName.toString()}
                            warningcontent={this.state.noticesName}
                            mandatory="true"
                            valid={this.state.validName.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters linePersonForm">
                    <div className="col-12 columnPersonForm">
                        <Select 
                            label="Fonte"
                            default={this.state.optionChosenMidiaOrigin}
                            options={this.state.optionsMidiaOrigin} 
                            mandatory="true"
                            onChange={(optionChosenMidiaOrigin) => {
                                this.setState({optionChosenMidiaOrigin});
                                if (this.state.validMidiaOrigin === false)
                                {
                                    this.setState({ validMidiaOrigin: true });
                                }
                            }}
                            valid={this.state.validMidiaOrigin.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters linePersonForm">
                    <div className="col-12 columnPersonForm">
                        <InputGeneral
                            label="Telefone Principal"
                            value={this.state.phoneOne}
                            onChange={e => this.phoneOneChangeAndChecking(e.target.value)}
                            searching={this.state.searchingPhoneOne.toString()}
                            warning={this.state.warningPhoneOne.toString()}
                            warningcontent={this.state.noticesPhoneOne}
                            mandatory="true"
                            valid={this.state.validPhoneOne.toString()}
                            maxLength="14"
                        />
                    </div>
                </div>
                <div className="row no-gutters linePersonForm">
                    <div className="col-12 columnPersonForm">
                        <InputGeneral
                            label="Telefone"
                            value={this.state.phoneTwo}
                            onChange={e => this.phoneTwoChangeAndChecking(e.target.value)}
                            searching={this.state.searchingPhoneTwo.toString()}
                            warning={this.state.warningPhoneTwo.toString()}
                            warningcontent={this.state.noticesPhoneTwo}
                            valid={this.state.validPhoneTwo.toString()}
                            maxLength="14"
                        />
                    </div>
                </div>
                <div className="row no-gutters linePersonForm">
                    <div className="col-12 pr-1 columnPersonForm">
                        <InputGeneral
                            label="E-mail"
                            value={this.state.mail}
                            onChange={e => this.mailChangeAndChecking(e.target.value)}
                            searching={this.state.searchingMail.toString()}
                            warning={this.state.warningMail.toString()}
                            warningcontent={this.state.noticesMail} 
                            valid={this.state.validMail.toString()}
                        />
                    </div>
                </div>
                {
                    this.props.user.unit_companies.length > 1 &&
                        <div className="row no-gutters linePersonForm">
                            <div className="col-12 columnPersonForm">
                                <Select 
                                    label="Unidade"
                                    default={this.state.optionChosenCompanyUnit}
                                    options={this.state.optionsCompanyUnit} 
                                    onChange={(optionChosenCompanyUnit) => {
                                        this.setState({optionChosenCompanyUnit});
                                        if (this.state.validCompanyUnit === false)
                                        {
                                            this.setState({ validCompanyUnit: true });
                                        }
                                    }}
                                    valid={this.state.validCompanyUnit.toString()}
                                    mandatory
                                />
                            </div>
                        </div>
                }
                <div className="row no-gutters linePersonForm">
                    <div className="col-12 columnPersonForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterPersonForm">
                    <Button
                        classaditional="buttonPersonForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions()}
                        />
                    <Button
                        classaditional="buttonPersonForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                        disabled={this.state.disabledButton}
                    />                  
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;

    return {
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        getAllUsersLeads ()
        {
            //action creator -> action
            const action = getAllUsersLeads();
            dispatch(action);
        },
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonForm);