import { 
    NEW_DEAL_BUSINESS, 
    CHANGE_DEAL_BUSINESS,
    CHANGE_MODE_VIEW_BUSINESS,
    GET_ALL_DRAGGABLES_BUSINESS,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_BUSINESS,
    TOGGLE_CHECKED_ALL_BUSINESS
} from "../../core/constants";

const initialState = { 
    allDraggables: [],
    draggablesUpdated: false,
    getAllBusinessFromDatabaseCompleted: true
}

export default function businessReducer (state = initialState, action)
{
    switch (action.type) {
        case NEW_DEAL_BUSINESS:
            return {
                ...state,
                newDeal: action.payload
            }

        case CHANGE_DEAL_BUSINESS:

            let {title, money, name, column, stamp, propertyName, id, checked, owner, prospectionOwner} = action.payload;
            let allDraggablesFinal = state.allDraggables.map(d => {
                
                if (d.id === parseInt(id))
                {
                    switch (propertyName) {
                        case "title":
                            d.title = title;
                            break;
                        case "money":
                            d.money = money;
                            break;
                        case "name":
                            d.name = name;
                            break;
                        case "column":
                            d.column = column;
                            break;
                        case "stamp":
                            d.stamp = stamp;
                            break;
                        case "owner":
                            d.owner = owner;
                            break;
                        case "prospectionOwner":
                            d.prospectionOwner = prospectionOwner;
                            break;
                        case "checked":
                            d.checked = checked;
                            break;
                        default: 
                    }                        
                }
                return d;
            });

            return {
                ...state,
                allDraggables: allDraggablesFinal
            }

        case CHANGE_MODE_VIEW_BUSINESS:
            return {
                ...state,
                modeBusinesFinal: action.payload
            }

        case GET_ALL_DRAGGABLES_BUSINESS:
            return {
                ...state,
                allDraggables: action.payload,
                getAllBusinessFromDatabaseCompleted: true
            }

        case TOGGLE_CHECKED_ALL_BUSINESS:
            let status = action.payload;
            let allBusinessFinalChecked = state.allDraggables.map(d => {
                d.checked = status;
                return d;
            });
    
            return {
                ...state,
                allDraggables: allBusinessFinalChecked
            }

        case CHANGE_STATUS_GET_FROM_DATABASE_ALL_BUSINESS:
            
            return {
                ...state,
                getAllBusinessFromDatabaseCompleted: action.payload
            }
            
        default:
            return state
    }
}