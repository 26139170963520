import React, { Component } from "react";
import { formatedPercentageNumberFromFloat } from "../../auxiliary/generalFunctions";
import "./PerformanceIndex.css";

const initialState = {
    percent: 0,
    icon: "", //[empty] is default
}

export default class PerformanceIndex extends Component
{
    _mounted = false;
	state = {...initialState}

    componentDidMount = async () =>
    {
        this._mounted = true;
        await this.getParams();
    }

    componentDidUpdate = async (prevProps) =>
    {
        // console.log("PERFORMACE INDEX prevProps: ",prevProps);

        // WARNING: has been updated.
        if (prevProps.valueAfter !== this.props.valueAfter)
        {
            await this.getParams();
        }
    }

    componentWillUnmount = async (prevProps) => 
	{ 
		this._mounted = false;
		// console.info("PERFORMACE INDEX demoting.");
	}

    getParams = async () =>
    {
        let icon = "";
        let one = parseFloat(this.props.valueBefore);
        let two = parseFloat(this.props.valueAfter);
        let diff = (two - one);
        let percent = (one === 0) ? (diff) : (diff / one * 100);
        
        if (percent === 0)
        {
            icon = "equal";
        }
        else if (percent < 0)
        {
            icon = "negative";
            percent = Math.abs(percent);
        }
        else
        {
            icon = "positive";
        }
        
        percent = formatedPercentageNumberFromFloat(percent);

        if (this._mounted)
        {
            await this.setState({
                percent,
                icon
            });
        }
    }

    render ()
    {
        return (
            <div className={`performanceIndex ${this.props.classaditional ? this.props.classaditional : ""}`}>
                <span className="namePerformaceIndex">
                    {this.props.name ? `${this.props.name}:` : " "}
                </span>
                <div className="areaPerformaceIndex">
                    {
                        this.state.icon === "positive" &&
                        <i className="fas fa-sort-up iconPerformaceIndex positive" />
                    }
                    {
                        this.state.icon === "negative" &&
                        <i className="fas fa-sort-down iconPerformaceIndex negative" />
                    }
                    {
                        this.state.icon === "equal" &&
                        <i className="fas fa-equals iconPerformaceIndex equal"></i>
                    }
                </div>
                <span className={`percentPerformaceIndex ${this.state.icon} `}>{this.state.percent}%</span>
            </div>
        )
    }
}
