import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class SystemUsersForNotificationsController 
{
    async create(data) 
    {
        const response = await api.systemUsersForNotifications().post(data);
        return apiCallbackTreatment(response);
    }

    async deleteById(id)
    {
        const response = await api.systemUsersForNotifications().remove(id);
        return apiCallbackTreatment(response);
    }

    async get() 
    {
        const response = await api.systemUsersForNotifications().get();
        return apiCallbackTreatment(response);
    }
}