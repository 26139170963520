import React, { Component } from "react";
import "./AbsenceAttendantsForm.css";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import { validDate } from "../../auxiliary/generalFunctions";
import { newAttendantAbsence } from "../../store/actions/absence";

import {
    formatedTime,
    validTime 
} from "../../auxiliary/generalFunctions";

import Button from "../general/Button";
import Select from "../general/Select";
import Message from "../general/Message";
import InputGeneral from "../general/InputGeneral";

import WorkShiftsPersonController from "../../controllers/WorkShiftsPersonController";
import AbsenceAttendantsController from "../../controllers/AbsenceAttendantsController";

const initialState = {

    // start content reason
    reason: "",
    searchingReason: false,
    warningReason: false,
    noticesReason: [],
    validReason: true,
    // ends content reason 

    // start content attendant
    validAttendant: true,
    choosenAttendant: {
        name: "",
        value: 0,
    },
    // ends content attendant

    // start content date
    date: "",
    validDate: true,
    // ends content date 

    // start content timeStart
    timeStart: "",
    validTimeStart: true,
    // ends content timeStart 

    // start content timeEnds
    timeEnds: "",
    validTimeEnds: true,
    // ends content timeEnds 

    optionsAttendants: [],

    // start modal message
    message_type: "information",
    message_show: false,
    message: "",
    // ends modal message
}

const workShiftsPersonController = new WorkShiftsPersonController();
const absenceAttendantsController = new AbsenceAttendantsController();
class AbsenceAttendantsForm extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        await this.getAllAttendants();
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        }
        this.props.showModalMoreActions(data);
    }

    getAllAttendants = async () =>
    {
        let optionsAttendants = [];
        const result = await workShiftsPersonController.get();

        if (result.status) 
        {
            let {data} = result.data;

            for (const attendant of data)
            {
                optionsAttendants.push(
                    {
                        name: attendant.PER_NAME,
                        value: attendant.PER_ID,
                        checked: false
                    }
                );
            }
        }
                
        await this.setState({optionsAttendants});
    }

    reasonChangeAndChecking = async (reason) =>
    {
        await this.setState({
            reason,
            validReason: reason.toString().trim() !== ""
        });
    }

    attendantChangeAndChecking = async (choosenAttendant) =>
    {
        await this.setState({
            choosenAttendant,
            validAttendant: choosenAttendant.value !== 0
        });
    }

    dateChangeAndChecking = async (date) =>
    {
        await this.setState({
            date,
            validDate: date.toString().trim() !== "" && validDate(date)
        });
    }

    timeStartChangeAndChecking = async (timeStart) =>
    {
        await this.setState({
            timeStart,
            validTimeStart: timeStart.toString().trim() !== "" && timeStart.toString().trim().length === 5
        });

        this.checkIsValidTimeStartAndEnds();
    }

    timeEndsChangeAndChecking = async (timeEnds) =>
    {
        await this.setState({
            timeEnds,
            validTimeEnds: timeEnds.toString().trim() !== "" && timeEnds.toString().trim().length === 5
        });

        this.checkIsValidTimeStartAndEnds();
    }

    checkIsTimeEndsAfterTimeStart = (timeStart, timeEnds) =>
    {
        function zeroPad(num, places) { return String(num).padStart(places, '0'); }
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = zeroPad((currentDate.getMonth() + 1), 2);
        let day = zeroPad(currentDate.getDate(), 2);

        let intervalStartTime = `${year}-${month}-${day} ${timeStart}:00`;
        let intervalEndsTime = `${year}-${month}-${day} ${timeEnds}:00`;
        let stampStart = new Date(intervalStartTime).getTime();
        let stampEnds = new Date(intervalEndsTime).getTime();
        return (stampEnds > stampStart);
    }

    checkIsValidTimeStartAndEnds = async () =>
    {
        let timeStart = await this.state.timeStart;
        let timeEnds = await this.state.timeEnds;
        let validTimeStart = ((timeStart.toString().trim().length < 5 || validTime(timeStart) === "00:00")) ? false : true;
        let validTimeEnds = ((timeEnds.toString().trim().length < 5 || validTime(timeEnds) === "00:00")) ? false : true;
        let allCorrect = (validTimeStart && validTimeEnds);
        
        await this.setState({validTimeStart, validTimeEnds});

        if (allCorrect)
        {
            if (!this.checkIsTimeEndsAfterTimeStart(timeStart, timeEnds))
            {
                allCorrect = false;
                await this.setState({validTimeStart: false, validTimeEnds: false});
            }
        }
    }

    onActionForm = async () =>
    {
        let allCorrect = true;
        
        // strings
        let reason = await this.state.reason;
        let timeStart = await this.state.timeStart;
        let timeEnds = await this.state.timeEnds;
        
        // numbers
        let attendant = await this.state.choosenAttendant.value;
        
        // objects
        let date = await this.state.date;

        if (reason.toString().trim() === "")
        {
            allCorrect = false;
            await this.setState({validReason: false});
        }
        
        if (isNaN(attendant) || attendant === 0)
        {
            allCorrect = false;
            await this.setState({validAttendant: false});
        }

        if (date.toString().trim() === "" || !validDate(date))
        {
            allCorrect = false;
            await this.setState({validDate: false});
        }

        if ((timeStart.toString().trim().length < 5 || validTime(timeStart) === "00:00"))
        {
            allCorrect = false;
            await this.setState({validTimeStart: false});
        }

        if ((timeEnds.toString().trim().length < 5 || validTime(timeEnds) === "00:00"))
        {
            allCorrect = false;
            await this.setState({validTimeEnds: false});
        }

        if (!this.checkIsTimeEndsAfterTimeStart(timeStart, timeEnds))
        {
            allCorrect = false;
            await this.setState({validTimeStart: false, validTimeEnds: false});
        }

        if (!allCorrect)
        {
            await this.message("error", "Confira o(s) campo(s) destacado(s) em vermelho!");
            return;
        }

        const result = await absenceAttendantsController.create(
            {
                ABT_ABSENCE_DATE: date,
                ABT_ID_CREATE_PERSON: this.props.user.PER_ID,
                ABT_ID_ABSENCE_PERSON: attendant,
                ABT_TIME_START: timeStart,
                ABT_TIME_ENDS: timeEnds,
                ABT_ABSENCE_REASON: reason,
            }
        );

        if (!result.status)
        {
            return this.message("error", `Opsssss, tivemos algum problema! (${result.message})`);
        }
        
        this.closeModalMoreActions();
        this.props.newAttendantAbsence(true);
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render () 
    {
        return (
            <div className="absenceAttendantsForm"> 
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="row no-gutters lineAbsenceAttendantsForm">
                    <div className="col-12 columnAbsenceAttendantsForm">
                        <InputGeneral
                            label="Motivo"
                            value={this.state.reason}
                            onChange={e => this.reasonChangeAndChecking(e.target.value)}
                            searching={this.state.searchingReason.toString()}
                            warning={this.state.warningReason.toString()}
                            warningcontent={this.state.noticesReason}
                            mandatory="true"
                            maxLength={50}
                            valid={this.state.validReason.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineAbsenceAttendantsForm">
                    <div className="col-12 columnAbsenceAttendantsForm">
                        <Select
                            label="Atendente *"
                            options={this.state.optionsAttendants}
                            default={this.state.choosenAttendant}
                            onChange={(choosenAttendant) => this.attendantChangeAndChecking(choosenAttendant)}
                            valid={this.state.validAttendant.toString()}
                            withFieldSearch
                        />
                    </div>
                </div>
                <div className="row no-gutters lineAbsenceAttendantsForm">
                    <div className="col-12 columnAbsenceAttendantsForm">
                        <InputGeneral
                            label="Data"
                            value={this.state.date}
                            onChange={e => this.dateChangeAndChecking(e.target.value)}
                            mandatory="true"
                            type="date"
                            valid={this.state.validDate.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineAbsenceAttendantsForm">
                    <div className="col-6 columnAbsenceAttendantsForm pr-1">
                        <InputGeneral
                            label="Horário Inicial"
                            value={formatedTime(this.state.timeStart)}
                            onChange={e => this.timeStartChangeAndChecking(e.target.value)}
                            mandatory="true"
                            valid={this.state.validTimeStart.toString()}
                            maxLength={5}
                        />
                    </div>
                    <div className="col-6 columnAbsenceAttendantsForm pl-1">
                        <InputGeneral
                            label="Horário Final"
                            value={formatedTime(this.state.timeEnds)}
                            onChange={e => this.timeEndsChangeAndChecking(e.target.value)}
                            mandatory="true"
                            valid={this.state.validTimeEnds.toString()}
                            maxLength={5}
                        />                        
                    </div>
                </div>
                <div className="row no-gutters lineAbsenceAttendantsForm">
                    <div className="col-12 columnAbsenceAttendantsForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterAbsenceAttendantsForm">
                    <Button
                        classaditional="buttonAbsenceAttendantsForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions()}
                    />
                    <Button
                        classaditional="buttonAbsenceAttendantsForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;

    return {
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        },
        newAttendantAbsence (status)
        {
            //action creator -> action
            const action = newAttendantAbsence(status);
            dispatch(action);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AbsenceAttendantsForm);