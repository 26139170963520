import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditActivities.css";
import { withRouter } from "react-router-dom";
import { formatedDateTimeToBRA, formatedTime, dateDiff, getDateTimeCurrentBrowserAtMidnight, formatedDateTimeToUSA, formatedDateTimeToBrowserAtMidnight, formatedMoneyBRA } from "../../auxiliary/generalFunctions";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";
import { PHONE_TYPE_ACTIVITY, TASK_TYPE_ACTIVITY, MAIL_TYPE_ACTIVITY, VISIT_TYPE_ACTIVITY, HISTORIC_TYPE_ACTIVITY, STATUS_BUSINESS_GAIN, STATUS_BUSINESS_LOSS, STATUS_BUSINESS_PAUSED} from "../../core/constants";
import Check from "./Check";
import Button from "./Button";
import Message from "./Message";
import Select from "./Select";

const initialState = {

    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
  
    // start message modal
    message_type: "information",
    message_show: false,
    message: "",
    // ends message modal

}

class TableEditActivities extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });
    }
    
    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT Activities prevProps ", prevProps);
        // console.log("TABLE EDIT Activities this.props ", this.props);
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    setPositionsDetails = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX, clientY} = e;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    getTypesCheckedFormated = (options) =>
    {
        let checkeds = "";
        let plus = 0;
        let display = "";

        options.forEach((obj, i) => {
            if (obj.checked)
            {
                plus++;
                if (checkeds === "")
                {
                    checkeds = obj.name;
                }
                
            }
        });

        if (plus <= 0)
        {
            display = checkeds;
        }
        else
        {
            plus = plus - 1;
            if (plus === 0)
            {
                display = checkeds;
            }
            else
            {
                display = checkeds+" (+"+plus+")";

            }

        }
        return display.toString();
    }

    getIconByTypeActivity = (type) =>
    {
        let allIconByType =  [
            {
                value: PHONE_TYPE_ACTIVITY,
                icon: "fas fa-phone-alt"
            },
            {
                value: VISIT_TYPE_ACTIVITY,
                icon: "fas fa-user-friends"
            },
            {
                value: TASK_TYPE_ACTIVITY,
                icon: "fas fa-thumbtack"
            },
            {
                value: MAIL_TYPE_ACTIVITY,
                icon: "far fa-paper-plane"
            },
            {
                value: HISTORIC_TYPE_ACTIVITY,
                icon: "far fa-clock"
            },
        ];
        let returnIcon = "";

        allIconByType.forEach(i => {
            if (type === i.value) 
            {
                returnIcon = i.icon;
            }
        });

        return returnIcon;

    }

    getColorUrgencyOfExecution = (dateActivity) =>
    {
        dateActivity = formatedDateTimeToBrowserAtMidnight(dateActivity);
        let dateNow = getDateTimeCurrentBrowserAtMidnight();
        let diffOne = dateDiff.inDays(dateActivity, dateNow);
        let diffTwo = dateDiff.inDays(dateNow, dateActivity);
        let color = "";
        
        // WARNING: for maintenance.
        // console.log(`dateActivity: ${dateActivity}, diffOne: ${diffOne}, diffTwo: ${diffTwo}`);
        
        if (diffOne >= 0 && diffTwo <= 0)
        {
            color = "error";
        }
        else if (diffOne === -1 && diffTwo === 1)
        {
            color = "warning";
        }
        else if (diffOne === -2 && diffTwo === 2)
        {
            color = "success";
        }

        return color;
    }

    getOwnersCheckedFormated = (options) =>
    {
        let checkeds = "";
        let plus = 0;
        let display = "";

        options.forEach((obj, i) => {
            if (obj.checked)
            {
                plus++;
                if (checkeds === "")
                {
                    checkeds = obj.name;
                }                
            }
        });

        if (plus <= 0)
        {
            display = checkeds;
        }
        else
        {
            plus = plus - 1;
            if (plus === 0)
            {
                display = checkeds;
            }
            else
            {
                display = checkeds+" (+"+plus+")";
            }

        }
        return display.toString();
    }

    getNameStatusBusiness = (slug) =>
    {
        switch (slug) {
            case null:
                return "Em andamento";

            case STATUS_BUSINESS_GAIN:
                return "Ganho";

            case STATUS_BUSINESS_LOSS:
                return "Perdido";

            case STATUS_BUSINESS_PAUSED:
                return "Pausado";

            default:
                return "";
        }
    }

    getIconStatusBusiness = (slug) =>
    {
        switch (slug) {
            case null:
                return "far fa-running";

            case STATUS_BUSINESS_GAIN:
                return "fas fa-trophy-alt";

            case STATUS_BUSINESS_LOSS:
                return "fas fa-do-not-enter";

            case STATUS_BUSINESS_PAUSED:
                return "far fa-pause-circle";

            default:
                return "";
        }
    }

    getClassnamesShowedup = showedup =>
    {
        if (showedup === 1 || showedup === 3)
        {
            return "far fa-user-check text-success";
        }
        else if (showedup === 2)
        {
            return "far fa-user-slash text-danger";
        }
        else
        {
            return "far fa-question-circle text-warning";
        }
    }

    getTitleShowedup = showedup =>
    {
        if (showedup === 1 || showedup === 3)
        {
            return "Compareceu";
        }
        else if (showedup === 2)
        {
            return "Não compareceu";
        }
        else
        {
            return "";
        }
    }

    verifyButtonUpdateShowedup = (activity) =>
    {
        if (activity.type !== VISIT_TYPE_ACTIVITY)
        {
            return false;
        }

        const now = new Date();
        const dateActivity = new Date(`${activity.dateStart} ${activity.timeStart}`);

        if (!activity.isShowedup && now < dateActivity)
        {
            return false;
        }

        return true;
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        });
    }

    render ()
    {
        return (
            <div className="tableEditActivities">
                <label className="helpTableEditActivities" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <table className="table">
                    <thead>
                        <tr className="theadTableEditActivities" ref={headerTable => {this.headerTable = headerTable}}>
                            {/* <td className="checkTableEditActivities">
                                <Check
                                    classaditional={`buttonCheckTableEditActivities ${(this.props.allActivitiesChecked) ? "active" : ""}`}
                                    checked={this.props.allActivitiesChecked}
                                    onClick={() => this.props.onToggleCheckedAllActivities(!this.props.allActivitiesChecked) }
                                />
                            </td> */}
                            <td
                                className="minimus45"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Concluído")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                            </td>
                            <td
                                className="minimus45"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Compareceu")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Compareceu
                            </td>
                            <td
                                className="minimus60"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "ID Negócio")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                ID Negócio
                            </td>
                            <td
                                className="minimus60"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Status do Atendimento")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Status
                            </td>
                            <td
                                className="minimus"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Data")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Data
                            </td>
                            <td
                                className="minimus75"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Horário")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Horário
                            </td>
                            <td
                                className="minimus180"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Título")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Título
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Negócio")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Negócio
                            </td>
                            <td
                                className="minimus"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Valor Negócio")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Valor
                            </td>
                            <td
                                className="minimus180"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Pessoa de Contato")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Pessoa de Contato
                            </td>
                            {/* <td
                                className="minimus120"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Telefone")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Telefone
                            </td> */}
                            <td
                                className="minimus120"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Unidade")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Unidade
                            </td>
                            <td
                                className=""
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Proprietário")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Proprietário
                            </td>
                            <td
                                className="minimus60"
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Detalhes")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.data.map((e, i) => {
                                let colorUrgencyOfExecution = "";

                                if (e.type === HISTORIC_TYPE_ACTIVITY)
                                {
                                    colorUrgencyOfExecution = "information";
                                }
                                else
                                {
                                    colorUrgencyOfExecution = (e.finished) ? "inactive" : this.getColorUrgencyOfExecution(e.dateStart);
                                }

                                const { dateMin, dateMax } = this.props.verifyDateMinAndDateMax(e.type, e.company.id);

                                return (
                                    <tr 
                                        key={`${i}`} 
                                        className={`tbodyTableEditActivities ${(e.finished && e.type !== HISTORIC_TYPE_ACTIVITY) ? "finished" : ""}`}
                                        style={{
                                            color: `var(--color-${colorUrgencyOfExecution})`
                                        }}
                                    >
                                        {/* <td className="checkTableEditActivities">
                                            <Check
                                                classaditional={`buttonCheckTableEditActivities ${(e.checked) ? "active" : ""}`}
                                                checked={e.checked}
                                                onClick={() => {
                                                    this.props.onChangeValue(!e.checked, e.id, "checked");
                                                    this.props.hasAllActivitiesChecked();
                                                }}
                                            />
                                        </td> */}
                                        <td className="center finishTableEditActivities">
                                            {
                                            <Check
                                                classaditional={`buttonFinishTableEditActivities ${(e.finished) ? "active" : ""}`}
                                                checked={e.finished}
                                                onClick={() => {
                                                    const finished = (e.finished) ? 2 : 1; //WARNING: inversion status
                                                    this.props.onCheckActivity(e.id, e.type, finished, e.business.id, e.business.idUser);                                             
                                                }}
                                            />
                                            }
                                        </td>
                                        <td className="center finishTableEditActivities">
                                            {
                                                this.verifyButtonUpdateShowedup(e) && 
                                                    <i 
                                                        title={this.getTitleShowedup(e.isShowedup)} 
                                                        className={`${this.getClassnamesShowedup(e.isShowedup)} acitiviesIconShowedup`}
                                                        onClick={() => {
                                                            
                                                            // WARNING: Don't change showedup when business is equal o GAIN
                                                            if (e.business.status === STATUS_BUSINESS_GAIN) { return; }
                                                            
                                                            if (!this.props.permissions.activities.toUpdate)
                                                            { return this.message("error", "Você não tem permissão para alterar a atividade!"); }
                                                            this.props.onClickShowedup(e.id, e.finished ? 1 : 2, e.business.id, e.business.idUser);
                                                        }}> 
                                                    </i>
                                            }
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditActivities noEdit"
                                                value={e.business.id}
                                                placeholder={e.business.id}
                                                name="ID Negócio"
                                                onChange={value => {}}
                                                onPositiveClick={() => {}}
                                                onNegativeClick={() => {}}
                                                permissions={this.props.permissions.business}
                                            />
                                        </td>
                                        <td
                                            title={this.getNameStatusBusiness(e.business.status)}
                                        >
                                            <FieldEdit
                                                classaditional="editTableEditActivities justifyCenter inheritIconColor noEdit"
                                                value={e.task}
                                                placeholder=""
                                                name="Status do Atendimento"
                                                onChange={value => {}}
                                                onPositiveClick={() => {}}
                                                onNegativeClick={() => {}}
                                                permissions={this.props.permissions.business}
                                                icon={`${this.getIconStatusBusiness(e.business.status)}`}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${(!this.props.permissions.activities.toUpdate || e.finished) && "noEdit"}`
                                                }
                                                value={formatedDateTimeToUSA(e.dateStart)}
                                                placeholder={formatedDateTimeToBRA(e.dateStart)}
                                                name="Data"
                                                onChange={value => this.props.onChangeValue(value, e.id, "dateStart")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "ACT_DATE_START", e.dateStart)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                type="date" 
                                                min={dateMin}
                                                max={dateMax}
                                                permissions={this.props.permissions.activities}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${(!this.props.permissions.activities.toUpdate || e.finished) && "noEdit"}` 
                                                }
                                                value={formatedTime(e.timeStart)}
                                                placeholder={formatedTime(e.timeStart)}
                                                name="Horas"
                                                onChange={value => this.props.onChangeValue(value, e.id, "timeStart")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "ACT_TIME_START", e.timeStart)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions.activities}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${colorUrgencyOfExecution}
                                                    ${(!this.props.permissions.activities.toUpdate || e.finished) && "noEdit"}` 
                                                }
                                                value={e.task}
                                                placeholder={e.task}
                                                name="Título"
                                                onChange={value => this.props.onChangeValue(value, e.id, "task")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "ACT_TITLE", e.task)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                icon={this.getIconByTypeActivity(e.type)}
                                                permissions={this.props.permissions.activities}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${!this.props.permissions.business.toUpdate && "noEdit"}` 
                                                }
                                                value={e.business.title}
                                                placeholder={e.business.title}
                                                name="Negócio"
                                                onChange={value => this.props.onChangeValue(value, e.id, "businessTitle")}
                                                onPositiveClick={() => this.props.onSaveValue(e.business.id, "BUS_TITLE", e.business.title)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions.business}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${!this.props.permissions.business.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedMoneyBRA(e.business.money)}
                                                placeholder={formatedMoneyBRA(e.business.money)}
                                                name="Negócio"
                                                onChange={value => this.props.onChangeValue(formatedMoneyBRA(value), e.id, "businessMoney")}
                                                onPositiveClick={() => this.props.onSaveValue(e.business.id, "BUS_MONEY_VALUE", formatedMoneyBRA(e.business.money))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions.business}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${!this.props.permissions.leads.toUpdate && "noEdit"}` 
                                                }
                                                value={e.business.nameUser}
                                                placeholder={e.business.nameUser}
                                                name="Pessoa de Contato"
                                                onChange={value => this.props.onChangeValue(value, e.id, "businessName")}
                                                onPositiveClick={() => this.props.onSaveValue(e.business.idUser, "PER_NAME", e.business.nameUser)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions.leads}
                                            />
                                        </td>
                                        {/* <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditActivities 
                                                    ${!this.props.permissions.leads.toUpdate && "noEdit"}` 
                                                }
                                                value={e.business.phoneUser}
                                                placeholder={formatedPhoneAndCellphone(e.business.phoneUser)}
                                                name="Telefone"
                                                onChange={value => this.props.onChangeValue(value, e.id, "businessPhone")}
                                                onPositiveClick={() => this.props.onSaveValue(e.business.idUser, "PER_PHONE_ONE", e.business.phoneUser)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={11}
                                                permissions={this.props.permissions.leads}
                                            />
                                        </td> */}
                                        <td>
                                            <FieldEdit
                                                classaditional="editTableEditActivities noEdit"
                                                value={e.company.name}
                                                placeholder={e.company.name}
                                                name="Unidade"
                                                onChange={value => {}}
                                                onPositiveClick={() => {}}
                                                onNegativeClick={() => {}}
                                                maxLength={14}
                                                permissions={this.props.permissions.activities}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={`editTableEditActivities ${!this.props.permissions.leadOwners.toView && "noEdit"}`}
                                                name="Proprietário"
                                                optionsSelectMulti
                                                placeholder={e.owner?.name}
                                                onChange={() => {}}
                                                onPositiveClick={() => this.props.onSaveValue(e.business.id, "BUS_ID_PERSON_OWNER", e.owner?.value, e.id)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={e.ownerPermissions}
                                            >
                                                <Select
                                                    default={e.owner}
                                                    options={this.props.optionsOwners}
                                                    onChange={async (optionChosenOwner) => {
                                                        await this.props.onChangeValue(optionChosenOwner, e.id, "owner");
                                                    }}
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <div className="detailsTableEditActivities">
                                                {/* {
                                                    e.annotations !== "" &&
                                                    <Button
                                                        classaditional="buttonShowDetailsTableEditActivities"
                                                        icon="fas fa-info"
                                                        onClick={() => {}}
                                                        onMouseOver={(event) => this.setPositionsDetails(event, e.annotations)}
                                                        onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                                                    />
                                                } */}
                                                <Button
                                                    classaditional="buttonShowDetailsTableEditActivities"
                                                    icon="fas fa-eye"
                                                    onClick={() => {window.open(`/business/details/${e.business.id}`)}}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default withRouter(connect(null, null)(TableEditActivities));