import React, { useState } from "react";
import ItemHistoric from "../CRM/ItemHistoric";
import ButtonIcon from "./ButtonIcon";
import "./InputSearch.css";

export default function InputSearch (props)
{
    const [focused, setFocused] = useState(false);

    function getStyleFocus ()
    {
        if (focused)
        {
            return "yesFocus";
        }
        else
        {
            return "noFocus";
        }
    }

    function handleFocus (focused)
    {
        setFocused(focused)
    }

    return (
        <div className="inputSearch">
            <i 
                className={`
                    far fa-search icon
                    ${getStyleFocus()}
                `}
            ></i>
            <input 
                className={`
                    field
                    ${getStyleFocus()}
                `}
                onBlur={() => handleFocus(false)}
                onMouseUp={() => handleFocus(true)}
                onChange={(e) => props.onChange(e)}
                value={props.value}
                placeholder={props.placeholder}
            />
            {
                props.value !== "" &&
                <div className="historicSearch"> 
                    {
                        props.data.map((e, i) => {
                            return (
                                <ItemHistoric key={i} data={e} permissions={props.permissions}/>
                            )
                        })
                    }
                </div>
            }
            {
                props.value !== "" &&
                <ButtonIcon
                    icon="fal fa-times"
                    onClick={() => props.onClearInputSearch()}
                    className="buttonIcon"
                />
            }
        </div>
    )
}