import React, { useState } from "react"; 
import "./InputGeneral.css";
import ButtonIcon from "../../components/general/ButtonIcon";
import loading from "../../assets/images/loading.png";

export default function InputGeneral (props)
{
    const [showWarning, setShowWarning] = useState(false);
    const [showClear] = useState(props?.onClear ? true : false);
    var ownProperties = {...props};
    ownProperties = {...ownProperties};
    delete ownProperties.onClear;

    return (
        <div className={`groupInputGeneral ${props?.classaditional}`}>
            {
                props.label &&
                <label className={`labelInputGeneral`}>
                    {props.label}
                    {(props.mandatory && props.mandatory === "true")? " *" : ""} 
                </label>                
            }
            <div className="inputInputGeneral">
                <input 
                    className={`
                        fieldInputGeneral 
                       ${
                           (props?.valid === "false")
                           ? "error"
                           : null
                       }
                    `} 
                    {...ownProperties}
                />
                {
                    showClear &&
                    <ButtonIcon icon="far fa-times" classaditional="buttonClearInputGeneral" onClick={props.onClear}/>
                }
                {
                    props?.searching && 
                    <div className="moreInformationSearchInputGeneral">
                        {
                            props?.searching === "true"
                            ? <img className="img-fluid" src={loading} alt="imagem de loading"/>
                            : null
                        }

                        {
                            props?.warning === "true"
                            ?   <ButtonIcon 
                                    icon="far fa-exclamation-triangle"
                                    onClick={(e) => setShowWarning(!showWarning)}
                                    classaditional="buttonInformationInputGeneral"
                                />
                            : null
                        }

                        {
                            (props?.warningcontent && showWarning)
                            ?   <div
                                    className="contentWarningSearchInputGeneral"
                                    
                                >
                                    <div className="headerContentWarningInputGeneral">
                                        <h3 className="title">aviso</h3>
                                        <ButtonIcon 
                                            icon="fal fa-times"
                                            onClick={() => setShowWarning(false)}
                                            classaditional="buttonCloseContentWarningInputGeneal"
                                        />
                                    </div>
                                    <div className="bodyContentWarningInputGeneral">
                                        {
                                            props.warningcontent.map((e, i) => {
                                                return (
                                                    <div key={`notice${i}`} className="notice">
                                                        <span  className="label">{e.label}</span> 
                                                        <span 
                                                            className="input"
                                                            onClick={() => {
                                                                if 
                                                                (
                                                                    props.warningclicked !== null && 
                                                                    props.warningclicked !== undefined
                                                                )
                                                                {
                                                                    window[props.warningclicked](e.input);
                                                                }
                                                                
                                                                setShowWarning(false);
                                                            }}
                                                        >
                                                            {e.input}
                                                        </span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                }
            </div>
        </div>
    )

}