import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class PermissionGroupController 
{
    async create(data) 
    {
        const response = await api.permissionsGroup().post(data);
        return apiCallbackTreatment(response);
    }

    async get() 
    {
        const response = await api.permissionsGroup().get();
        return apiCallbackTreatment(response);
    }

    async getById(id)
    {
        const response = await api.permissionsGroup().getById(id);
        return apiCallbackTreatment(response);
    }

    async savePermissions(id, data) 
    {
        const response = await api.savePermissions().update(id, data);
        return apiCallbackTreatment(response);
    }

    async update(id, data) 
    {
        const response = await api.permissionsGroup().update(id, data);
        return apiCallbackTreatment(response);
    }
}