import {
    GET_ALL_CAMPAIGN,
    CHANGE_CAMPAIGN,
    NEW_CAMPAIGN,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_CAMPAIGN,
    TOGGLE_CHECKED_ALL_CAMPAIGN
}  from "../../core/constants";

//action creator
export function getAllCampaign (data)
{
    return {
        type: GET_ALL_CAMPAIGN,
        payload: data
    }
}

//action creator
export function changeCampaign (data)
{
    return {
        type: CHANGE_CAMPAIGN,
        payload: data,
    }
}

//action creator
export function newCampaign (data)
{
    return {
        type: NEW_CAMPAIGN,
        payload: data,
    }
}

//action creator
export function changeStatusGetFromDatabaseCompletedCampaign (status)
{
    return {
        type: CHANGE_STATUS_GET_FROM_DATABASE_ALL_CAMPAIGN,
        payload: status
    }
}

//action creator
export function toggleCheckedAllCampaign (status)
{
    return {
        type: TOGGLE_CHECKED_ALL_CAMPAIGN,
        payload: status
    }
}