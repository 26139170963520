import { SHOW_MODAL_MORE_ACTIONS_GENERAL, SET_MODAL_MESSAGE, SET_CURRENT_SCREEN_CRM }  from "../../core/constants";

//action creator
export function showModalMoreActions (data)
{
    return {
        type: SHOW_MODAL_MORE_ACTIONS_GENERAL,
        payload: data,
    }
}

//action creator
export function setModalMessage (data)
{
    return {
        type: SET_MODAL_MESSAGE,
        payload: data,
    }
}

//action creator
export function setCurrentScreenCRM (path)
{
    return {
        type: SET_CURRENT_SCREEN_CRM,
        payload: path,
    }
}

