import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class WorkShiftsController
{
    async get() 
    {
        const response = await api.workShifts().get();
        return apiCallbackTreatment(response);
    }
}