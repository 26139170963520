import React, { Fragment } from "react";
import InputGeneral from "./InputGeneral";
import "./ModalEdit.css";
import Button from "../general/Button";

export default function ModalEdit (props)
{
    return (
        <div className={`modalEdit ${props?.classaditional}`}>
            <div className="headerModalEdit">
                <h5 className="titleModalEdit">{props?.title}</h5>
            </div>
            <div className="bodyModalEdit">
                {
                    !props.optionsSelectMulti &&
                    <InputGeneral
                        maxLength={props?.maxLength}
                        type={props.type}
                        placeholder={props.value}
                        value={props.value}
                        onChange={e => props.onChange(e.target.value)} 
                        min={props?.min}
                        max={props?.max}
                    />
                }
                {
                    props.optionsSelectMulti &&
                    <Fragment>
                        {props.children}
                    </Fragment>
                }
            </div>
            <div className="footerModalEdit">
                <Button
                    classaditional="buttonModalEdit"
                    name="Cancelar"
                    onClick={() => props.onNegativeClick()}
                />
                {
                    props.permissions.toUpdate && 
                    <Button
                        classaditional="buttonModalEdit positive"
                        name="Salvar"
                        onClick={() => props.onPositiveClick()}
                    />
                }
            </div>
        </div>
    )
}
