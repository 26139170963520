import React, { Component } from "react";  
import "./CommissionReportIntervalsForm.css";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showModalMoreActions }  from "../../store/actions/general";
import { formatedMoneyBRAFromFloat } from "../../auxiliary/generalFunctions";
import { getAllCommissionReport } from "../../store/actions/commission";
import { setModalMessage } from "../../store/actions/general";

import Button from "../general/Button";
import InputGeneral from "../general/InputGeneral";
import CommissionReportIntervalsController from "../../controllers/CommissionReportIntervalsController";

const initialState = {
    id: 0, 
    name: "",
    amountTotal: 0,

    // start content percentCommission
    percentCommission: "",
    validPercentCommission: true,
    // ends content percentCommission

    // start content amountCommission
    amountCommission: "",
    validAmountCommission: true,
    // ends content amountCommission

    disabledButton: false
}

const commissionReportIntervalsController = new CommissionReportIntervalsController();

class CommissionReportIntervalsForm extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        let {id, name, percentCommission, amountCommission, amountTotal} = this.props.data;
        await this.setState({id, name, percentCommission, amountCommission, amountTotal});
    }

    componentDidUpdate = async (prevProps) =>
    {
        // console.log("COMMISSION REPORT INTERVALS prevProps ", prevProps);
        // console.log("COMMISSION REPORT INTERVALS this.props ", this.props);
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        };
        this.props.showModalMoreActions(data);
    }

    pecentCommissionChangeAndChecking = async (percentCommission) =>
    {
        let amountCommission = 0;

        percentCommission = percentCommission.replace(/[A-Za-z]/g, "");
        percentCommission = percentCommission.toString().replace(",", ".");

        if (percentCommission.toString().trim() !== "")
        {
            let amountTotal = await this.state.amountTotal;
            amountCommission = (amountTotal * percentCommission / 100);
            amountCommission = formatedMoneyBRAFromFloat(amountCommission);
        }

        await this.setState({
            amountCommission,
            percentCommission,
            validPercentCommission: percentCommission.toString().trim() !== "",
        });
    }

    amountCommissionChangeAndChecking = async (amountCommission) =>
    {
        let percentCommission = 0;

        if (amountCommission)
        {
            let amountTotal = await this.state.amountTotal;
            let amountCommissionToCalculated = amountCommission.replace(/[A-Za-z]/g, "");
            amountCommissionToCalculated = amountCommission.toString().replace(".", ",");
            amountCommissionToCalculated = amountCommission.toString().replace(",", ".");
            percentCommission = (amountCommissionToCalculated / amountTotal * 100).toFixed(2);

            if (isNaN(percentCommission)) { percentCommission = ""; }
        }

        amountCommission = formatedMoneyBRAFromFloat(amountCommission);

        await this.setState({
            amountCommission,
            percentCommission,
            validAmountCommission: amountCommission.toString().trim() !== ""
        });
    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }

    onActionForm = async () =>
    {
        let idReport = await this.state.id;
        let percent = this.state.percentCommission;
        let amount = this.state.amountCommission;
        
        if (percent.toString().trim() === "") { return this.setState({validPercentCommission: false}); }
        if (amount.toString().trim() === "") { return this.setState({validAmountCommission: false}); }
        
        await this.setState({ disabledButton: true });
        
        amount = amount.toString().replace(".", "");
        amount = amount.toString().replace(",", ".");

        await commissionReportIntervalsController.update(idReport, {
            COI_COMMISSION_PERCENT: percent, 
            COI_COMMISSION_AMOUNT: amount,
            USER_ID: this.props.user.PER_ID
        });

        await this.setState({ disabledButton: false });
        this.props.data.onSaveCommission();
        this.closeModalMoreActions();
    }

    render ()
    {
        return (
            <div className="commissionReportIntervalsForm"> 
                <div className="row no-gutters lineCommissionReportIntervalsForm">
                    <div className="col-12 columnCommissionReportIntervalsForm">
                        <InputGeneral
                            classaditional="fieldCommissionReportIntervalsForm disabled"
                            label="Nome"
                            value={this.state.name}
                            onChange={e => {}}
                            mandatory="true"
                            disabled
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionReportIntervalsForm">
                    <div className="col-12 columnCommissionReportIntervalsForm">
                        <InputGeneral
                            classaditional="fieldCommissionReportIntervalsForm disabled"
                            label="Total Recebido"
                            value={formatedMoneyBRAFromFloat(this.state.amountTotal)}
                            onChange={e => {}}
                            mandatory="true"
                            disabled
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionReportIntervalsForm">
                    <div className="col-12 columnCommissionReportIntervalsForm">
                        <InputGeneral
                            label="Comissão (%)"
                            placeholder="14.98"
                            value={this.state.percentCommission}
                            onChange={e => this.pecentCommissionChangeAndChecking(e.target.value)}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validPercentCommission.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionReportIntervalsForm">
                    <div className="col-12 columnCommissionReportIntervalsForm">
                        <InputGeneral
                            label="Comissão (R$)"
                            value={this.state.amountCommission}
                            onChange={e => this.amountCommissionChangeAndChecking(e.target.value)}
                            mandatory="true"
                            valid={this.state.validAmountCommission.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionReportIntervalsForm">
                    <div className="col-12 columnCommissionReportIntervalsForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterCommissionReportIntervalsForm">
                    <Button
                        classaditional="buttonCommissionReportIntervalsForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions()}
                        />
                    <Button
                        classaditional="buttonCommissionReportIntervalsForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                        disabled={this.state.disabledButton}
                    />                  
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;
    return {
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        },
        getAllCommissionReport ()
        {
            //action creator -> action
            const action = getAllCommissionReport();
            dispatch(action);
        },
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommissionReportIntervalsForm));