import React, { Component } from "react";
import "./PersonOnline.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { APPLICATION_NAME } from "../../core/constants";
import { setModalMessage } from "../../store/actions/general";
import { setSocketsMainOnlineUpdatedAuth } from "../../store/actions/auth";

import Header from "../../components/CRM/Header";
import Box from "../../components/general/Box";
import { sortInAlphabeticalOrder } from "../../auxiliary/generalFunctions";

const initialState = {
    sockets: [],
}

class PersonOnline extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Usuários Online`;
        await this.getAllSocketsMainOnline();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("PERSON ONLINE prevProps ", prevProps);
        // console.log("PERSON ONLINE this.props ", this.props); 

        if (!prevProps.auth.socketMainOnlineUpdated && this.props.auth.socketMainOnlineUpdated)
        {
            let sockets = this.props.auth.socketsMainOnline;
            sockets = this.simplify(sockets);
            await this.setState({sockets});
            this.props.setSocketsMainOnlineUpdatedAuth(false);
        }
        
        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.ownersOnline.toView) 
			{
				this.props.history.push(`/activities`);
			}
		}
    }

    simplify = (sockets) =>
    {
        let newSockets = [];
        let idAlreadyInserted = [];

        sockets.forEach((s) => {

            if (!idAlreadyInserted.includes(s.id))
            {
                newSockets.push({...s, instances: 1});
                idAlreadyInserted.push(s.id);
            }
            else
            {
                newSockets = newSockets.map(o => {
                    if (o.id === s.id) { o.instances++; }
                    return o;
                });                
            }
        });

        newSockets = sortInAlphabeticalOrder(newSockets);

        return newSockets;
    }

    getAllSocketsMainOnline = async () =>
    {
        let sockets = this.props.auth.socketsMainOnline;
        sockets = this.simplify(sockets);
        await this.setState({sockets});
    }
 
    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }

    render ()
    {
        return (
            <div className="personOnline">
                <Header title="Usuários Online"/>
                <div className="scrollPersonOnline mb-4">
                    <div className="container headerPersonOnline">
                        <Box>
                            <h4>Usuários Online (instâncias do socket main)</h4>
                        </Box>
                    </div>
                    <div className="container bodyPersonOnline">
                        <Box classaditional="boxTablePersonOnline">
                            <span className="amountPersonOnline">Pessoa(s) online: {this.state.sockets.length}</span>
                            <div className="socketMainPersonOnline header">
                                <div className="minimum"><span>ID</span></div>
                                <div className="minimum name"><span>Nome</span></div>
                                <div className="minimum right"><span>Instância(s)</span></div>
                            </div>
                            {
                                this.state.sockets.map((s, i) => {
                                    return (
                                        <div className="socketMainPersonOnline body" key={i}>
                                            <div className="minimum"><span>{s.id}</span></div>
                                            <div className="minimum name"><span>{s.name}</span></div>
                                            <div className="minimum right"><span>{s.instances}</span></div>
                                        </div>
                                    )
                                })
                            }
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const permissions = state.permissions;
    const auth = state.auth;
    const hasPermissionsLoaded = permissions.hasPermissionsLoaded;

    return {
        permissions,
        hasPermissionsLoaded,
        auth
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
        setSocketsMainOnlineUpdatedAuth (status)
        {
            //action creator -> action
            const action = setSocketsMainOnlineUpdatedAuth(status);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonOnline));