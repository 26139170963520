import React, { Component } from "react";
import "./CardButonsTopLineChart.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import PerformanceIndex from "../../components/general/PerformanceIndex";
import { CustomTooltipLineCharts } from "../charts/CustomTooltipLineCharts";
import ButtonIcon from "../general/ButtonIcon";
import Loading from "../general/Loading";

const initialState = {
    buttons: [],
    charts: [],
    chart: [],
    widthLineButtons: 0,
    enableButtonsCarousel: false,
}

const WIDTH_DEFAULT_BUTTON_TOGGLE = 140; //px

export default class CardButonsTopLineChart extends Component
{
    state = {...initialState}

    constructor(props) {
        super(props);        
        this.lineChart = React.createRef();
        this.lineButtons = React.createRef();

    }

    componentDidMount = async () =>  { }

    componentDidUpdate = async () =>
    {
        let buttons = this.props.buttons;
        let charts = this.props.charts;
        let updated = this.props.updated;
        let lineChart = this.lineChart;

        if (this.state.charts.length === 0 && charts !== undefined && updated)
        {
            await this.setState({buttons, charts, chart: charts[0].chart, updated: false});            
            this.handleButtonsCarousel(charts);
        }

        if (lineChart !== null && this.state.widthLineButtons === 0)
        {
            let widthLineButtons = lineChart.current.clientWidth - 80;
            await this.setState({widthLineButtons});
        }

        if (!updated && this.state.chart.length !== 0) { await this.setState({buttons: [], chart: [], charts: []}); }
    }

    toggleButton = async (index) =>
    {
        let buttons = await this.state.buttons;
        let charts = await this.state.charts;
        let chart = charts.filter(obj => obj.indexButtonTarget === index)[0].chart;
        
        buttons = buttons.map(b => {
            b.active = false; 
            if (b.index === index) { b.active = true; }
            return b;
        });

        await this.setState({
            buttons, 
            chart
        });

    }

    getRandomDataChart = async () =>
    {
        let dataChart = [];

        for (let i = 1; i <= 30; i++) 
        {
            dataChart.push({
                name: `${i} out.`,
                after: parseInt(Math.random() * 400),
                before: parseInt(Math.random() * 400),
            });
        }

        await this.setState({dataChart});
    }

    handleButtonsCarousel = (charts) =>
    {
        let widthInnerCarousel = this.lineChart.current.clientWidth;
        let widthNeedButtons = charts.length * WIDTH_DEFAULT_BUTTON_TOGGLE + 50; // (WIDTH_DEFAULT_BUTTON_TOGGLE) width default of the button.
        let enableButtonsCarousel = (widthNeedButtons >= widthInnerCarousel);
        this.setState({enableButtonsCarousel});        
    }

    setButtonsToggle = () =>
    {
        let buttonsJSX = this.state.buttons;
        buttonsJSX = buttonsJSX.map(b => {
            return (
                <div 
                    key={b.index} className={`buttonToggleCardButtonsTopLineChart ${b.active ? "active" : ""}`}
                    onClick={() => this.toggleButton(b.index)}
                >
                    <span className="labelButtonToggleCardButtonsTopLineChart">{b.label}</span>
                    <span className="amountButtonToggleCardButtonsTopLineChart success">{b.amountAfterPerformance}</span>
                    <span className="amountButtonToggleCardButtonsTopLineChart information">{b.amountBeforePerformance}</span>
                    <PerformanceIndex classaditional="performanceButtonToggleCardButtonsTopLLineChart" valueBefore={b.amountBeforePerformance} valueAfter={b.amountAfterPerformance} />
                </div>  
            )
        });

        return buttonsJSX;
    }

    moveInnerCarousel = (direction) =>
    {
        let scrolled = this.lineButtons.current.scrollLeft;
        let scrollTo = (direction === "next") ? (scrolled + (WIDTH_DEFAULT_BUTTON_TOGGLE / 2)) : (scrolled - (WIDTH_DEFAULT_BUTTON_TOGGLE / 2));
        this.lineButtons.current.scrollTo({left: scrollTo, behavior: "smooth"});
    }

    render ()
    {
        return (
            <div className="cardButonsTopLineChart">
                <div className="lineGeneralCardButonsTopLineChart">
                    {
                        this.state.enableButtonsCarousel &&
                        <ButtonIcon
                            icon="far fa-chevron-left"
                            classaditional="buttonCarouselCardButtonsTopLineChart previous"
                            onClick={() => this.moveInnerCarousel("previous")}
                        />
                    }
                    <div className="lineCardButonsTopLineChart buttons" style={{width: `${this.state.widthLineButtons}px`}} ref={this.lineButtons}>
                        {this.setButtonsToggle()}
                    </div>
                    {
                        this.state.enableButtonsCarousel &&
                        <ButtonIcon
                            icon="far fa-chevron-right"
                            classaditional="buttonCarouselCardButtonsTopLineChart next"
                            onClick={() => this.moveInnerCarousel("next")}
                        />
                    }
                </div>
                <div className="row no-gutters lineCardButonsTopLineChart chart" ref={this.lineChart}>
                    <ResponsiveContainer  width="100%" height={400}>
                        <LineChart width="100%" height={400} data={this.state.chart}>
                        <CartesianGrid strokeDasharray="0 0" vertical={false} />
                        <XAxis 
                            dataKey="name"
                            padding={{ right: 20 }}
                            axisLine={{stroke: "var(--dark-light)"}}
                            tickLine={{stroke: "var(--dark-light)"}}
                        />
                        <YAxis 
                            dataKey="before"
                            orientation="right"
                            axisLine={{stroke: "var(--dark-light)"}}
                            tickLine={{stroke: "var(--dark-light)"}}
                            strokeWidth={0}
                            type="number"
                            tickCount={10}
                            domain={[0, 'dataMax + 400']}
                        />
                        <Tooltip
                            content={<CustomTooltipLineCharts />}
                        />
                        <Legend 
                            iconType="plainline"
                            align="left"
                        />
                        <Line 
                            name={`Período de ${this.state.chart.length} dia(s)`}
                            type="linear" 
                            dataKey="after" 
                            strokeWidth={2}
                            stroke="var(--color-success)" 
                            dot={{ stroke: 'var(--color-success)', strokeWidth: 2, r: 0}}
                            activeDot={{ stroke: 'var(--color-success)', strokeWidth: 2, r: 2}}
                        />
                        <Line 
                            name="Período anterior (dia correspondente da semana)"
                            type="linear" 
                            dataKey="before" 
                            strokeWidth={1}
                            stroke="var(--color-information)" 
                            dot={{ stroke: 'var(--color-information)', strokeWidth: 2, r: 0}} 
                            activeDot={{ stroke: 'var(--color-information)', strokeWidth: 2, r: 2}} 
                        />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                { !this.props.updated && <div className="filterLoadingCardButtonsTopLineCharts"><Loading classaditional="loadingCardButtonsTopLineChart" transparent loading={true} /></div> }
            </div>
        )
    }
}