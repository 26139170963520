import {
    GET_ALL_CAMPAIGN,
    CHANGE_CAMPAIGN,
    NEW_CAMPAIGN,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_CAMPAIGN,
    TOGGLE_CHECKED_ALL_CAMPAIGN
} from "../../core/constants";

const initialState = {

    allCampaign: [],
    getAllCampaignFromDatabaseCompleted: true,
}

export default function campaignReducer (state = initialState, action)
{
    switch (action.type)
    {
        case GET_ALL_CAMPAIGN:
            
            return {
                ...state,
                allCampaign: action.payload,
                getAllCampaignFromDatabaseCompleted: true,
            }

        case CHANGE_CAMPAIGN:
        
            let {
                    id,
                    checked,
                    name,
                    idCompanyUnit,
                    nameCompanyUnit,
                    idMidiaOrigin,
                    nameMidiaOrigin,
                    amountInvested,
                    timeStart,
                    timeEnds,
                    dateStart,
                    dateEnds,
                    executions,
                    isItPossibleToRun,
                    running,
                    propertyName
                } = action.payload;

            let allCampaignFinal = state.allCampaign.map(d => {
                
                if (d.id === parseInt(id))
                {
                    switch (propertyName) {

                        case "checked":
                            d.checked = checked;
                            break;

                        case "name":
                            d.name = name;
                            break;

                        case "idCompanyUnit":
                            d.idCompanyUnit = idCompanyUnit;
                            break;

                        case "nameCompanyUnit":
                            d.nameCompanyUnit = nameCompanyUnit;
                            break;

                        case "idMidiaOrigin":
                            d.idMidiaOrigin = idMidiaOrigin;
                            break;

                        case "nameMidiaOrigin":
                            d.nameMidiaOrigin = nameMidiaOrigin;
                            break;

                        case "amountInvested":
                            d.amountInvested = amountInvested;
                            break;

                        case "timeStart":
                            d.timeStart = timeStart;
                            break;

                        case "timeEnds":
                            d.timeEnds = timeEnds;
                            break;

                        case "dateStart":
                            d.dateStart = dateStart;
                            break;

                        case "dateEnds":
                            d.dateEnds = dateEnds;
                            break;

                        case "executions":
                            d.executions = executions;
                            break;

                        case "isItPossibleToRun":
                            d.isItPossibleToRun = isItPossibleToRun;
                            break;

                        case "running":
                            d.running = running;
                            break;

                        default: 
                            break;
                    }                        
                }
                return d;
            });

            return {
                ...state,
                allCampaign: allCampaignFinal
            }

        case NEW_CAMPAIGN:
            let allCampaignNew = state.allCampaign;
            allCampaignNew.push(action.payload);
            
            return {
                ...state,
                allCampaign: allCampaignNew,
                getAllCampaignFromDatabaseCompleted: false,
            }

        case CHANGE_STATUS_GET_FROM_DATABASE_ALL_CAMPAIGN:
            
            return {
                ...state,
                getAllCampaignFromDatabaseCompleted: action.payload
            }
        
        case TOGGLE_CHECKED_ALL_CAMPAIGN:

            let checkedToggle = action.payload;
            let allCampaignFinalChecked = state.allCampaign.map(d => {
                d.checked = checkedToggle;
                return d;
            });

            return {
                ...state,
                allCampaign: allCampaignFinalChecked
            }

        default:
            return state
    }
}