import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class SystemController 
{
    async create(data) 
    {
        const response = await api.system().post(data);
        return apiCallbackTreatment(response);
    }

    async get() 
    {
        const response = await api.system().get();
        return apiCallbackTreatment(response);
    }

    async update(id, data) 
    {
        const response = await api.system().update(id, data);
        return apiCallbackTreatment(response);
    }
}