import { 
    GET_ALL_ACTIVITIES,
    CHANGE_TASK_ACTIVITIES,
    TOGGLE_CHECKED_ALL_ACTIVITIES,
    CHANGE_STATUS_GET_FROM_DATABASE_ALL_ACTIVITIES
}  from "../../core/constants";

//action creator
export function getAllActivities (data)
{
    return {
        type: GET_ALL_ACTIVITIES,
        payload: data
    }
}

//action creator
export function changeTaskActivities (data)
{
    return {
        type: CHANGE_TASK_ACTIVITIES,
        payload: data,
    }
}

//action creator
export function toggleCheckedAllActivities (status)
{
    return {
        type: TOGGLE_CHECKED_ALL_ACTIVITIES,
        payload: status
    }
}

//action creator
export function changeStatusGetFromDatabaseCompletedActivities (status)
{
    return {
        type: CHANGE_STATUS_GET_FROM_DATABASE_ALL_ACTIVITIES,
        payload: status
    }
}