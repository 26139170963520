import CommissionController from "../../controllers/CommissionRulesController";
import CommissionReport from "../../controllers/CommissionReportController";
import CommissionReportIntervals from "../../controllers/CommissionReportIntervalsController";
import {
    GET_ALL_COMMISSION_RULE,
    GET_ALL_COMMISSION_REPORT,
    GET_ALL_COMMISSION_REPORT_INTERVALS
} from "../../core/constants";

const commissionController = new CommissionController();
const commissionReport = new CommissionReport();
const commissionReportIntervals = new CommissionReportIntervals();

export const getAllCommissionRulesMiddleware = (store) => (next) => async (action) =>
{
    if (action.type === GET_ALL_COMMISSION_RULE)
    {
        let payload = [];
        const result = await commissionController.get();

        if (result.status)
        {
            for (const commission of result.data.data) 
            {
                payload.push({
                    id: commission.COR_ID,
                    checked: false,
                    unit: commission.COR_ID_COMPANY_UNIT,
                    month: commission.COR_MONTH,
                    utilizationCommission: commission.COR_PERCENT_UTILIZATION_COMMISSION * 100,
                    receiptCommission: commission.COR_PERCENT_RECEIVING_COMMISSION * 100,
                    utilizationGoal: commission.COR_PERCENT_UTILIZATION_MINIMUM_GOAL * 100,
                    receivingGoal: commission.COR_PERCENT_RECEIVING_MINIMUM_GOAL * 100,
                    lossGoal: commission.COR_PERCENT_LOSS_MINIMUM_GOAL * 100,
                    performanceBonus: commission.COR_PERCENT_UTILIZATION_BONUS * 100,
                    receivingBonus: commission.COR_PERCENT_RECEIVING_BONUS * 100,
                    usageCeiling: commission.COR_PERCENT_UTILIZATION_MAXIMUM_GOAL * 100,
                    receiptCeiling: commission.COR_PERCENT_RECEIVING_MAXIMUM_GOAL * 100,
                    lossCeiling: commission.COR_PERCENT_LOSS_MAXIMUM_GOAL * 100,
                    monthsPortfolio: commission.COR_TOTAL_MONTHS_OF_PORTFOLIO,
                    target: commission.COR_RULE_TARGET,
                    status: commission.COR_STATUS_COMMISSION_REPORT
                });
            };
        }

        action.payload = payload;
    }

    next(action);
}

export const getAllCommissionReportMiddleware = (store) => (next) => async (action) =>
{
    if (action.type === GET_ALL_COMMISSION_REPORT)
    {
        let payload = [];
        const result = await commissionReport.get();

        if (result.status)
        {
            for (const report of result.data.data)
            {
                let amountUtilizationGain = report.COP_AMOUNT_BUSINESS_GAIN_UTILIZATION;
                let amountUtilizationLoss = report.COP_AMOUNT_BUSINESS_LOSS_UTILIZATION;
                let amountUtilizationTotal = (amountUtilizationGain + amountUtilizationLoss);
                let percentUtilization = (amountUtilizationGain === 0) ? 0 : parseInt(amountUtilizationGain / amountUtilizationTotal * 100);
                let amountReceivingSold = report.COP_AMOUNT_SALES_ON_MONTH;
                let amountRecevingReceived = report.COP_AMOUNT_SALES_RECEIVED_ON_MONTH;
                let amountRecevingReceivedOnMonthsPortfolio = report.COP_AMOUNT_SALES_RECEIVED_ON_MONTHS_PORTFOLIO;
                let amountTotalReceived = amountRecevingReceived + amountRecevingReceivedOnMonthsPortfolio;
                let percentReceiving = (amountReceivingSold === 0) ? 0 : parseInt(amountRecevingReceived / amountReceivingSold * 100);
                let amountCommissionToSeller = report.COP_COMMISSION_AMOUNT_TOTAL_GLOBAL_TO_SELLER;
                let percentCommission = (report.COP_COMMISSION_PERCENT_TOTAL_COMMISSION_TO_SELLER === 0) ? 0 : parseFloat(report.COP_COMMISSION_PERCENT_TOTAL_COMMISSION_TO_SELLER.toFixed(2));

                let log = null;
                if (report.log) 
                {
                    log = {
                        oldCommissionPercent: report.log.LCR_OLD_COMMISSION_PERCENT,
                        oldCommissionAmount: report.log.LCR_OLD_COMMISSION_AMOUNT
                    }
                }

                payload.push(
                    {
                        idCommissionReport: report.COP_ID,
                        id: report.COP_ID_PERSON,
                        name: report.PER_NAME,
                        amountUtilizationTotal,
                        amountUtilizationGain,
                        percentUtilization,
                        percentUtilizationMinimumGoal: report.COP_RULE_PERCENT_UTILIZATION_MINIMUM_GOAL,
                        amountReceivingSold,
                        amountRecevingReceived,
                        amountRecevingReceivedOnMonthsPortfolio,
                        amountTotalReceived,
                        percentReceiving,
                        percentReceivingMinimumGoal: report.COP_RULE_PERCENT_RECEIVING_MINIMUM_GOAL,
                        percentCommission,
                        amountCommissionToSeller,
                        month: report.COP_MONTH,
                        monthsPortfolio: report.COR_TOTAL_MONTHS_OF_PORTFOLIO,
                        idPermissionsGroup: report.PER_ID_PERMISSIONS_GROUP,
                        company: {
                            id: report.COP_ID_COMPANY_UNIT,
                            name: report.UNT_NAME
                        },
                        log
                    }
                );
            };
        }

        action.payload = payload;
    }

    if (action.type === GET_ALL_COMMISSION_REPORT_INTERVALS)
    {
        let payload = [];
        let result = await commissionReportIntervals.get();

        if (result.status)
        {
            for (const report of result.data.data)
            {
                let amountUtilizationGain = report.COI_AMOUNT_BUSINESS_GAIN;
                let amountUtilizationLoss = report.COI_AMOUNT_BUSINESS_LOSS;
                let amountUtilizationTotal = (amountUtilizationGain + amountUtilizationLoss);
                let percentUtilization = (amountUtilizationGain === 0) ? 0 : parseInt(amountUtilizationGain / amountUtilizationTotal * 100);
                let amountReceivingSold = parseFloat(report.COI_AMOUNT_SALES_ON_MONTH);
                let amountRecevingReceived = parseFloat(report.COI_AMOUNT_SALES_RECEIVED_ON_MONTH);
                let percentReceiving = (amountReceivingSold === 0) ? 0 : parseInt(amountRecevingReceived / amountReceivingSold * 100);
                let amountRecevingReceivedOnMonthsPortfolio = parseFloat(report.COI_AMOUNT_SALES_RECEIVED_ON_MONTHS_PORTFOLIO);
                let amountTotalReceived = amountRecevingReceived + amountRecevingReceivedOnMonthsPortfolio;
                let percentCommission = (parseFloat(report.COI_COMMISSION_PERCENT) === 0) ? 0 : parseFloat(report.COI_COMMISSION_PERCENT);
                let amountCommissionToSeller = parseFloat(report.COI_COMMISSION_AMOUNT);
                let log = null;

                if (report.log) 
                {
                    log = {
                        oldCommissionPercent: report.log.LCI_OLD_COMMISSION_PERCENT,
                        oldCommissionAmount: report.log.LCI_OLD_COMMISSION_AMOUNT
                    }
                }

                payload.push(
                    {
                        id: report.COI_ID_PERSON,
                        idReport: report.COI_ID,
                        name: report.PER_NAME,
                        month: report.COI_MONTH,
                        amountUtilizationTotal,
                        amountUtilizationGain,
                        percentUtilization,
                        amountReceivingSold,
                        amountRecevingReceived,
                        percentReceiving,
                        monthsPortfolio: report.COI_TOTAL_MONTHS_OF_PORTFOLIO,
                        amountRecevingReceivedOnMonthsPortfolio,
                        amountTotalReceived,
                        percentCommission,
                        amountCommissionToSeller,
                        company: {
                            id: report.COI_ID_COMPANY_UNIT,
                            name: report.UNT_NAME
                        },
                        log
                    }
                );
            }
        }

        action.payload = payload;
    }

    next(action);
}