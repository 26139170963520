import React, { Component } from "react";
import "./Room.css";
import Header from "../../components/CRM/Header";
import { setModalMessage } from "../../store/actions/general";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { baseURLSocket } from "../../configuration";
import { 
    APPLICATION_NAME,
    EVENT_JOIN_ROOM,
    EVENT_USER_DISCONNECTED,
    EVENT_STOP_MY_VIDEO,
    EVENT_START_MY_VIDEO,
    EVENT_STOP_MY_AUDIO,
    EVENT_START_MY_AUDIO,
    EVENT_USER_CONNECTED,
    EVENT_SEND_MESSAGE
} from "../../core/constants";
import { Peer } from "peerjs";
import socketIOClient from "socket.io-client";
import InputGeneral from "../../components/general/InputGeneral";
import ToastCustom from "../../components/general/ToastCustom";
import ButtonIcon from "../../components/general/ButtonIcon";
import Button from "../../components/general/Button";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";

var PEERS = [//LOCAL (peers from the same room and without the user of the page itself.)
// {
    //     "idPeer": "",
    //     "idRoom": "",
    //     "name": "",
    //     "video": true,
    //     "audio": true
    // }
];
var PEERS_CALLED = [
// {
    //     "idPeer": "",
    //     "idRoom": "",
    //     "name": "",
    //     "video": true,
    //     "audio": true
    // }
];
var PEERS_STREAM = [
    // {
    //     "idPeer": "",
    //     "idRoom": "",
    //     "name": "",
    //     "video": true,
    //     "audio": true,
    //     "stream": null
    // }
];
var MY_PEER = {};
var socket;

const initialState = {
    inputMyPeerId: "",
    inputRoomId: "",
    inputNameUser: "",
    inputIdUser: "",
    inputToEnterURL: "",
    inputSendMessage: "",
    toasts: [],
    hiddenMyVideo: false,
    hiddenMyAudio: false,
    showModalMaximumStream: false,
    showMessages: false,
    cameIntoRoom: false,
    peersStream: [
        // {
        //     "idPeer": "",
        //     "idRoom": "",
        //     "name": "",
        //     "video": true,
        //     "audio": true,
        //     "stream": objStream
        // }
    ],
    allMessages: [
        // {
        //     "idPeer": "",
        //     "idMessage": 0,
        //     "idRoom": 0,
        //     "idUser": 0,
        //     "message": "Hello, world!",
        //     "name": "Maria"
        // }
    ],
    myMaximumStream: false,
}
const WIDTH_VIDEO_CONSTRAINTS = 320;
const HEIGHT_VIDEO_CONSTRAINTS = 240;
const PROPORTIONAL_CONTRATINTS = (WIDTH_VIDEO_CONSTRAINTS / HEIGHT_VIDEO_CONSTRAINTS);
const MAXIMUM_PEOPLE_ROOM = 50; //DANGER!!!
const CONSTRAINTS_RTC_VIDEO = {
    video: {
        width: {
            min: WIDTH_VIDEO_CONSTRAINTS, //https://developer.mozilla.org/en-US/docs/Web/Media/Formats/WebRTC_codecs
            max: WIDTH_VIDEO_CONSTRAINTS
        },
        height: {
            min: HEIGHT_VIDEO_CONSTRAINTS,
            max: HEIGHT_VIDEO_CONSTRAINTS
        },
        frameRate: {
            min: 5,  //https://developer.mozilla.org/en-US/docs/Web/Media/Formats/WebRTC_codecs
            max: 5
        },
        aspectRatio: { ideal: 1.7777777778 }
    }
};
const { v4: uuidV4 } = require("uuid");
const peer = new Peer();
const BASE_URL_FRONT = process.env.REACT_APP_FRONT_URL;
const DEVICE_DIMENSIONS = getWindowDimensions();
const DEVICE_HEIGTH = DEVICE_DIMENSIONS.height - 200;

class Room extends Component
{
    _mounted = false;
    state = {...initialState}
    
    constructor (props)
    {
        super(props);

        for (let i = 0; i < MAXIMUM_PEOPLE_ROOM; i++)
        {
            this["nameRefChat"+i] = React.createRef();
            this["videoRefChat"+i] = React.createRef();
        }

        this.videoMaximumRefChat = React.createRef();
        this.nameMaximumRefChat = React.createRef();
        this.messagesRoom = React.createRef();
    }

    componentDidMount = async () => 
    {
        this._mounted = true;
        document.title = `${APPLICATION_NAME} - Sala`;
        socket = socketIOClient(baseURLSocket);
        await this.createVideoStreamChat();
        await this.setModeChat();
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("CHAT prevProps ", prevProps);
        // console.log("CHAT this.props ", this.props);
        
        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded)
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}
    }

    componentWillUnmount = async () => 
	{ 
		this._mounted = false;
		console.info("ROOM demoting.");
	}

    setModeChat = async () => 
    {
        const { path, params } = this.props.match;
        let {room} = params;
        if (path.includes("room") && room !== undefined && this._mounted)
        {
            await this.setState({inputRoomId: room});
            await this.setPeerEvents();
            await this.setSocketEvents();
        }
    }

    setPeerEvents = async () =>
    {
        let userLogged = this.props.user;
        let idRoom = await this.state.inputRoomId;
        
        peer.on("open", async (id) => {

            await this.setState(
                {
                    inputMyPeerId: id,
                    inputIdUser: userLogged.PER_ID,
                    inputNameUser: userLogged.PER_NAME
                }
            );

            let user = {
                name: userLogged.PER_NAME,
                idPeer: id,
                idUser: parseInt(userLogged.PER_ID),
                idRoom,
                video: true,
                audio: true,
            };
            MY_PEER = user;
            await this.toast("success", `Seja muito bem-vindo(a) ${user.name}! Aguarde na sala até que algum usuário se conecte!`);
            socket.emit(EVENT_JOIN_ROOM, user);
            await this.setMyPeerStream();
        });

        peer.on("call", (call) => {
            navigator.mediaDevices.getUserMedia({...CONSTRAINTS_RTC_VIDEO, audio: true})
            .then((stream) => {
                call.answer(stream); // my stream when I get a call...
            })
            .catch(error => {
                console.log("Unable to get media: ", error);
                this.toast("error", "Opsssss, tivemos algum erro! Se persistir, contacte o desenvolvedor! (001)");
            });
        });
    }

    setSocketEvents = async () =>
    {
        socket.on(EVENT_USER_DISCONNECTED, async (user) => {
            this.toast("information", `O usuário ${user.name} acabou de sair!`);
            await this.removeRemoteVideo(user);
            await this.reorderingTagsRef();
        });

        socket.on(EVENT_USER_CONNECTED, async (data) => {
            PEERS = data.room.filter(p => p.idPeer !== MY_PEER.idPeer);
            console.log("New user connected... ", PEERS);
            await this.callAllPeers();
            await this.reorderingTagsRef();
        
            await this.setState({allMessages: data.roomMessages});
            this.scrollingToEnd();

            // DANGER: wait a bit to at least have an initial stream to be able to set false if you need to.
            setTimeout(async() => { 
                await this.checkStatusVideoAllPeers();
                await this.checkStatusAudioAllPeers();
            }, 3000);
        });

        socket.on(EVENT_STOP_MY_VIDEO, (user) => {
            this.stopVideo(user);
        });
        
        socket.on(EVENT_START_MY_VIDEO, (user) => {
            this.startVideo(user);
        });
        
        socket.on(EVENT_STOP_MY_AUDIO, (user) => {
            this.stopAudio(user);
        });
        
        socket.on(EVENT_START_MY_AUDIO, (user) => {
            this.startAudio(user);
        });
        
        socket.on(EVENT_SEND_MESSAGE, async (room) => {
            await this.setState({allMessages: room});
            this.scrollingToEnd();
        });
    }

    callPeer = async (user) =>
    {
        let peersCalled = PEERS_CALLED.filter(p => p.idPeer === user.idPeer);
        let userCalled = (peersCalled.length > 0) ? true : false;

        if (userCalled) { return; } //DANGER!!!

        await navigator.mediaDevices.getUserMedia({...CONSTRAINTS_RTC_VIDEO, audio: true})
        .then(async (stream) => {

            let call = await peer.call(user.idPeer, stream);

            await call.on("stream", (streamRemote) => {

                PEERS_CALLED.push(user);

                let peersStream = PEERS_STREAM.filter(p => p.idPeer === user.idPeer);
                let userStream = (peersStream.length > 0) ? true : false;

                if (!userStream)
                {
                    this.setRemotePeerStream(
                        {
                            "idPeer": user.idPeer,
                            "idRoom": MY_PEER.idRoom,
                            "name": user.name,
                            "video": true,
                            "audio": true,
                            "stream": streamRemote
                        }
                    );
                    PEERS_STREAM.push({...user, stream: streamRemote});            
                }

            });
        })
        .catch(error => {
            console.log("Unable to get media: ", error);
            this.toast("error", "Opsssss, nós precisamos da sua permissão para que possamos oferecer um serviço adequado!");
        });
    }

    callAllPeers = async () =>
    {
        for (let i = 0; i < PEERS.length; i++) 
        {
            let peer = PEERS[i];
            await this.callPeer(peer);
        }
    }

    checkStatusVideoAllPeers = async () =>
    {
        for (let i = 0; i < PEERS.length; i++) 
        {
            let peer = PEERS[i];

            if (peer.video) 
            {
                await this.startVideo(peer); 
            }
            else 
            {
                await this.stopVideo(peer); 
            }
            
        }
    }

    checkStatusAudioAllPeers = async () =>
    {
        for (let i = 0; i < PEERS.length; i++) 
        {
            let peer = PEERS[i];

            if (peer.audio) 
            {
                await this.startAudio(peer); 
            }
            else 
            {
                await this.stopAudio(peer); 
            }
            
        }
    }

    createVideoStreamChat = () =>
    {
        let elements = [];
        for (let i = 0; i < MAXIMUM_PEOPLE_ROOM; i++)
        {
            let peerStream = this.state.peersStream;
            let peer = peerStream[i];
            let isPeer = (peer) ? true : false;

            elements.push(
                <div key={i} className={`videoStreamChat ${isPeer ? "" : "hidden"}`}>
                        <span className="nameRemoteUserChat" ref={this[`nameRefChat${i}`]}></span>
                        <div className="videoRemoteUserChat"><video className={`${(i === 0 ? "mySelf" : "")}`} ref={this[`videoRefChat${i}`]} autoPlay /></div>
                        <ButtonIcon 
                            classaditional="buttonIconRoom maximum" icon="fas fa-expand-arrows-alt" 
                            onClick={async () => this.createVideoMaximumStreamChat(peer)} 
                        />
                </div>
            )            
        }

        return elements;
    }
    
    createVideoMaximumStreamChat = async (user = null) =>
    {
        if (user)
        {
            let peersStream = await this.state.peersStream;
            let myMaximumStream = false;

            peersStream.forEach(p => {
                if (p.idPeer === user.idPeer)
                {

                    myMaximumStream = (p.idPeer === MY_PEER.idPeer) ? true : false;
                    this.videoMaximumRefChat.current.srcObject = p.stream;
                    this.nameMaximumRefChat.current.innerHTML = p.name;
                }
            });

            await this.setState({showModalMaximumStream: true, myMaximumStream});
        }
    }

    setRemotePeerStream = async (user) =>
    {
        try 
        {
            //seting stream by user...
            let peersStream = await this.state.peersStream;
            peersStream.push(user);
            await this.setState({peersStream});

            //getting the user's position in the peersStream to match the videoRef index...
            peersStream = await this.state.peersStream;
            let nextPosition = peersStream.findIndex(p => p.idPeer === user.idPeer);
            this[`videoRefChat${nextPosition}`].current.srcObject = user.stream;
            this[`nameRefChat${nextPosition}`].current.innerHTML = user.name;
        }
        catch (error)
        {
            console.log("error: ", error);
            this.toast("error", "Opsssss, tivemos algum problema com o streaming remoto. Se persistir, contacte o desenvolvedor.");
        }
    }

    reorderingTagsRef = async () =>
    {
        let peersStream = await this.state.peersStream;

        for (let i = 0; i < MAXIMUM_PEOPLE_ROOM; i++)
        {
            let peer = peersStream[i];

            if (peer)
            {
                this[`videoRefChat${i}`].current.srcObject = peer.stream;
                this[`nameRefChat${i}`].current.innerHTML = peer.name;
            }
            else
            {
                this[`videoRefChat${i}`].current.srcObject = null;
                this[`nameRefChat${i}`].current.innerHTML = "";
            }

        }
    }

    removeRemoteVideo = async (user) =>
    {
        let peersStream = await this.state.peersStream;
        peersStream = peersStream.filter(p => p.idPeer !== user.idPeer);
        await this.setState({peersStream});
    }

    setMyPeerStream = async () =>
    {
        await navigator.mediaDevices.getUserMedia({...CONSTRAINTS_RTC_VIDEO, audio: false})
        .then(async (stream) => {
            let peersStream = [];
            peersStream.push({...MY_PEER, stream});
            await this.setState({peersStream, cameIntoRoom: true});

            this[`videoRefChat0`].current.srcObject = stream;
            this[`nameRefChat0`].current.innerHTML = MY_PEER.name;

        })
        .catch(error => {
            console.log("Unable to get media: ", error);
            this.toast("error", "Opsssss, nós precisamos da sua permissão para que possamos oferecer um serviço adequado!");
        });
    }

    createRoom = async () =>
    {
        let room = uuidV4();
        let currentURL = window.location.href;
        this.redirectToURL(`${currentURL}/${room}`);
    }

    exitRoom = () =>
    {
        this.redirectToURL(`${BASE_URL_FRONT}/chat`);
    }

    redirectToURL = (url) =>
    {
        window.location.href = url;
    }

    enterRoom = async () =>
    {
        let urlToEnter = await this.state.inputToEnterURL;

        if (urlToEnter.toString().trim() === "")
        {
            this.message("error", "Informe uma url válida de uma sala.");
            return;
        }

        this.redirectToURL(urlToEnter);
    }

    copyToCliboard = async () => 
    {
        let url = window.location.href;
        navigator.clipboard.writeText(url);
        this.toast("success", "URL da sala copiado com sucesso !");
    }

    stopVideo = async (user) =>
    {
        try 
        {
            let peersStream = await this.state.peersStream;

            for (let i = 0; i < peersStream.length; i++) 
            {
                let peer = peersStream[i];
                if (user.idPeer === peer.idPeer)
                {
                    peer.stream.getVideoTracks()[0].enabled = false;
                }
            }        
        } 
        catch (error) 
        {
            console.log("error (002) ", error)
        }
    }

    startVideo = async (user) =>
    {
        try 
        {
            let peersStream = await this.state.peersStream;

            for (let i = 0; i < peersStream.length; i++) 
            {
                let peer = peersStream[i];
                if (user.idPeer === peer.idPeer)
                {
                    peer.stream.getVideoTracks()[0].enabled = true;
                }
            }        
        } 
        catch (error) 
        {
            console.log("error (003) ", error)
        }
    }

    stopAudio = async (user) =>
    {
        try 
        {
            let peersStream = await this.state.peersStream;

            for (let i = 0; i < peersStream.length; i++) 
            {
                let peer = peersStream[i];
    
                if (user.idPeer === peer.idPeer && user.idPeer !== MY_PEER.idPeer)
                {
                    peer.stream.getAudioTracks()[0].enabled = false;
                }
            }        
        } 
        catch (error) 
        {
            console.log("error (004) ", error)
        }
    }
    
    startAudio = async (user) =>
    {
        try 
        {
            let peersStream = await this.state.peersStream;

            for (let i = 0; i < peersStream.length; i++) 
            {
                let peer = peersStream[i];

                if (user.idPeer === peer.idPeer && user.idPeer !== MY_PEER.idPeer)
                {
                    peer.stream.getAudioTracks()[0].enabled = true;
                }
            }        
        } 
        catch (error) 
        {
            console.log("error (003) ", error)
        }
    }

    sendMessage = async () =>
    {
        let message = await this.state.inputSendMessage;

        if (message.toString().trim() !== "")
        {
            let {name, idPeer, idRoom} = MY_PEER;
            let idMessage = parseInt(Math.random() * 9999999);
            let data = {name, idPeer, idRoom, message, idMessage, idUser: parseInt(MY_PEER.idUser)};
            socket.emit(EVENT_SEND_MESSAGE, data);
            await this.setState({inputSendMessage: ""});
        }
    }

    scrollingToEnd = () =>
    {
        let messagesRoom = this.messagesRoom;

        if (messagesRoom.current)
        {
            let height = messagesRoom.current.scrollHeight;
            messagesRoom.current.scrollTo({ top: height, behavior: 'smooth'});
        }
        
    }

    toast = async (type, message) =>
    {
        let toasts = await this.state.toasts;
        let d = new Date();
        let stamp = d.getTime();
        toasts.push({type, message, stamp });
        await this.setState({toasts});
    }

	message = (type, message) =>
	{
        this.props.setModalMessage({show: true, type, message});
	}

    render ()
    {
        return (
            <div className="room">
                <Header className="headerRoom" title="Sala"/>
                <div className={`modalMaximumStream ${this.state.showModalMaximumStream ? "show" : "hidden"} `}>
                    <h6 className="nameMaximumStream" ref={this.nameMaximumRefChat}>&nbsp;</h6>
                    <ButtonIcon 
                        classaditional="buttonIconRoom close"
                        icon="fas fa-times icon"
                        onClick={() => this.setState({showModalMaximumStream: false})} 
                    />
                    <video 
                        className={`videoMaximumStream ${this.state.myMaximumStream ? "mySelf" : ""}`}
                        ref={this.videoMaximumRefChat}
                        style={{
                            width: `${DEVICE_HEIGTH * PROPORTIONAL_CONTRATINTS}px`,
                            height: `${DEVICE_HEIGTH}px`
                        }}
                        autoPlay
                    />
                </div>
                <div className="bodyRoom">
                    <ToastCustom toasts={this.state.toasts}/>
                    <div className="areaVideosRoom" style={{"width": `${this.state.showMessages ? "70%" : "100%"}`}}>
                        <ButtonIcon 
                            classaditional="buttonIconRoom messages"
                            icon="fas fa-comment"
                            onClick={() => this.setState({showMessages: !this.state.showMessages})} 
                        />
                        {
                            !this.state.cameIntoRoom &&
                            <Button
                                classaditional="buttonRoom"
                                name="entrar"
                                onClick={() => this.redirectToURL(window.location.href)}
                            />
                        }
                        {this.createVideoStreamChat()}
                        <InputGeneral hidden onChange={() => {}} type="text" id="myPeerId" value={`${this.state.inputMyPeerId}`} />
                        <InputGeneral hidden onChange={() => {}} type="text" id="nameUser" value={`${this.state.inputNameUser}`} />
                        <InputGeneral hidden onChange={() => {}} type="text" id="idUser" value={`${this.state.inputIdUser}`} />

                    </div>
                    <div className="areaMessagesRoom" style={{"width": `${this.state.showMessages ? "30%" : "0%"}`, "display": `${this.state.showMessages ? "block" : "none"}`}}>
                        <div className="messagesRoom" ref={this.messagesRoom}>
                            {
                                this.state.allMessages.map(m => {
                                    return (
                                        <div key={m.idMessage} className={`messageItem ${(m.idUser === MY_PEER.idUser) ? "right" : "left"}`}>
                                            <span className="author">{m.name}:</span>
                                            <span className="content">{m.message}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="sendMessagesRoom">
                            <InputGeneral
                                type="text" 
                                classaditional="inputMessagesRoom" 
                                autoFocus onChange={(e) => this.setState({inputSendMessage: e.target.value})}
                                value={this.state.inputSendMessage}
                                onKeyUp={(e) => {
                                    if (e.code === "Enter") { this.sendMessage() }
                                }}
                            />
                            <ButtonIcon 
                                classaditional="buttonIconRoom"
                                icon="fas fa-paper-plane"
                                onClick={this.sendMessage} 
                            />
                        </div>
                    </div>
                </div>
                <div className="footerRoom">
                    {
                        !this.state.hiddenMyVideo &&
                        <ButtonIcon 
                            classaditional="buttonIconRoom"
                            icon="fas fa-video"
                            onClick={async () => {
                                socket.emit(EVENT_STOP_MY_VIDEO, MY_PEER);
                                await this.setState({hiddenMyVideo: true});
                            }} 
                        />
                    }
                    {
                        this.state.hiddenMyVideo &&
                        <ButtonIcon
                            classaditional="buttonIconRoom"
                            icon="fas fa-video-slash"
                            onClick={async () => {
                                socket.emit(EVENT_START_MY_VIDEO, MY_PEER);
                                await this.setState({hiddenMyVideo: false});
                            }}

                        />
                    }
                    {
                        !this.state.hiddenMyAudio &&
                        <ButtonIcon
                            classaditional="buttonIconRoom"
                            icon="fas fa-microphone"
                            onClick={async () => {
                                socket.emit(EVENT_STOP_MY_AUDIO, MY_PEER);
                                await this.setState({hiddenMyAudio: true});
                            }}
                        />
                    }
                    {
                        this.state.hiddenMyAudio &&
                        <ButtonIcon
                            classaditional="buttonIconRoom"
                            icon="fas fa-microphone-slash"
                            onClick={async () => {
                                socket.emit(EVENT_START_MY_AUDIO, MY_PEER);
                                await this.setState({hiddenMyAudio: false});
                            }}
                        />
                    }
                    <ButtonIcon
                        onClick={this.copyToCliboard} 
                        classaditional="buttonIconRoom" 
                        icon="fal fa-copy" 
                    />
                    <ButtonIcon 
                        onClick={this.exitRoom} 
                        classaditional="buttonIconRoom" 
                        icon="far fa-sign-out" />
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const {user} = state.auth;
    const permissions = state.permissions.chat;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        user,
        permissions,
        hasPermissionsLoaded,
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Room));