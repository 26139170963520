import React, { Component } from "react";
import FieldEdit from "./FieldEdit";
import { connect } from "react-redux";
import "./TableEditCommissionRules.css";
import { 
    formatedMonthAndYear, 
    formatedOnlyNumber, 
    formatedPercentageNumber, 
    formatedPercentToFloat 
} from "../../auxiliary/generalFunctions";
import { getWindowDimensions } from "../../auxiliary/generalFunctions";
import Check from "./Check";
import Select from "./Select";
import Button from "./Button";

const initialState = {

    textLabelHelp: "",
    showLabelHelp : false,
    numberIndexLabelHelp: 0,
    headerTable: null, // reference DOM element,
    x: -200, //position default
    y: -200, //position default
    
    choosenUnitCompany: {
        name: "",
        value: "",
    },
    optionsTypeTargets: [
        {
            name: "Especialista",
            value: 1,
            checked: true
        },
        {
            name: "Gerente",
            value: 2,
            checked: false
        }
    ],
    choosenTarget: {
        name: "",
        value: "",
    },
}

class TableEditCommissionRules extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let headerTable = this.headerTable;
        this.setState({ headerTable });
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("TABLE EDIT COMMISSION RULES prevProps ", prevProps);
        // console.log("TABLE EDIT COMMISSION RULES this.props ", this.props);        
    }

    setPositionsLabelHelp = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX} = e;
        let clientY = await this.state.headerTable;
        clientY = clientY.getBoundingClientRect().y;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    setPositionsDetails = async (e, text) =>
    {
        let widthWindow = getWindowDimensions().width;
        let {clientX, clientY} = e;

        if ((clientX + 100)> widthWindow)
        {
            clientX = clientX - 100;
        }

        clientX = clientX - 180;

        this.setState({x: clientX, y: clientY - 30, textLabelHelp: text})
    }

    onlyNumberOrEqualOne = (value) =>
    {
        value = formatedOnlyNumber(value.toString());
        value = value === "" ? 1 : value;
        value = value === "0" ? 1 : value;
        return value;
    }

    render ()
    {
        return (
            <div className="tableEditCommissionRules">
                <label className="helpTableEditCommissionRules" style={{top: `${this.state.y}px`, left: `${this.state.x}px`}}>
                    {this.state.textLabelHelp}
                </label>
                <table className="table">
                    <thead>
                        <tr className="theadTableEditCommissionRules" ref={headerTable => {this.headerTable = headerTable}}>
                            <td className="checkTableEditCommissionRules">
                                <Check
                                    classaditional={`buttonCheckTableEditCommissionRules ${(this.props.allCommissionRulesChecked) ? "active" : ""}`}
                                    checked={this.props.allCommissionRulesChecked}
                                    onClick={() => this.props.onToggleCheckedAllCommissionRules(!this.props.allCommissionRulesChecked)}
                                />
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Unidade")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Unidade
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Referente")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Referente
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Comissão de Aproveitamento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Com. Aprov. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Comissão de Recebimento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Com. Rec. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Qualifição de Aproveitamento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Qual. Aprov. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Qualifição de Recebimento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Qual. Rec. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Qualifição de Perda (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Qual. Per. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Bônus de Aproveitamento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Bônus Aprov. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Bônus de Recebimento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Bônus Rec. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Meta de Aproveitamento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Meta Aprov. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Meta de Recebimento (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Meta Rec. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Meta de Perda (%)")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Meta Per. (%)
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Meses de Carteira")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                M. Carteira
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Grupo que será aplicado a Regra")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                                Grupo
                            </td>
                            <td
                                onMouseOver={(e) => this.setPositionsLabelHelp(e, "Ações")}
                                onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                            >
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props?.data.map((e, i) => {

                                return (
                                    <tr key={`${i}`} className="tbodyTableEditCommissionRules">
                                        <td className="checkTableEditCommissionRules">
                                            <Check
                                                classaditional={`buttonCheckTableEditCommissionRules ${(e.checked) ? "active" : ""}`}
                                                checked={e.checked}
                                                onClick={() => this.props.onChangeValue(!e.checked, e.id, "checked")}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Unidade"
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_ID_COMPANY_UNIT", e.unit)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                optionsSelectMulti
                                                placeholder={`${e.optionsCompanyUnit.filter(o => o.value === e.unit)[0]?.name}`}
                                                permissions={this.props.permissions}
                                            >
                                                <Select
                                                    default={{
                                                        name: `${e.optionsCompanyUnit.filter(o => o.value === e.unit)[0]?.name}`,
                                                        value: e.optionsCompanyUnit.filter(o => o.value === e.unit)[0]?.value,
                                                        checked: true
                                                    }}
                                                    options={e.optionsCompanyUnit} 
                                                    onChange={async (choosenUnitCompany) => this.props.onChangeValue(choosenUnitCompany.value, e.id, "unit")}
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.month}
                                                placeholder={formatedMonthAndYear(e.month)}
                                                name="Mês de Referênca"
                                                onChange={value => this.props.onChangeValue(value, e.id, "month")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_MONTH", e.month)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                permissions={this.props.permissions}
                                                type="month"
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPercentageNumber(e.utilizationCommission)}
                                                placeholder={formatedPercentageNumber(e.utilizationCommission)}
                                                name="Comissão de Aproveitamento"
                                                onChange={value => this.props.onChangeValue(formatedPercentageNumber(value), e.id, "utilizationCommission")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_PERCENT_UTILIZATION_COMMISSION", formatedPercentToFloat(e.utilizationCommission))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPercentageNumber(e.receiptCommission)}
                                                placeholder={formatedPercentageNumber(e.receiptCommission)}
                                                name="Comissão de Recebimento"
                                                onChange={value => this.props.onChangeValue(formatedPercentageNumber(value), e.id, "receiptCommission")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_PERCENT_RECEIVING_COMMISSION", formatedPercentToFloat(e.receiptCommission))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPercentageNumber(e.utilizationGoal)}
                                                placeholder={formatedPercentageNumber(e.utilizationGoal)}
                                                name="Meta de Aproveitamento"
                                                onChange={value => this.props.onChangeValue(formatedPercentageNumber(value), e.id, "utilizationGoal")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_PERCENT_UTILIZATION_MINIMUM_GOAL", formatedPercentToFloat(e.utilizationGoal))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPercentageNumber(e.receivingGoal)}
                                                placeholder={formatedPercentageNumber(e.receivingGoal)}
                                                name="Meta de Recebimento"
                                                onChange={value => this.props.onChangeValue(formatedPercentageNumber(value), e.id, "receivingGoal")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_PERCENT_RECEIVING_MINIMUM_GOAL", formatedPercentToFloat(e.receivingGoal))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPercentageNumber(e.lossGoal)}
                                                placeholder={formatedPercentageNumber(e.lossGoal)}
                                                name="Meta de Perda"
                                                onChange={value => this.props.onChangeValue(formatedPercentageNumber(value), e.id, "lossGoal")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_PERCENT_LOSS_MINIMUM_GOAL", formatedPercentToFloat(e.lossGoal))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPercentageNumber(e.performanceBonus)}
                                                placeholder={formatedPercentageNumber(e.performanceBonus)}
                                                name="Bônus de Aproveitamento"
                                                onChange={value => this.props.onChangeValue(formatedPercentageNumber(value), e.id, "performanceBonus")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_PERCENT_UTILIZATION_BONUS", formatedPercentToFloat(e.performanceBonus))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPercentageNumber(e.receivingBonus)}
                                                placeholder={formatedPercentageNumber(e.receivingBonus)}
                                                name="Bônus de Recebimento"
                                                onChange={value => this.props.onChangeValue(formatedPercentageNumber(value), e.id, "receivingBonus")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_PERCENT_RECEIVING_BONUS", formatedPercentToFloat(e.receivingBonus))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPercentageNumber(e.usageCeiling)}
                                                placeholder={formatedPercentageNumber(e.usageCeiling)}
                                                name="Teto de Aproveitamento"
                                                onChange={value => this.props.onChangeValue(formatedPercentageNumber(value), e.id, "usageCeiling")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_PERCENT_UTILIZATION_MAXIMUM_GOAL", formatedPercentToFloat(e.usageCeiling))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPercentageNumber(e.receiptCeiling)}
                                                placeholder={formatedPercentageNumber(e.receiptCeiling)}
                                                name="Teto de Recebimento"
                                                onChange={value => this.props.onChangeValue(formatedPercentageNumber(value), e.id, "receiptCeiling")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_PERCENT_RECEIVING_MAXIMUM_GOAL", formatedPercentToFloat(e.receiptCeiling))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={formatedPercentageNumber(e.lossCeiling)}
                                                placeholder={formatedPercentageNumber(e.lossCeiling)}
                                                name="Teto de Perda"
                                                onChange={value => this.props.onChangeValue(formatedPercentageNumber(value), e.id, "lossCeiling")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_PERCENT_LOSS_MAXIMUM_GOAL", formatedPercentToFloat(e.lossCeiling))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={5}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                value={e.monthsPortfolio}
                                                placeholder={e.monthsPortfolio}
                                                name="Meses de Carteira"
                                                onChange={value => this.props.onChangeValue(formatedOnlyNumber(value.toString()), e.id, "monthsPortfolio")}
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_TOTAL_MONTHS_OF_PORTFOLIO", this.onlyNumberOrEqualOne(e.monthsPortfolio))}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                maxLength={2}
                                                permissions={this.props.permissions}
                                            />
                                        </td>
                                        <td>
                                            <FieldEdit
                                                classaditional={
                                                    `editTableEditCommissionRules 
                                                    ${!this.props.permissions.toUpdate && "noEdit"}` 
                                                }
                                                name="Grupo"
                                                onPositiveClick={() => this.props.onSaveValue(e.id, "COR_RULE_TARGET", e.target)}
                                                onNegativeClick={() => this.props.onIgnoreSave()}
                                                optionsSelectMulti
                                                placeholder={`${e.optionsPermissionsGroup.filter(o => o.value === e.target)[0]?.name}`}
                                                permissions={this.props.permissions}
                                            >
                                                <Select
                                                    default={{
                                                        name: `${e.optionsPermissionsGroup.filter(o => o.value === e.target)[0]?.name}`,
                                                        value: e.optionsPermissionsGroup.filter(o => o.value === e.target)[0]?.value,
                                                        checked: true
                                                    }}
                                                    options={e.optionsPermissionsGroup} 
                                                    onChange={async (choosenUnitCompany) => this.props.onChangeValue(choosenUnitCompany.value, e.id, "target")}
                                                />
                                            </FieldEdit>
                                        </td>
                                        <td>
                                            {
                                                e.status === 3 && this.props.permissions.toInsert &&
                                                <div className="detailsTableEditCommissionRules">
                                                    <Button
                                                        classaditional="buttonShowDetailsTableEditCommissionRules success"
                                                        icon="fas fa-tools"
                                                        onClick={() => this.props.onChangeValue(1, e.id, "status")}
                                                        onMouseOver={(event) => this.setPositionsDetails(event, "Gerar relatótio de comissão dessa unidade?")}
                                                        onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                                                    />
                                                </div>                                            
                                            }
                                            {
                                                e.status === 2 && this.props.permissions.toView &&
                                                <div className="detailsTableEditCommissionRules">
                                                    <Button
                                                        classaditional="buttonShowDetailsTableEditCommissionRules warning"
                                                        icon="fas fa-spinner"
                                                        onClick={() => {}}
                                                        onMouseOver={(event) => this.setPositionsDetails(event, "Relatório de comissão em andamento...")}
                                                        onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                                                    />
                                                </div>
                                            }
                                            {
                                                e.status === 1 && this.props.permissions.toDelete &&
                                                <div className="detailsTableEditCommissionRules">
                                                    <Button
                                                        classaditional="buttonShowDetailsTableEditCommissionRules error"
                                                        icon="fas fa-trash"
                                                        onClick={() => this.props.onChangeValue(3, e.id, "status")}
                                                        onMouseOver={(event) => this.setPositionsDetails(event, "Relatório de comissão gerado! Deseja excluí-lo?")}
                                                        onMouseLeave={() => this.setState({showLabelHelp: false, x: -200, y: -200})}
                                                    />
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default connect(null, null)(TableEditCommissionRules);