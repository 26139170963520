import React, { Component } from "react"; 
import "./Permissions.css";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import { setEditPermissionGroup } from "../../store/actions/permissions";
import { APPLICATION_NAME, permissionGroup } from "../../core/constants";

import Header from "../../components/CRM/Header";
import Message from "../../components/general/Message";
import Box from "../../components/general/Box";
import Button from "../../components/general/Button";
import ButtonIcon from "../../components/general/ButtonIcon";

import PermissionGroupController from "../../controllers/PermissionsGroupController";

const initialState = {

    allPermissionsGroup: [
        // {
        //     id: 1,
        //     name: "Prospecção",
        //     value: "Prospecção",
        //     checked: true
        // },
        // {
        //     id: 2,
        //     name: "Cobrança",
        //     value: "Cobrança",
        //     checked: false
        // },
        // {
        //     id: 3,
        //     name: "Sup. de Prospeção",
        //     value: "Sup. de Prospeção",
        //     checked: false
        // },
        // {
        //     id: 4,
        //     name: "Gestor",
        //     value: "Gestor",
        //     checked: false
        // },
    ],

    // start message modal
    message_type: "information",
    message_show: false,
    message: ""
    // ends message modal
}

const permissionsGroupController = new PermissionGroupController();

class Permissions extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {   
        document.title = `${APPLICATION_NAME} - Grupos de Permissão`;
        this.listPermissionsGroup();
    }
    
    componentDidUpdate = async (prevProps) =>
    {
        // console.log("PERMISSIONS prevProps ", prevProps);
        // console.log("PERMISSIONS this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    listPermissionsGroup = async () => {
        const result = await permissionsGroupController.get();
        if (result.status) {
            let newPermissionsGroup = [];
            for (const permission of result.data.data) {
                newPermissionsGroup.push({
                    id: permission.PEG_ID,
                    name: permission.PEG_NAME,
                    value: permission.PEG_NAME,
                    checked: false,
                    status: permission.PEG_STATUS
                });
            }
            this.setState({ allPermissionsGroup: newPermissionsGroup });
        }
    }

    render ()
    {
        return (
            <div className="permissions">
                <Header title="Grupos de Permissão" classaditional="headerNavigationPermissions"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="scrollPermissions mb-4">
                    <div className="container headerPermissions">
                        <Box>
                            <h4>Grupos de Permissão</h4>
                        </Box>
                    </div>
                    <div className="container bodyPermissions">
                        <Box classaditional="boxBodyPermissions" >
                            <div className="row no-gutters lineChosenPermissionGroupPermissions">
                                <div className="col-12 columnChosenPermissionsGroupPermissions">
                                    {
                                        this.props.permissions.toInsert &&
                                            <Button
                                                icon="fal fa-plus"
                                                name="&nbsp;&nbsp;Grupo"
                                                classaditional="buttonPlusPermissionsGroupPermisions"
                                                onClick={() => {
                                                    this.props.setEditPermissionGroup(null);
                                                    this.props.showModalMoreActions({show: true, type: permissionGroup});
                                                }}
                                            />
                                    }
                                </div>
                            </div>
                            <div className="row no-gutters lineTitleHeaderConfigPermissions">
                                <div className="col-4 columnTitleHeaderConfigPermissions">
                                    <span>Grupo</span>
                                </div>
                                <div className="col-4 columnTitleHeaderConfigPermissions">
                                    <span>Status</span>
                                </div>
                                <div className="col-4 columnTitleHeaderConfigPermissions">
                                    <span></span>
                                </div>
                            </div>
                            {
                                this.state.allPermissionsGroup.map((g, i) => {
                                    return (
                                        <div key={`${i}`}>
                                            <div className={`row no-gutters lineBodyConfigPermissions ${i} ${i % 2 === 0 ? "pair" : ""} ${(i === (this.state.allPermissionsGroup.length - 1 ))? "final" : ""}  `}>
                                                <div className="col-4 columnBodyConfigPermissions justify-content-start">
                                                    <span>{g.name}</span>
                                                </div>
                                                <div className="col-4 columnBodyConfigPermissions">
                                                    <span>{ g.status === 1 ? "ATIVO" : "INATIVO" }</span>
                                                </div>
                                                <div className="col-4 columnBodyConfigPermissions d-flex justify-content-end">
                                                    <ButtonIcon
                                                        icon="fas fa-list-ul"
                                                        classaditional="buttonBodyConfiPermissions"
                                                        onClick={() => {
                                                            this.props.history.push(`/permissions/details/${g.id}`)
                                                        }}
                                                    />
                                                    {
                                                        this.props.permissions.toUpdate &&
                                                            <ButtonIcon
                                                                icon="fas fa-pencil"
                                                                classaditional="buttonBodyConfiPermissions"
                                                                onClick={() => {
                                                                    this.props.setEditPermissionGroup(g.id);
                                                                    this.props.showModalMoreActions({show: true, type: permissionGroup, modeUpdateModal: true});
                                                                }}
                                                            />
                                                    }
                                                    {
                                                        this.props.permissions.toDelete &&
                                                            <ButtonIcon
                                                                icon="fas fa-trash"
                                                                classaditional="buttonBodyConfiPermissions"
                                                                onClick={() => this.message("information", "nothing...")}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const permissions = state.permissions.permissionGroups;
    const { user } = state.auth;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        hasPermissionsLoaded,
        permissions,
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        setEditPermissionGroup (data)
        {
            //action creator -> action
            const action = setEditPermissionGroup(data);
            dispatch(action);
        },
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Permissions));