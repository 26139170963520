import API from "../api";
import { apiCallbackTreatment, identifiesBrowser, isEmail } from "../auxiliary/generalFunctions";

var api = new API();

export default class AuthController
{
    async login(data) 
    {

        if (!isEmail(data.email)) 
        {
            return {
                status: false,
                message: "Informe um e-mail válido!"
            };
        }

        if (data.password.trim() === "") 
        {
            return {
                status: false,
                message: "Informe a senha!"
            };
        }

        let browser = identifiesBrowser();

        const response = await api.user().login(
            {
                PER_MAIL: data.email,
                PER_PASSWORD: data.password,
                BROWSER_INFO: `${browser.name} - ${navigator?.userAgentData?.platform || "[Não identificado]"}`
            }
        );

        return apiCallbackTreatment(response);
    }

    async forgotPassword(data)
    {
        const response = await api.user().forgotPassword(data);

        return apiCallbackTreatment(response);
    }

    async isChangedPasswordFromDate(userId, date)
    {
        const response = await api.user().isChangedPasswordFromDate({
            LCP_ID_PERSON: userId,
            LCP_CHANGED_DATE: date
        });

        return apiCallbackTreatment(response);
    }

    async forceUpdatePassword(id, newPassword)
    {
        const response = await api.user().updatePassword(
            {
                PER_ID: id,
                NEW_PASSWORD: newPassword,
                FORCE_UPDATE: 1
            }
        );

        return apiCallbackTreatment(response);
    }

    async updatePassword(data) 
    {
        if (data.oldPassword.trim() === "") 
        {
            return {
                status: false,
                message: "Informe a senha antiga!"
            };
        }

        if (data.newPassword.trim() === "") 
        {
            return {
                status: false,
                message: "Informe a nova senha!"
            };
        }

        if (data.newPasswordConfirm.trim() === "") 
        {
            return {
                status: false,
                message: "Informe a confirmação da nova senha!"
            };
        }

        if (data.newPassword !== data.newPasswordConfirm)
        {
            return {
                status: false,
                message: "SENHA NOVA e SENHA NOVA CONFIRMA não conferem."
            };
        }

        const response = await api.user().updatePassword(
            {
                PER_ID: data.perID,
                OLD_PASSWORD: data.oldPassword,
                NEW_PASSWORD: data.newPassword,
                FORCE_UPDATE: 2
            }
        );

        return apiCallbackTreatment(response);
    }
}