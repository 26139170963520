import React, { Component } from "react";
import "./Chat.css";
import Header from "../../components/CRM/Header";
import { setModalMessage } from "../../store/actions/general";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { APPLICATION_NAME } from "../../core/constants";
import ToastCustom from "../../components/general/ToastCustom";
import Box from "../../components/general/Box";
import Button from "../../components/general/Button";

const { v4: uuidV4 } = require("uuid");
const BASE_URL_FRONT = process.env.REACT_APP_FRONT_URL;
const initialState = {
    inputMyPeerId: "",
    inputRoomId: "",
    inputNameUser: "",
    inputIdUser: "",
    inputToEnterURL: "",
    toasts: [],
}
class Chat extends Component
{
    state = {...initialState}
    
    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Chat`;
    }

    componentDidUpdate = async (prevProps, prevState) => 
    {
        // console.log("CHAT prevProps ", prevProps);
        // console.log("CHAT this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.toView) { this.props.history.push(`/activities`); }
		}
    }

    createRoom = async () =>
    {
        let room = uuidV4();
        this.redirectToURL(`${BASE_URL_FRONT}/room/${room}`);
    }

    redirectToURL = (url) =>
    {
        window.location.href = url;
    }

    enterRoom = async () =>
    {
        let urlToEnter = await this.state.inputToEnterURL;

        if (urlToEnter.toString().trim() === "")
        {
            this.toast("error", "Informe uma URL válida de uma sala.");
            return;
        }

        this.redirectToURL(urlToEnter);
    }

    toast = async (type, message) =>
    {
        let toasts = await this.state.toasts;
        let d = new Date();
        let stamp = d.getTime();
        toasts.push({type, message, stamp });
        await this.setState({toasts});
    }

    render ()
    {
        return (
            <div className="chat">
                <Header className="headerChat" title="Chat"/>
                <div className="bodyChat">
                    <ToastCustom toasts={this.state.toasts}/>
                    <Box classaditional="boxChat">
                        <i className="fas fa-comments-alt brandChat"></i>
                        <Button
                            classaditional="buttonChat"
                            name="criar sala"
                            onClick={this.createRoom}
                        />
                        {/* <span className="labelChat">Entrar:</span>
                        <InputGeneral
                            classaditional="inputChat"
                            placeholder="url da sala"
                            onChange={(e) => this.setState({inputToEnterURL: e.target.value})}
                            onKeyUp={(e) => {if (e.code === "Enter") { this.enterRoom(); }}}
                        />
                        <Button
                            classaditional="buttonChat"
                            name="entrar"
                            onClick={this.enterRoom}
                        /> */}
                    </Box>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const {user} = state.auth;
    const permissions = state.permissions.chat;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        user,
        permissions,
        hasPermissionsLoaded,
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat));