import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class NetworkAddressWhiteList
{
    async post(data)
    {
        const response = await api.networkAddressWhiteList().post(data);
        return apiCallbackTreatment(response);
    }
}