import React, { Component } from "react";
import "./Integrations.css";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Header from "../../components/CRM/Header";
import Box from "../../components/general/Box";
import FacebookLogin from "../../components/CRM/FacebookLogin";

import { APPLICATION_NAME } from "../../core/constants";
import { setModalMessage } from "../../store/actions/general";

const initialState = {

}

class Integrations extends Component
{
    state = {...initialState}
    
    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Integrações`;
    }
    
    componentDidUpdate = async (prevProps) =>
    {
        // console.log("INTEGRATIONS prevState ", prevState);
        // console.log("INTEGRATIONS this.state ", this.props);
    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }

    render ()
    {
        return (
            <div className="integrations">
                <Header title="Integrações" classaditional="headerNavigationIntegrations"/>
                <div className="scrollIntegrations mb-4">
                    <div className="warningIntegrations d-flex d-md-none">
                        <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                    </div>
                    <div className="container headerIntegrations">
                        <Box>
                            <h4>Integrações</h4>
                        </Box>
                    </div>
                    <div className="container bodyIntegrations d-none d-md-block">
                        <Box>
                            <FacebookLogin />
                        </Box>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    return { }
}

function mapDispatchToProps (dispatch)
{
    return {
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Integrations));