import React, { Fragment, useEffect, useRef, useState } from "react";
import "./MessageDocumentWhatsApp.css";
import { AUDIO, DOCUMENT, IMAGE } from "../../core/constants";

export default function MessageDocumentWhatsApp (props)
{
    const buttonActionsMessagesRef = useRef();
    const [time, setTime] = useState("");
    const [noMessage, setNoMessage] = useState(false);

    useEffect(() => {
        let datetime = props.datetime;
        let parts = datetime.split(" ");
        let timeFull = parts[1];
        let partsTimeFull = timeFull.split(":");
        let time = `${partsTimeFull[0]}:${partsTimeFull[1]}`;

        setNoMessage((props.message.trim() === ""));
        setTime(time);

    }, [props]);

    let extension = props.file.extension;
    let iconExtension = (extension !== "PDF" && extension !== "DOC" && extension !== "DOCX" && extension !== "XLS") ?  "GENERAL" : extension;

    return (
        <div className={`messageWhatsApp messageDocumentWhatsApp ${(props.sentForMe) ? "right" : "left"}`} data-id={props.id}>
            <div className={`areaMessageDocumentWhatsApp ${props.initial === true && "initial"} ${props.emphasis === true && "emphasis"}`}>
                {
                    !props.sentForMe &&
                    <svg viewBox="0 0 8 13" height="13" width="8" preserveAspectRatio="xMidYMid meet" className={`iconStartMessageDocumentWhatsApp ${props.initial === true && "initial"} left ${props.emphasis === true && "emphasis"}`} version="1.1" x="0px" y="0px" enableBackground="new 0 0 8 13" xmlSpace="preserve"><path opacity="0.13" fill="#0000000" d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z"></path><path d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z" ></path></svg>
                }
                {
                    props.sentForMe &&
                    <svg viewBox="0 0 8 13" height="13" width="8" preserveAspectRatio="xMidYMid meet" className={`iconStartMessageDocumentWhatsApp ${props.initial === true && "initial"} right ${props.emphasis === true && "emphasis"}`} version="1.1" x="0px" y="0px" enableBackground="new 0 0 8 13" xmlSpace="preserve"><path opacity="0.13" d="M5.188,1H0v11.193l6.467-8.625 C7.526,2.156,6.958,1,5.188,1z"></path><path d="M5.188,0H0v11.193l6.467-8.625C7.526,1.156,6.958,0,5.188,0z"></path></svg>
                }
                <div className={`innerMessageDocumentWhatsApp ${noMessage && "noMessage"}`}>
                    <div className="innerTextMessageDocumentWhatsApp">
                        {
                            props.answer && 
                            // !props.isDeleted &&
                            <div
                                className={`innerAnswerTextMessageDocumentWhatsApp ${props.sentForMe ? "right" : "left"} ${props.emphasis && "emphasis"}`}
                                onClick={() => props.scrollToAnswerMessage(props.answer.id)}
                            >
                                <div className="shadow"></div>
                                <div className="detailsAnswerMessageDocumentWhatsApp">
                                    <span className="nameAnswerMessageDocumentWhatsApp">{props.answer.name}</span>
                                    <span className="textAnswerMessageDocumentWhatsApp">
                                        {props.answer.type === AUDIO && (<svg viewBox="0 0 12 20" height="20" width="12" preserveAspectRatio="xMidYMid meet" className="iconTypeAnswerMessageDocumentWhatsApp" version="1.1" x="0px" y="0px" enableBackground="new 0 0 12 20" xmlSpace="preserve"><path className="iconTypeAnswerMessageDocumentWhatsApp" d="M6,11.745c1.105,0,2-0.896,2-2V4.941c0-1.105-0.896-2-2-2s-2,0.896-2,2v4.803 C4,10.849,4.895,11.745,6,11.745z M9.495,9.744c0,1.927-1.568,3.495-3.495,3.495s-3.495-1.568-3.495-3.495H1.11 c0,2.458,1.828,4.477,4.192,4.819v2.495h1.395v-2.495c2.364-0.342,4.193-2.362,4.193-4.82H9.495V9.744z"></path></svg>)}
                                        {props.answer.type === IMAGE && (<svg viewBox="0 0 16 20" height="20" width="16" preserveAspectRatio="xMidYMid meet" className="iconTypeAnswerMessageDocumentWhatsApp" version="1.1" x="0px" y="0px" enableBackground="new 0 0 16 20" xmlSpace="preserve"><path className="iconTypeAnswerMessageDocumentWhatsApp" d="M13.822,4.668H7.14l-1.068-1.09C5.922,3.425,5.624,3.3,5.409,3.3H3.531 c-0.214,0-0.51,0.128-0.656,0.285L1.276,5.296C1.13,5.453,1.01,5.756,1.01,5.971v1.06c0,0.001-0.001,0.002-0.001,0.003v6.983 c0,0.646,0.524,1.17,1.17,1.17h11.643c0.646,0,1.17-0.524,1.17-1.17v-8.18C14.992,5.191,14.468,4.668,13.822,4.668z M7.84,13.298 c-1.875,0-3.395-1.52-3.395-3.396c0-1.875,1.52-3.395,3.395-3.395s3.396,1.52,3.396,3.395C11.236,11.778,9.716,13.298,7.84,13.298z  M7.84,7.511c-1.321,0-2.392,1.071-2.392,2.392s1.071,2.392,2.392,2.392s2.392-1.071,2.392-2.392S9.161,7.511,7.84,7.511z"></path></svg>)}
                                        {props.answer.type === DOCUMENT && (<svg viewBox="0 0 13 20" height="20" width="13" preserveAspectRatio="xMidYMid meet" className="iconTypeAnswerMessageDocumentWhatsApp" version="1.1" x="0px" y="0px" enableBackground="new 0 0 13 20" xmlSpace="preserve"><path className="iconTypeAnswerMessageDocumentWhatsApp" d="M10.2,3H2.5C1.7,3,1,3.7,1,4.5v10.1C1,15.3,1.7,16,2.5,16h7.7c0.8,0,1.5-0.7,1.5-1.5v-10 C11.6,3.7,11,3,10.2,3z M7.6,12.7H3.5v-1.3h4.1V12.7z M9.3,10H3.5V8.7h5.8V10z M9.3,7.3H3.5V6h5.8V7.3z"></path></svg>)}
                                        {props.answer.message}
                                    </span>
                                </div>
                                {
                                    props.answer.type === IMAGE &&
                                    <div className="innerImageAnswerMessageDocumentWhatsApp">
                                        <div className="imageInnerImageAnswerMessageDocumentWhatsApp" style={{backgroundImage: `url(${props.answer.path})`}}/>
                                    </div>
                                }
                            </div>
                        }
                        {/* {
                            props.isDeleted &&
                            <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" className="iconDeletedMessageDocumentWhatsApp"><path fillRule="evenodd" clipRule="evenodd" d="M7.75897 6.43054C8.93584 5.533 10.4057 5 12 5C15.866 5 19 8.13401 19 12C19 13.5943 18.467 15.0642 17.5695 16.241L7.75897 6.43054ZM6.35707 7.85707C5.50399 9.01706 5 10.4497 5 12C5 15.866 8.13401 19 12 19C13.5503 19 14.9829 18.496 16.1429 17.6429L6.35707 7.85707ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"></path></svg>
                        } */}
                        {
                            // !props.isDeleted &&
                            <div
                                className={`
                                    innerFileMessageDocumentWhatsApp 
                                    ${noMessage && "noMessage"} 
                                    ${props.sentForMe ? "right" : "left"} 
                                    ${props.emphasis && "emphasis"}
                                `}
                            >
                                <div className="areaIconFileMessageDocumentWhatsApp">
                                    <div className={`iconFileMessageDocumentWhatsApp ${iconExtension.toString().toLowerCase()}`}></div>
                                </div>
                                <div className="detailsFileMessageDocumentWhatsApp">
                                    <div className="areaNameFileMessageDocumentWhatsApp">
                                        <span className="nameFileMessageDocumentWhatsApp">{props.file.name}</span>
                                    </div>
                                    <div className="othersDetailsFileMessageDocumentWhatsApp">
                                        {/* reserved */}
                                        {/* {extension === "PDF" && <span className="infoOthersDetailsFileMessageDocumentWhatsApp">{props.file.pages} página{props.file.pages > 1 ? "s" : ""}</span>} */}
                                        {/* {extension === "PDF" && <span className="pointOthersDetailsFileMessageDocumentWhatsApp">•</span>} */}
                                        <span className="infoOthersDetailsFileMessageDocumentWhatsApp">{props.file.extension}</span>
                                        {props.file.extension !== "" && <span className="pointOthersDetailsFileMessageDocumentWhatsApp">•</span>}
                                        <span className="infoOthersDetailsFileMessageDocumentWhatsApp">{props.file.size}</span>
                                    </div>
                                </div>
                                <button 
                                    className="buttonDefaultMessageDocumentWhatsApp buttonDownloadFileMessageDocumentWhatsApp"
                                    onClick={() => props.onDownload(props)}
                                >
                                    <svg viewBox="0 0 34 34" height="34" width="34" preserveAspectRatio="xMidYMid meet" className="iconButtonDownloadFileMessageDocumentWhatsApp" version="1.1" x="0px" y="0px" enableBackground="new 0 0 34 34" xmlSpace="preserve"><path className="iconButtonDownloadFileMessageDocumentWhatsApp" d="M17,2c8.3,0,15,6.7,15,15s-6.7,15-15,15S2,25.3,2,17S8.7,2,17,2 M17,1C8.2,1,1,8.2,1,17 s7.2,16,16,16s16-7.2,16-16S25.8,1,17,1L17,1z"></path><path className="iconButtonDownloadFileMessageDocumentWhatsApp" d="M22.4,17.5h-3.2v-6.8c0-0.4-0.3-0.7-0.7-0.7h-3.2c-0.4,0-0.7,0.3-0.7,0.7v6.8h-3.2 c-0.6,0-0.8,0.4-0.4,0.8l5,5.3c0.5,0.7,1,0.5,1.5,0l5-5.3C23.2,17.8,23,17.5,22.4,17.5z"></path></svg>
                                </button>
                            </div>
                        }
                        {
                            props.message !== "" &&
                            <Fragment>
                                <span className={`textMessageDocumentWhatsApp ${props.isDeleted === true && "deleted"}`}>{props.message}</span>
                                <span className="textSpaceMessageDocumentWhatsApp"></span>
                            </Fragment>
                        }
                    </div>
                    {
                        !props.isDeleted &&
                        <div
                            className={`
                                moreActionsMessageDocumentWhatsApp 
                                ${props.answer ? "answer" : "noAnswer"} 
                                ${props.sentForMe ? "right" : "left"}
                            `}
                        >
                            <button
                                className={`buttonDefaultMessageDocumentWhatsApp buttonDropMoreActionsMessageDocumentWhatsApp ${props.sentForMe === true ? "right" : "left"} ${props.answer ? "answer" : "noAnswer"}`}
                                onClick={(e) => props.openDropdownActionsMessages(
                                    {
                                        ...props,
                                        top: buttonActionsMessagesRef.current.getBoundingClientRect().y,
                                        left: buttonActionsMessagesRef.current.getBoundingClientRect().x
                                    },
                                    e
                                    )
                                }
                                ref={buttonActionsMessagesRef}
                            >
                                <svg viewBox="0 0 18 18" height="18" width="18" preserveAspectRatio="xMidYMid meet" className={`iconButtonDropMoreActionsMessageWhatsApp`} version="1.1" x="0px" y="0px" enableBackground="new 0 0 18 18" xmlSpace="preserve"><path className={`iconButtonDropMoreActionsMessageWhatsApp`} d="M3.3,4.6L9,10.3l5.7-5.7l1.6,1.6L9,13.4L1.7,6.2L3.3,4.6z"></path></svg>
                            </button>
                        </div>
                    }
                    <div className={`detailsTimeAndStatusMessageDocumentWhatsApp ${noMessage && "noMessage"}`}>
                        <div className="innerDetailsTimeAndStatusMessageDocumentWhatsApp">
                            <span className="timeMessageDocumentWhatsApp">{time}</span>
                            {
                                props.sentForMe && !props.isDeleted && props.status === 3 &&
                                <svg viewBox="0 0 16 11" height="11" width="16" preserveAspectRatio="xMidYMid meet" className="iconStatusMessageDocumentWhatsApp read"><path d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z" ></path></svg>
                            }
                            {
                                props.sentForMe && !props.isDeleted && props.status === 2 &&
                                <svg viewBox="0 0 16 11" height="11" width="16" preserveAspectRatio="xMidYMid meet" className="iconStatusMessageDocumentWhatsApp delivered"><path d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z"></path></svg>
                            }
                            {
                                props.sentForMe && !props.isDeleted && props.status === 1 &&
                                <svg viewBox="0 0 12 11" height="11" width="12" preserveAspectRatio="xMidYMid meet" className="iconStatusMessageDocumentWhatsApp sent"><path d="M11.1549 0.652832C11.0745 0.585124 10.9729 0.55127 10.8502 0.55127C10.7021 0.55127 10.5751 0.610514 10.4693 0.729004L4.28038 8.36523L1.87461 6.09277C1.8323 6.04622 1.78151 6.01025 1.72227 5.98486C1.66303 5.95947 1.60166 5.94678 1.53819 5.94678C1.407 5.94678 1.29275 5.99544 1.19541 6.09277L0.884379 6.40381C0.79128 6.49268 0.744731 6.60482 0.744731 6.74023C0.744731 6.87565 0.79128 6.98991 0.884379 7.08301L3.88047 10.0791C4.02859 10.2145 4.19574 10.2822 4.38194 10.2822C4.48773 10.2822 4.58929 10.259 4.68663 10.2124C4.78396 10.1659 4.86436 10.1003 4.92784 10.0156L11.5738 1.59863C11.6458 1.5013 11.6817 1.40186 11.6817 1.30029C11.6817 1.14372 11.6183 1.01888 11.4913 0.925781L11.1549 0.652832Z"></path></svg>
                            }
                            {
                                props.isDeleted &&
                                <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" className={`iconStatusMessageDocumentWhatsApp deleted`}><path fillRule="evenodd" clipRule="evenodd" d="M7.75897 6.43054C8.93584 5.533 10.4057 5 12 5C15.866 5 19 8.13401 19 12C19 13.5943 18.467 15.0642 17.5695 16.241L7.75897 6.43054ZM6.35707 7.85707C5.50399 9.01706 5 10.4497 5 12C5 15.866 8.13401 19 12 19C13.5503 19 14.9829 18.496 16.1429 17.6429L6.35707 7.85707ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"></path></svg>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}