import { apiERP } from "../../src/configuration";

export default class API_ERP {

    client() {
        async function post(data) 
        {
            const result = await apiERP.post("/client", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await apiERP.put(`/client/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            post,
            update
        }
    }

    services() {
        async function getById(id) 
        {
            const result = await apiERP.get(`/services/${id}`)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getOptions() 
        {
            const result = await apiERP.get(`/options_from_services`)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getCitiesFromState(state) 
        {
            const result = await apiERP.get(`/cities_from_state?STATE_ID=${state}`)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getWitnesses(officeId, serviceId) 
        {
            const result = await apiERP.get(`/witnesses?officeId=${officeId}&serviceId=${serviceId}`)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getProcurators(clientId, guarantorId) 
        {
            const result = await apiERP.get(`/services/get_procurators/?clientId=${clientId}&guarantorId=${guarantorId}`)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getSuccessStories(data) 
        {
            const result = await apiERP.post(`/success_stories`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function queryEnvelope(data) 
        {
            const result = await apiERP.post(`/envelope/query`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function totalSignedEnvelope(data) 
        {
            const result = await apiERP.post(`/envelope/totalSigned`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function detailsEnvelope(data) 
        {
            const result = await apiERP.post(`/envelope/details`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function statusSignatures(data) 
        {
            const result = await apiERP.post(`/envelope/status_signatures`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function createInstallments(idService, data) 
        {
            const result = await apiERP.post(`/debts/${idService}`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }
        
        async function deleteInstallments(idService) 
        {
            const result = await apiERP.delete(`/debts/${idService}`)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function hasInstallments(idService) 
        {
            const result = await apiERP.get(`/debts/${idService}`)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function queryValidationCode(data) 
        {
            const result = await apiERP.post(`/validationCode/query`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function verifyTokenValidationCode(data) 
        {
            const result = await apiERP.put(`/validationCode`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function releaseContractWithoutWhatsapp(data) 
        {
            const result = await apiERP.put(`/validationCode/release_contract_without_whatsapp`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function queryPhoneWhatsapp(data) 
        {
            const result = await apiERP.post(`/validationCode/query_phone_whatsapp`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function createValidationCode(data) 
        {
            const result = await apiERP.post(`/validationCode`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function createEnvelope(data) 
        {
            const result = await apiERP.post(`/envelope/create`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }
        
        async function deleteEnvelope(data) 
        {
            const result = await apiERP.post(`/envelope/delete`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function sendSignEnvelope(data) 
        {
            const result = await apiERP.post(`/envelope/send`, data)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getNeighborhoodFromCity(city) 
        {
            const result = await apiERP.get(`/neighborhood_from_city?CITY_ID=${city}`)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getAddressOptionsFromText(stateAbbreviation, cityName, neighborhoodName) 
        {
            const result = await apiERP.get(`/address_options_from_text?UF=${stateAbbreviation}&CITY_NAME=${cityName}&NEIGHBORHOOD_NAME=${neighborhoodName}`)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getClientByNationalRegistration(nationalRegistration) 
        {
            const result = await apiERP.get(`/client_by_national_registration?NATIONAL_REGISTRATION=${nationalRegistration}`)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getClientById(id) 
        {
            const result = await apiERP.get(`/client/${id}`)
                .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await apiERP.post("/services", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await apiERP.put(`/services/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            getById,
            getOptions,
            getCitiesFromState,
            getNeighborhoodFromCity,
            getAddressOptionsFromText,
            getClientByNationalRegistration,
            getClientById,
            getWitnesses,
            getProcurators,
            getSuccessStories,
            queryEnvelope,
            createEnvelope,
            totalSignedEnvelope,
            detailsEnvelope,
            statusSignatures,
            createInstallments,
            deleteInstallments,
            hasInstallments,
            queryValidationCode,
            createValidationCode,
            verifyTokenValidationCode,
            queryPhoneWhatsapp,
            releaseContractWithoutWhatsapp,
            deleteEnvelope,
            sendSignEnvelope,
            post,
            update
        }
    }

}