import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class CommissionReportController
{
    async generate(data) 
    {
        const response = await api.commissionReport().post(data);
        return apiCallbackTreatment(response);
    }

    async get()
    {
        const response = await api.commissionReport().get();
        return apiCallbackTreatment(response);
    }

    async getByCompanyUnit(idUnitCompany) 
    {
        const response = await api.commissionReport().getByCompanyUnit(idUnitCompany);
        return apiCallbackTreatment(response);
    }

    async getByMonth(month) 
    {
        // MONTH FORMAT: yyyy-mm
        const response = await api.commissionReport().getByMonth(month);
        return apiCallbackTreatment(response);
    }

    async deleteByCommissionRule(idCommissionRule)
    {
        const response = await api.commissionReport().remove(idCommissionRule);
        return apiCallbackTreatment(response);
    }

    async update(id, data)
    {
        const response = await api.commissionReport().update(id, data);
        return apiCallbackTreatment(response);
    }
}