import React from "react";
import "./ButtonWhatsApp.css";

export default function ButtonWhatsApp (props)
{
    return (
        <button
            className={`buttonWhatsApp ${props?.classaditional}`}
            {...props}
        >
            {props.name}
        </button>
    )
}