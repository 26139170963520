import { 
    SET_AUTHENTICATE,
    SET_SOCKETS_MAIN_ONLINE,
    SET_SOCKETS_MAIN_ONLINE_UPDATED,
    SET_USER_LOGOUT
}  from "../../core/constants";

export function setAuthenticated (data)
{
    return {
        type: SET_AUTHENTICATE,
        payload: data
    }
}

export function setUserLogout ()
{
    return {
        type: SET_USER_LOGOUT,
    }
}

export function setSocketsMainOnlineAuth (data)
{
    return {
        type: SET_SOCKETS_MAIN_ONLINE,
        payload: data
    }
}

export function setSocketsMainOnlineUpdatedAuth (status)
{
    return {
        type: SET_SOCKETS_MAIN_ONLINE_UPDATED,
        payload: status
    }
}