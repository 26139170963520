import { 
    SET_AUTHENTICATE,
    SET_SOCKETS_MAIN_ONLINE,
    SET_SOCKETS_MAIN_ONLINE_UPDATED,
    SET_USER_LOGOUT
} from "../../core/constants";

const initialState = {
    user: null,
    hasUserLogged: false,
    socketsMainOnline: [],
    socketMainOnlineUpdated: false,
};

export default function authReducer (state = initialState, action) 
{
    switch (action.type) {
        case SET_AUTHENTICATE:
            return {
                ...state,
                user: action.payload,
                hasUserLogged: action.payload ? true : false,
            }

        case SET_USER_LOGOUT:
            return {
                ...state,
                hasUserLogged: false,
            }

        case SET_SOCKETS_MAIN_ONLINE:
            return {
                ...state,
                socketsMainOnline: action.payload,
                socketMainOnlineUpdated: true,
            }

        case SET_SOCKETS_MAIN_ONLINE_UPDATED:
            return {
                ...state,
                socketMainOnlineUpdated: false
            }
        default:
            return state
    }

}