import React, { Component } from "react";
import "./CommissionRuleForm.css";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import { getAllUsersLeads } from "../../store/actions/leads";
import { newCommissionRule, getAllCommissionRules, changeStatusGetFromDatabaseCompletedCommissionRules } from "../../store/actions/commission";
import { formatedPercentageNumber, formatedPercentToFloat } from "../../auxiliary/generalFunctions";
import CompanyUnitController from "../../controllers/CompanyUnitController";
import CommissionRulesController from "../../controllers/CommissionRulesController";
import InputGeneral from "../general/InputGeneral";
import Button from "../general/Button";
import Select from "../general/Select";
import Message from "../general/Message";
import PermissionGroupController from "../../controllers/PermissionsGroupController";

const initialState = {

    // start content unit company
    validUnitCompany: true,
    choosenUnitCompany: {
        name: "",
        value: 0,
    },
    // ends content unit company

    // start content month
    month: "",
    searchingMonth: false,
    warningMonth: false,
    noticesMonth: [],
    validMonth: true,
    // ends content month 

    // start content utilizationCommission
    utilizationCommission: "",
    searchingUtilizationCommission: false,
    warningUtilizationCommission: false,
    noticesUtilizationCommission: [],
    validUtilizationCommission: true,
    // ends content utilizationCommission 

    // start content receiptCommission
    receiptCommission: "",
    searchingReceiptCommission: false,
    warningReceiptCommission: false,
    noticesReceiptCommission: [],
    validReceiptCommission: true,
    // ends content receiptCommission 

    // start content utilizationGoal
    utilizationGoal: "",
    searchingUtilizationGoal: false,
    warningUtilizationGoal: false,
    noticesUtilizationGoal: [],
    validUtilizationGoal: true,
    // ends content utilizationGoal 

    // start content lossGoal
    lossGoal: "",
    searchingLossGoal: false,
    warningLossGoal: false,
    noticesLossGoal: [],
    validLossGoal: true,
    // ends content lossGoal 

    // start content receivingGoal
    receivingGoal: "",
    searchingReceivingGoal: false,
    warningReceivingGoal: false,
    noticesReceivingGoal: [],
    validReceivingGoal: true,
    // ends content receivingGoal 

    // start content performanceBonus
    performanceBonus: "",
    searchingPerformanceBonus: false,
    warningPerformanceBonus: false,
    noticesPerformanceBonus: [],
    validPerformanceBonus: true,
    // ends content performanceBonus 

    // start content receivingBonus
    receivingBonus: "",
    searchingReceivingBonus: false,
    warningReceivingBonus: false,
    noticesReceivingBonus: [],
    validReceivingBonus: true,
    // ends content receivingBonus 

    // start content usageCeiling
    usageCeiling: "",
    searchingUsageCeiling: false,
    warningUsageCeiling: false,
    noticesUsageCeiling: [],
    validUsageCeiling: true,
    // ends content usageCeiling 

    // start content receiptCeiling
    receiptCeiling: "",
    searchingReceiptCeiling: false,
    warningReceiptCeiling: false,
    noticesReceiptCeiling: [],
    validReceiptCeiling: true,
    // ends content receiptCeiling

    // start content lossCeiling
    lossCeiling: "",
    searchingLossCeiling: false,
    warningLossCeiling: false,
    noticesLossCeiling: [],
    validLossCeiling: true,
    // ends content lossCeiling

    // start content monthsPortfolio
    monthsPortfolio: "",
    searchingMonthsPortfolio: false,
    warningMonthsPortfolio: false,
    noticesMonthsPortfolio: [],
    validMonthsPortfolio: true,
    // ends content monthsPortfolio 

    // start content target
    target: "",
    searchingTarget: false,
    warningTarget: false,
    noticesTarget: [],
    validTarget: true,
    choosenTarget: {
        name: "",
        value: 0,
    },
    // ends content target 

    optionsTypeTargets: [
        {
            name: "Especialista",
            value: 1,
            checked: false
        },
        {
            name: "Gerente",
            value: 2,
            checked: false
        },
    ],

    optionsUnitCompany: [],

    // start modal message
    message_type: "information",
    message_show: false,
    message: "",
    // ends modal message
}

const companyUnitController = new CompanyUnitController();
const commissionRuleController = new CommissionRulesController();
const permissionGroupController = new PermissionGroupController();

class CommissionRuleForm extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        await this.getPermissionsGroup();
        await this.getCompanyUnit();
    }

    getCompanyUnit = async () => 
    {
        let optionsUnitCompany = [];
        const result = await companyUnitController.get();

        if (result.status) 
        {
            for (const company of result.data.data)
            {
                if (company.UNT_STATUS === 1)
                {
                    optionsUnitCompany.push({
                        name: company.UNT_NAME,
                        value: company.UNT_ID,
                        checked: false
                    });
                }
            }
        }
                
        await this.setState({optionsUnitCompany});
    }

    getPermissionsGroup = async () =>
    {
        let optionsTypeTargets = [];
        const result = await permissionGroupController.get();

        if (result.status) 
        {
            for (const group of result.data.data)
            {
                if (group.PEG_IS_COMMISSIONED === 1)
                {
                    optionsTypeTargets.push({
                        name: group.PEG_NAME,
                        value: group.PEG_ID,
                        checked: false
                    });
                }
                
            }
        }
                
        await this.setState({optionsTypeTargets});
    }

    closeModalMoreActions = () =>
    {
        let data = {
            show: false,
            type: null
        }
        this.props.showModalMoreActions(data);
    }

    unitChangeAndChecking = async (choosenUnitCompany) =>
    {
        await this.setState({
            choosenUnitCompany,
            validUnitCompany: choosenUnitCompany.value !== 0
        });
    }

    monthChangeAndChecking = async (month) =>
    {
        await this.setState({
            month,
            validMonth: month.toString().trim() !== ""
        });
    }

    utilizationCommissionChangeAndChecking = async (utilizationCommission) =>
    {
        await this.setState({
            utilizationCommission,
            validUtilizationCommission: utilizationCommission.toString().trim() !== ""
        });
    }

    receiptCommissionChangeAndChecking = async (receiptCommission) =>
    {
        await this.setState({
            receiptCommission,
            validReceiptCommission: receiptCommission.toString().trim() !== ""
        });
    }

    utilizationGoalChangeAndChecking = async (utilizationGoal) =>
    {
        await this.setState({
            utilizationGoal,
            validUtilizationGoal: utilizationGoal.toString().trim() !== ""
        });
    }

    lossGoalChangeAndChecking = async (lossGoal) =>
    {
        await this.setState({
            lossGoal,
            validLossGoal: lossGoal.toString().trim() !== ""
        });
    }

    receivingGoalChangeAndChecking = async (receivingGoal) =>
    {
        await this.setState({
            receivingGoal,
            validReceivingGoal: receivingGoal.toString().trim() !== ""
        });
    }

    performanceBonusChangeAndChecking = async (performanceBonus) =>
    {
        await this.setState({
            performanceBonus,
            validPerformanceBonus: true
        });
    }

    receivingBonusChangeAndChecking = async (receivingBonus) =>
    {
        await this.setState({
            receivingBonus,
            validReceivingBonus: true
        });
    }

    usageCeilingChangeAndChecking = async (usageCeiling) =>
    {
        await this.setState({
            usageCeiling,
            validUsageCeiling: usageCeiling.toString().trim() !== ""
        });
    }

    receiptCeilingChangeAndChecking = async (receiptCeiling) =>
    {
        await this.setState({
            receiptCeiling,
            validReceiptCeiling: receiptCeiling.toString().trim() !== ""
        });
    }

    lossCeilingChangeAndChecking = async (lossCeiling) =>
    {
        await this.setState({
            lossCeiling,
            validLossCeiling: lossCeiling.toString().trim() !== ""
        });
    }

    monthsPortfolioChangeAndChecking = (monthsPortfolio) =>
    {
        this.setState({
            monthsPortfolio,
            validMonthsPortfolio:  true
        });
    }

    targetChangeAndChecking = async (choosenTarget) =>
    {
        await this.setState({
            choosenTarget,
            validTarget: choosenTarget.value !== 0
        });
    }

    onActionForm = async () =>
    {
        let allCorrect = true;

        //numbers
        let unit = await this.state.choosenUnitCompany.value;   

        //strings
        let month = await this.state.month;
        let utilizationCommission = await this.state.utilizationCommission;
        let receiptCommission = await this.state.receiptCommission;
        let utilizationGoal = await this.state.utilizationGoal;
        let receivingGoal = await this.state.receivingGoal;
        let lossGoal = await this.state.lossGoal;
        let performanceBonus = await this.state.performanceBonus;
        let receivingBonus = await this.state.receivingBonus;
        let usageCeiling = await this.state.usageCeiling;   
        let receiptCeiling = await this.state.receiptCeiling;   
        let lossCeiling = await this.state.lossCeiling;   
        let monthsPortfolio = await this.state.monthsPortfolio; 
        let target = await this.state.choosenTarget.value;   

        let data = {
            month,
            utilizationCommission,
            receiptCommission,
            utilizationGoal,
            receivingGoal,
            lossGoal,
            usageCeiling,
            receiptCeiling,
            lossCeiling
        };

        // number checking
        if (unit === 0)
        {
            allCorrect = false;
            await this.setState({validUnitCompany: false});
        }

        if (target === 0)
        {
            allCorrect = false;
            await this.setState({validTarget: false});
        }

        // string checking
        Object.keys(data).forEach(key => {
            if (data[key].toString().trim() === "")
            {
                allCorrect = false;
                let input = key[0].toUpperCase() + key.slice(1);
                let state = `valid${input}`;
                this.setState({[state]: false});
            }
        });

        data = {
            unit,
            month,
            utilizationCommission: formatedPercentToFloat(utilizationCommission),
            receiptCommission: formatedPercentToFloat(receiptCommission),
            utilizationGoal: formatedPercentToFloat(utilizationGoal),
            receivingGoal: formatedPercentToFloat(receivingGoal),
            lossGoal: formatedPercentToFloat(lossGoal),
            performanceBonus: formatedPercentToFloat((performanceBonus === "") ? "0" : performanceBonus),
            receivingBonus: formatedPercentToFloat((receivingBonus === "") ? "0" : receivingBonus),
            usageCeiling: formatedPercentToFloat(usageCeiling),
            receiptCeiling: formatedPercentToFloat(receiptCeiling),
            lossCeiling: formatedPercentToFloat(lossCeiling),
            monthsPortfolio: parseInt((monthsPortfolio === "") ? "3" : monthsPortfolio),
            target
        };

        if (!allCorrect)
        {
            await this.message("error", "Confira o(s) campo(s) destacado(s) em vermelho!");
            return;
        }

        const result = await commissionRuleController.create({
            COR_ID_COMPANY_UNIT: data.unit,
            COR_MONTH: data.month,
            COR_PERCENT_UTILIZATION_COMMISSION: data.utilizationCommission,
            COR_PERCENT_RECEIVING_COMMISSION: data.receiptCommission,
            COR_PERCENT_UTILIZATION_MINIMUM_GOAL: data.utilizationGoal,
            COR_PERCENT_RECEIVING_MINIMUM_GOAL: data.receivingGoal,
            COR_PERCENT_LOSS_MINIMUM_GOAL: data.lossGoal,
            COR_PERCENT_UTILIZATION_BONUS: data.performanceBonus,
            COR_PERCENT_RECEIVING_BONUS: data.receivingBonus,
            COR_PERCENT_UTILIZATION_MAXIMUM_GOAL: data.usageCeiling,
            COR_PERCENT_RECEIVING_MAXIMUM_GOAL: data.receiptCeiling,
            COR_PERCENT_LOSS_MAXIMUM_GOAL: data.lossCeiling,
            COR_TOTAL_MONTHS_OF_PORTFOLIO: data.monthsPortfolio,
            COR_RULE_TARGET: data.target
        });

        if (!result.status)
        {
            return this.message("error", result.message);
        }

        // setting as default
        this.setState({
            choosenUnitCompany: {
                name: "",
                value: 0,
            },
            month: "",
            utilizationCommission: "",
            receiptCommission: "",
            utilizationGoal: "",
            receivingGoal: "",
            lossGoal: "",
            performanceBonus: "",
            receivingBonus: "",
            usageCeiling: "",
            receiptCeiling: "",
            lossCeiling: "",
            monthsPortfolio: "",
            choosenTarget: {
                name: "",
                value: 0,
            },
        });

        this.closeModalMoreActions();

        await this.props.changeStatusGetFromDatabaseCompletedCommissionRules(false);
        await this.props.getAllCommissionRules();
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    render () 
    {
        return (
            <div className="commissionRuleForm"> 
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-12 columnCommissionRuleForm">
                        <Select
                            label="Unidade da Empresa *"
                            options={this.state.optionsUnitCompany}
                            default={this.state.choosenUnitCompany}
                            onChange={(choosenUnitCompany) => this.unitChangeAndChecking(choosenUnitCompany)}
                            valid={this.state.validUnitCompany.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-12 columnCommissionRuleForm">
                        <InputGeneral
                            label="Mês de Referência"
                            value={this.state.month}
                            onChange={e => this.monthChangeAndChecking(e.target.value)}
                            searching={this.state.searchingMonth.toString()}
                            warning={this.state.warningMonth.toString()}
                            warningcontent={this.state.noticesMonth}
                            mandatory="true"
                            type="month"
                            valid={this.state.validMonth.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-6 columnCommissionRuleForm pr-1">
                        <InputGeneral
                            label="Aprov. Comissão (%)"
                            value={this.state.utilizationCommission}
                            onChange={e => this.utilizationCommissionChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingUtilizationCommission.toString()}
                            warning={this.state.warningUtilizationCommission.toString()}
                            warningcontent={this.state.noticesUtilizationCommission}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validUtilizationCommission.toString()}
                        />
                    </div>
                    <div className="col-6 columnCommissionRuleForm pl-1">
                        <InputGeneral
                            label="Receb. Comissão (%)"
                            value={this.state.receiptCommission}
                            onChange={e => this.receiptCommissionChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingReceiptCommission.toString()}
                            warning={this.state.warningReceiptCommission.toString()}
                            warningcontent={this.state.noticesReceiptCommission}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validReceiptCommission.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-6 columnCommissionRuleForm pr-1">
                        <InputGeneral
                            label="Aprov. Qualificação (%)"
                            value={this.state.utilizationGoal}
                            onChange={e => this.utilizationGoalChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingUtilizationGoal.toString()}
                            warning={this.state.warningUtilizationGoal.toString()}
                            warningcontent={this.state.noticesUtilizationGoal}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validUtilizationGoal.toString()}
                        />
                    </div>
                    <div className="col-6 columnCommissionRuleForm pl-1">
                        <InputGeneral
                            label="Aprov. Meta (%)"
                            value={this.state.usageCeiling}
                            onChange={e => this.usageCeilingChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingUsageCeiling.toString()}
                            warning={this.state.warningUsageCeiling.toString()}
                            warningcontent={this.state.noticesUsageCeiling}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validUsageCeiling.toString()}
                        />

                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-6 columnCommissionRuleForm pr-1">
                        <InputGeneral
                            label="Receb. Qualificação (%)"
                            value={this.state.receivingGoal}
                            onChange={e => this.receivingGoalChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingReceivingGoal.toString()}
                            warning={this.state.warningReceivingGoal.toString()}
                            warningcontent={this.state.noticesReceivingGoal}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validReceivingGoal.toString()}
                        />
                    </div>
                    <div className="col-6 columnCommissionRuleForm pl-1">
                        <InputGeneral
                            label="Receb. Meta (%)"
                            value={this.state.receiptCeiling}
                            onChange={e => this.receiptCeilingChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingReceiptCeiling.toString()}
                            warning={this.state.warningReceiptCeiling.toString()}
                            warningcontent={this.state.noticesReceiptCeiling}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validReceiptCeiling.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-6 columnCommissionRuleForm pr-1">
                        <InputGeneral
                            label="Perda Qualificação (%)"
                            value={this.state.lossGoal}
                            onChange={e => this.lossGoalChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingLossGoal.toString()}
                            warning={this.state.warningLossGoal.toString()}
                            warningcontent={this.state.noticesLossGoal}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validLossGoal.toString()}
                        />

                    </div>
                    <div className="col-6 columnCommissionRuleForm pl-1">
                        <InputGeneral
                            label="Perda Meta (%)"
                            value={this.state.lossCeiling}
                            onChange={e => this.lossCeilingChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingLossCeiling.toString()}
                            warning={this.state.warningLossCeiling.toString()}
                            warningcontent={this.state.noticesLossCeiling}
                            mandatory="true"
                            maxLength={5}
                            valid={this.state.validLossCeiling.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-6 columnCommissionRuleForm pr-1">
                        <InputGeneral
                            label="Aprov. Bônus (%)"
                            value={this.state.performanceBonus}
                            onChange={e => this.performanceBonusChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingPerformanceBonus.toString()}
                            warning={this.state.warningPerformanceBonus.toString()}
                            warningcontent={this.state.noticesPerformanceBonus}
                            maxLength={5}
                            valid={this.state.validPerformanceBonus.toString()}
                        />
                    </div>
                    <div className="col-6 columnCommissionRuleForm pl-1">
                        <InputGeneral
                            label="Receb. Bônus (%)"
                            value={this.state.receivingBonus}
                            onChange={e => this.receivingBonusChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingReceivingBonus.toString()}
                            warning={this.state.warningReceivingBonus.toString()}
                            warningcontent={this.state.noticesReceivingBonus}
                            maxLength={5}
                            valid={this.state.validReceivingBonus.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-6 columnCommissionRuleForm pr-1">
                        <InputGeneral
                            label="Meses de Carteira"
                            value={this.state.monthsPortfolio}
                            onChange={e => this.monthsPortfolioChangeAndChecking(formatedPercentageNumber(e.target.value))}
                            searching={this.state.searchingMonthsPortfolio.toString()}
                            warning={this.state.warningMonthsPortfolio.toString()}
                            warningcontent={this.state.noticesMonthsPortfolio}
                            maxLength={2}
                            valid={this.state.validMonthsPortfolio.toString()}
                        />
                    </div>
                    <div className="col-6 columnCommissionRuleForm pl-1">
                        <Select
                            label="Grupo *"
                            default={this.state.choosenTarget}
                            options={this.state.optionsTypeTargets} 
                            onChange={async (choosenTarget) => {
                                await this.setState({choosenTarget});
                            }}
                            valid={this.state.validTarget.toString()}
                        />
                    </div>
                </div>
                <div className="row no-gutters lineCommissionRuleForm">
                    <div className="col-12 columnCommissionRuleForm">
                        <span className="mandatoryInformation">(*) campos com preenchimento obrigatório.</span>
                    </div>
                </div>
                <div className="row no-gutters lineFooterCommissionRuleForm">
                    <Button
                        classaditional="buttonCommissionRuleForm"
                        name="Cancelar"
                        onClick={() => this.closeModalMoreActions()}
                    />
                    <Button
                        classaditional="buttonCommissionRuleForm positive"
                        name="Salvar"
                        onClick={() => this.onActionForm()}
                    />                  
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const { user } = state.auth;

    return {
        user
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        getAllUsersLeads ()
        {
            //action creator -> action
            const action = getAllUsersLeads();
            dispatch(action);
        },
        showModalMoreActions (show)
        {
            //action creator -> action
            const action = showModalMoreActions(show);
            dispatch(action);
        },
        newCommissionRule (data)
        {
            //action creator -> action
            const action = newCommissionRule(data);
            dispatch(action);
        },
        getAllCommissionRules ()
        {
            //action creator -> action
            const action = getAllCommissionRules();
            dispatch(action);
        },
        changeStatusGetFromDatabaseCompletedCommissionRules (status)
        {
            //action creator -> action
            const action = changeStatusGetFromDatabaseCompletedCommissionRules(status);
            dispatch(action);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionRuleForm);