import {
    ACTIVITY,
    ADVANCED_BUSINESS,
    ANALYSIS,
    BUSINESS,
    CAMPAIGN,
    CLIENT,
    COMMISSION_RULES,
    COMPANY,
    CONNECTION_LOG,
    DASHBOARD,
    DASHBOARD_MONTHS,
    DASHBOARD_PROSPECTION,
    DASHBOARD_MARKETING,
    GAIN_STATUS,
    GET_ALL_PERMISSIONS,
    LEAD_OWNERS,
    LIST_ALL,
    LIST_ALL_TO_CONNECT_UP,
    LIST_ALL_COMMISSION_MANAGER,
    LIST_ALL_COMMISSION_SPECIALIST,
    LOSS_STATUS,
    NULL_STATUS,
    OWNERS,
    PAUSED_STATUS,
    PERMISSIONS,
    REPORT,
    SERVICE_QUEUE,
    UNIT_COMPANY,
    USER,
    MODAL,
    DEBUG,
    CHAT,
    RUN_CHAT,
    SCREEN,
    LIST_ALL_COMMISSION_ATTENDANT,
    LIST_ALL_COMMISSION_SUPERVISOR,
    LIST_ALL_COMMISSION_INTERVALS,
    COMMISSION_RULES_PROSPECTION,
    COMMISSION_RULES_INTERVALS,
    TRAINING,
    SERVICES,
    PRE_SALE_SERVICE_QUEUE,
    ABSENCE_ATTENDANTS,
    WHATSAPP,
    PHONE
} from "../../core/constants";
import PermissionsGroupController from "../../controllers/PermissionsGroupController";

const permissionsGroupController = new PermissionsGroupController();

export const getAllPermissionsMiddleware = (store) => (next) => async (action) => 
{
    if (action.type === GET_ALL_PERMISSIONS) 
    {
        const { user } = store.getState().auth;
        const result = await permissionsGroupController.getById(user.PER_ID_PERMISSIONS_GROUP);

        if (result.status) 
        {
            const allPermissions = result.data.permissions;
            const activities = allPermissions.find(p => p.component === ACTIVITY).actions.find(a => a.slug === LIST_ALL);
            const advancedBusiness = allPermissions.find(p => p.component === ADVANCED_BUSINESS).actions.find(a => a.slug === LIST_ALL);
            const analysis = allPermissions.find(p => p.component === ANALYSIS).actions.find(a => a.slug === LIST_ALL);
            const business = allPermissions.find(p => p.component === BUSINESS).actions.find(a => a.slug === LIST_ALL);
            const campaign = allPermissions.find(p => p.component === CAMPAIGN).actions.find(a => a.slug === LIST_ALL);
            const commissionReport = allPermissions.find(p => p.component === REPORT).actions.find(a => a.slug === LIST_ALL_COMMISSION_SPECIALIST);
            const commissionReportAttendant = allPermissions.find(p => p.component === REPORT).actions.find(a => a.slug === LIST_ALL_COMMISSION_ATTENDANT);
            const commissionReportManager = allPermissions.find(p => p.component === REPORT).actions.find(a => a.slug === LIST_ALL_COMMISSION_MANAGER);
            const commissionReportSupervisor = allPermissions.find(p => p.component === REPORT).actions.find(a => a.slug === LIST_ALL_COMMISSION_SUPERVISOR);
            const commissionReportIntervals = allPermissions.find(p => p.component === REPORT).actions.find(a => a.slug === LIST_ALL_COMMISSION_INTERVALS);
            const commissionRules = allPermissions.find(p => p.component === COMMISSION_RULES).actions.find(a => a.slug === LIST_ALL);
            const commissionRulesProspection = allPermissions.find(p => p.component === COMMISSION_RULES_PROSPECTION).actions.find(a => a.slug === LIST_ALL);
            const commissionRulesIntervals = allPermissions.find(p => p.component === COMMISSION_RULES_INTERVALS).actions.find(a => a.slug === LIST_ALL);
            const companyPreferences = allPermissions.find(p => p.component === COMPANY).actions.find(a => a.slug === LIST_ALL);
            const companyUnit = allPermissions.find(p => p.component === UNIT_COMPANY).actions.find(a => a.slug === LIST_ALL);
            const dashboard = allPermissions.find(p => p.component === DASHBOARD).actions.find(a => a.slug === LIST_ALL);
            const dashboardMonths = allPermissions.find(p => p.component === DASHBOARD_MONTHS).actions.find(a => a.slug === LIST_ALL);
            const gainStatus = allPermissions.find(p => p.component === GAIN_STATUS).actions.find(a => a.slug === LIST_ALL);
            const leads = allPermissions.find(p => p.component === CLIENT).actions.find(a => a.slug === LIST_ALL);
            const leadOwners = allPermissions.find(p => p.component === LEAD_OWNERS).actions.find(a => a.slug === LIST_ALL);
            const leadOwnersModal = allPermissions.find(p => p.component === LEAD_OWNERS).actions.find(a => a.slug === MODAL);
            const lossStatus = allPermissions.find(p => p.component === LOSS_STATUS).actions.find(a => a.slug === LIST_ALL);
            const owners = allPermissions.find(p => p.component === OWNERS).actions.find(a => a.slug === LIST_ALL);
            const pausedStatus = allPermissions.find(p => p.component === PAUSED_STATUS).actions.find(a => a.slug === LIST_ALL);
            const permissionGroups = allPermissions.find(p => p.component === PERMISSIONS).actions.find(a => a.slug === LIST_ALL);
            const personalPreferences = allPermissions.find(p => p.component === USER).actions.find(a => a.slug === LIST_ALL);
            const reopenStatus = allPermissions.find(p => p.component === NULL_STATUS).actions.find(a => a.slug === LIST_ALL);
            const serviceQueue = allPermissions.find(p => p.component === SERVICE_QUEUE).actions.find(a => a.slug === LIST_ALL);
            const connectionLog = allPermissions.find(p => p.component === CONNECTION_LOG).actions.find(a => a.slug === LIST_ALL);
            const dashboardProspection = allPermissions.find(p => p.component === DASHBOARD_PROSPECTION).actions.find(a => a.slug === LIST_ALL);
            const dashboardMarketing = allPermissions.find(p => p.component === DASHBOARD_MARKETING).actions.find(a => a.slug === LIST_ALL);
            const debug = allPermissions.find(p => p.component === DEBUG).actions.find(a => a.slug === SCREEN);
            const chat = allPermissions.find(p => p.component === CHAT).actions.find(a => a.slug === RUN_CHAT);
            const training = allPermissions.find(p => p.component === TRAINING).actions.find(a => a.slug === LIST_ALL);
            const services = allPermissions.find(p => p.component === SERVICES).actions.find(a => a.slug === LIST_ALL);
            const ownersOnline = allPermissions.find(p => p.component === OWNERS).actions.find(a => a.slug === LIST_ALL_TO_CONNECT_UP);
            const preSaleServiceQueue = allPermissions.find(p => p.component === PRE_SALE_SERVICE_QUEUE).actions.find(a => a.slug === LIST_ALL);
            const absenceAttendants = allPermissions.find(p => p.component === ABSENCE_ATTENDANTS).actions.find(a => a.slug === LIST_ALL);
            const whatsapp = allPermissions.find(p => p.component === WHATSAPP).actions.find(a => a.slug === LIST_ALL);
            const phone = allPermissions.find(p => p.component === PHONE).actions.find(a => a.slug === LIST_ALL);

            action.payload = {
                activities,
                advancedBusiness,
                analysis,
                business,
                campaign,
                commissionReport,
                commissionReportAttendant,
                commissionReportManager,
                commissionReportSupervisor,
                commissionReportIntervals,
                commissionRules,
                commissionRulesProspection,
                commissionRulesIntervals,
                companyPreferences,
                companyUnit,
                dashboard,
                dashboardMonths,
                dashboardProspection,
                dashboardMarketing,
                debug,
                chat,
                training,
                gainStatus,
                leads,
                leadOwners,
                leadOwnersModal,
                lossStatus,
                owners,
                ownersOnline,
                pausedStatus,
                permissionGroups,
                personalPreferences,
                reopenStatus,
                services,
                serviceQueue,
                connectionLog,
                preSaleServiceQueue,
                absenceAttendants,
                whatsapp,
                phone,
                hasPermissionsLoaded: true
            };
        }
    }

    next(action);
}