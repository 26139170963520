import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class CommissionReportIntervalsController
{
    async generate(idCommissionRuleIntervals) 
    {
        const response = await api.commissionReportIntervals().post(idCommissionRuleIntervals);
        return apiCallbackTreatment(response);
    }

    async deleteById(id)
    {
        const response = await api.commissionReportIntervals().remove(id);
        return apiCallbackTreatment(response);
    }

    async get()
    {
        const response = await api.commissionReportIntervals().get();
        return apiCallbackTreatment(response);
    }

    async update(id, data)
    {
        const response = await api.commissionReportIntervals().update(id, data);
        return apiCallbackTreatment(response);
    }

    async getByMonth(month) 
    {
        // MONTH FORMAT: yyyy-mm
        const response = await api.commissionReportIntervals().getByMonth(month);
        return apiCallbackTreatment(response);
    }

    // async monthClosure(month) 
    // {
    //     const response = await api.monthClosureIntervals().post({MONTH: month});
    //     return apiCallbackTreatment(response);
    // }
}