import React, { Component, Fragment } from "react";
import "./DetailsService.css";
import { withRouter } from "react-router-dom";
import Header from "../../components/CRM/Header";
import { connect } from "react-redux";
import { showModalMoreActions } from "../../store/actions/general";
import gravatar from "../../assets/images/default_avatar.svg";
import Box from "../../components/general/Box";
import Menus from "../../components/general/Menus";
import Message from "../../components/general/Message";
import { APPLICATION_NAME } from "../../core/constants";
import { formatedDateTimeToBRA, formatedMoneyBRAFromFloat } from "../../auxiliary/generalFunctions";
import ServiceController from "../../controllers/ServiceController";
import InputGeneral from "../../components/general/InputGeneral";
import Select from "../../components/general/Select";

const initialState = {
    // start service
    service: {
        id: 0,
        title: "",
        description: "",
        value: 0,
        createdAt: "",
        amountFineEarlyResolution: 0,
        creator: {
            id: 0,
            name: ""
        },
        type: {
            id: 0,
            title: "",
        },
        status: {
            id: 0,
            text: ""
        },
        statusNegotiation: [],
        suspended: {
            id: 0,
            text: ""
        },
        unit: {
            id: 0,
            name: ""
        },
        client: {
            id: 0,
            name: "",
            email: "",
            document: "",
            rg: "",
            birthday: "",
            mothersName: "",
            occupation: "",
            notified: {
                id: 0,
                text: ""
            },
            address: {
                zipCode: "",
                state: "",
                city: "",
                neighborhood: "",
                street: "",
                number: 0,
                complement: ""
            },
            phones: []
        },
        guarantor: {
            id: 0,
            name: 0
        },
        financialInstitution: {
            id: 0,
            name: ""
        },
        installments: [],
    },
    // ends service

    // start all visibles modals
    showModalMoreActions: false,
    // ends all visibles modals

    // start others defualts
    tabs: [
        {
            option: 1,
            name: "Dados do Cliente",
            icon: "fas fa-user"
        },
        {
            option: 2,
            name: "Dados do Serviço",
            icon: "far fa-calendar"
        },
        {
            option: 3,
            name: "Dados do Contrato",
            icon: "far fa-calendar"
        },
        {
            option: 4,
            name: "Valor do Serviço",
            icon: "fas fa-dollar-sign"
        },
        {
            option: 5,
            name: "Financeiro",
            icon: "fas fa-dollar-sign"
        },
    ],
    activeTab: 1,

    // start message modal
    message_type: "information",
    message_show: false,
    message: "",
    // ends message modal
}

const serviceController = new ServiceController();

class DetailsService extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        await this.getDetailsService();
        document.title = `${APPLICATION_NAME} - Detalhes Serviço`;
    }

    componentDidUpdate = async (prevProps, prevState) =>
    {
        // console.log("DETAILS BUSINESS prevProps ", prevProps);
        // console.log("DETAILS BUSINESS this.props ", this.props);

        if (prevProps.hasPermissionsLoaded !== this.props.hasPermissionsLoaded) 
		{
			if (!this.props.permissions.services.toView) 
            {
                this.props.history.push(`/preferences-user`);
            }
		}
    } 

    getDetailsService = async () => 
    {
        const { path, params } = this.props.match;
        
        if (path.includes("services") && path.includes("details")) 
        {
            const id = params.id;

            const query = await serviceController.getById(id);
            const service = query.data;

            this.setState({
                service: service.result
            });
        }   
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    getInstallmentsSummary = () =>
    {
        const summary = this.state.service.installments.reduce((acc, r) => acc += parseFloat(r.pendingValue), 0);
        return summary;
    }

    contentCurrentTab = () => 
    {
        switch(this.state.activeTab) {
            default:
                return <h1>nothing...</h1>
            case 1:
                return this.tabDetailsClient()
            case 2:
                return this.tabDetailsService()
            case 3:
                return this.tabDetailsContract()
            case 4:
                return this.tabDetailsServiceValue()
            case 5:
                return this.tabDetailsFinance()
        }
    }

    tabDetailsService = () => 
    {
        return (
            <div className="p-4">
                {/* <div className="row my-3">
                    <div className="col-4 col-lg-3 text-right text-muted">
                        Código
                    </div>
                    <div className="col-8 col-lg-9">
                        {this.state.service.id}
                    </div>
                </div> */}
                {/* <div className="row my-3">
                    <div className="col-4 col-lg-3 text-right text-muted">
                        Escritório
                    </div>
                    <div className="col-8 col-lg-9">
                        {this.state.service.unit.id} - {this.state.service.unit.name}
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-4 col-lg-3 text-right text-muted">
                        Criado Em
                    </div>
                    <div className="col-8 col-lg-9">
                        {this.state.service.createdAt.split("-").reverse().join("/")}
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-4 col-lg-3 text-right text-muted">
                        Criado Por
                    </div>
                    <div className="col-8 col-lg-9">
                        {this.state.service.creator.id} - {this.state.service.creator.name}
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-4 col-lg-3 text-right text-muted">
                        Suspender Serviço
                    </div>
                    <div className="col-8 col-lg-9">
                        {this.state.service.suspended.text}
                    </div>
                </div> */}
                <div className="row my-3">
                    <div className="col-12">
                        <Select 
                            label="Tipo de Serviço"
                            default={this.state.service.type}
                            options={[]} 
                            mandatory="true"
                            onChange={(optionChosenServiceType) => {
                                console.log("A");
                            }}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Título"
                            value={this.state.service.title}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                {/* <div className="row my-3">
                    <div className="col-4 col-lg-3 text-right text-muted">
                        Status do Serviço
                    </div>
                    <div className="col-8 col-lg-9">
                        {this.state.service.status.text}
                    </div>
                </div> */}
                {/* {
                    this.state.service.statusNegotiation.map(status => (
                        <div className="row my-3" key={status.label}>
                            <div className="col-4 col-lg-3 text-right text-muted">
                                Status da Negociação {status.label}
                            </div>
                            <div className="col-8 col-lg-9">
                                {status.title}
                            </div>
                        </div>
                    ))
                } */}
                <div className="row my-3">
                    <div className="col-12">
                        <Select 
                            label="Status da Negociação 01"
                            default={this.state.service.statusNegotiation[0]}
                            options={[]} 
                            mandatory="true"
                            onChange={(optionChosenServiceType) => {
                                console.log("A");
                            }}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <Select 
                            label="Status da Negociação 02"
                            default={this.state.service.statusNegotiation[1]}
                            options={[]} 
                            mandatory="true"
                            onChange={(optionChosenServiceType) => {
                                console.log("A");
                            }}
                        />
                    </div>
                </div>
                {/* <div className="row my-3">
                    <div className="col-4 col-lg-3 text-right text-muted">
                        Cliente Notificado
                    </div>
                    <div className="col-8 col-lg-9">
                        {this.state.service.client.notified.text}
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-4 col-lg-3 text-right text-muted">
                        Multa Resolução Antecipada
                    </div>
                    <div className="col-8 col-lg-9">
                        {formatedMoneyBRAFromFloat(this.state.service.amountFineEarlyResolution)}
                    </div>
                </div> */}
                <div className="row my-3">
                    <div className="col-4 col-lg-3 text-right text-muted">
                        Responsável Financeiro
                    </div>
                    <div className="col-8 col-lg-9">
                        {this.state.service.guarantor.name}
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-4 col-lg-3 text-right text-muted">
                        Instituição Financeira
                    </div>
                    <div className="col-8 col-lg-9">
                        {this.state.service.financialInstitution.name}
                    </div>
                </div>
            </div>
        )
    }

    tabDetailsServiceValue = () => 
    {
        return (
            <div className="p-4">
                <div className="row my-3">
                    <div className="col-12">
                        <Select 
                            label="Forma de Pagamento"
                            default={{}}
                            options={[]} 
                            mandatory="true"
                            onChange={(optionChosenServiceType) => {
                                console.log("A");
                            }}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Descrição (Forma de Pagto.)"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Valor do Serviço"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Número de Parcelas"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
            </div>
        )
    }

    tabDetailsContract = () => 
    {
        return (
            <div className="p-4">
                <div className="row my-3">
                    <div className="col-12">
                        <Select 
                            label="Tipo de Contrato"
                            default={{}}
                            options={[]} 
                            mandatory="true"
                            onChange={(optionChosenServiceType) => {
                                console.log("A");
                            }}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Data de Assinatura"
                            type="date"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Número do Contrato Financiamento/Empréstimo"
                            type="number"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Número do Processo"
                            type="number"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Dia do Vencimento"
                            type="number"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <strong>Endereço do Carnê</strong>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="CEP"
                            value={""}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Endereço"
                            value={""}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Número"
                            value={""}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Complemento"
                            value={""}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Bairro"
                            value={""}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Cidade"
                            value={""}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Estado"
                            value={""}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <strong>Dados do Veículo</strong>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Tipo de Veículo (Ex: Pas/Automóvel)"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Marca/Modelo"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Ano/Modelo"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Cor"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Placa"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Renavam"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Chassi"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <Select 
                            label="Detran"
                            default={{}}
                            options={[]} 
                            mandatory="true"
                            onChange={(optionChosenServiceType) => {
                                console.log("A");
                            }}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <strong>Cheque Especial / Cartão de Crédito</strong>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Cheque Especial Agência"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Cheque Especial Conta Corrente"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Final Cartão de Crédito"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <strong>Débitos</strong>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Data do Atraso da Parcela"
                            type="date"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Número de Parcelas"
                            type="number"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Valor das Parcelas"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Parcelas Pagas"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Valor Financiado"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Total Pago"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                            disabled="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Total Débito"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                            disabled="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <strong>Dados</strong>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Quitação seja menos que"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                            disabled="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Valor Fechado"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                            disabled="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Desconto Obtido"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                            disabled="true"
                        />
                    </div>
                </div>
            </div>
        )
    }

    tabDetailsClient = () => 
    {
        return (
            <div className="p-4">
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="CPF"
                            value={this.state.service.client.document}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Nome"
                            value={this.state.service.client.name}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="RG"
                            value={this.state.service.client.rg}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Data de Nascimento"
                            type="date"
                            value={this.state.service.client.birthday}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Estado Civil"
                            value=""
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Nome da Mãe"
                            value={this.state.service.client.mothersName}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Profissão"
                            value={this.state.service.client.occupation}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="CEP"
                            value={this.state.service.client.address.zipCode}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Endereço"
                            value={this.state.service.client.address.street}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Número"
                            value={this.state.service.client.address.number}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Complemento"
                            value={this.state.service.client.address.complement}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Bairro"
                            value={this.state.service.client.address.neighborhood}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Cidade"
                            value={this.state.service.client.address.city}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <InputGeneral
                            label="Estado"
                            value={this.state.service.client.address.state}
                            onChange={e => alert(e.target.value)}
                            mandatory="true"
                        />
                    </div>
                </div>
            </div>
        )
    }

    tabDetailsFinance = () => 
    {
        return (
            <div className="p-4">
                <div className="row my-3">
                    <div className="col-6">
                        Valor do Serviço Inicial: <strong>R$ {formatedMoneyBRAFromFloat(this.state.service.value)}</strong>
                    </div>
                    <div className="col-6">
                        Soma das Parcelas: <strong>R$ {formatedMoneyBRAFromFloat(this.getInstallmentsSummary())}</strong>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <table className="table table-striped">
                            <thead>
                                <th>Boleto/Status</th>
                                <th className="text-center"></th>
                                <th className="text-center">Vencimento</th>
                                <th className="text-center">Pagamento</th>
                                <th className="text-right">Vl. Original</th>
                                <th className="text-right">Vl. Aberto</th>
                                <th className="text-right">Vl. Recebido</th>
                            </thead>
                            <tbody>
                                {this.state.service.installments.map(installment => (
                                    <tr key={installment.id}>
                                        <td>
                                            Parcela Nº {installment.number} <strong className={`ml-2 text-${installment.payment.status.className}`}>{installment.payment.status.text}</strong>
                                        </td>
                                        <td className="text-center">
                                            <span title={installment.remittance.status.text} className={`remittance-status bg-${installment.remittance.status.className}`}></span>
                                        </td>
                                        <td className="text-center">{formatedDateTimeToBRA(installment.dueDate)}</td>
                                        <td className="text-center">{installment.paid && formatedDateTimeToBRA(installment.payment.date)}</td>
                                        <td className="text-right">{formatedMoneyBRAFromFloat(installment.originalValue)}</td>
                                        <td className="text-right">{formatedMoneyBRAFromFloat(installment.pendingValue)}</td>
                                        <td className="text-right">{formatedMoneyBRAFromFloat(installment.receivedValue)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    render ()
    {
        return (
            <div className="detailsService">
                <Header title="Serviços" classaditional="headerNavigationDetailsService"/>
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                <div className="scrollDetailsService mb-4">
                    <div className="warningDetailsService d-flex d-md-none">
                        <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                    </div>
                    <div className="headerDetailsService">
                        <div className="row no-gutters lineHeaderInfoDetailsService justify-content-center">
                            <div className="col-12 col-lg-7 columnHeaderInfoDetailsService">
                                <div className="lineTopMoreInfoDetailsService">
                                    <h1
                                        className="titleHeaderInfoDetailsService"
                                    >
                                        {this.state.service.title}
                                    </h1>
                                </div>
                                <div className="lineBottomMoreInfoDetailsService">
                                    <span 
                                        className="moneyInfoDetailsService"
                                    >
                                        R$&nbsp;{formatedMoneyBRAFromFloat(parseFloat(this.state.service.value))}
                                    </span>
                                    <div className="ownersInfoDetailsService">
                                        <i className="fas fa-user iconUserMoreInfoDetailsService"></i>
                                        <span className="nameUserMoreInfoDetailsService">&nbsp;{this.state.service.client.name}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 columnHeaderInfoDetailsService d-flex justify-content-center justify-content-lg-end m-2 m-lg-0">
                            {/* {
                                this.props.permissions.leadOwners.toView &&
                                <div className="editOwnersHeaderInfoDetailsService d-none d-md-flex">
                                    <div className="avatarOwnerHeaderDetailsService">
                                        <img className="img-fluid" src={gravatar} alt="" />
                                    </div>
                                    <div className="nameOwnerHeaderDetailsService">
                                        <Anchor
                                            classaditional="anchorNameOwnerHeaderDetailsService"
                                            label={this.state.owner?.name}
                                            onClick={() => this.setState({showModalEditOwner: true})}
                                        >
                                        </Anchor>
                                        <span className="levelNameOwnerHeaderDetailsService">Proprietário</span>
                                        {
                                            this.state.showModalEditOwner &&
                                            <ModalEdit
                                                classaditional="modalEditOwnerDetailsService"
                                                title="Transferir propriedade"
                                                onPositiveClick={() => {
                                                    this.saveOwner();
                                                    this.setState({showModalEditOwner: false})
                                                }}
                                                onNegativeClick={() => {
                                                    this.setState({showModalEditOwner: false})
                                                }}
                                                optionsSelectMulti
                                                permissions={this.props.permissions.leadOwners}
                                            >
                                                <Select
                                                    default={this.state.optionChosenOwner}
                                                    options={this.state.optionOwnerSpecialistOrManagers} 
                                                    onChange={(optionChosenOwner) => this.setState({optionChosenOwner})}
                                                    withFieldSearch
                                                />
                                            </ModalEdit>
                                        }
                                    </div>
                                    {
                                        (!this.state.showModalEditOwner) &&
                                        <span className="notificationOwnerDetailsService">transferir propriedade</span>
                                    }
                                    <ButtonIcon
                                        icon="fas fa-sort-down"
                                        classaditional="buttonEditOwnerHeaderDetailsService"
                                        onClick={() => {
                                            this.setState({showModalEditOwner: !this.state.showModalEditOwner});
                                        }}
                                    />
                                </div>
                            } */}
                            </div>
                        </div>
                    </div>
                    <div className="bodyDetailsService">
                        <div className="row no-gutters lineBodyDetailsService">
                            <div className="col-12 col-md-12 col-lg-4 p-md-2 pr-lg-4 mb-5">
                                <Box classaditional="boxOthersBodyDetailsService">
                                    <div className="headerBoxOthersBodyDetailsService">
                                        <span className="subtitleOthersDetailsService">Contato</span>
                                    </div>
                                    <div className="bodyBoxOthersBodyDetailsService">
                                        <div className="row no-gutters lineBodyOthersDetailsService mb-4">
                                            <div className="col-3 colOne">
                                                <img className="avatarClientOthersDetailsService" src={gravatar} alt="Imagem do Cliente do Negócio" />
                                            </div>
                                            <div className="col-9 colTwo">
                                                <span className="nameClientBodyOthersDetailsService">{ this.state.service.client.name }</span>
                                            </div>
                                        </div>
                                        {
                                            this.state.service.client.phones.map(phone => (
                                                <Fragment key={phone.number}>
                                                    <div className="row no-gutters lineBodyOthersDetailsService">
                                                        <div className="col-4 colOne">
                                                            <span>{phone.label} - {phone.kinship}</span>
                                                        </div>
                                                        <div className="col-8 colTwo">
                                                            <span>{phone.number}</span>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))
                                        }
                                        <div className="row no-gutters lineBodyOthersDetailsService">
                                            <div className="col-4 colOne">
                                                <span>E-mail</span>
                                            </div>
                                            <div className="col-8 colTwo">
                                                <span>{ this.state.service.client.email }</span>
                                            </div>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsService">
                                            <div className="col-4 colOne">
                                                <span>Unidade</span>
                                            </div>
                                            <div className="col-8 colTwo">
                                                <span>{ this.state.service.unit.name }</span>
                                            </div>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsService">
                                            <div className="col-4 colOne">
                                                <span>Criado por</span>
                                            </div>
                                            <div className="col-8 colTwo">
                                                <span>{ this.state.service.creator.name }</span>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                                {/* <Box classaditional="boxOthersBodyDetailsService">
                                    <div className="headerBoxOthersBodyDetailsService">
                                        <span className="subtitleOthersDetailsService">Visão Geral</span>
                                    </div>
                                    <div className="bodyBoxOthersBodyDetailsService">
                                        <div className="row no-gutters lineBodyOthersDetailsService general d-flex justify-content-between">
                                            <span>Idade do negócio</span>
                                            <span>{ this.state.numberOfDays } dia(s)</span>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsService d-flex justify-content-between">
                                            <span className="minumus">Inativo (dias)</span>
                                            <span className="minumus">{ this.state.inactiveInDays }</span>
                                        </div>
                                        <div className="row no-gutters lineBodyOthersDetailsService d-flex justify-content-between">
                                            <span className="minumus">Criado em</span>
                                            <span className="minumus">{ this.state.service.createdAt }</span>
                                        </div>
                                        {
                                            this.state.creatorName &&
                                            <div className="row no-gutters lineBodyOthersDetailsService d-flex justify-content-between">
                                                <span className="minumus">Criado por</span>
                                                <span className="minumus">{ this.state.creatorName }</span>
                                            </div>
                                        }
                                        {
                                        this.props.permissions.leadOwners.toView &&
                                        <Fragment>
                                            <div className="row no-gutters lineBodyOthersDetailsService general d-flex justify-content-between">
                                                <span>Proprietário</span>
                                            </div>
                                            {
                                                this.state.owner === null ?
                                                    <div className="row no-gutters lineBodyOthersDetailsService d-flex justify-content-between">
                                                        <span className="minumus">Nenhum proprietário</span>
                                                    </div>
                                                :
                                                    <div className="row no-gutters lineBodyOthersDetailsService d-flex justify-content-between">
                                                        <span className="minumus">{this.state.owner.name}</span>
                                                        <span className="minumus">{this.state.owner.permissionGroup}</span>
                                                    </div>
                                            }
                                        </Fragment>
                                        }
                                        <div className="row no-gutters lineBodyOthersDetailsService general d-flex justify-content-between">
                                            <span>Atividades principais</span>
                                        </div>
                                        {
                                            this.state.groupActivities.map((a, i) => {
                                                return (
                                                    <Fragment key={i}>
                                                        <div className="row no-gutters lineBodyOthersDetailsService d-flex justify-content-between">
                                                            <div className="progressOthersDetailsService" style={{backgroundColor: "var(--color-warning)"}} >&nbsp;</div>
                                                        </div>
                                                        <div className="row no-gutters lineBodyOthersDetailsService d-flex justify-content-between">
                                                            <span className="minumus">{ a.name }</span>
                                                            <span className="minumus">{ a.count }</span>
                                                            <span className="minumus">{ a.percentage } %</span>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </Box> */}
                            </div>
                            <div className="col-12 col-md-12 col-lg-8 d-none d-md-block p-md-2 pr-lg-4">
                                <Box
                                    classaditional="boxActivitiesBodyDetailsService"
                                >
                                    <Menus
                                        classaditional="menusActivitiesDetailsService"
                                        menus={this.state.tabs}
                                        active={this.state.activeTab}
                                        onChange={(option) => this.setState({activeTab: option})}
                                    />
                                    {
                                        this.contentCurrentTab()
                                    }
                                    {/* <ActivityForm 
                                        business={{
                                            id: this.state.id,
                                            name: this.state.name,
                                            title: this.state.title,
                                            clientId: this.state.clientId,
                                            owners: this.state.optionsOwnersLead,
                                            owner: this.state.owner,
                                            status: this.state.status
                                        }}
                                        onSaveActivity={async () => {
                                            this.getActivities();
                                            await this.getDetailsService();
                                        }}
                                        contentOption={this.state.activeTab}
                                    /> */}
                                </Box>
                                {/* <br />
                                <Box classaditional="boxActivitiesHistoricBodyDetailsService"><span className="statusActivitiesDetailsService">Planejado</span>
                                    {
                                        this.state.allActivities.filter((a => !a.finished && a.files.length === 0)).map((a, i) => {
                                            return (
                                                <ItemHistoricActivity key={a.id} activity={a} onUpdateActivity={this.getActivities} onCheckActivity={this.onCheckActivity} />
                                            )
                                        })
                                    }
                                    <span className="statusActivitiesDetailsService">Concluído</span>
                                    {
                                        this.state.allActivities.filter((a => a.files.length > 0)).map((a, i) => {
                                            return (
                                                <ItemHistoricActivity key={a.id} activity={a} onUpdateActivity={this.getActivities} onCheckActivity={this.onCheckActivity} />
                                            )
                                        })
                                    }
                                    {
                                        this.state.allActivities.filter((a => a.finished && a.files.length === 0)).map((a, i, s) => {
                                            let size = s.length;
                                            return (
                                                <ItemHistoricActivity key={a.id} activity={a} onUpdateActivity={this.getActivities} onCheckActivity={this.onCheckActivity} lastActivity={i === (size - 1)}/>
                                            )
                                        })
                                    }
                                </Box> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state) 
{
    const permissions = state.permissions;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;
    const { user } = state.auth;
    
    return {
        permissions,
        hasPermissionsLoaded,
        user,
    }
}

function mapDispatchToProps (dispatch)
{
    return {
        showModalMoreActions (data)
        {
            //action creator -> action
            const action = showModalMoreActions(data);
            dispatch(action);
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsService));